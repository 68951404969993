
<div class="box">
    <i class="fab fa-dropbox fa-3x"></i>
    <button
    class="btn btn-primary"
    type="button"
    (click)="handleAuthorization()"
    >
        Authorize me!
    </button>
</div>
