<div status="success">
    <div *ngIf="aviso">
        <p style="font-size: 15px; text-align:justify;">
            <strong>Atenção :</strong> Você está prestes a solicitar a vistoria para o cliente <strong>{{filtredvisto[0].Cliente}}</strong> . É importante que você tenha seguido todas as recomendações e exigências do projeto enviado pela equipe de engenharia.
            Tenha fotografias nítidas dos componentes do seu sistema para preparação do documento de validação de sua solicitação. Esse procedimento é importante para que possamos garantir qualidade em nossos projetos.</p>
    </div>
    <div style="top: 0px;" class="custom-file">
        <input ng2FileSelect multiple [uploader]="uploader" class="custom-file-input" type="file" accept="application/pdf,application/msword,image/*">
        <label class="custom-file-label" id="file-upload" #labelImport for="importFile">
              Escolher fotos da usina </label>
    </div>
    <h6 style="margin-top: 10px;">O que devo enviar?</h6>
    <div style="margin-left: 20px; margin-top:10px">
        <ul>
            <li>Foto nítida e ampla dos módulos fixados</li>
            <li>Foto nítida dos módulos aterrados</li>
            <li>Foto nítida do cabeamento em eletroduto, eletrocalha ou perfilado</li>
            <li>Foto nítida da fixação da proteção CC, CA e inversores</li>
            <li>Foto nítida da segregação da alimentação CC e CA</li>
            <li>Foto nítida das ligações internas da(s) StringBox</li>
            <li>Foto nítida das ligações internas do(s) quadro(s) CA</li>
            <li>Foto nítida das ligações do(s) Inversores</li>
            <li>Foto nítida das medições de corrente e tensão em cada string</li>
            <li>Foto nítida do aterramento do sistema</li>
            <li>Foto nítida dos instaladores com EPI's e EPC's utilizados</li>

        </ul>
    </div>
    <div style="margin-bottom: 40px;min-width: 700px;">
        <table style="background-color: white;min-width: 700px; " class="table">
            <thead>
                <tr>
                    <th class="teste" width="50%">Nome</th>
                    <th class="teste">Tamanho</th>
                    <th class="teste">Status</th>
                    <th class="teste">Ação</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of uploader.queue">
                    <td nowrap>
                        <p style=" text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;">{{ item?.file?.name }}</p>
                    </td>
                    <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                    <td class="text-center">
                        <span *ngIf="item.isSuccess">
                      <mat-icon>check</mat-icon>
                    </span>
                        <span *ngIf="item.isCancel">
                      <mat-icon>close</mat-icon>
                    </span>
                    </td>
                    <td nowrap>


                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                        <mat-menu #menu="matMenu">
                            <button style="border: black;" (click)="item.remove()" mat-menu-item>
                        <mat-icon>remove_circle_outline</mat-icon>
                        <span>Remover</span>
                      </button>
                        </mat-menu>
                    </td>
                </tr>
            </tbody>
        </table>

        <div>
            <div style="margin-bottom: 10px;">
                <p class="subtitle" style="color: black;">Progresso:</p>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                </div>
            </div>
            <p-divider></p-divider>
            <button style="width: 100% !important" (click)="uploadalgo(dialogo)" [disabled]=" !uploader.getNotUploadedItems().length" nbButton>
                SOLICITAR
              </button>
        </div>
    </div>
</div>
<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card style="width:600px;">
        <nb-card-body>
            <nz-result nzStatus="success" nzTitle="Vistoria Solicitada com sucesso!" nzSubTitle="Não se esqueça de enviar o check-list preenchido e assinado pela equipe de execução">
                <div nz-result-extra>
                    <button (click)="ref.close(); rota()" label="Fechar" pButton type="button" icon="pi pi-check" iconPos="left"></button>
                </div>
            </nz-result>
        </nb-card-body>
    </nb-card>
</ng-template>
<p-dialog header="Vistoria Solicitada" [(visible)]=" finalizou ">
    <nz-result nzStatus="success " nzTitle="Não se esqueça de enviar o check-list preenchido!" nzSubTitle="">
        <div nz-result-extra>
            <button (click)="finalizou=false; rota() " label="Finalizar" pButton type="button" icon="pi pi-check" iconPos="left"></button>
        </div>
    </nz-result>
</p-dialog>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8) " size="medium " color="#fff " type="timer " [fullScreen]="true ">
    <p style="color: white "> Aguarde... </p>
</ngx-spinner>