
<nb-card style="margin-right: 0.5rem;margin-left: 0.5rem;margin-top: 2rem !important; border: 1px solid rgb(171, 171, 255)" status="success">
    <nb-card-header style="background-color: white; color: black; padding: 0rem !important">
        <p-menubar [model]="itemsx">
            <ng-template pTemplate="start"> </ng-template>
        </p-menubar>
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="productForm">
            <div *ngIf="menu1 === true">
                <div formGroupName="dadosinstal">

                    <div class="form-row">
                        <div class="form-group col-3">
                            <ejs-numerictextbox format='###' min='0' max='500' step='1' formControlName="digeral" placeholder="Disjuntor de Entrada" cssClass="e-outline" (change)="disjuntorentrada=$event.value" [value]="disjuntorentrada" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-dropdownlist formControlName="entradaareasub" floatLabelType="Auto" cssClass="e-outline" id='entradaAreaSub' #sample (change)="entradaAreaSub = $event.value;shx($event.value)" [dataSource]='ramaistipos' [value]='entradaAreaSub' [fields]='fields'
                                placeholder='Ramal' [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                        <div class="form-group col-3">
                            <ejs-dropdownlist formControlName="padraoentrada" floatLabelType="Auto" cssClass="e-outline" id='padraoDeEntrada' #sample (change)="padraoDeEntrada = $event.value;" [dataSource]='faseamentotipos' [value]="padraoDeEntrada" [fields]='fields' placeholder='Faseamento'
                                [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                        <div class="form-group col-3">
                            <ejs-dropdownlist formControlName="classetensao" floatLabelType="Auto" cssClass="e-outline" id='classetensao' #sample [dataSource]='fases' (change)="classetensao = $event.value" [value]="classetensao" [fields]='fields' placeholder='Classe de Tensão' [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-3">
                            <ejs-numerictextbox format='###' min='0' max='500' step='1' formControlName="aumentocarga" placeholder="Aumento de carga" cssClass="e-outline" [(ngModel)]="disjuntoraumentocarga" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-dropdownlist formControlName="tipoaumentocarga" floatLabelType="Auto" cssClass="e-outline" id='tipoaumentocarga' #sample (change)="tpdaumentoDeCarga = $event.value" [dataSource]='faseamentotipos' [value]="tpdaumentoDeCarga" [fields]='fields' placeholder='Faseamento'
                                [popupHeight]='height'></ejs-dropdownlist>
                        </div>

                        <div class="form-group col-3">
                            <ejs-numerictextbox format='###.##' min='0' max='500' step='0.1' formControlName="distanciaplacaquadro" placeholder="Dist. placas ao inversor" cssClass="e-outline" (change)="distanciaPlacaInversor = $event.value" [value]="distanciaPlacaInversor" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>

                        <div class="form-group col-3">
                            <ejs-numerictextbox format='###.##' min='0' max='500' step='0.1' formControlName="distanciainversorquadro" placeholder="Dist. inversor ao quadro" cssClass="e-outline" (change)="distanciaInversorQuadro = $event.value" [value]="distanciaInversorQuadro" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>

                    </div>



                    <div class="form-row">
                        <div class="form-group col-3">
                            <ejs-dropdownlist formControlName="localinst" floatLabelType="Auto" cssClass="e-outline" id='inputlocal' #sample (change)="localInstalacao = $event.value" [dataSource]='locais' [value]="localInstalacao" [fields]='fields' placeholder='Local de Instalação'
                                [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                        <div class="form-group col-3">
                            <ejs-dropdownlist formControlName="tipotelhado" floatLabelType="Auto" cssClass="e-outline" id='inputtipo' #sample (change)="tipoDeTelhado = $event.value" [dataSource]='tipos' [value]="tipoDeTelhado" [fields]='fields' placeholder='Tipo de Instalação' [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox formControlName="latitude" id='inputlatitude' placeholder="Latitude" cssClass="e-outline" (change)="latitude = $event.value" [value]="latitude" floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox formControlName="longitude" placeholder="Longitude" cssClass="e-outline" (change)="longitude = $event.value" [value]="longitude" floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-3">
                            <ejs-numerictextbox format='###.##' min='0' max='500000' step='0.1' formControlName="consumo" placeholder="Consumo considerado" cssClass="e-outline" (change)="consumo = $event.value" [value]="consumo" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        
                        <div class="form-group col-3">
                            <ejs-dropdownlist formControlName="agrupado" floatLabelType="Auto" cssClass="e-outline" id='agrupado' #sample (change)="agrupado = $event.value" [dataSource]='simnao' [value]="agrupado" [fields]='fields' placeholder='Padrão energia agrupado ?' [popupHeight]='height'></ejs-dropdownlist>
                        </div>

                        <div class="form-group col-3">
                            <ejs-dropdownlist formControlName="rateio" floatLabelType="Auto" cssClass="e-outline" id='rateio' #sample (change)="rateio = $event.value" [dataSource]='simnao' [value]="rateio" [fields]='fields'placeholder="Haverá compensação?"  [popupHeight]='height'></ejs-dropdownlist>
                        </div>

                        <div class="form-group col-3">
                            <ejs-dropdownlist formControlName="coletivo" floatLabelType="Auto" cssClass="e-outline" id='coletivo' #sample (change)="coletivo = $event.value" [dataSource]='simnao' [value]="coletivo" [fields]='fields' placeholder="Telhado coletivo?"  [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                    
                    </div>
                    <button style="width: 100% !important" (click)="abremapa()" nbButton>
                        <i class="pi pi-map-marker"></i>
 Abrir Mapa
                      </button>
                </div>
                
            </div>
            <div *ngIf="menu2 === true">


                <div class="espaco"></div>
                <div formArrayName="selling_points">
                    <div *ngFor="let item of sellingPoints.controls;let u =index; let pointIndex=index; let lastindex = pointIndex " [formGroupName]="pointIndex">
                        <div class="espaco"></div>
                        <span class="subheader"><strong>Configuração Nº{{pointIndex + 1}}:
                        </strong> </span>
                        <div class="espaco"></div>
                        <div class="form-row">
                            <div class="form-group col-3">
                                <ejs-numerictextbox format='###' min='0' max='500' step='1' formControlName="qtdmodulos" id="inputqtdmodulos+{{pointIndex + 1}}" placeholder="Quantidade de Módulos" cssClass="e-outline" floatLabelType="Auto"></ejs-numerictextbox>
                            </div>
                            <div class="form-group col-3">
                                <ejs-textbox formControlName="marcamodulos" id="inputmarcamodulos+{{pointIndex + 1}}" placeholder="Marca dos módulos" cssClass="e-outline" floatLabelType="Auto"></ejs-textbox>
                            </div>
                            <div class="form-group col-3">
                                <ejs-textbox formControlName="modelomodulos" id="modelomodulos+{{pointIndex + 1}}" placeholder="Modelo dos módulos" cssClass="e-outline" floatLabelType="Auto"></ejs-textbox>
                            </div>

                            <div class="form-group col-3">
                                <ejs-numerictextbox format='###.##' min='0' max='500' step='1' formControlName="potenciamodulo" placeholder="Potência Total Kwp" id="potenciamodulo+{{pointIndex + 1}}" cssClass="e-outline" floatLabelType="Auto"></ejs-numerictextbox>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-3">
                                <ejs-numerictextbox format='###' min='0' max='500' step='1' formControlName="qtdinv" id="inputqtdinv+{{pointIndex + 1}}" placeholder="Quantidade de Inversor" cssClass="e-outline" floatLabelType="Auto"></ejs-numerictextbox>
                            </div>
                            <div class="form-group col-3">
                                <ejs-textbox formControlName="marcainv" id="marcainv+{{pointIndex + 1}}" placeholder="Marca do Inversor" cssClass="e-outline" floatLabelType="Auto"></ejs-textbox>
                            </div>
                            <div class="form-group col-3">
                                <ejs-textbox formControlName="modeloinv" id="inputModeloinv+{{pointIndex + 1}}" placeholder="Modelo do Inversor" cssClass="e-outline" floatLabelType="Auto"></ejs-textbox>
                            </div>
                            <div class="form-group col-3">
                                <ejs-numerictextbox format='###.##' min='0' max='500' step='1' formControlName="potenciinv" placeholder="Potência do Inversor" id="inputpotetotalinv+{{pointIndex + 1}}" cssClass="e-outline" floatLabelType="Auto"></ejs-numerictextbox>
                            </div>
                        </div>

                    </div>
                </div>

                <p-divider align="right">
                    <div class="p-d-inline-flex p-ai-center">
                        <button  style="margin-right: 20px ;" (click)="addSellingPoint()" pButton pRipple type="button" label="Adicionar" class="p-button-raised"></button>
                        <button (click)="deleteSellingPoint(lasti)"pButton pRipple type="button" label="Remover" class="p-button-raised"></button>
                    </div>
                </p-divider>
                <div formGroupName="dadosinstal" class="form-row" style="width: 100%;">
                    <div class="form-group col-12">
                        <ejs-textbox rows="4" formControlName="descricaodokit" [(ngModel)]="descricaodokit" [multiline]="true" id="configuração" placeholder="Descrição e observações da homologação" cssClass="e-outline" floatLabelType="Auto"></ejs-textbox>
                    </div>
                </div>
            </div>
            <div *ngIf="menu3 === true">

                <div class="row">
                    <div class="col col-md-3">
                      <div class="card">
                        <div class="card-header" data-id-feature="1">
                          <div class="feature-cabecalho"></div>
                          <ejs-dropdownlist (click)='getcons()' floatLabelType="Auto" cssClass="e-outline" id='inputtipoco' #sample (change)="dataChanged($event)" [dataSource]='Cons' [fields]='Consx' placeholder='Escolha a concessionária' [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                        <div class="card-body">
                          <div class="row">
                  
                          <div>
                              <div style="margin-bottom: 0px; " role="list" *ngFor="let file of filesUp;let i =index ">
                                  <div style="margin-bottom: 20px; " class="espaco3">
                                      <label for="file-upload">
                            <div class="faab" position="top left 0px">
                              <img 
                                style="margin-top: 0px;margin-left: 4px;position: center;height: 20px; width: 20px; color: white"
                                src="assets/images/up.png">
                            </div>
                          </label>
                                  </div>
                                  <div style="margin-left: 40px;margin-top: -55px; ">
                                      <span style="color: black;
                                      font-weight: 500 !important;
                                      font-family: 'Oxygen', sans-serif !important; font-size: 12px !important;" id="custom-text">{{file}}</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                        </div>
                      </div>
                    </div>
                    <div class="col col-md-9">
                        <button style="width: 100% !important;background-color:  rgb(24, 133, 201) !important;color:white;" (click)="solicitahomologa()" nbButton>
                            <i class="pi pi-send"></i>
     Solicitar homologação
                          </button>
                        <div class="sample-container">
                            <ejs-filemanager  [navigationPaneSettings]='navigationPaneSettings' [height]='"80vh"'  (beforeSend)="OnbeforeSend($event)" 
                            [rootAliasName]="homol" (toolbarClick)="toolbarClicks($event)" id='filemanager' [ajaxSettings]='ajaxSettings'>
                            </ejs-filemanager>

                          </div>
                    </div>
                  </div>

            </div>
        </form>
    </nb-card-body>
    <nb-card-footer style="background-color: white; color: white">
    </nb-card-footer>
</nb-card>


<ng-template #dialogoso let-data let-ref="dialogRef">
    <nb-card style="width:600px;">
        <nb-card-header style="background-color: red;">
            <h6 style="color: white;">Atenção</h6>
        </nb-card-header>
        <nb-card-body>
            <div class="list4">
                <ol class="">
                    <li>
                        <b>Deixar de enviar</b> arquivos obrigatórios pode causar o atraso na preparação do projeto e de documentos técnicos, o que pode atrasar sua homologação.
                    </li>
                    <li>
                        Inserir <b>modelo e marca</b> correto dos módulos e inversores, não coloque dados genéricos, sempre busque esses dados do seu fornecedor.
                    </li>
                    <li>
                        Para clientes da <b>CPFL, Equatorial</b> é obrigatório o envio de fotos nítidas : <b>Ampla do padrão</b>,
                        <b>disjuntor de entrada com cabeamento</b>.
                    </li>
                    <li>
                        Para clientes da <b>Equatorial</b> é obrigatório o envio do número do poste mais próximo, conta contrato, data de expedição do RG.
                    </li>
                    <li>
                        Sempre envie fotos <b>nítidas</b> para que nossa equipe consiga identificar os dados necessários de sua homologação.
                    </li>
                    <li>
                        Caso já tenha sido adquirido as proteções CC e CA é necessário informar <b>Modelo ou datasheet da
                Strginbox</b> e parâmetros elétricos de <b>Disjuntores e DPS</b>.
                    </li>
                </ol>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton (click)="ref.close()">Ok</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card style="width:600px;">
        <nb-card-body>
            <nz-result nzStatus="success" nzTitle="Homologação solicitada com sucesso!" nzSubTitle="Em breve enviaremos documentos para assinatura, verifique sempre a pasta do seu projeto">
                <div nz-result-extra>
                    <button (click)="ref.close(); rota()" label="Fechar" pButton type="button" icon="pi pi-check" iconPos="left"></button>
                </div>
            </nz-result>
        </nb-card-body>
    </nb-card>
</ng-template>

<ng-template #dialogs let-data let-ref="dialogRef">
    <nb-card style="width:600px;">
        <nb-card-body>
            <nz-result nzStatus="success" nzTitle="Homologação solicitada com sucesso!" nzSubTitle="Em breve enviaremos documentos para assinatura, verifique sempre a pasta do seu projeto">
                <div nz-result-extra>
                    <button (click)="ref.close(); rota()" label="Fechar" pButton type="button" icon="pi pi-check" iconPos="left"></button>
                </div>
            </nz-result>
        </nb-card-body>
    </nb-card>
</ng-template>

<ng-template #dialog2 let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>Homologação não enviada</nb-card-header>
        <nb-card-body>Sua homologação <strong>não</strong> foi enviada verifique todos os dados! <br> Caso o erro persista tente novamente mais tarde ou entre em contato com a equipe técnica.</nb-card-body>
        <nb-card-footer>
            <button style="width: 100px;" nbButton (click)="ref.close()">Fechar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template style="width: 400px" #dialog3 let-data let-ref="dialogRef">
    <nb-card style="width: 700px">
        <nb-card-header>Verificar Campos</nb-card-header>
        <nb-card-body>Não foi possivel enviar sua homologação verifique se preencheu todos os campos
            <strong>obrigatórios</strong>
        </nb-card-body>
        <nb-card-footer>
            <button style="width: 100px;" nbButton (click)="ref.close()">Abrir painel</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<p-dialog [modal]="true" [style]="{width: '40vw'}" [(visible)]="todosfiles">
    <nz-result nzStatus="info" nzTitle="Nā foram adicionados todos os arquivos obrigatórios" nzSubTitle="verifique novamente todos os arquivos e tente enviar novamente">
    </nz-result>
    <p-footer>
        <button nbButton>Ok</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="criafoldex" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '60vw'}" header="Criação do armazenamento Cloud" 
[draggable]="false" [resizable]="false">
<div class="flex flex-wrap border-top-1 surface-border pt-4">
    <div class="bg-blue-50 flex align-items-center justify-content-center py-3 px-0 w-full md:w-4 border-round">
        <img src="assets/folders.png" alt="Image" class="w-9">
        
    </div>
        <p style="  text-align: justify;
        text-justify: inter-word;" class="text-700 line-height-3 m-0 p-5 w-full md:w-8">     
        Você está prestes a criar o armazenamento do seu projeto e temos algumas mudanças importantes para você: <b>O seu cliente precisa estar de acordo que as informações pessoais de identificação, bem como documentos (CNH, RG, CPF, endereço), foram disponibilizadas digitalmente para nossa empresa cuidar do processo dele</b>    
    <br>
    Os dados inseridos no sistema devem ser restritos ao pessoal autorizado, sendo vedado a transferência de acesso à terceiros; Após a finalização do processo, os documentos salvos na plataforma poderão ser apagados em até 30 dias, pois não serão mais úteis;    
    </p>
        
</div>
<ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
        <button pButton pRipple icon="pi pi-times" (click)="criafoldex = false;menumuda(2)" label="Cancelar" class="p-button-text"></button>
        <button pButton pRipple icon="pi pi-check" (click)="criasfold()" label="Concordo"></button>
    </div>
</ng-template>
</p-dialog>

<p-dialog [modal]="true" [style]="{ width: '40vw' }" [(visible)]="naopode">
    <nz-result nzStatus="warning" nzTitle="Verifique todos os campos!" nzSubTitle="Apenas os campos: Aumento de carga e  Faseamento do aumento de carga podem ficar em branco">
    </nz-result>
</p-dialog>


<p-dialog [modal]="true" [style]="{ width: '40vw' }" [(visible)]="alerta">
    <nz-result nzStatus="warning" nzTitle="Não enviada!" nzSubTitle="Verifique todos os campos, homologação não enviada">
    </nz-result>
</p-dialog>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    <p style="color: white"> Aguarde... </p>
</ngx-spinner>

<p-dialog [(visible)]="naocontinua" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}">
    <ng-template pTemplate="header">
        <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
            <span class="font-medium text-2xl text-900">Dados incompletos</span>
        </div>
    </ng-template>
    <p class="line-height-3 p-0 m-0">
        Verifique todos os dados no preenchimento de sua solicitação.Os únicos campos que não são obrigatórios são:  <b>aumento de carga e faseamento do aumento de carga.</b>
    </p>
    <ng-template pTemplate="footer">
        <div class=" border-top-1 surface-border pt-3">
            <button pButton pRipple icon="pi pi-times" (click)="naocontinua = false" label="Cancel" class="p-button-text"></button>
            <button pButton pRipple icon="pi pi-check" (click)="naocontinua = false" label="Save"></button>
        </div>
    </ng-template>
</p-dialog>