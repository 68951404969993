import { Component, OnInit } from '@angular/core';
import {
  FileManagerComponent,
  NavigationPaneService,
  ToolbarService,
  DetailsViewService
} from '@syncfusion/ej2-angular-filemanager';
@Component({
  selector: 'app-filemanager',
  templateUrl: './filemanager.component.html',
  styleUrls: ['./filemanager.component.css']
})
export class FilemanagerComponent implements OnInit {
  public ajaxSettings: object;
  public hostUrl: string = 'https://ej2-azure-aspcore-service.azurewebsites.net/';

  constructor() { }
  public ngOnInit(): void {
    this.ajaxSettings = {
        url: this.hostUrl + 'api/AzureFileManager/AzureFileOperations',
        getImageUrl: this.hostUrl + 'api/AzureFileManager/AzureGetImage',
        uploadUrl: this.hostUrl + 'api/AzureFileManager/AzureUpload',
        downloadUrl: this.hostUrl + 'api/AzureFileManager/AzureDownload'
    };
}

}
