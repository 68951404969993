<mat-card style="margin-top: 20px;background-color: white;">
  <mat-card-title style="font-size: 16px;font-weight:bold">Atualização de Kits</mat-card-title>
  <div class="form-row">

    <div class="form-group col-md-2">
      <label style="font-weight:bold" for="albedo">Potência :</label>
      <div  class="input-group mb-3 ">
        <input min='0' (change)="potenciamodulos($event.target.value);potenciafvselec=$event.target.value"  type="number" class="form-control" id="potinv">
        <div class="input-group-append">
          <span class="input-group-text">kW</span>
        </div>
      </div>
    </div>


    <div class="form-group col-md-3">
      <label style="font-weight:bold" for="albedo">Marca Módulos:</label>
      <div class="input-group ">
        <select (change)="modelomodulos($event.target.value); marcamodulos = $event.target.value"
          class="custom-select" id="marca">
          <option> Selecione...</option>
          <option *ngFor="let marca of marcas" [value]="marca">{{marca}}</option>
        </select>
      </div>
    </div>

    <div class="form-group col-md-4">
      <label style="font-weight:bold" for="albedo">Modelo Módulos:</label>
      <div class="input-group ">
        <select (change)="parametromudolo($event.target.value);mudamodelo($event.target.selectedIndex)"
          class="custom-select" id="modelo">
          <option> Selecione...</option>
          <option  *ngFor="let modelo of mod;let i = index;let indice = i" [value]="modelo.codice2">{{modelo.modelo}}</option>

        </select>
      </div>
    </div>

    <div class="form-group col-md-3">
      <label style="font-weight:bold" for="albedo">Código:</label>
      <div class="input-group ">
        <input min='0'    class="form-control" id="potinv">
      </div>
    </div>
  </div>
<div class="container" style="padding-top: 10px;">
  <div class="row">
    <!-- left column -->
    <div class="col-md-4 col-sm-6 col-xs-12">
      <div style="background-color: transparent;" class="text-center">
        <img style="background-color: transparent;" src="assets/images/panel.png" class="avatar img-circle img-thumbnail" alt="avatar">
      </div>
    </div>
    <!-- edit form column -->
    <div class="col-md-8 col-sm-6 col-xs-12 personal-info">
      <div class="form-row">
        <div class="form-group col-md-2">
          <label style="font-weight:bold" for="albedo">Tensão(Vmp) :</label>
          <div class="input-group ">
            <input class="form-control"  type="text">
          </div>
        </div>
        <div class="form-group col-md-2">
          <label style="font-weight:bold" for="albedo">Corrente(Imp):</label>
         <div class="input-group ">
            <input class="form-control"  type="text">
          </div>
        </div>

        <div class="form-group col-md-2">
          <label style="font-weight:bold" for="albedo">Tensão(Voc):</label>
         <div class="input-group ">
            <input class="form-control"  type="text">
          </div>
        </div>

        <div class="form-group col-md-2">
          <label style="font-weight:bold" for="albedo">Corrente(Isc):</label>
          <div class="input-group ">
            <input class="form-control"  type="text">
          </div>
        </div>
        <div class="form-group col-md-2">
          <label style="font-weight:bold" for="albedo">Eficiência:</label>
          <div class="input-group ">
            <input class="form-control"  type="text">
          </div>
        </div>

      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label style="font-weight:bold" for="albedo">CFT (Pmax) -%/°C  :</label>
          <div class="input-group ">
            <input class="form-control"  type="text">
          </div>
        </div>
        <div class="form-group col-md-3">
          <label style="font-weight:bold" for="albedo">CFT (Voc) - %/°C:</label>
         <div class="input-group ">
            <input class="form-control"  type="text">
          </div>
        </div>

        <div class="form-group col-md-3">
          <label style="font-weight:bold" for="albedo">CFT (Isc) - %/°C:</label>
         <div class="input-group ">
            <input class="form-control"  type="text">
          </div>
        </div>
        <div class="form-group col-md-3">
          <label style="font-weight:bold" for="albedo">Tensão Isolamento:</label>
          <div class="input-group ">
            <input class="form-control"  type="text">
          </div>
        </div>
      </div>

        <div class="form-group">
          <label class="col-md-3 control-label"></label>
          <div class="col-md-8">
            <input class="btn btn-primary" style="width: 200px;" value="Salvar Alterações" type="button">
          </div>
        </div>
    </div>
  </div>
</div>
</mat-card>

<mat-card style="margin-top: 20px;background-color: white;">
  <mat-card-title style="font-size: 16px;font-weight:bold">Atualização de Kits</mat-card-title>
  <div class="form-row">
    <div class="form-group col-md-12">
      <div class="form-check">
        <input (click)="$event.target.value" [(ngModel)]="simetria" type="checkbox" class="form-check-input" id="check5">
        <label class="form-check-label" for="exampleCheck1">Possui MPPT não simétricos</label>
      </div>
    </div>
  </div>

  <div class="form-row">

    <div class="form-group col-md-2">
      <label style="font-weight:bold" for="albedo">Potência :</label>
      <div  class="input-group mb-3 ">
        <input min='0' (change)="marcainversor2($event.target.value);"  type="text" class="form-control" id="potinv">
        <div class="input-group-append">
          <span class="input-group-text">kW</span>
        </div>
      </div>
    </div>


    <div class="form-group col-md-3">
      <label style="font-weight:bold" for="albedo">Marca Inversor:</label>
      <div class="input-group ">
        <select (change)="modeloinversor($event.target.value);"
          class="custom-select" id="marca">
          <option> Selecione...</option>
          <option *ngFor="let marca of marcasinver2" [value]="marca">{{marca}}</option>
        </select>
      </div>
    </div>

    <div class="form-group col-md-4">
      <label style="font-weight:bold" for="albedo">Modelo Inversor:</label>
      <div class="input-group ">
        <select (change)="parainver2($event.target.value);"
          class="custom-select" id="modelo">
          <option> Selecione...</option>
          <option *ngFor="let modelo of modi" [value]="modelo.codice2">{{modelo.modelo}}</option>
        </select>
      </div>
    </div>

    <div class="form-group col-md-3">
      <label style="font-weight:bold" for="albedo">Código:</label>
      <div class="input-group ">
        <input min='0'    class="form-control" id="potinv">
      </div>
    </div>
  </div>
<div class="container" style="padding-top: 10px;">
  <div class="row">
    <!-- left column -->
    <div class="col-md-4 col-sm-6 col-xs-12">
      <div style="background-color: transparent;" class="text-center">
        <img style="background-color: transparent;" src="assets/images/inverter.png" class="avatar img-circle img-thumbnail" alt="avatar">
      </div>
    </div>
    <!-- edit form column -->
    <div class="col-md-8 col-sm-6 col-xs-12 personal-info">
      <div class="form-row">
        <div class="form-group col-md-3">
          <label style="font-weight:bold" for="albedo">Tensão nominal :</label>
          <div class="input-group ">
            <input class="form-control"  type="text">
          </div>
        </div>
        <div class="form-group col-md-3">
          <label style="font-weight:bold"  for="albedo">Tensão Máxima:</label>
         <div class="input-group ">
            <input class="form-control" [value]="paramsinv2[0].vmp_max"  type="text">
          </div>
        </div>
        <div class="form-group col-md-3">
          <label style="font-weight:bold" for="albedo">Tensão Minima MPPT:</label>
         <div class="input-group ">
            <input class="form-control" [value]="paramsinv2[0].vmp_min"  type="text">
          </div>
        </div> 
        <div class="form-group col-md-3">
          <label style="font-weight:bold" for="albedo">Tensão Máxima MPPT:</label>
         <div class="input-group ">
            <input class="form-control" [value]="paramsinv2[0].vmpax"  type="text">
          </div>
        </div>  
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label style="font-weight:bold"  for="albedo">Corrente String:</label>
         <div class="input-group ">
            <input class="form-control" [value]="paramsinv2[0].cor_str"  type="text">
          </div>
        </div>
        <div class="form-group col-md-3">
          <label style="font-weight:bold" for="albedo">Corrente Máxima :</label>
         <div class="input-group ">
            <input class="form-control" [value]="paramsinv2[0].cor_max"  type="text">
          </div>
        </div> 
 
      </div>
      <div *ngFor="let mppt of IDSCONF1;let i = index" class="form-row">          
        <div *ngIf="i+1 <= nummpp"  class="form-group col-md-3">
          <label style="font-weight:bold" for="albedo">{{mppt.viewValue }}:</label>
          <div class="input-group ">
            <input [value]="mppt.value" (change)="mppt.value = $event.target.value" class="form-control" [value]="mppt.value" >
          </div>
        </div>
      </div>

        <div class="form-group">
          <label class="col-md-3 control-label"></label>
          <div class="col-md-8">
            <input (click)="upai()"class="btn btn-primary" style="width: 200px;" value="Salvar Alterações" type="button">
          </div>
        </div>
    </div>
  </div>
</div>
</mat-card>

