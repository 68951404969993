    <div   class="mesgs">
        <div  #scrollMe class="msg_history">
          <p class="descriptions"> Bate papo da cotação - {{currentCota}}</p>

          <div class="conversation-container"*ngFor="let msg of chatsx; let i= index">
              <div  *ngIf="msg.tipo !== 'in' " class="received_msg">
                <div class="message sent">
                  <span class="time_date"> {{msg.user}}</span>
                  <p>{{msg.textmsg}}</p>
                  <span class="time_date"> {{msg.ts}}</span>
              </div>
            </div>
       
  
    
              <div *ngIf="msg.tipo !== 'out'" class="received_msg">
                <div class="message received">
                  <span class="time_date"> {{msg.user}}</span>
                <p>{{msg.textmsg}}</p>
                <span class="time_date"> {{msg.ts}}</span>
              </div> </div>
          </div>
        </div>
        <div style="margin-bottom: 5px;" class="type_msg">
          <form class="conversation-compose">
            <div class="emoji">
            </div>
            <input [(ngModel)]= "messageText" class="input-msg" name="input" placeholder="Escreva sua mensagem" autocomplete="off" autofocus>
            <div class="photo">
              <i class="zmdi zmdi-camera"></i>
            </div>
            <button (click)="sendMessage()" class="send">
                <div class="circle">
                    <i class="fas fa-paper-plane"></i>                
                </div>
              </button>
          </form>
        </div>
      </div>
       
