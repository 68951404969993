  <div class="form-row">

    <div class="form-group col-md-3">
      <label for="inputtipo">Escolha a concessionária:*</label>
      <select (change)="dataChanged($event.target.value)" id="inputtipo" class="form-control">
        <option selected>Selecione...</option>
        <option *ngFor="let con of Cons" [value]="con.value">{{con.viewValue}}</option>
      </select>
    </div>

  </div>
 <div style="overflow: hidden">
  <iframe  *ngIf="showx" [src]="linksx| safex: 'resourceUrl'" frameborder="0" height="500" width="100%" marginheight="0" marginwidth="0">Carregando…</iframe>
</div> 
