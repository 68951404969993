import { Component, OnInit } from '@angular/core';
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';

@Component({
  selector: 'app-intersolar',
  templateUrl: './intersolar.component.html',
  styleUrls: ['./intersolar.component.css']
})
export class IntersolarComponent implements OnInit {
wpp="";
nome="";
  constructor(private service: HomologacoesserviceService) { }

  ngOnInit(): void {

  }


  enviatexto(){
    this.service.sendtextintersolar({
      sessionName: 'sollux',
      number: this.wpp,
      text: 'Olá ' +  "*"+ this.nome+ "*"})
      .subscribe(dados => {
        if (dados) {
          console.log(dados)
        }
      });
  }
}
