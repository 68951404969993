import { Component, OnInit, TemplateRef } from '@angular/core';
import * as $AB from 'jquery';
import * as $ from 'jquery';
import { FilesService } from '../files.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileUploader } from 'ng2-file-upload';
import { Dropbox } from 'dropbox';
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-gestaoarquivos',
  templateUrl: './gestaoarquivos.component.html',
  styleUrls: ['./gestaoarquivos.component.css']
})
export class GestaoarquivosComponent implements OnInit {
  Cons: any[];
  concessionaria: string;
  linksx : string;
  showx = false;
  ref = '/aplicativos/Proemanager/';
  path = '';
  pathori = '';
  refant = ''
  refatual = ''
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  homolselec: string;
  ultprogup: number;
  enviado = false;
  foldernames = '';
  caminhoatual = '';
  naoenviado = false;
  validaupload = 'false';
  ACCESS_TOKEN = 'WBvN5eap0OgAAAAAAAAAASiMdIw6Cn6fmogOSIp_aahRK9D_2xNq9ndWaptLsxLE';
  dbx = new Dropbox({ accessToken: this.ACCESS_TOKEN });
  constructor(
    private service: HomologacoesserviceService,
    private dialogService: NbDialogService,
    private spinner: NgxSpinnerService,
    private filesService: FilesService) {  this.uploader = new FileUploader({ url: '',
  disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
  formatDataFunctionIsAsync: true,
  formatDataFunction: async (item) => {
    return new Promise((resolve, reject) => {
      resolve({
        name: item._file.name,
        length: item._file.size,
        contentType: item._file.type,
        date: new Date()
      });
    });
  }
});
this.hasBaseDropZoneOver = false;
this.hasAnotherDropZoneOver = false;
this.response = '';
this.uploader.response.subscribe(res => this.response = res); }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  asyncUpload(i) {
    this.caminhoatual= localStorage.getItem('currentpath')
    console.log(this.caminhoatual)
    console.log(this.caminhoatual.substr(24))
    var novocama = this.caminhoatual.substr(24) + "/" 
    if(novocama ==="//" ){
      novocama = "/";
    }
    console.log(novocama)
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line: max-line-length
      this.dbx.filesUpload({ path: novocama  + this.uploader.queue[i]['_file'].name, contents: this.uploader.queue[i]['_file'] })
        // tslint:disable-next-line: only-arrow-functions
        .then(response => {
          this.enviado = true;
          this.uploader.queue[i]['isUploaded'] = true;
          this.uploader.queue[i]['isSuccess'] = true;
          resolve();
        })
        .catch(error => reject())
        .finally(() => this.progresso());
    });
  }
  fliera(a){console.log(a)}

  criapasta(){
    this.caminhoatual= localStorage.getItem('currentpath')
    if(this.caminhoatual === '/aplicativos/Proemanager/'){
      var novocama = this.caminhoatual.substr(24)  + this.foldernames
    } 
    if(this.caminhoatual !== '/aplicativos/Proemanager/') {
      var novocama = this.caminhoatual.substr(24)  + "/" +  this.foldernames
    }
    console.log(this.caminhoatual)
    console.log(this.caminhoatual.substr(24))
    if(novocama ==="//" ){
      novocama = "/";
    }
    console.log(novocama)
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line: max-line-length
      this.dbx.filesCreateFolderV2({ path:  novocama })
        // tslint:disable-next-line: only-arrow-functions
        .then(response => {
          this.enviado = true;
          resolve();
        })
        .catch(error => reject())
        .finally(() => this.filesService.getFiles(this.caminhoatual));
    });
  }
  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }
  
  progresso() {
    this.ultprogup = 100 / (this.uploader.queue.length);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (this.uploader.queue[i]['isSuccess'] === true) {
        this.uploader.progress = this.ultprogup * (i + 1);
      }
    }
    if (this.uploader.progress === 100) {
      this.validaupload = 'true';
      this.caminhoatual= localStorage.getItem('currentpath');
      this.filesService.getFiles(this.caminhoatual)
      setTimeout(()=>{                          
        this.uploader.clearQueue();
   }, 3000);
    }
  }

  async uploadalgo() {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      await this.asyncUpload(i);
    }
  }
  ngOnInit() {
    this.service.dowconcessionarias()
    .subscribe(dados => {
      if (dados !== this.Cons) {
        this.Cons = dados;
        console.log(this.Cons)
      }
    });
  this.path = this.ref
  this.pathori = this.ref
  }
  dataChanged(event) {
    this.showx = false;
    this.concessionaria = event;
    this.puxauploads();
  }

  puxauploads() {
    this.service.checks({ cons: this.concessionaria })
      .subscribe(dados => {
        if (dados) {
          this.linksx = dados['check']
          if(this.linksx !== null){
            this.showx = true;

          }
         console.log(dados)
        }
      });
  }


  public files: NgxFileDropEntry[] = [];
 
  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
 
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
 
          // Here you can access the real file
          console.log(droppedFile.relativePath, file);
    

          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)
 
          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })
 
          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/
 
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }
 
  public fileOver(event){
    console.log(event);
  }
 
  public fileLeave(event){
    console.log(event);
  }

}
