import { HomologacoesserviceService } from './../homologacoes/homologacoesservice.service';
import { Component, OnInit } from '@angular/core';
import { NbComponentStatus, NbDialogService, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-alteracadastro',
  templateUrl: './alteracadastro.component.html',
  styleUrls: ['./alteracadastro.component.css']
})
export class AlteracadastroComponent implements OnInit {
    dadosolicitante = [];
    id: string;
    resourcesLoaded = '';
    nome = '';
    cpf ='';
    rua = '';
    bairro = '';
    numero = '';
    cidade = '';
    estado = '';
    cep = '';
    email = '';
    telefone = '';
    validacao = [];
    senha1 = '1';
    senha2= '2';
    complemento = '';
    valid={};
  sp = true;
  cpflegal= '';
  replegal = '';
  rglegal= '';
    constructor(private service: HomologacoesserviceService,
                private toastrService: NbToastrService) { }
  
    ngOnInit() {
      this.service.currentid.subscribe(dados => {
        if (dados !== this.id && dados !== '' ) {
          this.resourcesLoaded = dados;
          this.id = dados;
          console.log(this.id)
          this.puxasolicitante(); }  } ); }
  
    puxasolicitante(){
      this.service.gsolicitante({ID: this.id })
      .subscribe(dados => {
        if (dados) {
          console.log(dados)
          this.dadosolicitante = dados;
          this.resourcesLoaded = 'false';
          this.atualizanomes();
          console.log(this.dadosolicitante)

        }
        })}

  atualizanomes(){
    this.nome = this.dadosolicitante['nomeparceiro1'];
    this.cpf = this.dadosolicitante['cnpjparceiro1'];
    this.rua = this.dadosolicitante['ruaparceiro'];
    this.bairro = this.dadosolicitante['bairroparceiro'];
    this.numero = this.dadosolicitante['numruaparceiro'];
    this.cidade = this.dadosolicitante['cidadeparceiro'];
    this.estado = this.dadosolicitante['ufparceiro'];
    this.cep = this.dadosolicitante['cepparceiro'];
    this.email = this.dadosolicitante['emailparceiro'];
    this.telefone = this.dadosolicitante['celparceiro'];
    this.complemento = this.dadosolicitante['complemento'];
    this.cpflegal= this.dadosolicitante['cpflegal'];
    this.replegal = this.dadosolicitante['replegal'];
    this.rglegal= this.dadosolicitante['rglegal'];
    this.sp = false;
  }
  showToast2(status: NbComponentStatus) {
    this.toastrService.show(status, `Sucesso: Status atualizado com sucesso!`, { status });
  }
  
  upacliente() {
    this.service.updateparceiro2({
      token:localStorage.getItem('tokenx'),
      nome: this.nome,
      cnpj: this.cpf,
      endereco: this.rua,
      cidade: this.cidade,
      uf: this.estado,
      bairro: this.bairro,
      complemento: this.complemento,
      cep: this.cep,
      idempresa: this.id,
      numruaparceiro: this.numero,
      email: this.email,
      celparceiro: this.telefone,
      cpflegal: this.cpflegal,
      replegal :  this.replegal,
      rglegal: this.rglegal
    })
      .subscribe((data) => {
        if (data['status'] === 'atualizado') {
          this.valid = data;
          this.showToast2('success');
          this.puxasolicitante();
        }
      });
  }
  
  upasenha() {
    this.service.upasenha({
      token:localStorage.getItem('tokenx'),
      senha: this.senha2,
      idempresa: this.id,
    })
      .subscribe((data) => {
        if (data['status'] === 'atualizado') {
          this.valid = data;
          console.log(this.valid);
          this.showToast2('success');
          this.puxasolicitante();
        }
      });
  }
  
  }
