<div *ngIf="display === true">
    <div>
        <div class="row">
            <div class="chatbox chatbox22 chatbox--tray">
                <div class="chatbox__title">
                    <h5 style="color: white; font-size: smaller">
                        {{ meunome }} - {{ homolselec }}
                    </h5>
                    <!--<button class="chatbox__title__tray">
          <span></span>
      </button>-->
                    <button (click)="displayx();scroler()" class="chatbox__title__close">
            <span>
              <svg viewBox="0 0 12 12" width="12px" height="12px">
                <line
                  stroke="#FFFFFF"
                  x1="11.75"
                  y1="0.25"
                  x2="0.25"
                  y2="11.75"
                ></line>
                <line
                  stroke="#FFFFFF"
                  x1="11.75"
                  y1="11.75"
                  x2="0.25"
                  y2="0.25"
                ></line>
              </svg>
            </span>
          </button>
                </div>
                <div #scrollMe style="overflow: auto !important; height: 100%;" class="chatbox__body">
                    <div *ngFor="let msg of comentarios; let i = index">
                        <div *ngIf="id !== msg.idono" class="outgoing_msg">
                            <div class="received_withd_msg">
                                <p>{{ msg.msg }}</p>
                                <span class="time_date"> {{ msg.data }}</span>
                            </div>
                        </div>
                        <div *ngIf="id === msg.idono" class="outgoing_msg">
                            <div class="sent_msg">
                                <p>{{ msg.msg }}</p>
                                <span class="time_date"> {{ msg.data }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="background-color: transparent !important;border-top-color: cornflowerblue;" class="panel-footer">
                    <div class="input-group">
                        <textarea  class="clatextarea" [(ngModel)]="commenta" style="text-indent:10px;background-color: #fafffe !important;border-bottom: none;border-right: none;border-top-color: cornflowerblue; border-left-color: cornflowerblue;" rows="2" cols="30" pInputTextarea ></textarea>
                        <div  style="background-color: #fafffe !important;" class="input-group-append">
                            <span  (click)="sendMessage()" style="background-color: #fafffe !important;border-top-color: cornflowerblue; " class="input-group-text send_btn">
                                <i class="fas fa-location-arrow iconHeartActive"></i></span>
                        </div>
                    </div>                       
                </div>
            </div>
        </div>
    </div>
</div>
<div style="margin-right: 0.5rem;margin-left: 0.5rem;margin-top: 1rem !important;">
<!-- BOÕES CONTROLE -->
<div class="espaco"></div>
<!-- BOÕES CONTROLE -->
<nb-card *ngIf="menu5 === true" style="margin-top: 1rem !important; border: 1px solid rgb(171, 171, 255)" status="success">
    <nb-card-header style="background-color: white; color: black; padding: 0rem !important">
        <p-menubar [model]="itemsx">
            <ng-template pTemplate="start"> </ng-template>
        </p-menubar>
    </nb-card-header>
    <nb-card-body>
        <div>
            <p style="font-size: 15px; text-align:justify;">
                <strong>Atenção :</strong> Você está prestes a solicitar a vistoria para o cliente <strong>{{filtredhomol.nome}}</strong> . É importante que você tenha seguido todas as recomendações e exigências do projeto enviado pela equipe de engenharia.
                Tenha fotografias nítidas dos componentes do seu sistema para preparação do documento de validação de sua solicitação. Esse procedimento é importante para que possamos garantir qualidade em nossos projetos.</p>
        </div>
        <h6 style="margin-top: 10px;">O que devo enviar?</h6>
        <div style="margin-left: 20px; margin-top:10px">
            <ul>
                <li>Foto nítida e ampla dos módulos fixados</li>
                <li>Foto nítida dos módulos aterrados</li>
                <li>Foto nítida do cabeamento em eletroduto, eletrocalha ou perfilado</li>
                <li>Foto nítida da fixação da proteção CC, CA e inversores</li>
                <li>Foto nítida da segregação da alimentação CC e CA</li>
                <li>Foto nítida das ligações internas da(s) StringBox</li>
                <li>Foto nítida das ligações internas do(s) quadro(s) CA</li>
                <li>Foto nítida das ligações do(s) Inversores</li>
                <li>Foto nítida das medições de corrente e tensão em cada string</li>
                <li>Foto nítida do aterramento do sistema</li>
                <li>Foto nítida dos instaladores com EPI's e EPC's utilizados</li>
    
            </ul>
        </div>
        <button style="width: 100% !important; margin-bottom: 10px;" (click)="solicitarvisto()" nbButton>
            SOLICITAR
          </button>
        <div class="sample-container">
            <ejs-filemanager [maxFileSize]="maxFileSize" [toolbarSettings]='toolbarSettings' [height]='"80vh"'  (beforeSend)="OnbeforeSend($event)"  (fileOpen)="fileOpen($event)"
            [rootAliasName]="homolselec" (toolbarClick)="toolbarClicks($event)" id='filemanager' [ajaxSettings]='ajaxSettings'>
            </ejs-filemanager>
          </div>
    </nb-card-body>
</nb-card>

<div *ngIf="abrehomologacao === 'false' && menu5 === false">
    <div class="surface-card shadow-4 border-round">
        <div class="flex align-items-center justify-content-between mb-4">
            <ngx-charts-advanced-pie-chart [view]="view" [scheme]="colorScheme" [results]="single" [gradient]="gradient">
            </ngx-charts-advanced-pie-chart>
        </div>
    </div>
    

    <!-- TABELA HOMOLOGACOES -->
    <div style="margin-bottom: 5px;" *ngIf="renderizou === true" class="control-section p-shadow-4">
        <ejs-grid   [allowReordering]='false' [gridLines]="'Horizontal'" (rowSelecting)="selectingEvent($event)" [allowSelection]='true' [selectionSettings]='selectOptionsx' id="Grid" allowPaging="true" [locale]="locas" (click)="eventa($event)" #overviewgrid id="overviewgrid" [dataSource]="data"
            rowHeight="38" [allowSorting]="true" (dataBound)="onDataBound($event); dataBound()" [enableHover]="true" [filterSettings]="filterSettings" [allowSorting]="true" [allowFiltering]="true" [enableVirtualization]="false" [toolbar]="toolbar" (toolbarClick)="toolbarClick($event)"
            [allowExcelExport]="true" [allowPdfExport]="true" (excelQueryCellInfo)="exportQueryCellInfo($event)" (pdfQueryCellInfo)="exportQueryCellInfo($event)">
            <e-columns>
                <e-column textAlign="Left" headerText="Cod" width="110px"
                field="codhomol" [filter]="filter"
                clipMode="EllipsisWithTooltip" width="120">
                <ng-template #template let-data>
                  <a (click)="filtrahomol(data.codhomol);caminhando = data.codhomol;homolselec=data.codhomol"
                    style="font-family: 'Oxygen', sans-serif;font-size: 11px !important;color: blue;cursor:
                    pointer;">{{
                    data.codhomol
                    }}</a>
                </ng-template>
              </e-column>
    
                <e-column field="Cliente" [visible]="true" headerText="Cliente" width="180" [isPrimaryKey]="false">
                    <ng-template #template let-data>
                        <span style="font-size:11px !important;font-family: 'Oxygen', sans-serif;" class="xc">{{data.nomersumido}}</span>
                    </ng-template>
                    </e-column>
                    <e-column textAlign='Left' headerText="Data Entrada" field="datani" format="yMd" clipMode="EllipsisWithTooltip" width="160">
                        <ng-template #template let-data>
                            <span style="font-size:11px !important;font-family: 'Oxygen', sans-serif;" class="xc">{{data.datani | date: 'dd/MM/yyyy'}}</span>
                        </ng-template>
                    </e-column>
                    <e-column textAlign='Left' headerText="Data Homologação" field="datafim" format="yMd" clipMode="EllipsisWithTooltip" width="190">
                        <ng-template #template let-data>
                            <span style="font-size:11px !important;font-family: 'Oxygen', sans-serif;" class="xc">{{data.datafim | date: 'dd/MM/yyyy'}}</span>
                        </ng-template>
                    </e-column>
                    <e-column headerText="Vistoria" width="110">
                        <ng-template style="padding: 0 !important;" #template let-data>
                            <button (click)=" homolselec=data.codhomol;menu5 = true;ajax();filtrahomol(data.codhomol);
                            menu4 = false;
                            menu3 = false;
                            menu2 = false;
                            menu1 = false;" style="font-size: 12px; width: 100% !important; height: 100% !important;" *ngIf="data.Status === 'Parecer: Aprovado' || data.Status === 'Vistoria: Nāo Solicitada'" pButton pRipple type="button" label="Solicitar" class="p-button"></button>
                        </ng-template>
                    </e-column>

                <e-column style="font-size:11px !important" field="pend" headerText="Pendência" textAlign='Left' [filter]="filter" clipMode="EllipsisWithTooltip" width="400px">
                </e-column>
                <e-column style="font-size:11px !important" field="Status" textAlign='Left' field="Status" [filter]="filter" clipMode="EllipsisWithTooltip" width="200">
                </e-column>              
            </e-columns>
        </ejs-grid>

        <ng-template style="height: 65vw" #didados let-data let-ref="dialogRef">
            <nb-card style="width: 65vw">
                <nb-card-body>
                    <p-card>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-2 p-lg-4">
                                <span>Status</span>
                                <select disabled style="font-size: smaller" (change)="filtredhomol.statusdados = $event.target.value" [value]="filtredhomol.statusdados" id="input disabledtipoentrada" class="form-control">
                  <option style="font-size: small">Selecione...</option>
                  <option style="font-size: small">Pendente</option>
                  <option style="font-size: small">Coletando dados</option>
                  <option style="font-size: small">Dados incompletos</option>
                  <option style="font-size: small">
                    Aguardando assinaturas
                  </option>
                  <option style="font-size: small">Concluído</option>
                </select>
                            </div>
                            <div class="p-col-12 p-md-6 p-lg-4">
                                <span>Data inicial</span>
                                <input disabled style="font-size: small" (change)="filtredhomol.time1 = $event.target.value" mask="00/00/0000" [(ngModel)]="filtredhomol.time1" type="text" class="form-control" id="time1" />
                            </div>

                            <div class="p-col-12 p-md-6 p-lg-4">
                                <span>Data Final</span>
                                <input disabled style="font-size: small" (change)="filtredhomol.time1f = $event.target.value" mask="00/00/0000" [(ngModel)]="filtredhomol.time1f" type="text" class="form-control" id="time1f" />
                            </div>
                        </div>

                        <p-divider align="left" type="dashed"> </p-divider>

                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-6 p-lg-4">
                                <span>Status</span>
                                <select disabled style="font-size: smaller" (change)="filtredhomol.statusprojeto = $event.target.value" [value]="filtredhomol.statusprojeto" id="input disabledtipoentrada" class="form-control">
                  <option style="font-size: small">Selecione...</option>
                  <option style="font-size: small">Pendente</option>
                  <option style="font-size: small">Elaborando projeto</option>
                  <option style="font-size: small">Alterando projeto</option>
                  <option style="font-size: small">Aguardando aprovação</option>
                  <option style="font-size: small">Concluído</option>
                </select>
                            </div>
                            <div class="p-col-12 p-md-6 p-lg-4">
                                <span>Data inicial</span>
                                <input disabled style="font-size: small" (change)="filtredhomol.time2 = $event.target.value" mask="00/00/0000" [(ngModel)]="filtredhomol.time2" type="text" class="form-control" id="time2" />
                            </div>

                            <div class="p-col-12 p-md-6 p-lg-4">
                                <span>Data inicial</span>
                                <input disabled style="font-size: small" (change)="filtredhomol.time2f = $event.target.value" mask="00/00/0000" [(ngModel)]="filtredhomol.time2f" type="text" class="form-control" id="time2f" />
                            </div>
                        </div>

                        <p-divider align="left" type="dashed"> </p-divider>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <span>Status</span>
                                <select disabled style="font-size: smaller" (change)="
                    filtredhomol.statushomologacao = $event.target.value
                  " [value]="filtredhomol.statushomologacao" id="input disabledtipoentrada" class="form-control">
                  <option style="font-size: small">Selecione...</option>
                  <option style="font-size: small">Pendente</option>
                  <option style="font-size: small">Aguardando parecer</option>
                  <option style="font-size: small">Em correção</option>
                  <option style="font-size: small">Parecer com obras</option>
                  <option style="font-size: small">Concluído</option>
                </select>
                            </div>
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <span>Data inicial</span>
                                <input disabled style="font-size: small" (change)="filtredhomol.time3 = $event.target.value" mask="00/00/0000" [(ngModel)]="filtredhomol.time3" type="text" class="form-control" id="time3" />
                            </div>

                            <div class="p-col-12 p-md-3 p-lg-3">
                                <span>Data Final</span>
                                <input disabled style="font-size: small" (change)="filtredhomol.time3f = $event.target.value" mask="00/00/0000" [(ngModel)]="filtredhomol.time3f" type="text" class="form-control" id="time3f" />
                            </div>
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <span>Protocolo</span>
                                <input disabled style="font-size: small" [(ngModel)]="filtredhomol.protocoloparecer" type="text" class="form-control" />
                            </div>
                        </div>
                        <p-divider align="left" type="dashed"> </p-divider>

                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <span>Status</span>
                                <select disabled style="font-size: smaller" (change)="filtredhomol.statusvistoria = $event.target.value" [value]="filtredhomol.statusvistoria" id="input disabledtipoentrada" class="form-control">
                  <option style="font-size: small">Selecione...</option>
                  <option style="font-size: small">Pendente</option>
                  <option style="font-size: small">Aguardando vistoria</option>
                  <option style="font-size: small">Vistoria Solicitada</option>
                  <option style="font-size: small">Reprovada</option>
                  <option style="font-size: small">
                    Aguardando troca medidor
                  </option>
                  <option style="font-size: small">Concluído</option>
                </select>
                            </div>
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <span>Data inicial</span>
                                <input disabled style="font-size: small" (change)="filtredhomol.time4 = $event.target.value" mask="00/00/0000" [(ngModel)]="filtredhomol.time4" type="text" class="form-control" id="time4" />
                            </div>

                            <div class="p-col-12 p-md-3 p-lg-3">
                                <span>Data Final</span>
                                <input disabled style="font-size: small" (change)="filtredhomol.time4f = $event.target.value" mask="00/00/0000" [(ngModel)]="filtredhomol.time4f" type="text" class="form-control" id="time4f" />
                            </div>
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <span>Protocolo</span>
                                <input disabled style="font-size: small" type="text" [(ngModel)]="filtredhomol.protocolovistoria" class="form-control" />
                            </div>
                        </div>

                        <p-divider></p-divider>
                    </p-card>
                </nb-card-body>
                <nb-card-footer>
                    <button style="margin-right: 30px" nbButton (click)="ref.close()">
            Fechar
          </button>
                    <button nbButton (click)="atulizoustatus()">Atualizar</button>
                </nb-card-footer>
            </nb-card>
        </ng-template>

        <ng-template style="margin-top: 50px !important" #didadox let-data let-ref="dialogRef">
            <nb-card style="
          width: 70vw !important;
          min-height: 90% !important;
          max-height: 95% !important;
        ">
                <nb-card-body *ngIf="filtredhomol.managerx === 'true'">
                    <nz-result nzStatus="success" nzTitle="Tarefas já Cadastradas!" nzSubTitle="Todas as tarefas desse projeto já foram atribuídas a alguem">
                        <div nz-result-extra>
                            <button nbButton (click)="ref.close()">Fechar</button>
                        </div>
                    </nz-result>
                </nb-card-body>
                <nb-card-body *ngIf="filtredhomol.managerx !== 'true'">
                    <div class="form-group p-field p-col">
                        <label for="codigo">Selecione um Responsável para essa tarefa:
            </label>
                        <select disabled style="font-size: smaller" (change)="responsavex = $event.target.value" [value]="responsavex" id="input disabledtipoentrada" class="form-control">
              <option style="font-size: small">Selecione...</option>
              <option style="font-size: small">Erick Peixoto</option>
              <option style="font-size: small">Francine Pereira</option>
              <option style="font-size: small">Iago Oliveira</option>
              <option style="font-size: small">Robson Resena</option>
              <option style="font-size: small">Jonnathan Lopes</option>
            </select>
                    </div>
                    <p-pickList [source]="productTeam1" [target]="tasklist" sourceHeader="Tarefas" targetHeader="Atribuídas" dragdrop="true" [responsive]="true" [sourceStyle]="{ height: '200px' }" [targetStyle]="{ height: '200px' }" filterBy="title" sourceFilterPlaceholder="Buscar"
                        targetFilterPlaceholder="Buscar">
                        <ng-template let-product pTemplate="item">
                            <div class="product-item">
                                <div class="product-list-detail">
                                    <span style="font-size: small" class="p-mb-2"><b>{{ product.title }}</b></span
                  >
                  <br />
                  <span style="font-size: small" class="product-category">{{
                    product.description
                  }}</span>
                                </div>
                                <div class="product-list-action"></div>
                            </div>
                        </ng-template>
                    </p-pickList>

                    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fall" [fullScreen]="true">
                        <p style="color: white">Aguarde ...</p>
                    </ngx-spinner>
                </nb-card-body>
                <nb-card-footer *ngIf="filtredhomol.managerx !== 'true'">
                    <button style="margin-right: 30px !important" nbButton (click)="ref.close()">
            Fechar
          </button>
                    <button tyle="color:
          black;background-color: orange ;" *ngIf="responsavex !== 'Selecione...'" nbButton (click)="atribuir()">
            Atribuir
          </button>
                </nb-card-footer>
            </nb-card>
        </ng-template>
    </div>

    <!-- TABELA HOMOLOGACOES-->
</div>

<div *ngIf="abrehomologacao === 'true' && menu5 === false">
    <nb-card style="margin-top: 1rem !important; border: 1px solid rgb(171, 171, 255)" status="success">
        <nb-card-header style="background-color: white; color: black; padding: 0rem !important">
            <p-menubar [model]="itemsx">
                <ng-template pTemplate="start"> </ng-template>
            </p-menubar>
        </nb-card-header>
        <nb-card-body *ngIf="menu1 === true">
            <div class="form-row">
                <div class="form-group col-5">
                    <ejs-textbox readonly placeholder="Nome" cssClass="e-outline" [(ngModel)]="filtredhomol.nome" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-3">
                    <ejs-textbox readonly placeholder="CPF/CNPJ" cssClass="e-outline" [(ngModel)]="filtredhomol.cpf" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-4">
                    <ejs-textbox readonly placeholder="Código homologaçāo" cssClass="e-outline" [(ngModel)]="filtredhomol.codhomol" floatLabelType="Always"></ejs-textbox>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-5">
                    <ejs-textbox readonly placeholder="Cidade" cssClass="e-outline" [(ngModel)]="filtredhomol.cidade" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-3">
                    <ejs-textbox readonly placeholder="Estado" cssClass="e-outline" [(ngModel)]="filtredhomol.uf" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox readonly placeholder="Latitude" cssClass="e-outline" [(ngModel)]="filtredhomol.longitude" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox readonly placeholder="Longitude" cssClass="e-outline" [(ngModel)]="filtredhomol.latitude" floatLabelType="Always"></ejs-textbox>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-5">
                    <ejs-textbox readonly placeholder="Rua" cssClass="e-outline" [(ngModel)]="filtredhomol.endereco" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-3">
                    <ejs-textbox readonly placeholder="Bairro" cssClass="e-outline" [(ngModel)]="filtredhomol.bairro" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox readonly placeholder="Número" cssClass="e-outline" [(ngModel)]="filtredhomol.numerocliente" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox readonly placeholder="Cep" cssClass="e-outline" [(ngModel)]="filtredhomol.cep" floatLabelType="Always"></ejs-textbox>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-3">
                    <ejs-textbox readonly placeholder="Categoria" cssClass="e-outline" [(ngModel)]="filtredhomol.classe" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox readonly placeholder="Classe de tensão" cssClass="e-outline" [(ngModel)]="filtredhomol.classetensao" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-3">
                    <ejs-textbox readonly placeholder="Distância  placa-inversor" cssClass="e-outline" [(ngModel)]="filtredhomol.distanciaplacaquadro" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-4">
                    <ejs-textbox readonly placeholder="Distância Inversor ao QDC" cssClass="e-outline" [(ngModel)]="filtredhomol.distanciainversorquadro" floatLabelType="Always"></ejs-textbox>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-3">
                    <ejs-textbox readonly placeholder="Padrão de entrada" cssClass="e-outline" [(ngModel)]="filtredhomol.padraoentrada" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox readonly placeholder="Disjuntor Geral" cssClass="e-outline" [(ngModel)]="filtredhomol.digeral" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-3">
                    <ejs-textbox readonly placeholder="Ramal" cssClass="e-outline" [(ngModel)]="filtredhomol.entradaareasub" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox readonly readonly placeholder="Código Cliente" cssClass="e-outline" [(ngModel)]="filtredhomol.numclientecemig" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox readonly readonly placeholder="UC" cssClass="e-outline" [(ngModel)]="filtredhomol.numeroinstalacao" floatLabelType="Always"></ejs-textbox>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-3">
                    <ejs-textbox readonly placeholder="Tipo telhado" cssClass="e-outline" [(ngModel)]="filtredhomol.tipotelhado" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox readonly placeholder="Local instalação" cssClass="e-outline" [(ngModel)]="filtredhomol.localinst" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-3">
                    <ejs-textbox readonly placeholder="Concessionária" cssClass="e-outline" [(ngModel)]="filtredhomol.aviso" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox readonly placeholder="Aumento de carga" cssClass="e-outline" [(ngModel)]="filtredhomol.disjuntoraumentocarga" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox readonly placeholder="Carga instalada" cssClass="e-outline" [(ngModel)]="filtredhomol.cargainstalada" floatLabelType="Always"></ejs-textbox>
                </div>
            </div>

            <form>
                <div>
                    <div *ngFor="
              let item of configurcao;
              let u = index;
              let pointIndex = index;
              let lastindex = pointIndex
            ">
                        <div *ngIf="configurcao[u].qtdplaca !== null">
                            <div class="espaco"></div>
                            <h6 class="subheader">
                                <strong>Configuração Nº{{ pointIndex + 1 }}: </strong>
                            </h6>
                            <div class="espaco"></div>
                            <div class="form-row">
                                <div class="form-group col-3">
                                    <ejs-textbox readonly placeholder="Quantidade de Módulos" cssClass="e-outline" [value]="configurcao[u].qtdplaca" id="input disabledqtdmodulos+{{ pointIndex + 1 }}" floatLabelType="Always"></ejs-textbox>
                                </div>
                                <div class="form-group col-3">
                                    <ejs-textbox readonly placeholder="Marca dos módulos" cssClass="e-outline" [value]="configurcao[u].marcaplaca" floatLabelType="Always"></ejs-textbox>
                                </div>
                                <div class="form-group col-3">
                                    <ejs-textbox readonly placeholder="Modelo dos módulos" cssClass="e-outline" [value]="configurcao[u].modeloplaca" floatLabelType="Always"></ejs-textbox>
                                </div>
                                <div class="form-group col-3">
                                    <ejs-textbox readonly placeholder="Potência total dos Módulos" cssClass="e-outline" [value]="configurcao[u].potplaca" floatLabelType="Always"></ejs-textbox>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-3">
                                    <ejs-textbox [matKeyboard]="'Azərbaycanca'" readonly placeholder="Quantidade de Inversor" cssClass="e-outline" [value]="configurcao[u].qtdinv" id="input disabledqtdmodulos+{{ pointIndex + 1 }}" floatLabelType="Always"></ejs-textbox>
                                </div>
                                <div class="form-group col-3">
                                    <ejs-textbox readonly placeholder="Marca do Inversor" cssClass="e-outline" [value]="configurcao[u].marcainv" floatLabelType="Always"></ejs-textbox>
                                </div>
                                <div class="form-group col-3">
                                    <ejs-textbox readonly placeholder="Modelo do Inversor" cssClass="e-outline" [value]="configurcao[u].modeloinv" floatLabelType="Always"></ejs-textbox>
                                </div>
                                <div class="form-group col-3">
                                    <ejs-textbox readonly placeholder="Potência Total Inversor" cssClass="e-outline" [value]="configurcao[u].potinv" floatLabelType="Always"></ejs-textbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="comment">Outras informaçōes:</label>
                        <div class="input disabled-group">
                            <textarea style="
                  font-size: 13px;
                  width: 100%;
                  padding-left: 20px;
                  padding-top: 10px;
                " rows="7" (change)="filtredhomol.descricao = $event.target.value" [value]="filtredhomol.descricao" id="configuração"></textarea>
                        </div>
                    </div>
                </div>
                <div class="simple-keyboard"></div>

            </form>
        </nb-card-body>
        <nb-card-footer *ngIf="menu1 === true" style="background-color: rgb(156, 206, 247); color: black">
   <span>Para atualizar algum dado procure a equipe sollux</span>
        </nb-card-footer>
        <nb-card-body *ngIf="menu2 === true" tyle="background-color: white !important;">
            <!-- TIMELINE -->
            <div *ngIf="kabanstats !== 'Homologado'">
                <ejs-kanban *ngIf="kabanstats !== 'Parada'" locale="pt-BR" #kanbanObj cssClass="kanban-overview" keyField="Tags" [dataSource]="kanbanData" [cardSettings]="cardSettings" [allowDragAndDrop]="allowDragAndDrop">
                    <e-columns>
                        <e-column *ngFor="let column of columns" headerText="{{ column.headerText }}" keyField="{{ column.keyField }}">
                            <ng-template #template let-data>
                                <div class="header-template-wrap">
                                    <div class="header-icon e-icons {{ data.keyField }}"></div>
                                    <div class="header-text">{{ data.headerText }}</div>
                                </div>
                            </ng-template>
                        </e-column>
                    </e-columns>
                    <ng-template #cardSettingsTemplate let-data>
                        <div *ngIf="data.Tags === 'dados'" class="card-template">
                            <div class="e-card-header"></div>
                            <div class="e-card-content e-tooltip-text">
                                <span class="subtitle-2">Status:</span>
                            </div>
                            <div class="e-card-custom-footer">
                                <span style="color: blue" class="caption-2 text-hint">{{
                  data.statusdados
                }}</span>
                            </div>
                            <div class="e-card-content e-tooltip-text">
                                <span style="color: rgb(70, 70, 82)" class="caption-2 text-hint">Data inicio &nbsp; &nbsp; &nbsp; &nbsp; Prazo</span
                >
              </div>
              <div class="e-card-custom-footer">
                <span style="color: rgb(23, 85, 23)" class="caption-2 text-hint"
                  >{{ data.data1dados }} &nbsp; &nbsp; &nbsp; &nbsp;
                  {{ data.data2dados }}
                </span>
                            </div>
                        </div>

                        <div *ngIf="data.Tags === 'Projeto'" class="card-template">
                            <div class="e-card-header"></div>
                            <div class="e-card-content e-tooltip-text">
                                <span class="subtitle-2">Status:</span>
                            </div>
                            <div class="e-card-custom-footer">
                                <span style="color: blue" class="caption-2 text-hint">{{
                  data.statusproj
                }}</span>
                            </div>
                            <div class="e-card-content e-tooltip-text">
                                <span style="color: rgb(70, 70, 82)" class="caption-2 text-hint">Data inicio &nbsp; &nbsp; &nbsp; &nbsp; Prazo</span
                >
              </div>
              <div class="e-card-custom-footer">
                <span style="color: rgb(23, 85, 23)" class="caption-2 text-hint"
                  >{{ data.data1proj }} &nbsp; &nbsp; &nbsp; &nbsp;
                  {{ data.data2proj }}
                </span>
                            </div>
                            <div *ngIf="data.statusdados !== 'Concluído'">
                                <div class="e-card-content e-tooltip-text">
                                    <div class="e-text">
                                        <div style="display: inline-block">
                                            <img src="assets/images/alertas.png" />
                                        </div>
                                        <div style="margin-top: -20px; margin-left: 30px !important">
                                            <span style="color: red" class="caption-2 text-hint">Pendências
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="e-card-custom-footer">
                                    <ul style="padding-left: 15px; padding-right: 5px">
                                        <li>
                                            <span style="color: blue; font-size: small" class="caption-1 text-hint">{{ data.statusdados }}
                      </span>
                                        </li>
                                    </ul>
                                </div>
                                <br />
                            </div>
                        </div>

                        <div *ngIf="data.Tags === 'Parecer'" class="card-template">
                            <div class="e-card-content e-tooltip-text">
                                <span class="subtitle-2">Status:</span>
                            </div>
                            <div class="e-card-custom-footer">
                                <span style="color: blue" class="caption-2 text-hint">{{
                  data.statusparecer
                }}</span>
                            </div>
                            <div class="e-card-content e-tooltip-text">
                                <span style="color: rgb(70, 70, 82)" class="caption-2 text-hint">Data inicio &nbsp; &nbsp; &nbsp; &nbsp; Previsāo</span
                >
              </div>
              <div class="e-card-custom-footer">
                <span style="color: rgb(23, 85, 23)" class="caption-2 text-hint"
                  >{{ data.data1parecer }} &nbsp; &nbsp; &nbsp; &nbsp;
                  {{ data.data2parecer }}
                </span>
                            </div>

                            <div *ngIf="data.protocoloparecer !== null">
                                <div class="e-card-content e-tooltip-text">
                                    <div class="e-text">
                                        <div style="display: inline-block; width: 20px">
                                            <img src="assets/images/alertas.png" />
                                        </div>
                                        <div style="margin-top: -20px; margin-left: 30px !important">
                                            <b>Protocolo</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="e-card-custom-footer">
                                    <div style="color: red; font-size: small; margin-left: 0px" class="e-card-tag-field e-tooltip-text">
                                        <b>{{ data.prococoloparecer }}</b>
                                    </div>
                                </div>
                                <div *ngIf="data.statusparecer === 'Aprovado'" class='e-card-custom-footer'>
                                    <div style="color: blue;font-size: xx-small; margin-left: 0px; font-weight: bold;" class='e-card-tag-field e-tooltip-text'><b>Aguardando solicitaçāo de vistoria</b></div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="data.Tags === 'Vistoria'" class="card-template">
                            <div class="e-card-content e-tooltip-text">
                                <span class="subtitle-2">Status:</span>
                            </div>
                            <div class="e-card-custom-footer">
                                <span style="color: blue" class="caption-2 text-hint">{{
                  data.statusvisto
                }}</span>
                            </div>
                            <div class="e-card-content e-tooltip-text">
                                <span style="color: rgb(70, 70, 82)" class="caption-2 text-hint">Data inicio &nbsp; &nbsp; &nbsp; &nbsp; Previsāo</span
                >
              </div>
              <div class="e-card-custom-footer">
                <span style="color: rgb(23, 85, 23)" class="caption-2 text-hint"
                  >{{ data.data1visto }} &nbsp; &nbsp; &nbsp; &nbsp;
                  {{ data.data2visto }}
                </span>
                            </div>
                            <div *ngIf="data.protocoloparecer !== null">
                                <div class="e-card-content e-tooltip-text">
                                    <div class="e-text">
                                        <div style="display: inline-block; width: 20px">
                                            <img src="assets/images/alertas.png" />
                                        </div>
                                        <div style="margin-top: -20px; margin-left: 30px !important">
                                            <b>Protocolo</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="e-card-custom-footer">
                                    <div style="color: red; font-size: small" class="e-card-tag-field e-tooltip-text">
                                        <b>{{ data.prococolovistoria }}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ejs-kanban>
            </div>
            <nz-result *ngIf="kabanstats === 'Homologado'" nzStatus="success" nzTitle="Seu projeto foi homologado junto a concessionária local" nzSubTitle="Obrigado pela parceria com a Sollux Engenharia">
            </nz-result>
            <nz-result *ngIf="kabanstats === 'Parada'" nzStatus="info" nzTitle="Seu projeto está parado pelo seguinte motivo" [nzSubTitle]="filtredhomol.statusparada">
            </nz-result>
            <div class="control-section">
                <div class="control-wrapper">
                    <div class="default-section">
                        <div *ngIf="forum" class='forum'> 
                            <div class="answerSection">
                                <div class="answer">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td rowspan="2">
                                                    <div class="logos"> </div>
                                                </td>
                                                <td>
                                                    <div class="authorname"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="detailsAnswer"></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="posting">
                                        
                                    </div>
            
                                    <div class="separator"></div>
                                </div>
                            </div>

            
                            <div id="createpostholder">
                                <form novalidate="novalidate">
                                    <ejs-richtexteditor [toolbarSettings]='tools' [ImageUploadingEventArgs]="ImageUploadingEventArgs" (filesData)="filesData($event)" [ImageSettingsModel]='insertImageSettings' id='blogRTE' #blogRTE placeholder='Deixe seu comentário'>
                                    </ejs-richtexteditor>
                                    <div id='buttonSection'>
                                        <table>
                                            <tr>
                                                <td>
                                                    <button ejs-button #rteCancel (click)="cancelClick()" id="rteCancel">Cancelar</button>
                                                </td>
                                                <td>
                                                    <button ejs-button [isPrimary]="true" (click)="submitClick()" #rteSubmit id="rteSubmit">Enviar</button>
            
                                                </td>
            
                                            </tr>
                                        </table>
                                    </div>
            
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer *ngIf="menu2 === true" style="background-color: white; color: white">
        </nb-card-footer>
        <nb-card-body *ngIf="menu3 === true"> 
            <p-dialog [style]="{width: '50vw'}" [modal]="true" header="Atençāo" [visible]="filess">
                <nz-result nzStatus="warning" nzTitle="Não é mais possível acessar arquivos de projetos homologados, conforme LGPD esses arquivos não são mais necessários. Caso necessite de algum arquivo entre em contato com o Suporte Técnico">
                </nz-result>
                <div nz-result-extra>
                    <button nbButton (click)="menumuda(1)">Fechar</button>
                </div>
            </p-dialog>           
                <div *ngIf="!filess" class="sample-container">
                    <ejs-filemanager [maxFileSize]="maxFileSize" [toolbarSettings]='toolbarSettings' [height]='"80vh"'  (beforeSend)="OnbeforeSend($event)"  (fileOpen)="fileOpen($event)"
                    [rootAliasName]="homolselec" (toolbarClick)="toolbarClicks($event)" id='filemanager' [ajaxSettings]='ajaxSettings'>
                    </ejs-filemanager>

                  </div>
        </nb-card-body>
        <nb-card-body *ngIf="menu4 === true">
            <div class="form-row">
                <div class="form-group col-4">
                    <ejs-textbox readonly placeholder="Titular" cssClass="e-outline" [(ngModel)]="filtredhomol.nome" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-3">
                    <ejs-textbox readonly placeholder="UC Geradora" cssClass="e-outline" [(ngModel)]="filtredhomol.numeroinstalacao" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-3">
                    <ejs-textbox placeholder="Geraçāo média (kWh/mês)" cssClass="e-outline" [(ngModel)]="filtredhomol.mediage" floatLabelType="Always"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox placeholder="Excedente (kWh/mês)" cssClass="e-outline" [(ngModel)]="filtredhomol.excedente" floatLabelType="Always"></ejs-textbox>
                </div>
            </div>
            <p-divider align="right">
                <div class="p-d-inline-flex p-ai-center">
                    <button (click)="adicionarateio()" style="width: 20px !important; height: 20px;font-size: x-small;" pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded"></button>
                    <button (click)="removerateio()" style="width: 20px !important; height: 20px;font-size: x-small;background-color: red;" pButton pRipple type="button" icon="pi pi-minus" class="p-button-rounded"></button>

                </div>
            </p-divider>

            <div class="control-section">
                <div *ngFor="let item of datarateio" style="margin-top: 0px" class="form-row">
                    <div class="form-group col-4">
                        <ejs-textbox decimals="10" format="###.######"
                            min="0"
                            placeholder="Endereço"
                            cssClass="e-outline" [(ngModel)]="item.endereco"
                            floatLabelType="Auto"></ejs-textbox>
                    </div>
                    <div class="form-group col-3">
                        <ejs-numerictextbox decimals="10" format="###.######"
                            placeholder="Nº da UC"
                            cssClass="e-outline" [(ngModel)]="item.uc"
                            floatLabelType="Auto"></ejs-numerictextbox>
                    </div>
                    <div class="form-group col-2">
                        <ejs-numerictextbox decimals="10" format="###.######"
                            placeholder="Percentual" 
                            cssClass="e-outline" [(ngModel)]="item.percentual"
                            floatLabelType="Aucto"></ejs-numerictextbox>
                    </div>
                    <div class="form-group col-2">
                        <ejs-numerictextbox decimals="10" format="###.######"
                            placeholder="kWh/mês" readonly
                            cssClass="e-outline" [value]="(item.percentual/100)*filtredhomol.excedente"
                            floatLabelType="Aucto"></ejs-numerictextbox>
                    </div>
                    <div class="form-group col-1">
                        <p-button (click)="deletarateio(item.idx,item.idempresa)" icon="pi pi-trash"></p-button>

                    </div>

                </div>
             
            </div>
            <button style="width: 100% !important" (click)="uparatex(); upahomol()" nbButton>
        ATUALIZAR
      </button>
        </nb-card-body>
    </nb-card>

    <!-- DIALOGOS -->

    <ng-template #dialog6 let-data let-ref="dialogRef">
        <nb-card style="width: 500px">
            <nb-card-header>Mudar Status</nb-card-header>
            <nb-card-body>
                <form>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <mat-form-field style="width: 100%">
                                <input disabled (change)="
                    todastarefas[idstatustar].descricao = $event.target.value
                  " [value]="todastarefas[idstatustar].descricao" type="text" matinput disabled placeholder="Descrição da tarefa" />
                            </mat-form-field>
                        </div>
                    </div>
                </form>

                <form>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <mat-form-field style="width: 120px; margin-right: 20px">
                                <input disabled (dateChange)="
                    todastarefas[idstatustar].datainicio =
                      $event.target.value['_i']['date'] +
                      '/' +
                      ($event.target.value['_i']['month'] + 1) +
                      '/' +
                      $event.target.value['_i']['year']
                  " matinput disabled [matDatepicker]="picker30" placeholder="Data inicio" />
                                <mat-datepicker-toggle matSuffix [for]="picker30"></mat-datepicker-toggle>
                                <mat-datepicker #picker30></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-4">
                            <mat-form-field style="width: 200px">
                                <input disabled [value]="todastarefas[idstatustar].datafim" (dateChange)="
                    todastarefas[idstatustar].datafim =
                      $event.target.value['_i']['date'] +
                      '/' +
                      ($event.target.value['_i']['month'] + 1) +
                      '/' +
                      $event.target.value['_i']['year']
                  " matinput disabled [matDatepicker]="picker20" placeholder="Previsão de
                  Término" />
                                <mat-datepicker-toggle matSuffix [for]="picker20"></mat-datepicker-toggle>
                                <mat-datepicker #picker20></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div style="margin-top: 10px" class="form-row">
                        <label for="statusprops">Status da tarefa</label>
                        <select disabled (change)="todastarefas[idstatustar].status = $event.target.value" id="statusprops1" class="form-control">
              <option selected>Selecione...</option>
              <option *ngFor="let stat of droptarefa" [value]="stat.value">
                {{ stat.viewValue }}
              </option>
            </select>
                    </div>
                </form>
            </nb-card-body>
            <nb-card-footer>
                <div style="margin-top: -20px" class="modal-footer">
                    <button nbButton (click)="ref.close()">Cancelar</button>
                    <button style="background-color: #0a147c" nbButton (click)="ref.close(); upatarefa()">
            Atualizar
          </button>
                </div>
            </nb-card-footer>
        </nb-card>
    </ng-template>

    <ng-template #notifica let-data let-ref="dialogRef">
        <nb-card style="width: 900px">
            <nb-card-header>Notifica parceiro</nb-card-header>
            <nb-card-body>
                <form>
                    <div class="form-row">
                        <h6>{{ msg0 + msg1 }}</h6>
                    </div>
                </form>

                <form>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <mat-form-field style="width: 100%">
                                <input disabled (change)="msg1 = $event.target.value" type="text" matinput disabled placeholder="Texto da notificação" />
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </nb-card-body>
            <nb-card-footer>
                <div style="margin-top: -20px" class="modal-footer">
                    <button nbButton (click)="ref.close()">Cancelar</button>
                    <button style="background-color: #0a147c; color: white" nbButton (click)="enviamsg2(); ref.close()">
            Enviar
          </button>
                </div>
            </nb-card-footer>
        </nb-card>
    </ng-template>

    <ng-template #notifica2 let-data let-ref="dialogRef">
        <nb-card style="width: 900px">
            <nb-card-header>Notifica parceiro</nb-card-header>
            <nb-card-body>
                <form>
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <mat-form-field>
                                <input disabled (change)="titulo = $event.target.value" type="text" matinput disabled placeholder="Título da notificação" />
                            </mat-form-field>
                        </div>
                    </div>
                </form>

                <form>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <mat-form-field style="width: 100%">
                                <input disabled (change)="msg = $event.target.value" type="text" matinput disabled placeholder="Texto da notificação" />
                            </mat-form-field>
                        </div>
                    </div>
                </form>

                <table style="margin-top: 10px" class="table table-striped">
                    <thead>
                        <tr>
                            <th style="width: 20px" scope="col">#</th>
                            <th style="width: 200px" scope="col">Título</th>
                            <th style="width: auto !important" scope="col">Notificação</th>
                            <th style="width: 150px" scope="col">Data</th>
                            <th style="width: 40px" scope="col">Lida</th>
                            <th style="width: 40px" scope="col">Deletar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let tarefa of xnotificacoes; let i = index">
                            <th scope="row">{{ i + 1 }}</th>
                            <td>{{ tarefa.titulo }}</td>
                            <td>{{ tarefa.msg }}</td>
                            <td>{{ tarefa.data }}</td>
                            <td class="text-center">
                                <span *ngIf="tarefa.lido === '1'">
                  <mat-icon>check</mat-icon>
                </span>
                                <span *ngIf="tarefa.lido === '0'">
                  <mat-icon>close</mat-icon>
                </span>
                            </td>
                            <td (click)="delenot(tarefa.ID)" style="text-align: center">
                                <i class="fas fa-trash"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </nb-card-body>
            <nb-card-footer>
                <div style="margin-top: -20px" class="modal-footer">
                    <button nbButton (click)="ref.close()">Cancelar</button>
                    <button style="background-color: #0a147c" nbButton (click)="inserenotifi()">
            Enviar
          </button>
                </div>
            </nb-card-footer>
        </nb-card>
    </ng-template>

    <p-dialog header="Integração Omie" [(visible)]="integraomie">
        <div *ngIf="!intgradoomie">
            <nz-result nzStatus="info" nzTitle="Parceiro não cadastrado!" nzSubTitle="Não há integração com o Omie">
                <div nz-result-extra>
                    <button nbButton (click)="sair()">Fechar</button>
                </div>
            </nz-result>
        </div>
        <div *ngIf="filtredhomol.art === 'true' && intgradoomie">
            <nz-result nzStatus="success" nzTitle="Integração já realizada!" nzSubTitle="Essa integração já foi realizada">
                <div nz-result-extra>
                    <button nbButton (click)="sair()">Fechar</button>
                </div>
            </nz-result>
        </div>
        <div *ngIf="filtredhomol.art !== 'true' && intgradoomie">
            <div class="p-field row">
                <div class="col-md-12">
                    <select disabled (change)="codconta = $event.target.value" style="font-size: smaller" id="ressp" class="form-control">
            <option style="font-size: small" selected>Selecione...</option>
            <option
              style="font-size: small"
              *ngFor="let cont of contas"
              [value]="cont.nCodCC"
            >
              {{ cont.descricao }}
            </option>
          </select>
                </div>
            </div>
            <p-divider align="left" type="dashed">
                <div class="p-d-inline-flex p-ai-center">
                    <b>1ª Parcela</b>
                </div>
            </p-divider>
            <div class="p-fluid p-grid">
                <div class="p-md-6 p-lg-6">
                    <label for="input disablednome">Valor</label>
                    <input disabled [(ngModel)]="valor3" style="font-size: small; width: 150px !important" type="number" class="form-control" id="input disablednome" />
                </div>

                <div class="p-md-6 p-lg-6">
                    <label for="cpf">Vencimento</label>
                    <input disabled style="font-size: small; width: 150px !important" (change)="
              vencimento1 = $event.target.value; previsao1 = $event.target.value
            " mask="00/00/0000" type="text" class="form-control" id="vencimento1" />
                </div>
            </div>

            <p-divider align="left" type="dashed">
                <div class="p-d-inline-flex p-ai-center">
                    <b>2ª Parcela</b>
                </div>
            </p-divider>
            <div class="p-fluid p-grid">
                <div class="p-md-6 p-lg-6">
                    <label for="input disablednome">Valor</label>
                    <input disabled [(ngModel)]="valor2" style="font-size: small; width: 150px !important" type="number" class="form-control" id="input disablednome" />
                </div>
                <div class="p-md-6 p-lg-6">
                    <label for="cpf">Vencimento</label>
                    <input disabled style="font-size: small; width: 150px !important" (change)="
              vencimento2 = $event.target.value; previsao2 = $event.target.value
            " mask="00/00/0000" type="text" class="form-control" id="vencimento2" />
                </div>
            </div>
            <button style="width: 100% !important" (click)="mostrax()" nbButton>
        Integrar
      </button>
        </div>
    </p-dialog>

    <p-dialog [modal]="true" [style]="{ width: '40vw' }" [(visible)]="editacard">
        <ng-template pTemplate="header"> Adicionar tarefa </ng-template>
        <div *ngIf="editacard" class="left">
            <div class="form-group row">
                <label for="Tarefa" class="col-md-3 col-form-label text-md-left">Tarefa</label
        >
        <div class="col-md-8">
          <input
            disabled
            (change)="Title = $event.target.value"
            maxlength="30"
            type="text"
            id="Tarefa"
            class="form-control"
            name="Tarefa"
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="Etapa" class="col-md-3 col-form-label text-md-left"
          >Cliente</label
        >
        <div class="col-md-8">
          <input
            disabled
            readonly
            [value]="filtredhomol.nome"
            (change)="filtredhomol.nome = $event.target.value"
            type="text"
            id="cliente"
            class="form-control"
            name="cliente"
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="status" class="col-md-3 col-form-label text-md-left"
          >Status</label
        >
        <div class="col-md-8">
          <select
            disabled
            [value]="Status"
            (change)="Status = $event.target.value"
            style="font-size: smaller"
            id="status"
            class="form-control"
          >
            <option style="font-size: small">Selecione...</option>
            <option style="font-size: small">Pendente</option>
            <option style="font-size: small">Executando</option>
            <option style="font-size: small">Parada</option>
            <option style="font-size: small">Atrasada</option>
            <option style="font-size: small">Transferida</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="Etapa" class="col-md-3 col-form-label text-md-left"
          >Previsão</label
        >
        <div class="col-md-8">
          <input
            disabled
            mask="00/00/0000"
            [value]="datafim"
            (change)="datafim = $event.target.value"
            type="text"
            id="previsao"
            class="form-control"
            name="Etapa"
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="descr" class="col-md-3 col-form-label text-md-left"
          >Descrição</label
        >
        <div class="col-md-8">
          <textarea
            (change)="descricaox = $event.target.value"
            pinput
            disabledTextarea
            style="
              font-size: smaller;
              width: 100%;
              padding-left: 20px;
              padding-top: 10px;
            "
            rows="3"
            type="text"
            id="descr"
            name="descr"
          ></textarea>
        </div>
      </div>
    </div>
    <p-footer>
      <button (click)="atribuir2()" nbButton>Cadastrar</button>
      <button (click)="editacard = false" nbButton>Cancelar</button>
    </p-footer>
  </p-dialog>

  <p-dialog [modal]="true" [style]="{ width: '40vw' }" [(visible)]="pausar">
    <label for="ressp" class="col-md-12">Pausar Tarefas</label>
                <div class="form-group row">
                    <div class="col-md-8">
                        <select disabled [value]="filtredhomol.statusparada" (change)="filtredhomol.statusparada = $event.target.value" style="font-size: smaller" id="ressp" class="form-control">
          <option style="font-size: small">Selecione...</option>
          <option value="" style="font-size: small">
            Reativar Homologação
          </option>
          <option style="font-size: small">Aguardando troca de titular</option>
          <option style="font-size: small">Débitos com a concessionária</option>
          <option style="font-size: small">Mais de 5 dias sem retorno</option>
          <option style="font-size: small">Pagamento em atraso</option>
          <option style="font-size: small">Alteração do Kit</option>
          <option style="font-size: small">
            Aguardando alteração de carga
          </option>
          <option style="font-size: small">Projeto cancelado</option>
          <option style="font-size: small">A pedido do cliente</option>
          <option style="font-size: small">Aguardando obras</option>
          <option style="font-size: small">Parecer vencido</option>
          <option style="font-size: small">Aguardando financiamento</option>
        </select>
                    </div>
                </div>
                <p-footer>
                    <button (click)="updateparada()" nbButton>Salvar</button>
                </p-footer>
    </p-dialog>
    </div>

    <p-dialog [style]="{width: '50vw'}" [modal]="true" header="Atençāo" [visible]="displays">
        <nz-result nzStatus="warning" nzTitle="Sua versão está desatualizada baixe a versão mais recente">
        </nz-result>
    </p-dialog>

    <p-dialog [style]="{ width: '40vw' }" [(visible)]="filera">
        <ng-template pTemplate="header">
            <b>Notificação de Arquivos</b>
        </ng-template>
        <div >
            <p style="text-align: justify">
             Ao clicar no botão notificar abaixo, você irá emitir um alerta para os projetistas que seus arquivos
             foram anexados na pasta, recomendamos que faça isso somente <b>Após enviar todos os arquivos</b>
            </p>
            <br>
            <p style="text-align: justify">
                Caso deseje enviar mais arquivos, basta clicar em <b> Enviar mais arquivos</b> . Essa mensagem aparecerá assim que um novo arquivo for enviado
            </p>
            <br>
            <p style="text-align: justify">
               Deseja notificar a equipe de engenharia que os arquivos foram enviados agora? clique no botão  <b> Notificar Agora </b>
            </p>
        </div>
        <ng-template pTemplate="footer">
            <div class="flex justify-content-left pt-0 col-12">
                <button style="background-color: rgb(151, 93, 93)
                    !important;
                    color: white;
                    height: 40px;
                    font-size: 14px;
                    font-weight: bold;" (click)="filera=false" pButton pRipple
                    label="Enviar mais arquivos"
                    class="p-button-outlined p-button-secondary w-12 mr-2"></button>
                    <button style="background-color: rgb(72, 51, 121)
                    !important;
                    color: white;
                    height: 40px;
                    font-size: 14px;
                    font-weight: bold;" (click)="enviandofiles()" pButton pRipple
                    label="Notificar Agora"
                    class="p-button-outlined p-button-secondary w-12 mr-2"></button>
            </div>
        </ng-template>
    </p-dialog>
</div>

<p-dialog header="Detalhes Pagamentos" [(visible)]="pagamentos" [style]="{width: '60vw'}">
    <div class="surface-overlay border-round p-4 shadow-0 relative" style="max-width: 800px">
        <div class="grid">
            <div class="col-12 md:col-6 flex justify-content-center align-items-center mt-6 sm:mt-0">
                <img alt="Código QR">

            </div>
            <div class="col-12 md:col-6 md:pl-5">
                <div class="text-900 text-2xl font-medium mb-3">1ª Parcela</div>
                <div class="text-900 text-3xl font-bold mb-3">R$ 120.00</div>
                <p class="text-700 line-height-3 my-4 p-0">Para pagamento em Pix usar o botão abaixo para gerar o Qrcode.</p>
                <div class="flex align-items-center mb-4">
                    <button type="button" pButton pRipple label="Pagar" class="mr-3 py-2"></button>
                    <button type="button" pButton pRipple label="Pagar Total" class="mr-3 py-2"></button>
                </div>

                <ul class="list-none p-0 m-0">
                    <li class="flex align-items-center mb-2">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900 text-sm">Para enviar projeto a 1ª Parcela deve está quitada</span>
                    </li>
                    <li class="flex align-items-center mb-2">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900 text-sm">Para Solicitar Vistoria 2ª Parcela deve está quitada.</span>
                    </li>
                    <li class="flex align-items-center">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900 text-sm">Magna etiam tempor orci..</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</p-dialog>
<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

