<div class="container">
    <div style="background-color: white !important; border: none; " class="container">
        <div style="width: 100% !important;overflow:auto;height:auto;overflow-x: hidden;" class="main-content">
            <table style="width: 100% !important;background-color: white; margin-bottom: 10px;" class="table">
                <thead>
                    <tr>
                        <div style="margin-top: 10px !important; margin-left: 10px !important;" class="breadcrumbs">
                           
                        </div>
                        <span class="spacer"></span>
                    </tr>
                    <div style="margin-top: 40px;" *ngIf="inEntries.length < 1">
                        Essa pasta está Vazia...
                    </div>
                    <tr>
                        <th style="color: black;
                        font-weight: bold !important;
                        font-family: 'Oxygen', sans-serif !important; font-size: 12px !important;"> 
                        <a style="color: blue; margin-right: 20px;" (click)="voltarq()">{{ '' ? 'Home' : ''}}<i class="fas fa-home"></i>voltar</a>

                        Nome</th>
                        <th style="color: black;
                        font-weight: bold !important;
                        font-family: 'Oxygen', sans-serif !important; font-size: 12px !important;">Data </th>
                        <th style="color: black;
                        font-weight: bold !important;
                        font-family: 'Oxygen', sans-serif !important; font-size: 12px !important;">Tamanho</th>
                            <th style="color: black;
                            font-weight: bold !important;
                            font-family: 'Oxygen', sans-serif !important; font-size: 12px !important;">Download</th>
                    </tr>

                </thead>
                <tbody>
                    <tr *ngFor="let item of inEntries">
                        
                        <ng-template *ngIf="item['.tag'] === 'folder';then thenBlock; else elseBlock"></ng-template>
                        <ng-template #thenBlock>
                            <td>
                                <div>
                                    <i class="fas fa-folder fa-2x"></i>
                                    <a style="color: black;
                                    font-weight: 500 !important;
                                    font-family: 'Oxygen', sans-serif !important; font-size: 12px !important;" (click)="mostraevento($event);verificafolder(item.name,item.name)">{{ item.name }}</a>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span style="color: black;
                                    font-weight: 500 !important;
                                    font-family: 'Oxygen', sans-serif !important; font-size: 12px !important;">{{ item.client_modified | date:'dd-MM-yyyy HH:mm' }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span style="color: black;
                                    font-weight: 500 !important;
                                    font-family: 'Oxygen', sans-serif !important; font-size: 12px !important;">{{ item.size | fileSize }}</span>
                                </div>
                            </td>
                            <td>
                             
                            </td>
                        </ng-template>

                        <ng-template #elseBlock>
                            <td>
                                <div>
                                    <img *ngIf="isImage(item.path_lower)" alt="bild" width="32" height="32" [id]="item.path_lower" class="thumbnail">
                                    <i *ngIf="!isImage(item.name)" [ngClass]="getFileType(item.name)"></i>
                                    <a (click)="downloadFile(item.path_lower, item.name, $event, dialogoso);" href='javascript:void(0);'>
                                        <span style="color: black;
                                        font-weight: 500 !important;
                                        font-family: 'Oxygen', sans-serif !important; font-size: 12px !important;">{{ item.name }}</span>
                                    </a>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span style="color: black;
                                    font-weight: 500 !important;
                                    font-family: 'Oxygen', sans-serif !important; font-size: 12px !important;">{{ item.client_modified | date:'dd-MM-yyyy HH:mm' }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span style="color: black;
                                    font-weight: 500 !important;
                                    font-family: 'Oxygen', sans-serif !important; font-size: 12px !important;">{{ item.size | fileSize }}</span>
                                </div>
                            </td>
                            <td>
                                <a  (click)="downloadFilex(item.path_lower, item.name, $event, dialogoso);"  >
                                    <i class="fas fa-download"></i>
                                  </a>
                            </td>
                        </ng-template>
                    </tr>
                </tbody>
            </table>

            <table style="width: automatic;">
                <tr>
                    <td>
                    </td>
                </tr>
            </table>
        </div>
    </div>


    <ng-template style="max-height: 90%;
   overflow-y: hidden; width: 980px !important;margin-top:200px !important; " #dialogoso let-data let-ref="dialogRef">
        <div style="margin-top:20px !important"></div>
        <nb-card style="max-height: 90%;
     overflow-y: hidden; width: 980px !important;padding: 0rem 0rem !important;">
            <nb-card-body style="background-color: #ECEFF1 !important;padding: 0rem 0rem !important;">
                <div *ngIf="naorender === 'true'">
                    <div style="margin-top:40px !important"></div>

                    <h6 style="color: black; margin-left:20px;">Atenção: Não é possível visualizar esse formato de arquivo, por favor faça o download</h6>
                    <div style="margin-bottom:20px !important"></div>

                </div>
                <div *ngIf="ehimage === 'true'">
                    <div cdkDrag>
                        <ngx-imageviewer cdkDrag [src]="docFile"></ngx-imageviewer>

                    </div>
                </div>
                <div *ngIf="ehpdf === 'true'">
                    <ngx-extended-pdf-viewer [base64Src]="nbase64data" [height]="'90vh'" [useBrowserLocale]="true" [textLayer]="true" [showHandToolButton]="true" [showPresentationModeButton]="true" [showBookmarkButton]="false" [(page)]="page" [(pageLabel)]="pageLabel">
                    </ngx-extended-pdf-viewer>
                </div>
            </nb-card-body>
            <nb-card-footer style="background-color: #DCDCDC;height: 20px;">
                <div style="margin-top: -10px;">
                    <a class="noselect" (click)="ref.close()">Voltar</a>

                    <a class="noselect" style="margin-left: 90px; align-items: center;" (click)="downlo()">
                        <i class="fa fa-download" aria-hidden="true"></i> Download
                    </a>
                </div>
            </nb-card-footer>
        </nb-card>

    </ng-template>
