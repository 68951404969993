<nav id="sidebar" class="sidebar">
    <div class="sidebar-content">
        <perfect-scrollbar>
            <div class="sidebar-profile">
                <div style="background-color: white;" class="user-pic">
                    <img class="img-responsive img-rounded" [src]="urln" alt="User picture">
                </div>
                <div class="user-info">
                    <span class="user-name">{{meunome}}
            </span>
                    <span class="user-role">Administrator</span>
                    <span class="user-status">
              <i class="fa fa-circle"></i>
              <span>Online</span>
                    </span>
                </div>
            </div>

            <div class="sidebar-menu">
                <ul>
                    <li *ngFor="let menu of menus" [ngClass]="{'active': menu.active , 'sidebar-dropdown':menu.type === 'dropdown' , 'header-menu':menu.type === 'header'}">
                        <span *ngIf="menu.type === 'header'">{{menu.title}}</span>
                        <a href="javascript: void(0);" *ngIf="menu.type !== 'header'" (click)='toggle(menu)'>
                            <i style="background-color: #3d3e42 !important;" class="{{menu.icon}}"></i>
                            <span class="teste">{{menu.title}}</span>
                            <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{menu.badge.text}}</span>
                        </a>
                        <div *ngIf="menu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(menu)">
                            <ul>
                                <li *ngFor="let submenu of menu.submenus">
                                    <a href="javascript: void(0);"> {{submenu.title}}
                      <span *ngIf="submenu.badge" class="badge badge-pill" [ngClass]="submenu.badge.class">{{submenu.badge.text}}</span>
                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </perfect-scrollbar>
    </div>
    <div class="sidebar-footer">
        <div class="dropdown" dropdown>

            <a ref="javascript: void(0);" dropdownToggle>
                <i class="fa fa-bell"></i>
                <span class="badge badge-pill badge-warning notification"></span>
            </a>
            <div class="dropdown-menu notifications" *dropdownMenu>
                <div class="notifications-header">
                    <i class="fa fa-bell"></i> Notifications
                </div>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" ref="javascript: void(0);">
                    <div class="notification-content">
                        <div class="icon">
                            <i class="fas fa-check text-success border border-success"></i>
                        </div>
                        <div class="content">
                            <div class="notification-detail">Lorem ipsum dolor sit amet consectetur adipisicing elit. In totam explicabo</div>
                            <div class="notification-time">
                                6 minutes ago
                            </div>
                        </div>
                    </div>
                </a>
                <a class="dropdown-item" ref="javascript: void(0);">
                    <div class="notification-content">
                        <div class="icon">
                            <i class="fas fa-exclamation text-info border border-info"></i>
                        </div>
                        <div class="content">
                            <div class="notification-detail">Lorem ipsum dolor sit amet consectetur adipisicing elit. In totam explicabo</div>
                            <div class="notification-time">
                                Today
                            </div>
                        </div>
                    </div>
                </a>
                <a class="dropdown-item" ref="javascript: void(0);">
                    <div class="notification-content">
                        <div class="icon">
                            <i class="fas fa-exclamation-triangle text-warning border border-warning"></i>
                        </div>
                        <div class="content">
                            <div class="notification-detail">Lorem ipsum dolor sit amet consectetur adipisicing elit. In totam explicabo</div>
                            <div class="notification-time">
                                Yesterday
                            </div>
                        </div>
                    </div>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item text-center" href="#">View all notifications</a>
            </div>
        </div>
        <div class="dropdown" dropdown>
            <a ref="javascript: void(0);" dropdownToggle>
                <i class="fa fa-envelope"></i>
                <span class="badge badge-pill badge-success notification"></span>
            </a>
            <div class="dropdown-menu messages" *dropdownMenu>
                <div class="messages-header">
                    <i class="fa fa-envelope"></i> Messages
                </div>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">
                    <div class="message-content">
                        <div class="pic">
                            <img src="assets/img/user.jpg" alt="">
                        </div>
                        <div class="content">
                            <div class="message-title">
                                <strong> Jhon doe</strong>
                            </div>
                            <div class="message-detail">Lorem ipsum dolor sit amet consectetur adipisicing elit. In totam explicabo</div>
                        </div>
                    </div>

                </a>
                <a class="dropdown-item" href="#">
                    <div class="message-content">
                        <div class="pic">
                            <img src="assets/img/user.jpg" alt="">
                        </div>
                        <div class="content">
                            <div class="message-title">
                                <strong> Jhon doe</strong>
                            </div>
                            <div class="message-detail">Lorem ipsum dolor sit amet consectetur adipisicing elit. In totam explicabo</div>
                        </div>
                    </div>

                </a>
                <a class="dropdown-item" ref="javascript: void(0);">
                    <div class="message-content">
                        <div class="pic">
                            <img src="assets/img/user.jpg" alt="">
                        </div>
                        <div class="content">
                            <div class="message-title">
                                <strong> Jhon doe</strong>
                            </div>
                            <div class="message-detail">Lorem ipsum dolor sit amet consectetur adipisicing elit. In totam explicabo</div>
                        </div>
                    </div>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item text-center" href="#">View all messages</a>

            </div>
        </div>
        <div id="toglarex" class="dropdown">
            <a (click)="firstComponentFunction();toggleSidebar()" id="toglarex">
                <i id="toglar" class="fa fa-power-off"></i>
                <span class="badge-sonar"></span>
            </a>
        </div>

    </div>
</nav>

<p-dialog styleClass="disable-scroll" [style]="{width: '600px',overflow: 'hidden !important'}" position="center" [(visible)]="embreve">
    <nz-result nzStatus="info" nzTitle="Em breve!" nzSubTitle="Estamos ajustando tudo, essa funçāo estará disponível em breve">
        <div nz-result-extra>
            <button nbButton (click)="embreve = false">Fechar</button>
        </div>
    </nz-result>
</p-dialog>