import { todocaixa } from './../homologacoes/caixa';
import { HomologacoesserviceService } from './../homologacoes/homologacoesservice.service';
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import * as $AB from 'jquery';
import * as $ from 'jquery';
import { formatDate } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { todosdados } from '../homologacoes/todosdados';
import { HOMOTAREFA } from '../homologacoes/taskhomol';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';

import 'node_modules/jquery/dist/jquery.min.js';
import 'src/assets/js/radiacao.js';
import 'src/assets/js/docxtemplater.js';
import 'src/assets/js/FileSaver.min.js';
import 'src/assets/js/pizzip-utils.js';
import 'src/assets/js/pizzip.js';
import 'src/assets/js/imagemodule.js';
import 'src/assets/js/jszip.js';
import 'src/assets/js/jszip-utils.js';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import ImageModule from 'open-docxtemplater-image-module';
import 'src/assets/js/dropboxsdk.min.js';
import { FileUploader } from 'ng2-file-upload';
import { Dropbox } from 'dropbox';
import { ExcelQueryCellInfoEventArgs, GridComponent, PdfQueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';
import { MaskedTextBoxModel } from '@syncfusion/ej2-inputs';
import { ClickEventArgs } from '@syncfusion/ej2-navigations'


export interface CONFIGURACAO {
  modeloplaca: string;
  marcaplaca: string;
  qtdplaca: string;
  potplaca: string;
  modeloinv: string;
  marcainv: string;
  qtdinv: string;
  potinv: string;
}

export interface FITRO {
  ID: string;
  area: string;
  art: string;
  atividade: string;
  aviso: string;
  bairro: string;
  cargainstalada: string;
  cel: string;
  cep: string;
  cidade: string;
  classe: string;
  classetensao: string;
  codhomol: string;
  complemento: string;
  coords: string;
  cpf: string;
  datafim: string;
  datainicio: string;
  descricao: string;
  digeral: string;
  disjuntoraumentocarga: string;
  disjuntorgeracao: string;
  distanciainversorquadro: string;
  distanciaplacaquadro: string;
  email: string;
  endereco: string;
  entradaareasub: string;
  iddono: string;
  inclinacao: string;
  latitude: string;
  localinst: string;
  longitude: string;
  marcainv1: string;
  marcainv2: string;
  marcainv3: string;
  marcainv4: string;
  marcainv5: string;
  marcaplaca1: string;
  marcaplaca2: string;
  marcaplaca3: string;
  marcaplaca4: string;
  marcaplaca5: string;
  modeloinv1: string;
  modeloinv2: string;
  modeloinv3: string;
  modeloinv4: string;
  modeloinv5: string;
  modeloplaca1: string;
  modeloplaca2: string;
  modeloplaca3: string;
  modeloplaca4: string;
  modeloplaca5: string;
  nome: string;
  numclientecemig: string;
  numerocliente: string;
  numeroinstalacao: string;
  orientacao: string;
  padraoentrada: string;
  pagamentoentrada: string;
  pagamentosegundaparte: string;
  potinv1: string;
  potinv2: string;
  potinv3: string;
  potinv4: string;
  potinv5: string;
  potplaca1: string;
  potplaca2: string;
  potplaca3: string;
  potplaca4: string;
  potplaca5: string;
  potenominalusina: string;
  potkwp: string;
  qtdinv1: string;
  qtdinv2: string;
  qtdinv3: string;
  qtdinv4: string;
  qtdinv5: string;
  qtdplaca1: string;
  qtdplaca2: string;
  qtdplaca3: string;
  qtdplaca4: string;
  qtdplaca5: string;
  responsavel: string;
  rg: string;
  solicitante: string;
  statusdados: string;
  statushomologacao: string;
  statusprojeto: string;
  statusvistoria: string;
  telefone: string;
  tipotelhado: string;
  tipodisjgeracao: string;
  tpdaumentodeCarga: string;
  uf: string;
}

export interface Myhomolg {
  codhomol: string;
  Cliente: string;
  Status: number;
  local: string;
  descricao: string;
  categoria: string;
  Menu: number;
}
export interface Locais {
  value: string;
  viewValue: string;
}
export interface DialogData {
  animal: string;
  name: string;
}



/**
 * @title Table with selection
 */

@Component({
  selector: 'app-gestao',
  templateUrl: './gestao.component.html',
  styleUrls: ['./gestao.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-10%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-10%)' }))
      ])
    ])
  ]
})
export class GestaoComponent implements OnInit {



  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = ['projeto', 'responsavel',  'dataini', 'observacao', 'cliente','parceiro', 'datafim','Menu'];
  columnsToDisplay = ['projeto', 'responsavel',  'dataini',  'observacao','cliente','parceiro', 'datafim','Menu'];
  abrehomologacao = 'false';
  filtredhomol = {
    projeto:'',
    responsavel:'',  
    status:'',
    dataini:'',  
    observacao:'',  
    datafim:'', 
    cliente:'',  
    parceiro:'', 
    id:'', 
    diasvenc:'',  
    pgt1:'',  
    pgt2:'', 
  }
  todastarefas: HOMOTAREFA[];
  selection = new SelectionModel<todocaixa>(true, []);
  todashomologacoes: any[];
  nopDate: any;
  id: string;
  closeResult: string;
  responsavel: string;
  projeto: string;
  cliente: string;
  data: string;
  dataini:string;
  datafim: string;
  observacao: string;
  parceiro: string;
  cliprojeto: string;
  validacao = [];
  validacao6 = {};
  validacao5 = {};
  validacao3 = {};
  validacaoup = {};
  @ViewChild('overviewgrid')
  public gridInstance: GridComponent;
  public maskedTextBoxModel: MaskedTextBoxModel = {
    mask: '00/00/0000'
  };
  public dReady: boolean = false;
  public dtTime: boolean = false;
  public isDataBound: boolean = false;
  public isDataChanged: boolean = true;
  public intervalFun: any;
  public clrIntervalFun: any;
  public clrIntervalFun1: any;
  public clrIntervalFun2: any;
  public dropSlectedIndex: number = null;
  public stTime: any;
  renderizou = false;
  disabled = false;
  showSearch = false;
  locas = 'pt-BR';
  public pageSettings: Object;
  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;
  public toolbar: string[];
  public isInitial: Boolean = true;
  // variaveis de controle
  notificacoes = [];
  dataSource = new MatTableDataSource<any>([]);
  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  public animationsDisabled = false;
  response: string;
  validacao1 = {};
  validacaoemail = {};
  enviado = false;
  naoenviado = false;
  dia : any;
  mes: any;
  ano: any;
  datacorret: any;
  constructor(
    private service: HomologacoesserviceService,
    private dialogService: NbDialogService,
    private spinner: NgxSpinnerService) 
  {
    setTimeout(()=>{
      this.puxagestoes();
  }, 2000);
  }

  
  // tslint:disable-next-line: member-ordering
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  clientepnael() {
    this.puxagestoes();
    if (this.abrehomologacao === 'true') { this.abrehomologacao = 'false'; } else { this.abrehomologacao = 'true'; }
  }


 refresh(){
    this.puxagestoes();
 }

 toolbarClick(args: ClickEventArgs): void {
  switch (args.item.text) {
      case 'PDF Export':
          this.gridInstance.pdfExport();
          break;
      case 'Exportar Excel':
          this.gridInstance.excelExport();
          break;
      case 'Exportar CSV':
          this.gridInstance.csvExport();
          break;
  }
}
  updatege() {
    this.spinner.show();
    this.service.updatege(this.filtredhomol)
      .subscribe(dados => {
        if (dados !== this.validacaoup) {
          this.validacaoup = dados;
          if (dados['status'] === 'homologado') {
            console.log('atualizado');
            this.spinner.hide();
          }
        }
      });
    this.validacaoup = {};
  }


  filtrahomol(cl) {
    console.log(cl)
    for (let i = 0; i < this.todashomologacoes.length; i++) {
      if(this.todashomologacoes[i]['id']=== cl){

        this.filtredhomol= {
          projeto:this.todashomologacoes[i].projeto,
          responsavel:this.todashomologacoes[i].responsavel,  
          status:this.todashomologacoes[i].status,
          dataini:this.todashomologacoes[i].dataini,
          observacao:this.todashomologacoes[i].observacao, 
          datafim:this.todashomologacoes[i].datafim, 
          cliente:this.todashomologacoes[i].cliente,   
          parceiro:this.todashomologacoes[i].parceiro,  
          id:this.todashomologacoes[i].id, 
          diasvenc:this.todashomologacoes[i].diasvenc,  
          pgt1:this.todashomologacoes[i].pgt1,  
          pgt2:this.todashomologacoes[i].pgt2, 
        }
        console.log(this.filtredhomol)
      }
    }
  }
  onDataBound(args: any): void {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }
  dataBound() {
    if(this.isInitial) {
        this.gridInstance.toolbarModule.toolbar.hideItem(2, true);
        this.isInitial = false;
    }
  }
  calculateDiff(){
    for (let i = 0; i < this.todashomologacoes.length; i++) {
      console.log ("Block statement execution no." + i);
    let currentDate = new Date();
    this.datacorret = (this.todashomologacoes[i]['dataini']).split('/');
    console.log(this.todashomologacoes[i]['dataini'])
    this.dia = this.datacorret[0];
    console.log(this.dia) 
    this.mes = this.datacorret[1]; 
    this.ano = this.datacorret[2]; 
    var datanova = new Date(this.ano, this.mes - 1, this.dia);
    var diff =((  currentDate.getTime() - datanova.getTime()   ));
    var days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    console.log (days)
    this.todashomologacoes[i]['diasvenc'] = days
    if(this.todashomologacoes[i]['status'] ==='Concluído'){
      this.todashomologacoes.pop()
    }
    }
    console.log( this.todashomologacoes)
    this.dataSource = new MatTableDataSource<any>(this.todashomologacoes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort; 
}
  novagestao() {
    this.service.novagestao({
      responsavel: this.responsavel,
      projeto: this.projeto ,
      observacao: this.observacao,
      dataini: this.dataini,
      datafim: this.datafim,
      cliente: this.cliprojeto,
      parceiro: this.parceiro,
      id:'GE'+(((Date.now()) / 1000).toFixed(0)).toString(),
      status: 'Pendente',
      pgt1:'Pendente',
      pgt2: 'Pendente',
    })
      .subscribe(dados => {
        if (dados !== this.validacao1) {
          this.validacao1 = dados;
          console.log(dados)
          if (dados['status'] === 'cadastrado') {
            console.log('inserido')
            this.puxagestoes()
          }
          if (dados['status'] === 'erro') {
            console.log(' não inserido')
          }
        }
      });
  }
  
 


  ngOnInit() {
    this.service.currentid.subscribe(dados => {
      if (dados !== this.id && dados !== '') {
        this.id = dados;
        console.log(this.id);
        this.puxagestoes();
      }
    });
  }

  exportQueryCellInfo(args: ExcelQueryCellInfoEventArgs | PdfQueryCellInfoEventArgs): void {
    if (args.column.headerText === 'Employee Image') {
        if ((args as any).name === 'excelQueryCellInfo') {
            args.image = { height: 75, base64: (args as any).data.EmployeeImage, width: 75 };
        } else {
            args.image = { base64: (args as any).data.EmployeeImage };
        }
    }
    if (args.column.headerText === 'Email ID') {
        args.hyperLink = {
            target: 'mailto:' + (args as any).data.EmailID,
            displayText: (args as any).data.EmailID
        };
    }
}


  toggleAnimations() {
    this.animationsDisabled = !this.animationsDisabled;
  }
  puxagestoes() {
    this.dataSource.sort = this.sort;
    this.service.todasgestao()
      .subscribe((data: any[]) => {
        if (data !== this.todashomologacoes) {
          this.todashomologacoes = data;
          this.renderizou = true;
          this.calculateDiff()
     
        }
      });
  }
  
  closemodal() {
    // tslint:disable-next-line: only-arrow-functions
    $(function () {
      $('#transacaomodal').modal('toggle');
    });
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  deletetask(i) {
    console.log(i)
    this.service.deltarhomol({ ID: this.todastarefas[i].ID })
      .subscribe(dados => {
        if (dados !== this.validacao6) {
          if (dados['status'] === 'atualizado') {
            console.log('inserido')
            this.todastarefas = [] as HOMOTAREFA[];
          }
          if (dados['status'] === 'erro') {
            console.log(' não inserido')
          }
        }
      });
    this.validacao6 = {};
  }

  


}
