<div style="padding: 5px !important; ">
  <p-menubar styleClass="Colors" [model]="items">
    <ng-template style="background-color: #2d323d !important;" pTemplate="start">
      <h1></h1>
    </ng-template>
  </p-menubar>

  <div style="padding: 10px" *ngIf="tabletickets">
  
    <ejs-grid  [toolbar]='toolbar' allowResizing= 'true' allowPaging="true" autoFit= 'true' [allowReordering]='false' [gridLines]="'Horizontal'" [allowSelection]='true' id="Grid" allowPaging="true"
      #overviewgrid id="overviewgrid" [dataSource]="data" rowHeight="38" [allowSorting]="true" [enableHover]="true"
      [filterSettings]="filterSettings" [allowSorting]="true" [allowFiltering]="true" [enableVirtualization]="false"
      [allowExcelExport]="true" [allowPdfExport]="true">
      <e-columns>
        <e-column [filter]="filter" clipMode="EllipsisWithTooltip" field="ticket" headerText="Name" textAlign="Left"
          width="auto"></e-column>
          <e-column [filter]="filter" clipMode="EllipsisWithTooltip" field="cliente" headerText="Cliente" textAlign="Left"
          width="auto"></e-column>
        <e-column [filter]="filter" clipMode="EllipsisWithTooltip" field="assunto" headerText="Título" textAlign="Left"
          width="auto"></e-column>
        <e-column [filter]="filter" clipMode="EllipsisWithTooltip" field="status" headerText="Status" textAlign="Left"
          width="auto"></e-column>
        <e-column [filter]="filter" clipMode="EllipsisWithTooltip" field="action" headerText="Ação" width="150px"
          textAlign="Left">
          <e-column [filter]="filter" clipMode="EllipsisWithTooltip" field="ticket" textAlign='Left' [visible]="true"
            headerText="Editar" width="150px" [isPrimaryKey]="false">
            <ng-template #template let-data>
              <button (click)="filtra_ticket(data.ticket);" nz-button nzType="default">Abrir</button>
            </ng-template>
          </e-column>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>

  <div class="p-4" *ngIf="mostratickets">
    <div class="surface-card p-4 mt-2 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">Dados do Ticket</div>
    <div class="col-12 md:col-3 p-3">
    </div>
    <div class="col-12 mb-2 md:col-6 p-0">
      <div class="text-500 font-bold mb-2">Título</div>
      <div class="text-900">{{filtred_ticket.assunto}}</div>
    </div>
    <div class="grid grid-nogutter border-top-1 surface-border pt-2">
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-bold mb-2">ID do Ticket</div>
        <div class="text-900">{{filtred_ticket.ticket}}</div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-bold mb-2">Homologação</div>
        <div class="text-900">{{filtred_ticket.homol}}</div>
      </div>
      <div class="col-12 md:col-6 p-3">
        <div class="text-500 font-bold mb-2">Cliente</div>
        <div class="text-900">{{filtred_ticket.cliente}}</div>
      </div>

      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-bold mb-2">Data de Abertura</div>
        <div class="text-900">{{filtred_ticket.criadoem}}</div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-bold mb-2">Status</div>
        <div class="text-900">
          <p-tag *ngIf="filtred_ticket.status === 'Pendente'" severity="warning" [value]="filtred_ticket.status">
          </p-tag>
          <p-tag *ngIf="filtred_ticket.status === 'Concluído'" severity="success" [value]="filtred_ticket.status">
          </p-tag>
          <p-tag *ngIf="filtred_ticket.status === 'Agd. Pagamento'" severity="danger" [value]="filtred_ticket.status">
          </p-tag>
          <p-tag *ngIf="filtred_ticket.status === 'Agd. Seu retorno'" severity="danger" [value]="filtred_ticket.status">
          </p-tag>
          <p-tag *ngIf="filtred_ticket.status === 'Em andamento'" severity="info" [value]="filtred_ticket.status">
          </p-tag>
        </div>
      </div>
      <div class="col-12 md:col-2 p-3">
        <div class="text-500 font-bold mb-2">Valor</div>
        <div *ngIf="filtred_ticket.custo == 0" class="text-900">A definir</div>
        <div *ngIf="filtred_ticket.custo != 0" class="text-900">R$ {{filtred_ticket.custo}}</div>
      </div>

      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-bold mb-2">Previsão:</div>
        <div class="text-900">
          <p-tag *ngIf="filtred_ticket.alteradoem === ''" severity="info" [value]="'A definir'">
          </p-tag>
          <p-tag *ngIf="filtred_ticket.alteradoem !== ''" severity="info" [value]="filtred_ticket.alteradoem">
          </p-tag>
        </div>
      </div>
      <div class="col-12 p-3">
        <div class="text-500 font-bold mb-2">Descrição</div>
        <div class="text-900 line-height-3">{{filtred_ticket.detail}}</div>
      </div>
    </div>
  </div>
  <div class="surface-card p-4 mt-2 shadow-2 border-round">
    <div *ngIf="filtred_ticket.tipo == 'Rateio'" class="control-section p-4">
      <div class="text-black font-bold mb-3">Dados do Rateio</div>
    <div *ngFor="let item of datarateio_reg" style="margin-top: 0px" class="form-row">
      <div class="form-group col-4">
        <ejs-textbox decimals="10" format="###.######" min="0" placeholder="Endereço" cssClass="e-outline"
          [(ngModel)]="item.endereco" floatLabelType="Auto"></ejs-textbox>
      </div>
      <div class="form-group col-4">
        <ejs-textbox decimals="10" format="###.######" min="0" placeholder="Titular (CPF ou CNPJ)" cssClass="e-outline"
          [(ngModel)]="item.titular" floatLabelType="Auto"></ejs-textbox>
      </div>
      <div class="form-group col-2">
        <ejs-numerictextbox decimals="10" format="###.######" placeholder="Nº da UC" cssClass="e-outline"
          [(ngModel)]="item.uc" floatLabelType="Auto"></ejs-numerictextbox>
      </div>
      <div class="form-group col-2">
        <ejs-numerictextbox decimals="10" format="###.######" placeholder="Percentual" cssClass="e-outline"
          [(ngModel)]="item.percentual" floatLabelType="Aucto"></ejs-numerictextbox>
      </div>
    </div>
  </div>
</div>

  <div class="surface-card p-4 mt-2 shadow-2 border-round">
    <div class="col-12 p-3">
      <div class="text-black font-bold mb-3">Histórico</div>

      <div class="surface-ground px-3 py-3 md:px-3 lg:px-3">
        <div *ngFor="let help of helpesk; let i = index" class="mb-5 flex">

          <div class="ml-1 surface-card shadow-2 border-round p-3 flex-auto">
            <div class="mb-3">
              <span class="text-900 font-medium inline-block mr-3">{{help.name}} </span>
              <span class="text-500 text-sm">{{help.data}}</span>
            </div>
            <div class="line-height-3 text-700 mb-3">
              <div [innerHTML]="help.observa"></div>
            </div>
          </div>
        </div>
        <ejs-richtexteditor [insertImageSettings]="insertImageSettings" [fileManagerSettings]='fileManagerSettings'
          (filesData)="filesData($event)" id='rteObj' #rteObj placeholder='Deixe seu comentário'>
        </ejs-richtexteditor>
      </div>
      <button style="margin-top: 1rem;width: 100% !important;" (click)="submitClick()" #rteSubmit id="rteSubmit"
        nbButton>Enviar</button>
  </div>
</div>
<div style="margin-top: 1rem; background-color: white;" class="sample-container">
  <ejs-filemanager [maxFileSize]="maxFileSize" [toolbarSettings]='toolbarSettings' [height]='"80vh"'
    (beforeSend)="OnbeforeSend($event)" (fileOpen)="fileOpen($event)" [rootAliasName]="filtred_ticket.ticket"
    (toolbarClick)="toolbarClicks($event)" id='filemanager' [ajaxSettings]='ajaxSettings'>
  </ejs-filemanager>
</div>

  </div>

  <div style="margin-top: 20px;" *ngIf="abreticket === true">

  </div>
</div>

<p-dialog [(visible)]="clienteinterno"  appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '90vw'}" header="" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <div class="flex flex-column gap-2">
      <h1 class="m-0 text-900 font-semibold text-xl line-height-3">Novo Ticket</h1>
    </div>
  </ng-template>

  <div class="mb-2">
    <label style="font-weight: bold" for="Ramal"> Selecione um Cliente:
    </label>
    <p-autoComplete [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="clienteselecionado"
      [dropdown]="true" [suggestions]="filteredVistorias" (onSelect)="checkticket(clienteselecionado)"
      (completeMethod)="filterCountry($event);checkticket($event)" field="name">
    </p-autoComplete>
  </div>

  <p-messages  *ngIf="clienteselecionado['name'] != undefined " severity="info">
    <ng-template *ngIf="clienteselecionado['name'] != undefined " pTemplate>
        <div class="ml-2">{{clienteselecionado['endereco']}}</div>
    </ng-template>
</p-messages>

  <div  class="mb-3">
    <label style="font-weight: bold" for="Ramal"> Tipo do Ticket:
    </label>
    <p-dropdown [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="tipo_ticket"
      [options]="tipos_ticket" optionLabel="name" placeholder="Selecione um tipo"></p-dropdown>
  </div>
  <div *ngIf="tipo_ticket['name'] == 'Rateio'">
    <p-divider align="left"> 
      <div class="p-d-inline-flex p-ai-center">
        Clique aqui <button (click)="adicionarateio()" style="width: 20px !important; height: 20px;font-size: x-small;" pButton
        pRipple type="button" icon="pi pi-plus" class="p-button-rounded"></button>
  para adicionar uma UC de rateio
              </div>
    </p-divider>
    <div class="control-section">
      <div *ngFor="let item of datarateio" style="margin-top: 0px" class="form-row">
        <div class="form-group col-4">
          <ejs-textbox decimals="10" format="###.######" min="0" placeholder="Endereço" cssClass="e-outline"
            [(ngModel)]="item.endereco" floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-3">
          <ejs-textbox decimals="10" format="###.######" min="0" placeholder="Titular (CPF ou CNPJ)" cssClass="e-outline"
            [(ngModel)]="item.titular" floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-2">
          <ejs-numerictextbox decimals="10" format="###.######" placeholder="Nº da UC" cssClass="e-outline"
            [(ngModel)]="item.uc" floatLabelType="Auto"></ejs-numerictextbox>
        </div>
        <div class="form-group col-2">
          <ejs-numerictextbox decimals="10" format="###.######" placeholder="Percentual" cssClass="e-outline"
            [(ngModel)]="item.percentual" floatLabelType="Aucto"></ejs-numerictextbox>
        </div>

        <div class="form-group col-1">
          <p-button styleClass="ressource-button" (click)="deletarateio(item.idx)" icon="pi pi-trash"></p-button>

        </div>

      </div>
    </div>
  </div>
  <div  class="mb-3">
    <label style="font-weight: bold" for="Ramal"> Título:
    </label>
    <input [style]="{'width':'100%'}" maxlength="100" type="text" pInputText [(ngModel)]="titulo_ticket" />
  </div>

  <div  class="mb-0">
    <label style="font-weight: bold" for="descr"> Descrição:
    </label>
    <textarea (change)="descricaox = $event.target.value" pinput disabledTextarea style="
  font-size: smaller;
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
" rows="3" type="text" id="descr" name="descr"></textarea></div>

  <ng-template pTemplate="footer">
    <div class="flex gap-1 justify-content-end border-top-1 surface-border pt-2">
      <button pButton pRipple label="Solicitar" (click)="novoticket();" class="p-button-rounded"></button>
    </div>
  </ng-template>
</p-dialog>


<p-dialog style="min-height: '90vh'" [(visible)]="clienteexterno" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '90vw'}" header="" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <div class="flex flex-column gap-2">
      <h1 class="m-0 text-900 font-semibold text-xl line-height-3">Novo Ticket</h1>
    </div>
  </ng-template>

  <div  class="mb-3">
    <label style="font-weight: bold" for="Ramal"> Nome do Cliente:
    </label>
    <input [style]="{'width':'100%'}" maxlength="100" type="text"   pInputText [(ngModel)]="nomeclienteexterno" />
  </div>

  <div  class="mb-3">
    <label style="font-weight: bold" for="Ramal"> Tipo do Ticket:
    </label>
    <p-dropdown [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="tipo_ticket"
      [options]="tipos_ticket" optionLabel="name" placeholder="Selecione um tipo"></p-dropdown>
  </div>

  <div *ngIf="tipo_ticket['name'] == 'Rateio'">
    <p-divider align="left"> 
      <div class="p-d-inline-flex p-ai-center">
        Clique aqui <button (click)="adicionarateio()" style="width: 20px !important; height: 20px;font-size: x-small;" pButton
        pRipple type="button" icon="pi pi-plus" class="p-button-rounded"></button>
  para adicionar uma UC de rateio
              </div>
    </p-divider>
    <div class="control-section">
      <div *ngFor="let item of datarateio" style="margin-top: 0px" class="form-row">
        <div class="form-group col-4">
          <ejs-textbox decimals="10" format="###.######" min="0" placeholder="Endereço" cssClass="e-outline"
            [(ngModel)]="item.endereco" floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-3">
          <ejs-textbox decimals="10" format="###.######" min="0" placeholder="Titular (CPF ou CNPJ)" cssClass="e-outline"
            [(ngModel)]="item.titular" floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-2">
          <ejs-numerictextbox decimals="10" format="###.######" placeholder="Nº da UC" cssClass="e-outline"
            [(ngModel)]="item.uc" floatLabelType="Auto"></ejs-numerictextbox>
        </div>
        <div class="form-group col-2">
          <ejs-numerictextbox decimals="10" format="###.######" placeholder="Percentual" cssClass="e-outline"
            [(ngModel)]="item.percentual" floatLabelType="Aucto"></ejs-numerictextbox>
        </div>

        <div class="form-group col-1">
          <p-button styleClass="ressource-button" (click)="deletarateio(item.idx)" icon="pi pi-trash"></p-button>

        </div>

      </div>
    </div>
  </div>
  <div class="mb-3">
    <label style="font-weight: bold" for="Ramal"> Título:
    </label>
    <input [style]="{'width':'100%'}" maxlength="100" type="text" pInputText [(ngModel)]="titulo_ticket" />
  </div>

  <div  class="mb-0">
    <label style="font-weight: bold" for="descr"> Descrição:
    </label>
    <textarea (change)="descricaox = $event.target.value" pinput disabledTextarea style="
  font-size: smaller;
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
" rows="3" type="text" id="descr" name="descr"></textarea></div>

  <ng-template pTemplate="footer">
    <div class="flex gap-1 justify-content-end border-top-1 surface-border pt-2">
      <button pButton pRipple label="Solicitar" (click)="novoticket_externo()" class="p-button-rounded"></button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="newticket" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" [closable]="false" [showHeader]="false">
  <div class="flex flex-column align-items-center my-4">
      <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3" style="width:64px;height:64px">
          <i class="pi pi-check text-5xl"></i>
      </span>
      <div class="font-medium text-2xl text-900">Tipo de Cliente</div>
  </div>
  <p class="line-height-3 p-0 m-0">
  Caso você deseje registrar uma solicitação para um cliente que não esteja registrado em uma homologação no datasol clique no botão cliente externo abaixo. Caso o cliente já esteja registrado os dados serão puxados automaticamente.
  </p>
  <ng-template pTemplate="footer">
      <div class=" border-top-1 surface-border pt-3 flex">
          <button pButton pRipple icon="pi pi-check" (click)="clienteinterno = true ;clienteexterno = false;newticket=false" label="Cliente Interno" class="w-6 ml-2"></button>
          <button pButton pRipple icon="pi pi-times" (click)="clienteexterno = true;clienteinterno = false;newticket=false;setaclienteexterno()" label="Cliente Externo" class="p-button-outlined w-6 mr-2"></button>
          <button pButton pRipple icon="pi pi-times" (click)="clienteexterno = false;clienteinterno = false;newticket=false" label="Sair" class="p-button-outlined w-6 mr-2"></button>

        </div>
  </ng-template>
</p-dialog>

<p-toast position="top-left" key="tl"></p-toast>
