
<div style="background-color: white;" class="card">
  <div class="card-body">
<ng-template *ngIf="!dbxAuth.isAuth; then thenBlock; else elseBlock">
</ng-template>
<ng-template #thenBlock>
<a routerLink="/auth" routerLinkActive="active">Go to Auth</a>
</ng-template>
<ng-template #elseBlock>
    <div class="webhook-container">
      <app-webhooks></app-webhooks>
    </div>
    <div class="storage-container">
      <app-storage [novocaminho]="caminho" ></app-storage>
    </div>
</ng-template>
</div>
</div>
