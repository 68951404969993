<p-sidebar
  [style]="{ overflow: 'scroll' }"
  blockScroll="true"
  [(visible)]="display"
  position="right"
>
  <div (click)="abrechat(item)" *ngFor="let item of chatspendentes">
    <nz-comment
      style="height: 50px"
      [nzAuthor]="item.dono"
      [nzDatetime]="item.data"
    >
      <nz-avatar
        nz-comment-avatar
        nzIcon="user"
        nzSrc="http://solluxengenharia.com.br/static/gen.png"
      >
      </nz-avatar>
      <nz-comment-content>
        <p *ngIf="item.tipo !== 'file'" style="font-size: medium">
          {{ item.textmsg }}
        </p>
        <p *ngIf="item.tipo === 'file'" style="font-size: medium; color: red">
          <b>Arquivo recebido</b>
        </p>
      </nz-comment-content>
      <nz-comment-action>
        <span *ngIf="item.recebida === true" style="font-size: 12px">
          <i style="color: green; margin-right: 5px" class="fa fa-circle"></i>
          <span style="font-size: 12px">Online</span>
        </span>
      </nz-comment-action>
    </nz-comment>
    <p-divider></p-divider>
  </div>
</p-sidebar>

<p-dialog
  styleClass="disable-scroll"
  [resizable]="true"
  [style]="{ width: '600px', overflow: 'hidden !important' }"
  position="center"
  [(visible)]="display2"
>
  <div class="msg_history">
    <div *ngFor="let msg of chatsx; let i = index">
      <div *ngIf="meuid !== msg.sender" class="outgoing_msg">
        <div class="received_withd_msg">
          <p>{{ msg.textmsg }}</p>
          <span class="time_date"> {{ msg.data }}</span>
        </div>
      </div>
      <div *ngIf="meuid === msg.sender" class="outgoing_msg">
        <div class="sent_msg">
          <p>{{ msg.textmsg }}</p>
          <span class="time_date"> {{ msg.data }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="type_msg">
    <div style="margin-top: 10px" class="input_msg_write">
      <input
        (keyup.enter)="sendMessage()"
        [(ngModel)]="messageText"
        style="padding-left: 10px; width: 100%"
        type="text"
        class="form-control"
        placeholder="Digite a mensagem e aperte enter para enviar"
        id="exampleFormControlTextarea1"
        rows="2"
      />
    </div>
  </div>
</p-dialog>

<p-dialog
  styleClass="disable-scroll"
  [style]="{ width: '600px', overflow: 'hidden !important' }"
  position="center"
  [(visible)]="embreve"
>
  <nz-result
    nzStatus="info"
    nzTitle="Essa funçāo está sendo configurada para sua conta estará disponível em breve!"
    nzSubTitle="Obrigado"
  >
    <div nz-result-extra>
      <button nbButton (click)="embreve = false">Fechar</button>
    </div>
  </nz-result>
</p-dialog>
<div
  style="
    background-color: #2d323d;
    height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100%;
  "
  *ngIf="naovalido"
>
  <!--Header-->
  <div class="espaco"></div>
  <div class="d-flex justify-content-center align-middle">
    <mat-card style="width: 600px; height: 500px">
      <img
        style="padding-left: -100px; margin-bottom: 20px"
        class="center2"
        src="assets/images/eemanager.png"
        height="automatic"
        width="automatic"
      />
      <div class="container">
        <form
          style="
            margin-top: 30px;
            margin-bottom: 30px;
            min-width: 400px;
            max-width: 400px;
            width: 100%;
          "
          class="center"
        >
          <mat-form-field class="example-full-width">
            <mat-label>Usúario </mat-label>
            <input
              (click)="errologin = false"
              style="width: 400px"
              (input)="usuario = $event.target.value"
              matInput
              [formControl]="emailFormControl"
              [errorStateMatcher]="matcher"
              placeholder="Ex. pat@example.com"
            />
            <mat-error
              *ngIf="
                emailFormControl.hasError('email') &&
                !emailFormControl.hasError('required')
              "
            >
              Por favor digite um usúario válido
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Usúario é <strong>Obrigatório</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Senha </mat-label>
            <input
              (click)="errologin = false"
              style="width: 400px"
              (input)="senha = $event.target.value"
              type="password"
              matInput
              [formControl]="senhaformcontrol"
              [errorStateMatcher]="matcher"
              placeholder="******"
            />
            <mat-error *ngIf="!senhaformcontrol.hasError('required')">
              Por favor digite sua senha!
            </mat-error>
            <mat-error *ngIf="senhaformcontrol.hasError('required')">
              Senha é <strong>Obrigatória</strong>
            </mat-error>
          </mat-form-field>
        </form>
        <div
          style="text-align: center"
          *ngIf="errologin"
          class="alert alert-primary"
          role="alert"
        >
          Ops! Credenciais erradas tente novamente
        </div>
      </div>

      <div class="center">
        <button
          style="width: 400px"
          (click)="onClick()"
          mat-raised-button
          color="primary"
        >
          Login
        </button>
      </div>

      <p-divider></p-divider>
      <div class="center">
        <button
          style="width: 400px; background-color: turquoise; color: black;"
          (click)="displays = true"
          mat-raised-button
          color="primary"
        >
          Recuperar senha
        </button>
      </div>
      <p-dialog
        [style]="{ width: '70vw' }"
        header="Recuperação de senha"
        [(visible)]="displays"
      >
        <div
          *ngIf="
            mostrapin === false && mudsenha === false && resultado !== true
          "
        >
          <mat-form-field class="example-full-width">
            <mat-label>Digite seu Email </mat-label>
            <input
              (click)="errologin = false"
              style="width: 400px"
              (input)="emailrecu = $event.target.value"
              matInput
              [formControl]="emailFormControl"
              [errorStateMatcher]="matcher"
              placeholder="Ex. datasol@exemplo.com"
            />
            <mat-error
              *ngIf="
                emailFormControl.hasError('email') &&
                !emailFormControl.hasError('required')
              "
            >
              Por favor digite um usúario válido
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Usúario é <strong>Obrigatório</strong>
            </mat-error>
          </mat-form-field>
          <p-divider></p-divider>
          <button
            pButton
            (click)="recupera()"
            type="button"
            label="Recepurar"
            class="p-button-outlined"
          ></button>
        </div>
        <div>
          <code-input
            *ngIf="mostrapin === true"
            [isCodeHidden]="false"
            [codeLength]="6"
            (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)"
          >
          </code-input>
        </div>
        <div *ngIf="mudsenha === true">
          <div style="width: 100%">
            <div class="p-fluid">
              <div class="p-field">
                <label for="username">Nova Senha de <b>8 digitos</b></label>
                <input
                  (change)="trocasenha1 = $event.target.value"
                  pInputText
                  id="password2"
                  type="password"
                  strongLabel="Forte"
                  weakLabel="Fraca"
                  mediumLabel="Média"
                />
              </div>
              <div class="p-field">
                <label for="password">Repita a senha</label>
                <input
                  (change)="trocasenha2 = $event.target.value"
                  pInputText
                  id="password2"
                  type="password"
                  strongLabel="Forte"
                  weakLabel="Fraca"
                  mediumLabel="Média"
                />
              </div>
              <mat-error *ngIf="trocasenha1 !== trocasenha2">
                As senhas não coincidem
              </mat-error>
              <p-divider></p-divider>
              <p-button (click)="upasenha()" label="Alterar"></p-button>
            </div>
          </div>
        </div>

        <div *ngIf="resultado === true">
          <nz-result
            nzStatus="success"
            nzTitle="Senha alterada com sucesso!"
            nzSubTitle="Por favor faça seu login com a nova senha salva."
          >
          </nz-result>
        </div>
      </p-dialog>
    </mat-card>
  </div>
</div>

<div *ngIf="valido" class="page-wrapper chiller-theme toggled">
  <a
    (click)="togla()"
    style="color: white; width: 20px"
    id="show-sidebar"
    class="btn btn-sm btn-dark"
  >
    <i class="fas fa-bars"></i>
  </a>

  <div  class="page-wrapper default-theme sidebar-bg bg1 toggled">
    <nav id="sidebar" class="sidebar-wrapper">
      <div class="sidebar-content">
        <div class="sidebar-header">
          <div style="background-color: transparent" class="user-pic">
            <img
              class="img-responsive img-rounded"
              [src]="[url]"
              alt="User picture"
            />
          </div>
          <div class="user-info">
            <span style="font-size: 9px !important;color:w" class="user-name">{{ nome }} </span>
            <span style="font-size: 9px !important;" class="user-role">{{ cargo }}</span>
            <span style="font-size: 9px !important;" class="user-role">versão 3.0.2</span>

          </div>
        </div>
        <!-- sidebar-header  -->

        <!-- sidebar-search  -->
        <div  class="sidebar-menu">
          <ul>
            <li
              *ngFor="let menu of menus"
              [ngClass]="{
                active: menu.active,
                'sidebar-dropdown': menu.type === 'dropdown',
                'header-menu': menu.type === 'header'
              }"
            >

              <a
                href="javascript: void(0);"
                *ngIf="menu.type !== 'header'"
                (click)="toggle(menu)"
              >
                <i
                  style="background-color: #414146 !important;font-size: 10px; width: 30px;"
                  class="{{ menu.icon }}"
                ></i>
                <span class="teste">{{ menu.title }}</span>
                <span
                  *ngIf="menu.badge"
                  class="badge badge-pill"
                  [ngClass]="menu.badge.class"
                  >{{ menu.badge.text }}</span
                >
              </a>
              <div
                *ngIf="menu.type === 'dropdown'"
                class="sidebar-submenu"
                [@slide]="getState(menu)"
              >
                <ul>
                  <li *ngFor="let submenu of menu.submenus">
                    <a href="javascript: void(0);">
                      {{ submenu.title }}
                      <span
                        *ngIf="submenu.badge"
                        class="badge badge-pill"
                        [ngClass]="submenu.badge.class"
                        >{{ submenu.badge.text }}</span
                      >
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- sidebar-footer  -->
      <div class="sidebar-footer">
        <div>
          <a  style="color: white" (click)="pinus()">
            <i style="font-size: 12px;" class="fa fa-angle-double-left"></i>
          </a>
        </div>
        <div>
          <a (click)="togla()" style="color: white"> <i style="font-size: 12px;" class="fas fa-bell"></i> </a>
        </div>

        <div>
          <a style="color: white" (click)="logut()">
            <i style="font-size: 12px;" class="fa fa-power-off"></i>
          </a>
        </div>
      </div>
    </nav>
    <!-- sidebar-wrapper  -->
    <main  class="page-content">
      <nb-layout
        style="
          padding-top: 0px !important;
          padding-right: 0px !important;
          padding-bottom: 0px !important;
          padding-left: 0px !important;
        "
      >
        <nb-layout-column style="background-color: #EFF3F8 !important; padding: 0px !important;">
          <router-outlet ></router-outlet>
        </nb-layout-column>
      </nb-layout>
    </main>
  </div>

  <div class="my-container">
    <ng-template #customLoadingTemplate>
      <div class="custom-class">
        <h3>Aguarde...</h3>
      </div>
    </ng-template>
  </div>
</div>
