import { Component, OnInit } from '@angular/core';
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';
import { PAINV } from '../homologacoes/parametrosinv';


export interface Potencia {
  value: number;
  viewValue: string;
}

export interface MODULOMODELO {
  modelo: string;
  codice2: string;
}

export interface IDSCONF1 {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  mod = [];
  paramsmod = [];
  marcamodulos: string;
  potinversorselec: any;
  paramsinv2: PAINV[] = []; // INVERSOR 2
  IDSCONF1: IDSCONF1[] = [];
  nummpp = 0;
  modeloplacas: any;
  marcas: any[];
  digitapotinv1= false;
  mfv: string;
  MPPTC = [];
  indice: any;
  indice2: any;
  potmodulo: any;
  modi: MODULOMODELO[] = [];
  codmodulo: any;
  paramsinv = [];
  potenciafvselec: any;
  marcasinver2: any[]; 
  simetria = false;
  validacaoup ={};
  nosim='0';
  codigo='';
  tipos: Potencia[] = [
    { value: 225, viewValue: '225W' },
    { value: 230, viewValue: '230W' },
    { value: 235, viewValue: '235W' },
    { value: 240, viewValue: '240W' },
    { value: 245, viewValue: '245W' },
    { value: 250, viewValue: '250W' },
    { value: 255, viewValue: '255W' },
    { value: 260, viewValue: '260W' },
    { value: 265, viewValue: '265W' },
    { value: 270, viewValue: '270W' },
    { value: 275, viewValue: '275W' },
    { value: 280, viewValue: '285W' },
    { value: 295, viewValue: '295W' },
    { value: 300, viewValue: '300W' },
    { value: 305, viewValue: '305W' },
    { value: 310, viewValue: '310W' },
    { value: 315, viewValue: '315W' },
    { value: 320, viewValue: '320W' },
    { value: 325, viewValue: '325W' },
    { value: 330, viewValue: '330W' },
    { value: 335, viewValue: '335W' },
    { value: 340, viewValue: '340W' },
    { value: 345, viewValue: '345W' },
    { value: 350, viewValue: '350W' },
    { value: 355, viewValue: '355W' },
    { value: 360, viewValue: '360W' },
    { value: 365, viewValue: '365W' },
    { value: 370, viewValue: '370W' },
    { value: 375, viewValue: '375W' },
    { value: 380, viewValue: '380W' },
    { value: 385, viewValue: '385W' },
    { value: 390, viewValue: '390W' },
    { value: 395, viewValue: '395W' },
    { value: 400, viewValue: '400W' },
  ];
  constructor(private service: HomologacoesserviceService) { }

  ngOnInit() {
  }
  /////////////////////////////////////////////////////////
  // CALCULA POTÊNCIA DOS MÓDULOS
  //////////////////////////////////////////////////////////

  potenciamodulos(potencia) {
    this.potmodulo = potencia;
    this.service.potmod({ pot: potencia })
      .subscribe(dados => {
        if (dados !== this.marcas) {
          this.marcas = dados.sort();
        }
      });
  }
  /////////////////////////////////////////////////////////
  //  BUSCA MODELO MODULOS BANCO DE DADOS
  //////////////////////////////////////////////////////////
  modelomodulos(mar) {
    this.mod = [];
    this.service.modelomodu({ marca: mar, pot: this.potmodulo })
      .subscribe(dados => {
        if (dados !== this.mod) {
          this.mod = dados;
          }
      });
  }
  /////////////////////////////////////////////////////////
  // BUSCA MPARAMETROS DOS MODULOS
  //////////////////////////////////////////////////////////
  parametromudolo(mods) {
    this.codmodulo = mods;
    this.paramsmod = [];
    this.service.parametrosmodulo({ codigo: mods })
      .subscribe(dados => {
        this.paramsmod = dados;

      });
  }

  /////////////////////////////////////////////////////////
  // BUSCA MPARAMETROS DO INVERSOR 1
  //////////////////////////////////////////////////////////
  parainver(mods) {
    this.service.parametrosinversores({ codigo: mods })
      .subscribe(dados => {
        this.paramsinv = dados;
        });
  }

  mudamodelo(i) {
    const index = i - 1;
    this.modeloplacas = this.mod[index].modelo;
    this.marcamodulos = this.marcamodulos + ' - ' + this.modeloplacas;
  }


  /////////////////////////////////////////////////////////
  // BUSCA MARCA DO INVERSOR 
  //////////////////////////////////////////////////////////
  marcainversor2(potencia) {
    this.marcasinver2 = [];
   this.potinversorselec = potencia
    this.service.marcainver({ pot: potencia })
      .subscribe(dados => {
        if (dados !== this.marcasinver2) {
          this.marcasinver2 = dados.sort();
        }
      });
  }

   /////////////////////////////////////////////////////////
  // BUSCA MODELO INVERSOR1 BANCO DE DADOS
  //////////////////////////////////////////////////////////
  modeloinversor(mar) {
    this.modi = [];
    this.service.modeloinv({ marca: mar, pot: this.potinversorselec })
      .subscribe(dados => {
        if (dados !== this.modi) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < dados.length; i++) {
            this.modi.push({ modelo: dados[i]['modelo'], codice2: dados[i]['codice2'] })
          }
        }
      });
  }


  parainver2(mods) {
    this.paramsinv2 = [];
    this.codigo = mods;
    this.service.parametrosinversores({ codigo: mods })
    .subscribe(dados => {
      if (dados !== this.paramsinv2) {
        this.paramsinv2 = dados;
        this.nummpp = this.paramsinv2[0]['numppt']
        for (let i = 0; i < this.nummpp; i++) {
          var codmppt =  'mppt' + (i+1)
          console.log(this.paramsinv2[0][codmppt])
          this.IDSCONF1.push({ value: this.paramsinv2[0][codmppt], viewValue: codmppt });
        }
        for (let i = this.nummpp ; i < 18; i++) {
          var codmppt =  'mppt' + (i)
          this.IDSCONF1.push({ value: 0, viewValue: codmppt });
        }
        
        console.log(this.IDSCONF1);
      }
    });
  }

check(a){
  if(this.simetria === true){
    this.nosim ='1'
  } else {      
    this.nosim ='0'
}
console.log(this.nosim)
}

  upai() {
    if(this.simetria === true){
      this.nosim ='1'
    } else {      
      this.nosim ='0'
  }
  console.log(this.nosim)
    this.service.upainver({
      mppt1: this.IDSCONF1[0].value,
      mppt2: this.IDSCONF1[1].value,
      mppt3: this.IDSCONF1[2].value,
      mppt4: this.IDSCONF1[3].value,
      mppt5: this.IDSCONF1[4].value,
      mppt6: this.IDSCONF1[5].value,
      mppt7: this.IDSCONF1[6].value,
      mppt8: this.IDSCONF1[7].value,
      mppt9: this.IDSCONF1[8].value,
      mppt10: this.IDSCONF1[9].value,
      mppt11: this.IDSCONF1[10].value,
      mppt12: this.IDSCONF1[11].value,
      mppt13: this.IDSCONF1[12].value,
      mppt14: this.IDSCONF1[13].value,
      mppt15: this.IDSCONF1[14].value,
      mppt16: this.IDSCONF1[15].value,
      mppt17: this.IDSCONF1[16].value,
      nosim: this.nosim,
      codigo: this.codigo,
      
    })
      .subscribe(dados => {
        if (dados !== this.validacaoup) {
          this.validacaoup = dados;
          if (dados['status'] === 'homologado') {
            console.log('atualizado');
          }
        }
      });
    this.validacaoup = {};
  }
}



