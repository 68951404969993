import { Component, ViewChild, ViewEncapsulation, Inject } from "@angular/core";
import { extend, addClass } from "@syncfusion/ej2-base";
import { formatDistance } from 'date-fns';
import { SwUpdate, SwPush } from '@angular/service-worker';

import {
  KanbanComponent,
  ColumnsModel,
  CardSettingsModel,
  SwimlaneSettingsModel,
  DialogSettingsModel,
  CardRenderedEventArgs,
} from "@syncfusion/ej2-angular-kanban";
import { cardData } from "./xdata";
import { HomologacoesserviceService } from "../homologacoes/homologacoesservice.service";
import {
  NbComponentStatus,
  NbDialogService,
  NbToastrService,
} from "@nebular/theme";
import { MenuItem, MessageService } from "primeng/api";
import { timeUnits } from "ng-zorro-antd/core/time";

export interface kanban {
  Id: string;
  nomecli: string;
  Title: string;
  Status: string;
  Summary: string;
  Type: string;
  Priority: string;
  Tags: string;
  Estimate: string;
  Assignee: string;
  RankId: any;
  Color: string;
  ClassName: string;
  dataini: string;
  datafim: string;
  valor: string;
  ide: string;
}

@Component({
  selector: "app-kanban",
  templateUrl: "./kanban.component.html",
  styleUrls: ["./kanban.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class KanbanComponents {
  itemsx: MenuItem[];
  public allowDragAndDrop: Boolean = true;
  displayx = false;
  filtros = false;
  datahoje: any;
  tarefas: any;
  atrasadas = [];
  kanbanview = false;
  kanbanData: Object[];
  editacard = false;
  filtrorespo = "Selecione...";
  todasmy= "Selecione...";
  todasdata= "Selecione...";
  meunome: string;
  allmy = [];
  filtronyhomol:any;
  tarefafiltrada: any;
  tarefafiltrada2: any;
  iniciar = false;
  concluir= false;
  errro = false;
  mostra1 = false;
  mostra2 = false;
  mostra3 = false;
  date1: Date;
  sairs= false;
  vaisair = ''
  date8: Date;
  adob = false;
  novob = ''
  novoresp = ''
  mdata: any[] = [];
  submitting = false;
  inputValue = '';
  user = {
    author: 'Han Solo',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
  };
  taskx = ''
  idfiltr = ''
  mostra4 = false;
  sholist = false
  public fields: Object = { text: 'funcionario', value: 'funcionario' };
  public date: Object = new Date();
  public format: string = 'dd-MMM-yy';
  urln: string;
  filtrex:any;
  data1:string;
  data2:string;
  employers: any;
  idemresa = ''
  wpp: any;
  tarefabus = "HL1617310600-00";
  naopermitido = false;
  observadores = [];
  calendar = false
  penda = false
  parad = false
  conclu = false
  execu = false
  todos = []
  adresp = false;
  datefilter = null;
  datefilter2 = null;
  filtrousu = ''
  filtrorela = ''
  pendrel = false;
  execrel = false;
  paradarel = false;
  conclurel = false;
  dateFormat = 'dd/MM/yyyy HH:mm';
  filtroresp = 'nops';
  filterlink = false;
  relatorio = false; 
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
      - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
      ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString());
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  public lastEnd: Date = new Date(new Date(new Date().setDate(0)).toDateString());
  public yearStart: Date = new Date(new Date(new Date().getFullYear() - 1, 0, 1).toDateString());
  public yearEnd: Date = new Date(new Date(new Date().getFullYear() - 1, 11, 31).toDateString());
  @ViewChild("kanbanObj") kanbanObj: KanbanComponent;

  public fieldsx: Object = { text: 'text', value: 'value' };

  public stax = [
    { text: "Pendente", value: "Pendente"},
    { text: "Executando", value: "Executando"}
  ];
  public columns: ColumnsModel[] = [
    { headerText: "Atrasadas", keyField: "vencido", allowToggle: true },
    { headerText: "Hoje", keyField: "hoje", allowToggle: true },
    { headerText: "Essa semana", keyField: "semana", allowToggle: true },
    { headerText: "Próxima semana", keyField: "nvsemana", allowToggle: true }
  ];
  public cardSettings: CardSettingsModel = {
    headerField: "Title",
    template: "#cardTemplate",
    selectionType: "Multiple",
  };
  public dialogSettings: DialogSettingsModel = {
    fields: [
      { text: "Tarefa", key: "Title", type: "TextBox" },
      { text: "Status", key: "Status", type: "DropDown" },
      { text: "Responsável", key: "Assignee", type: "DropDown" },
      { text: "Classifição", key: "RankId", type: "TextBox" },
      { text: "descricao", key: "Summary", type: "TextArea" },
    ],
  };
  public swimlaneSettings: SwimlaneSettingsModel = { keyField: "Id" };
  myDate:any;
  constructor(
    private messageService: MessageService,
    private swPush: SwPush,
    private service: HomologacoesserviceService,
    private toastrService: NbToastrService
  ) {
    this.swPush.messages.subscribe(message => {
      if (message) {
        var not = message
        console.log('message',message)
       //this.messageService.add({ severity: 'warn', summary: titulo, detail:  body});
        this.playSound()
      }
    });
  }


  notif(nome, titulo, msg) {
    this.service.swnotifica({ email: nome, titulo: titulo, msg: msg }).subscribe((datax: any[]) => {
      if (datax) {
        console.log(datax)
      }
    });
  }

  playSound() {
    const audio = new Audio('https://sollux.dev/static_cotafacil/not.mp3');
    audio.play();
  }

  public getString(assignee: string) {
    return assignee
      .match(/\b(\w)/g)
      .join("")
      .toUpperCase();
  }
  cardRendered(args: CardRenderedEventArgs): void {
    const val: string = (<{ [key: string]: Object }>args.data).RankId as string;
    addClass([args.element], val);
  }


  getcoment() {
    this.service.getcoment({ ID: this.idfiltr }).subscribe((datax: any[]) => {
      if (datax) {
          this.mdata = datax;
          this.mdata = this.mdata.map(e => ({
            ...e,
            displayTime: formatDistance(new Date(), new Date(e.datetime))
          }));
          console.log('todos', this.mdata)
      }
    });
  }

  teste(id,a,c,d){
    console.log(a)
    for (let i = 0; i < this.todos.length; i++) {
      if (id === this.todos[i]["id"]) {
        this.todos[i]["class"] = 'completed' 
        this.todos[i]["checked"] = true 
        this.todos[i]["na"] = false
        for (let i = 0; i < this.employers.length; i++) {
          if (c === this.employers[i]["nickname"]) {
            this.fimsub(this.employers[i]["wpp"],d)
            this.notif(this.employers[i]["nickname"],"Tarefa Concluída",'☑️ A subtarefa ' + d + ' da tarefa ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi marcada como concluída por ' + this.meunome);
          }
        }
        this.upatodo(i)
        this.notif('Jonnathan Lopes',"Tarefa Concluída", '☑️ A subtarefa ' + d + ' da tarefa ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi marcada como concluída por ' + this.meunome);
        this.notif('Robson Resena',"Tarefa Concluída",'☑️ A subtarefa ' + d + ' da tarefa ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi marcada como concluída por ' + this.meunome);
        this.notif('Francine Pereira',"Tarefa Concluída", '☑️ A subtarefa ' + d + ' da tarefa ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi marcada como concluída por ' + this.meunome);
      }
      else if (id === this.todos[i]["id"]) {
        this.todos[i]["class"] = ''
        this.todos[i]["checked"] = false 
        this.todos[i]["na"] = false

        this.upatodo(i)
      }
    }

  }


  teste2(id,a,c,d){
    console.log(a)
    for (let i = 0; i < this.todos.length; i++) {
      if (id === this.todos[i]["id"] && a === true) {
        this.todos[i]["class"] = 'completed' 
        this.todos[i]["na"] = true
        this.todos[i]["checked"] = false  
        for (let i = 0; i < this.employers.length; i++) {
          if (c === this.employers[i]["nickname"]) {
            this.fimsub(this.employers[i]["wpp"],d)
            this.notif(this.employers[i]["nickname"],"Tarefa Concluída",'☑️ A subtarefa ' + d + ' da tarefa ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi marcada como não se aplica por ' + this.meunome);
          }
        }
        this.upatodo(i)
        this.notif('Jonnathan Lopes',"Tarefa não se aplica", '☑️ A subtarefa ' + d + ' da tarefa ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi marcada como não se aplica por ' + this.meunome);
        this.notif('Robson Resena',"Tarefa não se aplica",'☑️ A subtarefa ' + d + ' da tarefa ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi marcada como não se aplica por ' + this.meunome);
        this.notif('Francine Pereira',"Tarefa não se aplica", '☑️ A subtarefa ' + d + ' da tarefa ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi marcada como não se aplica por ' + this.meunome);
      }
      else if (id === this.todos[i]["id"] && a !== true) {
        this.todos[i]["class"] = ''
        this.todos[i]["na"] = false
        this.todos[i]["checked"] = false 
        this.upatodo(i)
      }
    }

  }
  upatodo(i) {
    this.service.updatetodo({
      task:this.todos[i]["task"],
      checked:this.todos[i]["checked"],
      resp:this.todos[i]["resp"],
      class:this.todos[i]["class"],
      picres:this.todos[i]["picres"],
      id:this.todos[i]["id"],
      modby: this.meunome,
      na:this.todos[i]["na"],
    }).subscribe((data) => {
      if (data) {
        var dax = data;
        if (dax["status"] === "atualizado") {
          this.service.gettarefas({ nome: this.meunome }).subscribe((dados) => {
            if (dados) {
              this.messageService.add({severity:'info', summary: 'Atenção', detail:  'Atualizado com sucesso '});
            }
          });
        }
      }
    });
  }
novocoment(){
  var pic = ''
  for (let i = 0; i < this.employers.length; i++) {
    if (this.meunome === this.employers[i]["nickname"]) {
      pic = this.employers[i]["pic"]
    }
  }
  this.service.insertcommentx({
    user: this.meunome, 
    content: this.inputValue, 
    datetime: new Date(),
    displayTime:formatDistance(new Date(), new Date()), 
    pic: pic,
    idtar: this.idfiltr
  })
  .subscribe(dados => {
    if (dados) {
      console.log(dados);
      if (dados['valido'] === 'atualizado') {
          console.log(dados['valido'])
          //this.filtrawppx2();
          this.adresp = false;  
          this.showSuccess();
          this.getcoment()
        }
      if (dados['status'] === 'erro') {
        console.log('não inserido');
      }
    }
  });
}
sair(){
    var newobserva = this.tarefafiltrada.observadores 
    this.tarefafiltrada.observadores = ''
    let observa = (newobserva).split(';')
    this.sholist = false
    var pic = ''
    var wappob = ''
    for (let m = 0; m < observa.length; m++) {
      if (this.meunome !== observa[m].split(',')[0] && '' !== observa[m].split(',')[0] ) {
        console.log('entrou',observa[m].split(',')[0])
        console.log(this.tarefafiltrada.observadores)
        for (let i = 0; i < this.employers.length; i++) {
          if (observa[m].split(',')[0] === this.employers[i]["nickname"]) {
            pic = this.employers[i]["pic"]
            wappob =  this.employers[i]["wpp"]
          }
        }
        this.tarefafiltrada.observadores = this.tarefafiltrada.observadores + observa[m].split(',')[0] + ','+ pic + ';'
      } 
    }
    console.log(this.tarefafiltrada.observadores)
    this.updatestatus();
}

change(a){
console.log(a)
}

dialsair(sair2){
this.sairs = true;
this.vaisair = sair2
console.log(this.vaisair)
}
sair2(){
  var newobserva = this.tarefafiltrada.observadores 
  this.tarefafiltrada.observadores = ''
  let observa = (newobserva).split(';')
  this.sholist = false
  var pic = ''
  var wappob = ''

  for (let m = 0; m < observa.length; m++) {
    if (this.vaisair !== observa[m].split(',')[0] && '' !== observa[m].split(',')[0] ) {
      console.log('entrou',observa[m].split(',')[0])
      console.log(this.tarefafiltrada.observadores)
      for (let i = 0; i < this.employers.length; i++) {
        if (observa[m].split(',')[0] === this.employers[i]["nickname"]) {
          pic = this.employers[i]["pic"]
          wappob =  this.employers[i]["wpp"]
        }
      }
      this.tarefafiltrada.observadores = this.tarefafiltrada.observadores + observa[m].split(',')[0] + ','+ pic + ';'
    } 
  }
  console.log(this.tarefafiltrada.observadores)
  this.updatestatus();
}
  showx(a) {
    this.filtra(a);
  }

  

  nvtar(){
    var pic= ''
    var wpp = ''
    for (let i = 0; i < this.employers.length; i++) {
      if (this.novoresp === this.employers[i]["nickname"]) {
        pic = this.employers[i]["pic"]
        wpp = this.employers[i]["wpp"]
      }
    }
    this.service.inserttodos({
      task:this.taskx,
      checked:false,
      resp: this.novoresp, 
      class:"", 
      picres: pic,
      idtarefa: this.idfiltr
    })
    .subscribe(dados => {
      if (dados) {
        console.log(dados);
        if (dados['status'] === 'atualizado') {
            console.log(dados['status'])
            //this.filtrawppx2();
            this.adresp = false; 
            this.taskx = "";
            this.enviasub(wpp); 
            this.showSuccess();
            this.gettodos()
          }
        if (dados['status'] === 'erro') {
          console.log('não inserido');
        }
      }
    });
  }
  showSuccess() {
    this.messageService.add({severity:'success', summary: 'Success', detail: 'Atualizado com sucesso!'});
  }
  
  filtra(a) {
    this.idfiltr = a
    this.sholist = false
    this.service.filtramanagerx({ ID: a, managerx: 'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2] }).subscribe((data) => {
      if (data) {
        this.tarefafiltrada = data;
        this.tarefafiltrada.observa =[]
        let observa = (this.tarefafiltrada.observadores).split(';');
        console.log(observa)
        for (let m = 0; m < observa.length-1; m++) {
          this.tarefafiltrada.observa.push({ 'pic': observa[m].split(',')[1],'name': observa[m].split(',')[0] })
        }
        console.log(this.tarefas)
        this.displayx = true;
        this.editacard = true;
      }
    });
  }

  enviasub(a) {
    var grupo = '553892515427-1612924425'
    var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
    //for (let i = 0; i < fones.length; i++) {
      this.service.sendtext({
        sessionName: 'sollux',
        number: a,
        text: '🕘 Uma subtarefa na  tarefa *' + this.tarefafiltrada.Title +  '* do cliente ' + '*' + this.tarefafiltrada.nomecli  +  '*'  +' foi atribuída à você, favor verificar na gestão de tarefas'
      })
        .subscribe(dados => {
          if (dados) {
            console.log(dados);
          }
        });
  }
  fimsub(a,b) {
      console.log(a)
      console.log(b)
      console.log(this.tarefafiltrada.Title)
      console.log(this.tarefafiltrada.nomecli)

      this.service.sendtext({
        sessionName: 'sollux',
        number: a,
        text: '☑️ A subtarefa _*' + b + '*_ da tarefa *' + this.tarefafiltrada.Title +  '* do cliente ' + '*' + this.tarefafiltrada.nomecli  +  '*'  +' foi marcada como concluída por ' + this.meunome
      })
        .subscribe(dados => {
          if (dados) {
            console.log(dados);
          }
        });
  }
  onLoad(args: any) {
    /*Date need to be disabled*/
          if (args.date.getDay() === 0 || args.date.getDay() === 6) {
              args.isDisabled = true;
          }
      }


      gettodos() {
        this.todos = []
        this.service.gettodos({ ID: this.idfiltr }).subscribe((datax: any[]) => {
          if (datax) {
              this.todos = datax;
              console.log('todos', this.todos)
              this.getcoment()
              this.sholist = true
          }
        });
      }

  puxaequipe() {
    this.service.allequipe({ ID: this.idemresa }).subscribe((datax: any[]) => {
      if (datax) {
          console.log('equipe',datax)
          this.employers = datax;
          this.todasminhas()
      }
    });
  }

  public onWeekDayChange(args): void {
    this.tarefafiltrada.Assignee = args.value;
}
  buscarporhomol(){
    this.kanbanData = extend(
      [],
      [],
      null,
      true
    ) as Object[];
    console.log(this.filtronyhomol)
    var nomex = this.filtronyhomol.split('-', 1)
    console.log(this.filtronyhomol.split('-', 1))
    if (  this.meunome === "Jonnathan Lopes" ||
    this.meunome === "Robson Resena" ||
      this.meunome === this.todasmy){  
      this.service.gettarefash({ nome: nomex }).subscribe((dados) => {
      if (dados) {
        this.tarefas = dados;
        this.kanbanData = extend(
          [],
          this.tarefas,
          null,
          true
        ) as Object[];
        this.showToast2("success");
      }
    });}
    else {
      this.naopermitido = true;
    }
  }


  convert(a){
    var nas = a.split("/");
    var d = nas[0];
    var mo = nas[1]
    var an = nas[2]
    console.log(d);
    console.log(mo);
    console.log(an);
    var newDate = mo + '/'+ d + '/'+ an 
    var unixdate = (new Date(newDate +" 00:00:00")).getTime() / 1000;
    return(unixdate)
  }


  showToast2(status: NbComponentStatus) {
    this.toastrService.show(status, `Sucesso: Status atualizado com sucesso!`, {
      status,
    });
  }
  executando(id){
    console.log(id)
    this.iniciar = true
    this.service.filtramanagerx({ ID: id, managerx: 'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2]  }).subscribe((data) => {
      if (data) {
        this.tarefafiltrada = data;
        this.tarefafiltrada.dataexe = new Date();
        this.tarefafiltrada.Status = "Executando";
      }
    })
  }

  concluirx(id){
    console.log(id)
    this.concluir = true
    this.service.filtramanagerx({ ID: id, managerx: 'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2]  }).subscribe((data) => {
      if (data) {
        this.tarefafiltrada = data;
        this.tarefafiltrada.dataconcl = new Date();
        this.tarefafiltrada.Status = "Concluída";
        this.tarefafiltrada.modby = this.meunome;
        this.tarefafiltrada.datamod = new Date();
      }
    })
  }

  mudaco(){
    this.notif('Jonnathan Lopes',"Tarefa Concluída", '☑️ A tarefa  ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi concluída por ' + this.meunome);
    this.notif('Robson Resena',"Tarefa Concluída",'☑️ A tarefa  ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi concluída por ' + this.meunome);
    this.notif('Francine Pereira',"Tarefa Concluída", '☑️ A tarefa  ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi concluída por ' + this.meunome);
    this.updatestatus()
  }
  mudaex(){
    //this.notif('Jonnathan Lopes',"Tarefa Concluída", '☑️ A tarefa  ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi iniciada por ' + this.meunome);
    //this.notif('Robson Resena',"Tarefa Concluída",'☑️ A tarefa  ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi iniciada por ' + this.meunome);
    //this.notif('Francine Pereira',"Tarefa Concluída", '☑️ A tarefa  ' + this.tarefafiltrada.Title +  ' do cliente ' + this.tarefafiltrada.nomecli   +' foi iniciada por ' + this.meunome);
    this.updatestatus()
  }
  updatestatus() {
    if (this.tarefafiltrada.Status === "Transferida") {
      this.filtrawpp();
    }
    this.tarefafiltrada.managerx = 'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2]
    console.log(this.tarefafiltrada)
    this.service.upatarmanagerx2(this.tarefafiltrada).subscribe((data) => {
      if (data) {
        var dax = data;
        if (dax["status"] === "atualizado") {
          this.service.gettarefas({ nome: this.meunome, managerx: 'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2]}).subscribe((dados) => {
            this.displayx = false
            if (dados) {
              this.todasminhas();
              this.messageService.add({severity:'info', summary: 'Atenção', detail:  'Atualizado com sucesso '});
            }
          });
        }
      }
    });
  }
  buscart() {
    this.service.gettarefasx({ nome: this.tarefabus }).subscribe((dados) => {
      if (dados.length > 0) {
        this.tarefafiltrada = dados[0];
        this.editacard = true;
      } else {
        this.errro = true;
      }
    });
  }

  
  buscarpordata() {
    this.service.gettarefasx({ nome: this.tarefabus }).subscribe((dados) => {
      if (dados.length > 0) {
        this.tarefafiltrada = dados[0];
        this.editacard = true;
      } else {
        this.errro = true;
      }
    });
  }
  allmylist() {

    this.service.allmymanager({ nome: this.todasmy }).subscribe((dados) => {
      if (dados.length > 0) {
       console.log(dados)
       this.allmy = dados;
      } else {
        this.errro = true;
      }
    });
 
  }

  
  sytelex(xdatax) {
    let dataf = new Date(Date.now()).toLocaleString("pt-BR").split(" ")[0];
    let datacorretax = dataf.split("/");
    let datacorreta = xdatax.split("/");
    let d = parseFloat(datacorreta[0]);
    let m = parseFloat(datacorreta[1]);
    let an = parseFloat(datacorreta[2]);
    let d2 = parseFloat(datacorretax[0]);
    let m2 = parseFloat(datacorretax[1]);
    let an2 = parseFloat(datacorretax[2]);
    let currentDate = new Date(an2, m2 - 1, d2);
    var datanova = new Date(an, m - 1, d);
    var diff = currentDate.getTime() - datanova.getTime();
    var days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    if (days > 0) {
      return true;
    }
    if (days <= 0) {
      return false;
    }
  }
  bydate(){
    this.kanbanData = extend(
      [],
      [],
      null,
      true
    ) as Object[];
    console.log(this.data1)
    console.log(this.data2)
    var ts1 = this.convert(this.data1);
    var ts2 =this.convert(this.data2);
    if (
      this.meunome === "Jonnathan Lopes" ||
      this.meunome === "Robson Resena" || this.meunome === this.todasdata
    ) {
    this.service.getmanagersdate(
      { nome: this.todasdata,
        t1: ts1,
        t2: ts2, }).subscribe((dados) => {
      if (dados.length > 0) {
       console.log(dados)
       this.tarefas = dados;
       this.kanbanData = extend(
         [],
         this.tarefas,
         null,
         true
       ) as Object[];
       this.showToast2("success");
      } else {
        this.errro = true;
      }
    });}
    else {
      this.naopermitido = true;
    }
  }

  onChange(result): void {
    //console.log('onChange: ', result[0].toISOString());
    console.log(this.datefilter.length)
    if(this.datefilter.length !== 0){
      this.filtradate()
    }
  }

  filtradate(){
    console.log('manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2])
    if(this.filterlink === true){
    this.service.gebtwndate({filterlink: true, quem: this.filtroresp,t1: (new Date(this.datefilter[0])).toISOString().split('T')[0], t2: (new Date(this.datefilter[1])).toISOString().split('T')[0],nome: this.meunome, managerx: 'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2] }).subscribe((dados) => {
      if (dados) {
        this.tarefas = dados;
        for (let x = 0; x < this.tarefas.length; x++) {
          this.tarefas[x].observa = []
          this.tarefas[x].Estimate = (new Date(this.tarefas[x].Estimate * 1000*60).toISOString().substr(11, 8))
          for (let i = 0; i < this.employers.length; i++) {
          if (this.employers[i]["nickname"] === this.tarefas[x].criador) {
            this.tarefas[x].urlcriador = this.employers[i]["pic"]
            console.log('éigual')
          }
          if (this.employers[i]["nickname"] === this.tarefas[x].Assignee) {
            this.tarefas[x].urlAssignee = this.employers[i]["pic"]
            console.log('éigual')
          }
          let observa = (this.tarefas[x].observadores).split(';');
          console.log(observa)
          for (let m = 0; m < observa.length-1; m++) {
            this.tarefas[x].observa.push({ 'pic': observa[m].split(',')[1],'name': observa[m].split(',')[0] })

          }
        }
      }
      console.log(this.tarefas)
      this.criakanba()
    }}) }
    if(this.filterlink === false){
      console.log(this.filterlink)
      this.service.gebtwndate({filterlink: false,quem: this.meunome,t1: (new Date(this.datefilter[0])).toISOString().split('T')[0], t2: (new Date(this.datefilter[1])).toISOString().split('T')[0],nome: this.meunome, managerx: 'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2] }).subscribe((dados) => {
        if (dados) {
          this.tarefas = dados;
          for (let x = 0; x < this.tarefas.length; x++) {
            this.tarefas[x].observa = []
            this.tarefas[x].Estimate = (new Date(this.tarefas[x].Estimate * 1000*60).toISOString().substr(11, 8))
            for (let i = 0; i < this.employers.length; i++) {
            if (this.employers[i]["nickname"] === this.tarefas[x].criador) {
              this.tarefas[x].urlcriador = this.employers[i]["pic"]
              console.log('éigual')
            }
            if (this.employers[i]["nickname"] === this.tarefas[x].Assignee) {
              this.tarefas[x].urlAssignee = this.employers[i]["pic"]
              console.log('éigual')
            }
            let observa = (this.tarefas[x].observadores).split(';');
            console.log(observa)
            for (let m = 0; m < observa.length-1; m++) {
              this.tarefas[x].observa.push({ 'pic': observa[m].split(',')[1],'name': observa[m].split(',')[0] })
  
            }
          }
        }
        console.log(this.tarefas)
        this.criakanba()
      }}) }
}

filterrespo(){
  console.log('manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2])
  if(this.filterlink === true){
  this.service.getresp({filterlink: true, t1: (new Date(this.datefilter[0])).toISOString().split('T')[0], t2: (new Date(this.datefilter[1])).toISOString().split('T')[0],nome: this.filtroresp, managerx: 'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2] }).subscribe((dados) => {
    if (dados) {
      this.tarefas = dados;
      for (let x = 0; x < this.tarefas.length; x++) {
        this.tarefas[x].observa = []
        this.tarefas[x].Estimate = (new Date(this.tarefas[x].Estimate * 1000*60).toISOString().substr(11, 8))
        for (let i = 0; i < this.employers.length; i++) {
        if (this.employers[i]["nickname"] === this.tarefas[x].criador) {
          this.tarefas[x].urlcriador = this.employers[i]["pic"]
          console.log('éigual')
        }
        if (this.employers[i]["nickname"] === this.tarefas[x].Assignee) {
          this.tarefas[x].urlAssignee = this.employers[i]["pic"]
          console.log('éigual')
        }
        let observa = (this.tarefas[x].observadores).split(';');
        console.log(observa)
        for (let m = 0; m < observa.length-1; m++) {
          this.tarefas[x].observa.push({ 'pic': observa[m].split(',')[1],'name': observa[m].split(',')[0] })

        }
      }
    }
    console.log(this.tarefas)
    this.criakanba()
  }}) }
  if(this.filterlink === false){
    this.service.getresp({filterlink: false, nome: this.filtroresp, managerx: 'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2] }).subscribe((dados) => {
      if (dados) {
        this.tarefas = dados;
        for (let x = 0; x < this.tarefas.length; x++) {
          this.tarefas[x].observa = []
          this.tarefas[x].Estimate = (new Date(this.tarefas[x].Estimate * 1000*60).toISOString().substr(11, 8))
          for (let i = 0; i < this.employers.length; i++) {
          if (this.employers[i]["nickname"] === this.tarefas[x].criador) {
            this.tarefas[x].urlcriador = this.employers[i]["pic"]
            console.log('éigual')
          }
          if (this.employers[i]["nickname"] === this.tarefas[x].Assignee) {
            this.tarefas[x].urlAssignee = this.employers[i]["pic"]
            console.log('éigual')
          }
          let observa = (this.tarefas[x].observadores).split(';');
          console.log(observa)
          for (let m = 0; m < observa.length-1; m++) {
            this.tarefas[x].observa.push({ 'pic': observa[m].split(',')[1],'name': observa[m].split(',')[0] })

          }
        }
      }
      console.log(this.tarefas)
      this.criakanba()
    }}) }
}
  mos(){
  console.log(this.filtroresp)
  this.filterrespo()
  }
  filtroa(){
    if(this.filterlink === false){
      this.filterlink = true
    } else {
      this.filterlink = false
    }
  }

  emitirrelatorio(){
    this.service.getrelatoriotar(
      { filterlink: true,
        nome: this.meunome, 
        managerx: 'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2],
        filtrorela : this.filtrorela,
        pendentes : this.pendrel,
        exceutando : this.execrel,
        pradas : this.paradarel,
        concluidas : this.conclurel,
        t1: (new Date(this.datefilter2[0])).toISOString().split('T')[0], 
        t2: (new Date(this.datefilter2[1])).toISOString().split('T')[0]
      })
  }
  ngOnInit(): void {
    this.service.currenturl.subscribe(dados => {
      if (dados !== this.urln && dados !== '') {
        this.urln = dados;
        console.log(this.urln);
      }
    })
    this.service.currentid.subscribe((dados) => {
      if (dados) {
        this.idemresa = dados;
      }
    });
    this.itemsx = [
      {
        label:'Filtro',
        icon:'pi pi-filter',
        command: (event) => {
       this.filtros = true
       this.calendar = false

        }
      },
      {
        label:'Adicionar Tarefa',
        icon:'pi pi-calendar-plus',
        command: (event) => { 
      }
    }
    ,
      {
          label:'Calendário',
          icon:'pi pi-calendar',
          command: (event) => {
            this.calendar = true
          }
      }
  ];
    this.kanbanData = extend(
      [],
      [],
      null,
      true
    ) as Object[];
    this.service.currentNome.subscribe((dados) => {
      if (dados) {
        this.meunome = dados;
        console.log(this.meunome);
      }
    });
    this.user = {
      author: this.meunome,
      avatar: this.urln
    };
    //this.allmylist();
    this.puxaequipe()
  }

  todasminhas(){
    console.log('manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2])
    this.service.gettarefas({ nome: this.meunome, managerx: 'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.idemresa.split('-')[2] }).subscribe((dados) => {
      if (dados) {
        this.tarefas = dados;
        for (let x = 0; x < this.tarefas.length; x++) {
          this.tarefas[x].observa = []
          this.tarefas[x].Estimate = (new Date(this.tarefas[x].Estimate * 1000*60).toISOString().substr(11, 8))
          for (let i = 0; i < this.employers.length; i++) {
          if (this.employers[i]["nickname"] === this.tarefas[x].criador) {
            this.tarefas[x].urlcriador = this.employers[i]["pic"]
            console.log('éigual')
          }
          if (this.employers[i]["nickname"] === this.tarefas[x].Assignee) {
            this.tarefas[x].urlAssignee = this.employers[i]["pic"]
            console.log('éigual')
          }
          let observa = (this.tarefas[x].observadores).split(';');
          console.log(observa)
          for (let m = 0; m < observa.length-1; m++) {
            this.tarefas[x].observa.push({ 'pic': observa[m].split(',')[1],'name': observa[m].split(',')[0] })

          }
        }
      }
      console.log(this.tarefas)
      this.criakanba()
    }})
}

  criakanba(){
    this.kanbanData = extend([], this.tarefas, null, true) as Object[];
  }

  buscartarefas() {
    this.kanbanData = extend(
      [],
      [],
      null,
      true
    ) as Object[];
    console.log(this.meunome);
    if (
      this.meunome === "Jonnathan Lopes" ||
      this.meunome === "Robson Resena"
    ) {
      this.service.gettarefas({ nome: this.filtrorespo }).subscribe((dados) => {
        if (dados) {
          this.tarefas = dados;
          this.kanbanData = extend([], this.tarefas, null, true) as Object[];
        }
      });
    } else {
      this.naopermitido = true;
    }
  }
  adoba(){
    var pic ='';
    var wappob='';
    var newobserva = this.tarefafiltrada.observadores 
    //this.tarefafiltrada.observadores = ''
    let observa = (newobserva).split(';')
    var controle = false
    for (let m = 0; m < observa.length; m++) {
      if (this.novob === observa[m].split(',')[0]) {
        controle = true
      }
    }
    if(controle !== true && this.novob !== this.tarefafiltrada.Assignee && this.novob !== this.tarefafiltrada.criador ){
      for (let i = 0; i < this.employers.length; i++) {
        if (this.novob === this.employers[i]["nickname"]) {
          pic = this.employers[i]["pic"]
          wappob =  this.employers[i]["wpp"]
        }
      }
      this.tarefafiltrada.observadores = this.tarefafiltrada.observadores + this.novob + ','+ pic + ';'
      this.tarefafiltrada.observa.push({user:this.novob, pic:pic})
    }
    this.notificaob(wappob)
    console.log(this.tarefafiltrada.observa)
    this.novob = ''
  this.updatestatus();
  }
  notificaob(a){
    console.log(a)
    this.service.sendtext({
      sessionName: 'sollux',
      number: a,
      text: '☑️ Voce foi inserido como observador da da tarefa *'  + this.tarefafiltrada.Title +  '* do cliente ' + '*' + this.tarefafiltrada.nomecli  +  '*'  +' por ' + this.meunome
    })
      .subscribe(dados => {
        if (dados) {
          console.log(dados);
        }
      });
  }
  adobax(){
    var newobserva = this.tarefafiltrada.observadores 
    //this.tarefafiltrada.observadores = ''
    let observa = (newobserva).split(';')
    var bx = ''
    var controle = false
    for (let m = 0; m < observa.length; m++) {
      if (this.novob === observa[m]  ) {
        controle = true
      }
    }
    if(controle !== true){
      this.tarefafiltrada.observadores = this.tarefafiltrada.observadores + this.novob + ';'
    }
    console.log(this.tarefafiltrada.observadores)
    this.novob = ''
    this.updatestatus();
  }
  buscaratrasos() {
    return new Promise<void>((resolve, reject) => {
      this.atrasadas = [];
      console.log(this.meunome);
      if (
        this.meunome === "Jonnathan Lopes" ||
        this.meunome === "Robson Resena"
      ) {
        for (let i = 0; i < this.tarefas.length; i++) {
          let dataf = new Date(Date.now())
            .toLocaleString("pt-BR")
            .split(" ")[0];
          let datacorretax = dataf.split("/");
          let datacorreta = this.tarefas[i].datafim.split("/");
          let d = parseFloat(datacorreta[0]);
          let m = parseFloat(datacorreta[1]);
          let an = parseFloat(datacorreta[2]);
          let d2 = parseFloat(datacorretax[0]);
          let m2 = parseFloat(datacorretax[1]);
          let an2 = parseFloat(datacorretax[2]);
          let currentDate = new Date(an2, m2 - 1, d2);
          var datanova = new Date(an, m - 1, d);
          var diff = currentDate.getTime() - datanova.getTime();
          var days = Math.ceil(diff / (1000 * 60 * 60 * 24));

          if (days > 0) {
            this.tarefas[i].Status = "Atrasada";
            this.atrasadas.push(this.tarefas[i]);
            this.service.upatarmanagerx2(this.tarefas[i]).subscribe((data) => {
              if (data) {
                var dax = data;
                if (dax["status"] === "atualizado") {
                  this.service
                    .gettarefas({ nome: this.meunome })
                    .subscribe((dados) => {
                      if (dados) {
                        this.tarefas = dados;
                        this.kanbanData = extend(
                          [],
                          this.tarefas,
                          null,
                          true
                        ) as Object[];
                      }
                    });
                }
              }
            });
          }
          if (days <= 0) {
            console.log("maior");
          }
        }
      } else {
        this.naopermitido = true;
      }

      resolve();
    });
  }
  log() {
    this.kanbanData = extend(
      [],
      [],
      null,
      true
    ) as Object[];
    if (
      this.meunome === "Jonnathan Lopes" ||
      this.meunome === "Robson Resena"
    ) {
      this.service.getwppadm({ nome: this.filtrorespo }).subscribe((dados) => {
        if (dados) {
          this.wpp = dados;
          this.service
            .sendtext({
              sessionName: "sollux",
              number: this.wpp.wpp,
              text:
                "⚠️ Atenção você possui " +
                "*" +
                this.atrasadas.length +
                "*" +
                " *tarefas atrasadas.*" +
                " Por favor verifique todas suas tarefas e evite  atrasos.",
            })
            .subscribe((dados) => {
              if (dados) {
                this.showToast2("success");
              }
            });
        }
      });
    } else {
      this.naopermitido = true;
    }
  }

  atrasos() {
    this.buscaratrasos().then((res) => this.log());
  }
  notificas(s) {
    this.filtrawppx(s);
  }
  filtrawppx(s) {
    this.service.getwppadm({ nome: s }).subscribe((dados) => {
      if (dados) {
        this.wpp = dados;
        this.enviacomentax();
      }
    });
  }


  
  OnDragStart(e): void {
    console.log(e)
}

OnDrag(e): void {
  console.log(e)
}

OnDragStop(e): void {
  console.log(e)
}

OnActionComplete(e): void {
  console.log(e)
}

  enviacomentax() {
    var grupo = "553892515427-1612924425";
    var fones = [
      "5538992515427",
      "5538991437790",
      "553899666526",
      "5538988271752",
    ];
    //for (let i = 0; i < fones.length; i++) {
    this.service
      .sendtext({
        sessionName: "sollux",
        number: this.wpp.wpp,
        text:
          "⚠️ Atenção a tarefa " +
          "*" +
          this.tarefafiltrada.Title +
          "*" +
          " da homologação " +
          "*" +
          this.tarefafiltrada.ide +
          "*" +
          " do cliente " +
          "*" +
          this.tarefafiltrada.nomecli +
          "*" +
          " - esta *atrasada* favor verificar pois  a data final para essa tarefa era " +
          "*" +
          this.tarefafiltrada.datafim +
          "*",
      })
      .subscribe((dados) => {
        if (dados) {
          this.showToast2("success");
        }
      });
    // }
  }

  filtrawpp() {
    this.service
      .getwppadm({ nome: this.tarefafiltrada.Assignee })
      .subscribe((dados) => {
        if (dados) {
          this.wpp = dados;
          this.enviacomenta();
        }
      });
  }

  enviacomenta() {
    var grupo = "553892515427-1612924425";
    var fones = [
      "5538992515427",
      "5538991437790",
      "553899666526",
      "5538988271752",
    ];
    //for (let i = 0; i < fones.length; i++) {
    this.service
      .sendtext({
        sessionName: "sollux",
        number: this.wpp.wpp,
        text:
          "⚠️ A tarefa " +
          "*" +
          this.tarefafiltrada.Summary +
          "*" +
          " da homologação " +
          "*" +
          this.tarefafiltrada.ide +
          "*" +
          " do cliente " +
          "*" +
          this.tarefafiltrada.nomecli +
          "*" +
          " - Foi transferida para você, favor verificar na gestão de tarefas ",
      })
      .subscribe((dados) => {
        if (dados) {
        }
      });
    // }
  }
}
