import { todocaixa } from "./../homologacoes/caixa";
import { HomologacoesserviceService } from "./../homologacoes/homologacoesservice.service";
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { LinkService, ImageService, QuickToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { HtmlEditorService, FileManagerService, FileManagerSettingsModel } from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarSettingsModel } from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { isNullOrUndefined as isNOU } from '@syncfusion/ej2-base';
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef,
} from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { FormControl } from "@angular/forms";
import * as $AB from "jquery";
import * as $ from "jquery";
import { MenuItem, MessageService, PrimeNGConfig } from "primeng/api";
import {
  EditSettingsModel,
  ExcelExportService,
  ExcelQueryCellInfoEventArgs,
  FilterService,
  GridComponent,
  IFilter,
  PdfExportService,
  PdfQueryCellInfoEventArgs,
  RowSelectEventArgs,
  ToolbarService,
  VirtualScrollService,
} from "@syncfusion/ej2-angular-grids";
import {
  DropDownListComponent,
  DropDownListModel,
} from "@syncfusion/ej2-angular-dropdowns";
import { CheckBox } from "@syncfusion/ej2-buttons";
import {
  InPlaceEditorComponent,
  RenderMode,
  EditableType,
} from "@syncfusion/ej2-angular-inplace-editor";
import {
  NumericTextBoxModel,
  MaskedTextBoxModel,
  TextBoxModel,
} from "@syncfusion/ej2-inputs";
import { formatDate } from "@angular/common";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { todosdados } from "../homologacoes/todosdados";
import { HOMOTAREFA } from "../homologacoes/taskhomol";
import {
  NbComponentStatus,
  NbDialogService,
  NbToastrService,
} from "@nebular/theme";
import { NgxSpinnerService } from "ngx-spinner";
import { FilesService } from "../files.service";
import { TreeView, DragAndDropEventArgs } from "@syncfusion/ej2-navigations";
import { ListView } from "@syncfusion/ej2-lists";
import { closest } from "@syncfusion/ej2-base";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { ViewEncapsulation, Inject } from "@angular/core";
import { extend, addClass } from "@syncfusion/ej2-base";
import {
  EditService,
  PageService,
  CommandColumnService,
  CommandModel,
} from "@syncfusion/ej2-angular-grids";
import "node_modules/jquery/dist/jquery.min.js";
import "src/assets/js/radiacao.js";
import "src/assets/js/docxtemplater.js";
import "src/assets/js/FileSaver.min.js";
import "src/assets/js/pizzip-utils.js";
import "src/assets/js/pizzip.js";
import "src/assets/js/imagemodule.js";
import "src/assets/js/jszip.js";
import "src/assets/js/jszip-utils.js";
import { ResizeService } from "@syncfusion/ej2-angular-grids";
import {
  AccumulationChart, AccumulationChartComponent, IAccLoadedEventArgs, AccumulationTheme
} from '@syncfusion/ej2-angular-charts';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import ImageModule from "open-docxtemplater-image-module";
import "src/assets/js/dropboxsdk.min.js";
import { FileUploader } from "ng2-file-upload";
import { Dropbox } from "dropbox";
import * as XLSX from "xlsx";
import { NOTIFYS } from "../homologacoes/notificacoes";
import "src/assets/js/chatwd.js";
import { DbxAuth } from './configs';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser'

import {
  TransferChange,
  TransferItem,
  TransferSelectChange,
} from "ng-zorro-antd/transfer";
import * as moment from "moment-business-days";
import { setCulture, loadCldr, L10n } from "@syncfusion/ej2-base";
import { constants } from "fs";
//import { ChatService } from "../chat.service";
import { Router } from "@angular/router";
import * as CryptoJS from 'crypto-js';
import { ReorderService } from '@syncfusion/ej2-angular-grids';

import {
  CardSettingsModel,
  ColumnsModel,
  DialogSettingsModel,
  KanbanComponent,
  SwimlaneSettingsModel,
} from "@syncfusion/ej2-angular-kanban";
import { SelectionService } from '@syncfusion/ej2-angular-grids';
declare let require: any;
L10n.load({
  "pt-BR": {
    grid: {
      EmptyRecord: "Não há registros a serem exibidos",
      GroupDropArea:
        "Arraste um cabeçalho de coluna aqui para grupo a sua coluna",
      DeleteOperationAlert:
        "Não há registros selecionados para a operação de exclusão",
      EditOperationAlert:
        "Não há registros selecionados para a operação de edição",
      SaveButton: "Salvar",
      OKButton: "Está bem",
      CancelButton: "Cancelar",
      EditFormTitle: "Detalhes de ",
      AddFormTitle: "Adicionar novas informações",
      GroupCaptionFormat:
        "{{:headerText}}: {{:key}} - {{:count}} {{if count == 1 }} item {{else}} itens {{/if}} ",
      BatchSaveConfirm: "Tem certeza de que deseja salvar as alterações?",
      BatchSaveLostChanges:
        "As alterações não salvas serão perdidas. Você tem certeza que quer continuar?",
      ConfirmDelete: "Tem certeza de que deseja excluir Record?",
      CancelEdit: "Tem certeza de que deseja cancelar as mudanças?",
      PagerInfo: "{0} de {1} páginas ({2} itens)",
      FrozenColumnsViewAlert: "colunas congeladas devem estar na área gridview",
      FrozenColumnsScrollAlert:
        "Ativar permitem rolagem ao usar colunas congeladas",
      FrozenNotSupportedException:
        "Colunas congeladas e linhas não são suportados para Agrupamento, Template Row, Template Detalhe, Hierarquia Grade e Batch Edição",
      Add: "Adicionar",
      Edit: "Editar",
      Delete: "Excluir",
      Update: "Atualizar",
      Cancel: "Cancelar",
      Done: "Concluído",
      Columns: "Colunas",
      SelectAll: "(Selecionar tudo)",
      PrintGrid: "Impressão",
      ExcelExport: "Exportar para Excel",
      WordExport: "Exportar para Word",
      PdfExport: "Exportar para PDF",
      StartsWith: "Inicia com",
      EndsWith: "Termina com",
      Contains: "contém",
      Equal: "Igual",
      NotEqual: "Não igual",
      PredicateAnd: "E",
      PredicateOr: "OU",
      Filter: "Filtro",
      FilterMenuCaption: "Valor do filtro",
      FilterMenuFromCaption: "A partir de",
      FilterMenuToCaption: "Para",
      FilterbarTitle: "celular barra de filtragem s",
      MatchCase: "Caso de compatibilidade",
      Clear: "Claro",
      ResponsiveFilter: "Filtro",
      ResponsiveSorting: "Ordenar",
      Search: "Pesquisar",
      NumericTextBoxWaterMark: "Colocar o valor",
      DatePickerWaterMark: "Selecionar data",
      EmptyDataSource:
        "DataSource não pode estar vazio na carga inicial desde colunas são geradas a partir dataSource utilizando a Geração Automática do Grid",
      ForeignKeyAlert:
        "O valor atualizado deve ser um valor de chave estrangeira válida",
      True: "Verdadeiro",
      False: "falso",
      UnGroup: "Clique aqui para desagrupar",
      AddRecord: "Adicionar registro",
      EditRecord: "Editar Gravar",
      DeleteRecord: "excluir registro",
      Save: "Salvar",
      Grouping: "Grupo",
      Ungrouping: "Desagrupar",
      SortInAscendingOrder: "Classificar em Ordem Crescente",
      SortInDescendingOrder: "Classificar em Ordem Decrescente",
      NextPage: "Próxima página",
      PreviousPage: "Página anterior",
      FirstPage: "Primeira página",
      LastPage: "Última página",
      EmptyRowValidationMessage: "Pelo menos um campo deve ser atualizado",
      NoResult: "Nenhuma equivalência encontrada",
    },
  },
});
setCulture("pt-BR");

export interface CONFIGURACAO {
  modeloplaca: string;
  marcaplaca: string;
  qtdplaca: string;
  potplaca: string;
  modeloinv: string;
  marcainv: string;
  qtdinv: string;
  potinv: string;
}

export interface FITRO {
  ID: string;
  area: string;
  art: string;
  atividade: string;
  aviso: string;
  bairro: string;
  cargainstalada: string;
  cel: string;
  cep: string;
  cidade: string;
  classe: string;
  classetensao: string;
  codhomol: string;
  complemento: string;
  coords: string;
  cpf: string;
  datafim: string;
  datainicio: string;
  descricao: string;
  digeral: string;
  disjuntoraumentocarga: string;
  disjuntorgeracao: string;
  distanciainversorquadro: string;
  distanciaplacaquadro: string;
  email: string;
  endereco: string;
  entradaareasub: string;
  iddono: string;
  inclinacao: string;
  latitude: string;
  localinst: string;
  longitude: string;
  marcainv1: string;
  marcainv2: string;
  marcainv3: string;
  marcainv4: string;
  marcainv5: string;
  marcaplaca1: string;
  marcaplaca2: string;
  marcaplaca3: string;
  marcaplaca4: string;
  marcaplaca5: string;
  modeloinv1: string;
  modeloinv2: string;
  modeloinv3: string;
  modeloinv4: string;
  modeloinv5: string;
  modeloplaca1: string;
  modeloplaca2: string;
  modeloplaca3: string;
  modeloplaca4: string;
  modeloplaca5: string;
  nome: string;
  numclientecemig: string;
  numerocliente: string;
  numeroinstalacao: string;
  orientacao: string;
  padraoentrada: string;
  pagamentoentrada: string;
  pagamentosegundaparte: string;
  potinv1: string;
  potinv2: string;
  potinv3: string;
  potinv4: string;
  potinv5: string;
  potplaca1: string;
  potplaca2: string;
  potplaca3: string;
  potplaca4: string;
  potplaca5: string;
  potenominalusina: string;
  potkwp: string;
  qtdinv1: string;
  qtdinv2: string;
  qtdinv3: string;
  qtdinv4: string;
  qtdinv5: string;
  qtdplaca1: string;
  qtdplaca2: string;
  qtdplaca3: string;
  qtdplaca4: string;
  qtdplaca5: string;
  responsavel: string;
  rg: string;
  solicitante: string;
  statusdados: string;
  statushomologacao: string;
  statusprojeto: string;
  statusvistoria: string;
  telefone: string;
  tipotelhado: string;
  tipodisjgeracao: string;
  tpdaumentodeCarga: string;
  uf: string;
}

export interface Myhomolg {
  codhomol: string;
  Cliente: string;
  Status: number;
  local: string;
  descricao: string;
  categoria: string;
  Menu: number;
}
export interface Locais {
  value: string;
  viewValue: string;
}
export interface DialogData {
  animal: string;
  name: string;
}

export interface lista {
  key: number;
  description: string;
  title: string;
  tag: string;
  data: any;
  color: string;
  descricao: string;
  tsp: number;
}
export interface kanban {
  Id: string;
  nomecli: string;
  Title: string;
  Status: string;
  Summary: string;
  Type: string;
  Priority: string;
  Tags: string;
  Estimate: string;
  Assignee: string;
  RankId: any;
  Color: string;
  ClassName: string;
  dataini: string;
  datafim: string;
  valor: string;
  ide: string;
  tp1: number;
  tp2: number;
}
/**
 * @title Table with selection
 */
@Component({
  selector: "app-minhashomologacoes",
  templateUrl: "./minhashomologacoes.component.html",
  styleUrls: ["./minhashomologacoes.component.css"],
  providers: [
    ReorderService,
    ToolbarService,
    ExcelExportService,
    PdfExportService,
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateY(-10%)" }),
        animate("200ms ease-in", style({ transform: "translateY(0%)" })),
      ]),
      transition(":leave", [
        animate("200ms ease-in", style({ transform: "translateY(-10%)" })),
      ]),
    ]),
  ],
})
export class MinhashomologacoesComponent implements OnInit {
  caminhoupar = '';
  public selectOptions: { type?: string, mode?: string, allowColumnSelection?: boolean };
  public selectOptionsx: Object;
  @ViewChild('blogRTE')
  public rteObj: RichTextEditorComponent;
  pagamentos = false;
  @ViewChild('rteSubmit')
  public buttonEle: ElementRef;

  @ViewChild('rteCancel')
  public cancelEle: ElementRef;
  filera =false;
  helpesk: any;
  helpcoment = ""
  locale: string;
  editacard = false;
  descricaox: string;
  datafim: string;
  Assignee: string;
  Status: string;
  Title: string;
  kanbanData: Object[];
  tarefas: any;
  validaversao: any;
  pendentes = []
  displays = false;
  @ViewChild("maskedTextBoxEle")
  public maskedTextBoxObj: InPlaceEditorComponent;
  public maskValue: string = "012-345-6789";
  public dReady: boolean = false;
  public dtTime: boolean = false;
  public isDataBound: boolean = false;
  public isDataChanged: boolean = true;
  public intervalFun: any;
  public clrIntervalFun: any;
  public clrIntervalFun1: any;
  public clrIntervalFun2: any;
  public dropSlectedIndex: number = null;
  public stTime: any;
  public data: Object;
  public datarateio: any;
  renderizou = false;
  invalidmsg = "";
  displayerro = false;
  display = false;
  locas = "pt-BR";
  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;
  public selectionSettingsx: Object;

  public height: string = "240px";
  @ViewChild("sample")
  valor1 = 0;
  valor2 = 0;
  wppx: any;
  filtrorespo = "";
  valor3 = 0;
  valor0 = 0;
  codconta = 0;
  notifica = false;
  autoup = false;
  contas = [];
  kanba = [
    {
      Id: "",
      Title: "",
      nomecli: "",
      Status: "",
      Summary: "",
      Type: "",
      Priority: "",
      Tags: "",
      Estimate: "",
      Assignee: "",
      RankId: 1,
      Color: "",
      ClassName: "",
      dataini: "",
      datafim: "",
      valor: "",
      ide: "",
      descricao: "",
      tp1: 0,
      tp2: 0,
      statusdados: "",
      statusproj: "",
      statusvisto: "",
      statusparecer: "",
      data1dados: "",
      data2dados: "",
      data1proj: "",
      data2proj: "",
      data1visto: "",
      data2visto: "",
      data1parecer: "",
      data2parecer: "",
      prococoloparecer: "",
      prococolovistoria: "",
      vencimentoparecer: "",
      atrasoparecer: "",
      atrasovistoria: "",
    },
  ];
  vencimento1 = "";
  vencimento2 = "";
  previsao1 = "";
  previsao2 = "";
  pagamentoentrada: string;
  pagamentosegundaparte: string;
  public editSettings: Object;
  public orderidrules: Object;
  public customeridrules: Object;
  public freightrules: Object;
  public editparams: Object;
  public pageSettings: Object;
  public commands: CommandModel[];
  public listObj: DropDownListComponent;
  @ViewChild("overviewgrid")
  public gridInstance: GridComponent;
  public maskedTextBoxModel: MaskedTextBoxModel = {
    mask: "00/00/0000",
  };

  public fields: Object = { text: "text", value: "value" };
  public item: number[] = [1, 2, 3, 4, 5];
  public autocompleteData: string[] = [
    "Australia",
    "Bermuda",
    "Canada",
    "Cameroon",
    "Denmark",
    "Finland",
    "Greenland",
    "Poland",
  ];
  tasklist: lista[] = [];
  statushomologacaox = [
    "Pendente",
    "Coletando dados",
    "Dados incompletos",
    "Aguardando assinaturas",
    "Concluído",
    "Coleta de dados concluída",
  ];
  statusprojetox = [
    "Pendente",
    "Elaborando projeto",
    "Alterando projeto",
    "Aguardando aprovação",
    "Concluído",
  ];
  statusparecerx = [
    "Pendente",
    "Aguardando parecer",
    "Em correção",
    "Concluído",
  ];
  statusvistoriax = [
    "Pendente",
    "Aguardando vistoria",
    "Vistoria Solicitada",
    "Reprovada",
    "Aguardando troca medidor",
    "Concluído",
  ];

  tipos: Locais[] = [
    { value: "Telhado Cerâmico", viewValue: "Telhado Cerâmico" },
    { value: "Telha Fibrocimento", viewValue: "Telha Fibrocimento" },
    { value: "Telhado Metálico", viewValue: "Telhado Metálico" },
    { value: "Telhado Plástico", viewValue: "Telhado Plástico" },
    { value: "Laje", viewValue: "Laje" },
    { value: "Grama", viewValue: "Grama" },
    { value: "Asfalto", viewValue: "Asfalto" },
    { value: "Concreto", viewValue: "Concreto" },
    { value: "Cascalho", viewValue: "Cascalho" },
  ];
  locais: Locais[] = [
    { value: "Telhado", viewValue: "Telhado" },
    { value: "Laje", viewValue: "Laje" },
    { value: "Solo", viewValue: "Solo" },
    { value: "Grama seca", viewValue: "Grama seca" },
  ];

  public cardSettings: CardSettingsModel = {
    headerField: "Title",
    template: "#cardTemplate",
    selectionType: "Multiple",
  };
  public dialogSettings: DialogSettingsModel = {
    fields: [
      { text: "Tarefa", key: "Title", type: "TextBox" },
      { text: "Status", key: "Status", type: "DropDown" },
      { text: "Responsável", key: "Assignee", type: "DropDown" },
      { text: "Classifição", key: "RankId", type: "TextBox" },
      { text: "descricao", key: "Summary", type: "TextArea" },
    ],
  };
  public swimlaneSettings: SwimlaneSettingsModel = { keyField: "Id" };
  myDate: any;
  public allowDragAndDrop: Boolean = false;
  kanbanview = false;
  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = true;

  colorScheme = {
    domain: [  "#276857", '#3066BE', '#C7B42C', '#556B2F','#FF4500', '#FFFF00', '#F3549C']
  };
  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = [
    "codhomol",
    "solicitante",
    "Cliente",
    "Status",
    "resp",
    "1p",
    "2p",
    "Menu",
  ];
  columnsToDisplay = [
    "codhomol",
    "solicitante",
    "Cliente",
    "Status",
    "resp",
    "1p",
    "2p",
    "Menu",
  ];
  abrehomologacao = "false";
  idstatustar: any;
  recarregou = true;
  filtredhomol: any;
  validaupdade = [];
  status1 = "";
  status2 = "";
  status3 = "";
  status4 = "";
  todastarefas: HOMOTAREFA[];
  configurcao = [];
  inversores = [];
  homolselec: string;
  ref = "/aplicativos/homofold/";
  reload = true;
  path = "";
  pathori = "";
  pagomie1: any;
  pagomie2: any;
  selection = new SelectionModel<todocaixa>(true, []);
  todashomologacoes: todosdados[];
  ntodashomologacoes: todosdados[];
  todosparceiros = [];
  unique = [];
  mostraavisos = false;
  nopDate: any;
  id: string;
  closeResult: string;
  finalizacao = [];
  comentarios = [];
  comment: string;
  commenta: string;
  forum = false;
  reloade: false;
  urln: string;
  meunome: string;
  pago: string;
  name: string;
  categoria = [];
  cor: string;
  corred = "red";
  corblue = "blue";
  trasacselect: string;
  datax: string;
  numerohologacaoes = 0;
  categor: string;
  valor: number;
  mes: number;
  color = "primary";
  filess = false;
  favorecido: string;
  maxFileSize = 30000000000;
  descricao: string;
  okpago: string;
  validacao = [];
  validacao6 = {};
  validacao5 = {};
  validacao4 = {};
  validacao3 = {};
  validacaoup = {};
  responsavex = "Selecione...";
  // variaveistarefa
  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|',
        'CreateLink', '|', 'ClearFormat', 'Print',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
};

  public insertImageSettings: object = {
    saveUrl: "https://www.talktunnels.com/api/_functions/FileManager/Upload2"
    };
// saveFormat: "base64"
  public ImageUploadingEventArgs = {teste: "teste"}
    
  tarefa: string = null;
  rateiorend = false
  dataininiciotarefa: string;
  datafimtarefa: string;
  previsaotarefa: string;
  tipotarefa = "Selecione...";
  statustarefa = "Selecione...";
  responsaveltarefa: string = null;
  // variaveis de controle
  notificacoes = [];
  dataSource = new MatTableDataSource<todosdados>([]);
  esperadados = 0;
  paradas = 0;
  esperaprojeto = 0;
  esperacesso = 0;
  esperavistoria = 0;
  paprovados = 0;
  notifys = true;
  p1 = 0;
  p2 = 0;
  p3 = 0;
  p4 = 0;
  iden: number;
  ideatuliza = [];
  styleproj = "none";
  styleproj2 = "none";
  styleproj3 = "none";
  styleproj4 = "none";
  styleproj5 = "none";
  rascunhoART = false;
  boletoART = false;
  formularioacesso = false;
  ARTassinar = false;
  projeto = false;
  documentoassinar = false;
  checado = false;
  textoenviar = "";
  categoriarecebimento = [
    "Homologação 1º Parcela",
    "Homologação 2º Parcela",
    "Homologação total",
    "Projeto Elétrico",
    "Laudo",
    "Vistoria",
    "Execução",
    "treinamento",
    "Balanço Caixa (Entrada)",
    "Outro",
  ];
  categoriadespesa = [
    "Aluguel",
    "Luz",
    "Água",
    "Internet",
    "Telefone",
    "Outro",
    "Balanço Caixa (Saída)",
  ];

  droptarefa = [
    { value: "Pendente", viewValue: "Pendente" },
    { value: "Atrasada", viewValue: "Atrasada" },
    { value: "Em andamento", viewValue: "Em andamento" },
    { value: "Concluída", viewValue: "Concluída" },
  ];

  arts = [
    { value: "true", viewValue: "Sim" },
    { value: "false", viewValue: "Não" },
  ];

  cols: any[];
  exportColumns: any[];
  @ViewChild("kanbanObj") kanbanObj: KanbanComponent;
  public columns: ColumnsModel[] = [
    { headerText: "Coleta de Dados", keyField: "dados", allowToggle: true },
    { headerText: "Projeto", keyField: "Projeto", allowToggle: true },
    { headerText: "Parecer", keyField: "Parecer", allowToggle: true },
    { headerText: "Vistoria", keyField: "Vistoria", allowToggle: true },
  ];
  quitadeb = "";
  date = new FormControl(new Date());
  serializedDate = new FormControl(new Date().toISOString());
  public animationsDisabled = false;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  validaupload = "false";
  validacaoemail = {};
  ultprogup: number;
  enviado = false;
  naoenviado = false;
  view: any[] = [650, 200];
  single: any[];
  msg = null;
  public initialPage: Object;
  msg1 = "";
  msg0 = "";
  titulo = null;
  xnotificacoes: NOTIFYS[];
  valid: {};
  idslec: any;
  wpp: any;
  tokenmob: string;
  menu1 = true;
  menu2 = false;
  menu3 = false;
  menu4 = false;
  menu5 = false;
  controle = 0;
  atualstatusdados: string;
  atualstatusprojeto: string;
  atualstatushomologacao: string;
  atualstatusvistoria: string;
  clienteatual: string;
  statusenviar = "";
  uploadedFiles: any[] = [];
abreenvia = false

  dbx = new Dropbox({ accessToken: '' });
  // apagar depois
  fileToUpload: File = null;
  //@ViewChild("scrollMe", { static: false })
  timeline = 0;
  tamanhotar = 0;
  data1: any;
  data2: any;
  data3: any;
  data4: any;
  dias1: any;
  dias2: any;
  dias3: any;
  dias4: any;
  datai1: any;
  datai2: any;
  datai3: any;
  datai4: any;
  datafi1: any;
  datafi2: any;
  datafi3: any;
  datafi4: any;
  tarefasx: [];
  delidx = ''
  delhomol = ''
  integra1 = false;
  integra2 = false;
  kabanstats = "fr-FR";
  tokenx:any;
  codig : any;
  adbx: any;
  acesstok:any;
  dbcAutho = [];
  autorizado = 'true';
  public dbxAuth: DbxAuth;
  toolbarSettings1: ToolbarSettingsModel = {
    items: ['FileManager', 'Image']
  };
  mostraspin = false;
  public ajaxSettings: object;
  public toolbarSettings: object;
  caminhando = ''
  public viewx: string;
  public hostUrl: string = "https://www.talktunnels.com/api/_functions/FileManager/";
  titulox: string;
  msx: string;
  integraomie = false;
  intgradoomie: number;
  //TAREFAS
  listx: TransferItem[] = [];
  list: any[];
  taske: any[];
  trule: any;
  disabled = false;
  showSearch = false;
  public pageSettingsx: Object;
  pausar = false;
  public toolbar: string[];
  public toolbarx: string[];
  public editOptions: EditSettingsModel;
  infoAdicionais = [];
  public isInitial: Boolean = true;

  // Hierarchical data source for TreeView component
  productTeam1: lista[] = [];

  taskdisplay = false;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  //TAREFAS
  itemsx: MenuItem[];
  itemsy: MenuItem[];
  numbnot = 0;
  
  constructor(
    private sanitizer: DomSanitizer,
    private messageService: MessageService,
    private service: HomologacoesserviceService,
    private dialogService: NbDialogService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private filesService: FilesService,
   // private _chatService: ChatService,
    private toastrService: NbToastrService,
    private config: PrimeNGConfig
  ) {

    //this._chatService.newUserJoined().subscribe((data) => //console.log(data));

   // this._chatService.onlinestatus().subscribe((data) => //console.log(""));

   // this._chatService.userLeftRoom().subscribe((data) => //console.log(""));

    //this._chatService
     // .newMessageReceivedx()
     // .subscribe((data) => this.apresentamsg(data));
     // this._chatService
     // .newMessageReceived()
     // .subscribe((data) => //console.log(data));
    this.locale = "fr-FR";

    this.uploader = new FileUploader({
      url: "",
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date(),
          });
        });
      },
    });
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.response = "";
    this.uploader.response.subscribe((res) => (this.response = res));
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }



scroler(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}
  
  asyncUpload(i) {
    var caminhouparx = this.ref  + this.caminhoupar + "/"
    this.service.currentpasta.subscribe((dados) => {
      if (dados !== "false") {
        caminhouparx = dados
      }
    });
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line: max-line-length
      this.acesstok
        .filesUpload({
          path: caminhouparx +
            this.uploader.queue[i]["_file"].name,
          contents: this.uploader.queue[i]["_file"],
        })
        // tslint:disable-next-line: only-arrow-functions
        .then((response) => {
          this.enviado = true;
          this.uploader.queue[i]["isUploaded"] = true;
          this.uploader.queue[i]["isSuccess"] = true;
          resolve();
        })
        .catch((error) => reject())
        .finally(() => this.progresso());
    });
  }
  toolbarClicks(args) { 
    if (args.item.text == "Custom") { 
        // Get the selected Files details. 
        //var selectedFiles = fileManager.getSelectedFiles()[0]; 
        // Get the path of FileManager component. 
        //var path = selectedFiles.filterPath + selectedFiles.name;
        ////console.log(path) 
    } 
} 

  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "PDF Export":
        this.gridInstance.pdfExport();
        break;
      case "Exportar Excel":
        this.gridInstance.excelExport();
        break;
      case "Homologados":
        this.renderizou = false;
        this.puxahomologacoes();
        break;
      case "Adicionar":
        this.renderizou = false;
        break;
      case "Editar":
        this.renderizou = false;
        this.puxahomologacoes();
        break;
      case "AS":
        this.renderizou = false;
        this.puxahomologacoes();
        break;
    }
  }

  toolbarClick2(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Adicionar":
        break;
      case "Editar":
        break;
      case "Deletar":
        //this.deletarateio()
        break;
    }
  }

  public onRowSelected(args: RowSelectEventArgs): void {
    const queryData: any = args.data;
    var delcod = queryData.codcliente
  }
  autorizacao() {
      this.tokenx =  localStorage.getItem('tokenx');
      this.service.getdbx({ token: this.tokenx })
      .subscribe((data) => {
        this.codig = data
        this.decryptData(this.codig.cod)}
        )
  }

  interPJ() {
    this.tokenx =  localStorage.getItem('tokenx');
    this.service.interpj()
    .subscribe((data) => {
      ////console.log(data)
    }
      )
}
  decryptData(data:any) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.tokenx);
      if (bytes.toString()) {
        var dadox = (JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        this.adbx = dadox;
        this.autoh();
      }
      return data;
    } catch (e) {
    }
  }

  inserthelp() {
    const d = new Date(Date.now()).toLocaleString("pt-BR");
    this.service
      .helpmsg({
        token: localStorage.getItem("tokenx"),
        name: this.meunome,
        homol: this.homolselec,
        data: new Date(Date.now()), 
        observa: this.helpcoment,
        pic: this.urln,
        id: this.homolselec + "-" + this.helpesk.length
      })
      .subscribe((data) => {
        if (data) {
          this.msgar()
          this.postamsgar()
          this.gethelpmsg();
          this.helpcoment = ""
        }
      });
  }

  gethelpmsg() {
    this.forum = true;
    this.service.gethelpmsg({
    ID: this.homolselec })
      .subscribe(dados => {
        if (dados) {
        this.helpesk = dados
       for (let i = 0; i < this.helpesk.length; i++) {
      const answer: HTMLElement = document.querySelector('.answer') as HTMLElement;
      const cloneAnswer: HTMLElement = answer.cloneNode(true) as HTMLElement;
      const authorName: HTMLElement = cloneAnswer.querySelector('.authorname') as HTMLElement;
      const logo: HTMLElement = cloneAnswer.querySelector('.logos') as HTMLElement;
    
      const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
  "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
];
      var timestamp = this.helpesk[i]['data']
      var date = new Date(timestamp);
    
      logo.style.backgroundImage = "url(" + this.helpesk[i]['pic'] + ")";
          //logo.innerHTML = this.helpesk[i]['name'];
      authorName.innerHTML = this.helpesk[i]['name'];
      const timeZone: HTMLElement = cloneAnswer.querySelector('.detailsAnswer') as HTMLElement;
      const day: string =  date.getDate()  + ' de ' + monthNames[date.getMonth()];
      let hr: string = date.getHours() + ':' + date.getMinutes();
      if (date.getHours() > 12) {
          hr = hr ;
      } else {
          hr = hr ;
      }
      timeZone.innerHTML = 'Enviado em ' + day + ', ' + date.getFullYear() + ' ' + hr;
      const postContent: HTMLElement = cloneAnswer.querySelector('.posting') as HTMLElement;
      postContent.innerHTML = this.helpesk[i]['observa'] ;
      const postElement: HTMLElement = document.querySelector('.answerSection') as HTMLElement;
      postElement.appendChild(cloneAnswer);
      const countEle: HTMLElement = document.querySelector('.answerCount') as HTMLElement;
      this.rteObj.refresh();
      this.forum = true;
      this.rteObj.value = '';
      }
        }
      });

  }
  autoh(){
    this.acesstok =  new Dropbox({ accessToken: this.adbx[0].accessToken });
        // Begin authentication process if credentials not found
      if (this.autorizado === 'true') {
            const authUrl = this.router.url;
            const parameters = authUrl.split('#')[1] || '';
            if (this.autorizado === 'true') {
                const arrParams = parameters.split('&') || [];
                if (this.autorizado === 'true') {
                    const authObj: DbxAuth = { isAuth: false };
                    // tslint:disable-next-line: prefer-for-of
                    authObj.accessToken = this.adbx[0].accessToken;
                    authObj.tokenType = this.adbx[0].tokenType;
                    authObj.uid = this.adbx[0].uid;
                    authObj.accountId = this.adbx[0].accountId;
                    if (authObj.accessToken && authObj.tokenType && authObj.uid && authObj.accountId) {
                        authObj.isAuth = true;
                        this.dbxAuth = authObj;
                    }
                  }
            }
            this.service.changedauth(this.dbxAuth)
            // Store credentials into Auth-service and into localStorage
            // if (this.dbxAuth.isAuth) {
             //    this.authService.storeAuth(this.dbxAuth);
             //    this.podeupar = true;
          //   }
        // } else {
        }
  }
  
  ultimas() {
    this.service
      .getchawpp({
        number: this.wpp.wpp,
        sessionName: "sollux",
            })
      .subscribe((dados) => {
        if (dados) {
        }
      });
  }

  onUpload(event) {
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }

}
  dataBound() {
    if (this.isInitial) {
      this.gridInstance.toolbarModule.toolbar.hideItem(2, true);
      this.isInitial = false;
    }
  }

  exportQueryCellInfo(
    args: ExcelQueryCellInfoEventArgs | PdfQueryCellInfoEventArgs
  ): void {
    if (args.column.headerText === "Employee Image") {
      if ((args as any).name === "excelQueryCellInfo") {
        args.image = {
          height: 75,
          base64: (args as any).data.EmployeeImage,
          width: 75,
        };
      } else {
        args.image = { base64: (args as any).data.EmployeeImage };
      }
    }
    if (args.column.headerText === "Email ID") {
      args.hyperLink = {
        target: "mailto:" + (args as any).data.EmailID,
        displayText: (args as any).data.EmailID,
      };
    }
  }
  exportExcel() {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.ntodashomologacoes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "products");
    });
  }

  apresentamsg(a) {
    this.scroler()
    setTimeout(() => {}, 2000);
    this.join();
    if (a.idono === "483dc174-72c9-4290-bade-2bdc6b39e45a") {
      this.comentarios.push(a);
    }
    if (a.idono !== "483dc174-72c9-4290-bade-2bdc6b39e45a") {
      this.comentarios.push(a);
    }
  }

  join() {
   // this._chatService.joinRoom({ user: this.meunome, room: this.homolselec });
  }

  leave() {
    //this._chatService.leaveRoom({ user: this.meunome, room: this.homolselec });
  }

  notificacao(tit, mg) {
    this.service
      .notificadata({ token: this.tokenmob, titulo: tit, msg: mg })
      .subscribe((dados) => {
        if (dados !== "vp") {
        }
      });
  }
  testes(x){
    this.comment = ''

  }
  sendMessage() {
    this.comment = this.commenta
    this.commenta =''
    this.enviacomenta();
    this.notificachat();
    this.postnotificachat();
    //this.notificacao('Nova Mensagem', 'Você recebeu uma mensagem na homologação de ' + this.filtredhomol.nome)
  
    const dateinici = new Date(Date.now()).toLocaleString("pt-BR");
    //this._chatService.sendMessagex({
    //  msg: this.comment,
     // idono: this.id,
      //hl: this.homolselec,
     // data: dateinici,
     // lida: "0",
     // room: this.homolselec,
     // nome: this.meunome,
     // wpp: this.wpp.wpp,
     // urlpic: "http://solluxengenharia.com.br/static/solluxavatar.png",
   // });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }
  //tarefas

  atribuir2() {
    //this.spinner.show()
    let datacorreta = this.datafim.split("/");
    let d = parseFloat(datacorreta[0]);
    let m = parseFloat(datacorreta[1]);
    let an = parseFloat(datacorreta[2]);
    var newDate = m + "/" + d + "/" + an;
    var unixdate = new Date(newDate + " 00:00:00").getTime() / 1000;
    var ts = unixdate;
    this.kanba = [];
    this.kanba.push({
      tp1: ts,
      tp2: ts,
      Id: this.homolselec,
      Title: this.Title,
      Status: this.Status,
      nomecli: this.filtredhomol.nome,
      Summary: this.Title,
      Type: "Demanda",
      Priority: "High",
      Tags: "Demanda",
      Estimate: "no",
      Assignee: this.responsavex,
      ClassName: "e-story, e-low, e-nancy-davloio",
      dataini: new Date(Date.now()).toLocaleString("pt-BR").split(" ")[0],
      datafim: this.datafim,
      valor: "#8F8173",
      ide: this.homolselec + "-" + (this.tamanhotar + 1),
      RankId: this.tamanhotar + 1,
      Color: "#8F8173",
      descricao: this.descricaox,
      statusdados: this.filtredhomol.statusdados,
      statusproj: this.filtredhomol.statusprojeto,
      statusvisto: this.filtredhomol.statusvistoria,
      statusparecer: this.filtredhomol.statusparecer,
      data1dados: this.filtredhomol.time1f,
      data2dados: this.filtredhomol.time2f,
      data1proj: "",
      data2proj: "",
      data1visto: "",
      data2visto: "",
      data1parecer: "",
      data2parecer: "",
      prococoloparecer: "",
      prococolovistoria: "",
      vencimentoparecer: "",
      atrasoparecer: "",
      atrasovistoria: "",
    });

    this.service.newmanager2(this.kanba).subscribe((dados) => {
      if (dados) {
        if (dados["status"] === "atualizado") {
          this.filtrawppx2();
          this.showToast2("success");
        }
        if (dados["status"] === "erro") {
        }
      }
    });
  }

  gettamanho() {
    this.service.lenmanager({ nome: this.homolselec }).subscribe((dados) => {
      if (dados) {
        this.tamanhotar = dados.tamanho;
      }
    });
  }

  atribuir() {
    //this.spinner.show()

    this.kanba = [];
    for (let i = 0; i < this.tasklist.length; i++) {}
    var controle = 0;
    this.service.newmanager(this.kanba).subscribe((dados) => {
      if (dados) {
        if (dados["status"] === "fim") {
          this.filtredhomol.managerx = "true";
          this.filtrawppx();
          this.upahomoltar();
        }
        if (dados["status"] === "erro") {
        }
      }
    });
  }

  upahomoltar() {
    this.service
      .updatehomolma({
        token: localStorage.getItem("tokenx"),
        managerx: "true",
        codhomol: this.homolselec,
        responsavel: this.responsavex,
      })
      .subscribe((dados) => {
        if (dados) {
          if (dados["status"] === "homologado") {
            this.showToast2("success");
          }
        }
      });
  }

  enviarespon(a) {
    this.service
      .getwppadm({ nome: this.filtredhomol.responsavel })
      .subscribe((dados) => {
        if (dados) {
          this.wppx = dados;
          this.service
            .sendtext({
        sessionName: "sollux",
                            number: this.wppx.wpp,
              text: a,
            })
            .subscribe((dados) => {
              if (dados) {
                
              }
            });
        }
      });
  }

  deletarateio(a,b) {
    this.service
      .delrat({
        token: localStorage.getItem("tokenx"),
        id: b,
        homol: this.homolselec,
        idx: a,
      })
      .subscribe((dados) => {
        if (dados) {
          if (dados) {
            this.modrateio();
            this.filtrarateio();
            this.showToast2("success");
            this.dswrateio()
            this.postadswrateio()
          }
        }
      });
  }

  actionBegin(args: any): void {
    let gridInstance: any = (<any>document.getElementById("Normalgrid"))
      .ej2_instances[0];
    if (args.requestType === "save") {
      if (
        gridInstance.pageSettings.currentPage !== 1 &&
        gridInstance.editSettings.newRowPosition === "Top"
      ) {
        args.index =
          gridInstance.pageSettings.currentPage *
            gridInstance.pageSettings.pageSize -
          gridInstance.pageSettings.pageSize;
      } else if (gridInstance.editSettings.newRowPosition === "Bottom") {
        args.index =
          gridInstance.pageSettings.currentPage *
            gridInstance.pageSettings.pageSize -
          1;
      }
    }
  }
  adicionarateio(){
 this.datarateio.push({
  endereco: "",
  homol: "",
  idempresa: this.id,
  idx: "",
  percentual: 0,
  saldo: 0,
  titular: null,
  uc: "",
 })
  }
  removerateio(){
    this.datarateio.pop()
  }
  uparatex() {
    this.spinner.show();
    var indice = 0
    this.rateiorend = false
    for (let i = 0; i < this.datarateio.length; i++) {
      indice = i;
      if (this.datarateio[i]["idx"] === undefined) {
        this.datarateio[i]["idx"] = ((Date.now() / 1000 )+ i).toFixed(0).toString();
      }
      if (this.datarateio[i]["idx"] ===  "") {
        this.datarateio[i]["idx"] = ((Date.now() / 1000 )+ i).toFixed(0).toString();
      }
      if (this.datarateio[i]["saldo"] === null) {
        this.datarateio[i]["saldo"] = 0;
      }
      if (this.datarateio[i]["homol"] === "") {
        this.datarateio[i]["homol"] = this.homolselec;
      }
      if (this.datarateio[i]["homol"] === "") {
        this.datarateio[i]["homol"] = this.homolselec;
      }
      this.service
        .uparateio({
          token: localStorage.getItem("tokenx"),
          lista: this.datarateio[i],
          locais: {
            id: this.id,
            homol: this.homolselec,
            idx: this.datarateio[i]["idx"],
          },
        })
        .subscribe((dados) => {
          if (dados) {
          }
        });
    }
    if (indice === this.datarateio.length-1) {
    this.datarateio = []
    this.enviourateio()
    this.swrateio()
    this.postaswrateio()
    this.showToast2("success");
    this.spinner.hide();
    this.filtrarateio()
    }
  }

  shox() {
  }

  updateparada() {
    this.service
      .paradahomol({
        token: localStorage.getItem("tokenx"),
        codhomol: this.homolselec,
        statusparada: this.filtredhomol.statusparada,
      })
      .subscribe((dados) => {
        if (dados) {
          if (dados["status"] === "homologado") {
            if (this.filtredhomol.statusparada === "") {
              this.enviaparada2();
              //this.notificacao("Homologação reativada", '⚠️ *Notificação automática* : A homologação ' + this.homolselec + ' - ' + this.clienteatual + ' foi reativada')
              this.showToast2("success");
              this.pausar = false;
            }
            if (this.filtredhomol.statusparada !== "") {
              this.enviaparada();
              //this.notificacao("Homologação parada", '⚠️ *Notificação automática* : A homologação ' + this.homolselec + ' - ' + this.clienteatual + ' encontra-se parada pelo seguinte motivo: ' + this.filtredhomol.statusparada)
              this.showToast2("success");
              this.pausar = false;
            }
          }
        }
      });
  }

  enviaparada() {
    var grupo = "553892515427-1633011809";
    var fones = [
      "5538992515427",
      "5538991437790",
      "553899666526",
      "5538988271752",
    ];
    //for (let i = 0; i < fones.length; i++) {
    if (this.notifys === true) {
      this.service
        .sendtext({
          sessionName: "Sollux_Datasol",
          number: this.wpp.wpp,
          text:
            "⚠️ *Notificação automática* : Sua homologação " +
            "*" +
            this.homolselec +
            " - " +
            this.clienteatual +
            "*" +
            " encontra-se parada pelo seguinte motivo: " +
            "*" +
            this.filtredhomol.statusparada +
            "*"
        })
        .subscribe((dados) => {
          if (dados) {
          }
        });
      // }
    }
  }

  enviaparada2() {
    var grupo = "553892515427-1633011809";
    var fones = [
      "5538992515427",
      "5538991437790",
      "553899666526",
      "5538988271752",
    ];
    //for (let i = 0; i < fones.length; i++) {
    if (this.notifys === true) {
      this.service
        .sendtext({
          sessionName: "Sollux_Datasol",          
          number: this.wpp.wpp,
          text:
            "⚠️ *Notificação automática* : Sua homologação " +
            "*" +
            this.homolselec +
            " - " +
            this.clienteatual +
            "*" +
            " foi reativada",
        })
        .subscribe((dados) => {
          if (dados) {
          }
        });
      // }
    }
  }
  teste() {}
  //tarefas
  async novatar(a) {
    this.service.newmanager(a).subscribe((dados) => {
      if (dados) {
        if (dados["status"] === "atualizado") {
          this.spinner.hide();
          this.showToast2("success");
        }
        if (dados["status"] === "erro") {
        }
      }
    });
  }

  puxanotifi() {
    // tslint:disable-next-line: prefer-for-of
    this.service
      .mynotificacoes({ ID: this.idslec })
      .subscribe((data: NOTIFYS[]) => {
        if (data !== this.xnotificacoes) {
          this.xnotificacoes = data;
        }
      });
  }

  // ngAfterViewInit() {
  // this.dataSource.paginator = this.paginator;
  // }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  displayx() {
    this.display = false;
  }
  eventa(a) {}
  puxacoment(cl) {
    this.service.puxacoment({ ID: cl }).subscribe((data: any[]) => {
      if (data) {
        this.comentarios = data;
        this.scroler()
      }
    });
  }
  setamsg() {
    this.msg0 =
      "⚠️ *Notificação automática* : *Atenção:*\nHomologação: " +
      "*" +
      this.homolselec +
      "* \n" +
      "Cliente: " +
      "*" +
      this.clienteatual +
      "*\n\n";
  }
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([currentUrl]);
  }

  enviacomenta() {
    var grupo = "553892515427-1633011809";
    var fones = [
      "5538992515427",
      "5538991437790",
      "553899666526",
      "5538988271752",
    ];
    //for (let i = 0; i < fones.length; i++) {
    this.service
      .sendtext({
        sessionName: "Sollux_Datasol",
                number: grupo,
        text:
          "⚠️ *Notificação automática* :  O parceiro " +
          "*" +
          this.meunome +
          "*" +
          " fez a seguinte pergunta no datasol " +
          "*" +
          this.clienteatual +
          "* \n" +
          this.comment,
      })
      .subscribe((dados) => {
        if (dados) {
          this.enviarespon(
            "⚠️ *Notificação automática* :  O parceiro " +
          "*" +
          this.meunome +
          "*" +
          " fez a seguinte pergunta no datasol " +
          "*" +
          this.clienteatual +
          "* \n" +
          this.comment,
          );
          this.comment= '';
        }
      });
    
    // }

  }
  modrateio() {
    var grupo = "553892515427-1633011809";
    var fones = [
      "5538992515427",
      "5538991437790",
      "553899666526",
      "5538988271752",
    ];
    //for (let i = 0; i < fones.length; i++) {
    this.service
      .sendtext({
        sessionName: "Sollux_Datasol",
                number: grupo,
        text:
          "⚠️ *Notificação automática* :  O parceiro " +
          "*" +
          this.meunome +
          "*" +
          " modificou rateio do cliente " +
          "*" +
          this.clienteatual +
          ".* \n" +
          "verifique no datasol",
      })
      .subscribe((dados) => {
        if (dados) {
          this.filtrarateio();
        }
      });
    // }
  }
  enviourateio() {
    var grupo = "553892515427-1633011809";
    var fones = [
      "5538992515427",
      "5538991437790",
      "553899666526",
      "5538988271752",
    ];
    //for (let i = 0; i < fones.length; i++) {
    this.service
      .sendtext({
        sessionName: "Sollux_Datasol",
                number: grupo,
        text:
          "⚠️ *Notificação automática* :  O parceiro " +
          "*" +
          this.meunome +
          "*" +
          " solicitou rateio para o cliente " +
          "*" +
          this.clienteatual +
          ".* \n" +
          "verifique no datasol",
      })
      .subscribe((dados) => {
        if (dados) {
          this.filtrarateio();
        }
      });
    // }
  }

  enviacoment() {
    const dateinici = new Date(Date.now()).toLocaleString("pt-BR");
    this.service.novocomment2({
      token: localStorage.getItem('tokenx'),
      'msg': this.comment,
      'id': 's',
      'idono': this.id,
      'hl': this.homolselec,
      'data': dateinici,
      'st': 'false',
      'nome': this.meunome,
      'urlpic': this.urln,
    })
      .subscribe(dados => {
        if (dados ) {
        }
      });
    this.service
      .novocomment({
        token: localStorage.getItem("tokenx"),
        msg: this.comment,
        id: "s",
        idono: this.id,
        hl: this.homolselec,
        data: dateinici,
        lida: "1",
        nome: this.meunome,
        urlpic: this.urln,
      })
      .subscribe((dados) => {
        if (dados !== this.validacao3) {
          this.validacao3 = dados;
          if (dados["status"] === "homologado") {
            this.comment = "";
            this.comentarios = [];
            this.puxacoment(this.homolselec);
            //this.enviacomenta()
          }
          if (dados["status"] === "erro") {
            this.comment = "";
            this.comentarios = [];
            this.puxacoment(this.homolselec);
          }
        }
      });
    this.validacao3 = { };
    //this.enviacomenta()
  }

  inserenotifi() {
    const d = new Date(Date.now()).toLocaleString("pt-BR");
    this.datax = d.split(" ")[0];
    this.service
      .novanoti({
        token: localStorage.getItem("tokenx"),
        nome: this.filtredhomol["nome"],
        idempresa: this.filtredhomol["iddono"],
        msg: this.msg,
        recebido: "0",
        lido: "0",
        data: this.datax,
        resolvido: "0",
        titulo: this.titulo,
      })
      .subscribe((data) => {
        if (data !== this.valid) {
          this.valid = data;
          this.puxanotifi();
        }
      });
  }

  zeranot() {
    this.xnotificacoes = [];
  }

  delenot(i) {
    this.service.delnoti({ ID: i }).subscribe((dados) => {
      if (dados !== this.validacao6) {
        if (dados["status"] === "atualizado") {
          this.puxanotifi();
        }
        if (dados["status"] === "erro") {
        }
      }
    });
    this.validacao6 = {};
  }
  progresso() {

    this.ultprogup = 100 / this.uploader.queue.length;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (this.uploader.queue[i]["isSuccess"] === true) {
        this.uploader.progress = this.ultprogup * (i + 1);
      }
    }
    if (this.uploader.progress === 100) {
          this.enviouarquivo();
          this.notificaarqu()
      this.abreenvia = false
      this.validaupload = "true";
      this.uploader.clearQueue();
      this.filesService.getFiles(this.path);
    }
  }
  // ENVIA NOTIFICACAO WHAT
  enviouarquivo() {
    var grupo = "553892515427-1633011809";
    var fones = [
      "5538992515427",
      "5538991437790",
      "553899666526",
      "5538988271752",
    ];
    this.service
      .sendtext({
        sessionName: "Sollux_Datasol",
                number: grupo,
        text:
          "⚠️ *Notificação automática* :  O parceiro " +
          "*" +
          this.meunome +
          "*" +
          " anexou arquivos na pasta da homologação " +
          "*" +
          this.homolselec +
          " - " +
          this.clienteatual +
          "*",
      })
      .subscribe((dados) => {
        if (dados) {
        }
      });
  }

  enviouarquivod() {
    var grupo = "553892515427-1633011809";
    var fones = [
      "5538992515427",
      "5538991437790",
      "553899666526",
      "5538988271752",
    ];
    this.service
      .sendtext({
        sessionName: "Sollux_Datasol",
                number: grupo,
        text:
          "⚠️ *Notificação automática* :  O parceiro " +
          "*" +
          this.meunome +
          "*" +
          " deletou arquivos na pasta da homologação " +
          "*" +
          this.homolselec +
          " - " +
          this.clienteatual +
          "*",
      })
      .subscribe((dados) => {
        if (dados) {
        }
      });
  }



  rascART(a) {
    this.rascunhoART = a;
  }
  bolART(a) {
    this.boletoART = a;
  }
  formace(a) {
    this.formularioacesso = a;
  }
  artas(a) {
    this.ARTassinar = a;
  }
  proj(a) {
    this.projeto = a;
  }
  docass(a) {
    this.documentoassinar = a;
  }

  async uploadalgo() {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      await this.asyncUpload(i);
    }
  }

  // tslint:disable-next-line: member-ordering
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  getMyStyles() {
    const MyStyles = {
      "background-color": this.status1 === "1" ? "#0A1591" : "#83859B",
    };
    return MyStyles;
  }
  getMyStyles2() {
    const MyStyles2 = {
      "background-color": this.status2 === "1" ? "#0A1591" : "#83859B",
    };
    return MyStyles2;
  }

  getMyStyles3() {
    const MyStyles3 = {
      "background-color": this.status3 === "1" ? "#0A1591" : "#83859B",
    };
    return MyStyles3;
  }
  getMyStyles4() {
    const MyStyles4 = {
      "background-color": this.status4 === "1" ? "#0A1591" : "#83859B",
    };
    return MyStyles4;
  }

  ntifica() {
    if (this.notifica === true) {
      this.atulizoustatus();
    }
    if (this.notifica === false) {
      this.updatestatus();
    }
  }
  atulizoustatus() {
    if (
      this.atualstatusdados !== this.filtredhomol.statusdados ||
      this.atualstatusprojeto !== this.filtredhomol.statusprojeto ||
      this.atualstatushomologacao !== this.filtredhomol.statushomologacao ||
      this.atualstatusvistoria !== this.filtredhomol.statusvistoria
    ) {
      if (this.atualstatusdados !== this.filtredhomol.statusdados) {
        this.statusenviar =
          "*Coleta de dados:* \n" + this.filtredhomol.statusdados + "\n ";
        this.atualstatusdados = this.filtredhomol.statusdados;
        var xstatusenviar =
          " Coleta de dados: " + this.filtredhomol.statusdados;
      }
      if (this.atualstatusprojeto !== this.filtredhomol.statusprojeto) {
        this.atualstatusprojeto = this.filtredhomol.statusprojeto;
        this.statusenviar =
          this.statusenviar +
          "*Elaboração do projeto:* \n" +
          this.filtredhomol.statusprojeto +
          "\n ";
        var xstatusenviar =
          xstatusenviar +
          " Elaboração do projeto: " +
          this.filtredhomol.statusprojeto;
      }
      if (this.atualstatushomologacao !== this.filtredhomol.statushomologacao) {
        this.atualstatushomologacao = this.filtredhomol.statushomologacao;
        this.statusenviar =
          this.statusenviar +
          "*Parecer de acesso:* \n" +
          this.filtredhomol.statushomologacao +
          "\n ";
        var xstatusenviar =
          xstatusenviar +
          " Parecer de acesso: " +
          this.filtredhomol.statushomologacao;
      }
      if (this.atualstatusvistoria !== this.filtredhomol.statusvistoria) {
        this.atualstatusvistoria = this.filtredhomol.statusvistoria;
        this.statusenviar =
          this.statusenviar +
          "*Vistoria:* \n" +
          this.filtredhomol.statusvistoria +
          "\n ";
        var xstatusenviar =
          xstatusenviar + " Vistoria: " + this.filtredhomol.statusvistoria;
      }
      var msg =
        "⚠️ *Notificação automática* : *Atenção:*\n Sua homologação " +
        "*" +
        this.homolselec +
        "* " +
        "do cliente  " +
        "*" +
        this.clienteatual +
        "*" +
        " mudou de status:\n\n" +
        this.statusenviar;
      var msg2 =
        "⚠️ *Notificação automática* :  Sua homologação " +
        this.homolselec +
        "-" +
        this.clienteatual +
        " mudou de status:" +
        xstatusenviar;
      this.statusenviar = "";
    }
    this.enviamsg(msg);
    //this.notificacao('Alteração de Status', msg2)
    this.updatestatus();
  }
  menumuda(a) {
    if (a === 1) {
      this.menu1 = true;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false;
    }
    if (a === 2) {
      this.menu1 = false;
      this.menu2 = true;
      this.menu3 = false;
      this.menu4 = false;
      this.gethelpmsg();
    }
    if (a === 3) {
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = true;
      this.menu4 = false;        
      this.toolbarSettings = { items: ['NewFolder', 'Upload', 'Cut', 'Copy', 'Paste', 'Delete', 'Download', 'Rename', 'SortBy', 'Refresh', 'Selection', 'View', 'Details'], visible: true}
      this.ajaxSettings = {
        path2: this.homolselec,
        url: this.hostUrl + 'apm',
        showHiddenItems: true,
        getImageUrl: this.hostUrl + 'GetImage',
        uploadUrl: this.hostUrl + 'Upload',
        downloadUrl: this.hostUrl + 'Download'
    };
    }
    if (a === 4) {
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = true;
      this.scrollToBottom();
    }
  }
  enviamsg2() {
    this.enviamsg(this.msg0 + this.msg1);
  }

  ajax(){
    this.ajaxSettings = {
      path2: this.homolselec,
      url: this.hostUrl + 'apm',
      showHiddenItems: true,
      getImageUrl: this.hostUrl + 'GetImage',
      uploadUrl: this.hostUrl + 'Upload',
      downloadUrl: this.hostUrl + 'Download'
  };
  }
  fileOpen(args) {
    //You can get the double clicked file/folder details in this event argument. Check the arguments below.

    // cancel - If you want to cancel this event then, set cancel to true. Otherwise, false.
    // fileDetails - Return the currently selected item as JSON object.
    // module - Returns the name of the target module in file manager.

    //You can alse check whether the clicked item is a file or not using the fileDetails isFile property.
    if (!args.fileDetails.isFile) {
      //args.cancel = true

    }
  }

  postaemail() {
    this.service
      .postaemail(
        {
          "fromAddress": "jonnathan.lopes@solluxengenharia.com.br",
          "toAddress": "escritorio.projetos@solluxengenharia.com.br,engenharia@solluxengenharia.com.br",
          "subject": "Arquivos recebidos",
          "content": "💾 O parceiro " +
          this.meunome +
          " anexou arquivos na pasta da homologação " +
          this.homolselec +
          " - " +
          this.clienteatual
       }
      )
      .subscribe((dados) => {
        this.showToast2("success");
        this.filera = false
        ////console.log(dados)
      });
  }
  postaemaild() {
    this.service
      .postaemail(
        {
          "fromAddress": "jonnathan.lopes@solluxengenharia.com.br",
          "toAddress": "escritorio.projetos@solluxengenharia.com.br,engenharia@solluxengenharia.com.br",
          "subject": "Arquivos deletados",
          "content": "💾 O parceiro " + this.meunome + " Deletou arquivos da pasta da homologação " + this.homolselec + " - " + this.clienteatual
       }
      )
      .subscribe((dados) => {
        ////console.log(dados)
      });
  }
OnbeforeSend (args){
  ////console.log(args)
  if(args.action==="Upload"){
    this.filera = true
    //this.enviouarquivo();
    //this.postaemail();
    //this.notificaarqu();
    var data = JSON.parse(args.ajaxSettings.data);  
    // Add custom parameter userid  
     data.push({"path2":this.homolselec}); 
     data.push({"myid":this.id}); 
    // Add custom parameter in ajax settings  
     args.ajaxSettings.data = JSON.stringify(data); 
  } 
  if(args.action==="delete"){
   this.enviouarquivod();
   this.postaemaild() 
  } 
  var data = JSON.parse(args.ajaxSettings.data);  
  // Add custom parameter userid  
   data["path2"] = this.homolselec; 
   data["myid"] = this.id; 
   args.ajaxSettings.data = JSON.stringify(data); 

  } 
  enviamsg(msg) {
    this.service
      .sendtext({
        sessionName: "Sollux_Datasol",
                number: this.wpp.wpp,
        text: msg,
      })
      .subscribe((dados) => {
        if (dados !== this.validacao4) {
          this.validacao4 = dados;
        }
      });
    this.validacao4 = {};
  }
  filtrawpp() {
    this.service.getwpp({ id: this.idslec }).subscribe((dados) => {
      if (dados) {
        this.wpp = dados;
        this.intgradoomie = this.wpp.intgradoomie;
        this.tokenmob = this.wpp.mob;
      }
    });
  }

  clientepnael() {
    this.puxahomologacoes();
    this.abrehomologacao = 'false'
    this.menu1 = true;
    this.menu2 = false;
    this.menu3 = false;
    this.menu4 = false;
    this.menu5 = false;
  }
  filtratable(a) {
    this.ntodashomologacoes = [];
    if (a === "Coleta de dados") {
      for (let i = 0; i < this.todashomologacoes.length; i++) {
        if (this.todashomologacoes[i]["momento"] === "1") {
          this.ntodashomologacoes.push(this.todashomologacoes[i]);
        }
      }
    }
    if (a === "Projeto em andamento") {
      for (let i = 0; i < this.todashomologacoes.length; i++) {
        if (this.todashomologacoes[i]["momento"] === "2") {
          this.ntodashomologacoes.push(this.todashomologacoes[i]);
        }
      }
    }
    if (a === "Aguardando parecer") {
      for (let i = 0; i < this.todashomologacoes.length; i++) {
        if (this.todashomologacoes[i]["momento"] === "3") {
          this.ntodashomologacoes.push(this.todashomologacoes[i]);
        }
      }
    }
    if (a === "Aguardando vistoria") {
      for (let i = 0; i < this.todashomologacoes.length; i++) {
        if (this.todashomologacoes[i]["momento"] === "4") {
          this.ntodashomologacoes.push(this.todashomologacoes[i]);
        }
      }
    }
    if (a === "Homologado") {
      for (let i = 0; i < this.todashomologacoes.length; i++) {
        if (this.todashomologacoes[i]["momento"] === "0") {
          this.ntodashomologacoes.push(this.todashomologacoes[i]);
        }
      }
    }

    this.dataSource = new MatTableDataSource<todosdados>(
      this.ntodashomologacoes
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  filtracliente(ab) {
    // tslint:disable-next-line: prefer-for-of
    this.service.filtralogin({ ID: this.idslec }).subscribe((data) => {
      if (data) {
      }
    });
  }

  filtrapa1(a) {
    this.ntodashomologacoes = [];
    if (a === "Pendente") {
      for (let i = 0; i < this.todashomologacoes.length; i++) {
        if (this.todashomologacoes[i]["pagamentoentrada"] === "Pendente") {
          this.ntodashomologacoes.push(this.todashomologacoes[i]);
        }
      }
    }
    if (a === "Pago") {
      for (let i = 0; i < this.todashomologacoes.length; i++) {
        if (this.todashomologacoes[i]["pagamentoentrada"] !== "Pendente") {
          this.ntodashomologacoes.push(this.todashomologacoes[i]);
        }
      }
    }
    this.dataSource = new MatTableDataSource<todosdados>(
      this.ntodashomologacoes
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  filtraresp(a) {
    this.ntodashomologacoes = [];
    for (let i = 0; i < this.todashomologacoes.length; i++) {
      if (this.todashomologacoes[i]["responsavel"] === a) {
        this.ntodashomologacoes.push(this.todashomologacoes[i]);
      }
    }
    this.dataSource = new MatTableDataSource<todosdados>(
      this.ntodashomologacoes
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  filtrapa2(a) {
    this.ntodashomologacoes = [];
    if (a === "Pendente") {
      for (let i = 0; i < this.todashomologacoes.length; i++) {
        if (this.todashomologacoes[i]["pagamentosegundaparte"] === "Pendente") {
          this.ntodashomologacoes.push(this.todashomologacoes[i]);
        }
      }
    }
    if (a === "Pago") {
      for (let i = 0; i < this.todashomologacoes.length; i++) {
        if (this.todashomologacoes[i]["pagamentosegundaparte"] !== "Pendente") {
          this.ntodashomologacoes.push(this.todashomologacoes[i]);
        }
      }
    }
    this.dataSource = new MatTableDataSource<todosdados>(
      this.ntodashomologacoes
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  upatarefa() {
    this.service
      .upatarefahomol({
        token: localStorage.getItem("tokenx"),
        descricao: this.todastarefas[this.idstatustar].descricao,
        datainicio: this.todastarefas[this.idstatustar].datainicio,
        datafim: this.todastarefas[this.idstatustar].datafim,
        status: this.todastarefas[this.idstatustar].status,
        responsavel: this.todastarefas[this.idstatustar].responsavel,
        ID: this.todastarefas[this.idstatustar].ID,
      })
      .subscribe((dados) => {
        if (dados !== this.validacao5) {
          this.validacao5 = dados;
          if (dados["status"] === "atualizado") {
            this.todastarefas = [] as HOMOTAREFA[];
            this.puxatarefas(this.homolselec);
          }
          if (dados["status"] === "erro") {
          }
        }
      });
    this.validacao5 = {};
  }
  showToast2(status: NbComponentStatus) {
    this.toastrService.show(status, `Sucesso: Status atualizado com sucesso!`, {
      status,
    });
  }

  updatestatus() {
    this.service
      .updatestaus({
        token: localStorage.getItem("tokenx"),
        protocoloparecer: this.filtredhomol.protocoloparecer,
        protocolovistoria: this.filtredhomol.protocolovistoria,
        time1: this.filtredhomol.time1,
        time2: this.filtredhomol.time2,
        time3: this.filtredhomol.time3,
        time4: this.filtredhomol.time4,
        time1f: this.filtredhomol.time1f,
        time2f: this.filtredhomol.time2f,
        time3f: this.filtredhomol.time3f,
        time4f: this.filtredhomol.time4f,
        statusdados: this.filtredhomol.statusdados,
        statusprojeto: this.filtredhomol.statusprojeto,
        statushomologacao: this.filtredhomol.statushomologacao,
        statusvistoria: this.filtredhomol.statusvistoria,
        codhomol: this.homolselec,
      })
      .subscribe((dados) => {
        if (dados) {
          if (dados["status"] === "homologado") {
            this.showToast2("success");
          }
        }
      });
  }

  updatehomol() {
    this.service
      .updatehomol({
        token: localStorage.getItem("tokenx"),
        nome: this.filtredhomol.nome,
        endereco: this.filtredhomol.endereco,
        cep: this.filtredhomol.cep,
        cidade: this.filtredhomol.cidade,
        uf: this.filtredhomol.uf,
        cpf: this.filtredhomol.cpf,
        email: this.filtredhomol.email,
        cel: this.filtredhomol.cel,
        classe: this.filtredhomol.classe,
        atividade: this.filtredhomol.atividade,
        potkwp: this.filtredhomol.potkwp,
        area: this.filtredhomol.area,
        localinst: this.filtredhomol.localinst,
        tipotelhado: this.filtredhomol.tipotelhado,
        coords:
          this.filtredhomol.latitude + " , " + this.filtredhomol.longitude,
        descricao: this.filtredhomol.descricao,
        distanciaplacaquadro: this.filtredhomol.distanciaplacaquadro,
        distanciainversorquadro: this.filtredhomol.distanciainversorquadro,
        padraoentrada: this.filtredhomol.padraoentrada,
        digeral: this.filtredhomol.digeral,
        statusdados: this.filtredhomol.statusdados,
        statusprojeto: this.filtredhomol.statusprojeto,
        statushomologacao: this.filtredhomol.statushomologacao,
        statusvistoria: this.filtredhomol.statusvistoria,
        modeloplaca1: this.configurcao[0].modeloplaca,
        entradaareasub: this.filtredhomol.entradaareasub,
        art: this.filtredhomol.art,
        disjuntoraumentocarga: this.filtredhomol.disjuntoraumentocarga,
        solicitante: this.filtredhomol.solicitante,
        iddono: this.filtredhomol.iddono,
        bairro: this.filtredhomol.bairro,
        longitude: this.filtredhomol.longitude,
        latitude: this.filtredhomol.latitude,
        numerocliente: this.filtredhomol.numerocliente,
        numeroinstalacao: this.filtredhomol.numeroinstalacao,
        numclientecemig: this.filtredhomol.numclientecemig,
        classetensao: this.filtredhomol.classetensao,
        qtdplaca1: this.configurcao[0].qtdplaca,
        marcaplaca1: this.configurcao[0].marcaplaca,
        disjuntorgeracao: this.filtredhomol.disjuntorgeracao,
        tipodisjgeracao: this.filtredhomol.tipodisjgeracao,
        tpdaumentodeCarga: this.filtredhomol.tpdaumentodeCarga,
        cargainstalada: this.filtredhomol.cargainstalada,
        pagamentoentrada: this.filtredhomol.pagamentoentrada,
        pagamentosegundaparte: this.filtredhomol.pagamentosegundaparte,
        responsavel: this.filtredhomol.responsavel,
        complemento: this.filtredhomol.complemento,
        marcainv1: this.configurcao[0].marcainv,
        qtdinv1: this.configurcao[0].qtdinv,
        modeloinv1: this.configurcao[0].modeloinv,
        potinv1: this.configurcao[0].potinv,
        marcainv2: this.configurcao[1].marcainv,
        modeloinv2: this.configurcao[1].modeloinv,
        qtdinv2: this.configurcao[1].qtdinv,
        potinv2: this.configurcao[1].potinv,
        marcainv3: this.configurcao[2].marcainv,
        modeloinv3: this.configurcao[2].modeloinv,
        qtdinv3: this.configurcao[2].qtdinv,
        potinv3: this.configurcao[2].potinv,
        marcainv4: this.configurcao[3].marcainv,
        modeloinv4: this.configurcao[3].modeloinv,
        qtdinv4: this.configurcao[3].qtdinv,
        potinv4: this.configurcao[3].potinv,
        marcainv5: this.configurcao[4].marcainv,
        modeloinv5: this.configurcao[4].modeloinv,
        qtdinv5: this.configurcao[4].potinv,
        potinv5: this.configurcao[4].qtdinv,
        potenominalusina: this.filtredhomol.potenominalusina,
        potplaca1: this.configurcao[0].potplaca,
        marcaplaca2: this.configurcao[1].marcaplaca,
        modeloplaca2: this.configurcao[1].modeloplaca,
        qtdplaca2: this.configurcao[1].qtdplaca,
        potplaca2: this.configurcao[1].potplaca,
        marcaplaca3: this.configurcao[2].marcaplaca,
        modeloplaca3: this.configurcao[2].modeloplaca,
        qtdplaca3: this.configurcao[2].qtdplaca,
        potplaca3: this.configurcao[2].potplaca,
        marcaplaca4: this.configurcao[3].marcaplaca,
        modeloplaca4: this.configurcao[3].modeloplaca,
        qtdplaca4: this.configurcao[3].qtdplaca,
        potplaca4: this.configurcao[3].potplaca,
        marcaplaca5: this.configurcao[4].marcaplaca,
        modeloplaca5: this.configurcao[4].modeloplaca,
        qtdplaca5: this.configurcao[4].qtdplaca,
        potplaca5: this.configurcao[4].potplaca,
        codhomol: this.homolselec,
        protocoloparecer: this.filtredhomol.protocoloparecer,
        protocolovistoria: this.filtredhomol.protocolovistoria,
        pagomie1: this.filtredhomol.pagomie1,
        pagomie2: this.filtredhomol.pagomie2,
        managerx: this.filtredhomol.managerx,
      })
      .subscribe((dados) => {
        if (dados) {
          this.validacaoup = dados;
          if (dados["status"] === "homologado") {
            this.showToast2("success");
          }
        }
      });
    this.validacaoup = {};
  }

  puxatarefas(cl) {
    this.service.tarefashomol({ ID: cl }).subscribe((data: HOMOTAREFA[]) => {
      if (data !== this.todastarefas) {
        this.todastarefas = data;
      }
    });
  }
  filtrarateio() {
    this.service
      .filtrarateio({ idempresa: this.id, homol: this.homolselec })
      .subscribe((data) => {
        this.datarateio = data;
        this.editSettings = {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Normal",
          allowEditOnDblClick: false,
        };
        this.orderidrules = { required: false };
        this.customeridrules = { required: true };
        this.freightrules = { required: true };
        this.editparams = { params: { popupHeight: "300px" } };
        this.pageSettings = { pageCount: 5 };
        this.commands = [
          {
            type: "Edit",
            buttonOption: { iconCss: " e-icons e-edit", cssClass: "e-flat" },
          },
          {
            type: "Delete",
            buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
          },
          {
            type: "Save",
            buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
          },
          {
            type: "Cancel",
            buttonOption: {
              iconCss: "e-icons e-cancel-icon",
              cssClass: "e-flat",
            },
          },
        ];
        this.menumuda(4);
      });
    this.rateiorend = true;
  }
  notificatodos() {
    this.service.swnotifica({ titulo: 'Vistoria Solicitada', msg: '⚠️ *Notificação automática* :  O parceiro '  + this.meunome  + ' Solicitou vistoria para homologação ' +  this.homolselec + '-' + this.filtredhomol['Cliente'] }).subscribe((datax: any[]) => {
      if (datax) {
      }
    });
  }
  enviandofiles(){
    this.enviouarquivo();
    this.postaemail();
    this.notificaarqu();
  }
  postanotificatodos() {
    this.service
      .postaemail(
        {
          "fromAddress": "jonnathan.lopes@solluxengenharia.com.br",
          "toAddress": "escritorio.projetos@solluxengenharia.com.br,engenharia@solluxengenharia.com.br",
          "subject": "Vistoria Solicitada",
          "content":'⚠️ *Notificação automática* :  O parceiro '  + this.meunome  + ' Solicitou vistoria para homologação ' +  this.homolselec + '-' + this.filtredhomol['Cliente']
       }
      )
      .subscribe((dados) => {
        ////console.log(dados)
      });
  }
  upavisto(){
    // tslint:disable-next-line: max-line-length
    let val: any;
    this.service.upastatusvisto({
      token: localStorage.getItem('tokenx'),
      statusvistoria: 'Solicitada pelo cliente',
      codhomol: this.homolselec,
      msgav: "Vistoria" })
    .subscribe((data) => {
      if (data !== val) {
        val = data;
        //this.geravistoria();
      }
    });
  }
  enviouvisto() {
    var grupo = "553892515427-1633011809";
    var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
    //for (let i = 0; i < fones.length; i++) {
    this.service.sendtext({
      sessionName: 'sollux',
      number: grupo,
      text: '⚠️ *Notificação automática* :  O parceiro ' + '*' + this.meunome + '*' + ' Solicitou vistoria para homologação ' + '*' + this.homolselec + '-' +  this.filtredhomol['Cliente'] + '*' 
    })
      .subscribe(dados => {
        if (dados) {
        }
      });
  }

  solicitarvisto(){
      // tslint:disable-next-line: max-line-length
      this.service.solicitavisto({
        token: localStorage.getItem('tokenx'),
        cliente: this.filtredhomol["nome"] , 
        email: "jonnathan.lopes@solluxengenharia.com.br" ,
        homol: this.homolselec })
          .subscribe((data) => {
            if (data) {
              this.solvist();
            }
          });
        
  }
  solvist() {
    this.enviouvisto()
    this.notificatodos()
    this.postanotificatodos()
    this.upavisto();
  }
  filtrahomol(cl) {
    this.reload = false;
    this.homolselec = cl;
    this.puxatarefas(cl);
    this.puxacoment(this.homolselec);
    this.caminhoupar = cl;
    this.configurcao = [];
    this.status1 = "0";
    this.status2 = "0";
    this.status3 = "0";
    this.status4 = "0";
    this.path = this.ref + cl;
    this.pathori = this.path;
    this.reload = true;
    this.service.filtrahomologa({ ID: cl }).subscribe((data) => {
      if (data !== this.filtredhomol) {
        this.filtredhomol = data;
        this.idslec = this.filtredhomol["iddono"];
        this.filtrawpp();
        this.clienteatual = this.filtredhomol.nome;
        if (this.filtredhomol.statusdados === "Concluído") {
          this.status1 = "1";
       
          let datacorreta = this.filtredhomol.time1f.split("/");
          let d = parseFloat(datacorreta[0]);
          let m = parseFloat(datacorreta[1]);
          let an = parseFloat(datacorreta[2]);
          var datanova = new Date(an, m - 1, d);
          if (this.filtredhomol.time1 === "") {
            this.dias1 = "";
          }
          if (this.filtredhomol.time1 !== "") {
            this.dias1 =
              this.calculateDiff(this.filtredhomol.time1, datanova) + " dias";
          }
          if (this.dias1 === "NaN dias") {
            this.dias1 = "";
          }
        }

        if (
          this.filtredhomol.statusdados !== "Concluído" &&
          this.filtredhomol.statusdados !== "Coleta de dados concluída"
        ) {
          this.status1 = "0";
          let currentDate = new Date();
          if (this.filtredhomol.time1 === "") {
            this.dias1 = "";
          }
          if (this.filtredhomol.time1 !== "") {
            this.dias1 =
              this.calculateDiff(this.filtredhomol.time1, currentDate) +
              " dias";
          }
          if (this.dias1 === "NaN dias") {
            this.dias1 = "";
          }
        }
        if (this.filtredhomol.statusprojeto === "Concluído") {
          this.status2 = "1";
          let datacorreta = this.filtredhomol.time2f.split("/");
          let d = parseFloat(datacorreta[0]);
          let m = parseFloat(datacorreta[1]);
          let an = parseFloat(datacorreta[2]);
          var datanova = new Date(an, m - 1, d);
          if (this.filtredhomol.time2 === "") {
            this.dias2 = "";
          }
          if (this.filtredhomol.time2 !== "") {
            this.dias2 =
              this.calculateDiff(this.filtredhomol.time2, datanova) + " dias";
          }
          if (this.dias2 === "NaN dias") {
            this.dias2 = "";
          }
        }
        if (
          this.filtredhomol.statusprojeto !== "Concluído" &&
          this.filtredhomol.statusprojeto !== "Projeto e doc téc. concluídos"
        ) {
          this.status2 = "1";
          let currentDate = new Date();
          if (this.filtredhomol.time2 === "") {
            this.dias2 = "";
          }
          if (this.filtredhomol.time2 !== "") {
            this.dias2 =
              this.calculateDiff(this.filtredhomol.time2, currentDate) +
              " dias";
          }
          if (this.dias2 === "NaN dias") {
            this.dias2 = "";
          }
        }
        if (
          this.filtredhomol.statushomologacao !== "Concluído" &&
          this.filtredhomol.statushomologacao !== "Parecer de acesso emitido"
        ) {
          this.status3 = "1";
          let currentDate = new Date();
          if (this.filtredhomol.time3 === "") {
            this.dias3 = "";
          }
          if (this.filtredhomol.time3 !== "") {
            this.dias3 =
              this.calculateDiff(this.filtredhomol.time3, currentDate) +
              " dias";
          }
          if (this.dias3 === "NaN dias") {
            this.dias3 = "";
          }
        }
        if (this.filtredhomol.statushomologacao === "Concluído") {
          this.status3 = "1";
          let datacorreta = this.filtredhomol.time3f.split("/");
          let d = parseFloat(datacorreta[0]);
          let m = parseFloat(datacorreta[1]);
          let an = parseFloat(datacorreta[2]);
          var datanova = new Date(an, m - 1, d);
          if (this.filtredhomol.time3 === "") {
            this.dias3 = "";
          }
          if (this.filtredhomol.time3 !== "") {
            this.dias3 =
              this.calculateDiff(this.filtredhomol.time3, datanova) + " dias";
          }
          if (this.dias3 === "NaN dias") {
            this.dias3 = "";
          }
        }
        if (this.filtredhomol.statusvistoria === "Concluído") {
          this.status4 = "1";
          let datacorreta = this.filtredhomol.time4f.split("/");
          let d = parseFloat(datacorreta[0]);
          let m = parseFloat(datacorreta[1]);
          let an = parseFloat(datacorreta[2]);
          var datanova = new Date(an, m - 1, d);
          if (this.filtredhomol.time4 === "") {
            this.dias4 = "";
          }
          if (this.filtredhomol.time4 !== "") {
            this.dias4 =
              this.calculateDiff(this.filtredhomol.time4, datanova) + " dias";
          }
          if (this.dias4 === "NaN dias") {
            this.dias4 = "";
          }
        }
        if (
          this.filtredhomol.statusvistoria !== "Concluído" &&
          this.filtredhomol.statusvistoria !== "Vistoria finalizada"
        ) {
          this.status4 = "1";
          let currentDate = new Date();
          if (this.filtredhomol.time4 === "") {
            this.dias4 = "";
          }
          if (this.filtredhomol.time4 !== "") {
            this.dias4 =
              this.calculateDiff(this.filtredhomol.time4, currentDate) +
              " dias";
          }
          if (this.dias4 === "NaN dias") {
            this.dias4 = "";
          }
        }
        this.configurcao.push({
          qtdplaca: this.filtredhomol.qtdplaca1,
          modeloplaca: this.filtredhomol.modeloplaca1,
          marcaplaca: this.filtredhomol.marcaplaca1,
          potplaca: this.filtredhomol.potplaca1,
          qtdinv: this.filtredhomol.qtdinv1,
          marcainv: this.filtredhomol.marcainv1,
          modeloinv: this.filtredhomol.modeloinv1,
          potinv: this.filtredhomol.potinv1,
        });
        if (this.filtredhomol.qtdplaca2 !== null) {
          this.configurcao.push({
            qtdplaca: this.filtredhomol.qtdplaca2,
            modeloplaca: this.filtredhomol.modeloplaca2,
            marcaplaca: this.filtredhomol.marcaplaca2,
            potplaca: this.filtredhomol.potplaca2,
            qtdinv: this.filtredhomol.qtdinv2,
            marcainv: this.filtredhomol.marcainv2,
            modeloinv: this.filtredhomol.modeloinv2,
            potinv: this.filtredhomol.potinv2,
          });
          if (this.filtredhomol.qtdplaca3 !== null) {
            this.configurcao.push({
              qtdplaca: this.filtredhomol.qtdplaca3,
              modeloplaca: this.filtredhomol.modeloplaca3,
              marcaplaca: this.filtredhomol.marcaplaca3,
              potplaca: this.filtredhomol.potplaca3,
              qtdinv: this.filtredhomol.qtdinv3,
              marcainv: this.filtredhomol.marcainv3,
              modeloinv: this.filtredhomol.modeloinv3,
              potinv: this.filtredhomol.potinv3,
            });
          }
          if (this.filtredhomol.qtdplaca4 !== null) {
            this.configurcao.push({
              qtdplaca: this.filtredhomol.qtdplaca4,
              modeloplaca: this.filtredhomol.modeloplaca4,
              marcaplaca: this.filtredhomol.marcaplaca4,
              potplaca: this.filtredhomol.potplaca4,
              qtdinv: this.filtredhomol.qtdinv4,
              marcainv: this.filtredhomol.marcainv4,
              modeloinv: this.filtredhomol.modeloinv4,
              potinv: this.filtredhomol.potinv4,
            });
          }
          if (this.filtredhomol.qtdplaca5 !== null) {
            this.configurcao.push({
              qtdplaca: this.filtredhomol.qtdplaca5,
              modeloplaca: this.filtredhomol.modeloplaca5,
              marcaplaca: this.filtredhomol.marcaplaca5,
              potplaca: this.filtredhomol.potplaca5,
              qtdinv: this.filtredhomol.qtdinv5,
              marcainv: this.filtredhomol.marcainv5,
              modeloinv: this.filtredhomol.modeloinv5,
              potinv: this.filtredhomol.potinv5,
            });
          }
        }
        if (this.filtredhomol.qtdplaca2 === null) {
          for (let i = 1; i < 5; i++) {
            this.configurcao.push({
              modeloplaca: null,
              marcaplaca: null,
              qtdplaca: null,
              potplaca: null,
              modeloinv: null,
              marcainv: null,
              qtdinv: null,
              potinv: null,
            });
          }
        }

        if (
          this.filtredhomol.qtdplaca2 !== null &&
          this.filtredhomol.qtdplaca3 === null
        ) {
          for (let i = 2; i < 5; i++) {
            this.configurcao.push({
              modeloplaca: null,
              marcaplaca: null,
              qtdplaca: null,
              potplaca: null,
              modeloinv: null,
              marcainv: null,
              qtdinv: null,
              potinv: null,
            });
          }
        }

        if (
          this.filtredhomol.qtdplaca3 !== null &&
          this.filtredhomol.qtdplaca4 === null
        ) {
          for (let i = 3; i < 5; i++) {
            this.configurcao.push({
              modeloplaca: null,
              marcaplaca: null,
              qtdplaca: null,
              potplaca: null,
              modeloinv: null,
              marcainv: null,
              qtdinv: null,
              potinv: null,
            });
          }
        }

        if (
          this.filtredhomol.qtdplaca4 !== null &&
          this.filtredhomol.qtdplaca5 === null
        ) {
          for (let i = 4; i < 5; i++) {
            this.configurcao.push({
              modeloplaca: null,
              marcaplaca: null,
              qtdplaca: null,
              potplaca: null,
              modeloinv: null,
              marcainv: null,
              qtdinv: null,
              potinv: null,
            });
          }
        }

        if (this.abrehomologacao === "true") {
          this.abrehomologacao = "false";
        } else {
          this.abrehomologacao = "true";
        }
      }
      this.atualstatusdados = this.filtredhomol.statusdados;
      this.atualstatusprojeto = this.filtredhomol.statusprojeto;
      this.atualstatushomologacao = this.filtredhomol.statushomologacao;
      this.atualstatusvistoria = this.filtredhomol.statusvistoria;
      if (this.filtredhomol.msgav !== "" && this.filtredhomol.msgav !== null) {
        this.kabanstats = this.filtredhomol.msgav;
      }
      if (this.filtredhomol.msgav === "" || this.filtredhomol.msgav === null) {
      if (
        this.filtredhomol.statusdados === "Pendente" ||
        this.filtredhomol.statusdados === "Coletando dados" ||
        this.filtredhomol.statusdados === "Dados incompletos"
      ) {
        this.timeline = 0;
        this.kabanstats = 'dados'
      }
      if (
        (this.filtredhomol.statusdados === "Concluído" ||
        this.filtredhomol.statusdados === "Aguardando assinaturas") &&
        this.filtredhomol.statusprojeto === "Elaborando projeto" ||
        this.filtredhomol.statusprojeto === "Alterando projeto" ||
        this.filtredhomol.statusprojeto === "Aguardando aprovação"
      ) {
        this.timeline = 0;
        this.kabanstats = 'Projeto'
      }
      if (
        this.filtredhomol.statusprojeto === "Concluído" &&
        (this.filtredhomol.statushomologacao === "Pendente" ||
          this.filtredhomol.statushomologacao === "Aguardando parecer" ||
          this.filtredhomol.statushomologacao === "Em correção")
      ) {
        this.timeline = 1;
        this.kabanstats = 'Parecer'
      }
        if (
          (this.filtredhomol.statushomologacao === "Concluído" || this.filtredhomol.statushomologacao === "Parecer com obras") &&
          (this.filtredhomol.statusvistoria === "Aguardando vistoria" ||
            this.filtredhomol.statusvistoria === "Vistoria Solicitada" ||
            this.filtredhomol.statusvistoria === "Reprovada")) {
        this.timeline = 2;
        this.kabanstats = 'Vistoria'
        }
      }
      if (
        this.filtredhomol.statusparada !== "" &&
        this.filtredhomol.statusparada !== null
      ) {
        this.kabanstats = "Parada";
      }

      if (this.filtredhomol.statushomologacao === "Concluído" &&
        this.filtredhomol.statusdados === "Concluído" &&
        this.filtredhomol.statusprojeto === "Concluído" &&
            this.filtredhomol.statusvistoria === "Concluído") {
        this.kabanstats = "Homologado";
      }
      this.tarefas = [
        {
          Assignee: "Robson Resena",
          ClassName: "e-story, e-low, e-nancy-davloio",
          Color: "#1B59D6",
          Estimate: "no",
          Id: "HL1617310600",
          Priority: "High",
          RankId: 11,
          Status: "Transferida",
          Summary: "Anexar Projeto",
          Tags: this.kabanstats,
          Title: "Anexar Projeto",
          Type: this.filtredhomol.msgav,
          datafim: "20/05/2021",
          dataini: "18/05/2021",
          descricao:
            "Tarefa transferida, Projeto eletrico anexado na pasta do cliente no One Drive ",
          ide: "HL1617310600-11",
          nomecli: "Luiz Carlos Miguel Junior",
          ts1: 1621479600,
          valor: "#02897B",
          statusdados: this.filtredhomol.statusdados,
          statusproj: this.filtredhomol.statusprojeto,
          statusvisto: this.filtredhomol.statusvistoria,
          statusparecer: this.filtredhomol.statushomologacao,
          data1dados: this.dataxs(this.filtredhomol.time1),
          data2dados: this.dataxs(this.filtredhomol.time1f),
          data1proj: this.dataxs(this.filtredhomol.time2),
          data2proj: this.dataxs(this.filtredhomol.time2f),
          data1visto: this.dataxs(this.filtredhomol.time4),
          data2visto: this.dataxs(this.filtredhomol.time4f),
          data1parecer: this.dataxs(this.filtredhomol.time3),
          data2parecer: this.dataxs(this.filtredhomol.time3f),
          prococoloparecer: this.filtredhomol.protocoloparecer,
          prococolovistoria: this.filtredhomol.protocolovistoria,
          vencimentoparecer: "",
          atrasoparecer: "",
          atrasovistoria: "",
        },
      ];
      this.kanbanData = extend([], this.tarefas, null, true) as Object[];
    });
  }

  dataxs(str) {
    if (str[2] === '/') {
      return str
    }
    if (str[2] !== '/') {
      var d = str.substr(0,2)+ '/' + str.substr(2,2)+ '/' + str.substr(4,4)
      return d
    }
}
  convert(a) {
    var nas = a.split("/");
    var d = nas[0];
    var mo = nas[1];
    var an = nas[2];

    var newDate = mo + "/" + d + "/" + an;
    var unixdate = new Date(newDate + " 00:00:00").getTime() / 1000;
    return unixdate;
  }
  filtrahomol2(cl, dialogo) {
    this.homolselec = cl;
    this.reload = false;
    this.homolselec = cl;
    this.configurcao = [];
    this.status1 = "0";
    this.status2 = "0";
    this.status3 = "0";
    this.status4 = "0";
    this.path = this.ref + cl;
    this.pathori = this.path;
    this.reload = true;
    this.service.filtrahomologa({ ID: cl }).subscribe((data) => {
      if (data) {
        this.filtredhomol = data;
        this.idslec = this.filtredhomol["iddono"];
        this.filtrawpp();
        let datacorreta = this.filtredhomol.datainicio.split("/");
        let d = parseFloat(datacorreta[0]);
        let m = parseFloat(datacorreta[1]);
        let an = parseFloat(datacorreta[2]);
        var newDate = m + "/" + d + "/" + an;
        var unixdate = new Date(newDate + " 00:00:00").getTime() / 1000;
        var ts = unixdate;
        // Hierarchical data source for TreeView component
        this.productTeam1 = [
          {
            key: 1,
            description: "Coleta de dados",
            title: "Conferir documentação",
            tag: "dados",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(1)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#FF5733",
            descricao:
              "Verificar se toda documentação anexada no Datasol está completa",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(1)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
          },
          {
            key: 2,
            description: "Coleta de dados",
            title: "Atualizar Dados Datasol",
            tag: "dados",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(1)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#FF5733",
            descricao: "Atualziar todos os dados do cliente no Proemanager",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(1)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
          },
          {
            key: 3,
            description: "Coleta de dados",
            title: "Elaborar Formulários",
            tag: "dados",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(1)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#FF5733",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(1)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "Elaborar todos formulários  necessários ( Anexos, procuração e planilhas)",
          },
          {
            key: 4,
            description: "Coleta de dados",
            title: "Elaborar ART",
            tag: "dados",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(1)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#FF5733",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(1)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao: "Elaborar ART de acordo com o que a concessionária pede",
          },
          {
            key: 5,
            description: "Coleta de dados",
            title: "Conferir Documentação ",
            tag: "dados",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(1)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#FF5733",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(1)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "Conferir todos documentos elaborados, formulários e ART com os dados do cliente",
          },
          {
            key: 6,
            description: "Coleta de dados",
            title: "Anexar Documentação",
            tag: "dados",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(1)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#FF5733",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(1)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "Anexar toda documentação na pasta do cliente no Datasol",
          },
          {
            key: 7,
            description: "Projeto",
            title: "Testar Configuração no Solergo ",
            tag: "Projeto",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(2)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#1B59D6",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(2)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "Verificar a configuração enviada pelo cliente no datasol e informar caso encontre inconsistências ",
          },
          {
            key: 8,
            description: "Projeto",
            title: "Elaborar DUB",
            tag: "Projeto",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(2)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#1B59D6",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(2)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "Elaborar projeto Diagrama Unifilar, conferir Modelo e marca com exatidão ",
          },

          {
            key: 9,
            description: "Projeto",
            title: "Elaborar Planta de Localização",
            tag: "Projeto",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(2)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#1B59D6",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(2)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "Elaborar planta de localização com coordenadas do telhado",
          },
          {
            key: 10,
            description: "Projeto",
            title: "Elaborar Memorial",
            tag: "Projeto",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(2)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#1B59D6",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(2)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "Elaborar Memorial técnico descritivo e corrigir se necessário",
          },
          {
            key: 11,
            description: "Projeto",
            title: "Anexar Projeto",
            tag: "Projeto",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(2)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#1B59D6",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(2)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "Anexar Diagrama Unifilar no Datasol para Cliente Executar",
          },
          {
            key: 12,
            description: "Parecer",
            title: "Enviar Documentação",
            tag: "Parecer",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(4)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#34EE1B",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(4)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "Enviar documentação para concessionária e anotar protocolo no datasol",
          },
          {
            key: 13,
            description: "Parecer",
            title: "Atualizar Status Datasol",
            tag: "Parecer",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(4)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#34EE1B",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(4)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "Atualizar data iniical no Datasol e protocolo da concessionária",
          },
          {
            key: 14,
            description: "Parecer",
            title: "Anexar parecer e atualizar status",
            tag: "Parecer",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(19)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#34EE1B",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(19)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao: "Anexar Parecer na pasta do cliente e atualizar Status",
          },
          {
            key: 15,
            description: "Vistoria",
            title: "Verificar fotos",
            tag: "Vistoria",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(15)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#0E6902",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(15)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao: "Verificar fotos enviadas pelo cliente ",
          },
          {
            key: 16,
            description: "Vistoria",
            title: "Solicitar vistoria",
            tag: "Vistoria",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(19)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#0E6902",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(19)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "solicitar vistoria , atualizar protocolo e status no Datasol",
          },
          {
            key: 17,
            description: "Vistoria",
            title: "Atualizar Homologação",
            tag: "Vistoria",
            data: moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
              .businessAdd(26)
              ["_d"].toLocaleString("pt-BR")
              .split(" ")[0],
            color: "#0E6902",
            tsp: this.convert(
              moment(this.filtredhomol.datainicio, "DD/MM/YYYY")
                .businessAdd(26)
                ["_d"].toLocaleString("pt-BR")
                .split(" ")[0]
            ),
            descricao:
              "Atualizar Status no Datasol e comunicar finalização do processo",
          },
        ];
        this.clienteatual = this.filtredhomol.nome;
        if (
          (this.filtredhomol.statusdados !== "Concluído" ||
            this.filtredhomol.statusdados !== "Coleta de dados concluída") &&
          (this.filtredhomol.statusprojeto !== "Concluído" ||
            this.filtredhomol.statusprojeto !==
              "Projeto e doc téc. concluídos") &&
          (this.filtredhomol.statushomologacao !== "Concluído" ||
            this.filtredhomol.statushomologacao !==
              "Parecer de acesso emitido") &&
          (this.filtredhomol.statusvistoria !== "Concluído" ||
            this.filtredhomol.statusvistoria !== "Vistoria finalizada")
        ) {
          this.timeline = 0;
        }
        if (
          ((this.filtredhomol.statusdados === "Concluído" ||
            this.filtredhomol.statusdados === "Coleta de dados concluída") &&
            this.filtredhomol.statusprojeto !== "Concluído") ||
          (this.filtredhomol.statusprojeto !==
            "Projeto e doc téc. concluídos" &&
            this.filtredhomol.statushomologacao !== "Concluído") ||
          (this.filtredhomol.statushomologacao !==
            "Parecer de acesso emitido" &&
            (this.filtredhomol.statusvistoria !== "Concluído" ||
              this.filtredhomol.statusvistoria !== "Vistoria finalizada"))
        ) {
          this.timeline = 1;
        }
        if (
          (this.filtredhomol.statusdados === "Concluído" ||
            this.filtredhomol.statusdados === "Coleta de dados concluída") &&
          (this.filtredhomol.statusprojeto === "Concluído" ||
            this.filtredhomol.statusprojeto ===
              "Projeto e doc téc. concluídos") &&
          (this.filtredhomol.statushomologacao !== "Concluído" ||
            this.filtredhomol.statushomologacao !==
              "Parecer de acesso emitido") &&
          (this.filtredhomol.statusvistoria !== "Concluído" ||
            this.filtredhomol.statusvistoria !== "Vistoria finalizada")
        ) {
          this.timeline = 2;
        }

        if (
          (this.filtredhomol.statusdados === "Concluído" ||
            this.filtredhomol.statusdados === "Coleta de dados concluída") &&
          (this.filtredhomol.statusprojeto === "Concluído" ||
            this.filtredhomol.statusprojeto ===
              "Projeto e doc téc. concluídos") &&
          (this.filtredhomol.statushomologacao === "Concluído" ||
            this.filtredhomol.statushomologacao ===
              "Parecer de acesso emitido") &&
          (this.filtredhomol.statusvistoria !== "Concluído" ||
            this.filtredhomol.statusvistoria !== "Vistoria finalizada")
        ) {
          this.timeline = 3;
        }
        if (
          (this.filtredhomol.statusdados === "Concluído" ||
            this.filtredhomol.statusdados === "Coleta de dados concluída") &&
          (this.filtredhomol.statusprojeto === "Concluído" ||
            this.filtredhomol.statusprojeto ===
              "Projeto e doc téc. concluídos") &&
          (this.filtredhomol.statushomologacao === "Concluído" ||
            this.filtredhomol.statushomologacao ===
              "Parecer de acesso emitido") &&
          (this.filtredhomol.statusvistoria === "Concluído" ||
            this.filtredhomol.statusvistoria === "Vistoria finalizada")
        ) {
          this.timeline = 4;
        }

        if (this.filtredhomol.statusdados === "Concluído") {
          this.status1 = "1";
          let datacorreta = this.filtredhomol.time1f.split("/");
          let d = parseFloat(datacorreta[0]);
          let m = parseFloat(datacorreta[1]);
          let an = parseFloat(datacorreta[2]);
          var datanova = new Date(an, m - 1, d);
          if (this.filtredhomol.time1 === "") {
            this.dias1 = "";
          }
          if (this.filtredhomol.time1 !== "") {
            this.dias1 =
              this.calculateDiff(this.filtredhomol.time1, datanova) + " dias";
          }
          if (this.dias1 === "NaN dias") {
            this.dias1 = "";
          }
        }

        if (
          this.filtredhomol.statusdados !== "Concluído" &&
          this.filtredhomol.statusdados !== "Coleta de dados concluída"
        ) {
          this.status1 = "0";
          let currentDate = new Date();
          if (this.filtredhomol.time1 === "") {
            this.dias1 = "";
          }
          if (this.filtredhomol.time1 !== "") {
            this.dias1 =
              this.calculateDiff(this.filtredhomol.time1, currentDate) +
              " dias";
          }
          if (this.dias1 === "NaN dias") {
            this.dias1 = "";
          }
        }
        if (this.filtredhomol.statusprojeto === "Concluído") {
          this.status2 = "1";
          let datacorreta = this.filtredhomol.time2f.split("/");
          let d = parseFloat(datacorreta[0]);
          let m = parseFloat(datacorreta[1]);
          let an = parseFloat(datacorreta[2]);
          var datanova = new Date(an, m - 1, d);
          if (this.filtredhomol.time2 === "") {
            this.dias2 = "";
          }
          if (this.filtredhomol.time2 !== "") {
            this.dias2 =
              this.calculateDiff(this.filtredhomol.time2, datanova) + " dias";
          }
          if (this.dias2 === "NaN dias") {
            this.dias2 = "";
          }
        }
        if (
          this.filtredhomol.statusprojeto !== "Concluído" &&
          this.filtredhomol.statusprojeto !== "Projeto e doc téc. concluídos"
        ) {
          this.status2 = "1";
          let currentDate = new Date();
          if (this.filtredhomol.time2 === "") {
            this.dias2 = "";
          }
          if (this.filtredhomol.time2 !== "") {
            this.dias2 =
              this.calculateDiff(this.filtredhomol.time2, currentDate) +
              " dias";
          }
          if (this.dias2 === "NaN dias") {
            this.dias2 = "";
          }
        }
        if (
          this.filtredhomol.statushomologacao !== "Concluído" &&
          this.filtredhomol.statushomologacao !== "Parecer de acesso emitido"
        ) {
          this.status3 = "1";
          let currentDate = new Date();
          if (this.filtredhomol.time3 === "") {
            this.dias3 = "";
          }
          if (this.filtredhomol.time3 !== "") {
            this.dias3 =
              this.calculateDiff(this.filtredhomol.time3, currentDate) +
              " dias";
          }
          if (this.dias3 === "NaN dias") {
            this.dias3 = "";
          }
        }
        if (this.filtredhomol.statushomologacao === "Concluído") {
          this.status3 = "1";
          let datacorreta = this.filtredhomol.time3f.split("/");
          let d = parseFloat(datacorreta[0]);
          let m = parseFloat(datacorreta[1]);
          let an = parseFloat(datacorreta[2]);
          var datanova = new Date(an, m - 1, d);
          if (this.filtredhomol.time3 === "") {
            this.dias3 = "";
          }
          if (this.filtredhomol.time3 !== "") {
            this.dias3 =
              this.calculateDiff(this.filtredhomol.time3, datanova) + " dias";
          }
          if (this.dias3 === "NaN dias") {
            this.dias3 = "";
          }
        }
        if (this.filtredhomol.statusvistoria === "Concluído") {
          this.status4 = "1";
          let datacorreta = this.filtredhomol.time4f.split("/");
          let d = parseFloat(datacorreta[0]);
          let m = parseFloat(datacorreta[1]);
          let an = parseFloat(datacorreta[2]);
          var datanova = new Date(an, m - 1, d);
          if (this.filtredhomol.time4 === "") {
            this.dias4 = "";
          }
          if (this.filtredhomol.time4 !== "") {
            this.dias4 =
              this.calculateDiff(this.filtredhomol.time4, datanova) + " dias";
          }
          if (this.dias4 === "NaN dias") {
            this.dias4 = "";
          }
        }
        if (
          this.filtredhomol.statusvistoria !== "Concluído" &&
          this.filtredhomol.statusvistoria !== "Vistoria finalizada"
        ) {
          this.status4 = "1";
          let currentDate = new Date();
          if (this.filtredhomol.time4 === "") {
            this.dias4 = "";
          }
          if (this.filtredhomol.time4 !== "") {
            this.dias4 =
              this.calculateDiff(this.filtredhomol.time4, currentDate) +
              " dias";
          }

          if (this.dias4 === "NaN dias") {
            this.dias4 = "";
          }
        }
      }
      this.atualstatusdados = this.filtredhomol.statusdados;
      this.atualstatusprojeto = this.filtredhomol.statusprojeto;
      this.atualstatushomologacao = this.filtredhomol.statushomologacao;
      this.atualstatusvistoria = this.filtredhomol.statusvistoria;

      this.open(dialogo);
    });
  }
  calculateDiff(xdatax, dataf) {
    let datacorreta = xdatax.split("/");
    let currentDate = dataf;
    let d = parseFloat(datacorreta[0]);
    let m = parseFloat(datacorreta[1]);
    let an = parseFloat(datacorreta[2]);
    var datanova = new Date(an, m - 1, d);
    var diff = currentDate.getTime() - datanova.getTime();
    var days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return days;
  }

  mostraevento(a) {
    if (a.target.text === "fotos_vistoria") {
      this.reload = false;
      this.path = this.ref + this.homolselec + "/fotos_vistoria";
      this.filesService.getFiles(this.path);
      setTimeout(() => {
        this.reload = true;
      }, 1000);
    }
    if (a.target.text === "Documentos_tecnicos") {
      this.reload = false;
      this.path = this.ref + this.homolselec + "/Documentos_tecnicos";
      this.filesService.getFiles(this.path);
      setTimeout(() => {
        this.reload = true;
      }, 1000);
    }
  }

  select(ret: TransferSelectChange): void {}

  change(ret: TransferChange): void {
    const listKeys = ret.list.map((l) => l.key);
    const hasOwnKey = (e: TransferItem) => e.hasOwnProperty("key");
    this.list = this.list.map((e) => {
      if (listKeys.includes(e.key) && hasOwnKey(e)) {
        if (ret.to === "left") {
          delete e.hide;
        } else if (ret.to === "right") {
          e.hide = false;
        }
      }
      return e;
    });
  }
  mostraxx(a){
  }
   getMonthName(index: number): string {
    const month: string[] = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'];
    return month[index];
}
 cancelClick = (): void => {
  this.rteObj.refresh();
  this.rteObj.value = '';
}
public submitClick = (): void => {
  this.forum = false;
  let empCount = 0;
  const answerElement: HTMLElement = this.rteObj.contentModule.getEditPanel() as HTMLElement;
  const comment: string = answerElement.innerHTML;
  const empList: string[] = ['emp1', 'emp2', 'emp3'];
  
  this.helpcoment = comment
  const nameListList: string[] = ['Anne Dodsworth', 'Janet Leverling', 'Laura Callahan'];
  this.inserthelp()
}
filesData(a){
}

imageSelected(a): void {
}

imageUploading(a): void {
}

imageUploadSuccess(a): void {

}

imageUploadFailed(a): void {
}
  ngOnInit() {
    this.spinner.hide();
    this.editOptions = { allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Normal' };

    this.single = [
      {
        name: "Em análise",
        value: 0,
      },
      {
        name: "Em Projetos",
        value: 0,
      },
      {
        name: "Agd Parecer",
        value: 0,
      },
      {
        name: "Parecer Apr.",
        value: 0,
      },
      {
        name: "Paradas",
        value: 0,
      },
      {
        name: "Em Vistoria",
        value: 0,
      },
      {
        name: "Homologados",
        value: 0,
      },
    ];
    this.selectOptions = { type: 'Single' };
    this.selectOptionsx = { type: 'Single' };
    //this.autorizacao()
    this.initialPage = { pageSize: 10, pageCount: 1 };
    moment.updateLocale("br", {
      workingWeekdays: [1, 2, 3, 4, 5],
    });
    //this.temporestante = moment(this.datainicio, 'DD-MM-YYYY').businessDiff(moment(this.toDate, 'DD-MM-YYYY'));
    //this.temponecessario = moment(this.fromDate, 'DD-MM-YYYY').businessDiff(moment(this.toDate, 'DD-MM-YYYY'));
    var dateinici = new Date(Date.now()).toLocaleString("pt-BR").split(" ")[0];
    let datacorreta = dateinici.split("/");
    let d = parseFloat(datacorreta[0]);
    let m = parseFloat(datacorreta[1]);
    let an = parseFloat(datacorreta[2]);
    var newDate = m + "/" + d + "/" + an;
    var unixdate = new Date(newDate + " 00:00:00").getTime() / 1000;
    var ts = unixdate;
    this.itemsy = [
      {
        label: "Voltar",
        icon: "pi pi-arrow-left",
        command: (event) => {
        },
      },
      {
        label: "Upload",
        icon: "pi pi-cloud-upload",
        command: (event) => {
          this.abreenvia  = true
        },
      }
    ];
    this.itemsx = [
      {
        label: "Voltar",
        icon: "pi pi-arrow-left",
        command: (event) => {
          this.clientepnael();
        },
      },
      {
        label: "Editar",
        icon: "pi pi-user-edit",
        command: (event) => {
          this.menu5 = false
          this.menumuda(1);
        },
      },
      {
        label: "Status",
        icon: "pi pi-check-square",
        command: (event) => {
          this.menu5 = false
          this.menumuda(2);
        },
      },
      {
        label: "Arquivos",
        icon: "pi pi-folder",
        command: (event) => {
          this.menu5 = false
          if(this.filtredhomol.msgav ==="Homologado"){
            this.menu1 = false;
            this.menu2 = false;
            this.menu3 = true;
            this.menu4 = false;  
            this.filess = true;
          } else {this.menumuda(3);}

        },
      },
    ];
    this.toolbar = ["ExcelExport", "Search"];
    this.toolbarx = ["Add", "Edit", "Delete"];
    this.pageSettings = { pageCount: 5 };
    this.list = this.productTeam1;
    setCulture("pt");
    this.cols = [
      { field: "codhomol", header: "Código" },
      { field: "Cliente", header: "Cliente" },
      { field: "Status", header: "Status" },
      { field: "responsavel", header: "Responsãvel" },
      { field: "solicitante", header: "Solicitante" },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.config.setTranslation({
      accept: "Aceito",
      reject: "Cancelar",
      startsWith: "Começa com",
      contains: "Contém",
      notContains: "Não contém",
      endsWith: "Termina com",
      equals: "Igual",
      notEquals: "Diferente",
      noFilter: "Sem filtro",
      lt: "Menor que",
      lte: "menor ou igual",
      gt: "Maior que",
      gte: "Maior ou igual",
      is: "É",
      isNot: "Não é",
      before: "Antes",
      after: "Depois",
      clear: "Limpar",
      apply: "Aplicar",
      matchAll: "Match All",
      matchAny: "Match Any",
      addRule: "Add regra",
      removeRule: "Remover regra",
      choose: "Escolher",
      upload: "Upload",
      cancel: "Cancelar",
      dayNames: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Sx", "Sa"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      weekHeader: "Wk",
      //translations
    });

    this.viewx = "Details";
    this.service.currentid.subscribe((dados) => {
      if (dados !== this.id && dados !== "") {
        this.id = dados;
        this.puxahomologacoes();
        this.puxapendentes()
      }
    });

    this.service.currentNome.subscribe((dados) => {
      if (dados !== this.meunome && dados !== "") {
        this.meunome = dados;
      }
    });

    this.service.currenturl.subscribe((dados) => {
      if (dados !== this.urln && dados !== "") {
        this.urln = dados;
      }
    });
    this.service.versao({ver:'3.0.5' })
    .subscribe(dados => {
    if (dados) {
      this.validaversao = dados;
      if(this.validaversao.atualiza === 'atualiza') {
        this.displays= true}   
      }
    })
    
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    this.stTime = performance.now();
    this.selectionSettings = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
  }

  apagacom(i) {
    this.service.delcoment({ ID: i }).subscribe((dados) => {
      if (dados !== this.validacao6) {
        if (dados["status"] === "atualizado") {
          this.comentarios = [];
          this.puxacoment(this.homolselec);
        }
        if (dados["status"] === "erro") {
          this.comentarios = [];
          this.puxacoment(this.homolselec);
        }
      }
    });
    this.validacao6 = {};
  }

  abrechat(a) {
  
    this.homolselec = a;
    this.join();
    this.display = true;
    this.togla();
    this.filtrahomol2(a, null);
    this.puxacoment(a);
    //this.ultimas();
  }

  filtrawppx() {
    this.service.getwppadm({ nome: this.responsavex }).subscribe((dados) => {
      if (dados) {
        this.wpp = dados;
        this.enviacomentax();
        this.responsavex = "Selecione...";
      }
    });
  }
  filtrawppx2() {
    this.service.getwppadm({ nome: this.responsavex }).subscribe((dados) => {
      if (dados) {
        this.wpp = dados;
        this.enviacomentax2();
        this.responsavex = "Selecione...";
      }
    });
  }
  enviacomentax2() {
    var grupo = "553892515427-1633011809";
    var fones = [
      "5538992515427",
      "5538991437790",
      "553899666526",
      "5538988271752",
    ];
    //for (let i = 0; i < fones.length; i++) {
    this.service
      .sendtext({
        sessionName: "Sollux_Datasol",
                number: this.wpp.wpp,
        text:
          "⚠️ *Notificação automática* : Uma nova tarefa para homologação " +
          "*" +
          this.homolselec +
          "*" +
          " do cliente " +
          "*" +
          this.clienteatual +
          "*" +
          " foram atribuídas a você, favor verificar na gestão de tarefas",
      })
      .subscribe((dados) => {
        if (dados) {
        }
      });
    // }
  }
  enviacomentax() {
    var grupo = "553892515427-1633011809";
    var fones = [
      "5538992515427",
      "5538991437790",
      "553899666526",
      "5538988271752",
    ];
    //for (let i = 0; i < fones.length; i++) {
    this.service
      .sendtext({
        sessionName: "sollux",
                number: this.wpp.wpp,
        text:
          "⚠️ *Notificação automática* : As tarefas da homologação " +
          "*" +
          this.homolselec +
          "*" +
          " do cliente " +
          "*" +
          this.clienteatual +
          "*" +
          " foram atribuídas a você, favor verificar na gestão de tarefas",
      })
      .subscribe((dados) => {
        if (dados) {
        }
      });
    // }
  }

  mostrax() {
    this.contareceber();
  }
  contareceber() {
    this.spinner.show();
    if (this.valor3 !== 0 && this.valor2 !== 0) {
      this.service
        .contareceberomie({
          codigo_lancamento_integracao: this.filtredhomol["codhomol"] + "-1",
          codigo_cliente_fornecedor: this.intgradoomie,
          data_vencimento: this.vencimento1,
          valor_documento: this.valor3,
          codigo_categoria: "1.01.02",
          data_previsao: this.previsao1,
          id_conta_corrente: this.codconta,
          nCodOS: this.filtredhomol["codhomol"],
          nCodPedido: this.filtredhomol["codhomol"],
          numero_parcela: "001/001",
          categorias: { homologacao: "Homologação" },
          observacao:
            "1ª parcela  " +
            this.filtredhomol["codhomol"] +
            "- " +
            this.filtredhomol["nome"],
        })
        .subscribe((dados) => {
          if (dados.codigo_status === "0") {
            this.pagomie1 = dados.codigo_lancamento_omie;
            this.integra1 = true;
            this.contareceber2();
          }
          if (dados.faultcode !== undefined) {
            this.invalidmsg = dados.faultstring;
            this.displayerro = true;
          }
        });
    }
    if (this.valor3 === 0 && this.valor2 !== 0) {
      this.pagomie1 = "00000";
      this.contareceber2();
    }
  }

  contareceber2() {
    this.service
      .contareceberomie({
        codigo_lancamento_integracao: this.filtredhomol["codhomol"] + "-2",
        codigo_cliente_fornecedor: this.intgradoomie,
        data_vencimento: this.vencimento2,
        valor_documento: this.valor2,
        codigo_categoria: "1.01.02",
        data_previsao: this.previsao2,
        id_conta_corrente: this.codconta,
        nCodOS: this.filtredhomol["codhomol"],
        nCodPedido: this.filtredhomol["codhomol"],
        numero_parcela: "002/002",
        categorias: { homologacao: "Homologação" },
        observacao:
          "2ª parcela " +
          this.filtredhomol["codhomol"] +
          "- " +
          this.filtredhomol["nome"],
      })
      .subscribe((dados) => {
        if (dados.codigo_status === "0") {
          this.pagomie2 = dados.codigo_lancamento_omie;
          this.integra2 = true;
          this.upahomol();
        }
        if (dados.faultcode !== undefined) {
          this.invalidmsg = dados.faultstring;
          this.displayerro = true;
        }
      });
  }

  sair() {
    this.integraomie = false;
    this.integra1 = false;
    this.integra2 = false;
  }
  upahomol() {
    this.service
      .updatehomolx({
        token: localStorage.getItem("tokenx"),
        mediage: this.filtredhomol.mediage,
        excedente: this.filtredhomol.excedente,
        codhomol: this.homolselec,
      })
      .subscribe((dados) => {
        if (dados) {
          if (dados["status"] === "homologado") {
            this.integra1 = false;
            this.integra2 = false;
            this.showToast2("success");
          }
        }
      });
  }
  togla() {
    $(document).ready(function () {
      var $chatbox = $(".chatbox"),
        $chatboxTitle = $(".chatbox__title"),
        $chatboxTitleClose = $(".chatbox__title__close"),
        $chatboxCredentials = $(".chatbox__credentials");
      $chatboxTitle.on("click", function () {
        $chatbox.toggleClass("chatbox--tray");
      });
      $chatboxTitleClose.on("click", function (e) {
        this.display = false;
        e.stopPropagation();
        $chatbox.addClass("chatbox--closed");
      });
      $chatbox.on("transitionend", function () {
        if ($chatbox.hasClass("chatbox--closed")) $chatbox.remove();
      });
    });
  }

  mostraavi() {
    if (this.mostraavisos === false) {
      this.mostraavisos = true;
    } else {
      this.mostraavisos = false;
    }
  }
  toggleAnimations() {
    this.animationsDisabled = !this.animationsDisabled;
  }
  puxahomologacoes() {
    this.dataSource.sort = this.sort;
    this.service.myhomols({ ID: this.id }).subscribe((data: todosdados[]) => {
      if (data) {
        this.data = data;
        ////console.log(this.data)
        this.renderizou = true;
        this.filterSettings = { type: "Menu" };
        this.filter = { type: "CheckBox" };
        this.stTime = performance.now();
        this.selectionSettings = {
          persistSelection: true,
          type: "Multiple",
          checkboxOnly: true,
        };
        this.todashomologacoes = data;

        this.dataSource = new MatTableDataSource<todosdados>(
          this.todashomologacoes
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        for (let i = 0; i < this.todashomologacoes.length; i++) {
          this.todosparceiros.push(this.todashomologacoes[i]["solicitante"]);
        }

        this.unique = Array.from(new Set(this.todosparceiros));
        this.calculos();
        this.abrehomologacao = "false";
      }
    });
  }


  puxapendentes() {
    this.dataSource.sort = this.sort;
    this.service.mypend({ ID: this.id }).subscribe((data: todosdados[]) => {
      if (data) {
       this.pendentes = data
       if(this.pendentes.length> 0){
        //this.showInfo()  
       }
      }
    });
  }

  showInfo() {
    this.messageService.add({severity:'info', summary: 'Info', detail: 'Você possui pendências em suas homologções! Clique na seta abaixo dos indicadores do card e confira'});
}

  closemodal() {
    // tslint:disable-next-line: only-arrow-functions
    $(function () {
      $("#transacaomodal").modal("toggle");
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  puxanovocaixa() {
    this.service
      .homologacaodados({ ide: this.id })
      .subscribe((data: todosdados[]) => {
        if (data !== this.todashomologacoes) {
          this.todashomologacoes = data;
          this.iden = this.todashomologacoes.length;
          this.dataSource = new MatTableDataSource<todosdados>(
            this.todashomologacoes
          );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.calculos();
        }
      });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  calculos() {
    // Zera indices
    this.esperadados = 0;
    this.esperaprojeto = 0;
    this.esperacesso = 0;
    this.paradas = 0;
    this.esperavistoria = 0;
    this.numerohologacaoes = 0;
    this.paprovados = 0;
    // calcula indices
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.todashomologacoes.length; i++) {
      if (this.todashomologacoes[i]["momento"] === "1") {
        this.esperadados = this.esperadados + 1;
      }
      if (this.todashomologacoes[i]["momento"] === "5") {
        this.paradas = this.paradas + 1;
      }
      if (this.todashomologacoes[i]["momento"] === "2") {
        this.esperaprojeto = this.esperaprojeto + 1;
      }
      if (this.todashomologacoes[i]["momento"] === "3") {
        this.esperacesso = this.esperacesso + 1;
      }
      if (this.todashomologacoes[i]["momento"] === "4") {
        this.esperavistoria = this.esperavistoria + 1;
      }
      if (this.todashomologacoes[i]["momento"] === "0") {
        this.numerohologacaoes = this.numerohologacaoes + 1;
      }
      if (this.todashomologacoes[i]['momento'] === '20') {
        this.paprovados = this.paprovados + 1;
      }
    }
    this.p1 =
      (this.esperadados /
        (this.esperadados +
          this.esperacesso +
          this.esperavistoria +
          this.esperaprojeto)) *
      100;
    this.single = [
      {
        name: "Em análise",
        value: this.esperadados,
      },
      {
        name: "Em Projetos",
        value: this.esperaprojeto,
      },
      {
        name: "Agd Parecer",
        value: this.esperacesso,
      },
      {
        name: "Parecer Apr.",
        value: this.paprovados,
      },
      {
        name: "Paradas",
        value: this.paradas,
      },
      {
        name: "Em Vistoria",
        value: this.esperavistoria,
      },
      {
        name: "Homologados",
        value: this.numerohologacaoes,
      },
    ];
 

  }

  shiw(a) {}
  mudastatustar(i) {
    this.idstatustar = i;
  }

  open(dialog: TemplateRef<any>) {
    if (dialog !== null) {
      this.dialogService.open(dialog, {
        context: "this is some additional data passed to dialog",
      });
    }
  }

  deletetask(i) {
    this.service
      .deltarhomol({ ID: this.todastarefas[i].ID })
      .subscribe((dados) => {
        if (dados !== this.validacao6) {
          if (dados["status"] === "atualizado") {
            this.todastarefas = [] as HOMOTAREFA[];
            this.puxatarefas(this.homolselec);
          }
          if (dados["status"] === "erro") {
          }
        }
      });
    this.validacao6 = {};
  }

  novatarefa() {
    this.spinner.show();
    if (this.tipotarefa === "Selecione...") {
      this.tipotarefa = null;
    }
    if (this.statustarefa === "Selecione...") {
      this.tipotarefa = null;
    }
    this.service
      .newtaskhomol({
        token: localStorage.getItem("tokenx"),
        descricao: this.tarefa,
        datainicio: this.dataininiciotarefa,
        previsao: this.datafimtarefa,
        datafim: this.datafimtarefa,
        codhomol: this.homolselec,
        status: this.statustarefa,
        responsavel: this.responsaveltarefa,
      })
      .subscribe((dados) => {
        if (dados !== this.validacao3) {
          this.validacao3 = dados;
          if (dados["status"] === "cadastrado") {
            this.spinner.hide();
            this.tarefa = null;
            this.dataininiciotarefa = null;
            this.datafimtarefa = null;
            this.tipotarefa = "Selecione...";
            this.statustarefa = "Selecione...";
            this.responsaveltarefa = null;
          }
          if (dados["status"] === "erro") {
          }
        }
      });
    this.puxatarefas(this.homolselec);
    this.validacao3 = {};
  }

  selectingEvent(e: any): void {
    ////console.log(e)
    ////console.log(e.target.id)
    //let homols = e.row.outerText.split("	");
    //this.homolselec = (homols[0]);
    ////console.log(this.homolselec)
    
    //if (e.target.innerHTML === 'Solicitar') {
      ////console.log('eh igual inner')
      //this.menu5 = true;
      //this.menu4 = false;
      //this.menu3 = false;
      //this.menu2 = false;
      //this.menu1 = false;
    //}
    }
   

    postnotificachat() {
      this.service
        .postaemail(
          {
            "fromAddress": "jonnathan.lopes@solluxengenharia.com.br",
            "toAddress": "escritorio.projetos@solluxengenharia.com.br,engenharia@solluxengenharia.com.br",
            "subject": "Pergunta no Software",
            "content": "💭 O parceiro " +
      "*" +
      this.meunome +
      "*" +
      " fez a seguinte pergunta no datasol " +
      "*" +
      this.clienteatual +
      "* \n" +
      this.comment
         }
        )
        .subscribe((dados) => {
          ////console.log(dados)
        });
    }

    notificachat() {
      this.service.swnotifica({ titulo: 'Pergunta no Software !!', 
      msg: "💭 O parceiro " +
      "*" +
      this.meunome +
      "*" +
      " fez a seguinte pergunta no datasol " +
      "*" +
      this.clienteatual +
      "* \n" +
      this.comment }).subscribe((datax: any[]) => {
        if (datax) {
        }
      });
    }

    dswrateio() {
      this.service.swnotifica({ titulo: 'Modificação de rateio !!', 
      msg: "💥  O parceiro " +
      "*" +
      this.meunome +
      "*" +
      " modificou o rateio  do cliente " +
      "*" +
      this.clienteatual +
      ".* \n" +
      "verifique no datasol" }).subscribe((datax: any[]) => {
        if (datax) {
        }
      });
    }

    postadswrateio() {
      this.service
        .postaemail(
          {
            "fromAddress": "jonnathan.lopes@solluxengenharia.com.br",
            "toAddress": "escritorio.projetos@solluxengenharia.com.br,engenharia@solluxengenharia.com.br",
            "subject": "Modificação de rateio",
            "content":"💥  O parceiro " +
            "*" +
            this.meunome +
            "*" +
            " modificou o rateio  do cliente " +
            "*" +
            this.clienteatual +
            ".* \n" +
            "verifique no datasol" 
         }
        )
        .subscribe((dados) => {
          ////console.log(dados)
        });
    }

    swrateio() {
      this.service.swnotifica({ titulo: 'Solicitação de rateio !!', 
      msg: "💥  O parceiro " +
      "*" +
      this.meunome +
      "*" +
      " solicitou rateio para o cliente " +
      "*" +
      this.clienteatual +
      ".* \n" +
      "verifique no datasol" }).subscribe((datax: any[]) => {
        if (datax) {
        }
      });
    }

    
  postaswrateio() {
    this.service
      .postaemail(
        {
          "fromAddress": "jonnathan.lopes@solluxengenharia.com.br",
          "toAddress": "escritorio.projetos@solluxengenharia.com.br,engenharia@solluxengenharia.com.br",
          "subject": "Solicitação de Rateio",
          "content":"💥  O parceiro " +
          "*" +
          this.meunome +
          "*" +
          " solicitou rateio para o cliente " +
          "*" +
          this.clienteatual +
          ".* \n" +
          "verifique no datasol"
       }
      )
      .subscribe((dados) => {
        ////console.log(dados)
      });
  }
    notificaarqu() {
      this.service.swnotifica({ titulo: 'Arquivos Adicionados !!', 
      msg: "💾 O parceiro " +
      this.meunome +
      " anexou arquivos na pasta da homologação " +
      this.homolselec +
      " - " +
      this.clienteatual }).subscribe((datax: any[]) => {
        if (datax) {
        }
      });
    }

    msgar() {
      this.service.swnotifica({ titulo: 'Mensagem enviada!!', 
      msg: "💾 O parceiro " +
      this.meunome +
      " deixou uma mensagem na homologação " +
      this.homolselec +
      " - " +
      this.clienteatual }).subscribe((datax: any[]) => {
        if (datax) {
        }
      });
    }
    
    postamsgar() {
      this.service
        .postaemail(
          {
            "fromAddress": "jonnathan.lopes@solluxengenharia.com.br",
            "toAddress": "escritorio.projetos@solluxengenharia.com.br,engenharia@solluxengenharia.com.br",
            "subject": "Mensagem recebida",
            "content": "💾 O parceiro " +
            this.meunome +
            " deixou uma mensagem na homologação " +
            this.homolselec +
            " - " +
            this.clienteatual
         }
        )
        .subscribe((dados) => {
          ////console.log(dados)
        });
    }

    notificaarqud() {
      this.service.swnotifica({ titulo: 'Arquivos Adicionados !!', 
      msg: "💾 O parceiro " +
      this.meunome +
      " Deletou arquivos da pasta da homologação " +
      this.homolselec +
      " - " +
      this.clienteatual }).subscribe((datax: any[]) => {
        if (datax) {
        }
      });
    }
  
    selectingEvent2(e: any): void {
      this.delidx = e.data.idx
      this.delhomol = e.data.idempresa
      }
  
   test(e: any): void {
  }
  valueChange(args: any): void {
    this.listObj.hidePopup();
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
    let index: number = this.listObj.value as number;
    clearTimeout(this.clrIntervalFun2);
    this.clrIntervalFun2 = setTimeout(() => {
      this.isDataChanged = true;
      this.stTime = null;
      let contentElement: Element = this.gridInstance.contentModule.getPanel()
        .firstChild as Element;
      contentElement.scrollLeft = 0;
      contentElement.scrollTop = 0;
      this.gridInstance.pageSettings.currentPage = 1;
      this.stTime = performance.now();
      this.gridInstance.dataSource = this.data;
      this.gridInstance.hideSpinner();
    }, 100);
  }
  onDataBound(args: any): void {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }



  pagapix(homol){
   this.infoAdicionais = [
      {
        nome: 'Campo 1',
        valor: 'Informação Adicional1 do PSP-Recebedor',
      },
      {
        nome: 'Campo 2',
        valor: 'Informação Adicional2 do PSP-Recebedor',
      },
    ]
    this.service.pagapixs({
      token: localStorage.getItem('tokenx'),
      devedor_cnpj: "09009648630",
      txid: homol, 
      devedor_nome: "Jonnathan Vinicius Lopes Silva",
      valor_original:111,
      valor_modalidadeAlteracao: 1,
      chave:"",
      solicitacaoPagador: 'teste automação datasol.',
      infoAdicionais: JSON.stringify(this.infoAdicionais)
    })
      .subscribe(dados => {
        if (dados) {
          ////console.log(dados)
        }
      });
  }
}
