import { Router, ActivatedRoute } from '@angular/router';
import { HomologacoesserviceService } from './homologacoesservice.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import docxtemplater from 'docxtemplater';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import createReport from 'docx-templates';
import * as $ from 'jquery';
import 'src/assets/js/docxtemplater.js';
import 'src/assets/js/FileSaver.min.js';
import 'src/assets/js/pizzip-utils.js';
import 'src/assets/js/pizzip.js';
declare const generate: any;


@Component({
  selector: 'app-homologacoes',
  templateUrl: './homologacoes.component.html',
  styleUrls: ['./homologacoes.component.css']
})
export class HomologacoesComponent implements OnInit {
URL: string;
pdf = false;
dadoshomol = [];
formm = false;
conc =  [];
links = []
id: any;
distanciaPlacaInversor: string;
distanciaInversorQuadro: string;
descricaodokit: string;
dadosolicitante: any;
codhomolocacao: string;
bairro: string;
numerocliente: string;
nome: string;
ruacliente: string;
cep: string;
municipiocliente: string;
cpfCnpj: string;
eMailcomum: string;
celcomum: string;
ramoDeAtividade: string;
tipoAtividade: string;
kwpplacas: string;
Areadasplacas: string;
localInstalacao: string;
tipoDeTelhado: string;
latitude: string;
longitude: string;
marcaInversor: string;
padraoDeEntrada: string;
disjuntorentrada: string;
telefoneparceiro: string;
statusColetaDados: string;
statusHomologacao: string;
statusProjeto: string;
statusVistoria: string;
modeloPlaca: string;
entradaAreaSub: string;
disjuntoraumentocarga: string;
estado: string;
aviso: string;
numclientecemig: string;
numeroinstalacao: string;
cargainstalada: string;
classetensao: string;
qtdplacas: string;
qtdinversor: string;
marcaplacas: string;
modeloinversor: string;
potenciainversor: string;
disjuntorgeracao: string;
tipodisjgeracao: string;
tpdaumentoDeCarga: string;
primeiraparcela: string;
segundaparcela: string;
responsavel: string;
validacao = [ ];
complemento: string;
resourcesLoaded = '' ;
linksform = [];
settings = {
  defaultStyle: false,
  actions: false,
  attr: {
    class: 'table table-bordered' // this is custom table scss or css class for table
    },
  columns: {
    conessionaria: {
      title: 'Concessionária', filter: false
    },
    link: {
      title: 'Link', filter: false
    }
  }

};

  constructor(private service: HomologacoesserviceService,
              private spinner: NgxSpinnerService,
              private router: Router,
              ) { }

  ngOnInit() {
    this.service.currentNome.subscribe(dados => {
      if (dados !== this.codhomolocacao && dados !== '' ) {
        this.resourcesLoaded = dados;
        this.codhomolocacao = dados;
        this.click2(); }  } ); }


    click() {
      this.service.dadoshomologacoes({numhomol: this.codhomolocacao })
        .subscribe(dados => {
          if (dados !== this.dadoshomol) {
            this.dadoshomol = dados;
            this.resourcesLoaded = 'true';
            this.puxasolicitante(); }
          })}

          click2() {
            this.service.dadoshomologacoes({numhomol: this.codhomolocacao })
              .subscribe(dados => {
                if (dados !== this.dadoshomol) {
                  this.dadoshomol = dados;
                  this.puxasolicitante(); }
                })
              }

    puxasolicitante(){
      this.service.dadossolicitante({iddono: this.dadoshomol['iddono'] })
      .subscribe(dados => {
        if (dados !== this.dadosolicitante) {
          this.dadosolicitante = dados;
          this.atualizanomes();

        }
        });
      }
idmuda(i) {
this.URL =  this.links[i];
}
closemodal() {
  // tslint:disable-next-line: only-arrow-functions
  $( function( ) {
    $('#exampleModal').modal('toggle');
 });
  this.nloadFile();
}


      atualizanomes(){
        this.bairro = this.dadoshomol['bairro']
        this.numerocliente =  this.dadoshomol['numerocliente']
        this.nome = this.dadoshomol['nome']
        this.ruacliente = this.dadoshomol['ruacliente']
        this.cep = this.dadoshomol['cep']
        this.municipiocliente = this.dadoshomol['municipiocliente']
        this.cpfCnpj = this.dadoshomol['cpfCnpj']
        this.eMailcomum = this.dadoshomol['eMailcomum']
        this.celcomum = this.dadoshomol['eMailcomum']
        this.ramoDeAtividade = this.dadoshomol['ramoDeAtividade']
        this.tipoAtividade = this.dadoshomol['tipoAtividade']
        this.kwpplacas = this.dadoshomol['kwpplacas']
        this.Areadasplacas = this.dadoshomol['Areadasplacas']
        this.localInstalacao = this.dadoshomol['localInstalacao']
        this.tipoDeTelhado = this.dadoshomol['tipoDeTelhado']
        this.latitude = this.dadoshomol['latitude']
        this.longitude = this.dadoshomol['longitude']
        this.descricaodokit = this.dadoshomol['descricaodokit']
        this.marcaInversor = this.dadoshomol['marcaInversor']
        this.padraoDeEntrada = this.dadoshomol['padraoDeEntrada']
        this.disjuntorentrada = this.dadoshomol['disjuntorentrada']
        this.telefoneparceiro = this.dadoshomol['telefoneparceiro']
        this.statusColetaDados = this.dadoshomol['statusColetaDados']
        this.statusHomologacao = this.dadoshomol['statusHomologacao']
        this.statusProjeto = this.dadoshomol['statusProjeto']
        this.statusVistoria = this.dadoshomol['statusVistoria']
        this.modeloPlaca = this.dadoshomol['modeloPlaca']
        this.entradaAreaSub = this.dadoshomol['entradaAreaSub']
        this.disjuntoraumentocarga = this.dadoshomol['disjuntoraumentocarga']
        this.estado = this.dadoshomol['estado']
        this.aviso = this.dadoshomol['aviso']
        this.numclientecemig = this.dadoshomol['numclientecemig']
        this.numeroinstalacao = this.dadoshomol['numeroinstalacao']
        this.cargainstalada = this.dadoshomol['cargainstalada']
        this.classetensao = this.dadoshomol['classetensao']
        this.marcaInversor = this.dadoshomol['marcaInversor']
        this.modeloPlaca = this.dadoshomol['modeloPlaca']
        this.qtdplacas = this.dadoshomol['qtdplacas']
        this.qtdinversor = this.dadoshomol['qtdinversor']
        this.marcaplacas = this.dadoshomol['marcaplacas']
        this.modeloinversor = this.dadoshomol['modeloinversor']
        this.potenciainversor = this.dadoshomol['potenciainversor']
        this.disjuntorgeracao = this.dadoshomol['disjuntorgeracao']
        this.tipodisjgeracao = this.dadoshomol['tipodisjgeracao']
        this.tpdaumentoDeCarga = this.dadoshomol['tpdaumentoDeCarga']
        this.disjuntoraumentocarga = this.dadoshomol['disjuntoraumentocarga']
        this.primeiraparcela = this.dadoshomol['pagamentoentrada']
        this.segundaparcela = this.dadoshomol['pagamentosegundaparte']
        this.responsavel = this.dadoshomol['responsavel']
        this.complemento = this.dadoshomol['complemento']
        this.distanciaPlacaInversor = this.dadoshomol['distanciaPlacaInversor']
        this.distanciaInversorQuadro = this.dadoshomol['distanciaInversorQuadro']
        this.resourcesLoaded = 'false';

      }
      btnClick = function() {
        this.pdf = true;
        this.router.navigateByUrl('/pdf');
      };

mostralinks(){
  this.formm = true;
}

      nloadFile(){
        // tslint:disable-next-line: max-line-length
        generate({
          ncliente : this.numclientecemig,
ninstal : this.numeroinstalacao,
titular : this.nome,
classe : this.ramoDeAtividade,
cpf : this.cpfCnpj,
rua : this.ruacliente,
numerorua : this.numerocliente,
bairro : this.bairro,
cep : this.cep,
municpio : this.municipiocliente,
estado : this.estado,
email: this.dadosolicitante['emailparceiro'],
lat: this.latitude,
long: this.longitude,
ci: this.cargainstalada,
de: this.disjuntorentrada,
te: this.classetensao,
da: this.disjuntorentrada,
potgeracao: this.potenciainversor,
qtdmod: this.qtdplacas,
fabmod: this.marcaplacas,
modmodu: this.modeloPlaca,
potmod: this.kwpplacas,
qtdinv: this.qtdinversor,
fabinv: this.marcaInversor,
modinv: this.modeloinversor,
potinv: this.potenciainversor,
area: this.Areadasplacas,
celular: this.dadosolicitante['celparceiro'],
complemento : this.complemento,
linkdoc: this.URL
        });
      }

      onUserRowSelect(event): void {
        this.URL = event.data['link']
        this.nloadFile();
      }
}
