<p-sidebar [blockScroll]="true" [modal]="true" position="right" [style]="{
  width: '70vw' }" [(visible)]="cadastracli">
  <div style="height: 100vh;" class="row">
    <div style="padding-left: 20px !important;background-color: #2d323d; color: white; " class="  col-4 ">
      <ul class="list-none p-0 ">
        <li class="noselect flex flex-column
          md:flex-row md:align-items-center md:justify-content-center
          mb-4">
          <div class="align-items-center">
            <div *ngIf="!urlxok" style="margin-left: -2px" class="group">
            <div style="margin-left: 0px; font-weight: bold; font-size: medium; color: blue;" ng2FileDrop [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
              (fileOver)="fileOverBase($event)" [style.backgroundImage]="'url('+urlx +')'" [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }" (onFileDrop)="dropped($event)" [uploader]="uploader" class="well my-drop-zone">
              Arraste sua foto aqui</div>

            </div>

            <div *ngIf="urlxok" style="margin-left: -2px" class="group">
              <div style="margin-left: 0px; font-weight: bold; font-size: medium; color: blue;" ng2FileDrop [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
                (fileOver)="fileOverBase($event)" [style.backgroundImage]="'url('+urlx +')'" [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }" (onFileDrop)="dropped($event)" [uploader]="uploader" class="well my-drop-zone2">
                </div>
  
              </div>
            <br/>
            <div *ngIf="urlxok" id="outer">
              <div id="inner">
                <nz-button-group>
                  <div style="margin-top: 0px">
                    <div style="width: 100px; height: 30px !important" (click)="uploader.clearQueue();urlxok=false" class="badge">
                      <p>Trocar</p>
                    </div>
                  </div>
                </nz-button-group>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
    <div class="surface-overlay col-8">
<h6>Cadastro de colaborador</h6>
      <div class="form-group col-11">
        <ejs-textbox placeholder="Nome" cssClass="e-outline" [(ngModel)]='funcionario' floatLabelType="Auto">
        </ejs-textbox>
      </div>
      <div class="form-group col-11">
        <ejs-textbox placeholder="E-mail" cssClass="e-outline" [(ngModel)]='email' floatLabelType="Auto">
        </ejs-textbox>

      </div>
      <div class="form-group col-11">
        <ejs-dropdownlist [(ngModel)]='cargo' floatLabelType="Auto" cssClass="e-outline" id='ress' #sample
          [dataSource]='empregados' [fields]='fields' placeholder='Cargo'></ejs-dropdownlist>

      </div>
      <div class="form-group col-11">
        <ejs-textbox placeholder="Telefone" cssClass="e-outline" [(ngModel)]='wppfun' floatLabelType="Auto">
        </ejs-textbox>
      </div>
      <div class="form-group col-11">
        <div style="margin-bottom: 20px;">
          <ejs-textbox placeholder="Senha de 8 digitos" cssClass="e-outline" [(ngModel)]='senha' type="password"
            floatLabelType="Auto"></ejs-textbox>
        </div>
        <div >
          <ejs-textbox placeholder="Repita a senha" cssClass="e-outline" [(ngModel)]='novasenha' type="password"
            floatLabelType="Auto"></ejs-textbox>
        </div>

        <mat-error *ngIf="senha !== novasenha">
          As senhas não coincidem
        </mat-error>
      </div>

      <div class="form-group col-11">
        <!-- Small switch -->
                <span class="switch switch-xs">
                    <input  (change)="admsim($event.target.checked)" type="checkbox" class="switch" id="idChk-xs">
                    <label for="idChk-xs">Permitir função de Administrador</label>
                </span>
          </div>

      <div class="form-group col-11">
        <button [disabled] ="senha !== novasenha || senha === null" (click)="cadastranovos()" style="width: 100% !important;background-color: #0a147c; color: white;"
          nbButton>
          Cadastrar
        </button>
      </div>

    </div>
  </div>
</p-sidebar>



<p-sidebar [blockScroll]="true" [modal]="true" position="right" [style]="{
  width: '70vw' }" [(visible)]="filtrado">
  <div style="height: 100vh;" class="row">
    <div style="padding-left: 20px !important;background-color: #2d323d; color: white; " class="  col-4 ">
      <ul class="list-none p-0 ">
        <li class="noselect flex flex-column
          md:flex-row md:align-items-center md:justify-content-center
          mb-4">
          <div class="align-items-center">
                    <div style="margin-left: -2px" *ngIf="!enviarfoto" class="group">
          <div *ngIf="filtrado" [style.backgroundImage]="
              'url(' + employerfildred['pic'] + ')'
            "></div>
        </div>
        <br />
        <div  style="margin-top: 10px;text-align: center;">
          <div *ngIf="!enviarfoto" style="width: 100px" (click)="enviarfoto = true" class="badge">
            <i style="color: black" nz-icon nzType="camera" nzTheme="outline"></i>

            <p>Editar</p>
          </div>
        </div>
        <div  style="margin-top: 10px;text-align: center;">
          <div *ngIf="!enviarfoto" style="width: 100px" (click)="trocarsenha = true" class="badge">
            <i style="color: black" nz-icon nzType="key" nzTheme="outline"></i>

            <p>Trocar Senha</p>
          </div>
        </div>
        <div style="margin-left: 0px" *ngIf="enviarfoto" ng2FileDrop
          [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }" (fileOver)="fileOverBase($event)"
          [uploader]="uploader" class="well my-drop-zone">
          Arraste sua foto aqui
        </div>
        <br />
        
          <div id="inner">
            <nz-button-group>
              <div style="margin-top: 0px; border-bottom: -10px; text-align: center;">
                <div *ngIf="enviarfoto" style="width: 100px; height: 30px !important" (click)="enviarfoto = false"
                  class="badge">
                  <p>Cancelar</p>
                </div>
              </div>
              <div style="margin-top: 0px ;text-align: center;">
                <div *ngIf="enviarfoto" style="width: 100px; height: 30px !important" (click)="mostrafile()"
                  class="badge">
                  <p>Enviar</p>
                </div>
              </div>
            </nz-button-group>
          </div>
  
      </div>

      </li>
      </ul>
    </div>
    <div class="surface-overlay col-8">
    <h6>Dados do colaborador</h6>
      <div class="form-group col-11">
        <ejs-textbox *ngIf="filtrado" placeholder="Nome" cssClass="e-outline"
        [value]="employerfildred['funcionario']" (change)="
            employerfildred['funcionario'] = $event.value;
            dhc($event.value)
          " floatLabelType="Auto"></ejs-textbox>
      </div>

      <div class="form-group col-11">
        <ejs-textbox *ngIf="filtrado" placeholder="E-mail" cssClass="e-outline"
        [value]="employerfildred['email']" (change)="
        employerfildred['email'] = $event.value;
        dhc($event.value)
      " floatLabelType="Auto"></ejs-textbox>
      </div>

      <div class="form-group col-11">
        <div
        *ngIf="filtrado && (employerfildred['cargo'] !== 'Administrador' && employerfildred['adm'] !== 1) ">
        <ejs-textbox readonly placeholder="Cargo" cssClass="e-outline"
          [value]="employerfildred['cargo']" (change)="
        employerfildred['cargo'] = $event.value;
        dhc($event.value)
      " floatLabelType="Auto"></ejs-textbox>
      </div>


      <ejs-dropdownlist
        *ngIf="filtrado && (employerfildred['cargo'] === 'Administrador' || employerfildred['adm'] === 1) "
        [value]="employerfildred['cargo']" (change)="
        employerfildred['cargo'] = $event.value;
        dhc($event.value)
      " floatLabelType="Auto" cssClass="e-outline" id='ress' #sample [dataSource]='empregados'
        [fields]='fields' placeholder='Cargo'></ejs-dropdownlist>
      </div>

      <div class="form-group col-11">
        <ejs-textbox *ngIf="filtrado" placeholder="Telefone" cssClass="e-outline"
        [value]="employerfildred['wppfun']" (change)="
        employerfildred['wppfun'] = $event.value;
        dhc($event.value)
      " floatLabelType="Auto"></ejs-textbox>
      </div>
        <div class="form-group col-11">
          <ejs-textbox *ngIf="filtrado" placeholder="Sobre mim" cssClass="e-outline" (change)="
          employerfildred['sobremim'] = $event.value;
          dhc($event.value)
        " [value]="employerfildred['sobremim']" floatLabelType="Auto"></ejs-textbox>
      </div>
      <div class="form-group col-11">
        <!-- Small switch -->
                <span class="switch switch-xs">
                    <input [value]="employerfildred.adm" *ngIf="filtrado"
                    [checked]="employerfildred.adm"
                    (change)="employerfildred.adm = ($event.target.checked)" type="checkbox" class="switch" id="idChk-xs">
                    <label for="idChk-xs">Permitir função de Administrador</label>
                </span>
          </div>
          <div   *ngIf="trocarsenha === true" class="form-group col-11">
            <div *ngIf="dadosolicitante.adm === 1" style="margin-bottom: 20px;">
              <ejs-textbox placeholder="Senha de 8 digitos" cssClass="e-outline" [(ngModel)]='senha' type="password"
                floatLabelType="Auto"></ejs-textbox>
            </div>
            <div *ngIf="dadosolicitante.adm === 1" >
              <ejs-textbox placeholder="Repita a senha" cssClass="e-outline" [(ngModel)]='novasenha' type="password"
                floatLabelType="Auto"></ejs-textbox>
            </div>
    
            <mat-error *ngIf="senha !== novasenha">
              As senhas não coincidem
            </mat-error>
          </div>

          <div class="form-group col-11">
            <button [disabled] ="senha !== novasenha || senha === null" (click)="upacliente()" style="width: 100% !important;background-color: #0a147c; color: white;"
              nbButton>
              Atualizar
            </button>
          </div>
    </div>
  </div>
</p-sidebar>

<div style="padding: 5px !important; ">
  <p-menubar styleClass="Colors" [model]="items">
    <ng-template style="background-color: #2d323d !important;" pTemplate="start">
      <h1></h1>
    </ng-template>
    <ng-template *ngIf="tabelacli" pTemplate="end">
      <input type="text" pInputText placeholder="Buscar" />
    </ng-template>
  </p-menubar>

  <div style="padding: 10px" *ngIf="tabelacli">
    <ejs-grid (rowSelected)="onRowSelected($event)" allowPaging="true" [gridLines]="'Horizontal '"  #grid [dataSource]="data"
      height="auto">
      <e-columns>
        <e-column headerText="Foto" width="150" textAlign="Center">
          <ng-template #template let-data>
            <div class="image">
              <nz-avatar nzIcon="user" [nzSrc]="data.pic"></nz-avatar>
            </div>
          </ng-template>
        </e-column>
        <e-column field="funcionario" headerText="Name" textAlign="Center" width="130"></e-column>
        <e-column field="cargo" headerText="Designaçāo" textAlign="Center" width="180"></e-column>
        <e-column field="email" headerText="Email ID" width="280" textAlign="Center">
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>

  <div *ngIf="cincow2h">
    <app-kanban></app-kanban>

  </div>

  <div style="margin-top: 20px;" *ngIf="abrecalendar === true">
    <app-calendario></app-calendario>
  </div>
</div>
<p-dialog *ngIf="display5w" [modal]="true" [style]="{ width: '70vw' }" [(visible)]="display5w">
  <ng-template pTemplate="header">
    <b>5W2H </b>
  </ng-template>
  <p-divider></p-divider>
  <div class="form-row">
    <div class="form-group col-12">
      <ejs-textbox placeholder="O quê?" cssClass="e-outline" [(ngModel)]="cincodatafiltro.oque" floatLabelType="Auto">
      </ejs-textbox>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <ejs-textbox placeholder="Porquê ?" cssClass="e-outline" [(ngModel)]="cincodatafiltro.porque"
        floatLabelType="Auto"></ejs-textbox>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <ejs-textbox placeholder="Onde ?" cssClass="e-outline" [(ngModel)]="cincodatafiltro.onde" floatLabelType="Auto">
      </ejs-textbox>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <ejs-textbox placeholder="Como ?" cssClass="e-outline" [(ngModel)]="cincodatafiltro.como" floatLabelType="Auto">
      </ejs-textbox>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <ejs-textbox placeholder="Quem ?" cssClass="e-outline" [(ngModel)]="cincodatafiltro.quem" floatLabelType="Auto">
      </ejs-textbox>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <ejs-textbox placeholder="Quando ?" cssClass="e-outline" [(ngModel)]="cincodatafiltro.quando"
        floatLabelType="Auto"></ejs-textbox>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-12">
      <ejs-textbox placeholder="Quanto ?" cssClass="e-outline" [(ngModel)]="cincodatafiltro.quanto"
        floatLabelType="Auto"></ejs-textbox>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-12">
      <ejs-textbox placeholder="Status" cssClass="e-outline" [(ngModel)]="cincodatafiltro.status" floatLabelType="Auto">
      </ejs-textbox>
    </div>
  </div>

  <p-footer>
    <button (click)="display5w = false" nbButton>Cancelar</button>
  </p-footer>
</p-dialog>

<p-dialog styleClass="disable-scroll" [style]="{width: '600px',overflow: 'hidden !important'}" position="center"
  [(visible)]="showaviso">
  <nz-result nzStatus="info" nzTitle="Somente Administrador ou colaborador permitdo pode executar essa função"
    nzSubTitle="Entre em contato com o Administrador">
    <div nz-result-extra>
      <button nbButton (click)="showaviso = false">Fechar</button>
    </div>
  </nz-result>
</p-dialog>

<p-dialog styleClass="disable-scroll" [style]="{width: '600px',overflow: 'hidden !important'}" position="center"
  [(visible)]="emailexiste">
  <nz-result nzStatus="info" nzTitle="Esse email já está registrado use outro" nzSubTitle="Altere o email">
    <div nz-result-extra>
      <button nbButton (click)="emailexiste = false">Fechar</button>
    </div>
  </nz-result>
</p-dialog>
<p-dialog styleClass="disable-scroll" [style]="{width: '600px',overflow: 'hidden !important'}" position="center"
  [(visible)]="colaboradorinserido">
  <nz-result nzStatus="success" nzTitle="Colaborador inserido com sucesso" nzSubTitle="">
    <div nz-result-extra>
      <button nbButton (click)="colaboradorinserido = false;cadastracli = false;puxasolicitante();puxaequipe()">Fechar</button>
    </div>
  </nz-result>
</p-dialog>
<p-dialog styleClass="disable-scroll" [style]="{width: '600px',overflow: 'hidden !important'}" position="center"
  [(visible)]="erroinserir">
  <nz-result nzStatus="success" nzTitle="Erro ao inserir, verifique todos os campos! "
    nzSubTitle="Em caso de dúvida entre em contato com o suporte técnico">
    <div nz-result-extra>
      <button nbButton (click)="erroinserir = false">Fechar</button>
    </div>
  </nz-result>
</p-dialog>

<p-sidebar [blockScroll]="true" [modal]="true" position="right" [style]="{
  width: '70vw' }" [(visible)]="editacard">
  <div *ngIf="editacard && sholist">
    <div style="background-color: rgb(72, 51, 121) !important; width: 100%
      !important;top:0">
      <div class="row p-2">
        <div class="col-9" style="color: white; font-weight: bold;margin-left:
          20px;">
          <i style="font-size: 14px; color: white;" (click)="sholist=false" class="fas fa-arrow-circle-left"></i>
          Lista de verificação
        </div>
      </div>
    </div>
    <div class="surface-overlay h-full col-12 xl:col-12">
      <div class="card-body">
        <div class="add-items d-flex">
          <input type="text" class="form-control todo-list-input" placeholder="Digite uma tarefa" [(ngModel)]='taskx'
            #newTodo />
          <button (click)="adresp= true" class="add btn btn-primary
            font-weight-bold todo-list-add-btn">
            Adicionar
          </button>
        </div>

        <div class="list-wrapper">
          <ul *ngFor="let item of todos" class="d-flex flex-column-reverse
            todo-list">
            <li [class]="item.class">
              <div class="form-check">
                <label style="font-size: 14px;" class="form-check-label">
                  {{item.list}} </label>
              </div>
            </li>
          </ul>
        </div>

        <p-divider></p-divider>
      </div>
    </div>
  </div>

  <div *ngIf="editacard && !sholist" style="height: 100vh;" class="row">
    <div style="padding-left: 20px !important;background-color: #2d323d; color: white; " class="  col-4 ">
      <ul class="list-none p-0 m-0 p-2">
        <li class="flex flex-column
          md:flex-row md:align-items-left md:justify-content-between
          mb-4">
          <div class="flex">
            <span style="font-weight: bolder;color: white" class="text-white-500 ml-0
              font-medium">Criado por</span>
          </div>
        </li>
        <li class="flex flex-column
          md:flex-row md:align-items-left md:justify-content-between
          mb-4">
          <div class="flex">
            <img [src]="urln" class="mr-3" style="width: 50%; height: auto" />

            <div>
              <span style="color: white" class="block text-white-900 font-medium mb-1">{{meunome}}</span>
              <div  style="color: white" class="text-white-600">{{datatarefax|date: "dd/MM/yyyy HH:mm"}}</div>
            </div>
          </div>
        </li>
        <li class="flex flex-column
          md:flex-row md:align-items-left md:justify-content-between
          mb-4">
          <div class="flex">
            <span style="font-weight: bolder; color: white" class="text-white-500 ml-0
              font-medium">Responsável pelo projeto</span>
          </div>
        </li>


        <li class="flex flex-column
          md:flex-row md:align-items-left md:justify-content-between
          mb-4">
          <div class="flex">
            <div>
              <span class="block text-900 font-medium mb-1"></span>
              <div class="text-600"></div>
            </div>
          </div>
        </li>

        <li class="flex flex-column
          md:flex-row md:align-items-left md:justify-content-between
          mb-2">

          <div class="flex">
            <span style="font-weight: bolder; color: white" class="text-white-500 ml-0
              font-medium">Projeto</span>
          </div>
        </li>
        <li class="flex flex-column md:flex-row md:align-items-left mb-3">
          <p-tag [value]="projetx"></p-tag>

        </li>
        <li class="flex flex-column
          md:flex-row md:align-items-left md:justify-content-between
          mb-4">

          <div class="flex">
            <span style="font-weight: bolder;color: white" class="text-white-500 ml-0
              font-medium">Observadores</span>
          </div>
        </li>
        <li class="flex flex-column
          md:flex-row md:align-items-left md:justify-content-between
          mb-4">
          <div class="flex
            align-items-start
            flex-column
            lg:flex-row lg:justify-content-between">
            <div class="flex align-items-left flex-column md:flex-row">
              <p-avatarGroup *ngFor="let item of observa" styleClass="p-mb-3">
                <p-avatar [image]="item.pic" size="small" shape="circle"></p-avatar>
              </p-avatarGroup>
            </div>
          </div>
        </li>
      </ul>
      <div style="position: absolute !important; bottom: 40px !important;
        vertical-align: bottom !important;width: 90% !important;">

      </div>
      <div style="margin-bottom: 7px;"></div>
    </div>
    <div class="surface-overlay col-8">
      <div class="form-row">
        <div class="form-group col-11">
          <ejs-textbox maxlength='62' *ngIf="!padrotar" placeholder="Tarefa" cssClass="e-outline"
            floatLabelType="Always" (change)="zeralist()" [(ngModel)]="nTitle" [value]="nTitle"></ejs-textbox>
          <ejs-dropdownlist *ngIf="padrotar" cssClass="e-outline" placeholder="Tarefas Padronizadas"
            floatLabelType="Always" [dataSource]="tarefaspadrao" (change)="nTitle= $event.value;mudades();"
            [value]="nTitle" [fields]="listex"></ejs-dropdownlist>
          <label style="font-size: 12px;" class="form-check-label">
            <p-checkbox [(ngModel)]="padrotar" binary="true" inputId="binary"></p-checkbox>
            Digitar tarefa manualmente
          </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-11">
          <ejs-textbox *ngIf="clientemanu" placeholder="Cliente" cssClass="e-outline" floatLabelType="Always"
            [(ngModel)]="nomecli" [value]="nomecli"></ejs-textbox>
          <ejs-dropdownlist *ngIf="!clientemanu" cssClass="e-outline" placeholder="Cliente" floatLabelType="Always"
            [dataSource]="todosclientes" (change)="nomecli= $event.value;filtrand($event.value);" [value]="nCliente"
            [fields]="listex"></ejs-dropdownlist>
          <button (click)="clientemanu=true" pButton style="font-size: 13px;" type="button" label="Digitar  manualmente"
            class="p-button-link"></button>
          <button (click)="puxaclientes();clientemanu=false" pButton style="font-size: 13px;" type="button"
            label="Filtrar por clientes" class="p-button-link"></button>
          <button (click)="puxahomologacoes();clientemanu=false" pButton style="font-size: 13px;" type="button"
            label="Filtrar por Homologações" class="p-button-link"></button>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-11">
          <ejs-dropdownlist cssClass="e-outline" placeholder="Responsável" floatLabelType="Always"
            [dataSource]="employers" (change)="nAssignee= $event.value" [value]="nAssignee" [fields]="fieldsx">
          </ejs-dropdownlist>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-11">
          <ejs-datetimepicker cssClass="e-outline" placeholder="Previsão" floatLabelType="Always" [(ngModel)]="ndatafim"
            format='dd/MM/yyyy HH:mm' [locale]='"en-US"'></ejs-datetimepicker>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-11">
          <ejs-numerictextbox min="0" max="999" placeholder="Duração Máxima (minutos)" cssClass="e-outline"
            [(ngModel)]="nduracao" floatLabelType="Always"></ejs-numerictextbox>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-11">
          <ejs-textbox rows="2" cssClass="e-outline" placeholder="Descrição" [(ngModel)]="ndescricao" [multiline]="true"
            floatLabelType="Always"></ejs-textbox>
        </div>
      </div>
      <div class="flex justify-content-between pt-0 col-11">
        <button style="background-color: rgb(37, 17, 107) !important;
          color: white;
          height: 40px;
          font-size: 14px;
          font-weight: bold;" (click)="novatarefax()" pButton pRipple label="Atribuir"
          class="p-button-outlined p-button-secondary w-6 mr-4"></button>
        <button style="background-color: rgb(7, 5, 117) !important;
          color: white;
          height: 40px;
          font-size: 14px;
          font-weight: bold;" pButton pRipple (click)='adob=true' label="Adicionar
          Observador" class="p-button-outlined p-button-secondary w-11 mr-1"></button>
      </div>
    </div>
  </div>
</p-sidebar>