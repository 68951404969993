

<!-- <section class="container main">
    <p>Welcome to the <code>fileuploader.</code> Choose which [<a href="http://dropbox.github.io/dropbox-sdk-js/Dropbox.html#filesUpload">documents</a>] you want to upload.</p>
 -->
 <div class="uploadContainer">
   <form #form="ngForm" class="form-group">
     <div   class="fileContainer">
      <i style="margin-right: 10px ;" class="fas fa-upload"></i> Enviar arquivos
       <input style="width: 300px;" type="file" id="file-upload" name="upload " (change)="storeFiles($event.target.files)" [(ngModel)]="filename.name"  />
     </div>
<!--      <button type="submit" (click)="upload()">Submit</button>
 -->   </form>
 </div>
