import { DbxAuth } from './../configs';
import { HomologacoesserviceService } from './../homologacoes/homologacoesservice.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { dropboxConfig } from '../configs';
import { LocalStorageMethods, getAuthObj } from '../utils';

import { AuthService } from '../auth.service';
import { viewClassName } from '@angular/compiler';



@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit, OnDestroy {
  public dbxAuth: DbxAuth;
  private subscription: Subscription;
  dbcAutho = [];
  autorizado = 'true';
  // tslint:disable-next-line: max-line-length
  urlautorizada = 'http://localhost:4200/auth:now#access_token=z_fOSBgdnYAAAAAAAAABZX4LmH2EZ4aVH4dk5mnj_5PudG9iXhnxbk5IgwkyLqqC&token_type=bearer&uid=2555201616&account_id=dbid%3AAADJxkR7klUpJ1Af9ffMdYIGWY1VMIWcNk8'
    // tslint:disable-next-line: align

    constructor(private authService: AuthService, private router: Router, private service: HomologacoesserviceService) {
    }

    ngOnInit() {
      console.log(window.location.href)
      // tslint:disable-next-line: max-line-length
      // tslint:disable-next-line: max-line-length
      this.subscription = this.authService.getAuth()
     .subscribe((auth) => this.dbxAuth = auth);
        // Begin authentication process if credentials not found
      if (this.autorizado === 'true') {
            const authUrl = this.router.url;
            const parameters = authUrl.split('#')[1] || '';
            if (this.autorizado === 'true') {
                const arrParams = parameters.split('&') || [];
                if (this.autorizado === 'true') {
                    const authObj: DbxAuth = { isAuth: false };
                    // tslint:disable-next-line: prefer-for-of
                    authObj.accessToken = 'z_fOSBgdnYAAAAAAAAABWcrt2Kyp4a5Cnsc599vY5SEuzdjYKAfgqYb-Bho2qLP2';
                    authObj.tokenType = 'bearer';
                    authObj.uid = '2555201616';
                    authObj.accountId = 'dbid:AADJxkR7klUpJ1Af9ffMdYIGWY1VMIWcNk8';
                    if (authObj.accessToken && authObj.tokenType && authObj.uid && authObj.accountId) {
                        authObj.isAuth = true;
                        this.dbxAuth = authObj;
                    }

                    console.log('authObj', authObj);
                }
            }

            // Store credentials into Auth-service and into localStorage
            if (this.dbxAuth.isAuth) {
                this.authService.storeAuth(this.dbxAuth);
                console.log('dbx auth é');
                console.log(this.dbxAuth);
                this.router.navigate(['']); // Navigate the user to homepage
            }
        } else {
            this.router.navigate(['']); // Navigate the user to homepage
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    handleAuthorization() {
        const urlAuth = `https://www.dropbox.com/oauth2/authorize?`
            + `client_id=${dropboxConfig.clientId}`
            + `&redirect_uri=${dropboxConfig.redirectUri}`
            + `&response_type=${dropboxConfig.responseType}`;
            // tslint:disable-next-line: max-line-length
    // tslint:disable-next-line: max-line-length
       // window.location.href = (urlAuth);
          // tslint:disable-next-line: max-line-length
        window.location.href = ('https://localhost:3000#access_token=z_fOSBgdnYAAAAAAAAABZX4LmH2EZ4aVH4dk5mnj_5PudG9iXhnxbk5IgwkyLqqC&token_type=bearer&uid=2555201616&account_id=dbid%3AAADJxkR7klUpJ1Af9ffMdYIGWY1VMIWcNk8');
      }
}
