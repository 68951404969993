
<nb-card style="margin-right: 0.5rem;margin-left: 0.5rem;margin-top: 2rem !important; border: 1px solid rgb(171, 171, 255)" status="success">
    <nb-card-header style="background-color: white; color: black; padding: 0rem !important">
       
    </nb-card-header>
    <nb-card-body>
        <div class="form-row">
            <div class="form-group col-6">
                <ejs-textbox  placeholder="Nome" cssClass="e-outline" [(ngModel)]="nome" floatLabelType="Always"></ejs-textbox>
            </div>
            <div class="form-group col-6">
                <ejs-textbox  placeholder="Whatsapp" cssClass="e-outline" [(ngModel)]="wpp" floatLabelType="Always"></ejs-textbox>
            </div>
            <button style="width: 100% !important" (click)="enviatexto();" nbButton>
                Enviar
              </button>
           
        </div>
    </nb-card-body>
    <nb-card-footer style="background-color: white; color: white">
    </nb-card-footer>
</nb-card>















