import { MYCARTEIRA } from './mycarteira';
import { FORMS } from './fomu';
import { PAINV } from './parametrosinv';
import { DbxAuth } from './../configs';
import { todocaixa } from './caixa';
import { cadstroclientefinal } from './cadastrocliente';
import { idempresa } from './idempresa';
import { cadastrologin } from './cadastroparceiro';
import { todosparceiros } from './todosparceiros';
import { todosdados } from './todosdados';
import { atualizastatus } from './atualiza';
import { dadossolicitante } from './dadossolicitante';
import { dadoshomologa } from './dadoshomologa';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { tap, delay, take, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { ID } from './id';
import { MOD } from './modelomodu';
import { PAMOD } from './parametrosmod';
import * as FileSaver from 'file-saver';
import { NOTIFYS } from './notificacoes';
import { MYCLIENTES } from './myclientes';
import { MYTAREFA } from './mytarefas';
import { FILTROHOMOL } from './dadoshomol';
import { HOMOTAREFA } from './taskhomol';
import { RESP } from './resposta';
import { Router } from '@angular/router';
import { todoslogins } from './todoslogins';
import { ENVIA } from './envia';
import { User } from './user';
import { Customer } from '../employers/data';


@Injectable({
  providedIn: 'root'
})
export class HomologacoesserviceService {
  fs: any;
  coord: string;
  onCategoriesChanged: BehaviorSubject<any>;
  onCoursesChanged: BehaviorSubject<any>;
  docx: Buffer;


   httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin':'*',
        "Content-Type": "application/json",
      })
    };
  public getToken(): string {
      return localStorage.getItem('tokenx');
    }
  url  = 'https://app.omie.com.br/api/v1/geral/clientes/'

  private readonly API1 = 'api/_functions/consultas/loginadm';
  private readonly API2 = 'api/filtrohomolsoft';
  private readonly API3 = 'api/dadoshomologa';
  private readonly API4 = 'api/solicitante';
  private readonly API5 = 'api/consecionarias';
  private readonly API6 = 'api/uploadssof';
  private readonly API7 = 'api/potmodulos';
  private readonly API8 = 'api/modelomodu';
  private readonly API9 = 'api/potinv';
  private readonly API10 = 'api/marcainver';
  private readonly API11 = 'api/modeloinv';
  private readonly API12 = 'api/parametrosmodu';
  private readonly API13 = 'api/parametrosinv';
  private readonly API14 = 'https://www.sollux.dev/api/_functions/consultas/docx';
  private readonly API15 = 'https://www.sollux.dev/api/_functions/consultas/loginadm';
  private readonly API16 = 'https://www.sollux.dev/api/_functions/consultas/myhomol';

  invokeFirstComponentFunction = new EventEmitter();    
  subsVar: Subscription;
  
  // tslint:disable-next-line: max-line-length
  private authSource = new BehaviorSubject('false');
  currentauth = this.authSource.asObservable();
  private pastasource = new BehaviorSubject('false');
  currentpasta = this.pastasource.asObservable();
  private Bae64Source = new BehaviorSubject('0');
currentBae64Source = this.Bae64Source.asObservable();
  private urlSource = new BehaviorSubject('false');
  currenturl = this.urlSource.asObservable();

  // tslint:disable-next-line: max-line-length
  private carteiraSource = new BehaviorSubject(0);
  currentCarteira = this.carteiraSource.asObservable();

  private wppSource = new BehaviorSubject('false');
  currentwpp = this.wppSource.asObservable();


  private abriumapaSource = new BehaviorSubject('false');
  currentabriumapa = this.abriumapaSource.asObservable();


  private nomeSource = new BehaviorSubject('false');
  currentNome = this.nomeSource.asObservable();

  private idSource = new BehaviorSubject('false');
  currentid = this.idSource.asObservable();

  private cargoSource = new BehaviorSubject('false');
  currentcargo = this.cargoSource.asObservable();
  
  private validosource = new BehaviorSubject('0');
  currentvalido = this.validosource.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    this.fs = (window as any).fs;
  }
  OMIE_APP_KEY = '1533753466245';
  OMIE_APP_SECRET = 'a8f92960c5367bf603974ce2e945512b';
  private latitudesource = new BehaviorSubject(null);
  currentelatitude = this.latitudesource.asObservable();
  private longitudesource = new BehaviorSubject(null);
  currentelongitude = this.longitudesource.asObservable();

  private formSource = new BehaviorSubject([]);
  currentForm = this.formSource.asObservable();

  mudaform(formular: any) {
    this.formSource.next(formular);
  }

  abriumapa(maps: string) {
    this.abriumapaSource.next(maps);
  }
 // read file synchronous
 getFile(path: string) {
  // return synchronous filestream
  return this.fs.readFileSync(path);
}
changeBase64(img: any) {
  this.Bae64Source.next(img);
}
validalogin(valido: string) {
  this.validosource.next(valido);
}

private dauthSource = new BehaviorSubject([]);
currentdAuth = this.dauthSource.asObservable();
private emailSource = new BehaviorSubject('');
currentmail = this.emailSource.asObservable();
  

  public getradiacao(): Observable<any> {
    return this.http.get('./assets/json/radiacao.json');
}

public getradiacaoglobal(): Observable<any> {
  return this.http.get('./assets/json/radiacaoglobal.json');
}

public getemperatura(): Observable<any> {
  return this.http.get('./assets/json/temperaturas.json');
}
changemapa(maps: string) {
  this.abriumapaSource.next(maps);
}
// API LOGIN NODE SERVER
loginnode(paramns1) {
  return this.http.get<ID[]>('https://www.sollux.dev/api/_functions/consultas/login', {params: paramns1})
.pipe(tap()
    );
}
 // API PUXA HOMOLOGAÇÕES
 homologacaodados(paramns2) {
  const headers= new HttpHeaders()
  
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<todosdados[]>(this.API2, {params : paramns2 , headers: headers })
.pipe(tap());
}

 // FILTRA HOMOLOGAÇÃO
dadoshomologacoes(paramns) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<dadoshomologa[]>(this.API3, {params : paramns , headers: headers });
}

onFirstComponentButtonClick() {    
  this.invokeFirstComponentFunction.emit();    
}  

 // DADOS PARCEIRO
dadossolicitante(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<dadossolicitante[]>(this.API4, {params : paramns2, headers: headers  })
.pipe(tap());
}

 // PUXA CONCESSIONÁRIA
 concessionarias() {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>(this.API5, {headers: headers} )
.pipe(tap());
}

getCustomersLarge() {
  return this.http.get<any>('assets/customers.json')
      .toPromise()
      .then(res => <Customer[]>res.data)
      .then(data => { return data; });
}


// API PUXA UPLOAD
puxauploads(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>(this.API6, {params : paramns2,headers: headers })
.pipe(tap());
}


changeurl(url: string) {
  this.urlSource.next(url);
}

changeCarteira(nome) {
  this.carteiraSource.next(nome);
}

changeNome(nome: string) {
  this.nomeSource.next(nome);
}

changeauth(aut: string) {
  this.authSource.next(aut);
}
changedauth(dauth) {
  this.dauthSource.next(dauth);
}
changeid(id: string) {
  this.idSource.next(id);
}
changecargo(id: string) {
  this.cargoSource.next(id);
}


mudaemail(email: string) {
  this.emailSource.next(email);
}
mudalogitude(longitude: string) {
  this.longitudesource.next(longitude);
}
mudalatitude(latitude: string) {
  this.latitudesource.next(latitude);
}

mudapasta(pasta: string) {
  this.pastasource.next(pasta);
}

// API's DO NOVO SERVIDOR

// API CURSOS
getCourses(): Promise<any> {
    return new Promise((resolve, reject) => {
        this.http.get('https://www.sollux.dev/api/_functions/consultas/cursos')
            .subscribe((response: any) => {
                this.onCoursesChanged.next(response);
                resolve(response);
            }, reject);
    });
}

// API LOGIN NODE SERVER
regdatasol(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<RESP[]>('https://www.sollux.dev/api/_functions/consultas/registradatasol', {params : paramns1 ,headers: headers})
.pipe(tap()
    );
}
// API LOGIN NODE SERVER
loginauth(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<ID[]>('https://www.sollux.dev/api/_functions/consultas/logindados', {params : paramns1, headers: headers })
.pipe(tap()
    );
}

// API LOGIN NODE SERVER
admx(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<ID[]>('https://www.sollux.dev/api/_functions/consultas/getadmx', {params : paramns1, headers: headers })
.pipe(tap()
    );
}

datasolcuso(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<ID[]>('https://www.sollux.dev/api/_functions/consultas/cursos', {params : paramns1, headers: headers })
.pipe(tap()
    );
}


changewpp(wpp: string) {
  this.wppSource.next(wpp);
}

// API ARQ NEC
dowconcessionarias() {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/concessionarias',{headers: headers})
.pipe(tap()
    );
}

// API  ARQUIVOS NECESSARIOS
arquivosnecessarios(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/arquivosnecessarios', {params : paramns1 ,headers: headers})
.pipe(tap()
    );
}

// API  ARQUIVOS NECESSARIOS
checks(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/checksx', {params : paramns1 ,headers: headers})
.pipe(tap());
}
  
  // API  ARQUIVOS NECESSARIOS
allequipe(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/getallequipe', {params : paramns1 ,headers: headers})
    .pipe(tap());
}
// API PUXA indicador
swnotifica(paramns2) {
  //const headers= new HttpHeaders()
  //.set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/swnotificatodos', {params : paramns2})
.pipe(tap());
}

getorcados(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/getorcados', {params : paramns1 ,headers: headers})
    .pipe(tap());
}

upaorcados(params3) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/upaorcados', {params : params3 })
.pipe(tap());
}
postaemail(params) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/postaemail', {params})
.pipe(tap());
}

criafolder(params3) {
  return this.http.post<any[]>('https://www.talktunnels.com/api/_functions/FileManager/criafolder', {params : params3 })
.pipe(tap());
}

criafolder_ticket(params3) {
  return this.http.post<any[]>('https://www.talktunnels.com/api/_functions/FileManager/criafolderticket1', {params : params3 })
.pipe(tap());
}
 // API UPDATE PARCEIRO
 postorcado(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/postorcado', {params : params3, headers: headers })
.pipe(tap());
}

    // API ENV ARQ
postfilex(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/upaimax', params3)
.pipe(tap());
}
  
    // Create User
  addUser(name: string, profileImage: File): Observable<any> {
      var formData: any = new FormData();
      formData.append("name", name);
      formData.append("avatar", profileImage);
      return this.http.post<User>('https://www.sollux.dev/filex/_functions/files/upaimax', formData)
    }
// API PUXA PROPOSTAS
getproposta(paramns2) {

  console.log(paramns2);
  this.http.post(this.API14 , { params : paramns2 }, { responseType: 'blob', reportProgress: true})
  .pipe(tap())
  .subscribe(
    data => {
      // tslint:disable-next-line: max-line-length
      const blob = new Blob([data as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', });
      FileSaver.saveAs(blob, 'Proposta_comercial.docx');
  });
  return true;
}

  // API PUXA PROPOSTAS
  getcontrato(paramns2) {

  console.log(paramns2);
  this.http.post('https://www.sollux.dev/api/_functions/consultas/contratodoc', { params : paramns2 }, { responseType: 'blob', reportProgress: true})
  .pipe(tap())
  .subscribe(
    data => {
      // tslint:disable-next-line: max-line-length
      const blob = new Blob([data as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', });
      FileSaver.saveAs(blob, 'contrato.docx');
  });
  return true;
}
  

 // API PUXA SOLICITANTE
 gsolicitante(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/solicitante', {params : params3,headers: headers })
.pipe(tap());
 }
  
  // API UPDATE PARCEIRO
updateparceiro2(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updateparceiro2', {params : params3, headers: headers })
.pipe(tap());
}

  // API UPDATE PARCEIRO
  cadastranovo(params3) {
    const headers= new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.getToken());
    return this.http.post('https://www.sollux.dev/api/_functions/consultas/cadastranovo', {params : params3, headers: headers })
  .pipe(tap());
  }

  
  // API UPDATE PARCEIRO
  cadastralista(params3) {
    const headers= new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.getToken());
    return this.http.post('https://www.sollux.dev/api/_functions/consultas/inserttodo', {params : params3, headers: headers })
  .pipe(tap());
  }
  // API UPDATE PARCEIRO
  upamydados(params3) {
    const headers= new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.getToken());
    return this.http.post('https://www.sollux.dev/api/_functions/consultas/upamydados', {params : params3, headers: headers })
  .pipe(tap());
  }
  // API UPDATE PARCEIRO
  upamydados2(params3) {
    const headers= new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.getToken());
    return this.http.post('https://www.sollux.dev/api/_functions/consultas/upamydados2', {params : params3, headers: headers })
  .pipe(tap());
  }
  
// API PUXA HOMOLOGAÇÕES
 myhomols(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<todosdados[]>('https://www.sollux.dev/api/_functions/consultas/myhomol', {params : paramns2,headers: headers })
.pipe(tap());
 }

 // API PUXA mytickets
 mytickets(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/mytickets', {params : paramns2,headers: headers })
.pipe(tap());
 }

 // API FILTRA ticket
filtraticket(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/filtra_ticket', {params : paramns2,headers: headers })
.pipe(tap());
}

 // API FILTRA ticket
 counttickets(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/counttickets', {params : paramns2,headers: headers })
.pipe(tap());
}

 // API INSERE tar
 insertticket(params3) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/insertticket', {params : params3 })
.pipe(tap());
}

gethelpmsg_ticket(paramns2) {
  //const headers= new HttpHeaders()
  //.set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/gethelp_ticket', {params : paramns2})
.pipe(tap());
}


 
 
 // API PUXA HOMOLOGAÇÕES
 filtraproposta(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<todosdados[]>('https://www.sollux.dev/api/_functions/consultas/filtraproposta', {params : paramns2,headers: headers })
.pipe(tap());
 }

// API PUXA HOMOLOGAÇÕES
myhomol2(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<todosdados[]>('https://www.sollux.dev/api/_functions/consultas/myhomol2', {params : paramns2,headers: headers })
.pipe(tap());
 }


 
 mypend(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<todosdados[]>('https://www.sollux.dev/api/_functions/consultas/mypend', {params : paramns2,headers: headers })
.pipe(tap());
 }
  
  // API PUXA CALENDARIOS
 mycalendar(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/mycalendar', {params : paramns2,headers: headers })
.pipe(tap());
}
// API PUXA COMENTARIO
enviaemail(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post('https://www.sollux.dev/api/_functions/sendmail/solux1', {params : params3, headers: headers })
.pipe(tap());
}
// API PUXA TODos wpp
allcontawpp() {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/todoscontatos',{headers: headers})
.pipe(tap());
}
// API PUXA TODAS HOMOLOGAÇÕES
todashomols(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<todosdados[]>('https://www.sollux.dev/api/_functions/consultas/todashomol',{params : paramns2,headers: headers})
.pipe(tap());
}

// API PUXA TODAS gestao
todasgestao() {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/todasgestao',{headers: headers})
.pipe(tap());
}

 // API INSERE gestao
 novagestao(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/nwgestao', {params : params3 })
.pipe(tap());
}
// API PUXA TODOS PARCEIROS
allparceiros() {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<todosparceiros[]>('https://www.sollux.dev/api/_functions/consultas/allparceiros',{headers: headers})
.pipe(tap());
}
// API PUXA VISTORIAS
myvistoria(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<todosdados[]>('https://www.sollux.dev/api/_functions/consultas/myvisto', {params : paramns2 ,headers: headers})
.pipe(tap());
}


// API PUXA VISTORIAS
myClitickes(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<todosdados[]>('https://www.sollux.dev/api/_functions/consultas/myClitickes', {params : paramns2 ,headers: headers})
.pipe(tap());
}

// API PUXA FILTRA VISTORIAS
filtravisto(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/filtravisto', {params : paramns2 ,headers: headers})
.pipe(tap());
}

  // API PUXA FILTRA RATEIO
  filtrarateio(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/filtrateio', {params : paramns2 ,headers: headers})
.pipe(tap());
}
  

// API SOLICITA VISTORIAS
solicitavisto(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/sendmail/vistoriaenviada', {params : paramns2,headers: headers })
.pipe(tap());
}


helpmsg(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/helpmsg', {params : params3 })
.pipe(tap());
}

helpmsg_ticket(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/helpmsgtickets', {params : params3 })
.pipe(tap());
}



gethelpmsg(paramns2) {
  //const headers= new HttpHeaders()
  //.set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/gethelpmsg', {params : paramns2})
.pipe(tap());
}
// API PUXA CLIENTES
myclientes(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<MYCLIENTES[]>('https://www.sollux.dev/api/_functions/consultas/myclientes', {params : paramns2 ,headers: headers})
.pipe(tap());
}

// API PUXA CLIENTES
myclientes2(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<MYCLIENTES[]>('https://www.sollux.dev/api/_functions/consultas/myclientes2', {params : paramns2 ,headers: headers})
.pipe(tap());
}

 // API INSERE HOMOLOGACAO
 novahomologacao(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/inserthomol', {params : params3 })
.pipe(tap());
}

 // API SALVAPROPOSTA
 salvaproposta(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/salvaproposta', {params : params3 })
.pipe(tap());
}

 // API SALVAPROPOSTA
 atualizaproposta(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/atualizaproposta', {params : params3 })
.pipe(tap());
}


// API FILTRA TAREFA MANAGER
criadbmana(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/criadbmana', {params : paramns2,headers: headers })
  .pipe(tap());
}

// API KANBAN 
getcoment(paramns2) {
  //const headers= new HttpHeaders()
  //.set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/getcommentex', {params : paramns2})
.pipe(tap());
}
// API ATUALIZA CARTEIRA PROJETOS
updatetodo(paramns2) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/updatetodo', {params : paramns2 })
.pipe(tap());
}
 // API
 inserttodos(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/inserttodos', {params : params3 })
.pipe(tap());
}
// API todos kanban
gettodos(paramns2) {
  //const headers= new HttpHeaders()
  //.set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/gettodos', {params : paramns2})
.pipe(tap());
}
 // API INSERE tar
 insertcommentx(params3) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/insertcommentx', {params : params3 })
.pipe(tap());
}
// API PUXA indicador
getliix(paramns2) {
  //const headers= new HttpHeaders()
  //.set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/gettlist', {params : paramns2})
.pipe(tap());
}
 // API INSERE PARCEIRO
 insertpar(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/insertpar', {params : params3 })
.pipe(tap());
}
 // API INSERE PARCEIRO
 insertcal(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/insertcal', {params : params3 })
.pipe(tap());
}
// API INSERE NOTIFICACAO
novanoti(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/novanoti', {params : params3 })
.pipe(tap());
}

// API INSERE HOMOLOGACAO
updatehomol(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updatehomol', {params : params3 })
.pipe(tap());
}

  // API INSERE HOMOLOGACAO
updatehomolx(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updatehomolx', {params : params3 })
.pipe(tap());
}

// API PARADA HOMOLOGACAO
paradahomol(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updateparada', {params : params3 })
.pipe(tap());
}
// API INSERE HOMOLOGACAO
updatehomolomie(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updateHomolomie', {params : params3 })
.pipe(tap());
}


// API INSERE HOMOLOGACAO
updatestaus(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updatestatus', {params : params3 })
.pipe(tap());
}


// API UPDATE GESTAO
updatege(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updatege', {params : params3 })
.pipe(tap());
}
// API UPDATE SUB
upasubnot(params3) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/upanot', {params : params3 })
.pipe(tap());
}

 // API INSERE CLIENTE
 novocliente(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/insertcliente', {params : params3 })
.pipe(tap());
}
 // API INSERE CLIENTE
 novocliente2(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/insertcliente2', {params : params3 })
.pipe(tap());
}

atualizanovocliente(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/atualizanovocliente', {params : params3 })
.pipe(tap());
}




// API UPDATE CLIENTE
updatecliente(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updatecliente', {params : params3 })
.pipe(tap());
}
  
  // API UPDATE CLIENTE
  updatecliente2(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updateclientxe', {params : params3 })
.pipe(tap());
}

// API UPDATE PARCEIRO
updateparceiro(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updateparceiro', {params : params3 })
.pipe(tap());
}
// API UPDATE PARCEIRO
upaomie(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updateparceiroomie', {params : params3 })
.pipe(tap());
}


// API UPDATE STATUS VISTORIA
upastatusvisto(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/upvistohomol', {params : params3 })
.pipe(tap());
}
  
  

// API PUXA NOTIFICAÇÕES
mynotificacoes(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/notificacoes', {params : paramns2 ,headers: headers})
.pipe(tap());
}

// API INSERE TAREFAS PROJETOS CLIENTES
newtarefa(paramns2) {
  return this.http.post<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/inserttarefas', {params : paramns2 })
.pipe(tap());
}

// API PUXA TAREFAS PROJETOS CLIENTES
mytarefas(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<MYTAREFA[]>('https://www.sollux.dev/api/_functions/consultas/mytarefas', {params : paramns2 ,headers: headers})
.pipe(tap());
}
  
  // API PUXA TAREFAS PROJETOS CLIENTES
  cincow(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<MYTAREFA[]>('https://www.sollux.dev/api/_functions/consultas/cincow', {params : paramns2 ,headers: headers})
.pipe(tap());
}


// API PUXA TAREFAS HOMOLOGACOES
tarefashomol(paramns2) {
  // tslint:disable-next-line: max-line-length
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<HOMOTAREFA[]>('https://www.sollux.dev/api/_functions/consultas/tarefashomologacoes', {params : paramns2 ,headers: headers})
.pipe(tap());
}

// API DELETA TAREFA HOMOLOGACOES
deltarhomol(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.delete<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/deltar', {params : paramns2 ,headers: headers })
.pipe(tap());
}
  
delorca(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.delete<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/delorca', {params : paramns2 ,headers: headers })
.pipe(tap());
}


  // API DELETA CLIENTE
delcliente(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.delete<any[]>('https://www.sollux.dev/api/_functions/consultas/delclientemanager', {params : paramns2 ,headers: headers })
.pipe(tap());
}
  
    // API DELETA CALEDARIO
delcar(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.delete<any[]>('https://www.sollux.dev/api/_functions/consultas/delcar', {params : paramns2 ,headers: headers })
.pipe(tap());
}

// API DELETA TAREFA HOMOLOGACOES
delnoti(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.delete<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/delnot', {params : paramns2,headers: headers  })
.pipe(tap());
}

// API DELETA TAREFA COTACAO
delcota(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.delete<any[]>('https://www.sollux.dev/api/_functions/cotafacil/delcota', {params : paramns2 ,headers: headers })
.pipe(tap());
}

// API ATUALIZA TAREFA HOMOL
upatarefahomol(paramns2) {
  return this.http.post<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/updatetarhomol', {params : paramns2 })
.pipe(tap());
}
  
  // API UPDATE STATUS VISTORIA
uparateio(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updaterateio', {params : params3 })
.pipe(tap());
}
  // API DELETA TAREFA HOMOLOGACOES
  delrat(paramns2) {
    const headers= new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.getToken());
    return this.http.delete<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/delrat', {params : paramns2,headers: headers  })
  .pipe(tap());
  }
// API INSERE TAREFAS HOMOLOGACOES
newtaskhomol(paramns2) {
  return this.http.post<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/newtarhomol', {params : paramns2 })
.pipe(tap());
}

// API PUXA CARTEIRA
mycarteira(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<MYCARTEIRA[]>('https://www.sollux.dev/api/_functions/consultas/mycarteira', {params : paramns2,headers: headers })
.pipe(tap());
}

// API ATUALIZA CARTEIRA PROJETOS
upprojetos(paramns2) {
  return this.http.post<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/updateprojetos', {params : paramns2 })
.pipe(tap());
}
// API ATUALIZA CARTEIRA PROPOSTA
upproposta(paramns2) {
  return this.http.post<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/updatepropostas', {params : paramns2 })
.pipe(tap());
}
// API ATUALIZA TAREFA PROJETOS CLIENTES
upatarefa(paramns2) {
  return this.http.post<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/updatetarefa', {params : paramns2 })
.pipe(tap());
}
// API DELETA TAREFA PROJETOS CLIENTES
deletatarefa(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.delete<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/deletetarefa', {params : paramns2 ,headers: headers })
.pipe(tap());
}


// API ATUALIZA STATUS PROJ
upstatusproj(paramns2) {
  return this.http.post<NOTIFYS[]>('https://www.sollux.dev/api/_functions/consultas/upastatusproj', {params : paramns2 })
.pipe(tap());
}
// API FILTRA HOMOL
getdbx(paramns2:any) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<FILTROHOMOL[]>('https://www.sollux.dev/api/_functions/consultas/dbox',{params : paramns2, headers: headers })
.pipe(tap());
}
// API ATUALIZA NOTIFICACAO
upanot(paramns2) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/upnotify', {params : paramns2 })
.pipe(tap());
}

// API FILTRA HOMOL
filtrahomologa(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<FILTROHOMOL[]>('https://www.sollux.dev/api/_functions/consultas/filtrahomol', {params : paramns2,headers: headers })
.pipe(tap());
}

// API FILTRA TAREFA MANAGER
filtramanagerx(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/filtramanax', {params : paramns2,headers: headers })
.pipe(tap());
}

// API FILTRA LOGIN
filtralogin(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<todoslogins[]>('https://www.sollux.dev/api/_functions/consultas/filtralogin', {params : paramns2,headers: headers })
.pipe(tap());
}

// API FILTRA LOGIN
recuperasenha(paramns2) {
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/recuperasenha', {params : paramns2})
.pipe(tap());
}


// API LOGIN NODE SERVER
getwpp(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/getwpp', {params : paramns1 ,headers: headers })
.pipe(tap()
    );
}

// API LOGIN NODE SERVER
getwppadm(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/getwppadm', {params : paramns1 ,headers: headers })
.pipe(tap()
    );
}
 // PUXA puxa marca
 potmod(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/marcamodulos', {params : paramns2,headers: headers  })
.pipe(tap());
}

 // PUXA puxa mdoelo modulo
 modelomodu(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<MOD[]>('https://www.sollux.dev/api/_functions/consultas/modelomodulos', {params : paramns2,headers: headers  })
.pipe(tap());
}

// PUXA parametros modulos
parametrosmodulo(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<PAMOD[]>('https://www.sollux.dev/api/_functions/consultas/parametromodulo2', { params : paramns2,headers: headers  })
.pipe(tap());
}

 // PUXA POTENCIA DOS INVERSORES
 potinv(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/potenciainversores', {params : paramns2,headers: headers  })
.pipe(tap());
}

 // PUXA puxa marca inversor
 marcainver(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/marcainversores', {params : paramns2,headers: headers  })
.pipe(tap());
}

// PUXA modelo inversor
modeloinv(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<MOD[]>('https://www.sollux.dev/api/_functions/consultas/modeloinversores', {params : paramns2 ,headers: headers })
.pipe(tap());
}

versao(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<ID[]>('https://www.sollux.dev/api/_functions/consultas/versaodata', {params : paramns1, headers: headers })
.pipe(tap()
    );
}
  
// PUXA parametros inversores
parametrosinversores(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<PAINV[]>('https://www.sollux.dev/api/_functions/consultas/parametrosinversores', { params : paramns2,headers: headers  })
.pipe(tap());
}

// UPA parametros inversores
upainver(paramns2) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/upaiv', { params : paramns2 })
.pipe(tap());
}
// POST CARTERA PROPOSTA
upacarteiraprop(paramns2) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/propostaup', paramns2 )
.pipe(tap());
}
  
  // UPDATE CALEDARIO
upacalendar(paramns2) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/upacalendar', paramns2 )
.pipe(tap());
}

// TODAS COTACOES
getcotacoes() {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/cotafacil/todascota' ,{headers: headers })
.pipe(tap());
}

// FILTRA COTACAO
filtracotacao(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/cotafacil/filtracota' , { params : paramns2 ,headers: headers })
.pipe(tap());
}

// POST COTACAO
updatecotacao(paramns2) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/cotafacil/updatecotacao', paramns2 )
.pipe(tap());
}

// POST COTACAO
upatarmanagerx2(paramns2) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/upatarmanagerx2', paramns2 )
.pipe(tap());
}

// FILTRA USUARIO COTACAO
filtrausercota(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/cotafacil/filtraucota' , { params : paramns2,headers: headers  })
.pipe(tap());
}
// NOTIFICA COTACAO
notificacota(paramns2) {
  return this.http.post<any>('https://www.sollux.dev/api/_functions/consultas/notificacota', paramns2 )
.pipe(tap());
}

// NOTIFICA COTACAO
notificadata(paramns2) {
  return this.http.post<any>('https://www.sollux.dev/api/_functions/consultas/notificadata', paramns2 )
.pipe(tap());
}

// API INSERE COMENTARIO
novocomment(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/insertcomment', {params : params3 })
.pipe(tap());
}
  
  // API INSERE COMENTARIO
novocomment2(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/insertcommentcli', {params : params3 })
.pipe(tap());
}

// Aupdate homol omie
updatehomolma(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updateHomolmanager', {params : params3 })
.pipe(tap());
}


// API PUXA COMENTARIO
puxacoment(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get('https://www.sollux.dev/api/_functions/consultas/comentarios', {params : params3,headers: headers  })
.pipe(tap());
}

// API PUXA COMENTARIO
delcoment(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.delete('https://www.sollux.dev/api/_functions/consultas/delmsg', {params : params3,headers: headers  })
.pipe(tap());
}

// API PUXA COMENTARIO
upacoment(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/upamsgsuo', {params : params3 })
.pipe(tap());
}

// API PUXA COMENTARIO
wpp(params3) {
  
  return this.http.get('https://www.sollux.dev/api/_functions/whatsapp/start', {params : params3 })
.pipe(tap());
}


// API eni
sendtext(params3) {
  return this.http.post<any[]>('https://www.talktunnels.com/api/_functions/whatsapp/sendText', {params : params3 })
.pipe(tap());
}
// API eni
sendtextintersolar(params3) {
  return this.http.post<ENVIA[]>('https://sollux.dev/api/_functions/whatsapp/sendTextintersolar', {params : params3 })
.pipe(tap());
}

// API PUXA COMENTARIO
sendtextgrupo(params3) {
  return this.http.post<ENVIA[]>('https://www.talktunnels.com/api/_functions/whatsapp/sendText', {params : params3 })
.pipe(tap());
}

contatos(params3) {
  return this.http.get('https://www.sollux.dev/api/_functions/whatsapp/contatos', {params : params3 })
.pipe(tap());
}

qrcode(params3) {
  return this.http.get('https://www.sollux.dev/api/_functions/whatsapp/qrcode', {params : params3 })
.pipe(tap());
}
getallmsg(params3) {
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/whatsapp/getmsg', {params : params3 })
.pipe(tap());
}
getallmsg2(params3) {
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/whatsapp/ultimas', {params : params3 })
.pipe(tap());
}

getchawpp(params3) {
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/whatsapp/getchawpp', {params : params3 })
.pipe(tap());
}

licitacoes(params3) {
  return this.http.get<any[]>('http://transparencia.al.gov.br/licitacao/json-editais/', {params : params3 })
.pipe(tap());
}

start2(params3) {
  return this.http.get<any[]>('http://209.126.12.101:3000/api/_functions/wppapi/start2', {params : params3 })
.pipe(tap());
}
nlidas(params3) {
  return this.http.get('http://209.126.12.101:3000/api/_functions/wppapi/nlidas', {params : params3 })
.pipe(tap());
}
// API PUXA COMENTARIO
puxachat(params3) {
  return this.http.get('https://www.sollux.dev/api/_functions/cotafacil/filtchatx', {params : params3})
.pipe(tap());
}


// API PUXA TODOS CHATS PENDENTES
chatpendentes(params3) {
  return this.http.get('https://www.sollux.dev/api/_functions/cotafacil/puxchat', {params : params3})
.pipe(tap());
}


// API PUXA NOTIFICAÇÕES
gettarefas(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/kanbancliente', {params : params3,headers: headers  })
.pipe(tap());
}

getrelatoriotar(paramns2) {
  console.log(paramns2);
  this.http.get('https://www.sollux.dev/api/_functions/consultas/getrelatoriotar' , { params : paramns2 , responseType: 'blob', reportProgress: true})
  .pipe(tap())
  .subscribe(
    data => {
      // tslint:disable-next-line: max-line-length
      const blob = new Blob([data as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', });
      FileSaver.saveAs(blob, 'Relatório_tarefas.docx');
  });
  return true;
}


// API PUXA NOTIFICAÇÕES
gebtwndate(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/gebtwndate', {params : params3,headers: headers  })
.pipe(tap());
}

// API PUXA NOTIFICAÇÕES
getresp(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/getresp', {params : params3,headers: headers  })
.pipe(tap());
}


// API PUXA NOTIFICAÇÕES
gettarefash(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/managerxh', {params : params3,headers: headers  })
.pipe(tap());
}
// API PUXA NOTIFICAÇÕES
getmanagersdate(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/getmanagersdate', {params : params3,headers: headers  })
.pipe(tap());
}



// API PUXA NOTIFICAÇÕES
allmymanager(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/allmymanager', {params : params3,headers: headers  })
.pipe(tap());
}


// API PUXA NOTIFICAÇÕES
gettarefasx(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/managerx2', {params : params3,headers: headers  })
.pipe(tap());
}

enviaomie(paramns2){
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/UpsertCliente', {params : paramns2} )
  .pipe(tap()); 
}

contareceberomie(paramns2){
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/IncluirContaReceber', {params : paramns2} )
  .pipe(tap()); 
}
validaomie(paramns2){
  return this.http.post<any>('https://www.sollux.dev/api/_functions/consultas/updateparceiroomie', {params : paramns2} )
  .pipe(tap()); 
}

 // API INSERE TAREFA MANAGER
 newmanager(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/insertmanager', {params : params3 })
.pipe(tap());
}
managercli(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/insertmanagercli', {params : params3 })
.pipe(tap());
}
newmanager2(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/insertmanager2', {params : params3 })
.pipe(tap());
}

// API PUXA NOTIFICAÇÕES
lenmanager(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/lemanagerx', {params : params3,headers: headers  })
.pipe(tap());
}


// API PUXA NOTIFICAÇÕES
interpj() {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/interpj', {headers: headers  })
.pipe(tap());
}


// API PUXA NOTIFICAÇÕES
getcontasx(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any>('https://www.sollux.dev/api/_functions/consultas/getcontasx', {params : params3,headers: headers  })
.pipe(tap());
}

upasenha(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/upasenha', {params : params3, headers: headers })
.pipe(tap());
}

// API UPDATE senh
upasenha2(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/upasenha2', {params : params3})
.pipe(tap());
}

// API eni
sendtextuni(params3) {
  return this.http.post<ENVIA[]>('https://sollux.dev/api/_functions/whatsapp/sendText', {params : params3 })
.pipe(tap());
}

  // API eni
clima(params3) {
  return this.http.get<any[]>('https://my.meteoblue.com/yearlyarchive/temperature_frequency?look=KILOMETER_PER_HOUR%2CCELSIUS%2CMILLIMETER&apikey=5838a18e295d&temperature=C&windspeed=kmh&precipitationamount=mm&winddirection=3char&city=Montes+Claros&iso2=br&lat=-16.735&lon=-43.8617&asl=686&tz=America%2FSao_Paulo&format=json&lang=pt&ts=1626561714&sig=1827d81e96e23b6ee38fa70c022d2803', { })
.pipe(tap());
}


// API eni
pagapixs(params3) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/financeiro/pagpix', {params3 })
.pipe(tap());
}

}


