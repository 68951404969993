<!-- BOÕES CONTROLE -->
<nb-card style="margin-right: 0.5rem;margin-left: 0.5rem; margin-top: 0.5rem; height: 100% !important;">
    <nb-card-header style="background-color: white; color: black; padding: 0rem !important">
        <p-menubar *ngIf="abrecliente === 'false' " [model]="itemsy">
            <ng-template pTemplate="start"> </ng-template>
        </p-menubar>
        <p-menubar *ngIf="abrecliente === 'true' " [model]="itemsx">
            <ng-template pTemplate="start">
            </ng-template>
        </p-menubar>
    </nb-card-header>

    <div style="margin-top: 20px;" *ngIf="abrecalendar === true">
        <app-calendario></app-calendario>
    </div>
    <!-- BOÕES CONTROLE -->
    <div style="margin-top: 20px;" *ngIf="abrecalendar === false && abrecliente === 'false' " class="container">
            <div class="grid">
              <div class="col-12 md:col-6 lg:col-3">
                <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <span class="block text-500 font-medium mb-3">Elaborando</span>
                      <div  class="text-900 font-small text-xl">{{Aguardando_Proposta}}</div>
                    </div>
                    <div
                      class="
                        flex
                        align-items-center
                        justify-content-center
                        bg-blue-100
                        border-round
                      "
                      style="width: 2.5rem; height: 2.5rem"
                    >
                      <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                    </div>
                  </div>
                  <span style="font-size: 12px !important;" class="text-green-500 font-medium">{{Aguardando_Proposta/todas*100| number : '1.2-2'}}% </span>
                  <span style="font-size: 12px !important;"  class="text-500"> aguardando propostas</span>
                </div>
              </div>
              <div class="col-12 md:col-6 lg:col-3">
                <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <span class="block text-500 font-medium mb-3">Acompanhando</span>
                      <div class="text-900 font-medium text-xl">{{acompanhando}}</div>
                    </div>
                    <div
                      class="
                        flex
                        align-items-center
                        justify-content-center
                        bg-orange-100
                        border-round
                      "
                      style="width: 2.5rem; height: 2.5rem"
                    >
                      <i class="pi pi-wallet  text-orange-500 text-xl"></i>
                    </div>
                  </div>
                  <span style="font-size: 12px !important;" class="text-green-500 font-medium">{{acompanhando/todas*100| number : '1.2-2'}}% </span>
                  <span style="font-size: 12px !important;" class="text-500">Acompanhando Proposta</span>
                </div>
              </div>
              <div class="col-12 md:col-6 lg:col-3">
                <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <span class="block text-500 font-medium mb-3">Em Negociação</span>
                      <div class="text-900 font-medium text-xl">{{ Em_Negociacao }}</div>
                    </div>
                    <div
                      class="
                        flex
                        align-items-center
                        justify-content-center
                        bg-cyan-100
                        border-round
                      "
                      style="width: 2.5rem; height: 2.5rem"
                    >
                      <i class="pi pi-user-plus text-cyan-500 text-xl"></i>
                    </div>
                  </div>
                  <span style="font-size: 12px !important;" class="text-green-500 font-medium">{{Em_Negociacao/todas| number : '1.2-2'}}% </span>
                  <span style="font-size: 12px !important;" class="text-500">Negociando com cliente</span>
                </div>
              </div>
              <div class="col-12 md:col-6 lg:col-3">
                <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <span class="block text-500 font-medium mb-3">Aprovadas</span>
                      <div class="text-900 font-medium text-xl">{{Aprovadas}}</div>
                    </div>
                    <div
                      class="
                        flex
                        align-items-center
                        justify-content-center
                        bg-purple-100
                        border-round
                      "
                      style="width: 2.5rem; height: 2.5rem"
                    >
                      <i class="pi pi-check-circle text-purple-500 text-xl"></i>
                    </div>
                  </div>
                  <span style="font-size: 12px !important;" class="text-green-500 font-medium">{{Aprovadas/todas*100| number : '1.2-2'}}% </span>
                  <span style="font-size: 12px !important;" class="text-500">projetos concretizados</span>
                </div>
              </div>
            </div>
    </div>

    <div style="margin-top: 20px;" *ngIf="funil === true && abrecalendar === false">
        <nb-card style="margin-top: -1rem !important;border: none" status="success">
            <nb-card-header style="background-color: white; color:black;padding : 0rem
!important;">

            </nb-card-header>
            <nb-card-body>
                <ejs-kanban (dragStart)='OnDragStart()' (drag)='OnDrag()' (dragStop)='OnDragStop($event)' locale="pt-BR" #kanbanObj cssClass='kanban-overview' keyField="statuscliente" [dataSource]='kanbanData' [cardSettings]='cardSettings' [allowDragAndDrop]='allowDragAndDrop2'>
                    <e-columns>
                        <e-column *ngFor="let column of columns;" headerText={{column.headerText}} keyField='{{column.keyField}}'>
                            <ng-template #template let-data>
                                <div class="header-template-wrap">
                                    <div class="header-icon e-icons {{data.keyField}}"></div>
                                    <div class="header-text">{{data.headerText}}</div>
                                </div>
                            </ng-template>
                        </e-column>
                    </e-columns>
                    <ng-template #cardSettingsTemplate let-data>
                        <div (click)='filtrax(data.codcliente)' class='card-template'>
                            <div class='e-card-header'>
                                <div class='e-card-header-caption'>
                                    <span class="subtitle-2">{{data.Cliente}}</span>
                                    <div  class="row">
                                        <div  class="col-1">
                                            <span  style="margin-right: 10px !important;" class="caption-2 text-hint"><i class="pi pi-sun "></i></span>
                                        </div>
                                        <div style="margin-top: -2px !important;" class="col-9 pl-4">
                                            <span class="caption-2 text-hint"> &nbsp; {{data.kwp}} kWp</span>
                                        </div>
                                    </div>
                                    <div  class="row">
                                        <div  class="col-1">
                                            <span style="margin-right: 10px !important;" class="caption-2 "><i class="pi pi-dollar"></i></span>
                                        </div>
                                        <div style="margin-top: -2px !important;" class="col-9 pl-4">
                                            <span class="caption-2 text-hint">&nbsp; {{data.valor_total}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='e-card-content e-tooltip-text'>
                                <span style="font-size: x-small;" class="caption-2 text-hint">{{data.obstatus}}</span>
                            </div>
                            <div class='e-card-custom-footer'>
                                <nb-user size="small" [name]="data.responsavel" [picture]="base64image">
                                </nb-user>
                            </div>
                            <div class='e-card-custom-footer'>
                                <span class="caption-2 text-hint">{{data.data_inicio}}</span>

                            </div>
                        </div>
                    </ng-template>

                </ejs-kanban>
            </nb-card-body>
        </nb-card>
    </div>
    <!--  INICIO TABELA -->
    <div style="margin-top: 20px;padding:15px !important" *ngIf="abrecliente === 'false' && funil === false && abrecalendar === false">
        <!-- FILTRO -->
        <div *ngIf="renderizou === true" >
            <ejs-grid allowPaging="true" [gridLines]="'Horizontal '" id="Grid"  [pageSettings]='initialPage' [locale]="locas" #overviewgrid id="overviewgrid" allowPaging='true' [dataSource]="todosclientes" rowHeight="38" [allowSelection]="true" [editSettings]="editOptions"
                [allowSorting]="true" (dataBound)="onDataBound($event);" [enableHover]="true" [selectionSettings]="selectionSettings" [filterSettings]="filterSettings" [allowSorting]="true" [allowFiltering]="true" [enableVirtualization]="false" (rowSelected)="onRowSelected($event)"
                [toolbar]="toolbar" (toolbarClick)="toolbarClick($event)" [allowExcelExport]="true" [allowPdfExport]="true" (excelQueryCellInfo)="exportQueryCellInfo($event)" (pdfQueryCellInfo)="exportQueryCellInfo($event)">
                <e-column type='checkbox' textAlign='Left' width='50'></e-column>
                <e-columns>
                    <e-column field="codcliente" headerText="OS" width="130" textAlign='Left' [isPrimaryKey]="true">
                    </e-column>

                    <e-column field="Cliente" textAlign='Left' [visible]="true" headerText="Cliente" width="auto" [isPrimaryKey]="false">
                    </e-column>

                    <e-column field="local" textAlign='Left' [visible]="true" headerText="Local" width="auto" [isPrimaryKey]="false">
                    </e-column>

                    <e-column field="responsavel" textAlign='Left' [visible]="true" headerText="Responsável" width="170" [isPrimaryKey]="false">
                    </e-column>

                    <e-column field="mail" textAlign='Left' [visible]="true" headerText="E-mail" width="auto" [isPrimaryKey]="false">
                    </e-column>

                    <e-column field="codcliente" textAlign='Left' [visible]="true" headerText="Editar" width="130px" [isPrimaryKey]="false">
                        <ng-template #template let-data>
                            <img (click)="navtoproposta(data.codcliente);"  alt="logo" src="\assets\images\open.svg" style="width: 1rem"/>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
    <!--  FIM TABELA -->

    <div style="margin-top: 20px;" *ngIf="abrecliente === 'true' && funil === false">
        <nb-card [nbSpinner]="loading" nbSpinnerStatus="danger" style="margin-top: -1rem !important;border: none" status="success">
            <nb-card-header style="background-color: white; color:black;padding : 0rem
!important;">

            </nb-card-header>
            <nb-card-body>
                <div *ngIf="menu1 === true">
                    <div class="form-row">
                        <div class="form-group col-6">
                            <ejs-textbox placeholder="Nome" cssClass="e-outline" [(ngModel)]='clientefiltrado.Cliente' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-2">
                            <ejs-textbox placeholder="CPF/CNPJ" cssClass="e-outline" [(ngModel)]='clientefiltrado.cpf' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-textbox readonly placeholder="Código" cssClass="e-outline" [(ngModel)]='clientefiltrado.codcliente' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <ejs-textbox placeholder="E-mail" cssClass="e-outline" [(ngModel)]='clientefiltrado.mail' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-2">
                            <ejs-textbox placeholder="Telefone" cssClass="e-outline" [(ngModel)]='clientefiltrado.telefone' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-dropdownlist [(ngModel)]="clientefiltrado.responsavel" floatLabelType="Auto" cssClass="e-outline" id='ress' #sample  [dataSource]='empregados'  [fields]='fields'
                            placeholder='Responsável' ></ejs-dropdownlist>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-6">
                            <ejs-textbox placeholder="Endereço" cssClass="e-outline" [(ngModel)]='clientefiltrado.endereco' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-2">
                            <ejs-textbox placeholder="Cidade" cssClass="e-outline" [(ngModel)]='clientefiltrado.Cidade' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-2">
                            <ejs-textbox placeholder="UF" cssClass="e-outline" [(ngModel)]='clientefiltrado.Estado' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-2">
                            <ejs-textbox placeholder="CEP" cssClass="e-outline" [(ngModel)]='clientefiltrado.cep' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>
                    <p-divider></p-divider>
                    <button style="width: 100% !important;" (click)="upacliente();" nbButton>ATUALIZAR</button>
                </div>
                <div *ngIf="menu2 === true">

                    <ejs-kanban (cardDoubleClick)='OnCardDoubleClick()' (dragStart)='OnDragStart()' (drag)='OnDrag()' (dragStop)='OnDragStop($event)' locale="pt-BR" #kanbanObj cssClass='kanban-overview' keyField="Tags" [dataSource]='kanbanData' [cardSettings]='cardSettings'
                        [allowDragAndDrop]='allowDragAndDrop'>
                        <e-columns>
                            <e-column *ngFor="let column of columns;" headerText={{column.headerText}} keyField='{{column.keyField}}'>
                                <ng-template #template let-data>
                                    <div class="header-template-wrap">
                                        <div class="header-icon e-icons {{data.keyField}}"></div>
                                        <div class="header-text">{{data.headerText}}</div>
                                    </div>
                                </ng-template>
                            </e-column>
                        </e-columns>
                        <ng-template #cardSettingsTemplate let-data>
                            <div class='card-template'>
                                <div class='e-card-header'>
                                    <div class='e-card-header-caption'>
                                        <span class="subtitle-2">{{data.nomecli}}</span>
                                        <div class="row">
                                            <div style="margin-right: -20px;" class="col-1">
                                                <span class="caption-2 text-hint"><i class="pi pi-sun "></i></span>
                                            </div>
                                            <div style="margin-top: -2px !important;" class="col-9 pl-4">
                                                <span class="caption-2 text-hint">{{data.kwp}} kWp</span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div style="margin-right: -20px;" class="col-1">
                                                <span class="caption-2 text-hint"><i class="pi pi-dollar"></i></span>
                                            </div>
                                            <div style="margin-top: -2px !important;" class="col-9 pl-4">
                                                <span class="caption-2 text-hint">{{data.valor}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='e-card-content e-tooltip-text'>
                                    <span style="font-size: x-small;" class="caption-2 text-hint">{{data.obsstatus}}</span>
                                </div>
                                <div class='e-card-custom-footer'>
                                    <nb-user size="small" [name]="data.responsavel" [picture]="base64image">
                                    </nb-user>
                                </div>
                                <div class='e-card-custom-footer'>
                                    <span class="caption-2 text-hint">{{data.dataini}}</span>

                                </div>
                            </div>
                        </ng-template>

                    </ejs-kanban>
                </div>
                <div *ngIf="menu3 === true">
                    <!-- RELATÓRIO ECONOMICO -->
                    <div style="padding: 0 !important; margin-left: 0px;" class="row">

                    <div class="col-5">
                        <div  class="shadow-2 surface-card p-1" style="border-radius: 6px; background-color: rgb(255, 255, 255);">
                            <h6 style="margin-left: 5px;">Custo orçado</h6>
                        <table style="width: auto; height:100px; margin-top:15px" class="table table-sm ">
                            <tbody>
                                <tr>
                                    <th style="font-family: 'Oxygen', sans-serif;width: 200px; font-size: 13px; background-color: #F0F0F0;" scope="row">Custo do Sistema PV:</th>
                                    <td>
                                        <input [(ngModel)]="clientefiltrado.custopv" (ngModelChange)="atualizacustos()" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" style="font-size: 13px;background-color: transparent; border-radius: 0;outline: none;border-color: transparent;width: 150px; "
                                            id="custopv">
                                    </td>
                                    <th *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 200px; font-size: 13px; background-color: #F0F0F0;" scope="row">Período em Anos:
                                    </th>
                                    <td *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 100px; background-color: #F0F0F0;">
                                        <input [(ngModel)]="periodofinan" (ngModelChange)="periodofinan = $event; atualizacustos()" style="text-align: right; border-color: transparent;width: 100px; font-size: 13px;background-color: transparent;  height:20px;" class="inputa" type="number">
                                    </td>
                                </tr>
                                <tr>
                                    <th style="font-family: 'Oxygen', sans-serif;width: 250px; font-size: 13px;background-color: #F0F0F0;" scope="row">Custo Material CA:
                                    </th>
                                    <td>
                                        <div class="col-xs-2">
                                            <input [(ngModel)]="clientefiltrado.custoca" (ngModelChange)="materialca = $event; atualizacustos()" value="0" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" style="font-size: 13px;background-color: transparent; border-radius: 0;outline: none;border-color: transparent;width: 150px; "
                                                id="custoinstal">
                                        </div>
                                    </td>
                                    <th *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 200px; font-size: 13px; background-color: #F0F0F0;" scope="row">Nº de parcelas:
                                    </th>
                                    <td *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 100px; background-color: #F0F0F0;">
                                        <input readonly [value]="periodofinan*12" style="font-family: 'Oxygen', sans-serif;text-align: right; border-color: transparent;width: 100px; font-size: 13px;background-color: transparent;  height:20px;" class="inputa" type="number">
                                    </td>
                                </tr>
                                <tr>
                                    <th style="font-family: 'Oxygen', sans-serif;width: 250px; font-size: 13px;background-color: #F0F0F0;" scope="row">Custo do projeto:</th>
                                    <td>
                                        <div class="col-xs-2">
                                            <input [(ngModel)]="clientefiltrado.custoproj" (ngModelChange)="custoproj = $event; atualizacustos()" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" style="font-size: 13px;background-color: transparent; border-radius: 0;outline: none;border-color: transparent;width: 150px; "
                                                id="custoprojeto">
                                        </div>
                                    </td>
                                    <th *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 200px; font-size: 13px; background-color: #F0F0F0;" scope="row">Taxa Anual de juros:
                                    </th>
                                    <td *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 100px; background-color: #F0F0F0;">
                                        <input [(ngModel)]="taxafinan" (ngModelChange)="taxafinan = $event; atualizacustos()" style=" border-color: transparent;width: 100px; font-size: 13px;background-color: transparent;  height:20px;" class="inputa" max="100" min="0" currencyMask [options]="{ prefix: '', suffix: '%', thousands: '.', decimal: ',' }">
                                    </td>
                                </tr>
                                <tr>
                                    <th style="font-family: 'Oxygen', sans-serif;width: 250px; font-size: 13px;background-color: #F0F0F0;" scope="row">Custo de mão-de-obra:</th>
                                    <td>
                                        <div class="col-xs-2">
                                            <input [(ngModel)]="clientefiltrado.customo" (ngModelChange)="customo = $event; atualizacustos()" style="font-size: 13px;background-color: transparent; border-radius: 0;outline: none;border-color: transparent;width: 150px; " currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                id="customo">
                                        </div>
                                    </td>
                                    <th *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 200px; font-size: 13px; background-color: #F0F0F0;" scope="row">Valor Financiado:
                                    </th>
                                    <td *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 100px; background-color: #F0F0F0;">
                                        <input [(ngModel)]="percentfinan" (ngModelChange)="percentfinan = $event;" style=" border-color: transparent;width: 100px; font-size: 13px;background-color: transparent;  height:20px;" class="inputa" min="0" currencyMask [options]="{ prefix: '', suffix: '%', thousands: '.', decimal: ',' }">
                                    </td>
                                </tr>
                                <tr>
                                    <th style="font-family: 'Oxygen', sans-serif;width: 250px; font-size: 13px;background-color: #F0F0F0;" scope="row">Margem de lucro:</th>
                                    <td>
                                        <div class="col-xs-2">
                                            <input [(ngModel)]="clientefiltrado.malucro" (ngModelChange)="custosadicionais = $event; atualizacustos()" style="font-size: 13px;background-color: transparent; border-radius: 0;outline: none;border-color: transparent;width: 150px; " currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                id="customo">
                                        </div>
                                    </td>
                                    <th *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 200px; font-size: 13px; background-color: #F0F0F0;" scope="row">Valor Financiado:
                                    </th>
                                    <td *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 100px; background-color: #F0F0F0;">
                                        <input [(ngModel)]="percentfinan" (ngModelChange)="percentfinan = $event;" style=" border-color: transparent;width: 100px; font-size: 13px;background-color: transparent;  height:20px;" class="inputa" min="0" currencyMask [options]="{ prefix: '', suffix: '%', thousands: '.', decimal: ',' }">
                                    </td>
                                </tr>
                                <tr>
                                    <th style="font-family: 'Oxygen', sans-serif;width: 250px; font-size: 13px;background-color: #F0F0F0;" scope="row">Desconto:</th>
                                    <td>
                                        <div class="col-xs-2">
                                            <input [(ngModel)]="clientefiltrado.desconto" (ngModelChange)="desconto = $event; atualizacustos()" style="font-size: 13px;background-color: transparent; border-radius: 0;outline: none;border-color: transparent;width: 150px; color: red;font-weight: bold; "
                                                currencyMask [options]="{ prefix: 'R$  ', thousands: '.', decimal: ',' }" id="customo">
                                        </div>
                                    </td>
                                    <th *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 200px; font-size: 13px; background-color: #F0F0F0;" scope="row">Valor Financiado:
                                    </th>
                                    <td *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 100px; background-color: #F0F0F0;">
                                        <input [(ngModel)]="percentfinan" (ngModelChange)="percentfinan = $event;" style=" border-color: transparent;width: 100px; font-size: 13px;background-color: transparent;  height:20px;" class="inputa" min="0" currencyMask [options]="{ prefix: '', suffix: '%', thousands: '.', decimal: ',' }">
                                    </td>
                                </tr>
                                <tr>
                                    <th style="font-family: 'Oxygen', sans-serif;width: 250px; font-size: 13px;background-color: #F0F0F0; ;" scope="row">Valor R$/kWp:</th>
                                    <td style="text-align: right">
                                        <div class="col-xs-2">
                                            <input [(ngModel)]="custokwp" (ngModelChange)="custokwp= $event; atualizacustos()" style="font-size: 13px;background-color: transparent; border-radius: 0;outline: none;border-color: transparent;width: 150px; " currencyMask [options]="{ prefix: 'R$/kWp  ', thousands: '.', decimal: ',' }"
                                                id="customo">
                                        </div>
                                    </td>
                                    <th *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 200px; font-size: 13px; background-color: #F0F0F0;" scope="row">Valor das parcelas:
                                    </th>
                                    <td *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 100px; background-color: #F0F0F0;">
                                        <input [(ngModel)]="parcelafinanciamento" readonly style="background-color: transparent; border-radius: 0;outline: none;border-color: transparent; width: 100px;font-size: 13px" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="custopv">
                                    </td>
                                </tr>
                                <tr>
                                    <th style="font-family: 'Oxygen', sans-serif;width: 250px; font-size: 13px;background-color: #F0F0F0;" scope="row">Percentual de ganho:</th>
                                    <td>
                                        <div class="col-xs-2">
                                            <input readonly currencyMask [(ngModel)]="clientefiltrado.ganho" [options]="{ prefix: '', suffix: '%', thousands: '.', decimal: ',' }" style="font-size: 13px;background-color: transparent; border-radius: 0;outline: none;border-color: transparent;width: 150px; "
                                                id="custoprojeto">
                                        </div>
                                    </td>
                                    <th *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 200px; font-size: 13px; background-color: #F0F0F0;" scope="row">Parcelas carência:
                                    </th>
                                    <td *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 100px; background-color: #F0F0F0;">
                                        <input [(ngModel)]="parcelacarencias" style="font-size: 13px;background-color: transparent; border-radius: 0;outline: none;border-color: transparent;width: 150px; " type="number">
                                    </td>
                                </tr>
                                <tr>
                                    <th style="font-family: 'Oxygen', sans-serif;width: 250px; font-size: 13px;background-color: #F0F0F0;" scope="row"><b>Custo Total:</b></th>
                                    <td>
                                        <div class="col-xs-2">
                                            <input readonly [(ngModel)]="custototal" style="background-color: transparent; border-radius: 0;outline: none;border-color: transparent;width: 150px;font-weight: bold;font-size: 13px " currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                id="custotal">
                                        </div>
                                    </td>
                                    <th *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 200px; font-size: 13px; background-color: #F0F0F0;" scope="row">Parcelas carência:
                                    </th>
                                    <td *ngIf="finam" style="font-family: 'Oxygen', sans-serif;width: 100px; background-color: #F0F0F0;">
                                        <input [(ngModel)]="parcelacarencias" style="text-align: right; border-color: transparent;width: 100px; font-size: 13px;background-color: transparent;  height:20px;" class="inputa" type="number">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                    <div class="col-7">
                        <div *ngIf="showgraph" class="shadow-2 surface-card p-1" style="border-radius: 6px; background-color: rgb(255, 255, 255);">
                            <div>
                                <ejs-accumulationchart height=365 [title]="title" id='container' style='display:block; width: 100%' [legendSettings]="legendSettings" [tooltip]="tooltip"  (load)='onLoad($event)'>
 
                                    <e-accumulation-series-collection>
                                        <e-accumulation-series name='Custo' [dataSource]='datacust' xName='x' yName='y' [startAngle]="startAngle" [endAngle]="endAngle"  innerRadius="40%" radius="90%" [dataLabel]="dataLabel"> </e-accumulation-series>
                                    </e-accumulation-series-collection>
                                </ejs-accumulationchart>
                            </div>
                        </div>
                </div>
            </div>
            <div class="enquiry-row">
                <div  class="shadow-2 surface-card p-3" style="background-color: rgb(24, 133, 201);border-radius: 0px">
                    <div class="flex align-items-center justify-content-between">
                        <div>
                            <span class="text-xl text-white-900 font-bold">R$ {{custototal-direncia}}</span>
                            <p style="color: white !important" class="mt-2 mb-0 text-white-900 text-l">Diferença entre Orçado e real</p>
                        </div>
                        <div>
                            <img src="assets/contabilidade.png" />
                        </div>
                    </div>
                </div>
            </div>
            <ejs-grid    [allowExcelExport]="true" (excelQueryCellInfo)="exportQueryCellInfo($event)"  (rowSelecting)="selectingEvent2($event)"
            (cellSelecting)="selectingEvent2($event)"  (toolbarClick)="toolbarClick2($event)" (actionComplete)='actionComplete($event)' [dataSource]='data2' allowPaging='true' [pageSettings]='pageSettings2' [editSettings]='editSettings2'  [toolbar]="toolbarx" >
                <e-columns>
                    <e-column field='descricao' headerText='Descrição' width='200' textAlign='Left' isPrimaryKey='false' ></e-column>
                    <e-column editType='dropdownedit' [edit]='editparams2' field='tipo' headerText='Categoria' width='90' textAlign='Left'></e-column>
                    <e-column field='valor' headerText='Valor' width='90' format='C2' textAlign='Right' editType='numericedit' ></e-column>
                </e-columns>

                <e-aggregates>
                    <e-aggregate>
                        <e-columns>
                            <e-column type="Sum" field="valor" format="C2">
                                <ng-template #footerTemplate let-data>Valor total: {{data.Sum}}</ng-template>
                            </e-column>
                        </e-columns>
                    </e-aggregate>
                </e-aggregates>
            </ejs-grid>

                    <button style="width: 100% !important;" (click)="upacliente();" nbButton>ATUALIZAR</button>

                </div>
                <div *ngIf="menu4 === true">
                    <p-divider align="left">
                        <span style="font-size: smaller; font-weight: bold;">Contratada</span>
                    </p-divider>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <ejs-textbox placeholder="Nome" cssClass="e-outline" [(ngModel)]='nome' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-2">
                            <ejs-textbox placeholder="CNPJ" cssClass="e-outline" [(ngModel)]='cpf' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-textbox placeholder="E-mail" cssClass="e-outline" [(ngModel)]='emailx' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-12">
                            <ejs-textbox placeholder="Endereço" cssClass="e-outline" [(ngModel)]='enderecempresa' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-2">
                            <ejs-textbox placeholder="Representante legal" cssClass="e-outline" [(ngModel)]='replegal' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-textbox placeholder="CPF" cssClass="e-outline" [(ngModel)]='cpflegal' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-textbox placeholder="RG" cssClass="e-outline" [(ngModel)]='rglegal' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>
                    <p-divider align="left">
                        <span style="font-size: smaller; font-weight: bold;">Contratante</span>
                    </p-divider>

                    <div class="form-row">
                        <div class="form-group col-6">
                            <ejs-textbox placeholder="Nome" cssClass="e-outline" [(ngModel)]='clientefiltrado.Cliente' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-2">
                            <ejs-textbox placeholder="CPF/CNPJ" cssClass="e-outline" [(ngModel)]='clientefiltrado.cpf' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-textbox placeholder="RG" cssClass="e-outline" [(ngModel)]='clientefiltrado.rg' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-12">
                            <ejs-textbox placeholder="Endereço" cssClass="e-outline" [(ngModel)]='enderecocontrato' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-2">
                            <ejs-textbox placeholder="Nacionalidade" cssClass="e-outline" [(ngModel)]='clientefiltrado.nac' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-textbox placeholder="Profissão" cssClass="e-outline" [(ngModel)]='clientefiltrado.prof' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>


                    <p-divider align="left">
                        <span style="font-size: smaller; font-weight: bold;">Dados do sistema</span>
                    </p-divider>

                    <div class="form-row">
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Média de consumo" cssClass="e-outline" [(ngModel)]='clientefiltrado.mediaconsumo' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Aumento manifestado" cssClass="e-outline" [(ngModel)]='clientefiltrado.aumentomani' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Redução manifestada" cssClass="e-outline" [(ngModel)]='clientefiltrado.redumani' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Sistema (kWp)" cssClass="e-outline" [(ngModel)]='clientefiltrado.kwp' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>
    
                    <div *ngFor="let item of inversores;let i =index;" class="form-row">
                        <div  class="form-group col-3">
                            <ejs-textbox (change)="changeinver()" placeholder="Marca Inversor" cssClass="e-outline" [(ngModel)]='item.marcai' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div  class="form-group col-3">
                            <ejs-textbox (change)="changeinver()" placeholder="Modelo" cssClass="e-outline" [(ngModel)]='item.modeloi' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox (change)="changeinver()" placeholder="Potência" cssClass="e-outline" [(ngModel)]='item.potenciai' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox (change)="changeinver()" placeholder="Quantidade" cssClass="e-outline" [(ngModel)]='item.quantidadei' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>
             
                    <div *ngFor="let item of modulos;let i =index;" class="form-row">
                        <div  class="form-group col-3">
                            <ejs-textbox placeholder="Marca Módulos" cssClass="e-outline" [(ngModel)]='modulos[i].marcam' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Modelo" cssClass="e-outline" [(ngModel)]='modulos[i].modelom' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Potência" cssClass="e-outline" [(ngModel)]='modulos[i].potenciam' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Quantidade" cssClass="e-outline" [(ngModel)]='modulos[i].quantidadem' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>

                    <p-divider align="left">
                        <span style="font-size: smaller; font-weight: bold;">Garantias</span>
                    </p-divider>


                    <div class="form-row">
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Garantia Inversor(anos):" cssClass="e-outline" [(ngModel)]='clientefiltrado.invergarantia' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Garantia dos Módulos(anos):" cssClass="e-outline" [(ngModel)]='clientefiltrado.modulogarantia' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Garantia legal(dias):" readonly cssClass="e-outline" [(ngModel)]='glegal' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Garantia extra(dias): " cssClass="e-outline" [(ngModel)]='clientefiltrado.garantiaextra' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>


                    <p-divider align="left">
                        <span style="font-size: smaller; font-weight: bold;">Pagamento</span>
                    </p-divider>
                    <p-divider align="right">
                        <div class="p-d-inline-flex p-ai-center">
                            <button (click)="adicionaprazo()" style="width: 20px !important; height: 20px;font-size: x-small;" pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded"></button>
                            <button (click)="removeprazo()" style="width: 20px !important; height: 20px;font-size: x-small;background-color: red;" pButton pRipple type="button" icon="pi pi-minus" class="p-button-rounded"></button>
                        </div>
                    </p-divider>
                    <div *ngFor="let item of prazos;let i =index;" class="form-row">
                        <div class="form-group col-6">
                            <ejs-textbox placeholder="Valor" cssClass="e-outline" [(ngModel)]='prazos[i].valorpag' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-6">
                            <ejs-textbox placeholder="Previsão de pagamento" cssClass="e-outline" [(ngModel)]='prazos[i].datapag' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>
                    <p-divider></p-divider>
                    <button style="width: 50% !important;" (click)="upaclientex();" nbButton>Gerar contrato</button>
                    <button style="width: 50% !important; background-color: #0a147c; color: white;" (click)="upacliente();" nbButton>Atualizar</button>

                </div>
                <div *ngIf="menu5 === true">
                    <div class="sample-container">
                        <ejs-filemanager [toolbarSettings]='toolbarSettings' [height]='"80vh"'  (beforeSend)="OnbeforeSend($event)"  (fileOpen)="fileOpen($event)"
                        [rootAliasName]="homolselec" (toolbarClick)="toolbarClicks($event)" id='filemanager' [ajaxSettings]='ajaxSettings'>
                        </ejs-filemanager>
                      </div>
                </div>
                <div *ngIf="menu6 === true">
                    <div class="form-row">
                        <div class="form-group col-3">
                            <ejs-numerictextbox format='###' min='0' max='500' step='1' [(ngModel)]='digeral'  placeholder="Disjuntor de Entrada" cssClass="e-outline"  floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-dropdownlist floatLabelType="Auto" cssClass="e-outline" id='entradaareasub' #sample  [(ngModel)]='entradaareasub' [dataSource]='ramaistipos'  [fields]='fields'
                                placeholder='Ramal' [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                        <div class="form-group col-3">
                            <ejs-dropdownlist [(ngModel)]='padraoentrada'  floatLabelType="Auto" cssClass="e-outline" id='padraoDeEntrada' #sample [dataSource]='faseamentotipos'  [fields]='fields' placeholder='Faseamento'
                                [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                        <div class="form-group col-3">
                            <ejs-dropdownlist [(ngModel)]='classetensao' floatLabelType="Auto" cssClass="e-outline" id='classetensao' #sample [dataSource]='fases'  [fields]='fields' placeholder='Classe de Tensão' [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-3">
                            <ejs-numerictextbox format='###' min='0' max='500' step='1' [(ngModel)]='aumentocarga'  placeholder="Aumento de carga" cssClass="e-outline"  floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-dropdownlist [(ngModel)]='tipoaumentocarga'  floatLabelType="Auto" cssClass="e-outline" id='tipoaumentocarga' #sample [dataSource]='faseamentotipos'  [fields]='fields' placeholder='Faseamento'
                                [popupHeight]='height'></ejs-dropdownlist>
                        </div>

                        <div class="form-group col-3">
                            <ejs-numerictextbox format='###.##' min='0' max='500' step='0.1' [(ngModel)]='distanciaplacaquadro'  placeholder="Dist. placas ao inversor" cssClass="e-outline"  floatLabelType="Auto"></ejs-numerictextbox>
                        </div>

                        <div class="form-group col-3">
                            <ejs-numerictextbox format='###.##' min='0' max='500' step='0.1' [(ngModel)]='distanciainversorquadro'  placeholder="Dist. inversor ao quadro" cssClass="e-outline" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>

                    </div>



                    <div class="form-row">
                        <div class="form-group col-3">
                            <ejs-dropdownlist  [(ngModel)]='localinst'  floatLabelType="Auto" cssClass="e-outline" id='inputlocal' #sample  [dataSource]='locais'  [fields]='fields' placeholder='Local de Instalação'
                                [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                        <div class="form-group col-3">
                            <ejs-dropdownlist [(ngModel)]='tipotelhado'   floatLabelType="Auto" cssClass="e-outline" id='inputtipo' #sample [dataSource]='tipos'  [fields]='fields' placeholder='Tipo de Instalação' [popupHeight]='height'></ejs-dropdownlist>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox [(ngModel)]='latitude'   id='inputlatitude' placeholder="Latitude" cssClass="e-outline"  floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox [(ngModel)]='longitude'  placeholder="Longitude" cssClass="e-outline" (change)="longitude = $event.value" floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>

                    <p-divider align="right">
                        <div class="p-d-inline-flex p-ai-center">
                            <button (click)="adicionainversor()" style="width: 20px !important; height: 20px;font-size: x-small;" pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded"></button>
                            <button (click)="removeinversor()" style="width: 20px !important; height: 20px;font-size: x-small;background-color: red;" pButton pRipple type="button" icon="pi pi-minus" class="p-button-rounded"></button>

                        </div>
                    </p-divider>
                    <div *ngFor="let item of inversores;let i =index;" class="form-row">
                        <div  class="form-group col-3">
                            <ejs-textbox placeholder="Marca Inversor" cssClass="e-outline" [(ngModel)]='inversores[i].marcai' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Modelo Inversor" cssClass="e-outline" [(ngModel)]='inversores[i].modeloi' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Potência" cssClass="e-outline" [(ngModel)]='inversores[i].potenciai' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Quantidade" cssClass="e-outline" [(ngModel)]='inversores[i].quantidadei' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>
                    <p-divider align="right">
                        <div class="p-d-inline-flex p-ai-center">
                            <button (click)="adicionamodulo()" style="width: 20px !important; height: 20px;font-size: x-small;" pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded"></button>
                            <button (click)="removemodulo()" style="width: 20px !important; height: 20px;font-size: x-small;background-color: red;" pButton pRipple type="button" icon="pi pi-minus" class="p-button-rounded"></button>
                        </div>
                    </p-divider>
                    <div *ngFor="let item of modulos;let i =index;" class="form-row">
                        <div  class="form-group col-3">
                            <ejs-textbox placeholder="Marca Módulos" cssClass="e-outline" [(ngModel)]='modulos[i].marcam' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Modelo" cssClass="e-outline" [(ngModel)]='modulos[i].modelom' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Potência" cssClass="e-outline" [(ngModel)]='modulos[i].potenciam' floatLabelType="Auto"></ejs-textbox>
                        </div>
                        <div class="form-group col-3">
                            <ejs-textbox placeholder="Quantidade" cssClass="e-outline" [(ngModel)]='modulos[i].quantidadem' floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>

                    <div class="form-row" style="width: 100%;">
                        <div style="width: 100%;" class="form-group col-12">
                            <ejs-textbox rows="4"  [(ngModel)]="descricaodokit" [multiline]="true" id="configuração" placeholder="Descrição e observações da homologação" cssClass="e-outline" floatLabelType="Auto"></ejs-textbox>
                        </div>
                    </div>

                    <button style="width: 100% !important;" (click)="solicitahomologa();" nbButton>Solicitar</button>

                </div>

            </nb-card-body>

        </nb-card>
    </div>
</nb-card>
<!-- MODAL -->
<p-dialog [modal]="true" [style]="{ width: '40vw' }" [(visible)]="dialogx">
    <ng-template pTemplate="header">
        <b>Atualizar processo </b>
    </ng-template>
    <div *ngIf="dad1 === true ">
        <div class="left">
            <div class="form-group row">
                <div style="margin-left: -10px;" class="col-md-12">
                    <label>Observação</label>
                    <select (change)="novaobs= $event.target.value;" placeholder="Selecione uma opçáo" style="font-size: smaller;" id="ressp" class="form-control">
                <option style="font-size: small">Selecione...</option>
                <option style="font-size: small">Aguardando informações do cliente</option>
                <option style="font-size: small">Aguardando retorno do fornecedor</option>
                <option style="font-size: small">Revisando Proposta </option>
                <option style="font-size: small">Editando proposta</option>
                <option style="font-size: small">Em visita de campo</option>
              </select>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="dad2 === true ">
        <div class="left">
            <div class="form-group row">
                <div style="margin-left: -10px;" class="col-md-12">
                    <label>Observação</label>
                    <select (change)="novaobs= $event.target.value;" placeholder="Selecione uma opçáo" style="font-size: smaller;" id="ressp" class="form-control">
              <option style="font-size: small">Selecione...</option>
              <option style="font-size: small">Enviada para o cliente</option>
              <option style="font-size: small">Aguardando confirmação de recebimento</option>
              <option style="font-size: small">Buscando outras alternativas </option>
              <option style="font-size: small">Sem contato com cliente</option>
            </select>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="dad3 === true ">
        <div class="left">
            <div class="form-group row">
                <div style="margin-left: -10px;" class="col-md-12">
                    <label>Observação</label>
                    <select (change)="novaobs= $event.target.value;" placeholder="Selecione uma opçáo" style="font-size: smaller;" id="ressp" class="form-control">
            <option style="font-size: small">Selecione...</option>
            <option style="font-size: small">Em contato com cliente</option>
            <option style="font-size: small">Visita presencial </option>
            <option style="font-size: small">Apresentando contra proposta</option>
            <option style="font-size: small">Sem retorno do cliente</option>
          </select>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="dad4 === true ">
        <div class="left">
            <div class="form-group row">
                <div style="margin-left: -10px;" class="col-md-12">
                    <label>Observação</label>
                    <select (change)="novaobs= $event.target.value;" placeholder="Selecione uma opçáo" style="font-size: smaller;" id="ressp" class="form-control">
          <option style="font-size: small">Selecione...</option>
          <option style="font-size: small">Aprovada</option>
          <option style="font-size: small">Rejeitada </option>
          <option style="font-size: small">Outra oportunidade</option>
        </select>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <button (click)="atualizacliente();dialogx=false" nbButton>Atualizar</button>
        <button (click)="dialogx=false" nbButton>Cancelar</button>
    </p-footer>
</p-dialog>


<p-dialog [modal]="true" [style]="{ width: '60vw' }" [(visible)]="display">
    <ng-template pTemplate="header">
        <b>Adicionar Projeto </b>
    </ng-template>
    <p-divider></p-divider>
    <div class="form-row">
        <div class="form-group col-6">
            <ejs-textbox placeholder="Nome" cssClass="e-outline" [(ngModel)]='nomecliente' floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-6">
            <ejs-textbox placeholder="CPF/CNPJ" cssClass="e-outline" [(ngModel)]='cpfcliente' floatLabelType="Auto"></ejs-textbox>
        </div>

    </div>


    <div class="form-row">
        <div class="form-group col-6">
            <ejs-textbox placeholder="E-mail" cssClass="e-outline" [(ngModel)]='email' floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-2">
            <ejs-textbox placeholder="Telefone" cssClass="e-outline" [(ngModel)]='telefone' floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-4">
            <ejs-dropdownlist [(ngModel)]="responsavel" floatLabelType="Auto" cssClass="e-outline" id='ress' #sample  [dataSource]='empregados'  [fields]='fields'
            placeholder='Responsável' ></ejs-dropdownlist>
       </div>

    </div>

    <div class="form-row">
        <div class="form-group col-6">
            <ejs-textbox placeholder="Endereço" cssClass="e-outline" [(ngModel)]='enderecocliente' floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-2">
            <ejs-textbox placeholder="Cidade" cssClass="e-outline" [(ngModel)]='cidadecliente' floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-2">
            <ejs-textbox placeholder="UF" cssClass="e-outline" [(ngModel)]='estadocliente' floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-2">
            <ejs-textbox placeholder="CEP" cssClass="e-outline" [(ngModel)]='cepcadastrar' floatLabelType="Auto"></ejs-textbox>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-6">
            <ejs-textbox placeholder="Valor" cssClass="e-outline" [(ngModel)]='Valornovo' floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-2">
            <ejs-textbox placeholder="Kwp" cssClass="e-outline" [(ngModel)]='Kwpx' floatLabelType="Auto"></ejs-textbox>
        </div>
        <div class="form-group col-4">
            <ejs-textbox readonly placeholder="Código" cssClass="e-outline" [(ngModel)]='Codcli' floatLabelType="Auto"></ejs-textbox>
        </div>

    </div>


    <p-footer>
        <button (click)="novocliente()" nbButton>Cadastrar</button>
        <button (click)="display = false" nbButton>Cancelar</button>
    </p-footer>
</p-dialog>

<ng-template #dialog6 let-data let-ref="dialogRef">
    <nb-card style="width: 500px">
        <nb-card-header>Mudar Status</nb-card-header>
        <nb-card-body>
            <form>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <mat-form-field style="width: 100%">
                            <input (change)="
                  todastarefas[idstatustar].tarefa = $event.target.value
                " [value]="todastarefas[idstatustar].tarefa" type="text" matInput placeholder="Descrição da tarefa" />
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <form>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <mat-form-field style="width: 120px; margin-right: 20px">
                            <input (dateChange)="
                  todastarefas[idstatustar].datainicio =
                    $event.target.value['_i']['date'] +
                    '/' +
                    $event.target.value['_i']['month'] +
                    '/' +
                    $event.target.value['_i']['year']
                " matInput [matDatepicker]="picker30" placeholder="Data inicio" />
                            <mat-datepicker-toggle matSuffix [for]="picker30"></mat-datepicker-toggle>
                            <mat-datepicker #picker30></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-4">
                        <mat-form-field style="width: 200px">
                            <input (dateChange)="
                  todastarefas[idstatustar].datafim =
                    $event.target.value['_i']['date'] +
                    '/' +
                    $event.target.value['_i']['month'] +
                    '/' +
                    $event.target.value['_i']['year']
                " matInput [matDatepicker]="picker20" placeholder="Previsão de
                Término" />
                            <mat-datepicker-toggle matSuffix [for]="picker20"></mat-datepicker-toggle>
                            <mat-datepicker #picker20></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-row">
                    <div style="width: 600px" class="form-group">
                        <label for="statusprops">Setor:</label>
                        <select (change)="todastarefas[idstatustar].tipo = $event.target.value" id="statusprops1" class="form-control">
              <option selected>Selecione...</option>
              <option *ngFor="let stat of categoriatipo" [value]="stat">
                {{ stat }}
              </option>
            </select>
                    </div>
                </div>

                <div class="form-row">
                    <label for="statusprops">Responsável:</label>
                    <input class="form-control" (change)="
              todastarefas[idstatustar].responsavel = $event.target.value
            " [value]="todastarefas[idstatustar].responsavel" type="text" placeholder="Responsável" />
                </div>
                <div style="margin-top: 10px" class="form-row">
                    <label for="statusprops">Status da tarefa</label>
                    <select (change)="todastarefas[idstatustar].status = $event.target.value" id="statusprops1" class="form-control">
            <option selected>Selecione...</option>
            <option *ngFor="let stat of droptarefa" [value]="stat.value">
              {{ stat.viewValue }}
            </option>
          </select>
                </div>
            </form>
        </nb-card-body>
        <nb-card-footer>
            <div style="margin-top: -20px" class="modal-footer">
                <button nbButton (click)="ref.close()">Cancelar</button>
                <button style="margin-right: 30px; background-color: #0a147c; color: white" nbButton (click)="ref.close(); upatarefa()">
          Atualizar
        </button>
            </div>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>Status atual</nb-card-header>
        <nb-card-body>
            <div style="width: 600px" class="form-group">
                <label for="statusprops">Status da proposta:</label>
                <select (change)="
            clientefiltrado.status_proposta = $event.target.value;
            styleproj = $event.target.value
          " id="statusprops" class="form-control">
          <option selected>Selecione...</option>
          <option
            *ngFor="let status1 of statusproposta"
            [value]="status1.value"
          >
            {{ status1.viewValue }}
          </option>
        </select>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button style="margin-right: 30px; background-color: #0a147c; color: white" nbButton (click)="updatestatusproj(); ref.close()">
        Atualizar
      </button>
            <button nbButton (click)="ref.close()">Fechar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialog2 let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>Status atual</nb-card-header>
        <nb-card-body>
            <div style="width: 600px" class="form-group">
                <label for="statushom">Status da homologação:</label>
                <select (change)="
            clientefiltrado.status_homologacao = $event.target.value;
            styleproj2 = $event.target.value
          " id="statushom" class="form-control">
          <option selected>Selecione...</option>
          <option
            *ngFor="let satus2 of statushomologacao"
            [value]="satus2.value"
          >
            {{ satus2.viewValue }}
          </option>
        </select>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button style="margin-right: 30px; background-color: #0a147c; color: white" nbButton (click)="updatestatusproj(); ref.close()">
        Atualizar
      </button>
            <button nbButton (click)="ref.close()">Fechar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialog3 let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>Status atual</nb-card-header>
        <nb-card-body>
            <div style="width: 600px" class="form-group">
                <label for="statusprops">Status da aquisição:</label>
                <select (change)="
            clientefiltrado.status_compra = $event.target.value;
            styleproj3 = $event.target.value
          " id="statusprops" class="form-control">
          <option selected>Selecione...</option>
          <option *ngFor="let satus3 of statuscompra" [value]="satus3.value">
            {{ satus3.viewValue }}
          </option>
        </select>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button style="margin-right: 30px; background-color: #0a147c; color: white" nbButton (click)="updatestatusproj(); ref.close()">
        Atualizar
      </button>
            <button nbButton (click)="ref.close()">Fechar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialog4 let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>Status atual</nb-card-header>
        <nb-card-body>
            <div style="width: 600px" class="form-group">
                <label for="statusprops">Status da execução:</label>
                <select (change)="
            clientefiltrado.status_execucao = $event.target.value;
            styleproj4 = $event.target.value
          " id="statusprops" class="form-control">
          <option selected>Selecione...</option>
          <option *ngFor="let satus4 of statusexecucao" [value]="satus4.value">
            {{ satus4.viewValue }}
          </option>
        </select>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button style="margin-right: 30px; background-color: #0a147c; color: white" nbButton (click)="updatestatusproj(); ref.close()">
        Atualizar
      </button>
            <button nbButton (click)="ref.close()">Fechar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialog5 let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>Status atual</nb-card-header>
        <nb-card-body>
            <div style="width: 600px" class="form-group">
                <label for="statusprops">Status da Vistoria:</label>
                <select (change)="
            clientefiltrado.status_vistoria = $event.target.value;
            styleproj5 = $event.target.value
          " id="statusprops" class="form-control">
          <option selected>Selecione...</option>
          <option *ngFor="let satus4 of statusvistoria" [value]="satus4.value">
            {{ satus4.viewValue }}
          </option>
        </select>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button style="margin-right: 30px; background-color: #0a147c; color: white" nbButton (click)="updatestatusproj(); ref.close()">
        Atualizar
      </button>
            <button nbButton (click)="ref.close()">Fechar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>
<p-dialog header="Operação não permitida" [modal]="true" [style]="{ width: '50vw' }" [(visible)]="naopermitido">
    <div style="align-items: center;">    <h4 style="font-size: 16px;"><b>Atenção:</b> Só administradores podem excluir projetos</h4>
    </div>
</p-dialog>
<p-dialog header="Enviar para homologação" [modal]="true" [style]="{ width: '50vw' }" [(visible)]="enviahomol">
    <div style="align-items: center;">    <h4 style="font-size: 16px;"><b>Atenção:</b> Só administradores podem excluir projetos</h4>
    </div>
</p-dialog>


<p-dialog header="Editar" [modal]="true" [style]="{ width: '50vw' }" [(visible)]="editaorca">
<div style="margin-top: 20px !important;" class="row">
    <div class="col-12">
        <ejs-textbox
          placeholder="Descrição"
          floatLabelType="Always"
          [(ngModel)]='filtrado.descricao'
          id="descricao"
        ></ejs-textbox>
    </div>

    <div class="col-12">
            <ejs-dropdownlist           
            [(ngModel)]='filtrado.tipo'
             id="tipo"  [dataSource]='parametros' [fields]="{text: 'countryName', value: 'countryName' }" placeholder="Categoria" popupHeight='300px' floatLabelType='Always'></ejs-dropdownlist>
    </div>
    <div class="col-12">
            <ejs-numerictextbox   
            [(ngModel)]='filtrado.valor' id="valor" placeholder="Valor" floatLabelType='Always'></ejs-numerictextbox>
    </div>
</div> 
<p-footer>
    <button (click)="edita();editaorca=false" nbButton>Atualizar</button>
    <button (click)="editaorca=false" nbButton>Cancelar</button>
</p-footer>
</p-dialog>


<p-dialog header="Adicionar" [modal]="true" [style]="{ width: '50vw' }" [(visible)]="adiciona">
    <div style="margin-top: 20px !important;" class="row">
        <div class="col-12">
            <ejs-textbox
              placeholder="Descrição"
              floatLabelType="Always"
              [(ngModel)]='filtrado.descricao'
              id="descricao"
            ></ejs-textbox>
        </div>
    
        <div class="col-12">
                <ejs-dropdownlist           
                [(ngModel)]='filtrado.tipo'
                 id="tipo"  [dataSource]='parametros' [fields]="{text: 'countryName', value: 'countryName' }" placeholder="Categoria" popupHeight='300px' floatLabelType='Always'></ejs-dropdownlist>
        </div>
        <div class="col-12">
                <ejs-numerictextbox   
                [(ngModel)]='filtrado.valor' id="valor" placeholder="Valor" floatLabelType='Always'></ejs-numerictextbox>
        </div>
    </div> 
    <p-footer>
        <button (click)="adicionax();adiciona=false" nbButton>Adicionar</button>
        <button (click)="adiciona=false" nbButton>Cancelar</button>
    </p-footer>
    </p-dialog>
