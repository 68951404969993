<div  >
   <nav style="background-color: #FCFCFC;" class="navbar navbar-light justify-content-between">
        <a class="navbar-brand"></a>
        <div class="form-inline">
            <input  (input) = "codhomolocacao= $event.target.value"  class="form-control mr-sm-2" placeholder="Insira uma homologação" aria-label="Search">
            <button (click) = "click();" class=" btn btn-dark my-2 my-sm-0" >Buscar</button>
        </div>
      </nav>

  <div class= "espaco"></div>

<div >
  <div class="form-row">
  <h6 class="form-group col-md-6" >Dados do Parceiro:</h6>
  <h6 class="form-group col-md-3" >Nº Homologação:</h6>
  <h6 class="form-group col-md-3" >{{codhomolocacao}}</h6>
  </div>
    <div class= "espaco"></div>
<div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputnomeparceiro">Nome:</label>
        <input [(value)] = "dadosolicitante['nomeparceiro1']" type="text" class="form-control" id="inputnomeparceiro" >
      </div>
      <div class="form-group col-md-6">
        <label for="inputcnpjparceiro">CPF/CNPJ:</label>
        <input [(value)] = "dadosolicitante['cnpjparceiro1']"
        type="text" class="form-control" id="inputcnpjparceiro">
      </div>
    </div>


    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inpuruaparceiro">Rua:</label>
        <input [(value)] = "dadosolicitante['ruaparceiro']" type="text" class="form-control" id="inpuruaparceiro">
      </div>
      <div class="form-group col-md-4">
        <label for="inputbairroparceiro">Bairro:</label>
        <input [(value)] = "dadosolicitante['bairroparceiro']"  type="text" class="form-control" id="inputbairroparceiro">
      </div>
      <div class="form-group col-md-2">
        <label for="inputnumeroparceiro">Número:</label>
        <input [(value)] = "dadosolicitante['numruaparceiro']"   type="text" class="form-control" id="inputnumeroparceiro">
      </div>
    </div>



    <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputcidadeparceiro">Cidade:</label>
          <input  [(value)] = "dadosolicitante['cidadeparceiro']" type="text" class="form-control" id="inputcidadeparceiro">
        </div>
        <div class="form-group col-md-4">
          <label for="inputestadoparceiro">Estado:</label>
          <input [(value)] = "dadosolicitante['ufparceiro']"  type="text" class="form-control" id="inputestadoparceiro">
        </div>
        <div class="form-group col-md-2">
          <label for="inputCEPparceiro">CEP:</label>
          <input [(value)] = "dadosolicitante['cepparceiro']"  type="text" class="form-control" id="inputCEPparceiro">
        </div>

        <div class="form-group col-md-6">
            <label for="inputemailparceiro">Email:</label>
            <input [(value)] = "dadosolicitante['emailparceiro']" type="email" class="form-control" id="inputemailparceiro">
          </div>

          <div class="form-group col-md-6">
              <label for="inputtelefoneparceiro">Telefone:</label>
              <input [(value)] = "dadosolicitante['celparceiro']" type="text" class="form-control" id="inputtelefoneparceiro">
            </div>
      </div>

<div >
    <h6>Dados do cliente:</h6>
    <div class= "espaco"></div>
<div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputnomecliente">Nome:</label>
        <input  (input) = "nome= $event.target.value" [(value)] = "dadoshomol['nome'] " type="text" class="form-control" id="inputnomecliente" >
      </div>
      <div class="form-group col-md-6">
        <label for="inputPassword4">CPF/CNPJ:</label>
        <input (input) = "cpfCnpj= $event.target.value" [(value)] = "dadoshomol['cpfCnpj']" type="text" class="form-control" id="inputCPF">
      </div>
    </div>


    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputCity">Rua:</label>
        <input (input) = "ruacliente= $event.target.value" [(value)] = "dadoshomol['ruacliente']" type="text" class="form-control" id="inputrua">
      </div>
      <div class="form-group col-md-3">
        <label for="inputState">Bairro:</label>
        <input [(value)] = "dadoshomol['bairro']"
        (input) = "bairro = $event.target.value" type="text" class="form-control" id="inputbairro">
      </div>

      <div class="form-group col-md-2">
        <label for="inputState">Complemento:</label>
        <input [(value)] = "dadoshomol['complemento']"
        (input) = "complemento = $event.target.value" type="text" class="form-control" id="complemento">
      </div>


      <div class="form-group col-md-1">
        <label for="inputZip">Número:</label>
        <input [(value)] = "dadoshomol['numerocliente']"
        (input) = "numerocliente = $event.target.value" type="text" class="form-control" id="inputnumero">
      </div>
    </div>



    <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputCidade">Cidade:</label>
          <input (input) = "municipiocliente= $event.target.value" [(value)] = "dadoshomol['municipiocliente']"  type="text" class="form-control" id="inputCidade">
        </div>
        <div class="form-group col-md-4">
          <label for="inputEstado">Estado:</label>
          <input [(value)] = "dadoshomol['estado']" (input) = "estado = $event.target.value" type="text" class="form-control" id="inputEstado">
        </div>
        <div class="form-group col-md-2">
          <label for="inputCEP">CEP:</label>
          <input (input) = "cep = $event.target.value" [(value)] = "dadoshomol['cep']"  type="text" class="form-control" id="inputCEP">
        </div>
      </div>



  <div >
      <h6>Dados da Instalação:</h6>
      <div class= "espaco"></div>

      <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputnuminstalacao">Número da Instalação:</label>
            <input (input) = "numeroinstalacao = $event.target.value" [(value)] = "dadoshomol['numeroinstalacao']"  type="text" class="form-control" id="inputnuminstalacao">
          </div>
          <div class="form-group col-md-4">
            <label for="inputnumcliente">Número do cliente:</label>
            <input (input) = "numclientecemig = $event.target.value" [(value)] = "dadoshomol['numclientecemig']"  type="text" class="form-control" id="inputnumcliente">
          </div>
          <div class="form-group col-md-2">
            <label for="inputClasse">Classe:</label>
            <input (input) = "ramoDeAtividade = $event.target.value" [(value)] = "dadoshomol['ramoDeAtividade']"  type="text" class="form-control" id="inputClasse">
          </div>
        </div>
    </div>
    <div class="form-row">

    <div class="form-group col-md-3">
        <label for="inputtipoentrada">Ramal</label>
        <select (change) = "entradaAreaSub = $event.target.value" [(value)] = "dadoshomol['entradaAreaSub']" id="inputtipoentrada" class="form-control">
          <option >Selecione...</option>
          <option>Aéreo</option>
          <option>Subterrâneo</option>
        </select>
      </div>

      <div class="form-group col-md-3">
          <label for="inputtipoentrada">Tipo</label>
          <select (change) = "padraoDeEntrada = $event.target.value" [(value)] = "dadoshomol['padraoDeEntrada']"  id="inputtipoentrada" class="form-control">
            <option >Selecione...</option>
            <option>Monofásico</option>
            <option>Bifásico</option>
            <option>Trifásico</option>
          </select>
        </div>

        <div class="form-group col-md-2">
            <label for="inputtensaoentrada">Tensão</label>
            <select (change) = "classetensao = $event.target.value" [(value)] = "dadoshomol['classetensao']"  id="inputtensaoentrada" class="form-control">
              <option >Selecione...</option>
              <option>127V</option>
              <option>127/220V</option>
              <option>220V/380V</option>
            </select>
          </div>

            <div class="form-group col-md-2">
                <label for="disjuntorentrada">Disjuntor</label>
                <div class="input-group mb-3">
                  <input (input) = "disjuntorentrada = $event.target.value" [(value)] = "dadoshomol['disjuntorentrada']"  type="number" class="form-control" id="inoutdisjuntor">
                  <div class="input-group-append">
                      <span class="input-group-text">A</span>
                    </div>
                </div>
              </div>


              <div class="form-group col-md-2">
                <label for="inputcargainstalada">Carga Instalada</label>
                <div class="input-group mb-3">
                  <input (input) = "cargainstalada = $event.target.value" [(value)] = "dadoshomol['cargainstalada']"  type="number" class="form-control" id="inputcargainstalada">
                  <div class="input-group-append">
                      <span class="input-group-text">kW</span>
                    </div>
                </div>
              </div>
      </div>

      <div class="form-row">
          <div class="form-group col-md-3">
            <label for="inputlatitude">Latitude:</label>
            <input (input) = "latitude = $event.target.value" [(value)] = "dadoshomol['latitude']"   type="text" class="form-control" id="inputlatitude">
          </div>
          <div class="form-group col-md-3">
            <label for="inputlogintude">Longitude:</label>
            <input (input) = "longitude = $event.target.value" [(value)] = "dadoshomol['longitude']"  type="text" class="form-control" id="inputlogintude">
          </div>

          <div class="form-group col-md-2">
              <label for="inputaumentocarga">Aumento de carga:</label>
              <div class="input-group mb-3">
                <input (input) = "disjuntoraumentocarga = $event.target.value" [(value)] = "dadoshomol['disjuntoraumentocarga']" type="text" class="form-control" id="inputaumentocarga">
                <div class="input-group-append">
                    <span class="input-group-text">A</span>
                  </div>
              </div>
            </div>

            <div class="form-group col-md-3">
                <label for="inputtipoaumento">Tipo</label>
                <select (change) = "tpdaumentoDeCarga = $event.target.value" [(value)] = "dadoshomol['tpdaumentoDeCarga']" id="inputtipoaumento" class="form-control">
                    <option >Selecione...</option>
                    <option>Monofásico</option>
                    <option>Bifásico</option>
                    <option>Trifásico</option>
                </select>
              </div>
      </div>

      <div>
        <div class="form-row">
    <div class="form-group col-md-3">
        <label for="distinv">Distância Placas ao inversor:</label>
              <div class="input-group mb-3">
              <input (input) = "distanciaPlacaInversor = $event.target.value" [(value)] = "dadoshomol['distanciaPlacaInversor']"  type="text" class="form-control" id="distinv">
              <div class="input-group-append">
              <span class="input-group-text">m²</span>
              </div>
              </div>
    </div>


  <div class="form-group col-md-3">
                <label for="distanciaInversorQuadro">Distância inversor ao quadro:</label>
              <div class="input-group mb-3">
                <input (input) = "distanciaInversorQuadro = $event.target.value" [(value)] = "dadoshomol['distanciaInversorQuadro']"  type="text" class="form-control" id="inputClasse">
              <div class="input-group-append">
              <span class="input-group-text">m²</span>
              </div>
              </div>
    </div>
    <div class="form-group col-md-3">
      <label for="coordenadasGeograficas">Coordenadas:</label>
    <div class="input-group mb-3">
      <input [(value)] = "dadoshomol['coordenadasGeograficas']"  type="text" class="form-control" id="coordenadas">
    </div>
</div>
    </div>
          <div class="form-group">
               <label for="deskit">Descrição do Kit</label>
              <textarea (input) = "descricaodokit = $event.target.value"  [(value)] = "dadoshomol['descricaodokit']" class="form-control" id="kitdec" rows="3"></textarea>
            </div>
          </div>

      <div>
          <h6>Dados da Geração:</h6>
          <div class= "espaco"></div>
          <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inputqtdmodulos">Quantidade de Módulos</label>
                <input (input) = "qtdplacas = $event.target.value" [(value)] = "dadoshomol['qtdplacas']" type="number" class="form-control" id="inputqtdmodulos">
              </div>
              <div class="form-group col-md-3">
                <label for="inputmarcamodulos">Marca dos módulos</label>
                <input (input) = "marcaplacas = $event.target.value" [(value)] = "dadoshomol['marcaplacas']" type="text" class="form-control" id="inputmarcamodulos">
              </div>
              <div class="form-group col-md-3">
                  <label for="inputModelo">Modelo dos módulos</label>
                  <input (input) = "modeloPlaca = $event.target.value"  [(value)] = "dadoshomol['modeloPlaca']" type="text" class="form-control" id="inputModelo">
                </div>

                <div class="form-group col-md-2">
              <label for="inputpotetotal">Potência Total :</label>
              <div class="input-group mb-3">
                <input (input) = "kwpplacas = $event.target.value"  [(value)] = "dadoshomol['kwpplacas']"  type="text" class="form-control" id="inputpotetotal">
                <div class="input-group-append">
                    <span class="input-group-text">kWp</span>
                  </div>
              </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="inputqtdinv">Quantidade de Inversor</label>
                  <input (input) = "qtdinversor = $event.target.value"  [(value)] = "dadoshomol['qtdinversor']" type="number" class="form-control" id="inputqtdinv">
                </div>
                <div class="form-group col-md-3">
                  <label for="inputmarcainv">Marca do Inversor</label>
                  <input (input) = "marcaInversor = $event.target.value"  [(value)] = "dadoshomol['marcaInversor']" type="text" class="form-control" id="inputmarcainv">
                </div>
                <div class="form-group col-md-3">
                    <label for="inputModeloinv">Modelo do Inversor</label>
                    <input (input) = "modeloinversor = $event.target.value"  [(value)] = "dadoshomol['modeloinversor']" type="text" class="form-control" id="inputModeloinv">
                  </div>
                  <div class="form-group col-md-2">
                      <label for="inputpotetotalinv">Potência Total Inversor :</label>
                      <div class="input-group mb-3">
                        <input (input) = "potenciainversor = $event.target.value"  [(value)] = "dadoshomol['potenciainversor']" type="text" class="form-control" id="inputpotetotalinv">
                        <div class="input-group-append">
                            <span class="input-group-text">kW</span>
                          </div>
                      </div>
                    </div>
                  <div class="form-group col-md-3">
                      <label for="disjuntorgeracao">Disjuntor :</label>
                      <div class="input-group mb-1">
                        <input (input) = "disjuntorgeracao = $event.target.value"  [(value)] = "dadoshomol['disjuntorgeracao']" type="number" class="form-control" id="disjuntorgeracao">
                        <div class="input-group-append">
                            <span class="input-group-text">A</span>
                          </div>
                      </div>
                    </div>
                <div class="form-group col-md-3">
                    <label for="inputdisjuntogeracao">Tipo </label>
                    <select (change) = "tipodisjgeracao = $event.target.value" [(value)] = "dadoshomol['tipodisjgeracao']" id="inputdisjuntogeracao" class="form-control">
                        <option >Selecione...</option>
                        <option>Monofásico</option>
                        <option>Bifasico</option>
                        <option>Trifásico</option>
                    </select>
                  </div>

                  <div class="form-group col-md-3">
                      <label for="inputpotetotal">Área dos Módulos:</label>
                      <div class="input-group mb-3">
                        <input (input) = "Areadasplacas = $event.target.value"  [(value)] = "dadoshomol['Areadasplacas']" type="text" class="form-control" id="areaplacas">
                        <div class="input-group-append">
                            <span class="input-group-text">m²</span>
                          </div>
                      </div>
                    </div>
              </div>
      </div>
      <div>
          <h6>Status:</h6>
            <div class= "espaco"></div>
            <div class="form-row">
             <div class="form-group col-md-3">
              <label for="statuscoletadados">Coleta de Dados:</label>
                  <select (change) = "statusColetaDados = $event.target.value" [(value)] = "dadoshomol['statusColetaDados']"  id="statuscoletadados" class="form-control">
                      <option>Dados incompletos</option>
                      <option>Aguardando Assinaturas</option>
                      <option>100</option>
                  </select>
              </div>
             <div class="form-group col-md-3">
              <label for="statusprojeto">Projeto:</label>
                  <select  (change) = "statusProjeto = $event.target.value" [(value)] = "dadoshomol['statusProjeto']" id="statusprojeto" class="form-control">
                          <option>Pendente</option>
                          <option>Dados insuficientes</option>
                          <option>Elaborando</option>
                          <option>100</option>
                  </select>
          </div>
              <div class="form-group col-md-3">
                 <label for="statusSolicitacao">Solicitação de Acesso:</label>
                      <select (change) = "statusHomologacao = $event.target.value" [(value)] = "dadoshomol['statusHomologacao']" id="statusSolicitacao" class="form-control">
                          <option>Pendente</option>
                          <option>Enviado para Concessionária</option>
                          <option>Aguardando Parecer</option>
                          <option>100</option>
                      </select>
              </div>
              <div class="form-group col-md-3">
                  <label for="statusvistoria">Solicitação de Vistoria:</label>
                      <select (change) = "statusVistoria = $event.target.value" [(value)] = "dadoshomol['statusVistoria']" id="statusvistoria" class="form-control">
                      <option>Pendente</option>
                      <option>Aguardando Solicitação</option>
                      <option>Solicitado</option>
                      <option>100</option>
                      </select>
              </div>
              </div>
      </div>

       <div>
          <div class="form-row">
              <div class="form-group col-md-3">
              <label for="primeiraparcela">Pagamento 1ª Parcela</label>
              <div class="input-group mb-3">
              <div class="input-group-prepend">
              <span class="input-group-text">R$</span>
              </div>
              <input (input) = "primeiraparcela = $event.target.value"  [(value)] = "dadoshomol['pagamentoentrada']" type="text" class="form-control" id="inputqtdinv">
              </div>
              </div>

          <div class="form-group col-md-3">
              <label for="segundaparcela">Pagamento 2ª Parcela</label>
              <div class="input-group mb-3">
              <div class="input-group-prepend">
              <span class="input-group-text">R$</span>
              </div>
              <input (input) = "segundaparcela = $event.target.value"  [(value)] = "dadoshomol['pagamentosegundaparte']" type="text" class="form-control" id="segundaparcela">
              </div>
              </div>

              <div class="form-group col-md-3">
              <label for="responsavel">Responsável pela homologação</label>
              <input (input) = "responsavel = $event.target.value"  [(value)] = " dadoshomol['responsavel']" type="text" class="form-control" id="responsavel">
              </div>  </div>
              <div class="form-group">
              <label for="aviso">Mensagem de aviso para clientes</label>
              <textarea (input) = "aviso = $event.target.value"  [(value)] = "dadoshomol['aviso']" class="form-control" id="aviso" rows="3"></textarea>
              </div>
          </div>

          <div>
            <div class="form-row">
                <div class="form-group col-md-10">
                <button  class="btn btn-dark">Atualizar</button>
                </div>
            <div class="form-group col-md-2">
                 <button (click) = "mostralinks()"   data-toggle="modal" data-target="#exampleModal" class="btn btn-dark">Preencher Formulario</button>
                </div>
            </div>
          </div>
      <div  *ngIf="resourcesLoaded !== 'false'  " class="overlay">
        <div class="espaco2">
            <mat-spinner></mat-spinner>
            <br>
            &nbsp; &nbsp;  <font size="4" color="white"><strong>Aguarde</strong></font>
          </div>

      </div>
    </div>
  </div>


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Selecione um modelo de formulário</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <select (change)="idmuda($event.target.value)"  class="form-control form-control-sm">
              <option  style="color: black;" *ngFor="let obj of conc; let i = index" [value]="i">{{obj}}</option>
            </select>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button (click)="closemodal()"type="button" class="btn btn-primary">Selecionar</button>
      </div>
    </div>
  </div>
</div>

