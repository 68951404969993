import * as moment from "moment-business-days";
import { MYTAREFA } from "./../homologacoes/mytarefas";
import { MYCLIENTES } from "./../homologacoes/myclientes";
import { todocaixa } from "./../homologacoes/caixa";
import { HomologacoesserviceService } from "./../homologacoes/homologacoesservice.service";
import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { CardSettingsModel, ColumnsModel, DialogSettingsModel, KanbanComponent, SwimlaneSettingsModel } from '@syncfusion/ej2-angular-kanban';
import * as CryptoJS from 'crypto-js';
import { Query } from '@syncfusion/ej2-data';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { FormControl } from "@angular/forms";
import * as $AB from "jquery";
import * as $ from "jquery";
import { formatDate } from "@angular/common";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { todosdados } from "../homologacoes/todosdados";
import "src/assets/js/bootstrap-datepicker.js";
import { InPlaceEditorComponent, RenderMode, EditableType } from '@syncfusion/ej2-angular-inplace-editor';
import { NumericTextBoxModel, TextBoxModel } from '@syncfusion/ej2-inputs';
import {
  NbDialogService,
  NbToastrService,
  NbComponentStatus,
} from "@nebular/theme";
import { Dropbox } from "dropbox";
import { FileUploader } from "ng2-file-upload";

import {
  DialogEditEventArgs,
  EditSettingsModel,
  ExcelQueryCellInfoEventArgs,
  GridComponent,
  IEditCell,
  PdfQueryCellInfoEventArgs,
  RowSelectEventArgs,
} from "@syncfusion/ej2-angular-grids";
import { MaskedTextBoxModel } from "@syncfusion/ej2-inputs";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { DropDownList, DropDownListComponent } from "@syncfusion/ej2-angular-dropdowns";
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { extend } from "node_modules/@syncfusion/ej2-base";
import { PopupSettingsModel } from "node_modules/@syncfusion/ej2-inplace-editor/src/inplace-editor/base/models-model";
import { DbxAuth } from "../configs";
import { Router } from "@angular/router";
import { FilesService } from "../files.service";
import { EditService, ToolbarService, PageService } from '@syncfusion/ej2-angular-grids';
import { AccumulationDataLabelSettingsModel, AccumulationTheme, IAccLoadedEventArgs, LegendSettingsModel, TooltipSettingsModel } from "@syncfusion/ej2-angular-charts";

export interface CARTEIRA {
  usoproposta: string;
  usoprojeto: string;
  creditoprojeto: string;
  creditoproposta: string;
}

export interface Myhomolg {
  codhomol: string;
  Cliente: string;
  Status: number;
  local: string;
  descricao: string;
  categoria: string;
  Menu: number;
}

export interface DialogData {
  animal: string;
  name: string;
}

/**
 * @title Table with selection
 */

@Component({
  selector: "app-clientecm",
  templateUrl: "./clientecm.component.html",
  styleUrls: ["./clientecm.component.css"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ClientecmComponent implements OnInit {
  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = [
    "codcliente",
    "Cliente",
    "local",
    "responsavel",
    "Menu",
  ];
  columnsToDisplay = ["codcliente", "Cliente", "local", "responsavel", "Menu"];
  expandedElement: Myhomolg | null;
  // variavelglobal
  codigoatual: string;
  itemsx: MenuItem[];
  itemsy: MenuItem[];

  public data2: Object[];
  public datacust: Object[] = [];
  showgraph = false
  direncia = 0
  public editSettings2: Object;
  public toolbar2: string[];
  public orderidrules2: Object;
  public customeridrules2: Object;
  public freightrules2: Object;
  public pageSettings2: Object;
  public editparams2: Object;

  andamentoproj: number;
  sytileand: string;
  styleproj = "none";
  styleproj2 = "none";
  styleproj3 = "none";
  styleproj4 = "none";
  styleproj5 = "none";
  tarefaatraso: number;
  tarefaexec: number;
  tarefaspend: number;
  tarefascon: number;
  idstatustar: any;
  materialca = 0;
  lucro = 0;
  custokwp: any;
  // variaveiscliente
  potenciai: string
  potenciaix: string
  potnominal = 0;
  potpico = 0;
  potenciam: string
  potenciamx: string
  dadosolicitante: any;
  solicitante: string;
  nomecliente: string;
  temporestante: any;
  temponecessario: any;
  telefone: string;
  email: string;
  datafimprojeto: string;
  datainicioprojeto: string;
  fromDate: any;
  toDate: any;
  cidadecliente: string;
  estadocliente: string;
  enderecocliente: string;
  enderecempresa = '';
  cotacao: string;
  categor: string;
  responsavel: string;
  datainicio: any;
  cpfcliente: string;
  validacao1: {};
  validacao2: {};
  validacao3: {};
  validacao4: {};
  validacao5: {};
  validacao6: {};
  validacao7: {};
  cepcadastrar = '';
  nac = 'Brasileiro'
  replegal = '';
  cpflegal = '';
  rglegal = '';
  novaobs = '';
  public listObj: DropDownListComponent;
  public cardSettings: CardSettingsModel = {
    headerField: "Title",
    template: "#cardTemplate",
    selectionType: "Multiple",
  };
  public dialogSettings: DialogSettingsModel = {
    fields: [
      { text: "Tarefa", key: "Title", type: "TextBox" },
      { text: "Status", key: "Status", type: "DropDown" },
      { text: "Responsável", key: "Assignee", type: "DropDown" },
      { text: "Classifição", key: "RankId", type: "TextBox" },
      { text: "descricao", key: "Summary", type: "TextArea" },
    ],
  };
  public swimlaneSettings: SwimlaneSettingsModel = { keyField: "Id" };
  public settings: PopupSettingsModel = {
    title: 'Digite...'
  };

  myDate: any;
  public allowDragAndDrop: Boolean = true;
  public allowDragAndDrop2: Boolean = false;

  kanbanview = false;
  kanbanData: Object[];
  cols: any[];
  dad1 = false;
  dad2 = false;
  dad3 = false;
  dad4 = false;
  dad5 = false;
  meucargo ='';
  exportColumns: any[];
  @ViewChild("kanbanObj") kanbanObj: KanbanComponent;
  public columns: ColumnsModel[] = [
    { headerText: "Aguardando", keyField: "Coleta de dados", allowToggle: true },
    { headerText: "Recebido", keyField: "Projeto", allowToggle: true },
    { headerText: "Follow Up", keyField: "Parecer", allowToggle: true },
    { headerText: "Negociando", keyField: "Vistoria", allowToggle: true }
  ];
  // options
  enviado = false;
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  // variaveis update
  filtrocodcliente: string;
  // variaveistarefa
  tarefa: string = null;
  dataininiciotarefa: string;
  datafimtarefa: string;
  previsaotarefa: string;
  tipotarefa = "Selecione...";
  statustarefa = "Selecione...";
  responsaveltarefa: string = null;
  // variaveis de controle
  single: any[];
  p1 = 0;
  p2 = 0;
  p3 = 0;
  p4 = 0;
  Negadas = 0;
  todas = 0;
  Aprovadas = 0;
  acompanhando = 0;
  Em_Negociacao = 0;
  Aguardando_Proposta = 0;
  Valornovo = 0;
  Kwpx = 0;
  Clicod = "CL";
  Codcli = "";
  datanagora = "";
  abrecliente = "false";
  minhacarteira: CARTEIRA[];
  creditoprojeto: string;
  usoprojeto: string;
  selection = new SelectionModel<todocaixa>(true, []);
  clientefiltrado: any;
  inversores: any[];
  inversores2: any[];
  modulos: any[];
  modulos2: any[];
  prazos: any[];
  todosclientes: MYCLIENTES[];
  todastarefas: MYTAREFA[];
  nopDate: any;
  public key: string = null;
  id: string;
  dataxs= [{vakir:''}];
  closeResult: string;
  finalizacao = [];
  pago: string;
  name: string;
  categoria = [];
  cor: string;
  corred = "red";
  corblue = "blue";
  base64image = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyBAMAAADsEZWCAAAAG1BMVEVEeef///+4zPaKq/ChvPPn7' +
    'vxymu3Q3flbieqI1HvuAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQUlEQVQ4jWNgGAWjgP6ASdncAEaiAhaGiACmFhCJLsMaIiDAEQEi0WXYEiMC' +
    'OCJAJIY9KuYGTC0gknpuHwXDGwAA5fsIZw0iYWYAAAAASUVORK5CYII=';
  trasacselect: string;
  data: string;
  numerohologacaoes = 0;
  valor: number;
  mes: number;
  favorecido: string;
  descricao: string;
  okpago: string;
  dataSource = new MatTableDataSource<MYCLIENTES>([]);
  esperadados = 0;
  esperaprojeto = 0;
  esperacesso = 0;
  esperavistoria = 0;
  iden: number;
  ideatuliza = [];
  menu1 = true;
  menu2 = false;
  menu3 = false;
  menu4 = false;
  menu5 = false;
  menu6 = false;

  dialogx = false;
  public initialPage: Object;
  ultprogup: number;

  @ViewChild("overviewgrid")
  public gridInstance: GridComponent;
  public maskedTextBoxModel: MaskedTextBoxModel = {
    mask: "00/00/0000",
  };
  progress = 0;
  mostrabuton = false;
  tarefas: any;
  public dReady: boolean = false;
  public dtTime: boolean = false;
  public isDataBound: boolean = false;
  public isDataChanged: boolean = true;
  public intervalFun: any;
  delcod = ''
  public clrIntervalFun: any;
  public clrIntervalFun1: any;
  public clrIntervalFun2: any;
  public dropSlectedIndex: number = null;
  public stTime: any;
  gasto = 0;
  abrecalendar=false
  parcelafinanciamento = 0;
  periodofinan = 0;
  parcelasfinan = 0;
  taxafinan = 0;
  valorfinan = 0;
  encargos = 0;
  custosadicionais = 0;
  desconto = 0;
  depreciacao = 0.8;
  parcelacarencias = 0;
  tarenergiaforaponta = 0;
  finam = false;
  percentfinan = 0;
  public hostUrl: string = "https://www.talktunnels.com/api/_functions/FileManager/";
  public ajaxSettings: object;
  public toolbarSettings: object;
  public navigationPaneSettings: object;

  custopv = 0;
  custoinstal = 0;
  custoproj = 0;
  customo = 0;
  custototal = 0;
  tarenergia = 0;
  reajtar = 5;
  taxjuros = 0;
  taxinf = 0;
  periodopay = 0;
  payback = 0;
  valortir = 0;
  renderizou = false;
  disabled = false;
  showSearch = false;
  locas = "pt-BR";
  loading = false;
  nome = '';
  cpf = '';
  rua = '';
  glegal = '90';
  bairro = '';
  numero = '';
  cidade = '';
  estado = '';
  cep = '';
  funil = false;
  equipex: any;
  emailx = '';
  telefonex = '';
  validacao = [];
  senha1 = '1';
  senha2 = '2';
  complemento = '';
  valid = {};
  sp = true;
  enderecocontrato = '';
  public pageSettings: Object;
  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;
  public toolbar: string[];
  public toolbarx: string[];
  
  public isInitial: Boolean = true;
  @ViewChild('inplace_editor')
  public inplaceObj: InPlaceEditorComponent;
  public empregados: Object[] = [
    { Id: '', tipo: ''}
  ];
  validaupload = "false";
  fileToUpload: File = null;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  tokenx:any;
  codig : any;
  adbx: any;
  acesstok:any;
  dbcAutho = [];
  autorizado = 'true';
  response: string;
  public dbxAuth: DbxAuth;
  dbx = new Dropbox({ accessToken: '' });


  public fields: Object = { text: 'tipo', value: 'Id' };

  public overviewModel: TextBoxModel = {
    placeholder: 'Digite um valor'
  };
  statusproposta = [
    {
      value: "Elaborando proposta comercial",
      viewValue: "Elaborando proposta comercial",
    },
    {
      value: "Aguardando parecer do cliente",
      viewValue: "Aguardando parecer do cliente",
    },
    { value: "Proposta em revisão", viewValue: "Proposta em revisão" },
    {
      value: "Proposta recusada pelo cliente",
      viewValue: "Proposta recusada pelo cliente",
    },
    {
      value: "Proposta aprovada pelo cliente",
      viewValue: "Proposta aprovada pelo cliente",
    },
  ];

  statushomologacao = [
    {
      value: "Aguardando parecer do cliente",
      viewValue: "Aguardando parecer do cliente",
    },
    {
      value: "Enviado para  engenharia",
      viewValue: "Enviado para  engenharia",
    },
    { value: "Aguardando homologação", viewValue: "Aguardando  homologação" },
    {
      value: "Solicitado revisão do projeto",
      viewValue: "Solicitado revisão de projeto",
    },
    { value: "Sistema  Homologado", viewValue: "Sistema  Homologado" },
  ];

  statuscompra = [
    {
      value: "Aguardando parecer do cliente",
      viewValue: "Aguardando parecer do cliente",
    },
    { value: "Aguardando Pagamento", viewValue: "Aguardando Pagamento" },
    {
      value: "Aguardando  financiamento",
      viewValue: "Aguardando financiamento",
    },
    {
      value: "Aguardando entrega de materiais",
      viewValue: "Aguardando entrega de materiais",
    },
    {
      value: "Material Recebido ou em estoque",
      viewValue: "Material Recebido ou em estoque",
    },
  ];

  statusexecucao = [
    {
      value: "Aguardando parecer do cliente",
      viewValue: "Aguardando parecer do cliente",
    },
    {
      value: "Aguardando chegada de Material",
      viewValue: "Aguardando chegada de Material",
    },
    {
      value: "Aguardando equipe para execuação",
      viewValue: "Aguardando equipe para execuação",
    },
    { value: "Aguardando Homologação", viewValue: "Aguardando Homologação" },
    {
      value: "Executando construção do sistema",
      viewValue: "Executando construção do sistema",
    },
    {
      value: "Execução do sistema concluída",
      viewValue: "Execução do sistema concluída",
    },
  ];

  statusvistoria = [
    {
      value: "Aguardando parecer do cliente",
      viewValue: "Aguardando parecer do cliente",
    },
    {
      value: "Aguardando Execução do sistema",
      viewValue: "Aguardando Execução do sistema",
    },
    { value: "Vistoria Solicitada", viewValue: "Vistoria Solicitada" },
    {
      value: "Vistoria reprovada, em correção",
      viewValue: "Vistoria reprovada, em correção",
    },
    {
      value: "Executando Correções do projeto",
      viewValue: "Executando Correção do projeto",
    },
    {
      value: "Solicitada nova Vistoria ",
      viewValue: "Solicitada nova Vistoria ",
    },
    { value: "Vistoria aprovada", viewValue: "Vistoria aprovada" },
  ];

  droptarefa = [
    { value: "Pendente", viewValue: "Pendente" },
    { value: "Atrasada", viewValue: "Atrasada" },
    { value: "Em execução", viewValue: "Em execução" },
    { value: "Concluída", viewValue: "Concluída" },
  ];

  categoriatipo = [
    "Comercial",
    "Homologações",
    "Compras",
    "Execução",
    "Administrativo",
    "Marketing",
    "Jurídico",
    "Suporte Técnico",
    "Tecnologia da informação",
  ];

  date = new FormControl(new Date());
  serializedDate = new FormControl(new Date().toISOString());
  display = false;
  timeline = 0;
  public editOptions: EditSettingsModel;
  homolselec = ''
  ref = "/aplicativos/homofold/";
  reload = true;
  path = "";
  pathori = "";
  caminhoupar = '';
  naopermitido= false;
  enviahomol = false;
  public tiposParams : IEditCell;  
  editmanager = false
  codselec =""
public fields2: Object = { text: 'countryName', countryId: 'countryName' };
public parametros: Object = [
  { countryName: 'Lucro', countryId: '1' },
  { countryName: 'Sistema Fotovoltaico', countryId: '1' },
  { countryName: 'Material CA', countryId: '1' },
  { countryName: 'Material CC', countryId: '2' },
  { countryName: 'Mão de Obra', countryId: '3' },
  { countryName: 'Segurança do trabalho', countryId: '4' },
  { countryName: 'Logística', countryId: '5' },
  { countryName: 'Estrutura', countryId: '6' },
  { countryName: 'Deslocamento', countryId: 'Deslocamento' },
  { countryName: 'Aluguel de equipamento', countryId: '8' },
  { countryName: 'Aluguel de andaime', countryId: '9' },
  { countryName: 'Ferramenta', countryId: '10' },
  { countryName: 'Estadia', countryId: '11' },
  { countryName: 'Alimentação', countryId: '12' },
  { countryName: 'Hora extra', countryId: '13' },
  { countryName: 'Comissão', countryId: '14' },
  { countryName: 'Manutenção', countryId: '15' },
  { countryName: 'Impostos', countryId: '16' },
  { countryName: 'Taxas', countryId: '17' },
  { countryName: 'Projeto', countryId: '18' },
  { countryName: 'Subestação', countryId: '19' },
  { countryName: 'Serviços de acabamento', countryId: '20' },
  { countryName: 'Alvenaria', countryId: '21' },
  { countryName: 'Construção', countryId: '22' },
  { countryName: 'Transformador', countryId: '23' },
  { countryName: 'Outros', countryId: '24' },
];
public legendSettings: Object = {
  visible: false,
  position: 'Top'
};

  public dataLabel: AccumulationDataLabelSettingsModel = {
      visible: true, position: 'Outside',
      connectorStyle: { length: '20px', type: 'Curve' }, name: 'text',
  };
  public startAngle: number = 270;
  //Initializing Tooltip 
  public endAngle: number = 90;

  public tooltip: TooltipSettingsModel = {
      enable: true, format: '${point.x} : <b>${point.y}%</b>'
  };
public title: string = 'Distribuição de Custos - Exceto valor do kit ';
public stateElem : HTMLElement;
public stateObj : DropDownList;
editaorca = false
adiciona=false
filtrado =  {
  descricao:"", 
valor:0,
projetid: "", 
dono: "", 
tipo:"",
id:""
}
public countryElem : HTMLElement;
public countryObj : DropDownList;
  digeral ='';
  distanciaplacaquadro='';
  distanciainversorquadro='';
  padraoentrada='';
  entradaareasub='';
  longitude='';
  latitude='';
  classetensao='';
  localinst='';
  tipotelhado='';
  descricaodokit='';
  aumentocarga='';
  tipoaumentocarga='';
  
  public ramaistipos: Object[] = [
    { Id: '', tipo: '' },
    { Id: 'Aéreo', tipo: 'Aéreo' },
    { Id: 'Subterrâneo', tipo: 'Subterrâneo' }
  ];
  public Consx: Object = { text: 'value', value: 'viewValue' };
  public height: string = '220px';
  public locais: Object = [
    { Id: 'Telhado', tipo: 'Telhado' },
    { Id: 'Laje', tipo: 'Laje' },
    { Id: 'Solo', tipo: 'Solo' },
    { Id: 'Grama seca', tipo: 'Grama seca' }
  ];
  public tipos: Object  = [
    { Id: 'Telhado Cerâmico', tipo: 'Telhado Cerâmico' },
    { Id: 'Telha Fibrocimento', tipo: 'Telha Fibrocimento' },
    { Id: 'Telhado Metálico', tipo: 'Telhado Metálico' },
    { Id: 'Telhado Plástico', tipo: 'Telhado Plástico' },
    { Id: 'Laje', tipo: 'Laje' },
    { Id: 'Grama', tipo: 'Grama' },
    { Id: 'Asfalto', tipo: 'Asfalto' },
    { Id: 'Concreto', tipo: 'Concreto' },
    { Id: 'Cascalho', tipo: 'Cascalho' },
    { Id: 'Solo', tipo: 'Solo' }
  ];
  public faseamentotipos: Object[] = [
    { Id: 'Monofásico', tipo: 'Monofásico' },
    { Id: 'Bifásico', tipo: 'Bifásico' },
    { Id: 'Trifásico', tipo: 'Trifásico' }
  ];

  public fases: Object[] = [
    { Id: '127V', tipo: '127V' },
    { Id: '220V', tipo: '220V' },
    { Id: '127V/220V', tipo: '127V/220V' },
    { Id: '220V/380V', tipo: '220V/380V' },
    { Id: '13.8kV', tipo: '13.8kV' }
  ];
  constructor(
    private filesService: FilesService,
    private router: Router,
    private service: HomologacoesserviceService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {  this.uploader = new FileUploader({
    url: "",
    disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunctionIsAsync: true,
    formatDataFunction: async (item) => {
      return new Promise((resolve, reject) => {
        resolve({
          name: item._file.name,
          length: item._file.size,
          contentType: item._file.type,
          date: new Date(),
        });
      });
    },
  });
  this.hasBaseDropZoneOver = false;
  this.hasAnotherDropZoneOver = false;
  this.response = "";
  this.uploader.response.subscribe((res) => (this.response = res));}

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  getMyStyles() {
    const MyStyles = {
      "background-color":
        this.styleproj === "Proposta aprovada pelo cliente"
          ? "#0A1591"
          : "#83859B",
    };
    return MyStyles;
  }
  getMyStyles2() {
    const MyStyles2 = {
      "background-color":
        this.styleproj2 === "Sistema  Homologado" ? "#0A1591" : "#83859B",
    };
    return MyStyles2;
  }
  getMyStyles3() {
    const MyStyles3 = {
      "background-color":
        this.styleproj3 === "Material Recebido ou em estoque"
          ? "#0A1591"
          : "#83859B",
    };
    return MyStyles3;
  }
  getMyStyles4() {
    const MyStyles4 = {
      "background-color":
        this.styleproj4 === "Execução do sistema concluída"
          ? "#0A1591"
          : "#83859B",
    };
    return MyStyles4;
  }
  getMyStyles5() {
    const MyStyles5 = {
      "background-color":
        this.styleproj5 === "Vistoria aprovada" ? "#0A1591" : "#83859B",
    };
    return MyStyles5;
  }
  modalshow() {
    this.display = true;
  }
  abrecalendario() {
    this.abrecalendar = true;
  }
autorizacao() {
    this.tokenx =  localStorage.getItem('tokenx');
    this.service.getdbx({ token: this.tokenx })
    .subscribe((data) => {
      //console.log(data)
      this.codig = data
      this.decryptData(this.codig.cod)}
      )
}

decryptData(data:any) {
  try {
    const bytes = CryptoJS.AES.decrypt(data, this.tokenx);
    if (bytes.toString()) {
      var dadox = (JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
      //console.log(dadox)
      this.adbx = dadox;
      this.autoh();
    }
    return data;
  } catch (e) {
    //console.log(e);
  }
}


autoh(){
  this.acesstok =  new Dropbox({ accessToken: this.adbx[0].accessToken });
      // Begin authentication process if credentials not found
      //console.log(this.adbx[0].accessToken )
    if (this.autorizado === 'true') {
          const authUrl = this.router.url;
          const parameters = authUrl.split('#')[1] || '';
          if (this.autorizado === 'true') {
              const arrParams = parameters.split('&') || [];
              if (this.autorizado === 'true') {
                  const authObj: DbxAuth = { isAuth: false };
                  // tslint:disable-next-line: prefer-for-of
                  authObj.accessToken = this.adbx[0].accessToken;
                  authObj.tokenType = this.adbx[0].tokenType;
                  authObj.uid = this.adbx[0].uid;
                  authObj.accountId = this.adbx[0].accountId;
                  if (authObj.accessToken && authObj.tokenType && authObj.uid && authObj.accountId) {
                      authObj.isAuth = true;
                      this.dbxAuth = authObj;
                  }
                }
          }
          //console.log('esse eh dbxauto',this.dbxAuth)
          this.service.changedauth(this.dbxAuth)
      }}

      edita(){
        this.service 
        .upaorcados({
          token: localStorage.getItem("tokenx"),
          descricao:this.filtrado.descricao, 
          valor:this.filtrado.valor,
          projetid: this.filtrado.projetid, 
          dono: this.filtrado.dono, 
          tipo:this.filtrado.tipo,
          id:this.filtrado.id
        })
        .subscribe((dados) => {
          if (dados) {
            this.getorcados()

            //console.log(dados);
            if (dados["status"] === "cadastrado") {
             // console.log("inserido");
            }
            if (dados["status"] === "erro") {
             // console.log(" não inserido");
            }
          }
        });
      }


      actionComplete(args): void {
        //console.log(args)
        if(args.requestType=== "delete"){
        }
        if(args.action=== "edit"){
        }
        if(args.action=== "add"){
        }
    }

    adicionax(){
      this.service
      .postorcado({
        token: localStorage.getItem("tokenx"),
        id: (((Date.now()) / 1000).toFixed(0)).toString(), 
        descricao:this.filtrado.descricao, 
        valor:this.filtrado.valor,
        projetid: this.clientefiltrado.codcliente, 
        dono: this.id, 
        tipo:this.filtrado.tipo,
      })
      .subscribe((dados) => {
        if (dados) {
          this.getorcados()

          //console.log(dados);
          if (dados["status"] === "cadastrado") {
            //console.log("inserido");
          }
          if (dados["status"] === "erro") {
            //console.log(" não inserido");
          }
        }
      });
    }
    selectingEvent2(e: any): void {
  //console.log(e.data)
  this.filtrado = {      
    descricao:e.data.descricao, 
    valor:e.data.valor,
    projetid: e.data.projetid, 
    dono: e.data.dono, 
    tipo:e.data.tipo,
    id:e.data.id}
    //console.log(e.data)
    }
    toolbarClick2(args: ClickEventArgs): void {
      //console.log(args)
      switch (args.item.text) {
        case "Adicionar":
          this.adiciona = true
          break;
        case "Editar":
          this.editaorca = true
          break;
        case "Deletar":
          this.service
          .delorca({
            token: localStorage.getItem("tokenx"),
            descricao:this.filtrado.descricao, 
            valor:this.filtrado.valor,
            projetid: this.filtrado.projetid, 
            dono: this.filtrado.dono, 
            tipo:this.filtrado.tipo,
            id:this.filtrado.id
          })
          .subscribe((dados) => {
            if (dados) {
              this.getorcados()
              //console.log(dados);
              if (dados["status"] === "cadastrado") {
                //console.log("inserido");
              }
              if (dados["status"] === "erro") {
                //console.log(" não inserido");
              }
            }
          });
          break;
      }
    }
    

  navtoproposta(a){
    this.codselec = a;
    //console.log(a)
    this.router.navigate(['/geraproposta'],{queryParams:{data:a,ativa:true}})
  }  

  public onLoad(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.accumulation.theme = <AccumulationTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
}

  ngOnInit() {
    
    this.data2 = [];
    this.editSettings2 = { allowEditing: true, allowAdding: true, allowDeleting: true };
    this.toolbar2 = ['Add', 'Edit', 'Delete'];
    this.orderidrules2 = { required: true, text: true };
    this.customeridrules2 = { required: true };
    this.freightrules2 =  { required: true };
    this.editparams2 = { params: { popupHeight: '300px' } };
    this.pageSettings2 = { pageCount: 5};

    this.service.currentcargo.subscribe((dados) => {
      if (dados) {
        this.meucargo = dados;
      }})
      
    this.initialPage = { pageSizes: true, pageCount: 4,pageSize: 5 };
    this.itemsx = [
      {
        label: 'Voltar',
        icon: 'pi pi-arrow-left',
        command: (event) => {
          this.clientepnael()
        }
      },
      {
        label: 'Dados Cliente',
        icon: 'pi  pi-pencil',
        command: (event) => {
          this.menumuda(1)

        }
      },
      {
        label: 'Status',
        icon: 'pi pi-check-square',
        command: (event) => {
          this.menumuda(2)
        }
      }
      ,
      {
        label: 'Financeiro',
        icon: 'pi pi-chart-line',
        command: (event) => {
          this.menumuda(3)
        }
      },

      {
        label: 'Contrato',
        icon: 'pi  pi-dollar',
        command: (event) => {
          this.menumuda(4)

        }
      }
      ,

      {
        label: 'Arquivos',
        icon: "pi pi-folder",
        command: (event) => {
          this.criasfold()

        }
      }
      ,

     {
        label: 'Enviar para Homologação',
        icon: "pi pi-sign-in",
        command: (event) => {
          this.menumuda(6)
        }
      }
      
    ];
    this.itemsy = [
      {
        label: 'Funil de vendas',
        icon: 'pi pi-filter',
        command: (event) => {
          this.puxafunil();
          this.abrecalendar = false
        }
      },
      {
        label: 'Painel de Clientes',
        icon: 'pi  pi-briefcase',
        command: (event) => {
          this.abrecliente = 'false';this.funil = false;this.abrecalendar = false

        }
      },
      {
        label: 'Calendário de eventos',
        icon: 'pi pi-calendar-times',
        command: (event) => {
          this.abrecalendario();this.abrecliente = 'false'; this.funil = false
        }
      }
      ,
      {
        label: 'Adicionar Cliente',
        icon: 'pi pi-user-plus',
        command: (event) => {
          this.display = true
        }
      }
    ];
    this.autorizacao()
    this.editOptions = { allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Normal' };
    this.toolbar = ["ExcelExport", 'Excluir', 'Ver detalhes'];
    this.toolbarx = ["Adicionar", 'Editar', 'Deletar'];

    this.andamentoproj = 0;
    this.datainicio = new Date(Date.now()).toLocaleString("pt-BR");
    this.datanagora = (Date.now() / 1000).toFixed(0).toString();
    this.Codcli = this.Clicod + this.datanagora;
    this.service.currentid.subscribe((dados) => {
      if (dados) {
        this.id = dados;
       this.puxaequipe()
        this.puxasolicitante()
        this.puxaclientes();
        this.puxacarteira();
        this.service.gsolicitante({ ID: this.id })
        .subscribe(dados => {
          if (dados !== this.dadosolicitante) {
            this.dadosolicitante = dados;
           // console.log('solicita é ')
            //console.log(this.dadosolicitante.nick.toUpperCase())
            //console.log(this.dadosolicitante.emailparceiro)
  
          }
        });
      }
    });
  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  async uploadalgo() {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      await this.asyncUpload(i);
    }
  }

  asyncUpload(i) {
    var caminhouparx = this.ref  + this.caminhoupar + "/"
    this.service.currentpasta.subscribe((dados) => {
      if (dados !== "false") {
        caminhouparx = dados
      }
    });
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line: max-line-length
    //console.log(this.acesstok)
      this.acesstok
        .filesUpload({
          path: caminhouparx +
            this.uploader.queue[i]["_file"].name,
          contents: this.uploader.queue[i]["_file"],
        })
        // tslint:disable-next-line: only-arrow-functions
        .then((response) => {
          this.enviado = true;
          this.uploader.queue[i]["isUploaded"] = true;
          this.uploader.queue[i]["isSuccess"] = true;
          resolve();
        })
        .catch((error) => reject())
        .finally(() => this.progresso());
    });
  }

  criapasta() {
    var caminhouparx = this.ref  + this.Codcli 
   // console.log(caminhouparx)
    this.service.currentpasta.subscribe((dados) => {
      if (dados !== "false") {
        caminhouparx = dados
      }
    });
    return new Promise((resolve, reject) => {
      this.acesstok
        .filesCreateFolderV2({
          'path': caminhouparx,
        })
        .then((response) => {
        //  console.log(response)
        })
        .catch((error) => {//console.log(error)
        })
    });
  }

  criasfold(){
    this.service
    .criafolder({
      path: '/',
      myid:this.id,
      name: this.delcod,
      data: [{name: this.delcod,
      size: '4.00 KB',
      isFile: false,
      dateModified: '2022-05-08T13:59:26.003Z',
      dateCreated: '2022-05-08T13:59:26.003Z',
      type: '',
      filterPath: '/',
      permission: null,
      hasChild: true,
      _fm_id: 'fe_tree'}
      ]})
    .subscribe((dados) => {
      //console.log()
      if (dados ) {
        this.dataxs = dados
        //console.log(this.dataxs)
        if(this.dataxs['vakir'] ==='400'){
          this.ajaxSettings = {
            path2: this.delcod,
            url: this.hostUrl + 'apm',
            showHiddenItems: true,
            getImageUrl: this.hostUrl + 'GetImage',
            uploadUrl: this.hostUrl + 'Upload',
            downloadUrl: this.hostUrl + 'Download'
        };
          this.menumuda(5)
       }
       if(this.dataxs['vakir'] ==='ok'){
        this.navigationPaneSettings = { maxWidth: '850px', minWidth: '140px', visible: false};
        this.ajaxSettings = {
          path2: this.delcod,
          url: this.hostUrl + 'apm',
          showHiddenItems: true,
          getImageUrl: this.hostUrl + 'GetImage',
          uploadUrl: this.hostUrl + 'Upload',
          downloadUrl: this.hostUrl + 'Download'
      };
        //this.criafoldex = false
        //this.menu3 = true
        this.menumuda(5)
       }
 
      }
    })
  }

  progresso() {
    this.ultprogup = 100 / this.uploader.queue.length;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (this.uploader.queue[i]["isSuccess"] === true) {
        this.uploader.progress = this.ultprogup * (i + 1);
      }
    }
    if (this.uploader.progress === 100) {
      this.validaupload = "true";
      this.uploader.clearQueue();
      this.filesService.getFiles(this.path);
    }
  }
  exportQueryCellInfo(
    args: ExcelQueryCellInfoEventArgs | PdfQueryCellInfoEventArgs
  ): void {
    if (args.column.headerText === "Employee Image") {
      if ((args as any).name === "excelQueryCellInfo") {
        args.image = {
          height: 75,
          base64: (args as any).data.EmployeeImage,
          width: 75,
        };
      } else {
        args.image = { base64: (args as any).data.EmployeeImage };
      }
    }
    if (args.column.headerText === "Email ID") {
      args.hyperLink = {
        target: "mailto:" + (args as any).data.EmailID,
        displayText: (args as any).data.EmailID,
      };
    }
  }

  public onRowSelected(args: RowSelectEventArgs): void {
    //console.log(args)
    const queryData: any = args.data;
    this.delcod = queryData.codcliente
    //console.log(queryData)
  }
  toolbarClick(args: ClickEventArgs): void {
   // console.log(args.item.text)
    switch (args.item.text) {
      case "PDF Export":
        this.gridInstance.pdfExport();
        break;
      case "Adicionar projeto":
        this.modalshow();
        break;
      case "Exportar Excel":
        this.gridInstance.excelExport();
        break;
      case "Ver detalhes":
        this.filtraclientes(this.delcod);
        this.homolselec = this.delcod
        this.caminhoupar = this.delcod
        this.path = this.ref + this.delcod;
        this.pathori = this.path;
        this.clientepnael();
        break;
      case "Excluir":
        if(this.meucargo==='Administrador')
        { this.delclientex()}
        if(this.meucargo!=='Administrador')
        { this.naopermitido = true}
       
        break;
        case "Enviar para Homologação":
        this.enviahomol = true; 
        break;
    }
  }


  onDataBound(args: any): void {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }

  showToasterror(status: NbComponentStatus) {
    this.toastrService.show(status, `Erro: Verifique todos os campos!`, {
      status,
    });
  }
  showToast1(status: NbComponentStatus) {
    this.toastrService.show(status, `Sucesso: Tarefa criada com sucesso!`, {
      status,
    });
  }
  showToast2(status: NbComponentStatus) {
    this.toastrService.show(status, `Sucesso: Status atualizado com sucesso!`, {
      status,
    });
  }
  showToast3(status: NbComponentStatus) {
    this.toastrService.show(
      status,
      `Sucesso: Projeto atualizado com sucesso!`,
      { status }
    );
  }

  atualizacustos() {
    this.custopv = parseFloat(this.clientefiltrado.custopv)
    this.materialca = parseFloat(this.clientefiltrado.custoca)
    this.custoproj = parseFloat(this.clientefiltrado.custoproj)
    this.customo = parseFloat(this.clientefiltrado.customo)
    this.custosadicionais = parseFloat(this.clientefiltrado.malucro)
    this.desconto = parseFloat(this.clientefiltrado.desconto)
    this.lucro = parseFloat(this.clientefiltrado.ganho)
    this.custototal = this.custopv + this.materialca + this.custoproj + this.customo + this.custosadicionais - this.desconto;
    this.lucro = ((this.materialca + this.custoproj + this.customo + this.custosadicionais - this.desconto) / this.custopv) * 100
    //console.log(this.custototal)
    this.custokwp = (this.custototal / (this.clientefiltrado.kwp)).toFixed(2)
  }

  ativafinan() {
    if (this.finam === false) {
      this.finam = true;
    } else {
      this.finam = false;
    }
  }
  menumuda(a) {
    //console.log(a)
    if (a === 1) {
      this.menu1 = true;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = false
      this.menu6 = false

    }
    
    if (a === 2) {
      this.menu1 = false;
      this.menu2 = true;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = false
      this.menu6 = false

    }
    if (a === 3) {
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = true;
      this.menu4 = false
      this.menu5 = false
      this.menu6 = false

    }
    if (a === 4) {
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = true
      this.menu5 = false
      this.menu6 = false

    }
    if (a === 5) {
      this.navigationPaneSettings = { maxWidth: '850px', minWidth: '140px', visible: true};
      this.ajaxSettings = {
        path2: this.homolselec,
        url: this.hostUrl + 'apm',
        showHiddenItems: true,
        getImageUrl: this.hostUrl + 'GetImage',
        uploadUrl: this.hostUrl + 'Upload',
        downloadUrl: this.hostUrl + 'Download'
    };
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = true
      this.menu6 = false

    }
    if (a === 6) {
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = false
      this.menu6 = true
    }
  }
  toolbarClicks(args) { 
    if (args.item.text == "Custom") { 
        // Get the selected Files details. 
        //var selectedFiles = fileManager.getSelectedFiles()[0]; 
        // Get the path of FileManager component. 
        //var path = selectedFiles.filterPath + selectedFiles.name;
        //console.log(path) 
    } 
} 
fileOpen(args) {
  //console.log(args)
  //You can get the double clicked file/folder details in this event argument. Check the arguments below.

  // cancel - If you want to cancel this event then, set cancel to true. Otherwise, false.
  // fileDetails - Return the currently selected item as JSON object.
  // module - Returns the name of the target module in file manager.

  //You can alse check whether the clicked item is a file or not using the fileDetails isFile property.
  if (!args.fileDetails.isFile) {
    //console.log('Double clicked item is a file');
    //args.cancel = true

  }
}
OnbeforeSend (args){
 // console.log(args)
  if(args.action==="Upload"){
    //this.enviouarquivo();
    //this.notificaarqu() 
    var data = JSON.parse(args.ajaxSettings.data);  
    // Add custom parameter userid  
     data.push({"path2":this.delcod}); 
     data.push({"myid":this.id}); 
    // Add custom parameter in ajax settings  
     args.ajaxSettings.data = JSON.stringify(data); 
  } 
  if(args.action==="delete"){
   //this.enviouarquivod();
   ///this.notificaarqud() 
  } 
  var data = JSON.parse(args.ajaxSettings.data);  
  // Add custom parameter userid  
   data["path2"] = this.delcod; 
   data["myid"] = this.id; 
   args.ajaxSettings.data = JSON.stringify(data); 

  } 
  clientepnael() {
    if (this.abrecliente === "true") {
      this.abrecliente = "false";
    } else {
      this.abrecliente = "true";
    }
  //  console.log(this.abrecliente);
  }

  puxaclientes() {
    this.dataSource.sort = this.sort;
    this.service.myclientes({ ID: this.id }).subscribe((data: MYCLIENTES[]) => {
      if (data) {
        this.todosclientes = data;
        this.renderizou = true;
        this.filterSettings = { type: "Menu" };
        this.filter = { type: "CheckBox" };
        this.stTime = performance.now();
        this.dataSource = new MatTableDataSource<MYCLIENTES>(
          this.todosclientes
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //console.log(this.todosclientes);
        this.calculos()
        this.loading = false
      }
    });
  }
  filtrax(a) {
    this.filtraclientes(a)
    this.funil = false;
    this.abrecliente = 'true'

  }
  puxafunil() {
    this.dataSource.sort = this.sort;
    this.service.myclientes({ ID: this.id }).subscribe((data: MYCLIENTES[]) => {
      if (data) {
        this.todosclientes = data;
        this.kanbanData = extend(
          [],
          this.todosclientes,
          null,
          true
        ) as Object[];
        this.funil = true;
      }
    });
  }
  puxaclientes2() {
    this.dataSource.sort = this.sort;
    this.service.myclientes({ ID: this.id }).subscribe((data: MYCLIENTES[]) => {
      if (data) {
        this.todosclientes = data;
        this.renderizou = true;
        this.filterSettings = { type: "Menu" };
        this.filter = { type: "CheckBox" };
        this.stTime = performance.now();
        this.dataSource = new MatTableDataSource<MYCLIENTES>(
          this.todosclientes
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //console.log(this.todosclientes);
        this.filtraclientes(this.delcod);
      }
    });
  }
  OnDragStart(): void {
   // console.log('finalizou');
  }

  OnDrag(): void {
    //console.log('movendo');
  }
  puxaequipe() {
    this.service.allequipe({ ID: this.id }).subscribe((datax: any[]) => {
      this.empregados = [];
      if (datax) {
         // console.log(datax)
          this.equipex = datax;
          for (let i = 0; i < this.equipex.length; i++) {
            this.empregados.push({Id: this.equipex[i].funcionario, tipo: this.equipex[i].funcionario,})
          }
      }
    });
  }
 
  OnCardDoubleClick() {
    if (this.clientefiltrado.statuscliente === 'Coleta de dados') {
      this.dad1 = true;
      this.dad2 = false;
      this.dad3 = false;
      this.dad4 = false;
      this.dad5 = false;
      this.dialogx = true;
    }
    if (this.clientefiltrado.statuscliente === 'Projeto') {
      this.dad2 = true;
      this.dad1 = false;
      this.dad3 = false;
      this.dad4 = false;
      this.dad5 = false;
      this.dialogx = true;
    }
    if (this.clientefiltrado.statuscliente === 'Parecer') {
      this.dad3 = true;
      this.dad2 = false;
      this.dad1 = false;
      this.dad4 = false;
      this.dad5 = false;
      this.dialogx = true;
    }
    if (this.clientefiltrado.statuscliente === 'Vistoria') {
      this.dad4 = true;
      this.dad2 = false;
      this.dad3 = false;
      this.dad1 = false;
      this.dad5 = false;
      this.dialogx = true;
    }
  }

  OnDragStop(e): void {
  //  console.log('finalizou', e.data);
  //  console.log('Tags', e.data[0].Tags);
    if (this.clientefiltrado.statuscliente !== e.data[0].Tags) {
      this.clientefiltrado.statuscliente = e.data[0].Tags
      this.upacliente()
    }

  }
  atualizacliente() {
    this.clientefiltrado.obstatus = this.novaobs
   // console.log("this.clientefiltrado",this.clientefiltrado)
    this.upacliente()
  }

  titleize(text) {
  
    return text;
  }

  adicionainversor() {
    this.inversores.push(
      { 'id':this.inversores.length ,'modeloi': 'Digite a marca', 'quantidadei': 'Digite a quantidade' }
    )
   // console.log(this.inversores)
  }
  removeinversor() {
    this.inversores.pop( )
  }

  adicionamodulo() {
    this.modulos.push(
      { 'id': this.modulos.length,'modelom': 'Digite a marca', 'quantidadem': 'Digite a quantidade' }
    )
  }
  removemodulo() {
    this.modulos.pop( )
  }
  adicionaprazo() {
    this.prazos.push(
      { 'id': this.prazos.length,'valorpag': 'Digite o valor', 'datapag': 'Digite o prazo' }
    )
  }
  removeprazo() {
    this.prazos.pop( )
  }


  changeinver(){
    //console.log(this.inversores)
  }

  solicitahomologa() {
    this.inversores2 = this.inversores
    this.modulos2 = this.modulos
    for (let i = this.inversores2.length ; i < 5; i++) {
      this.inversores2.push({ 'id': i,'modeloi': null,'marcai': null, 'potenciai': null, 'quantidadei': null})
    }
    for (let i = this.modulos2.length ; i < 5; i++) {
      this.modulos2.push({ 'id': i,'modelom': null, 'marcam': null,'potenciam': null, 'quantidadem': null})
    }
    //console.log("inversores",this.inversores2)
    if (this.aumentocarga === undefined) {
      this.aumentocarga = '0';
    }
    if (this.aumentocarga !== undefined) {
      this.aumentocarga = this.aumentocarga;
    }
    this.potnominal = 0;
    this.potpico = 0;
    // tslint:disable-next-line: prefer-for-of
    const dateinici=  new Date(Date.now()).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    this.service.novahomologacao({
      token: localStorage.getItem('tokenx'),
      ID: this.id,
      nome: this.clientefiltrado.Cliente,
      endereco: this.clientefiltrado.endereco,
      cep: '...',
      cidade: this.clientefiltrado.Cidade,
      uf: this.clientefiltrado.Estado,
      rg: '...',
      cpf: '...',
      nomeparceiro: this.dadosolicitante.nick.toUpperCase(),
      emailparc: this.dadosolicitante.emailparceiro,
      homologato: this.clientefiltrado.codcliente,
      email: this.dadosolicitante.emailparceiro,
      cel: this.dadosolicitante.celparceiro,
      classe: '...',
      atividade: '...',
      potkwp: this.potpico,
      area: '...',
      localinst: this.localinst,
      tipotelhado: this.tipotelhado,
      inclinacao: '...',
      coords: '...',
      orientacao: '...',
      descricao: this.descricaodokit,
      distanciaplacaquadro: this.distanciaplacaquadro,
      distanciainversorquadro: this.distanciainversorquadro,
      padraoentrada: this.padraoentrada,
      digeral: this.digeral,
      telefone: '...',
      codhomol: this.clientefiltrado.codcliente,
      statusdados: 'Pendente',
      statusprojeto: 'Pendente',
      statushomologacao: 'Pendente',
      statusvistoria: 'Pendente',
      entradaareasub: this.entradaareasub,
      datainicio: dateinic2,
      time1: dateinic2,
      time2: '',
      time3: '',
      time4: '',
      time1f: '',
      time2f: '',
      time3f: '',   
      time4f: '',
      datafim: '...',
      art: '...',
      disjuntoraumentocarga: this.aumentocarga,
      solicitante: this.dadosolicitante.nick.toUpperCase(),
      iddono: this.id,
      aviso: '...',
      bairro: '...',
      longitude: this.latitude,
      latitude: this.longitude,
      numerocliente: '...',
      numeroinstalacao: '...',
      numclientecemig: '...',
      classetensao: this.classetensao,
      disjuntorgeracao: '...',
      tipodisjgeracao: '...',
      tpdaumentodeCarga: this.tipoaumentocarga,
      cargainstalada: '...',
      pagamentoentrada: 'Pendente',
      pagamentosegundaparte: 'Pendente',
      responsavel: '...',
      complemento: '...',
      potenominalusina: this.potnominal,
      modeloplaca1: this.modulos2[0].modelom,
      qtdplaca1: this.modulos2[0].quantidadem,
      marcaplaca1: this.modulos2[0].marcam,
      potplaca1: this.modulos2[0].potenciam,
      marcaplaca2: this.modulos2[1].marcam,
      modeloplaca2: this.modulos2[1].modelom,
      qtdplaca2: this.modulos2[1].quantidadem,
      potplaca2: this.modulos2[1].potenciam,
      marcaplaca3: this.modulos2[2].marcam,
      modeloplaca3: this.modulos2[2].modelom,
      qtdplaca3: this.modulos2[2].quantidadem,
      potplaca3: this.modulos2[2].potenciam,
      marcaplaca4: this.modulos2[3].marcam,
      modeloplaca4: this.modulos2[3].modelom,
      qtdplaca4: this.modulos2[3].quantidadem,
      potplaca4: this.modulos2[3].potenciam,
      marcaplaca5: this.modulos2[4].marcam,
      modeloplaca5: this.modulos2[4].modelom,
      qtdplaca5: this.modulos2[4].quantidadem,
      potplaca5: this.modulos2[4].potenciam,
      marcainv1: this.inversores2[0].marcai,
qtdinv1: this.inversores2[0].quantidadei,
modeloinv1: this.inversores2[0].modeloi,
potinv1: this.inversores2[0].potenciai,
marcainv2: this.inversores2[1].marcai,
modeloinv2: this.inversores2[1].modeloi,
qtdinv2: this.inversores2[1].quantidadei,
potinv2: this.inversores2[1].potenciai,
marcainv3: this.inversores2[2].marcai,
modeloinv3: this.inversores2[2].modeloi,
qtdinv3: this.inversores2[2].quantidadei,
potinv3: this.inversores2[2].potenciai,
marcainv4: this.inversores2[3].marcai,
modeloinv4: this.inversores2[3].modeloi,
qtdinv4: this.inversores2[3].quantidadei,
potinv4: this.inversores2[3].potenciai,
marcainv5: this.inversores2[4].marcai,
modeloinv5: this.inversores2[4].modeloi,
qtdinv5: this.inversores2[4].quantidadei,
potinv5: this.inversores2[4].potenciai
   
    })
      .subscribe(dados => {
        if (dados) {
          //console.log(dados)
        }
      });
  }

  getorcados() {
    this.data2 = []
    this.direncia = 0
    this.service.getorcados({ 
      token: localStorage.getItem("tokenx"),
      projetid: this.delcod,
      dono:this.id
     }).subscribe((datax: any[]) => {
      if (datax) {
          this.data2 = datax;
          var cma = 0;
          var cmo = 0
          var tax = 0;
          var pro = 0;
          var maq = 0;
          var cest = 0;
          var clo = 0;
          var luc = 0
          var total = 0
          for (let i = 0; i < this.data2.length; i++) {
           // console.log(this.data2[i]["tipo"])
            //console.log(this.data2[i]["valor"])
            total = total + this.data2[i]["valor"]
            this.direncia = this.direncia + this.data2[i]["valor"]
            if(this.data2[i]["tipo"] === 'Material CA'){
              cma = cma + this.data2[i]["valor"]
            }
            if(this.data2[i]["tipo"] === 'Mão de Obra'){
              cmo = cmo + this.data2[i]["valor"]
            }
            if(this.data2[i]["tipo"] === 'Taxas'){
              tax = tax + this.data2[i]["valor"]
            }
            if(this.data2[i]["tipo"] === 'Projeto'){
              pro = pro + this.data2[i]["valor"]
            }
            if(this.data2[i]["tipo"] === 'Aluguel de andaime'){
              maq = maq + this.data2[i]["valor"]
            }
            if(this.data2[i]["tipo"] === 'Estrutura' || this.data2[i]["tipo"] === 'Serviços de acabamento'){
              cest = cest + this.data2[i]["valor"]
            }
            if(this.data2[i]["tipo"] === 'Deslocamento' || this.data2[i]["tipo"] === 'Logística'){
              clo = clo + this.data2[i]["valor"]
            }
            if(this.data2[i]["tipo"] === 'Lucro'){
              luc = luc + this.data2[i]["valor"]
            }
            
          } 
          this.datacust.push( { 'x': "Material CA", y: (cma/total)*100, text: "Material CA" })
          this.datacust.push( { 'x':'Mão de Obra', y: (cmo/total)*100, text: 'Mão de Obra' })
          this.datacust.push( { 'x': 'Estrutura', y: (cest/total)*100, text: 'Estrutura' })
          this.datacust.push( { 'x':'Projeto', y: (pro/total)*100, text: 'Projeto' })
          this.datacust.push( { 'x':'Maquinas e ferramentas', y: (maq/total)*100, text: 'Maquinas e ferramentas' })
          this.datacust.push( { 'x':'Impostos e taxas', y: (tax/total)*100, text: 'Impostos e taxas' })
          this.datacust.push( { 'x':'Deslocamentos e logística', y: (clo/total)*100, text: 'Deslocamentos e logística' })
          this.datacust.push( { 'x':'Lucro', y: (luc/total)*100, text: 'Lucro' })
          //console.log(this.datacust)
          this.showgraph = true
          //console.log('todos', this.data2)
      }
    });
  }


  filtraclientes(cl) {
    this.inversores = [];
    this.modulos = [];
    this.prazos = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.todosclientes.length; i++) {
      if (this.todosclientes[i].codcliente === cl) {
        this.clientefiltrado = this.todosclientes[i];
        let inversoresz = (this.clientefiltrado.dadosinversor).split(';');
        //console.log('tamanho inversorez',inversoresz.length)
        //console.log('inversorez',inversoresz)
        for (let i = 0; i < inversoresz.length; i++) {
          if(i !== inversoresz.length-1 ){
          var marcai = (inversoresz[i].split(':')[0])
          var modeloix = (inversoresz[i].split(':')[1])
          var modeloi = (modeloix)
          this.potenciaix = inversoresz[i].split(':')[2];
          this.potenciai = (this.potenciaix)
          //console.log(this.potenciai)
          var quantidadei = inversoresz[i].split(':')[3];
          this.inversores.push({ 'id': i,'modeloi': modeloi, 'marcai': marcai, 'potenciai': this.potenciai, 'quantidadei': quantidadei})
        }}
        //this.inversores.pop()

        let modulosz = (this.clientefiltrado.dadosmodulo).split(';');
        for (let i = 0; i < modulosz.length; i++) {
          if(i !== modulosz.length-1 ){
            var marcam = (modulosz[i].split(':')[0])
            var modelomx = (modulosz[i].split(':')[1])
            var modelom = (modelomx)
            var potenciamxs = modulosz[i].split(':')[2];
            var potenciamasx = (potenciamxs)
           // console.log(potenciamasx)
            var quantidadem = modulosz[i].split(':')[3];
          this.modulos.push({ 'id': i,'modelom': modelom,'marcam': marcam, 'potenciam': potenciamasx, 'quantidadem': quantidadem })
        }
      }


       // this.modulos.pop()
        let prazosx = (this.clientefiltrado.formapag).split(';');
        for (let i = 0; i < prazosx.length; i++) {
          this.prazos.push({ 'id': i,'valorpag': prazosx[i].split(',')[0], 'datapag': prazosx[i].split(',')[1] })
        }
        this.prazos.pop()
        this.fromDate = this.todosclientes[i].data_inicio;
        this.toDate = this.todosclientes[i].data_fim;
        this.clientefiltrado.endereco = this.titleize(this.clientefiltrado.endereco)
        //console.log(this.clientefiltrado.endereco)
        this.enderecocontrato = this.clientefiltrado.endereco + ', ' + this.titleize(this.clientefiltrado.cep) + ', ' + this.titleize(this.clientefiltrado.Cidade) + ', ' + this.titleize(this.clientefiltrado.Estado)
        this.calcDaysDiff();
        this.puxatarefas();
        this.styleproj = this.clientefiltrado.status_proposta;
        this.styleproj2 = this.clientefiltrado.status_homologacao;
        this.styleproj3 = this.clientefiltrado.status_compra;
        this.styleproj4 = this.clientefiltrado.status_execucao;
        this.styleproj5 = this.clientefiltrado.status_vistoria;
        if (
          this.clientefiltrado.status_proposta !==
          "Proposta aprovada pelo cliente" &&
          this.clientefiltrado.status_homologacao !== "Sistema  Homologado" &&
          this.clientefiltrado.status_compra !==
          "Material Recebido ou em estoque" &&
          this.clientefiltrado.status_execucao !==
          "Execução do sistema concluída" &&
          this.clientefiltrado.status_vistoria !== "Vistoria aprovada"
        ) {
          this.timeline = 0;
        }
        if (
          this.clientefiltrado.status_proposta ===
          "Proposta aprovada pelo cliente" &&
          this.clientefiltrado.status_homologacao !== "Sistema  Homologado" &&
          this.clientefiltrado.status_compra !==
          "Material Recebido ou em estoque" &&
          this.clientefiltrado.status_execucao !==
          "Execução do sistema concluída" &&
          this.clientefiltrado.status_vistoria !== "Vistoria aprovada"
        ) {
          this.timeline = 1;
        }
        if (
          this.clientefiltrado.status_proposta ===
          "Proposta aprovada pelo cliente" &&
          this.clientefiltrado.status_homologacao === "Sistema  Homologado" &&
          this.clientefiltrado.status_compra !==
          "Material Recebido ou em estoque" &&
          this.clientefiltrado.status_execucao !==
          "Execução do sistema concluída" &&
          this.clientefiltrado.status_vistoria !== "Vistoria aprovada"
        ) {
          this.timeline = 2;
        }

        if (
          this.clientefiltrado.status_proposta ===
          "Proposta aprovada pelo cliente" &&
          this.clientefiltrado.status_homologacao === "Sistema  Homologado" &&
          this.clientefiltrado.status_compra ===
          "Material Recebido ou em estoque" &&
          this.clientefiltrado.status_execucao !==
          "Execução do sistema concluída" &&
          this.clientefiltrado.status_vistoria !== "Vistoria aprovada"
        ) {
          this.timeline = 3;
        }
        if (
          this.clientefiltrado.status_proposta ===
          "Proposta aprovada pelo cliente" &&
          this.clientefiltrado.status_homologacao === "Sistema  Homologado" &&
          this.clientefiltrado.status_compra ===
          "Material Recebido ou em estoque" &&
          this.clientefiltrado.status_execucao ===
          "Execução do sistema concluída" &&
          this.clientefiltrado.status_vistoria !== "Vistoria aprovada"
        ) {
          this.timeline = 4;
        }
        if (
          this.clientefiltrado.status_proposta ===
          "Proposta aprovada pelo cliente" &&
          this.clientefiltrado.status_homologacao === "Sistema  Homologado" &&
          this.clientefiltrado.status_compra ===
          "Material Recebido ou em estoque" &&
          this.clientefiltrado.status_execucao ===
          "Execução do sistema concluída" &&
          this.clientefiltrado.status_vistoria === "Vistoria aprovada"
        ) {
          this.timeline = 5;
        }
        this.custopv = parseFloat(this.clientefiltrado.custopv)
        this.materialca = parseFloat(this.clientefiltrado.custoca)
        this.custoproj = parseFloat(this.clientefiltrado.custoproj)
        this.customo = parseFloat(this.clientefiltrado.customo)
        this.custosadicionais = parseFloat(this.clientefiltrado.malucro)
        this.desconto = parseFloat(this.clientefiltrado.desconto)
        this.lucro = parseFloat(this.clientefiltrado.ganho)
        this.atualizacustos();
        this.tarefas = [{
          responsavel: this.clientefiltrado.responsavel,
          ClassName: "e-story, e-low, e-nancy-davloio",
          Color: "#1B59D6",
          Estimate: "no",
          Id: "HL1617310600",
          Priority: "High",
          RankId: 11,
          Status: "Transferida",
          Summary: "Anexar Projeto",
          Tags: this.clientefiltrado.statuscliente,
          Title: "Anexar Projeto",
          Type: "Projeto",
          datafim: "20/05/2021",
          dataini: this.clientefiltrado.data_inicio,
          descricao: "Tarefa transferida, Projeto eletrico anexado na pasta do cliente no One Drive ",
          ide: "HL1617310600-11",
          nomecli: this.clientefiltrado.Cliente,
          ts1: 1621479600,
          valor: this.clientefiltrado.valor_total,
          obsstatus: this.clientefiltrado.obstatus,
          atrasoparecer: '',
          kwp: this.clientefiltrado.kwp,
        }]
        //console.log(this.clientefiltrado)
        this.getorcados()
        const nulle: any = null;

        this.kanbanData = extend(
          [],
          this.tarefas,
          nulle,
          true
        ) as Object[];
        //console.log(this.kanbanData)

      }
    }
    
  }

  puxacarteira() {
    this.minhacarteira = [];
    this.service.mycarteira({ ID: this.id }).subscribe((dados) => {
      if (dados !== this.minhacarteira) {
        this.minhacarteira = dados;
        this.creditoprojeto = this.minhacarteira["creditoproposta"];
        this.usoprojeto = this.minhacarteira["usoprojeto"];
      }
    });
  }

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }

  closemodal() {
    // tslint:disable-next-line: only-arrow-functions
    $(function () {
      $("#transacaomodal").modal("toggle");
    });
  }

  onSelect(data): void {
   // console.log("Item clicked", JSON.parse(JSON.stringify(data)));
  }
  puxasolicitante() {
    this.service.gsolicitante({ ID: this.id })
      .subscribe(dados => {
        if (dados !== this.dadosolicitante) {
          this.dadosolicitante = dados;
          this.atualizanomes();
        }
      })
  }
  atualizanomes() {
    this.nome = this.dadosolicitante['nomeparceiro1'];
    this.cpf = this.dadosolicitante['cnpjparceiro1'];
    this.rua = this.dadosolicitante['ruaparceiro'];
    this.bairro = this.dadosolicitante['bairroparceiro'];
    this.numero = this.dadosolicitante['numruaparceiro'];
    this.cidade = this.dadosolicitante['cidadeparceiro'];
    this.estado = this.dadosolicitante['ufparceiro'];
    this.cep = this.dadosolicitante['cepparceiro'];
    this.emailx = this.dadosolicitante['emailparceiro'];
    this.telefonex = this.dadosolicitante['celparceiro'];
    this.complemento = this.dadosolicitante['complemento'];
    this.cpflegal = this.dadosolicitante['cpflegal'];
    this.replegal = this.dadosolicitante['replegal'];
    this.rglegal = this.dadosolicitante['rglegal'];
    this.sp = false;
    this.enderecempresa = this.dadosolicitante['ruaparceiro'] + ', ' + this.dadosolicitante['numruaparceiro'] + '  ' + this.dadosolicitante['complemento'] + ', ' + this.dadosolicitante['bairroparceiro'] + ', ' + this.dadosolicitante['cepparceiro'] + ', ' + this.dadosolicitante['cidadeparceiro'] + ', ' + this.dadosolicitante['ufparceiro']
    //console.log(this.enderecempresa)
  }
  onActivate(data): void {
    //console.log("Activate", JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log("Deactivate", JSON.parse(JSON.stringify(data)));
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  valueChange(args: any): void {
    this.listObj.hidePopup();
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
    let index: number = this.listObj.value as number;
    clearTimeout(this.clrIntervalFun2);
    this.clrIntervalFun2 = setTimeout(() => {
      this.isDataChanged = true;
      this.stTime = null;
      let contentElement: Element = this.gridInstance.contentModule.getPanel()
        .firstChild as Element;
      contentElement.scrollLeft = 0;
      contentElement.scrollTop = 0;
      this.gridInstance.pageSettings.currentPage = 1;
      this.stTime = performance.now();
      this.gridInstance.dataSource = this.todosclientes;
      this.gridInstance.hideSpinner();
    }, 100);
  }
  novocliente() {
   // console.log(this.datainicioprojeto);
    //console.log(this.datafimprojeto);
    var data = new Date(),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    this.service
      .novocliente({
        token: localStorage.getItem("tokenx"),
        id: this.Codcli,
        idempresa: this.id,
        nomecliente: this.nomecliente,
        cidade: this.cidadecliente,
        estado: this.estadocliente,
        mail: this.email,
        telefone: this.telefone,
        endereco: this.enderecocliente,
        responsavel: this.responsavel,
        data_inicio: diaF + "/" + mesF + "/" + anoF,
        data_fim: diaF + "/" + mesF + "/" + anoF,
        cpf: this.cpfcliente,
        status_proposta: "Elaborando proposta comercial",
        status_homologacao: "Aguardando aprovação de proposta",
        status_compra: "Aguardando aprovação de proposta",
        status_execucao: "Aguardando aprovação de proposta",
        status_vistoria: "Aguardando aprovação de proposta",
        kwp: this.Kwpx,
        valor_total: this.Valornovo,
        statuscliente: 'Coleta de dados',
        obstatus: 'Aguardando informações do cliente',
        dadosinversor: 'Digite a marca, Digite a quantidade;',
        dadosmodulo: 'Digite a marca, Digite a quantidade;',
        formapag: 'Digite o valor, Digite o prazo;',
        cep:this.cepcadastrar

      })
      .subscribe((dados) => {
        if (dados !== this.validacao1) {
          this.validacao1 = dados;
          //console.log(dados);
          if (dados["status"] === "cadastrado") {
            //console.log("inserido");
            this.updateprojetos();
            this.puxacarteira();
            this.puxaclientes();
            this.showToast3("success");
            this.criapasta()
            this.display = false;
          }
          if (dados["status"] === "erro") {
            //console.log(" não inserido");
          }
        }
      });
    this.validacao1 = {};
  }

  upacliente() {
    //console.log("this.inversores",this.inversores)
    var novoinver = ''
    for (let i = 0; i < this.inversores.length; i++) {
      var novoinver = novoinver + this.inversores[i].marcai  + ':' + this.inversores[i].modeloi + ':' +this.inversores[i].potenciai + ':' + this.inversores[i].quantidadei + ';'
    }
    var novomodulo = ''
    for (let i = 0; i < this.modulos.length; i++) {
      var novomodulo = novomodulo + this.modulos[i].marcam  + ':' + this.modulos[i].modelom + ':' +this.modulos[i].potenciam + ':' + this.modulos[i].quantidadem + ';'
    }

    this.service
      .updatecliente({
        token: localStorage.getItem("tokenx"),
        idempresa: this.id,
        nomecliente: this.clientefiltrado.Cliente,
        cidade: this.clientefiltrado.Cidade,
        estado: this.clientefiltrado.Estado,
        mail: this.clientefiltrado.mail,
        telefone: this.clientefiltrado.telefone,
        endereco: this.clientefiltrado.endereco,
        responsavel: this.clientefiltrado.responsavel,
        status_proposta: this.clientefiltrado.status_proposta,
        status_homologacao: this.clientefiltrado.status_homologacao,
        status_compra: this.clientefiltrado.status_compra,
        status_execucao: this.clientefiltrado.status_execucao,
        status_vistoria: this.clientefiltrado.status_vistoria,
        data_inicio: this.clientefiltrado.data_inicio,
        data_fim: this.clientefiltrado.data_fim,
        parcelas_paga: this.clientefiltrado.parcelas_paga,
        cpf: this.clientefiltrado.cpf,
        cep: this.clientefiltrado.cep,
        kwp: this.clientefiltrado.kwp,
        obstatus: this.clientefiltrado.obstatus,
        statuscliente: this.clientefiltrado.statuscliente,
        custopv: this.clientefiltrado.custopv,
        custoca: this.clientefiltrado.custoca,
        custoproj: this.clientefiltrado.custoproj,
        customo: this.clientefiltrado.customo,
        malucro: this.clientefiltrado.malucro,
        desconto: this.clientefiltrado.desconto,
        ganho: this.clientefiltrado.ganho,
        valor_total: this.clientefiltrado.valor_total,
        rg: this.clientefiltrado.rg,
        nac: this.clientefiltrado.nac,
        prof: this.clientefiltrado.prof,
        id :this.clientefiltrado.codcliente,
        dadosinversor:novoinver,
        dadosmodulo:novomodulo
      })
      .subscribe((dados) => {
        if (dados !== this.validacao2) {
          this.validacao2 = dados;
          //console.log(dados);
          if (dados["status"] === "atualizado") {
            //console.log("inserido");
           this.puxaclientes2();
            this.showToast3("success");

          }
          if (dados["status"] === "erro") {
            //console.log(" não inserido");
            this.showToasterror("danger");
          }
        }
      });
    this.validacao2 = {};
  }

  geracontrato() {
    this.service.getcontrato({
      token: localStorage.getItem('tokenx'),
      id: this.id,
      inversores: this.inversores,
      modulos: this.modulos,
      empresa: this.nome,
      pagamentos: this.prazos,
      CNPJ: this.cpf,
      endempresa: this.enderecempresa,
      replegal: this.replegal,
      rgreplegal: this.rglegal,
      cpfreplegal: this.cpflegal,
      contratante: this.clientefiltrado.Cliente,
      naccontratante: this.clientefiltrado.nac,
      cpfcontratante: this.clientefiltrado.cpf,
      rgcontratante: this.clientefiltrado.rg,
      endcontratante: this.enderecocontrato,
      kwp: this.clientefiltrado.kwp,
      mediaanual: this.clientefiltrado.mediaconsumo,
      enderecousina: this.clientefiltrado.enderecocontrato,
      consumo: this.clientefiltrado.mediaconsumo,
      reducao: this.clientefiltrado.redumani,
      aumento: this.clientefiltrado.aumentomani,
      total: this.clientefiltrado.valor_total,
      valortotal: this.clientefiltrado.valor_total,
      primeiraparte: this.clientefiltrado.valor_total,
      segundaparte: this.clientefiltrado.valor_total,
      email: this.emailx,
      garantiainversor: this.clientefiltrado.invergarantia,
      garantiamodulo: this.clientefiltrado.modulogarantia,
      garantiaadicional: this.clientefiltrado.garantiaextra
    });
    this.progress = 0;
    setInterval(() => {
      if (this.progress < 100) {
       // console.log(this.progress);
        this.progress = this.progress + 1;
        
        // tslint:disable-next-line: align
      }
      if (this.progress === 100) {
        this.mostrabuton = true;
        //clearInterval();
      }
    }, 150);
  }

 

  teste() {
    var novoinver = ''
    for (let i = 0; i < this.inversores.length; i++) {
      var novoinver = novoinver + this.inversores[i].modeloi + ','+ this.inversores[i].quantidadei + ';'
    }
    var novomodulo = ''
    for (let i = 0; i < this.modulos.length; i++) {
      var novomodulo = novomodulo + this.modulos[i].modelom + ','+ this.modulos[i].quantidadem + ';'
    }
    var pagamentosx = ''
    for (let i = 0; i < this.prazos.length; i++) {
      pagamentosx = pagamentosx + this.prazos[i].valorpag + ','+ this.prazos[i].datapag + ';'
    }
    //console.log(novoinver)
    //console.log(novomodulo)
    //console.log(pagamentosx)
  }

  upaclientex() {
    this.loading = true
    var novoinver = ''

    var novoinver = ''
    for (let i = 0; i < this.inversores.length; i++) {
      var novoinver = novoinver + this.inversores[i].marcai  + ':' + this.inversores[i].modeloi + ':' +this.inversores[i].potenciai + ':' + this.inversores[i].quantidadei + ';'
    }
    var novomodulo = ''
    for (let i = 0; i < this.modulos.length; i++) {
      var novomodulo = novomodulo + this.modulos[i].marcam  + ':' + this.modulos[i].modelom + ':' +this.modulos[i].potenciam + ':' + this.modulos[i].quantidadem + ';'
    }
    var pagamentosx = ''
    for (let i = 0; i < this.prazos.length; i++) {
      pagamentosx = pagamentosx + this.prazos[i].valorpag + ','+ this.prazos[i].datapag + ';'
    }
    //console.log(novoinver)
    //console.log(novomodulo)
    //console.log(pagamentosx)
    
    //console.log(this.clientefiltrado)
    this.service
      .updatecliente2({
        token: localStorage.getItem("tokenx"),
        idempresa: this.id,
        id: this.clientefiltrado.codcliente,
        rg: this.clientefiltrado.rg,
        nac: this.clientefiltrado.nac,
        prof: this.clientefiltrado.prof,
        mediaconsumo: this.clientefiltrado.mediaconsumo,
        aumentomani: this.clientefiltrado.aumentomani,
        redumani: this.clientefiltrado.redumani,
        invergarantia: this.clientefiltrado.invergarantia,
        modulogarantia: this.clientefiltrado.modulogarantia,
        garantiaextra: this.clientefiltrado.garantiaextra,
        dadosinversor: novoinver,
        dadosmodulo: novomodulo,
        formapag: pagamentosx
      })
      .subscribe((dados) => {
        if (dados !== this.validacao2) {
          this.validacao2 = dados;
          //console.log(dados);
          if (dados["status"] === "atualizado") {
            //console.log("inserido");
            this.puxaclientes();
            this.showToast3("success");
            this.geracontrato()

          }
          if (dados["status"] === "erro") {
            //console.log(" não inserido");
            this.showToasterror("danger");
          }
        }
      });
    this.validacao2 = {};
  }
  novatarefa() {
    if (this.tipotarefa === "Selecione...") {
      this.tipotarefa = null;
    }
    if (this.statustarefa === "Selecione...") {
      this.tipotarefa = null;
    }

    this.service
      .newtarefa({
        id: this.filtrocodcliente,
        idempresa: this.id,
        tarefa: this.tarefa,
        datainicio: this.dataininiciotarefa,
        previsao: this.datafimtarefa,
        datafim: this.datafimtarefa,
        tipo: this.tipotarefa,
        status: this.statustarefa,
        responsavel: this.responsaveltarefa,
        token: localStorage.getItem("tokenx"),
      })
      .subscribe((dados) => {
        if (dados !== this.validacao3) {
          this.validacao3 = dados;
          //console.log(dados);
          if (dados["status"] === "cadastrado") {
            //console.log("inserido");
            this.tarefa = null;
            this.dataininiciotarefa = null;
            this.datafimtarefa = null;
            this.tipotarefa = "Selecione...";
            this.statustarefa = "Selecione...";
            this.responsaveltarefa = null;
            this.showToast1("success");
          }
          if (dados["status"] === "erro") {
            //console.log(" não inserido");
            this.showToasterror("danger");
          }
        }
      });
    this.validacao3 = {};
  }

  puxatarefas() {
    this.service
      .mytarefas({ ID: this.filtrocodcliente })
      .subscribe((data: MYTAREFA[]) => {
        if (data !== this.todastarefas) {
          this.todastarefas = data;
          //console.log(this.todastarefas);
          this.andamento();
        }
      });
  }

  updateprojetos() {
    //console.log(parseFloat(this.usoprojeto) + 1);
    this.service
      .upprojetos({
        token: localStorage.getItem("tokenx"),
        ID: this.id,
        usoprojeto: parseFloat(this.usoprojeto) + 1,
      })
      .subscribe((dados) => {
        if (dados !== this.validacao4) {
          this.validacao4 = dados;
          //console.log(dados);
          if (dados["status"] === "atualizado") {
            this.usoprojeto = (parseFloat(this.usoprojeto) + 1).toString();
            //console.log("inserido");
          }
          if (dados["status"] === "erro") {
            //console.log(" não inserido");
          }
        }
      });
    this.validacao4 = {};
  }

  calcDaysDiff() {
    this.temporestante = moment(this.datainicio, "DD-MM-YYYY").businessDiff(
      moment(this.toDate, "DD-MM-YYYY")
    );
    this.temponecessario = moment(this.fromDate, "DD-MM-YYYY").businessDiff(
      moment(this.toDate, "DD-MM-YYYY")
    );
  }

  mudastatustar(i) {
    this.idstatustar = i;
    //console.log(this.todastarefas[this.idstatustar].idel);
  }

  tarefaatualiza() {
    //console.log(this.todastarefas[this.idstatustar]);
  }

  andamento() {
    this.tarefaspend = 0;
    this.tarefaexec = 0;
    this.tarefaatraso = 0;
    this.andamentoproj = 0;
    this.tarefascon = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.todastarefas.length; i++) {
      if (this.todastarefas[i].status === "Concluída") {
        this.andamentoproj = this.andamentoproj + 1;
        this.tarefascon = this.tarefascon + 1;
      }
      if (this.todastarefas[i].status === "Pendente") {
        this.tarefaspend = this.tarefaspend + 1;
      }
      if (this.todastarefas[i].status === "Em execução") {
        this.tarefaexec = this.tarefaexec + 1;
      }
      if (this.todastarefas[i].status === "Atrasada") {
        this.tarefaatraso = this.tarefaatraso + 1;
      }
    }
    this.andamentoproj = parseFloat(
      ((this.andamentoproj / this.todastarefas.length) * 100).toFixed(2)
    );
    this.sytileand = parseFloat(this.andamentoproj.toFixed(2)) + "%";
  }
  upatarefa() {
    this.service
      .upatarefa({
        token: localStorage.getItem("tokenx"),
        tarefa: this.todastarefas[this.idstatustar].tarefa,
        datainicio: this.todastarefas[this.idstatustar].datainicio,
        previsao: this.todastarefas[this.idstatustar].previsao,
        datafim: this.todastarefas[this.idstatustar].datafim,
        tipo: this.todastarefas[this.idstatustar].tipo,
        status: this.todastarefas[this.idstatustar].status,
        responsavel: this.todastarefas[this.idstatustar].responsavel,
        idel: this.todastarefas[this.idstatustar].idel,
      })
      .subscribe((dados) => {
        if (dados !== this.validacao5) {
          this.validacao5 = dados;
          //console.log(dados);
          if (dados["status"] === "atualizado") {
            //console.log("inserido");
            this.todastarefas = [] as MYTAREFA[];
            this.puxatarefas();
          }
          if (dados["status"] === "erro") {
            //console.log(" não inserido");
            this.showToasterror("danger");
          }
        }
      });
    this.validacao5 = {};
  }
  calculos() {

    // Zera indices
    this.todas = 0;
    this.Aprovadas = 0;
    this.Em_Negociacao = 0;
    this.Aguardando_Proposta = 0;
    this.Negadas = 0;
    this.acompanhando = 0;
    // calcula indices
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.todosclientes.length; i++) {
      if (this.todosclientes[i]['statuscliente'] === 'Parecer') {
        this.Em_Negociacao = this.Em_Negociacao + 1;
      }
      if (this.todosclientes[i]['statuscliente'] === 'Projeto') {
        this.acompanhando = this.acompanhando + 1;
      }
      if (this.todosclientes[i]['statuscliente'] === 'Vistoria' && this.todosclientes[i]['obstatus'] === 'Aprovada') {
        this.Aprovadas = this.Aprovadas + 1;
      }
      if (this.todosclientes[i]['statuscliente'] === 'Vistoria' && this.todosclientes[i]['obstatus'] === 'Rejeitada') {
        this.Negadas = this.Negadas + 1;
      }
      if (this.todosclientes[i]['statuscliente'] === 'Vistoria' && this.todosclientes[i]['obstatus'] !== 'Rejeitada' && this.todosclientes[i]['obstatus'] !== 'Aprovada') {
        this.Em_Negociacao = this.Em_Negociacao + 1;
      }
      if (this.todosclientes[i]['statuscliente'] === 'Coleta de dados' ) {
        this.Aguardando_Proposta = this.Aguardando_Proposta + 1;
      }
    }
    this.todas = this.todosclientes.length;
    this.single = [
      {
        "name": "Coleta de dados",
        "value": this.Negadas
      },
      {
        "name": "Elaborando Projetos",
        "value": this.Aguardando_Proposta

      },
      {
        "name": "Parecer Pendente",
        "value": this.Em_Negociacao

      },
      {
        "name": "Paradas",
        "value": this.Aprovadas
      },
      {
        "name": "Em Vistoria",
        "value": this.todas
      }


    ]
  } v

  delclientex() {
    this.service
      .delcliente({
        token: localStorage.getItem("tokenx"),
        id: this.delcod,
        idempresa: this.id,
      })
      .subscribe((dados) => {
        if (dados !== this.validacao6) {
          if (dados["status"] === "atualizado") {
            //console.log("inserido");
            this.todastarefas = [] as MYTAREFA[];
            this.puxatarefas();
            this.puxaclientes();
          }
          if (dados["status"] === "erro") {
            //console.log(" não inserido");
          }
        }
      });
    this.validacao6 = {};
  }
  deletetask(i) {
    //console.log(i);
    this.service
      .deletatarefa({
        token: localStorage.getItem("tokenx"),
        ID: this.todastarefas[i].idel,
      })
      .subscribe((dados) => {
        if (dados !== this.validacao6) {
          if (dados["status"] === "atualizado") {
            //console.log("inserido");
            this.todastarefas = [] as MYTAREFA[];
            this.puxatarefas();
          }
          if (dados["status"] === "erro") {
            //console.log(" não inserido");
          }
        }
      });
    this.validacao6 = {};
  }
  enviouhomol() {
    var grupo = '53892515427-1633011579'
    var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
    //for (let i = 0; i < fones.length; i++) {
    this.service.sendtext({
      sessionName: 'sollux',
      number: grupo,
      text: '⚠️  O parceiro ' + '*' + this.dadosolicitante.nick.toUpperCase() + '*' + ' Solicitou uma nova homologação com número ' + '*' + this.delcod + '*' + ' .Favor iniciar coleta de dados'
    })
      .subscribe(dados => {
        if (dados) {
          //console.log(dados);
        }
      });
  }
  updatestatusproj() {
    this.service
      .upstatusproj({
        token: localStorage.getItem("tokenx"),
        status_proposta: this.clientefiltrado.status_proposta,
        status_homologacao: this.clientefiltrado.status_homologacao,
        status_compra: this.clientefiltrado.status_compra,
        status_execucao: this.clientefiltrado.status_execucao,
        status_vistoria: this.clientefiltrado.status_vistoria,
        id: this.filtrocodcliente,
      })
      .subscribe((dados) => {
        if (dados !== this.validacao7) {
          this.validacao7 = dados;
          //console.log(dados);
          if (dados["status"] === "atualizado") {
            //console.log("inserido");
            this.todastarefas = [] as MYTAREFA[];
            this.puxaclientes();
            this.showToast2("success");
          }
          if (dados["status"] === "erro") {
            //console.log(" não inserido");
            this.showToasterror("danger");
          }
        }
      });
    this.validacao5 = {};
  }
}
