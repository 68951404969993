<div class="grid">
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Aguardando cotação</span>
            <div class="text-900 font-medium text-xl">{{agcota}}</div>
          </div>
          <div
            class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            "
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">{{agcota/todas*100| number : '1.2-2'}}% </span>
        <span class="text-500"> Aguardando fornecedor</span>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Recebidas</span>
            <div class="text-900 font-medium text-xl">{{cotarecb}}</div>
          </div>
          <div
            class="
              flex
              align-items-center
              justify-content-center
              bg-orange-100
              border-round
            "
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-wallet  text-orange-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">{{cotarecb/todas*100| number : '1.2-2'}}% </span>
        <span class="text-500">Recebidas do fornecedor</span>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Negociando</span>
            <div class="text-900 font-medium text-xl">{{ negociando }}</div>
          </div>
          <div
            class="
              flex
              align-items-center
              justify-content-center
              bg-cyan-100
              border-round
            "
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-user-plus text-cyan-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">{{negociando/todas| number : '1.2-2'}}% </span>
        <span class="text-500">Negociando</span>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Aprovadas</span>
            <div class="text-900 font-medium text-xl">{{aprovadas}}</div>
          </div>
          <div
            class="
              flex
              align-items-center
              justify-content-center
              bg-purple-100
              border-round
            "
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-check-circle text-purple-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">{{aprovadas/todas*100| number : '1.2-2'}}% </span>
        <span class="text-500">Compras efetuadas</span>
      </div>
    </div>
  </div>


  <div style="margin-top: 2px; background-color: rgb(214, 214, 249);padding-top: 20px;" class="surface-section text-center">
    <div  class="grid">
        <div (click)="market=false;newcota=true" class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-dollar text-4xl text-blue-500"></i>
            </span>
            <div  class="text-900 mb-3 font-medium">Nova cotação</div>
            <span class="text-700 text-sm line-height-3">Faça cotações com diferentes fornecedores de kits, estruturas, materiais CA etc...</span>
        </div>
        <div (click)="market=false;newcota=false" class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-wallet text-4xl text-blue-500"></i>
            </span>
            <div class="text-900 mb-3 font-medium">Minhas cotações</div>
            <span class="text-700 text-sm line-height-3">Gerencie suas cotaçoes, faça negociações, acompanhe seus pedidos</span>
        </div>
        <div (click)="market=true;newcota=false" class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-shopping-cart text-4xl text-blue-500"></i>
            </span>
            <div class="text-900 mb-3 font-medium">Market Place</div>
            <span class="text-700 text-sm line-height-3">Acompanhe promoções de kits, estruturas, materiais e acessórios de nossos parceiros.</span>
        </div>
    </div>
    
</div>

<p-table *ngIf="!market"   #dt [value]="customers" [(selection)]="selectedCustomers" dataKey="id" styleClass="p-datatable-customers" [rowHover]="true"
            [rows]="5" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
            [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [filterDelay]="0" [globalFilterFields]="['name','country.name','representative.name','status']">
  
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th >Nome </th>
                    <th>Tipo </th>
                    <th >Data </th>
                    <th >Status </th>
                    <th >Activity </th>
                    <th style="width: 8rem"></th>
                </tr>
                
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr class="p-selectable-row">
                    <td>
                    </td>
                    <td>
                        <span class="p-column-title">Cliente</span>
                        {{customer.name}}
                    </td>
              
                    <td>
                        <span class="p-column-title">Tipo</span>
                        <span class="image-text">{{customer.representative.name}}</span>
                    </td>
                    <td>
                        <span class="p-column-title">Date</span>
                        {{customer.date}}
                    </td>
                    <td>
                        <span class="p-column-title">Status</span>
                        <span [class]="'customer-badge status-' + customer.status">{{customer.status}}</span>
                    </td>
                    <td>
                        <span class="p-column-title">Activity</span>
                        <p-progressBar [value]="customer.activity" [showValue]="false"></p-progressBar>
                    </td>
                    <td style="text-align: center">
                        <button pButton type="button" class="p-button-secondary" icon="pi pi-cog"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">No customers found.</td>
                </tr>
            </ng-template>
        </p-table>


        <div *ngIf="market" class="grid">
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div class="flex justify-content-between mb-3">
                <nz-card nzHoverable style="width:240px" [nzCover]="coverTemplate">
                  <nz-card-meta nzTitle="Kit OnGrid 5kWP" nzDescription="Acessar preço"></nz-card-meta>
                </nz-card>
                <ng-template #coverTemplate>
                  <img alt="example" src="assets/k1.png" />
                </ng-template> 
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div class="flex justify-content-between mb-3">
                <nz-card nzHoverable style="width:240px" [nzCover]="coverTemplate">
                  <nz-card-meta nzTitle="Kit OnGrid 3kWP" nzDescription="Acessar preço"></nz-card-meta>
                </nz-card>
                <ng-template #coverTemplate>
                  <img alt="example" src="assets/k2.png" />
                </ng-template> 
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div class="flex justify-content-between mb-3">
                <nz-card nzHoverable style="width:240px" [nzCover]="coverTemplate">
                  <nz-card-meta nzTitle="Kit OnGrid 5,6kWP" nzDescription="Acessar preço"></nz-card-meta>
                </nz-card>
                <ng-template #coverTemplate>
                  <img alt="example" src="assets/k1.png" />
                </ng-template> 
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div class="flex justify-content-between mb-3">
                <nz-card nzHoverable style="width:240px" [nzCover]="coverTemplate">
                  <nz-card-meta nzTitle="Kit OnGrid 10kWP" nzDescription="Acessar preço"></nz-card-meta>
                </nz-card>
                <ng-template #coverTemplate>
                  <img alt="example" src="assets/k2.png" />
                </ng-template> 
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div class="flex justify-content-between mb-3">
                <nz-card nzHoverable style="width:240px" [nzCover]="coverTemplate">
                  <nz-card-meta nzTitle="Kit OnGrid 5kWP" nzDescription="Acessar preço"></nz-card-meta>
                </nz-card>
                <ng-template #coverTemplate>
                  <img alt="example" src="assets/k1.png" />
                </ng-template> 
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div class="flex justify-content-between mb-3">
                <nz-card nzHoverable style="width:240px" [nzCover]="coverTemplate">
                  <nz-card-meta nzTitle="Kit OnGrid 3kWP" nzDescription="Acessar preço"></nz-card-meta>
                </nz-card>
                <ng-template #coverTemplate>
                  <img alt="example" src="assets/k2.png" />
                </ng-template> 
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div class="flex justify-content-between mb-3">
                <nz-card nzHoverable style="width:240px" [nzCover]="coverTemplate">
                  <nz-card-meta nzTitle="Kit OnGrid 5,6kWP" nzDescription="Acessar preço"></nz-card-meta>
                </nz-card>
                <ng-template #coverTemplate>
                  <img alt="example" src="assets/k1.png" />
                </ng-template> 
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div class="flex justify-content-between mb-3">
                <nz-card nzHoverable style="width:240px" [nzCover]="coverTemplate">
                  <nz-card-meta nzTitle="Kit OnGrid 10kWP" nzDescription="Acessar preço"></nz-card-meta>
                
                </nz-card>
                <ng-template #coverTemplate>
                  <img alt="example" src="assets/k2-1.png" />

                </ng-template> 
              </div>
            </div>
          </div>
        </div>



<p-sidebar [style]="{ width: '40em' }" [(visible)]="newcota" position="right">
    <div style="margin-top: 5px !important;" class="form-group">
        <ejs-textbox  placeholder="Nome Cliente" cssClass="e-outline"  floatLabelType="Always"></ejs-textbox>
    </div>
    <div class="form-group">
        <ejs-textbox readonly placeholder="Código" [value]="'CN3021020021'" cssClass="e-outline"  floatLabelType="Always"></ejs-textbox>
    </div>
    <div class="form-group">
      <ejs-textbox  placeholder="Fornecedor" cssClass="e-outline"  floatLabelType="Always"></ejs-textbox>
  </div>
    <div class="form-group">
        <ejs-textbox  placeholder="Potência Kwp" cssClass="e-outline" floatLabelType="Always"></ejs-textbox>
    </div>
    <div class="form-group">
      <ejs-textbox  placeholder="Marca Inversor" cssClass="e-outline" floatLabelType="Always"></ejs-textbox>
  </div>
  <div class="form-group">
    <ejs-textbox  placeholder="Marca Módulos" cssClass="e-outline" floatLabelType="Always"></ejs-textbox>
</div>
<div class="form-group">
  <ejs-textbox  placeholder="Tipo estrutura" cssClass="e-outline" floatLabelType="Always"></ejs-textbox>
</div>
<div class="form-group">
  <ejs-textbox  placeholder="Tensão operaçao" cssClass="e-outline" floatLabelType="Always"></ejs-textbox>
</div>
<div class="form-group">
  <ejs-textbox  placeholder="Módulos por linha" cssClass="e-outline" floatLabelType="Always"></ejs-textbox>
</div>
<div class="form-group">
  <ejs-textbox  placeholder="Número de linhas" cssClass="e-outline" floatLabelType="Always"></ejs-textbox>
</div>
<button
style="
  margin-bottom: 0px;
  background-color: #0a147c;
  width: 100% !important;
"

type="button"
class="btn btn-info"
>
Enviar
</button>
</p-sidebar>


