import { HomologacoesserviceService } from './../homologacoes/homologacoesservice.service';
import { DbxAuth } from './../configs';
import { AuthService } from './../auth.service';
import { FilesService } from './../files.service';
import { StorageService } from './../storage.service';
import { StorageComponent } from './../storage/storage.component';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Dropbox } from 'dropbox';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';



@Component({
  selector: 'app-visualizarquivos',
  templateUrl: './visualizarquivos.component.html',
  styleUrls: ['./visualizarquivos.component.css']
})
export class VisualizarquivosComponent implements OnInit, OnDestroy {
autorizado: string;
  public dbxAuth: any;
  private subscription: Subscription;
  @Input() caminho = '';

teste: any;
  constructor(private authService: AuthService,
              private storageService: StorageService,
              private filesService: FilesService,
              private service: HomologacoesserviceService) { }

  ngOnInit() {
    this.subscription = this.authService.getAuth()
    .subscribe((auth) => this.dbxAuth = auth);
    console.log(this.dbxAuth);
  }

  

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }

  showHome(event) {
      event.preventDefault();
      event.stopPropagation();

      // Disable all other functions (Starred - Favorites, Deletes files, Search) -- Added by K
      this.storageService.deactivateShowFavorites();
      this.storageService.deactivateShowDeletes();
      this.storageService.deactivateShowSearch();

      // Active again data stream from service
      this.filesService.getFiles('');
  }

  showFavorites(event) {
      event.preventDefault();
      event.stopPropagation();
      this.storageService.activateShowFavorites();
  }

  showDeletes(event) {
      event.preventDefault();
      event.stopPropagation();
      this.storageService.activateShowDeletes();
  }
}
