import { Injectable } from '@angular/core';
import { tap, delay, take, map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpClientModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class FinanceiroService {
  public getToken(): string {
    return localStorage.getItem('tokenx');
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin':'*',
      "Content-Type": "application/json",
    })
  };
  constructor(private http: HttpClient, private router: Router) {
  }
// API PUXA DEBITOS
meusdebitos(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/meusdebitos', {params : paramns2, headers: headers })
.pipe(tap());
}

// API INSERE 
updatedeb(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updatedeb', {params : params3 })
.pipe(tap());
}

// API INSERE 
nwcaixa(params3) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/financeiro/insertlancamento', {params : params3 })
.pipe(tap());
}

  // API PUXA  FINANCEIRO
finan() {
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/financeiro/financeirop')
.pipe(tap());
}

// API PUXA  HOMOLOGACOES PENDENTES DE PAGAMENTO
paghomol() {
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/financeiro/paghomol')
.pipe(tap());
}

// API PUXA  CAIXA DA SOLLUX
caixasolux() {
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/financeiro/caixasol')
.pipe(tap());
}

// API INSERE CONTA-RECEBIMENTO
novolancamento(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/financeiro/insertconta', {params : params3 })
.pipe(tap());
}


// API UPDATE CONTA-RECEBIMENTO
updatelancamento(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/financeiro/upafinanceiro', {params : params3 })
.pipe(tap());
}


// API UPDATE CONTA-RECEBIMENTO
updatetransfer(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/financeiro/transferefinan', {params : params3 })
.pipe(tap());
}
  
// API DELETA TAREFA HOMOLOGACOES
delfinanceiro(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.delete<any[]>('https://www.sollux.dev/api/_functions/financeiro/deletefin', {params : paramns2,headers: headers  })
.pipe(tap());
}

}
