import { FilesService } from './../../files.service';
import { todocaixa } from 'src/app/homologacoes/caixa';
import { HomologacoesserviceService } from 'src/app/homologacoes/homologacoesservice.service';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import * as $AB from 'jquery';
import * as $ from 'jquery';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { FilterService, GridComponent,IFilter,VirtualScrollService  } from '@syncfusion/ej2-angular-grids';
import { DropDownListComponent, DropDownListModel } from '@syncfusion/ej2-angular-dropdowns';
import { CheckBox  } from '@syncfusion/ej2-buttons';
import { InPlaceEditorComponent, RenderMode, EditableType } from '@syncfusion/ej2-angular-inplace-editor';
import { NumericTextBoxModel, MaskedTextBoxModel, TextBoxModel } from '@syncfusion/ej2-inputs';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import 'src/assets/js/bootstrap-datepicker.js';
import { NbDialogService, NbToastrService, NbComponentStatus } from '@nebular/theme';
import { MYTAREFA } from 'src/app/homologacoes/mytarefas';
import { COTACOES } from 'src/app/homologacoes/mycota';
import { FileUploader } from 'ng2-file-upload';
import { Dropbox } from 'dropbox';
import { ChatAdapter } from 'ng-chat';
import { ChatService } from 'src/app/chat.service';
import { DemoAdapter } from './demo-adapter';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import * as CryptoJS from 'crypto-js';
import { Router } from "@angular/router";

import "src/assets/js/chatwd.js";
import { AuthService } from 'src/app/auth.service';
import { ToolbarService } from '@syncfusion/ej2-angular-documenteditor';
import { MenuItemModel } from '@syncfusion/ej2-navigations';
import { DocumentEditorContainerComponent } from '@syncfusion/ej2-angular-documenteditor';
import { TitleBar } from './title-bar';
import { defaultDocument, WEB_API_ACTION } from './data';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { DbxAuth } from 'src/app/configs';

const URL = 'https://www.sollux.dev/api/_functions/consultas/upload';

@Component({
  selector: 'app-cotacoes',
  templateUrl: './cotacoes.component.html',
  styleUrls: ['./cotacoes.component.css'],
  providers: [ToolbarService,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],

})

export class CotacoesComponent implements OnInit {


  urlcompartilhadax = '';
  novocaminho:string;
  getpath  = '';
  htmlContent = '';
  display = false;
  chatselc = [];
  chatsx = [];
  public adapter: ChatAdapter = new DemoAdapter();
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '450px',
    minHeight: '5rem',
    enableToolbar: false,
    showToolbar: false,
    placeholder: 'Detalhe o orçamento.',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      []
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  colorScheme = {
    domain: ['#FF0000', '#D8FF00', '#0068FF', '#8A9465', '#484A5E']
  };
  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = ['codcliente', 'tipo', 'local', 'responsavel', 'status', 'entrada', 'validade', 'Menu', 'delete'];
  columnsToDisplay = ['codcliente', 'tipo', 'local', 'responsavel', 'status', 'entrada', 'validade', 'Menu', 'delete'];
  // variavelglobal
  single: any[];
  titulo: '';
  msg: '';
  titulonot: '';
  msgnot: '';
  codigoatual: string;

  // variaveiscliente

  telefone: string;
  email: string;
  datafimprojeto: string;
  datainicioprojeto: string;
  fromDate: any;
  toDate: any;
  cidadecliente: string;
  estadocliente: string;
  enderecocliente: string;
  cotacao: string;
  categor: string;
  responsavel: string;
  datainicio: any;
  cpfcliente: string;
  validacao1: {};
  validacao2: {};
  validacao3: {};
  validacao4: {};
  validacao5: {};
  validacao6: {};
  validacao7: {};
  pendentes = 0;
  perdidas = 0;
  emelab = 0;
  aprovadas = 0;
  elaboradas = 0;
  enviaarquivo = false;
  // variaveis update
  filtrocodcliente: string;
  // variaveistarefa
  tarefa: string = null;
  dataininiciotarefa: string;
  datafimtarefa: string;
  previsaotarefa: string;
  tipotarefa = 'Selecione...';
  statustarefa = 'Selecione...';
  responsaveltarefa: string = null;
  // variaveis de controle
  Clicod = 'CL';
  Codcli = '';
  datanagora = '';
  abrecliente = 'false';
  creditoprojeto: string;
  usoprojeto: string;
  selection = new SelectionModel<todocaixa>(true, []);
  clientefiltrado:any
  userfiltrado: any;
  todascotacoes: COTACOES[];
  todastarefas: MYTAREFA[];
  nopDate: any;
  id: string;
  closeResult: string;
  finalizacao = [];
  pago: string;
  name: string;
  categoria = [];
  cor: string;
  corred = 'red';
  corblue = 'blue';
  trasacselect: string;
  //data: string;
  numerohologacaoes = 0;
  valor: number;
  mes: number;
  favorecido: string;
  descricao: string;
  okpago: string;
  dataSource = new MatTableDataSource<COTACOES>([]);
  esperadados = 0;
  esperaprojeto = 0;
  esperacesso = 0;
  esperavistoria = 0;
  iden: number;
  ideatuliza = [];
  orcadetalhado = '';
  uploadedFiles: any[] = [];

  uploading = false;
  fileList: NzUploadFile[] = [];
  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  reload = true;
  path = '';
  pathori = '';
  ref = "/aplicativos/cotacoes/";
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  validaupload = 'false';
  validacaoemail = {};
  ultprogup: number;
  enviado = false;
  naoenviado = false;
  idcotafiltrado: any;
  ACCESS_TOKEN = 'BWJLh8XyqGoAAAAAAAAAAbw_HaIZUewnbtyacTQhdMtNCcgv1_Ojr_a5qqaMippC';
  dbx = new Dropbox({ accessToken: this.ACCESS_TOKEN });
  dbxau:any;
  // apagar depois
  public maskedTextBoxObj: InPlaceEditorComponent;
  public maskValue: string = '012-345-6789';
  public dReady: boolean = false;
  public dtTime: boolean = false;
  public isDataBound: boolean = false;
  public isDataChanged: boolean = true;
  public intervalFun: any;
  public clrIntervalFun: any;
  public clrIntervalFun1: any;
  public clrIntervalFun2: any;    
  public dropSlectedIndex: number = null;
  public stTime: any;
  public data:any;
  public fieldx: Object = { text: 'nome', groupBy: 'tipo' };
  public cssClass: string = 'e-list-template';
  public filter: Object;
  public filterSettings: Object;
  public selectionSettings: Object;  
  public height: string = '240px';
  @ViewChild('sample') 
  public listObj: DropDownListComponent;
  @ViewChild('overviewgrid')
  public gridInstance : GridComponent ;  

  public fields: Object = { text: 'text', value: 'value' };
  public item: number[] = [1, 2, 3, 4, 5];  
  public autocompleteData: string[] = ['Australia', 'Bermuda', 'Canada', 'Cameroon', 'Denmark', 'Finland', 'Greenland', 'Poland'];;
  
  public hostUrl: string = 'https://ej2services.syncfusion.com/production/web-services/';
  @ViewChild('documenteditor_default')
  public container: DocumentEditorContainerComponent;
  public culture: string = 'pt-BR';
  titleBar: TitleBar;
  fileToUpload: File = null;
  view: any[] = [800, 200];
  title = 'app';
  user: String;
  renderizou = false;
  room: String;
  meunome: String;
  messageText: String;
  messageArray: Array<{user:String, textmsg:String, tipo:String, ts:String }> = [];
  chatspendentes:any;
  online = [];
  abrecotacao = "false";
  menu1 = true;
  menu2 = false;
  menu3 = false;
  menu4 = false;
  menu5 = false
  menu6 = false;
  menu7 = false
  onlinee = false;
  first = 0;
  rows = 5;
  Negociando = 0;
  status = false;
  public messageSeen(event: any) {
    console.log(event);
  }
  public cellSpacing: number[] =  [5, 5];
    public aspectRatio : any = 100 / 85;
    public centerTitle: any;
  itemsx: MenuItem[];
  tokenx:any;
  codig : any;
  adbx: any;
  acesstok:any;
  dbcAutho = [];
  autorizado = 'true';
  public dbxAuth: DbxAuth;
  totaly = 0
  constructor(private datapat: DateAdapter<any>,
    private router: Router,
    private service: HomologacoesserviceService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private filesService: FilesService,
    private _chatService: ChatService,
    private configa: PrimeNGConfig,
    private http: HttpClient,
    private authService: AuthService,

  ) {
    
    this._chatService.newUserJoined()
      .subscribe(data => this.onlines(data));

      this._chatService.onlinestatus()
      .subscribe(data => this.onlines(data));

    this._chatService.userLeftRoom()
      .subscribe(data => this.offlines(data));

    this._chatService.newMessageReceived()
      .subscribe(data => this.apresentamsg(data));
    this.uploader = new FileUploader({
      url: URL,
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.response = '';
    this.uploader.response.subscribe(res => this.response = res);
  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  show(a){
    console.log(a.item.properties.text)
    if(a.item.properties.text === "Voltar"){
      this.clientepnael()
    }
  }
  asyncUpload(i) {
    return new Promise((resolve, reject) => {
      console.log(this.ultprogup);
      // tslint:disable-next-line: prefer-for-of
      console.log(this.uploader.queue[i]['isSuccess'])
      // tslint:disable-next-line: max-line-length
      this.dbx.filesUpload({ path: "/" + this.idcotafiltrado + "/proposta" + "/" + this.uploader.queue[i]['_file'].name, contents: this.uploader.queue[i]['_file'] })
        // tslint:disable-next-line: only-arrow-functions
        .then(response => {
          this.enviado = true;
          this.uploader.queue[i]['isUploaded'] = true;
          this.uploader.queue[i]['isSuccess'] = true;
          
        })
        .catch(error => reject())
        .finally(() => this.progresso());
    });
  }


    

enviaarquivos(){
  this.enviaarquivo  = true;
}
  progresso() {
    this.ultprogup = 100 / (this.uploader.queue.length);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (this.uploader.queue[i]['isSuccess'] === true) {
        this.uploader.progress = this.ultprogup * (i + 1);
      }
    }
    if (this.uploader.progress === 100) {
      this.validaupload = 'true';
    }
    this.filesService.getFiles(this.path);
  }


   getshare2(caminho){

   console.log(localStorage.getItem('urlcompartilhada'))
   if(localStorage.getItem('urlcompartilhada')){
    this.novocaminho =  localStorage.getItem('urlcompartilhada')
    console.log(this.novocaminho)
    this.novocaminho = this.novocaminho.substring(0, this.novocaminho.length - 1);
    this.urlcompartilhadax = this.novocaminho.replace('https://www.dropbox.com','https://dl.dropboxusercontent.com') + '1';
    console.log(this.urlcompartilhadax)
    this.messageText = this.urlcompartilhadax
    //
   }
   
  }

  getshare(caminho){
    this.dbxau = this.filesService.compartilha()
    console.log(this.dbxau)
    this.dbxau.sharingCreateSharedLink({ path: '/aplicativos/cotacoes'+caminho })
    .then(response => {
      this.novocaminho =  response.url
      console.log(this.novocaminho)
      this.novocaminho = this.novocaminho.substring(0, this.novocaminho.length - 1);
      this.urlcompartilhadax = this.novocaminho.replace('https://www.dropbox.com','https://dl.dropboxusercontent.com') + '1';
      console.log(this.urlcompartilhadax)
      this.messageText = this.urlcompartilhadax
      this.sendMessage('file');
   }, error => {
       console.log(error);
   });
  }


  async uploadalgo() {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      await this.asyncUpload(i);
    }
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  onlines(a) {
    this.online.push(a)
    console.log(a.user)
    var i = this.chatsx.length -1 
      console.log(this.chatsx[i])
      if(this.chatsx[i].sender === a.user  || this.chatsx[i].recept === a.user ){
      this.onlinee = true
      console.log('ésta online')
    }  
    
  }

  offlines(a){
    console.log(a.user)
    var i = this.chatsx.length -1 
      console.log(this.chatsx[i])
      if(this.chatsx[i].sender === a.user  || this.chatsx[i].recept === a.user ){
      this.onlinee = false
      console.log('ésta offline')
    }  
  }

  menumuda(a) {
    console.log(a)
    if (a === 1) {
      this.menu1 = true;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = false
      this.menu6 = false;
      this.menu7 = false

    }
    if (a === 2) {
      this.menu1 = false;
      this.menu2 = true;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = false

    }
    if (a === 3) {
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = true;
      this.menu4 = false
      this.menu5 = false
      this.menu6 = false;
      this.menu7 = false
    }
    if (a === 4) {
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu5 = false
      this.menu4 = true
      this.menu6 = false;
      this.menu7 = false
    }
    if (a === 5) {
      this.abrechat(this.clientefiltrado.alterid)
    }

    if (a === 6) {
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu5 = false
      this.menu4 = false;
      this.menu6 =true;
      this.menu7 = false
    }
    if (a === 7) {
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu5 = false
      this.menu4 = false;
      this.menu6 =false;
      this.menu7 = true
    }
  
  }
  onCreate(): void {
    let titleBarElement: HTMLElement = document.getElementById('default_title_bar');
    this.titleBar = new TitleBar(titleBarElement, this.container.documentEditor, true);
    this.container.serviceUrl = this.hostUrl + WEB_API_ACTION;
    this.container.documentEditor.open(JSON.stringify(defaultDocument));
    this.container.documentEditor.documentName = 'Getting Started';
    this.titleBar.updateDocumentTitle();
}

onDocumentChange(): void {
    if (!isNullOrUndefined(this.titleBar)) {
        this.titleBar.updateDocumentTitle();
    }
    this.container.documentEditor.focusIn();
}

  validateId(a) {
    if(this.online.length >0)
    {  var resultado = this.online.find(x => x.user === a)
   if(resultado.user){
     console.log('true')
     return true}
    }
    else if(this.online.length === 0){
      console.log('false')
       return false
     }
  }
  abrechat(a){
    this.room = this.clientefiltrado.alterid;
    this.user =  this.meunome;
    this.display = true;
    this.togla();
  
    this.chatx();
    console.log(a)
  }
  mostraevento(a) {
    console.log(a);
    console.log(this.clientefiltrado.idcota);
    console.log(this.idcotafiltrado);
    console.log(a.target.text);
    if (a.target.text === 'arquivoschat') {
      this.reload = false;
      this.path = this.ref + this.idcotafiltrado + '/arquivoschat';
      this.filesService.getFiles(this.path);
      setTimeout(() => {
        this.reload = true;
      }, 1000);
    }
    if (a.target.text === 'contas') {
      this.reload = false;
      this.path = this.ref + this.idcotafiltrado+ '/contas';
      this.filesService.getFiles(this.path);
      setTimeout(() => {
        this.reload = true;
      }, 1000);
    }

    if (a.target.text === 'proposta') {
      this.reload = false;
      this.path = this.ref + this.idcotafiltrado + '/proposta';
      this.filesService.getFiles(this.path);
      setTimeout(() => {
        this.reload = true;
      }, 1000);
    }
    console.log(this.path);
    this.filesService.getFiles(this.path);
  }


  join() {
    this._chatService.joinRoom({ user: this.user, room: this.room });
  }

  leave() {
    this._chatService.leaveRoom({ user: this.user, room: this.room });
  }

  sendMessage(type) {
    console.log('enviado mensagem')
    console.log(this.clientefiltrado.idcli)
    this._chatService.sendMessage(
      { 
        dono: this.user,
        user: this.user, 
        recept:this.clientefiltrado.idcliente,
        sender:this.id,
        room: this.clientefiltrado.alterid, 
        textmsg: this.messageText, 
        tipo: type, 
        data: new Date(Date.now()).toLocaleString('pt-BR') 
      });
      this.messageText = ''
  }

  apresentamsg(a){
    this.chatsx.push(a)
    console.log(a.textmsg)
  }
  enviamsg(a){
    this.messageText = a.message
    this.sendMessage('text');
    console.log(a)
  }

  chatx() {
    this.service.puxachat({ ID: this.clientefiltrado.alterid })
      .subscribe((data: any[]) => {
        if (data) {
          this.chatsx = data
          this.chatselc = data;
          console.log(this.chatselc)
        }
      });
  }

 

  onUpload(event) {
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }
    console.log(this.uploadedFiles[0].name)
    return new Promise((resolve, reject) => {
      this.dbx.filesUpload({ path: "/" + this.idcotafiltrado + "/arquivoschat" + "/" + this.uploadedFiles[0].name, contents: this.uploadedFiles[0] })
        // tslint:disable-next-line: only-arrow-functions
        .then(response => {
          console.log(response)
          this.getpath = response.path_lower
          this.enviado = true;
        })
        .finally(() => this.getshare(this.getpath));
    });
}

beforeUpload = (file: NzUploadFile): boolean => {
  this.fileList = this.fileList.concat(file);
  console.log(this.fileList)
  return false;
};


myUploader() {
  return new Promise((resolve, reject) => {
    this.dbx.filesUpload({ path: "/" + this.idcotafiltrado + "/proposta" + "/" + this.fileList[0].name, contents: this.fileList[0] })
      // tslint:disable-next-line: only-arrow-functions
      .then(response => {
        this.enviado = true;
      })
      .catch(error => reject())
      .finally(() => this.progresso());
  });
}

displayx(){
  this.display = false;
}
togla(){
  $(document).ready(function() {
    var $chatbox = $('.chatbox'),
        $chatboxTitle = $('.chatbox__title'),
        $chatboxTitleClose = $('.chatbox__title__close'),
        $chatboxCredentials = $('.chatbox__credentials');
    $chatboxTitle.on('click', function() {
        $chatbox.toggleClass('chatbox--tray');
    });
    $chatboxTitleClose.on('click', function(e) {
      this.display = false;
        e.stopPropagation();
        $chatbox.addClass('chatbox--closed');
    });
    $chatbox.on('transitionend', function() {
        if ($chatbox.hasClass('chatbox--closed')) $chatbox.remove();
    });
    
});
}

  ngOnInit() {
    this.autorizacao()
    this.itemsx = [
      {
        label:'Editar',
        icon:'pi pi-user-edit',
        command: (event) => {
          this.menumuda(1)
      }
    },
      {
          label:'Orçamento',
          icon:'pi pi-dollar',
          command: (event) => {
            this.menumuda(2)
          }
      },
      {
          label:'Arquivos',
          icon:'pi pi-folder',
          command: (event) => {
            this.menumuda(3)
          }
      },
      {
          label:'Chat',
          icon:'pi pi-comment',
          command: (event) => {
            this.menumuda(5)
          }
      },
      {
          label:'Notificar',
          icon:'pi pi-bell',
          command: (event) => {
            this.menumuda(4)
          }
      }
      ,
      {
          label:'Status',
          icon:'pi pi-check-square',
          command: (event) => {
            this.menumuda(7)
          }
      }
  ];

    localStorage.removeItem('urlcompartilhada');
    this.configa.setTranslation({
      accept: 'Aceito',
      reject: 'Cancelar',
      startsWith: "Começa com",
      contains: "Contém",
      notContains: "Não contém",
      endsWith: "Termina com",
      equals: "Igual",
      notEquals: "Diferente",
      noFilter: "Sem filtro",
      lt: "Menor que",
      lte: "menor ou igual",
      gt: "Maior que",
      gte: "Maior ou igual",
      is: "É",
      isNot: "Não é",
      before: "Antes",
      after: "Depois",
      clear: "Limpar",
      apply: "Aplicar",
      matchAll: "Match All",
      matchAny: "Match Any",
      addRule: "Add regra",
      removeRule: "Remover regra",
      choose: "Escolher",
      upload: "Enviar",
      cancel: "Cancelar",
      dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["Do","Se","Te","Qa","Qi","Sx","Sa"],
      monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
      monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      today: "Hoje",
      weekHeader: "Wk"
      //translations
  });
    $(document).ready(function() {
      var $chatbox = $('.chatbox'),
          $chatboxTitle = $('.chatbox__title'),
          $chatboxTitleClose = $('.chatbox__title__close'),
          $chatboxCredentials = $('.chatbox__credentials');
      $chatboxTitle.on('click', function() {
          $chatbox.toggleClass('chatbox--tray');
      });
      $chatboxTitleClose.on('click', function(e) {
          e.stopPropagation();
          $chatbox.addClass('chatbox--closed');
      });
      $chatbox.on('transitionend', function() {
          if ($chatbox.hasClass('chatbox--closed')) $chatbox.remove();
      });
      
  });
    this.datainicio = new Date(Date.now()).toLocaleString('pt-BR');
    this.datanagora = (((Date.now()) / 1000).toFixed(0)).toString()
    this.Codcli = this.Clicod + this.datanagora;
    this.service.currentid.subscribe(dados => {
      if (dados !== this.id && dados !== '') {
        this.id = dados;
        this.puxaclientes();
      }
    });

    this.service.currentNome.subscribe(dados => {
      if (dados) {
        this.meunome = dados;
      }
    });
    this.filterSettings = { type: "Menu" };      
    this.filter = { type: "CheckBox" };    
    this.stTime = performance.now();
    this.selectionSettings = {persistSelection: true, type: "Multiple", checkboxOnly: true };
  }

  showToasterror(status: NbComponentStatus) {
    this.toastrService.show(status, `Erro: Verifique todos os campos!`, { status });
  }
  showToast1(status: NbComponentStatus) {
    this.toastrService.show(status, `Sucesso: Tarefa criada com sucesso!`, { status });
  }
  showToast2(status: NbComponentStatus) {
    this.toastrService.show(status, `Sucesso: Status atualizado com sucesso!`, { status });
  }
  showToast3(status: NbComponentStatus) {
    this.toastrService.show(status, `Sucesso: Projeto atualizado com sucesso!`, { status });
  }
  clientepnael() {
    if (this.abrecliente === 'true') 
    { this.abrecliente = 'false'; } 
    else { this.abrecliente = 'true'; }
    console.log(this.abrecliente);
  }

  puxaclientes() {
    this.Negociando = 0;
    this.pendentes =0;
    this.perdidas = 0;
    this.aprovadas = 0;
    this.elaboradas = 0
    this.dataSource.sort = this.sort;
    this.service.getcotacoes()
      .subscribe((data: COTACOES[]) => {
        if (data) {
          this.data = data;
          this.renderizou = true
          this.todascotacoes = data;
          this.dataSource = new MatTableDataSource<COTACOES>(this.todascotacoes);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.totaly = this.todascotacoes.length
          console.log(this.todascotacoes)
          for (let i = 0; i < this.todascotacoes.length; i++) {
            if (this.todascotacoes[i]['status'] === 'Em andamento') {
              this.pendentes = this.pendentes + 1;
            }
            if (this.todascotacoes[i]['status'] === 'Em negociação') {
              this.Negociando = this.Negociando + 1;
            }
            if (this.todascotacoes[i]['status'] === 'Negado') {
              this.perdidas = this.perdidas + 1;
            }
            if (this.todascotacoes[i]['status'] === 'Vencida') {
              this.perdidas = this.perdidas + 1;
            }
            if (this.todascotacoes[i]['status'] === 'Elaborando') {
              this.pendentes = this.pendentes + 1;
            }
            if (this.todascotacoes[i]['status'] === 'Aprovada') {
              this.aprovadas = this.aprovadas + 1;
            }
            if (this.todascotacoes[i]['status'] === 'Elaborada') {
              this.elaboradas = this.elaboradas + 1;
            }
          }
          this.single = [
            {
              "name": "Pendentes",
              "value": this.pendentes
            },
            {
              "name": "Perdidas",
              "value": this.perdidas
            },
            {
              "name": "Elaboradas",
              "value": this.elaboradas
            },
            {
              "name": "Aprovadas",
              "value": this.aprovadas
            }
          ]
          console.log(this.single)
        }
      });
 
  }
  filtracota(cl) {
    // tslint:disable-next-line: prefer-for-of
    console.log(cl.idcota)
    for (let i = 0; i < this.todascotacoes.length; i++) {
      if (this.todascotacoes[i].idcota === cl.idcota) {
        this.clientefiltrado = this.todascotacoes[i];
        console.log(this.clientefiltrado)
        this.filtraparceiro(this.clientefiltrado.idcliente)
        console.log(this.clientefiltrado.idcota)
        console.log(this.clientefiltrado)
        this.filtrocodcliente = cl.idcota;
        this.idcotafiltrado = cl.idcota;
        console.log(this.user)
        console.log(this.room)
        this.room = this.clientefiltrado.alterid;
        this.user =  this.meunome;
        this._chatService.joinRoom({ user: this.user, room: this.room });
        this.chatx()
        this.path = this.ref + this.idcotafiltrado;
        this.pathori = this.path;
        this.reload = true;
        this.abrecotacao = "true"
        this.filesService.getFiles(this.ref + this.idcotafiltrado);

      }

    }

  }

  autorizacao() {
    this.tokenx =  localStorage.getItem('tokenx');
    this.service.getdbx({ token: this.tokenx })
    .subscribe((data) => {
      console.log(data)
      this.codig = data
      this.decryptData(this.codig.cod)}
      )
}
decryptData(data:any) {
  try {
    const bytes = CryptoJS.AES.decrypt(data, this.tokenx);
    if (bytes.toString()) {
      var dadox = (JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
      console.log(dadox)
      this.adbx = dadox;
      this.autoh();
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}
autoh(){
  this.acesstok =  new Dropbox({ accessToken: this.adbx[0].accessToken });
      // Begin authentication process if credentials not found
      console.log(this.adbx[0].accessToken )
    if (this.autorizado === 'true') {
          const authUrl = this.router.url;
          const parameters = authUrl.split('#')[1] || '';
          if (this.autorizado === 'true') {
              const arrParams = parameters.split('&') || [];
              if (this.autorizado === 'true') {
                  const authObj: DbxAuth = { isAuth: false };
                  // tslint:disable-next-line: prefer-for-of
                  authObj.accessToken = this.adbx[0].accessToken;
                  authObj.tokenType = this.adbx[0].tokenType;
                  authObj.uid = this.adbx[0].uid;
                  authObj.accountId = this.adbx[0].accountId;
                  if (authObj.accessToken && authObj.tokenType && authObj.uid && authObj.accountId) {
                      authObj.isAuth = true;
                      this.dbxAuth = authObj;
                  }
                }
          }
          console.log('esse eh dbxauto',this.dbxAuth)
          this.service.changedauth(this.dbxAuth)
          // Store credentials into Auth-service and into localStorage
          // if (this.dbxAuth.isAuth) {
           //    this.authService.storeAuth(this.dbxAuth);
           //    this.podeupar = true;
        //   }
      // } else {
      }
}
  voltarq() {
    this.path = this.pathori;
    this.filesService.getFiles(this.path);
  }

  notificacao() {
    this.service.notificacota({ token: "eG7zmyZ2R1-iMmfHD06bpI:APA91bHmGQXphfcLFTAcHYK5vJN2TZNshacU-8_jV3JYa3wQmgHJrTyggelCJr7KEUB_ZR_8eLo1fKc2bV0jVUczSue0kqclBZ8weYIR_KwvNAnfoit8_k7hRlJXi5fMD1fvEAwEqk6c", titulo: this.titulonot, msg: this.msgnot })
      .subscribe(dados => {
        if (dados !== "vp") {
          console.log(dados)
        }
      });
  }

  filtraparceiro(a) {
    this.service.filtrausercota({
      ID: a
    })
      .subscribe(dados => {
        if (dados[0] !== this.userfiltrado) {
          this.userfiltrado = dados[0];
          console.log('cliente filtrado', this.userfiltrado)
          //this.clientepnael()
          this.abrecliente = 'true'

        }
      });
  }

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  closemodal() {
    // tslint:disable-next-line: only-arrow-functions
    $(function () {
      $('#transacaomodal').modal('toggle');
    });
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  deletetask(i) {
    console.log(i)
    this.service.delcota({ ID: i })
      .subscribe(dados => {
        if (dados !== this.validacao6) {
          if (dados['status'] === 'atualizado') {
            console.log('inserido')
            this.puxaclientes();
          }
          if (dados['status'] === 'erro') {
            console.log(' não inserido')
          }
        }
      });
    this.validacao6 = {};
  }


  UPACOTA() {
    this.service.updatecotacao(this.clientefiltrado)
      .subscribe(dados => {
        if (dados !== this.validacao2) {
          this.validacao2 = dados;
          console.log(dados)
          if (dados['status'] === 'atualizado') {
            console.log('inserido')
            this.puxaclientes();
            this.filtracota(this.filtrocodcliente);
            this.showToast3('success');
          }
          if (dados['status'] === 'erro') {
            console.log(' não inserido')
            this.showToasterror('danger')
          }
        }
      });
    this.validacao2 = {};
  }
  ngAfterViewInit(args: any): void {
    this.gridInstance.on('data-ready', function () {
        this.dReady = true;
    });
    document.getElementById('overviewgrid').addEventListener('DOMSubtreeModified', () => {  
        if (this.stTime && this.isDataChanged) {
            let msgEle = document.getElementById('msg');
            let val: any = (performance.now() - this.stTime).toFixed(0);
            this.stTime = null;                
            this.dtTime = false;
            this.isDataChanged = false;
            msgEle.innerHTML = 'Load Time: ' + "<b>" + val + "</b>" + '<b>ms</b>';
            msgEle.classList.remove('e-hide')
       }
        })
}
valueChange(args:any): void {
this.listObj.hidePopup();	
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
     let index: number = this.listObj.value as number;         
     clearTimeout(this.clrIntervalFun2);
     this.clrIntervalFun2 = setTimeout(() => {
         this.isDataChanged = true;
         this.stTime = null;
         let contentElement: Element = this.gridInstance.contentModule.getPanel().firstChild as Element;
         contentElement.scrollLeft = 0;
         contentElement.scrollTop = 0;
         this.gridInstance.pageSettings.currentPage = 1;
         this.stTime = performance.now();
         this.gridInstance.dataSource = this.data
         this.gridInstance.hideSpinner();     
     }, 100);
}
onDataBound(args:any):void {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
}

// TABELA PRIMENG


}
