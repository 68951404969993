import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RowSelectEventArgs } from '@syncfusion/ej2-grids';
import { MenuItem } from 'primeng/api';
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';
import { QuickToolbarSettingsModel, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { LinkService, ImageService, QuickToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { HtmlEditorService, FileManagerService, FileManagerSettingsModel } from
  '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarSettingsModel } from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { InPlaceEditorComponent } from '@syncfusion/ej2-angular-inplace-editor';

interface DataItem {
  name: string;
  age: number;
  address: string;
}
@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {
  public data: Object;
  helpesk: any;
  public quickToolbarSettings: QuickToolbarSettingsModel = {
    text: ['Bold', 'Italic', 'Underline', 'FontColor', 'BackgroundColor', 'Alignments', '-', 'FontSize', 'FontName',
      'Formats', 'OrderedList', 'UnorderedList', 'FormatPainter']
  };
  public toolsx: ToolbarModule = {
    type: 'MultiRow',
    enableFloating: false
  };
 adefinir = "A definir"
  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough', 'SuperScript', 'SubScript', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'NumberFormatList', 'BulletFormatList', '|',
      'Outdent', 'Indent', '|', 'CreateLink', 'Image', 'FileManager', 'Video', 'Audio', 'CreateTable', '|', 'FormatPainter',
      'ClearFormat',
      '|', 'EmojiPicker', 'Print', '|',
      'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
  };
  public ImageUploadingEventArgs = { teste: "teste" }
  helpcoment = '';
  filtred_help = '';
  @ViewChild('rteObj')
  public rteObj: RichTextEditorComponent;
  pagamentos = false;
  nomeclienteexterno = '';
  @ViewChild('rteSubmit')
  public buttonEle: ElementRef;
  codigotikcet = '';
  public ajaxSettings: object;
  public toolbarSettings: object;
  vistorias: any[];
  vistoriaslecionada: string;
  dadosolicitante: any;
  todashomologacoes: any[];
  public hostUrl: string = "https://www.talktunnels.com/api/_functions/FileManager/";
  maxFileSize = 30000000000;
  nwestados: any[] = [];
  public datarateio: any;
  public datarateio_reg: any;
  clienteexterno = false;
  clienteinterno = false;

  @ViewChild('dateTimePickerEle')
  public dateTimePickerObj: InPlaceEditorComponent;
  
  @ViewChild('rteCancel')
  public cancelEle: ElementRef;
  meunome = ''
  dataxs = [{ valido: '' }];
  counttickets = 0;
  ticket = [];
  abreticket = false;
  mostratickets = false;
  tabletickets = true;
  searchValue = '';
  visible = false;
  items: MenuItem[];
  id = '';
  clienteselecionado = [{
    codhomol: "",
    endereco: "",
    name: ""
  }]
  filtred_ticket: any;
  filtred_homol: any;
  listOfData: DataItem[] = [
    {
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
    {
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park'
    },
    {
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park'
    },
    {
      name: 'Jim Red',
      age: 32,
      address: 'London No. 2 Lake Park'
    }
  ];
  public filterSettings: Object;

  newticket = false;
  public filter: Object;
  filteredEstados: any[];
  filteredVistorias: any[] | undefined;
  tipos_ticket = [];
  titulo_ticket = '';
  tipo_ticket = [];
  descricaox = '';
  toolbar = ['Search'];

  public insertImageSettings: object = {
    saveFormat: "Base64",
    //saveUrl: this.hostUrl + 'apm_ticket',
    //path: 'this.filtred_ticket.homol'
  };
  // saveFormat: "base64"
  public fileManagerSettings: FileManagerSettingsModel = {
    enable: true,
    path: '/Pictures/Food',
    ajaxSettings: {
      url: this.hostUrl + 'api/FileManager/FileOperations',
      getImageUrl: this.hostUrl + 'api/FileManager/GetImage',
      uploadUrl: this.hostUrl + 'api/FileManager/Upload',
      downloadUrl: this.hostUrl + 'api/FileManager/Download'
    }
  };
  constructor(private service: HomologacoesserviceService) { }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  public onRowSelected(args: RowSelectEventArgs): void {
    console.log(args)

  }

  search(): void {
    this.visible = false;
  }
  filesData(a) {
  }

  public submitClick = (): void => {
    let empCount = 0;
    const answerElement: HTMLElement = this.rteObj.contentModule.getEditPanel() as HTMLElement;
    this.rteObj.insertImageSettings.saveFormat = 'Base64';
    const comment: string = answerElement.innerHTML;
    const empList: string[] = ['emp1', 'emp2', 'emp3'];

    this.helpcoment = comment
    console.log(comment)
    const nameListList: string[] = ['Anne Dodsworth', 'Janet Leverling', 'Laura Callahan'];
    this.inserthelp()
    this.enviou_msg_ticket()
  }

  cancelClick = (): void => {
    this.rteObj.refresh();
    this.rteObj.value = '';
  }

  checkticket(clienteselecionado) {
    console.log('clienteselecionado')
    console.log(clienteselecionado)
    this.service.counttickets({ ID: clienteselecionado['codhomol'] }).subscribe((data) => {
      if (data) {
        console.log(data)
        if (data.count == 0) {
          this.counttickets = data.count
        }
        else if (data.count > 0) {
          this.counttickets = data.count + 1
        }
        console.log(this.counttickets)
      }
    })
  }

  novoticket_externo() {
     this.codigotikcet =   (this.clienteselecionado['codhomol']).replace('HL', 'TK') + '-' + this.counttickets
    const d = new Date(Date.now()).toLocaleString("pt-BR");
    this.service
      .insertticket({
        token: localStorage.getItem("tokenx"),
        ticket: this.codigotikcet,
        assunto: this.titulo_ticket,
        criadoem: new Date(Date.now()),
        alteradoem: "",
        status: "Pendente",
        idempresa: this.id,
        cliente: this.nomeclienteexterno,
        homol: this.clienteselecionado['codhomol'],
        detail: this.descricaox,
        custo: 0,
        empresa: this.dadosolicitante.nick.toUpperCase(),
        tipo: this.tipo_ticket['name']
      })
      .subscribe((data) => {
        if (data) {
          this.dataxs = data;
          //console.log(this.dataxs)
          if (this.dataxs['valido'] === "atualizado") {
            if (this.tipo_ticket['name'] == 'Rateio') {
              console.log("é rateio ")
              this.uparatex();
              this.enviou_rateio_ticket(this.codigotikcet)
            }
  
            else if (this.tipo_ticket['name'] !== 'Rateio') {
              this.enviouticket(this.codigotikcet)
            }
            this.criasfold();
            //this.enviouticket(this.codigotikcet)
            this.helpcoment = ""
            this.titulo_ticket ='';
            this.descricaox = '';
            this.datarateio = [];
            this.nomeclienteexterno = '';
            this.clienteexterno = false;
            this.clienteinterno = false;
            this.newticket = false;
          }
        }
      });
  }

  novoticket() {
    const d = new Date(Date.now()).toLocaleString("pt-BR");
    this.service
      .insertticket({
        token: localStorage.getItem("tokenx"),
        ticket: (this.clienteselecionado['codhomol']).replace('HL', 'TK') + '-' + this.counttickets,
        assunto: this.titulo_ticket,
        criadoem: new Date(Date.now()),
        alteradoem: "",
        status: "Pendente",
        idempresa: this.id,
        cliente: this.clienteselecionado['name'],
        homol: this.clienteselecionado['codhomol'],
        detail: this.descricaox,
        custo: 0,
        empresa: this.dadosolicitante.nick.toUpperCase(), 
        tipo: this.tipo_ticket['name']
      })
      .subscribe((data) => {
        if (data) {
          this.dataxs = data;
          console.log(this.dataxs)
          if (this.dataxs['valido'] === "atualizado") {

            if (this.tipo_ticket['name'] == 'Rateio') {
              this.uparatex();
              this.enviou_rateio_ticket((this.clienteselecionado['codhomol']).replace('HL', 'TK') + '-' + this.counttickets)
            }
            else if (this.tipo_ticket['name'] !== 'Rateio') {
              this.enviouticket((this.clienteselecionado['codhomol']).replace('HL', 'TK') + '-' + this.counttickets)
            }

            this.criasfold();
            
            this.helpcoment = ""
            this.titulo_ticket ='';
            this.descricaox = '';
            this.datarateio = [];
            this.nomeclienteexterno = '';
            this.clienteexterno = false;
            this.clienteinterno = false;
            this.newticket = false;
          }
        }
      });
  }
  enviouticket(tik) {
    var grupo = "553892515427-1633011809";
    this.service
      .sendtext({
        sessionName: "sollux",
        number: grupo,
        text:
          "⚠️ *Notificação automática* : O parceiro " +
          "*" +
          this.meunome +
          "*" +
          " Abriu um novo Ticket de Suporte " +
          "*" +
          tik +
          " - " +
          this.clienteselecionado['name'] +
          "*",
      })
      .subscribe((dados) => {
        if (dados) {
        }
      });
  }


  enviou_rateio_ticket(tik) {
    var grupo = "553892515427-1633011668";
    this.service
      .sendtext({
        sessionName: "sollux",
        number: grupo,
        text:
          "⚠️ *Notificação automática* : O parceiro " +
          "*" +
          this.meunome +
          "*" +
          " Abriu um novo Ticket de *Rateio* " +
          "*" +
          tik +
          " - " +
          this.clienteselecionado['name'] +
          "*",
      })
      .subscribe((dados) => {
        if (dados) {
        }
      });
  }
  


  enviou_msg_ticket() {
    var grupo = "553892515427-1633011809";
    this.service
      .sendtext({
        sessionName: "sollux",
        number: grupo,
        text:
          "⚠️ *Notificação automática* : O parceiro " +
          "*" +
          this.meunome +
          "*" +
          " Enviou mensagem no Ticket" +
          " *" +
          this.filtred_ticket.ticket +
          " - " +
          this.filtred_ticket.cliente +
          "*",
      })
      .subscribe((dados) => {
        if (dados) {
        }
      });
  }


  enviou_file_ticket() {
    var grupo = "553892515427-1633011809";
    this.service
      .sendtext({
        sessionName: "sollux",
        number: grupo,
        text:
          "⚠️ *Notificação automática* : O parceiro " +
          "*" +
          this.meunome +
          "*" +
          " Enviou arquivos no Ticket" +
          " *" +
          this.filtred_ticket.ticket +
          " - " +
          this.filtred_ticket.cliente +
          "*",
      })
      .subscribe((dados) => {
        if (dados) {
        }
      });
  }

  inserthelp() {
    // Supondo que filtred_help seja uma string
    let filtred_help = "HL10000000-1";

    // Convertendo a string para um array
    let filtred_helpArray = filtred_help.split('');

    // Substituindo o último caractere pelo valor de this.helpesk.length
    filtred_helpArray[filtred_helpArray.length - 1] = this.helpesk.length.toString();

    // Juntando os elementos do array novamente em uma string
    let novaString = filtred_helpArray.join('');

    const d = new Date(Date.now()).toLocaleString("pt-BR");
    this.service
      .helpmsg_ticket({
        token: localStorage.getItem("tokenx"),
        name: this.meunome,
        homol: this.filtred_help,
        data: new Date(Date.now()),
        observa: this.helpcoment,
        pic: '',
        id: this.filtred_help + "-" + this.helpesk.length
      })
      .subscribe((data) => {
        if (data) {
          this.helpcoment = ""
          this.gethelpmsg(this.filtred_help)
        }
      });
  }
  fileOpen(args) {
    //You can get the double clicked file/folder details in this event argument. Check the arguments below.

    // cancel - If you want to cancel this event then, set cancel to true. Otherwise, false.
    // fileDetails - Return the currently selected item as JSON object.
    // module - Returns the name of the target module in file manager.

    //You can alse check whether the clicked item is a file or not using the fileDetails isFile property.
    if (!args.fileDetails.isFile) {
      //args.cancel = true

    }
  }

  toolbarClicks(args) {
    if (args.item.text == "Custom") {
      // Get the selected Files details.
      //var selectedFiles = fileManager.getSelectedFiles()[0];
      // Get the path of FileManager component.
      //var path = selectedFiles.filterPath + selectedFiles.name;
      //console.log(path)
    }
  }
  OnbeforeSend(args) {
    //console.log(args)
    if (args.action === "Upload") {
      //this.filera = true
      //this.enviouarquivo();
      //this.postaemail();
      //this.notificaarqu();
      var data = JSON.parse(args.ajaxSettings.data);
      // Add custom parameter userid
      data.push({ "path2": this.filtred_ticket.homol + '/TICKET/' + this.filtred_ticket.ticket + '/' });
      data.push({ "myid": this.id });
      data.push({ "folders": this.filtred_ticket.homol + '/TICKET/' + this.filtred_ticket.ticket + '/' });

      // Add custom parameter in ajax settings
      args.ajaxSettings.data = JSON.stringify(data);
      this.enviou_file_ticket();
    }
    if (args.action === "delete") {
      //this.enviouarquivod();
      //this.postaemaild()
    }
    var data = JSON.parse(args.ajaxSettings.data);
    // Add custom parameter userid
    data["path2"] = this.filtred_ticket.homol + '/TICKET/' + this.filtred_ticket.ticket + '/';
    data["myid"] = this.id;
    args.ajaxSettings.data = JSON.stringify(data);

  }

  puxatickets() {
    this.service.myClitickes({ ID: this.id })
      .subscribe((data: any[]) => {
        if (data !== this.vistorias) {
          this.vistorias = data;
        }
      });
  }

  filterCountry2(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter

    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.vistorias.length; i++) {
      let estadox = this.vistorias[i]; if
        (estadox['Cliente'].toLowerCase().indexOf(query.toLowerCase()) == 0) { filtered.push(estadox); }
    }
    this.filteredEstados = filtered;
  } filterCountry(event) {
    let filtered: any[] = []; let query = event.query; for (let i = 0; i
      < (this.vistorias as any[]).length; i++) {
        let country = (this.vistorias as any[])[i]; if
          (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) { filtered.push(country); }
    }
    this.filteredVistorias = filtered;
  } filtravistoria() {
    if (this.vistoriaslecionada === undefined) { } else {
      this.service.filtravisto({ ID: this.vistoriaslecionada }).subscribe((data) => {
        if (data) {
          //this.filtredvisto = data;
          //this.solicitou = true;
          //this.aviso = true;
        }
      });
    }
  }

  setaclienteexterno() {
    if (this.clienteexterno == true) {
      var datanagora = (((Date.now()) / 1000).toFixed(0)).toString()
      this.clienteselecionado['name'] = this.nomeclienteexterno
      this.clienteselecionado['codhomol'] = 'EX' + datanagora;
    }
    console.log(this.clienteselecionado)
  }

  adicionarateio() {
    console.log(this.clienteselecionado['codhomol'])
    if (this.datarateio.length == 0) {
      var idx = 0
    } else {
      idx = this.datarateio[this.datarateio.length - 1].idx + 1
    }
    this.datarateio.push({
      endereco: "",
      homol: this.clienteselecionado['codhomol'],
      idempresa: this.id,
      idx: idx,
      percentual: 0,
      saldo: 0,
      titular: null,
      uc: "",
    })
    console.log(this.datarateio);
  }
  removerateio() {
    this.datarateio.pop()
  }
  uparatex() {
    //this.spinner.show();
    var indice = 0
    //this.rateiorend = false
    for (let i = 0; i < this.datarateio.length; i++) {
      indice = i; this.service.uparateio({
        token:
          localStorage.getItem("tokenx"), lista: this.datarateio[i], locais: {
            id: this.id, homol:
              this.clienteselecionado['codhomol'], idx: this.datarateio[i]["idx"],
          },
      }).subscribe((dados) => {
        if (dados) {
        }
      });
    }
    if (indice === this.datarateio.length - 1) {
      this.datarateio = []
      //this.enviourateio()
      //this.swrateio()
      //this.postaswrateio()
      //this.showToast2("success");
      //this.spinner.hide();
      //this.filtrarateio()
    }
  }
  deletarateio(idx: number): void {
    console.log(this.datarateio);
    console.log(idx);

    const indexToDelete = this.datarateio.findIndex(item => item.idx === idx);

    if (indexToDelete !== -1) {
      // Remove 1 elemento na posição indexToDelete
      this.datarateio.splice(indexToDelete, 1);

      // O array agora não possui mais o elemento com idx igual a idx
      console.log(this.datarateio);
    } else {
      console.error('Elemento com idx igual a', idx, 'não encontrado.');
    }
  }

  filtrarateio() {
    this.service
      .filtrarateio({ idempresa: this.id, homol: this.filtred_ticket.homol })
      .subscribe((data) => {
        this.datarateio_reg = data;
        //this.menumuda(4);
      });
  }

  ngOnInit(): void {
    this.datarateio = []
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    this.tipos_ticket = [
      { name: 'Rateio', code: 'Rateio' },
      { name: 'Fatura Incorreta', code: 'Fatura Incorreta' },
      { name: 'Troca de Titularidade', code: 'Troca de Titularidade' },
      { name: 'Aumento de Carga', code: 'Aumento de Carga' },
      { name: 'Reclamação', code: 'Reclamação' },
      { name: 'Outros', code: 'Outros' }
    ];

    this.toolbarSettings = {
      items: ['NewFolder', 'Upload', 'Cut', 'Copy', 'Paste', 'Delete', 'Download', 'Rename',
        'SortBy', 'Refresh', 'Selection', 'View', 'Details'], visible: true
    }
    this.service.currentNome.subscribe((dados) => {
      if (dados !== this.meunome && dados !== "") {
        this.meunome = dados;
      }
    });

    this.service.currentid.subscribe((dados) => {
      if (dados !== this.id && dados !== "") {
        this.id = dados;
        this.puxa_tickets()
      }
    });
    this.puxatickets()
    this.items = [
      {
        label: 'Tickets',
        icon: 'pi pi-list',
        command: (event) => {
          this.mostratickets = false;
          this.tabletickets = true;
          this.filtred_ticket = []
          this.helpcoment = '';
          this.filtred_help = '';
          this.filtred_ticket = [];
        }
      },
      {
        label: 'Adicionar',
        icon: 'pi pi-plus',
        command: (event) => {
          this.newticket = true;
        }
      }
    ];
    this.puxasolicitante ();
  }

  puxasolicitante (){
    this.service.gsolicitante({ ID: this.id })
    .subscribe(dados => {
      if (dados) {
        this.dadosolicitante = dados;
        console.log('solicita é ')
        console.log(this.dadosolicitante.nomeparceiro1.toUpperCase())
        console.log(dados)

      }
    });
  }

  criasfold() {
    this.service
      .criafolder_ticket({
        path: '/',
        myid: this.id,
        path2: this.clienteselecionado['codhomol'],
        ticket: (this.clienteselecionado['codhomol']).replace('HL', 'TK') + '-' + this.counttickets,
        name: this.clienteselecionado['codhomol'],
        data: [{
          name: this.clienteselecionado['codhomol'],
          size: '4.00 KB',
          isFile: false,
          dateModified: '2022-05-08T13:59:26.003Z',
          dateCreated: '2022-05-08T13:59:26.003Z',
          type: '',
          filterPath: '/',
          permission: null,
          hasChild: true,
          _fm_id: 'fe_tree'
        }
        ]
      })
      .subscribe((dados) => {
        console.log()
        if (dados) {
          this.puxa_tickets()
          console.log(dados)

        }
      })
  }

  puxa_tickets() {
    // tslint:disable-next-line: prefer-for-of
    this.service
      .mytickets({ ID: this.id })
      .subscribe((data: any[]) => {
        if (data) {
          this.data = data
          console.log(this.data)
        }
      });
  }

  teste() {
    //this.rteObj.insertImageSettings.saveFormat = 'Base64';
  }
  filtra_ticket(a) {
    this.filtred_help = a;
    this.service.filtraticket({ ID: a }).subscribe((data) => {
      if (data) {
        //console.log(data)
        this.filtred_ticket = data
        console.log(this.filtred_ticket.homol + '/TICKET/' + this.filtred_ticket.ticket + '/')

        this.insertImageSettings = {
          saveFormat: "Base64",
          //saveUrl: this.hostUrl + 'apm_ticket',
          //path: this.filtred_ticket.homol
        };

        this.fileManagerSettings = {
          enable: true,
          path: this.filtred_ticket.homol,
          ajaxSettings: {
            url: this.hostUrl + 'apm_ticket',
            getImageUrl: this.hostUrl + 'GetImage',
            uploadUrl: this.hostUrl + 'Upload_ticket',
            downloadUrl: this.hostUrl + 'Download'
          }
        };

        this.filtrarateio()
        this.gethelpmsg(a);

        this.ajaxSettings = {
          path2: this.filtred_ticket.homol + '/TICKET/' + this.filtred_ticket.ticket + '/',
          url: this.hostUrl + 'apm_ticket',
          showHiddenItems: true,
          path: this.filtred_ticket.homol + '/TICKET/' + this.filtred_ticket.ticket + '/',
          ticket: this.filtred_ticket.ticket,
          folders: this.filtred_ticket.homol + '/TICKET/' + this.filtred_ticket.ticket + '/',
          getImageUrl: this.hostUrl + 'GetImage',
          uploadUrl: this.hostUrl + 'Uploadticket',
          downloadUrl: this.hostUrl + 'Download'
        };
        this.tabletickets = false;
        this.mostratickets = true;
        this.teste();
      }
    })
  }

  gethelpmsg(id) {
    this.service.gethelpmsg_ticket({
      ID: id
    })
      .subscribe(dados => {
        if (dados) {
          this.helpesk = dados
          console.log(this.helpesk)
        }
      });

  }

}