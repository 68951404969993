// Configurations
export const dropboxConfig = {
    clientId: 'z8owr25q46lk8fn',
    redirectUri: 'http://localhost:3000/auth:now',
    responseType: 'token',
    trustUrl: 'https://www.dropbox.com'
};

export const dropboxApi = {
    'filesListFolderContinue': 'https://api.dropboxapi.com/2/files/list_folder/continue',
    'filesListFolderGetLatestCursor': 'https://api.dropboxapi.com/2/files/list_folder/get_latest_cursor'
};



// Constants
export interface DbxAuth {
    accessToken?: string;
    tokenType?: string;
    uid?: string;
    accountId?: string;
    isAuth?: boolean;
}
