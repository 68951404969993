import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SidebarService } from './sidebar.service';
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';
import { Router } from '@angular/router';
// import { MenusService } from './menus.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class SidebarComponent implements OnInit {
  menus = [];
  meunome = ''
  urln = '';
  embreve = false
  @Output() myEvent = new EventEmitter();
  constructor(private service: HomologacoesserviceService,
    private router: Router,public sidebars: SidebarService) {
    this.menus = sidebars.getMenuList();
   }

  ngOnInit() {
    this.service.currentNome.subscribe((dados) => {
      if (dados !== this.meunome && dados !== "") {
        this.meunome = dados;
      }
    });

    this.service.currenturl.subscribe((dados) => {
      if (dados !== this.urln && dados !== "") {
        this.urln = dados;
      }
    });
  }

  getSideBarState() {
    return this.sidebars.getSidebarState();
  }


  closeWindow() {
  window.open('','_parent','');
  window.close();
}
  firstComponentFunction() {
  console.log('testeclica')
  this.service.onFirstComponentButtonClick();    
}   
  toggle(currentMenu) {
    console.log(currentMenu)
    if (currentMenu.embreve === true) {
      this.embreve = true
    }
    this.router.navigateByUrl(currentMenu.routerLink);
    if (currentMenu.type === 'dropdown') {
      this.menus.forEach(element => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          element.active = false;
        }
      });
    }
  }


  getState(currentMenu) {

    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

  hasBackgroundImage() {
    return this.sidebars.hasBackgroundImage;
  }
  toggleSidebar() {
    this.sidebars.setSidebarState(!this.sidebars.getSidebarState());
  }
}
