import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { FinanceiroService } from './financeiro.service';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { PrimeNGConfig } from 'primeng/api';

import {xContas} from './financeiro'
import { todosparceiros } from '../homologacoes/todosparceiros';
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';
interface DataItem {
  categoria: string;
  datatTransacao: string;
  descricao: string;
  favorecido: string;
  ide: number;
  pago: string;
  tipotransacao: string;
  valortransacao: number;
}


@Component({
  selector: 'app-financeiro',
  templateUrl: './financeiro.component.html',
  styleUrls: ['./financeiro.component.css']
})
export class FinanceiroComponent implements OnInit {
  todospar: todosparceiros[];
  searchValue = '';
  visible = false;
  statuses: any[];
  loading: boolean = false;
  activityValues: number[] = [0, 100];
  // Project Booked
  text: string;
  parceiroselecionado = '';
  financeiros = [];
  pendentes =[];
  despesa = 0;
  recebimento = 0;
  menu0 = true;
  menu1 = false;
  menu2 = false;
  menu3 = false;
  menu4 = false;
  menu5 = false;
  menu6 = false;
  despex =[];
  recebex = [];
  caixasollux = 0;
  caixajonnathan = 0;
  caixarobson = 0;
  caixasol = [];
  transferencia=[];
  i = 0;
  editId: string | null = null;
  lsi:any;
  tipolanc: string;
  descricaolan: string;
  valorlan: any;
  categorialan: string;
  datalan: any;
  contasapagar = [];
  contasareceber = [];
  favorecidolan: string;
  startValue: Date | null = null;
  parcela: number;
  homol:string;
  clientehomol:string;

  cliente:string;
  valorbol: number;
  vencimentoboleto:string;
  totalemcx : any;
  boleto: string;
  contasel: string;
  descricaosel: string;
  valcontasel: string;
  favorecidocontasel: string;
  idecontasel: any;
  contasativas = [
    { value: 'Sollux', viewValue: 'Sollux PJ' },
    { value: 'Jonnathan', viewValue: 'Jonnathan' },
    { value: 'Robson', viewValue: 'Robson' }
    ];
  //listOfDisplayData = [...this.contasapagar];
  settings = {
    columns: {
      descricao: {
        title: 'Descrição',
        filter: true
      },
      favorecido: {
        title: 'Favorecido',
        filter: true
      },
      valortransacao: {
        title: 'Valor',
        filter: true
      },
      data_f: {
        title: 'Data',
        filter: true
      }
    }
  };
  display = false;
  xdate = null;
  isEnglish = false
  debitos = [];
  cols: any[];
  exportColumns: any[];
  wpp: any;
  contaorigem: string;
  contadestino: string;
  valortransf: string;
  datatransf: string;
  @ViewChild('endDatePicker', { static: false }) endDatePicker!: NzDatePickerComponent;
  constructor(private service: FinanceiroService,
    private servico: HomologacoesserviceService,
    private dialogService: NbDialogService,
    private config: PrimeNGConfig) { 
     // this.source = new LocalDataSource(this.contasapagar); // create the source
    }

    onChange(result: Date): void {
      console.log('onChange: ', result);
      console.log(this.xdate)
    }
  abredisplay (a,b, c){
    console.log(a)
    console.log(b)
    this.homol = a;
    this.clientehomol = c
    this.parcela = b;
    this.display = true;
  }  

  encode(a){
    var bs = (btoa(a));
    var encodeurl = "u!" + bs.trimEnd().replace('/','_').replace('+','-');
    console.log(encodeurl)
    var resultUrl = "https://api.onedrive.com/v1.0/shares/" + encodeurl+ "/root/content"
    console.log(resultUrl)
    this.boleto = resultUrl
  }
  downloadMyFile(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.boleto);
    link.setAttribute('download', "Boleto Homologação " + this.homol + "-"+ this.parcela+ " Parcela");
    document.body.appendChild(link);
    link.click();
    link.remove();
}
  atualizardeb(){
    this.service.updatedeb({
      parcela:this.parcela,
      token:localStorage.getItem('tokenx'),
      homol: this.homol,
      valor: this.valorbol,
      vencimentoboleto: this.vencimentoboleto,
      boleto: this.boleto
    })
      .subscribe((data) => {
        if (data) {
          this.enviacomenta(this.homol,this.clientehomol, this.valorbol,this.vencimentoboleto)
          console.log(data)
          this.puxdebitos();
        }
      });
  }

  filtrapagamento(a,b,c,d){
    this.descricaosel = a;
    this.valcontasel = c;
    this.favorecidocontasel = b;
    this.idecontasel = d;
  }


  delconta(i) {
    console.log(i)
    console.log('apagando conta')
    this.service.delfinanceiro({ 
      token:localStorage.getItem('tokenx'),
      ID: i })
      .subscribe(dados => {
        if (dados) {
          this.financeiro();
          if (dados['status'] === 'atualizado') {
            console.log('inserido')
          }
          if (dados['status'] === 'erro') {
            console.log(' não inserido')
          }
        }
      });
  }

  pagaconta(){
    const dateinici=  new Date(Date.now()).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    console.log(this.contasel)
    var ts = (((Date.now()) / 1000).toFixed(0)).toString() 
    this.service.nwcaixa({
      operacao: 'Saida', 
      mes:0, 
      ano:0, 
      valor1:'', 
      data_i: ts, 
      data_f: dateinic2,
      vencimento:'', 
      status:'', 
      conta: this.contasel, 
      descricao:this.descricaosel, 
      categoria:'Saida', 
      juros:'', 
      total:this.valcontasel, 
      numdoc:'', 
      projeto: 'cx'+ts, 
      cliente:this.favorecidocontasel, 
      valor2:'', 
      status2:''
    })
      .subscribe((data) => {
        if (data) {
          this.saldoatual('saida')
          this.caixa();
          this.delconta(this.idecontasel)
          console.log(data)     
        }
      });
  }


  pagatransf(){
    const dateinici=  new Date(this.datatransf).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    var ts = (((Date.now()) / 1000).toFixed(0)).toString() 
    this.service.nwcaixa({
      operacao: 'Saida', 
      mes:0, 
      ano:0, 
      valor1:'', 
      data_i: ts, 
      data_f: dateinic2,
      vencimento:'', 
      status:'', 
      conta: this.contaorigem, 
      descricao:'Transferencia', 
      categoria:'Transferencia', 
      juros:'', 
      total:this.valortransf, 
      numdoc:'', 
      projeto: 'cx'+ts, 
      cliente:this.contadestino, 
      valor2:'', 
      status2:''
    })
      .subscribe((data) => {
        if (data) {
          this.saldoatualpagatransf('saida')
          this.caixa();
        }
      });
  }
  recebetransf(){
    const dateinici=  new Date(this.datatransf).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    var ts = (((Date.now()) / 1000).toFixed(0)).toString() 
    this.service.nwcaixa({
      operacao: 'Entrada', 
      mes:0, 
      ano:0, 
      valor1:'', 
      data_i: ts, 
      data_f: dateinic2,
      vencimento:'', 
      status:'', 
      conta: this.contadestino, 
      descricao:'Transferencia', 
      categoria:'Transferencia', 
      juros:'', 
      total:this.valortransf, 
      numdoc:'', 
      projeto: 'cx'+ts, 
      cliente:this.contaorigem, 
      valor2:'', 
      status2:''
    })
      .subscribe((data) => {
        if (data) {
          this.saldoatualretransf('entrada')
          console.log(data)     
        }
      });
  }

  saldoatualretransf(tipo){
    var saldoatual = 0;
    if(tipo === 'saida'){
    if (this.contadestino === 'Sollux') {
      saldoatual = this.caixasollux - parseFloat(this.valortransf)
    }
    if (this.contadestino === 'Jonnathan') {
      saldoatual = this.caixajonnathan - parseFloat(this.valortransf)
    }
    if (this.contadestino === 'Robson') {
      saldoatual = this.caixarobson - parseFloat(this.valortransf)
    }
  }
  if(tipo === 'entrada'){
    if (this.contadestino === 'Sollux') {
      saldoatual = this.caixasollux + parseFloat(this.valortransf)
    }
    if (this.contadestino === 'Jonnathan') {
      saldoatual = this.caixajonnathan + parseFloat(this.valortransf)
    }
    if (this.contadestino === 'Robson') {
      saldoatual = this.caixarobson + parseFloat(this.valortransf)
    }
  }
    const dateinici=  new Date(this.datatransf).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    var ts = (((Date.now()) / 1000).toFixed(0)).toString() 
    this.service.nwcaixa({
      operacao: 'Saldo', 
      mes:0, 
      ano:0, 
      valor1:'', 
      data_i: ts, 
      data_f: dateinic2,
      vencimento:'', 
      status:'', 
      conta: this.contadestino,  
      descricao:'Saldo em conta', 
      categoria:'Saldo', 
      juros:'', 
      total:saldoatual, 
      numdoc:'', 
      projeto: 'cx'+ts, 
      cliente:'Caixa Sollux', 
      valor2:'', 
      status2:''
    })
      .subscribe((data) => {
        if (data) {
          console.log(data)
          this.caixa()
        }
      });
  }
  saldoatualpagatransf(tipo){
    var saldoatual = 0;
    if(tipo === 'saida'){
    if (this.contaorigem === 'Sollux') {
      saldoatual = this.caixasollux - parseFloat(this.valortransf)
    }
    if (this.contaorigem === 'Jonnathan') {
      saldoatual = this.caixajonnathan - parseFloat(this.valortransf)
    }
    if (this.contaorigem === 'Robson') {
      saldoatual = this.caixarobson - parseFloat(this.valortransf)
    }
  }
  if(tipo === 'entrada'){
    if (this.contaorigem === 'Sollux') {
      saldoatual = this.caixasollux + parseFloat(this.valortransf)
    }
    if (this.contaorigem === 'Jonnathan') {
      saldoatual = this.caixajonnathan + parseFloat(this.valortransf)
    }
    if (this.contaorigem === 'Robson') {
      saldoatual = this.caixarobson + parseFloat(this.valortransf)
    }
  }
    const dateinici=  new Date(this.datatransf).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    var ts = (((Date.now()) / 1000).toFixed(0)).toString() 
    this.service.nwcaixa({
      operacao: 'Saldo', 
      mes:0, 
      ano:0, 
      valor1:'', 
      data_i: ts, 
      data_f: dateinic2,
      vencimento:'', 
      status:'', 
      conta: this.contaorigem,  
      descricao:'Saldo em conta', 
      categoria:'Saldo', 
      juros:'', 
      total:saldoatual, 
      numdoc:'', 
      projeto: 'cx'+ts, 
      cliente:'Caixa Sollux', 
      valor2:'', 
      status2:''
    })
      .subscribe((data) => {
        if (data) {
          console.log(data)
          this.caixa()
          this.recebetransf()
        }
      });
  }
  receberconta(){
    const dateinici=  new Date(Date.now()).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    console.log(this.contasel)
    var ts = (((Date.now()) / 1000).toFixed(0)).toString() 
    this.service.nwcaixa({
      operacao: 'Entrada', 
      mes:0, 
      ano:0, 
      valor1:'', 
      data_i: ts, 
      data_f: dateinic2,
      vencimento:'', 
      status:'', 
      conta: this.contasel, 
      descricao:this.descricaosel, 
      categoria:'Entrada', 
      juros:'', 
      total:this.valcontasel, 
      numdoc:'', 
      projeto: 'cx'+ts, 
      cliente:this.favorecidocontasel, 
      valor2:'', 
      status2:''
    })
      .subscribe((data) => {
        if (data) {
          this.saldoatual('entrada')
          this.caixa();
          this.delconta(this.idecontasel)
          console.log(data)     
        }
      });
  }
  saldoatual(tipo){
    var saldoatual = 0;
    if(tipo === 'saida'){
    if (this.contasel === 'Sollux') {
      saldoatual = this.caixasollux - parseFloat(this.valcontasel)
    }
    if (this.contasel === 'Jonnathan') {
      saldoatual = this.caixajonnathan - parseFloat(this.valcontasel)
    }
    if (this.contasel === 'Robson') {
      saldoatual = this.caixarobson - parseFloat(this.valcontasel)
    }
  }
  if(tipo === 'entrada'){
    if (this.contasel === 'Sollux') {
      saldoatual = this.caixasollux + parseFloat(this.valcontasel)
    }
    if (this.contasel === 'Jonnathan') {
      saldoatual = this.caixajonnathan + parseFloat(this.valcontasel)
    }
    if (this.contasel === 'Robson') {
      saldoatual = this.caixarobson + parseFloat(this.valcontasel)
    }
  }
    const dateinici=  new Date(Date.now()).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    console.log(this.contasel)
    var ts = (((Date.now()) / 1000).toFixed(0)).toString() 
    this.service.nwcaixa({
      operacao: 'Saldo', 
      mes:0, 
      ano:0, 
      valor1:'', 
      data_i: ts, 
      data_f: dateinic2,
      vencimento:'', 
      status:'', 
      conta: this.contasel,  
      descricao:'Saldo em conta', 
      categoria:'Saldo', 
      juros:'', 
      total:saldoatual, 
      numdoc:'', 
      projeto: 'cx'+ts, 
      cliente:'Caixa Sollux', 
      valor2:'', 
      status2:''
    })
      .subscribe((data) => {
        if (data) {
          console.log(data)
          this.caixa()
        }
      });
  }
    puxaparceiros() {
      this.servico.allparceiros()
        .subscribe((data: todosparceiros[]) => {
          if (data !== this.todospar) {
            this.todospar = data;
            console.log(this.todospar);
          }
        });
    }

    reset(): void {
      this.searchValue = '';
      this.search();
    }

    reset2(): void {
      this.searchValue = '';
      this.pendencias();
      this.search2();
    }
  
    search(): void {
      this.visible = false;
      this.contasapagar = this.contasapagar.filter((item: DataItem) => item.descricao.indexOf(this.searchValue) !== -1);
    }

    search2(): void {
      this.visible = false;
      this.pendentes = this.pendentes.filter((item: DataItem) => item['Cliente'].indexOf(this.searchValue) !== -1);
    }
    startEdit(id: string): void {
      this.editId = id;
    }
  
    stopEdit(): void {
      this.editId = null;
    }


    onSearch(query: string = '') {


    }
  


  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  deleteRow(id: string): void {
    console.log(id)
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'descricao', header: 'Descrição' },
      { field: 'cliente', header: 'Favorecido' },
      { field: 'Categoria', header: 'categoria' },
      { field: 'total', header: 'valor' },
      { field: 'data_f', header: 'Data' }
  ];
  this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    this.config.setTranslation({
      accept: 'Aceito',
      reject: 'Cancelar',
      startsWith: "Começa com",
      contains: "Contém",
      notContains: "Não contém",
      endsWith: "Termina com",
      equals: "Igual",
      notEquals: "Diferente",
      noFilter: "Sem filtro",
      lt: "Menor que",
      lte: "menor ou igual",
      gt: "Maior que",
      gte: "Maior ou igual",
      is: "É",
      isNot: "Não é",
      before: "Antes",
      after: "Depois",
      clear: "Limpar",
      apply: "Aplicar",
      matchAll: "Match All",
      matchAny: "Match Any",
      addRule: "Add regra",
      removeRule: "Remover regra",
      choose: "Escolher",
      upload: "Upload",
      cancel: "Cancelar",
      dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["Do","Se","Te","Qa","Qi","Sx","Sa"],
      monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
      monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      today: "Hoje",
      weekHeader: "Wk"
      //translations
  });
    this.pendencias();
    this.financeiro();
    this.caixa();
  }

  mostavalor(a){
    console.log(a)
  }

  inserelancamento() {
    console.log(this.valorlan)
    const dateinici=  new Date(this.startValue).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    console.log(dateinic2)
    this.service.novolancamento({
      token:localStorage.getItem('tokenx'),
      datatTransacao: dateinic2,
      valortransacao: parseFloat(this.valorlan),
      favorecido: this.favorecidolan,
      descricao: this.descricaolan,
      categoria: this.categorialan,
      pago: false,
      tipotransacao: this.tipolanc
    })
      .subscribe((data) => {
        if (data) {
          console.log(data)
          this.financeiro();
          this.caixa();
          
        }
      });
  }

  atualizatransfer() {
    this.service.updatetransfer({
      codhomol: this.transferencia[0].codhomol,
      art: 'true',
    })
      .subscribe((data) => {
        if (data) {
          console.log(data)
          this.pendencias();
        }
      });
  }
  puxdebitos() {
    this.service.meusdebitos({ ID: this.parceiroselecionado })
      .subscribe((data) => {
        if (data) {
          this.debitos = data;
          this.filtrawpp()
          console.log(this.debitos);
        }
      });
  }
  financeiro() {
    this.financeiros = [];
    this.service.finan()
      .subscribe((data: any[]) => {
        if (data) {
          this.financeiros = data;
          for (let i = 0; i < this.financeiros.length; i++) {
            if(this.financeiros[i].tipotransacao === 'Saida'){
              this.despex.push(this.financeiros[i])
            }
            else if(this.financeiros[i].tipotransacao === 'Entrada'){
              this.recebex.push(this.financeiros[i])
            }
          }
            this.calculos()
        }
      });
  }

  caixa() {
    this.caixasol=[]
    this.service.caixasolux()
      .subscribe((data: any[]) => {
        if (data) {
          this.caixasol = data;
          console.log(this.caixasol)
          for (let i = 0; i < this.caixasol.length; i++) {
            if (this.caixasol[i]['conta'] === 'Sollux' && this.caixasol[i]['operacao']==='Saldo') {
              this.caixasollux = parseFloat(this.caixasol[i]['total'])

            }
            if (this.caixasol[i]['conta'] === 'Jonnathan' && this.caixasol[i]['operacao']==='Saldo') {
              this.caixajonnathan = parseFloat(this.caixasol[i]['total'])

            }
            if (this.caixasol[i]['conta'] === 'Robson'&& this.caixasol[i]['operacao']==='Saldo') {
              this.caixarobson = parseFloat(this.caixasol[i]['total'])

            }
          }
          this.totalemcx = (Math.round((this.caixasollux + this.caixajonnathan + this.caixarobson) * 100) / 100).toFixed(2);

        }
      });
  }

  

  filtrawpp() {
    this.servico.getwpp({ id: this.parceiroselecionado })
      .subscribe(dados => {
        if (dados) {
          this.wpp = dados;
          console.log(this.wpp.wpp)
        }
      });

  }

  enviacomenta(a,b,c,d){
    var grupo = '553892515427-1612924425'
    var fones = ['5538992515427','5538991437790','553899666526','5538988271752']
    //for (let i = 0; i < fones.length; i++) {
    this.servico.sendtext({
      sessionName: 'sollux',
      number: this.wpp.wpp ,
      text: '⚠️ Foi anexado um boleto para pagento da homologação ' + '*'+ a + ' - ' + b + '*' + ' com valor de R$' + ' *'+  c + '*'+  ' e vencimento em ' + '*'+  d+  '*'})
      .subscribe(dados => {
        if (dados) {
          console.log(dados);
        }
      });
      // }
  }
  transfere(){
    for (let i = 0; i < 2; i++) {
      if(i===0){
        if(this.transferencia[0].pagamentoentrada !== 'Pago'){
          const dateinici=  new Date(this.transferencia[0].data).toLocaleString('pt-BR')
          const dateinic2 = dateinici.split(' ')[0];
          console.log(dateinic2)
        this.service.novolancamento({
          datatTransacao: dateinic2,
          valortransacao: this.transferencia[0].valor,
          favorecido: this.transferencia[0].Cliente,
          descricao: this.transferencia[0].codhomol+' - 1ºP',
          categoria: 'Homologação',
          pago: false,
          tipotransacao: 'Entrada'
        })
          .subscribe((data) => {
            if (data) {
              console.log(data)
              this.financeiro();
              this.atualizatransfer()
            }
          });
      }}
      if(i===1){
        if(this.transferencia[1].pagamentosegundaparte !== 'Pago'){
          const dateinici4=  new Date(this.transferencia[0].data).toLocaleString('pt-BR')
          const dateinic3 = dateinici4.split(' ')[0];
        this.service.novolancamento({
          datatTransacao: dateinic3,
          valortransacao: this.transferencia[1].valor,
          favorecido: this.transferencia[1].Cliente,
          descricao: this.transferencia[1].codhomol+' - 2ºP',
          categoria: 'Homologação',
          pago: false,
          tipotransacao: 'Entrada'
        })
          .subscribe((data) => {
            if (data) {
              console.log(data)
              this.financeiro();
              this.atualizatransfer()
            }
          });
      }}
}
}

  transfer(x, e,c) {
    this.parceiroselecionado = c;
    console.log(this.parceiroselecionado);
    this.transferencia =[];
    console.log(x);
    for (let i = 0; i < 2; i++) {
      if(i===0){
        this.transferencia.push(
     {
      Cliente: e.Cliente,
      codhomol: e.codhomol,
      data: e.data,
      pagamentoentrada: e.pagamentoentrada,
      pot: e.pot,
      responsavel: e.responsavel,
      solicitante: e.solicitante,
      transferido: e.transferido,
      valor: 0

    })
      }
      
      if(i===1){
        this.transferencia.push(
          {
           Cliente: e.Cliente,
           codhomol: e.codhomol,
           data: e.data,
           pagamentosegundaparte: e.pagamentosegundaparte,
           pot: e.pot,
           responsavel: e.responsavel,
           solicitante: e.solicitante,
           transferido: e.transferido,
           valor: 0
         })
      }
    }
      
    console.log(this.transferencia)
  }
  pendencias() {
    this.service.paghomol()
      .subscribe((data: any[]) => {
        if (data) {
          this.pendentes = data;
          console.log(this.pendentes)
        }
      });
  }



  menumuda(a) {
    console.log(a)
    if (a === 0) {
      this.menu0 = true;
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false;
      this.menu5 = false;
      this.menu6 = false;
    }
    if (a === 5) {
      this.menu0 = false;
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = true
      this.menu6 = false;

    }
    if (a === 1) {
      this.menu0 = false;
      this.menu1 = true;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = false;
      this.menu6 = false;
    

      for (let i = 0; i < this.contasapagar.length; i++) {
        var datasx = (this.contasapagar[i]['datatTransacao'].slice(0,4))+'/'+(this.contasapagar[i]['datatTransacao'].slice(5,7))+'/'+(this.contasapagar[i]['datatTransacao'].slice(8,10))
        var unixdate = (new Date(datasx +" 00:00:00")).getTime() / 1000;
        this.lsi = unixdate
        console.log(this.lsi);
                //var unixtimestamp = (((this.financeiros[i]['datatTransacao']).replace('-','/'))).getTime() / 1000;
      }
    }
    if (a === 2) {
      this. puxaparceiros();
      this.menu0 = false;
      this.menu1 = false;
      this.menu2 = true;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = false;
      this.menu6 = false;
    }
    if (a === 3) {
      this.menu0 = false;
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = true;
      this.menu4 = false
      this.menu5 = false;
      this.menu6 = false;
    }
    if (a === 4) {
      this.menu0 = false;
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = true
      this.menu5 = false;
      this.menu6 = false;
    }
    if (a === 6) {
      this.menu0 = false;
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = false;
      this.menu6 = true;
    }
  }
  calculos() { 
    this.despesa = 0;
    this.contasapagar = [];
    this.recebimento= 0;
    this.contasareceber = [];
    for (let i = 0; i < this.financeiros.length; i++) {
      if (this.financeiros[i]['tipotransacao'] === 'Saida') {
        this.contasapagar.push(this.financeiros[i])
        this.despesa = (this.despesa + this.financeiros[i]['valortransacao'])
        console.log(this.contasapagar)
      }
      if (this.financeiros[i]['tipotransacao'] === 'Entrada') {
        this.recebimento = parseFloat((this.recebimento + parseFloat(this.financeiros[i]['valortransacao'])).toFixed(2))
        this.contasareceber.push(this.financeiros[i])
      }
    }
    
  }


  

exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.caixasol);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "products");
    });
}

saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}
}