<p-dialog [(visible)]="naopermitido">
  <nz-result nzStatus="warning" nzTitle="Operação não permitida"> </nz-result>
</p-dialog>



<p-dialog [(visible)]="errro">
  <nz-result nzStatus="warning" nzTitle="Tarefa não encontrada"> </nz-result>
</p-dialog>

<p-dialog [style]="{ width: '70vw' }" [(visible)]="filtros">
  <nb-card>
    <nb-card-body>
      <div class="p-field row">
        <div class="col-md-12">
          <select (change)="filtrex = $event.target.value" style="font-size: smaller" id="ressp" class="form-control">
            <option style="font-size: small">
              Selecione um tipo de filtro
            </option>
            <option value="1" style="font-size: small">Por Responsável</option>
            <option value="2" style="font-size: small">Por homologação</option>
            <option value="3" style="font-size: small">Por data</option>
            <option value="4" style="font-size: small">Filtrar tarefa</option>
            <option value="5" style="font-size: small">
              Puxar todas minhas tarefas
            </option>
          </select>
        </div>
      </div>
      <div *ngIf="!naopermitido && filtrex === '1'" class="form-group row">
        <label style="font-size: small" for="ressp" class="col-md-2 col-form-label text-md-left">
          <b>Responsável</b></label>
        <div class="col-md-4">
          <select (change)="filtrorespo = $event.target.value" (change)="
              mostra1 = true; mostra2 = false; mostra3 = false; mostra4 = false
            " style="font-size: smaller" id="ressp" class="form-control">
            <option style="font-size: small">Selecione...</option>
            <option style="font-size: small">Erick Peixoto</option>
            <option style="font-size: small">Francine Pereira</option>
            <option style="font-size: small">Iago Oliveira</option>
            <option style="font-size: small">Robson Resena</option>
            <option style="font-size: small">Jonnathan Lopes</option>
          </select>
        </div>
        <div class="col-md-6"></div>
      </div>
      <div *ngIf="filtrex === '2'" class="form-group row">
        <label style="font-size: small" for="ressp" class="col-md-2 col-form-label text-md-left">
          <b>Por homologaçáo:</b></label>
        <div class="col-md-4">
          <select (change)="
              todasmy = $event.target.value;
              allmylist();
              mostra1 = false;
              mostra2 = false;
              mostra3 = false;
              mostra4 = false
            " placeholder="Selecione uma opçáo" style="font-size: smaller" id="ressp" class="form-control">
            <option style="font-size: small">Selecione...</option>
            <option style="font-size: small">Erick Peixoto</option>
            <option style="font-size: small">Francine Pereira</option>
            <option style="font-size: small">Iago Oliveira</option>
            <option style="font-size: small">Robson Resena</option>
            <option style="font-size: small">Jonnathan Lopes</option>
          </select>
        </div>
        <div class="col-md-4">
          <select (change)="
              filtronyhomol = $event.target.value;
              mostra1 = false;
              mostra2 = true;
              mostra3 = false;
              mostra4 = false
            " placeholder="Selecione uma opçáo" style="font-size: smaller" id="ressp" class="form-control">
            <option style="font-size: small">Selecione...</option>
            <option style="font-size: small" *ngFor="let item of allmy">
              {{ item.nomecli }}
            </option>
          </select>
        </div>
      </div>
      <div *ngIf="filtrex === '3'" class="form-group row">
        <label style="font-size: small" for="ressp" class="col-md-2 col-form-label text-md-left">
          <b>Por Data:</b></label>
        <div class="col-md-4">
          <select (change)="
              todasdata = $event.target.value;
              mostra1 = false;
              mostra2 = false;
              mostra3 = false;
              mostra4 = true
            " placeholder="Selecione uma opçáo" style="font-size: smaller" id="ressp" class="form-control">
            <option style="font-size: small">Selecione...</option>
            <option style="font-size: small">Erick Peixoto</option>
            <option style="font-size: small">Francine Pereira</option>
            <option style="font-size: small">Iago Oliveira</option>
            <option style="font-size: small">Robson Resena</option>
            <option style="font-size: small">Jonnathan Lopes</option>
          </select>
        </div>
        <div class="col-md-2">
          <input (change)="data1 = $event.target.value" mask="00/00/0000" placeholder="Data inicial"
            style="font-size: small" type="text" class="form-control" />
        </div>
        <div class="col-md-2">
          <input (change)="data2 = $event.target.value" mask="00/00/0000" placeholder="Data final"
            style="font-size: small" type="text" class="form-control" />
        </div>
      </div>
      <div *ngIf="filtrex === '4'" class="form-group row">
        <label style="font-size: small" for="ressp" class="col-md-2 col-form-label text-md-left">
          <b>Buscar tarefa</b></label>
        <div class="col-md-4">
          <input placeholder="HL1617310600-01" style="font-size: small" (change)="tarefabus = $event.target.value"
            type="text" class="form-control" />
        </div>
        <div class="col-md-3"></div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="float-md-right">
        <button *ngIf="mostra1 || filtrex === '1'" (click)="buscartarefas()" style="margin-right: 10px" nbButton>
          Filtrar
        </button>
        <button *ngIf="mostra1 || filtrex === '1'" (click)="atrasos()" nbButton>
          Buscar Atrasos
        </button>
        <button *ngIf="mostra2 || filtrex === '2'" (click)="buscarporhomol()" nbButton>
          Filtrar homologaçáo
        </button>
        <button *ngIf="mostra4 || filtrex === '3'" (click)="bydate()" nbButton>
          Puxar por datas
        </button>
        <button *ngIf="mostra3 || filtrex === '4'" (click)="buscart()" nbButton>
          Filtrar tarefa
        </button>
        <button *ngIf="filtrex === '5'" (click)="todasminhas()" nbButton>
          Puxar todas
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</p-dialog>

<div *ngIf="calendar">
  <app-calendario></app-calendario>
</div>

<div *ngIf="!calendar" class="control-section">
  <div class="content-wrapper">


    <div >
      <div class="flex justify-content-start flex-wrap card-container ">
        <div class="flex align-items-center justify-content-center m-1">  <div style="  background: transparent;
          color: black;
          margin-right: 5px;
          line-height: 1.1;
          font-size: 13px;
          border-radius: 3px;
          padding: 4px;
          width: 40px;
          left: 12px;" class="e-card-tag-field e-tooltip-text">           
            <span  style="margin-top: 3px;font-size: 11px !important; margin-left: 3px;"
            class="font-bold line-height-1 text-black-500">Legenda
          </span>
        </div></div>
          <div class="flex align-items-center justify-content-center m-2">  
            <div style="  background: red;
            color: white;
            margin-right: 5px;
            line-height: 1.1;
            font-size: 13px;
            border-radius: 3px;
            padding: 4px;
            width: 80px;
            left: 12px;" class="e-card-tag-field e-tooltip-text">           
              <span  style="margin-top: 3px;font-size: 11px !important; margin-left: 3px;"
              class="font-bold line-height-1 text-black-500">Pendente
            </span>
          </div></div>

          <div class="flex align-items-center justify-content-center m-2">   <div style="  background: blue;
            color: white;
            margin-right: 5px;
            line-height: 1.1;
            font-size: 13px;
            border-radius: 3px;
            padding: 4px;
            width: 80px;
            left: 12px;" class="e-card-tag-field e-tooltip-text">           
              <span  style="margin-top: 3px;font-size: 11px !important; margin-left: 3px;"
              class="font-bold line-height-1 text-black-500">Executando
            </span>
          </div></div>

          <div class="flex align-items-center justify-content-center m-2">   <div style="  background: rgb(226, 226, 10);
            color: black;
            margin-right: 5px;
            line-height: 1.1;
            font-size: 13px;
            border-radius: 3px;
            border: 1px;
            padding: 4px;
            width: 80px;
            left: 12px;" class="e-card-tag-field e-tooltip-text">           
              <span  style="margin-top: 3px;font-size: 11px !important; margin-left: 3px;"
              class="font-bold line-height-1 text-black-500">Parada
            </span>
          </div></div>

          <div class="flex align-items-center justify-content-center m-2">   <div style="  background: green;
            color: white;
            margin-right: 5px;
            line-height: 1.1;
            font-size: 13px;
            border-radius: 3px;
            border: 1px;
            padding: 4px;
            width: 80px;
            left: 12px;" class="e-card-tag-field e-tooltip-text">           
              <span  style="margin-top: 3px;font-size: 11px !important; margin-left: 3px;"
              class="font-bold line-height-1 text-black-500">Concluídas
            </span>
          </div></div>
          <div class="flex align-items-center justify-content-center ">  <div style="  background: transparent;
            color: black;
            margin-right: 5px;
            line-height: 1.1;
            font-size: 13px;
            border-radius: 3px;
            padding: 4px;
            width: 70px;
            left: 12px;" class="e-card-tag-field e-tooltip-text">           
              <span  style="margin-top: 3px;font-size: 11px !important; margin-left: 3px;"
              class="font-bold line-height-1 text-black-500">Filtrar por
            </span>
          </div></div>
          <div class="flex align-items-center justify-content-center"> 
            <nz-range-picker  [nzFormat]="dateFormat" [(ngModel)]="datefilter" (ngModelChange)="onChange($event)" style="font-size: 10px;" ></nz-range-picker>
          </div>
          <div class="flex align-items-center justify-content-center ml-2 mr-2"> 
            <a (click)="filtroa()" class="cursor-pointer text-500 transition-colors transition-duration-150 hover:text-700">
              <i *ngIf="!filterlink" class="fas fa-link"></i>
              <i style="color: rgb(6, 6, 97);" *ngIf="filterlink" class="fas fa-link"></i>

            </a>
          </div>
          <div class="flex align-items-center justify-content-center "> 
            <nz-select  [(ngModel)]="filtroresp"  (ngModelChange)="mos();"  nzShowSearch nzAllowClear nzPlaceHolder="Filtro por Responsável" [(ngModel)]="filtroresp">
              <nz-option *ngFor="let option of employers" [nzValue]="option.funcionario" [nzLabel]="option.funcionario"></nz-option>
            </nz-select>
        </div>
        <div class="flex align-items-center justify-content-center ml-1"> 
          <button (click)="relatorio=true" style="font-size: 10px; color: blue;" ejs-button cssClass="e-flat" iconCss="e-btn-sb-icons e-open-icon" [isPrimary]="true" iconPosition="Right">Relatório</button>
      </div>
      </div>
  </div>

  

 

    <ejs-kanban locale="pt-BR" #kanbanObj cssClass="kanban-overview" keyField="Tags" [dataSource]="kanbanData"
      [cardSettings]="cardSettings" [allowDragAndDrop]="allowDragAndDrop" (dragStart)='OnDragStart($event)'
      (drag)='OnDrag($event)' (dragStop)='OnDragStop($event)' (actionComplete)='OnActionComplete($event)'>
      <e-columns>
        <e-column *ngFor="let column of columns" headerText="{{ column.headerText }}" keyField="{{ column.keyField }}">
          <ng-template #template let-data>
            <div class="header-template-wrap">
              <div class="header-icon e-icons {{ data.keyField }}"></div>
              <div class="header-text">{{ data.headerText }}</div>
            </div>
          </ng-template>
        </e-column>
      </e-columns>
      <ng-template #cardSettingsTemplate let-data>
        <div  style="max-height: 190px !important;min-height: 190px !important" [class]= "data.Status" >
          <div class='e-card-header'>
            <div class='e-card-header-caption'>
              <div style="font-size: 11px;" class='e-card-header-title  e-tooltip-text'>{{data.nomecli}}</div>
            </div>
          </div>
          <div class='e-card-content e-tooltip-text'>
            <div style="font-weight: bold; color: rgb(9, 9, 173);" class='e-text'>{{data.Title}}</div>
          </div>
          <div class="row">
            <div class="col-6 pl-4">
              <span *ngIf="data.dataexe !== ''" class="font-bold line-height-0 "
                style="font-size: 10px;margin-left: 0px;color: rgb(8, 8, 8); font-weight: bold;">{{data.timelapse}} /
                {{data.Estimate}}</span>
            </div>
            <div class="col-6 pr-5">
              <nz-progress [nzPercent]="data.Priority"></nz-progress>
            </div>
          </div>
          <div class='e-card-custom-footer'>
            <div class="e-card-tag-field e-tooltip-text" *ngFor="let tag of data.Tags.split(',');">           <i class="far fa-calendar-alt"></i> 
              <span style="margin-top: 3px;font-size: 11px !important; margin-left: 3px;"
              class="font-bold line-height-1 text-black-500">{{
              data.prazo
              }}
            </span></div>
            <div class='e-card-avatar'>  
              <a (click)="executando(data.Id)" *ngIf="data.Status !== 'Executando' && meunome === data.Assignee" class="cursor-pointer text-500 transition-colors transition-duration-150 hover:text-700">
              <i style="font-size: 17px;" class="far fa-play-circle"></i></a>
              <a *ngIf="data.Status=== 'Executando' && meunome === data.Assignee" style="font-size: 17px;"><i
              style="color: green;" class="fas fa-play-circle"></i></a>
              <a class="cursor-pointer text-500 transition-colors transition-duration-150 hover:text-700" (click)="concluirx(data.Id)" *ngIf="data.Status === 'Executando' && meunome === data.Assignee"
              style="font-size: 17px;margin-left: 12px"><i class="fas fa-check-circle"></i></a>
              <a style="font-size: 17px;margin-left: 13px" (click)="filtra(data.Id)"><i class="fas fa-edit"></i></a>
            </div>
          </div>
        </div>
      </ng-template>
    </ejs-kanban>
  </div>
</div>



<p-sidebar [blockScroll]="true" [style]="{ width: '75vw' }" position="right" [(visible)]="displayx">
  <div *ngIf="editacard && sholist">
    <div style="background-color:  rgb(72, 51, 121) !important; width: 100% !important;top:0">
      <div class="p-2">
        <div style="color: white; font-weight: bold;margin-left: 20px;">
          <i style="font-size: 14px; color: white;" (click)="sholist=false" class="fas fa-arrow-circle-left"></i>
          Lista de verificação
        </div>
      </div>
    </div>
    <div class="surface-overlay  h-full col-12 xl:col-12">
      <div class="card-body">
        <div class="add-items d-flex">
          <input type="text" class="form-control todo-list-input" placeholder="Digite uma tarefa" [(ngModel)]='taskx'
            #newTodo />
          <button (click)="adresp = true" class="add btn btn-primary font-weight-bold todo-list-add-btn">
            Adicionar
          </button>
        </div>

        <div class="list-wrapper">
          <ul *ngFor="let item of todos" class="d-flex flex-column-reverse todo-list">
            <li [class]="item.class">
              <div class="form-check">
                <label style="font-size: 12px;white-space: pre-wrap;" class="form-check-label">
                  <b style="margin-right: 3px;">Sim</b>
                  <p-checkbox (click)="teste(item.id, item.na, item.resp,item.task)" [(ngModel)]="item.checked"
                    binary="true" inputId="binary1"></p-checkbox>
                  <b style="margin-right: 3px;">N/A</b>
                  <p-checkbox (click)="teste2(item.id, item.na, item.resp,item.task)" [(ngModel)]="item.na"
                    binary="true" inputId="binary"></p-checkbox>
                  {{item.task}}
                </label>
              </div>
              <i class="remove">
                <nz-avatar nzSize="small" nzIcon="user" [nzSrc]="item.picres"></nz-avatar>
              </i>
            </li>
          </ul>
        </div>

        <p-divider></p-divider>
        <nz-comment style="margin-top: 10px;">
          <nz-avatar nz-comment-avatar nzIcon="user" [nzSrc]="user.avatar"></nz-avatar>
          <nz-comment-content>
            <nz-form-item>
              <textarea [(ngModel)]="inputValue" nz-input rows="2"></textarea>
            </nz-form-item>
            <nz-form-item>
              <button (click)="novocoment()" nz-button nzType="primary" [disabled]="!inputValue">
                Adicionar Comentario
              </button>
            </nz-form-item>
          </nz-comment-content>
        </nz-comment>
        <nz-list style="margin-top: 10px;" *ngIf="mdata.length" [nzDataSource]="mdata" [nzRenderItem]="item"
          [nzItemLayout]="'horizontal'">
          <ng-template #item let-item>
            <nz-comment [nzAuthor]="item.author" [nzDatetime]="item.displayTime">
              <nz-avatar nz-comment-avatar nzIcon="user" [nzSrc]="item.pic"></nz-avatar>
              <nz-comment-content>
                <p>{{ item.content }}</p>
              </nz-comment-content>
            </nz-comment>
          </ng-template>
        </nz-list>

      </div>
    </div>
  </div>
  <div *ngIf="editacard && !sholist" style="height: 100vh;" class="row">

    <div style="padding-left: 20px !important;background-color: #2d323d; color: white; " class="  col-4 ">
      <ul class="list-none m-0 p-2">
        <li class="
            flex flex-column
            md:flex-row md:align-items-left md:justify-content-between
            mb-4
          ">
          <div class="flex">
            <span style="color: white;" class="nvfont">Criado por</span>
          </div>
        </li>


        <li class="
            flex flex-column
            md:flex-row md:align-items-left md:justify-content-between
            mb-4
          ">
          <div class="flex">

            <div class="sidebar-header">
              <div style="background-color: white" class="user-pic">
                <img style="padding: 10px;"
                  class="img-responsive img-rounded"
                  [src]="tarefafiltrada.urlcriador"
                  alt="User picture"
                />
              </div>
              <div class="user-info">
                <span style="font-size: 12px !important;color:white" class="user-name">{{ tarefafiltrada.criador }} </span>
                <span style="font-size: 12px !important;color:white" class="user-role">Criado em {{tarefafiltrada.dataini |date: "dd/MM/yyyy HH:mm" }}</span>
                <span style="font-size: 12px !important;color:white" class="user-role">Prioridade : {{tarefafiltrada.Type}}</span>
    
              </div>
            </div>

    
          </div>
        </li>

        <li class="
            flex flex-column
            md:flex-row md:align-items-left md:justify-content-between
            mb-4
          ">

          <div class="flex">
            <span style="color: white;" class="nvfont">Projeto</span>
          </div>
        </li>
        <li class="flex flex-column md:flex-row md:align-items-left  mb-3">

          <div style="  background: #ececec;
          color: black;
          margin-right: 5px;
          line-height: 1.1;
          font-size: 13px;
          border-radius: 3px;
          padding: 4px;
          left: 12px;" class="e-card-tag-field e-tooltip-text" >         
            <span style="margin-top: 3px;font-size: 11px !important; margin-left: 3px; color: black;"
            class="font-bold line-height-1 text-black-500">{{
              tarefafiltrada.ide
            }}
          </span></div>
        </li>
        <li class="
            flex flex-column
            md:flex-row md:align-items-left md:justify-content-between
            mb-4
          ">

          <div class="flex">
            <span style="color: white;" class="nvfont">Observadores</span>
          </div>
        </li>
        <li class="
            flex flex-column
            md:flex-row md:align-items-left md:justify-content-between
            mb-4
          ">
          <div class="
              flex
              align-items-start
              flex-column
              lg:flex-row lg:justify-content-between
            ">
            <div class="flex align-items-left flex-column md:flex-row">
              <p-avatarGroup *ngFor="let item of tarefafiltrada.observa" styleClass="p-mb-3">
                <p-avatar (click)="dialsair(item.name)" [image]="item.pic" size="small" shape="circle"></p-avatar>
              </p-avatarGroup>
            </div>
          </div>
        </li>
      </ul>

      <div class="row">
        <div class="col pl-4">
          <span *ngIf="tarefafiltrada.dataexe !== ''" class="font-bold line-height-1 "
            style="font-size: 11px;margin-left: 5px;color: white; font-weight: bold;">{{tarefafiltrada.timelapse}}
            / {{tarefafiltrada.Summary}}</span>

        </div>
        <div class="col pr-6">
          <nz-progress [nzPercent]="tarefafiltrada.Priority"></nz-progress>

        </div>
      </div>
      <div class="flex justify-content-center pt-0 col-12">
        <button style="
          background-color: white!important;
          color: black;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
        " (click)="gettodos()" pButton pRipple label="Lista de tarefas"
          class="p-button-outlined p-button-secondary w-12 "></button>
      </div>

      <div *ngIf="tarefafiltrada.criador === meunome || tarefafiltrada.Assignee === meunome" class="flex justify-content-center pt-0 col-12">
        <button style="
          background-color: white!important;
          color: black;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
        " (click)='adob=true' pButton pRipple label="Adicionar Observador"
          class="p-button-outlined p-button-secondary w-12 "></button>
      </div>

      <div *ngIf="tarefafiltrada.criador !== meunome || tarefafiltrada.Assignee !== meunome" class="flex justify-content-center pt-0 col-12">
        <button style="
          background-color: white!important;
          color: black;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
        " (click)="sair()" pButton pRipple label="Sair"
          class="p-button-outlined p-button-secondary w-12 "></button>
      </div>
      <div
        style="position: absolute !important; bottom: 40px !important; vertical-align: bottom !important;width: 90% !important;">
        <div class="flex justify-content-center pt-0 col-12">
          <button style="
            background-color: white!important;
            color: black;
            height: 40px;
            font-size: 14px;
            font-weight: bold;" (click)="updatestatus()" pButton pRipple label="Atualizar"
            class="p-button-outlined p-button-secondary w-12 "></button>
        </div>
      </div>

      
      <div style="margin-bottom: 7px;"></div>
    </div>
    <div class="surface-overlay col-8">
      <div class="form-row">
        <div class="form-group col-11">
          <ejs-textbox  [readonly]="tarefafiltrada.criador !== meunome" placeholder="Tarefa" cssClass="e-outline"
            floatLabelType="Always" [(ngModel)]="tarefafiltrada.Title"></ejs-textbox>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-11">
          <ejs-textbox placeholder="Cliente" cssClass="e-outline" [(ngModel)]="tarefafiltrada.nomecli"
            floatLabelType="Always"></ejs-textbox>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-11">
          <ejs-dropdownlist cssClass="e-outline" placeholder="Responsável" floatLabelType="Always"
            [dataSource]="employers" (change)="tarefafiltrada.Assignee = $event.value" [value]="tarefafiltrada.Assignee"
            [fields]="fields"></ejs-dropdownlist>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-11">
          <ejs-datetimepicker cssClass="e-outline" placeholder="Previsão" floatLabelType="Always"
            [(ngModel)]="tarefafiltrada.datafim" format='dd/MM/yyyy HH:mm' [locale]='"en-US"'></ejs-datetimepicker>

        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-11">
          <ejs-numerictextbox min="0" max="999" step="1" placeholder="Duração Máxima (minutos)" cssClass="e-outline"
            [(ngModel)]="tarefafiltrada.Estimate" floatLabelType="Always"></ejs-numerictextbox>

        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-11">
          <ejs-dropdownlist  cssClass="e-outline" placeholder="Status" floatLabelType="Always" [dataSource]="stax"
          (change)="tarefafiltrada.Status = $event.value" [value]="tarefafiltrada.Status" [fields]="fieldsx">
        </ejs-dropdownlist>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-11">
          <ejs-textbox rows="3" cssClass="e-outline" placeholder="Descrição" [(ngModel)]="tarefafiltrada.descricao"
            [multiline]="true" floatLabelType="Always"></ejs-textbox>
        </div>
      </div>


    </div>
  </div>
</p-sidebar>
<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>


<p-dialog [style]="{ width: '40vw' }" [(visible)]="adob">
  <ng-template pTemplate="header">
    Adicionar Observadores
  </ng-template>
  <div class="form-row">
    <div class="form-group col-12">
      <ejs-dropdownlist cssClass="e-outline" placeholder="Responsável" floatLabelType="Always" [value]='novob'
        (change)="novob = $event.value" [dataSource]="employers" [fields]="fields"></ejs-dropdownlist>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-left pt-0 col-12">
      <button style="
          background-color: rgb(72, 51, 121) !important;
          color: white;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
        " (click)="adoba();adob=false" pButton pRipple label="Adicionar"
        class="p-button-outlined p-button-secondary w-12 mr-2"></button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [style]="{ width: '40vw' }" [(visible)]="adresp">
  <ng-template pTemplate="header">
    Selecione um Responsável
  </ng-template>
  <div class="form-row">
    <div class="form-group col-12">
      <ejs-dropdownlist cssClass="e-outline" placeholder="Responsável" floatLabelType="Always" [value]='novoresp'
        (change)="novoresp = $event.value" [dataSource]="employers" [fields]="fields"></ejs-dropdownlist>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-left pt-0 col-12">
      <button style="
          background-color: rgb(72, 51, 121) !important;
          color: white;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
        " (click)="nvtar()" pButton pRipple label="Selecionar"
        class="p-button-outlined p-button-secondary w-12 mr-2"></button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="iniciar" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
  <div class="flex flex-column align-items-center my-4">
    <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3"
      style="width:64px;height:64px">
      <i class="pi pi-check text-5xl"></i>
    </span>
    <div class="font-medium text-2xl text-900">Iniciar Execução da tarefa</div>
  </div>
  <p class="line-height-3 p-0 m-0">
    Você confirma o inicio dessa tarefa?
  </p>
  <ng-template pTemplate="footer">
    <div class=" border-top-1 surface-border pt-3 flex">
      <button pButton pRipple icon="pi pi-times" (click)="iniciar = false" label="Cancelar"
        class="p-button-outlined w-6 mr-2"></button>
      <button pButton pRipple icon="pi pi-check" (click)="iniciar = false;mudaex()" label="Continuar"
        class="w-6 ml-2"></button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="concluir" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
  <div class="flex flex-column align-items-center my-4">
    <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3"
      style="width:64px;height:64px">
      <i class="pi pi-check text-5xl"></i>
    </span>
    <div class="font-medium text-2xl text-900">Concluir tarefa</div>
  </div>
  <p class="line-height-3 p-0 m-0">
    Você confirma a conclusão dessa tarefa?
  </p>
  <ng-template pTemplate="footer">
    <div class=" border-top-1 surface-border pt-3 flex">
      <button pButton pRipple icon="pi pi-times" (click)="concluir = false" label="Cancelar"
        class="p-button-outlined w-6 mr-2"></button>
      <button pButton pRipple icon="pi pi-check" (click)="concluir = false;mudaco()" label="Continuar"
        class="w-6 ml-2"></button>
    </div>
  </ng-template>
</p-dialog>
<p-dialog [(visible)]="sairs" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
  <div class="flex flex-column align-items-center my-4">
    <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3"
      style="width:64px;height:64px">
      <i class="pi pi-check text-5xl"></i>
    </span>
    <div class="font-medium text-2xl text-900">Remover observador da tarefa</div>
  </div>
  <p class="line-height-3 p-0 m-0">
    Deseja remover esse observador da tarefa?
  </p>
  <ng-template pTemplate="footer">
    <div class=" border-top-1 surface-border pt-3 flex">
      <button pButton pRipple icon="pi pi-times" (click)="sairs = false" label="Cancelar"
        class="p-button-outlined w-6 mr-2"></button>
      <button pButton pRipple icon="pi pi-check" (click)="sairs = false;sair2()" label="Sim" class="w-6 ml-2"></button>
    </div>
  </ng-template>
</p-dialog>


<p-dialog [(visible)]="relatorio" appendTo="body" [modal]="true"
 [style]="{width:
'70vw'}">
<ng-template pTemplate="header">
  <div class="flex align-items-center">
    <span class="flex align-items-center justify-content-center bg-cyan-100
      text-cyan-800 mr-3 border-circle"
      style="width:32px;height:32px">
      <i class="pi pi-info text-lg"></i>
    </span>
    <span class="font-medium text-2xl text-900">Emissão de relatório</span>
  </div>
</ng-template>

<div class="form-row">
  <div class="form-group col-6">
    <ejs-dropdownlist cssClass="e-outline" placeholder="Responsável" floatLabelType="Always"
      [dataSource]="employers" (change)="filtrorela = $event.value" [value]="filtrorela"
      [fields]="fields"></ejs-dropdownlist>
  </div>
</div>

<div  style="margin-bottom: 15px;margin-left: 10px; overflow: hidden;" >
  <p style="margin-top: 0px;font-size: 16px !important; margin-left: 3px;"
  class="font-bold line-height-1 text-black-500"> Incluir os seguintes status: </p>
  <div style="margin-bottom: 5px" class="row">
    <div style="margin-left: 50px;" class="col-2">
      <input  (change)="pendrel = ($event.target.checked)" type="checkbox"
        class="form-check-input" id="a1" />
      <label style="margin-top: 0px;font-size: 13px !important; margin-left: 3px;"
      class="font-bold line-height-1 text-black-500" for="a1">Pendente</label>
    </div>
    <div class="col-2">
      <input (change)="execrel = ($event.target.checked)"  type="checkbox"
        class="form-check-input" id="a2" />
      <label style="margin-top: 0px;font-size: 13px !important; margin-left: 3px;"
      class="font-bold line-height-1 text-black-500"  for="a2">Executando</label>
    </div>
    <div class="col-2">
      <input (change)="paradarel = ($event.target.checked)" type="checkbox"
        class="form-check-input" id="a3" />
      <label style="margin-top: 0px;font-size: 13px !important; margin-left: 3px;"
      class="font-bold line-height-1 text-black-500"  for="a3">Paradas</label>
    </div>
    <div class="col-1">
      <input (change)="conclurel = ($event.target.checked)" type="checkbox"
        class="form-check-input" id="a4" />
      <label style="margin-top: 0px;font-size: 13px !important; margin-left: 3px;"
      class="font-bold line-height-1 text-black-500"  for="a4">Concluídas</label>
    </div>
  </div>
  <div class="row" >
    
  <div style="margin-left: 20px;"> 
    <p style="margin-top: 0px;font-size: 16px !important; margin-left: 3px;"
    class="font-bold line-height-1 text-black-500"> Período de apuração: </p>
    <nz-range-picker style=" position: relative;
    display: inline-block;
    width: 100%;
    color: rgba(0,0,0,.85);
    font-size: 16px;
    line-height: 1.5715;
    border-radius: 2px;
    transition: all .3s;
    flex: auto;
    min-width: 1px;
    height: 50px !important;
    padding: 0;
    background: 0 0;
    border: 0;" [nzFormat]="dateFormat" [(ngModel)]="datefilter2" style="font-size: 10px;" ></nz-range-picker>
  </div>
</div>
</div>

<ng-template pTemplate="footer">
  <div class="border-top-1 surface-border pt-3">
    <button (click)="emitirrelatorio()" pButton pRipple icon="pi pi-check-circle" 
    label="Ok"></button>
    <button (click)="relatorio=false" pButton pRipple icon="pi pi-check-circle" 
    label="Sair"></button>


  </div>
</ng-template>
</p-dialog>

