import { HomologacoesserviceService } from './homologacoes/homologacoesservice.service';
import { Component, OnInit } from '@angular/core';
import { NbSidebarService, NbMenuModule, NbThemeService } from '@nebular/theme';
import { NbThemeModule, NbLayoutModule, NbSidebarModule, NbButtonModule } from '@nebular/theme';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RESP } from './homologacoes/resposta';
import { FuseSplashScreenService } from './homologacoes/splash-screen.service';
import * as $ from 'jquery';
import 'node_modules/jquery/dist/jquery.min.js';
import 'src/assets/js/menu.js';
//import { ChatService } from './chat.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { ErrorStateMatcher } from '@angular/material/core';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { SidebarService } from './sidebar/sidebar.service';

/** declare const abrechat: any;
Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
declare var pin: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  senhaformcontrol = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();
  emailrecu: string;
  chatspendentes:any;
  display = false;
  displays= false;

  display2 = false;
  cotafiltrada: string;
  room : string;
  user: string;
  numeroonlines = 0;
  meuid: string;
  k1: string;
  k2: string;
  k3: string;
  minimiza = false;
  errologin = false;
  VALID: RESP[];
  nregistrado = false;
  registrad = false;
  valido = false;
  naovalido = true;
  registrado = false;
  dados: [];
  url: string;
  filelogin: any;
  idcliente: string;
  message: string;
  nome: string;
  cargo:string;
  ID = [];
  loga = false;
  usuario: string;
  senha: string;
  paramns1 = {email: '', senha: '' };
  logou: boolean;
  mostrapin = false;
  resultado= false;
  emailinformado : string;
  trocasenha1: string;
  trocasenha2: string;
  mudsenha = false;
  codex: any;
  validacao = [
    { id: 1, number: 'Valido' },
    { id: 2, text: 'ID' },
    { id: 2, text: 'nome' },
    { id: 2, text: 'pic' }
  ];
  color = 'black';
  mode = 'indeterminate';
  value = 100;
  recept= "";
  messageText = ""
  customStyle = {
    backgroundColor: "#ffffff",
    border: "1px solid #7e7e7e",
    borderRadius: "50%",
    color: "#7e7e7e",
    cursor: "pointer"
  };
  title = 'Sollux-engenharia';
  dadosolicitante: any;
  versao = '1.0';
  escondeu = true;
  embreve = false;
  tema='0';
  online = [];
  menus = [];
  public loading = false;
  messageArray: Array<{user:String, textmsg:String, tipo:String, ts:String }> = [];
  chatselc = [];
  chatsx = [];
  constructor(private sidebarService: NbSidebarService,
              private sidebars : SidebarService,
              private themeService: NbThemeService,
              private service: HomologacoesserviceService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private _fuseSplashScreenService: FuseSplashScreenService,
              //private _chatService: ChatService

  ) {
    //this._chatService.newUserJoined()
    //.subscribe(data => this.onlines(data));


  //this._chatService.userLeftRoom()
  //  .subscribe(data => this.offlines(data));

  //this._chatService.newMessageReceived()
   // .subscribe(data => this.apresentamsg(data));
    
  }
  onlines(a) {

  }

  offlines(a){

  }
  recupera() {
    this.service.recuperasenha({ email: this.emailrecu})
      .subscribe(dados => {
        if (dados ) {
            //console.log(dados)
            this.decryptData(dados['cod'])
        }
      });
  }
  validateId(a) {
    if(this.online.length >0)
    {  var resultado = this.online.find(x => x.user === a)
   if(resultado.user){
     //console.log('true')
     return true}
    }
    else if(this.online.length === 0){
      //console.log('false')
       return false
     }
  }

  join() {
    //this._chatService.joinRoom({ user: this.user, room: this.room });
  }

  leave() {
    //this._chatService.leaveRoom({ user: this.user, room: this.room });
  }
  apresentamsg(a){
    this.chatsx.push(a)
    //console.log(a.textmsg)
  }
  sendMessage() {
    //console.log('enviado mensagem')
    //this._chatService.sendMessage(
     // { 
      //  user: this.user, 
       // recept:this.recept,
       // sender:this.meuid,
       // room: this.cotafiltrada, 
       // textmsg: this.messageText, 
       // tipo:'in', 
       // data: new Date(Date.now()).toLocaleString('pt-BR'),
       // dono: this.nome
      //});
  }
  chatx() {
    this.service.puxachat({ ID: this.cotafiltrada })
      .subscribe((data: any[]) => {
        if (data) {
          this.chatsx = data
          this.chatselc = data;
         // console.log(this.chatselc)
        }
      });
  }
  abrechat(a){
    this.cotafiltrada = a.idref
    this.recept = a.sender
    this.room = this.cotafiltrada;
    this.user =  this.nome;
    this.display2 = true;
    this.join()
    this.chatx();
    //console.log(a)
  }
  ngOnInit() {
    this.menus = [
      {
        title: 'Projetos',
        type: 'header'
      },
      {
        title: 'Seus Dados',
        icon: 'fa fa-user',
        active: false,
        routerLink:"/alteracad",
        type: 'simple',
        submenus: [
          {
            title: 'Dashboard 1',
            badge: {
              text: 'Pro ',
              class: 'badge-success'
            }
          },
          {
            title: 'Dashboard 2'
          },
          {
            title: 'Dashboard 3'
          }
        ]
      },
      {
        title: 'Suas Homologações',
        routerLink:"/homologacoes",
        icon: 'fas fa-solar-panel',
        active: false,
        type: 'simple',
        submenus: [
          {
            title: 'Products',
          },
          {
            title: 'Orders'
          },
          {
            title: 'Credit cart'
          }
        ]
      },

      {
        title: 'Suporte - Tickets',
        icon: 'fas fa-hands-helping',
        active: false,
        routerLink:"/ticket",  
        embreve: false,
        type: 'simple'
      }
      ,
      {
        title: 'Nova homologação',
        icon: 'fas fa-folder-plus',
        routerLink:"/editahomologacao",
        active: false,
        type: 'simple',
        submenus: [
          {
            title: 'General',
          },
          {
            title: 'Panels'
          },
          {
            title: 'Tables'
          },
          {
            title: 'Icons'
          },
          {
            title: 'Forms'
          }
        ]
      }, 
      {
        title: 'Gestāo',
        type: 'header'
      },

      {
        title: 'Gerador de proposta',
        icon: 'fas fa-file-alt',
        active: false,
        routerLink:"/geraproposta",
        type: 'simple',
   
      },
      {
        title: 'Gestāo de projetos',
        icon: 'fas fa-tasks',
        routerLink:"/gestaoclientes",
        active: false,
        type: 'simple'
      },
     
      {
        title: 'Cursos',
        icon: 'fas fa-laptop-code',
        active: false,
        routerLink:"/curso",
        type: 'simple',

      }
    ];
    if (this.service.subsVar==undefined) {    
      this.service.subsVar = this.service.    
      invokeFirstComponentFunction.subscribe((name:string) => {    
        this.togla();    
      });    
    }  
    this.spinner.show();
    if (localStorage.getItem('emaildatasol') !== null && localStorage.getItem('senhadatasol') !== null) {
      this.usuario = localStorage.getItem('emaildatasol');
      this.senha = localStorage.getItem('senhadatasol');
      this.onClick();
    } else{
      this.spinner.hide();
    }
//console.log('iniciou')
  }
temas(){
  if(this.tema === '0'){
    this.tema = '1'
    this.themeService.changeTheme('dark');

   } 
   else if(this.tema === '1'){
    this.tema = '0'
    this.themeService.changeTheme('default');
   } 
}
// this called only if user entered full code
onCodeCompleted(code: string) {
  //console.log(code)
  //console.log(this.codex)
  if(this.codex === code){
    this.mostrapin = false
    this.mudsenha = true;
  }
}
decryptData(data) {
  try {
    const bytes = CryptoJS.AES.decrypt(data, 'teste');
    if (bytes.toString()) {
      var dadox = (JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
      this.enviacomenta(dadox['wpp'], dadox['cod'])
      this.codex = (dadox['cod']).toString();
    }
    return data;
  } catch (e) {
    //console.log(e);
  }
}
logut(){
  localStorage.setItem('emaildatasol', null);
  localStorage.setItem('senhadatasol', null);
  localStorage.setItem('tokenx', '');
  window.location.reload();
  

}

onCodeChanged(code: string) {
}
enviacomenta(a,b){
  var grupo = '553892515427-1612924425'
  var fones = ['5538992515427','5538991437790','553899666526','5538988271752']
  //for (let i = 0; i < fones.length; i++) {
  this.service.sendtextuni({
    sessionName: 'sollux',
    number: a ,
    text: '🔒  Caro parceiro,foi solicitado um reset de senha para sua conta.\n\n Digite o seguinte código no Datasol: ' + '*'+ b + '* ' + '.\n\nCaso não tenha feito essa solicitação entre imediatamente em contato com a Sollux Engenharia!'
   })
    .subscribe(dados => {
      if (dados['status'] === 'Enviado') {
        //console.log(dados);
        this.mostrapin = true;
      }
    });
   
}
  
  
upasenha() {
  this.service.upasenha2({
    senha: this.trocasenha2,
    email: this.emailrecu,
  })
    .subscribe((data) => {
      if (data['status'] === 'atualizado') {
        this.mostrapin = false;
        this.mudsenha = false;
        this.resultado = true;

      }
    });
}
chats(){
  this.display = true;
}
  pinus(){
    if(this.escondeu === false){
      this.escondeu = true
     } 
     else if(this.escondeu === true){
      this.escondeu = false
     } 
      if ($('.page-wrapper').hasClass('pinned')) {
      // unpin sidebar when hovered
      $('.page-wrapper').removeClass('pinned');
      $('#sidebar').unbind('hover');
    } else {
      $('.page-wrapper').addClass('pinned');
      $('#sidebar').hover(
        function () {
          $('.page-wrapper').addClass('sidebar-hovered');
        },
        function () {
          $('.page-wrapper').removeClass('sidebar-hovered');
        }
      );
    }  
  }

  sox(a) {
    //console.log(a)
  }
  togla(){
    //toggle sideba
     $('.page-wrapper').toggleClass('toggled');
 }



 
  mudatema() {
    this.themeService.changeTheme('corporate');
  }

  min(){
    this.minimiza = true;
  }
  

  onClick() {
    this.loga = true;
    this.service.loginnode({ email: this.usuario, senha: this.senha })
      .subscribe(dados => {
        if (dados) {
          //console.log(dados[`token`])
          localStorage.setItem('tokenx', dados[`token`]);
          //this.push();
          this.autoriza()
        }
      });
      this.spinner.hide();
  }


  registro() {

    this.service.gsolicitante({ ID: this.ID['ID'] })
      .subscribe(dados => {
        if (dados !== this.dadosolicitante) {
          this.dadosolicitante = dados;
        }
      });
    if (this.ID['registradodata'] === 'false') {
      this.naovalido = false;
      this.registrado = true;
    }
    if (this.ID['registradodata'] === 'true') {
      this.router.navigateByUrl('/homologacoes');
      this.naovalido = false;
      this.valido = true;
    }
    localStorage.setItem('email', this.usuario);
    localStorage.setItem('senha', this.senha);
    
  }

  push() {
    if (this.ID['Valido'] === 1) {
      this.naovalido = false;
      localStorage.setItem('emaildatasol', this.usuario);
      localStorage.setItem('senhadatasol', this.senha);
      localStorage.setItem('email', this.usuario);
      localStorage.setItem('senha', this.senha);
      this.valido = true;
      this.pinus()
      this.router.navigateByUrl('/firstlogxdatasol');
      // tslint:disable-next-line: max-line-length
    } else { this.errologin = true;}
  }

  autoriza(){
    const tokenx = localStorage.getItem('tokenx');
    this.service.loginauth({
      email: this.usuario, 
      senha: this.senha
    })
    .subscribe(dados => {
      if (dados) {
        this.ID = dados;
        this.url = this.ID['pic'];
        this.nome = this.ID['funcionario'];
        this.cargo = this.ID['cargo']
        this.service.changecargo(this.ID['cargo']);
        this.service.changeid(this.ID['ID']);
        this.service.changeNome(this.ID['funcionario']);
        this.service.changeurl(this.ID['pic']);
        this.service.changewpp(this.ID['wpp']);
        this.service.mudaemail(this.usuario);
        //console.log(this.ID['wpp'])
        this.push();
      }
    });
  }
  
  registrar(dialog) {
    this.service.regdatasol({ ID: this.ID['ID'], key: this.k1 + this.k2 + this.k3, versao: '1.0b' })
      .subscribe(dados => {
        if (dados !== this.VALID) {
          this.VALID = dados;
          if (this.VALID['resp'] === 'naoregistrado') { this.nregistrado = true; }
          if (this.VALID['resp'] === 'registrado') {
            this.router.navigateByUrl('/homologacoes');
            this.valido = true;
            this.registrad = true;
            this.registrado = false;
          }
        }
      });
  }

  todoschats(ide){
    this.service.chatpendentes({ID: ide })
    .subscribe(dados => {
      if (dados) {
        this.chatspendentes = dados;
        //console.log(this.chatspendentes)
        this.numeroonlines = this.chatspendentes.length
      }})
    }

  
    getSideBarState() {
      return this.sidebars.getSidebarState();
    }
  
  
    closeWindow() {
    window.open('','_parent','');
    window.close();
  }
    firstComponentFunction() {
    //console.log('testeclica')
    this.service.onFirstComponentButtonClick();    
  }   
    toggle(currentMenu) {
      //console.log(currentMenu)
      if (currentMenu.embreve === true) {
        this.embreve = true
      }
      this.router.navigateByUrl(currentMenu.routerLink);
      if (currentMenu.type === 'dropdown') {
        this.menus.forEach(element => {
          if (element === currentMenu) {
            currentMenu.active = !currentMenu.active;
          } else {
            element.active = false;
          }
        });
      }
    }
  
  
    getState(currentMenu) {
  
      if (currentMenu.active) {
        return 'down';
      } else {
        return 'up';
      }
    }
  
    hasBackgroundImage() {
      return this.sidebars.hasBackgroundImage;
    }
    toggleSidebar() {
      this.sidebars.setSidebarState(!this.sidebars.getSidebarState());
    }
}
