<div style="height: 100%; background-color: #2f3136;" class="row">
  <div style="height: 100%; background-color: #2f3136; padding: 0.5rem 0.5rem 0.5rem;" class="col-md-8">
    <iframe
      *ngIf="filtrou"
      [src]="srcslect | safex: 'resourceUrl'"
      style="margin-top: 20px; left: 0; margin-left: 20px"
      width="600px"
      height="400px"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
    <h5 style="color: white; margin-left: 20px">{{selecionada.titulo}}</h5>
    <p style="color: white; margin-left: 20px;text-align: justify;">{{selecionada.descrx}}</p>
  </div>
  <div style="background-color: white; " id="right" class="col-md-4">
    <h6 style="margin-top:1rem ">Conteúdo</h6>
    <ul
      *ngFor="let item of curso"
      nz-menu
      nzMode="inline"
      nzTheme="default"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li  [nzDisabled]="item.src === null"
      (click)="selecionaaula(item.id)" 
      nz-menu-item nz-tooltip nzTooltipPlacement="right" nzSelected>
        <i nz-icon nzType="play-square" nzTheme="outline"></i>
        <span>{{ item.titulo }}</span>

      </li>
    </ul>
    
  </div>
</div>
