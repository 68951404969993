import { MenuComponent } from './menu/menu.component';
import { SolicitarvistoriaComponent } from './solicitarvistoria/solicitarvistoria.component';
import { ClientecmComponent } from './clientmanager/clientecm.component';
import { MapsComponent } from './maps/maps.component';
import { SolicitahomologacaoComponent } from './solicitahomologacao/solicitahomologacao/solicitahomologacao.component';
import { AlteracadastroComponent } from './alteracadastro/alteracadastro.component';
import { AuthService } from './auth.service';
import { BoardComponent } from './board/board.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthComponent } from './auth/auth.component';
import { VisualizarquivosComponent } from './visualizarquivos/visualizarquivos.component';
import { ConsultahomologaComponent } from './consultahomologa/consultahomologa.component';
import { NgModule } from '@angular/core';
import { ExtraOptions, Routes, RouterModule } from '@angular/router';
import { HomologacoesComponent } from './homologacoes/homologacoes.component';
import { MinhashomologacoesComponent } from './minhashomologacoes/minhashomologacoes.component';
import { GeradorpropostaComponent } from './geradorproposta/geradorproposta.component';
import { CotacoesComponent } from './cotafacil/cotacoes/cotacoes.component';
import { GestaoComponent } from './gestao/gestao.component';
import { FinanceiroComponent } from './financeiro/financeiro.component';
import { GestaoarquivosComponent } from './gestaoarquivos/gestaoarquivos.component';
import { ChatComponent } from './chat/chat.component';
import { KanbanComponents } from './kanban/kanban.component';
import { EmployersComponent } from './employers/employers.component';
import { SmartcotaComponent } from './smartcota/smartcota.component';
import { CursoComponent } from './curso/curso.component';
import { TicketsComponent } from './tickets/tickets.component';
import { IntersolarComponent } from './intersolar/intersolar.component';


const routes: Routes = [

  {path: 'consultahomologa', component: ConsultahomologaComponent},
  {path: 'chat', component: ChatComponent},
  {path: 'sharedfiles', component: GestaoarquivosComponent},
  {path: 'employer', component: EmployersComponent},
  {path: 'cadastrakit', component: MenuComponent},
  {path: 'cotacoes', component: CotacoesComponent},
  {path: 'smartcota', component: SmartcotaComponent},
  {path: 'editahomologacao', component: SolicitahomologacaoComponent},
  {path: 'geraproposta', component: GeradorpropostaComponent},
  {path: 'firstlogxdatasol', component: MinhashomologacoesComponent},
  {path: 'homologacoes', component: MinhashomologacoesComponent , canActivate: [AuthService]},
  {path: 'gestaoclientes', component: ClientecmComponent},
  { path: 'auth:now', component: AuthComponent },
  { path: 'vistoria', component: SolicitarvistoriaComponent },
  { path: 'gestao', component: GestaoComponent },
  { path: 'alteracad', component: AlteracadastroComponent },
  { path: 'logout:now', component: LogoutComponent },
  { path: 'financeiro', component: FinanceiroComponent },
  { path: 'kanbans', component: KanbanComponents },
  { path: 'mapa', component: MapsComponent },
  {path: '*', component: MinhashomologacoesComponent , canActivate: [AuthService]},
  { path: 'visualizarquivos', component: VisualizarquivosComponent , canActivate: [AuthService] },
  { path: 'curso', component: CursoComponent , canActivate: [AuthService] },
  { path: 'ticket', component: TicketsComponent , canActivate: [AuthService] },
  { path: 'intersolar', component: IntersolarComponent },

];
const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
