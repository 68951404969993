<nz-page-header style="background-color: white;" class="site-page-header">
  <nz-page-header-title>Caixas</nz-page-header-title>
  <nz-page-header-subtitle>Valores do fluxo de caixa</nz-page-header-subtitle>
  <nz-page-header-content>
    <nz-row nzType="flex">
      <nz-statistic nzTitle="Conta Sollux PJ" [nzValue]="caixasollux" nzPrefix="R$"></nz-statistic>
      <nz-statistic nzTitle="Conta Robson" [nzValue]="caixarobson" nzPrefix="R$" style="margin: 0 32px"></nz-statistic>
      <nz-statistic nzTitle="Conta Jonnathan" [nzValue]="caixajonnathan" nzPrefix="R$"></nz-statistic>
        <nz-statistic style="margin: 0 32px" nzTitle="Total em caixa" [nzValue]="totalemcx" nzPrefix="R$">
        </nz-statistic>
    </nz-row>

  </nz-page-header-content>
</nz-page-header>
<nb-card>
  <nb-card-body>

    <nb-actions size="small">

      <nb-action (click)="menumuda(0)" nbTooltip="Home" nbTooltipPlacement="bottom" icon="home-outline">
      </nb-action>
      <nb-action (click)="menumuda(1)" nbTooltip="Pagar contas" nbTooltipPlacement="bottom" icon="minus-circle-outline">
      </nb-action>
      <nb-action (click)="menumuda(4)" nbTooltip="Receber" nbTooltipPlacement="bottom" icon="trending-up-outline">
      </nb-action>
      <nb-action (click)="menumuda(3)" nbTooltip="Homologações pendentes" nbTooltipPlacement="bottom"
        icon="swap-outline"></nb-action>
      <nb-action (click)="menumuda(2);" nbTooltip="Cobranças e boletos" nbTooltipPlacement="bottom"
        icon="credit-card-outline"></nb-action>
      <nb-action (click)="menumuda(5);" nbTooltip="Relatórios" nbTooltipPlacement="bottom" icon="file-text-outline">
      </nb-action>
      <nb-action (click)="menumuda(6);" nbTooltip="Transferência entre contas" nbTooltipPlacement="bottom"
        icon="sync-outline"></nb-action>

      <nb-action nbTooltip="Voltar" nbTooltipPlacement="bottom" icon="arrow-ios-back-outline"></nb-action>

    </nb-actions>

  </nb-card-body>
</nb-card>
<div *ngIf="menu0 === true" >
  <div >

   

    <p-panel header="Contas a pagar"  [toggleable]="true">
      <ng-template pTemplate="icons">
      </ng-template>


      <div class="card">

        <nb-card-header>
          <nz-statistic [nzValue]="despesa" nzPrefix="R$"></nz-statistic>
          <button nz-button>Receber</button>
          <button nz-button [nzType]="'primary'" (click)="open(novaentrada);tipolanc='Entrada'">Cadastrar
            recebimento</button>
        </nb-card-header>
  
          <p-table  scrollHeight="200px" [scrollable]="true" [value]="despex">
            <ng-template pTemplate="header">
                <tr>
                    <th>Descrição</th>
                    <th>Favorecido</th>
                    <th>Valor</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td>{{product.descricao}}</td>
                    <td>{{product.favorecido}}</td>
                    <td style="font-weight: 600;
                    color: #c16302;"><span style="font-weight: 600;
                    color: #c16302;">R$ </span>{{product.valortransacao}}</td>
                </tr>
            </ng-template>
        </p-table>
      </div>
  </p-panel>
  </div>
</div>

  <p-divider></p-divider>

  <div *ngIf="menu0 === true" >
    <p-panel header="Contas a receber"  [toggleable]="true">
      <ng-template pTemplate="icons">
      </ng-template>

      <div class="card">

      <nb-card-header>
        <nz-statistic [nzValue]="recebimento" nzPrefix="R$"></nz-statistic>
        <button nz-button>Receber</button>
        <button nz-button [nzType]="'primary'" (click)="open(novaentrada);tipolanc='Entrada'">Cadastrar
          recebimento</button>
      </nb-card-header>

        <p-table  scrollHeight="200px" [scrollable]="true" [value]="recebex">
          <ng-template pTemplate="header">
              <tr>
                  <th>Descrição</th>
                  <th>Favorecido</th>
                  <th>Valor</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
              <tr>
                  <td>{{product.descricao}}</td>
                  <td>{{product.favorecido}}</td>
                  <td style="font-weight: 600;
                  color: #c16302;"><span style="font-weight: 600;
                  color: #c16302;">R$ </span>{{product.valortransacao}}</td>
              </tr>
          </ng-template>
      </p-table>
    </div>
  </p-panel>
</div>

<nb-card *ngIf="menu1 === true">
  <nb-card-body>

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Filtrar por Data</nz-page-header-title>
      <nz-page-header-subtitle>escolha um período para mostar os registros</nz-page-header-subtitle>
      <nz-page-header-extra>
        <nz-range-picker (ngModelChange)="onChange($event)" [(ngModel)]="xdate" [nzSize]="'default'"></nz-range-picker>
      </nz-page-header-extra>
      <nz-page-header-content>
      </nz-page-header-content>
    </nz-page-header>
    <nz-table #editRowTable nzBordered [nzData]="contasapagar">
      <thead>
        <tr>
          <th nzCustomFilter>Descrição
            <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menua">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th>Favorecido</th>
          <th>Valor</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of editRowTable.data;let i = index" class="editable-row">
          <td>{{ data.descricao }}</td>
          <td>{{ data.favorecido }}</td>
          <td>{{ data.valortransacao }}</td>
          <td>
            <a nz-popconfirm nzPopconfirmTitle="Pagar essa conta agora?"
              (nzOnConfirm)="open(pagar);filtrapagamento( data.descricao,data.favorecido,data.valortransacao,data.ide)">Pagar</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-dropdown-menu #menua="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Busca por nome" [(ngModel)]="searchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">
            Buscar
          </button>
          <button nz-button nzSize="small" (click)="reset()">Limpar</button>
        </div>
      </div>
    </nz-dropdown-menu>
  </nb-card-body>
</nb-card>
<nb-card *ngIf="menu2 === true">
  <nb-card-body>
    <select [value]='parceiroselecionado' (change)="parceiroselecionado=($event.target.value);puxdebitos()"
      style="border: 0.5px solid blue !important; height: 40px; width: 100%;" class="inputa" id="statusprops">
      <option selected> Selecione...</option>
      <option *ngFor="let satus6 of todospar" [value]="satus6.idempresa"> {{satus6.nome}}</option>
    </select>
    <div *ngFor="let item of debitos" class="site-page-header-ghost-wrapper">
      <nz-page-header nzBackIcon [nzGhost]="false">
        <nz-page-header-title>Débito de Homologação</nz-page-header-title>
        <nz-page-header-extra>
          <button (click)="abredisplay(item.homol,item.parcela,item.cliente)" nz-button>Atualizar</button>
          <button (click)="downloadMyFile()" nz-button>Download boleto</button>
        </nz-page-header-extra>
        <nz-page-header-content>
          <nz-descriptions nzSize="small" [nzColumn]="3">
            <nz-descriptions-item nzTitle="Homologação:" [nzSpan]="1">{{item.homol}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Cliente:" [nzSpan]="1"><a>{{item.cliente}}</a></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Parcela" [nzSpan]="1">{{item.parcela}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Valor" [nzSpan]="1">R$ {{item.valor}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Vencimento" [nzSpan]="2"> {{item.vencimentoboleto}}</nz-descriptions-item>
          </nz-descriptions>
        </nz-page-header-content>
      </nz-page-header>
    </div>
  </nb-card-body>
</nb-card>
<nb-card *ngIf="menu3 === true">
  <nb-card-body>
    <nz-table #editRowTable nzBordered [nzData]="pendentes">
      <thead>
        <tr>
          <th>Código</th>
          <th>Cliente
            <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menux">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th>Parceiro</th>
          <th>1º Parcela</th>
          <th>2º Parcela</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of editRowTable.data;let i = index" class="editable-row">
          <td>
            {{ data.codhomol }}
          </td>
          <td>{{ data.Cliente }}</td>
          <td>{{ data.solicitante }}</td>
          <td>
            <i *ngIf="data.pagamentoentrada === 'Pago'" class="fa fa-check" aria-hidden="true"></i>
          </td>
          <td>
            <i *ngIf="data.pagamentosegundaparte === 'Pago'" class="fa fa-check" aria-hidden="true"></i>
          </td>
          <td>
            <a nz-popconfirm nzPopconfirmTitle="Inserir em recebimentos?"
              (nzOnConfirm)="open(transferir);transfer(i,data,data.id)">Transferir</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-dropdown-menu #menux="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Busca por nome" [(ngModel)]="searchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="search2()" class="search-button">
            Buscar
          </button>
          <button nz-button nzSize="small" (click)="reset2()">Limpar</button>
        </div>
      </div>
    </nz-dropdown-menu>
  </nb-card-body>
</nb-card>
<nb-card *ngIf="menu4 === true">
  <nb-card-body>

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Filtrar por Data</nz-page-header-title>
      <nz-page-header-subtitle>escolha um período para mostar os registros</nz-page-header-subtitle>
      <nz-page-header-extra>
        <nz-range-picker (ngModelChange)="onChange($event)" [(ngModel)]="xdate" [nzSize]="'default'"></nz-range-picker>
      </nz-page-header-extra>
      <nz-page-header-content>
      </nz-page-header-content>
    </nz-page-header>
    <nz-table #editRowTable nzBordered [nzData]="contasareceber">
      <thead>
        <tr>
          <th nzCustomFilter>Descrição
            <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menua">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th>Favorecido</th>
          <th>Valor</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of editRowTable.data;let i = index" class="editable-row">
          <td>{{ data.descricao }}</td>
          <td>{{ data.favorecido }}</td>
          <td>{{ data.valortransacao }}</td>
          <td>
            <a nz-popconfirm nzPopconfirmTitle="Receber essa conta agora?"
              (nzOnConfirm)="open(receber);filtrapagamento( data.descricao,data.favorecido,data.valortransacao,data.ide)">Receber</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-dropdown-menu #menua="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Busca nome" [(ngModel)]="searchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">
            Buscar
          </button>
          <button nz-button nzSize="small" (click)="reset()">Limpar</button>
        </div>
      </div>
    </nz-dropdown-menu>
  </nb-card-body>
</nb-card>
<nb-card *ngIf="menu5 === true">
  <nb-card-body>
    <p-table #dt [columns]="cols" [value]="caixasol" selectionMode="multiple">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV()" class="p-mr-2"
            pTooltip="CSV" tooltipPosition="bottom"></button>
          <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
            class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
          <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})"
            class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
<ng-template #novaconta let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Cadastrar conta</nb-card-header>
    <nb-card-body>
      <div style="width: 600px" class="form-group">
        <form>
          <div class="form-group">
            <label for="descricaoconta">Descrição</label>
            <input (change)="descricaolan= $event.target.value" type="text" class="form-control" id="descricaoconta"
              placeholder="Descrição da conta">
          </div>
          <div class="form-group">
            <label for="valorapagar">Valor a pagar</label>
            <br>
            <input style="border: 0.01px solid blue ;font-size: 14px;"
              (change)="valorlan= $event.target.value;mostavalor($event.target.value)" type="number"
              class="form-control">
          </div>

          <div class="form-group">
            <label for="Favorecido">Favorecido</label>
            <input (change)="favorecidolan= $event.target.value" type="text" class="form-control" id="Favorecido">
          </div>


          <div class="form-group">
            <label for="tipoconta">Categoria</label>
            <select (change)="categorialan= $event.target.value" class="form-control" id="tipoconta">
              <option selected>selecione...</option>
              <option value="Aluguel">Aluguel</option>
              <option value="Materialexpediente">Material expediente</option>
              <option value="Alimentacao">Alimentação</option>
              <option value="Água">Água</option>
              <option value="Luz">Luz</option>
              <option value="Energia">Energia</option>
              <option value="Internet">Internet</option>
              <option value="TI">TI</option>
              <option value="Bolsaestagio">Bolsa estágio</option>
              <option value="InvestimentoEquipamento">Investimento Equipamento</option>
              <option value="InvestimentoSoftware">Investimento Software</option>
              <option value="Prolabore">Pró-labore</option>
              <option value="Impostos">Impostos</option>
              <option value="Outros">Outros</option>
            </select>
          </div>
        </form>
      </div>

      <div class="form-group">
        <label for="Datadopagamento">Data do pagamento</label>
        <br>
        <nz-date-picker nzFormat="dd-MM-yyyy" [(ngModel)]="startValue"></nz-date-picker>
      </div>

    </nb-card-body>
    <nb-card-footer>
      <button nz-button (click)="ref.close();inserelancamento()">Cadastrar</button>
      <button nz-button (click)="ref.close();">Fechar</button>
    </nb-card-footer>
  </nb-card>
</ng-template>


<ng-template #novaentrada let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Cadastrar recebimento</nb-card-header>
    <nb-card-body>
      <div style="width: 600px" class="form-group">
        <form>
          <div class="form-group">
            <label for="descricaoconta">Descrição</label>
            <input (change)="descricaolan= $event.target.value" type="text" class="form-control" id="descricaoconta"
              placeholder="Descrição da conta">
          </div>
          <div class="form-group">
            <label for="valorapagar">Valor a receber</label>
            <br>
            <nz-input-number [ngModelOptions]="{standalone: true}" placeholder="R$400.00" style="width: 100%;"
              id="valorapagar" [(ngModel)]="valorlan" [nzMin]="1" [nzStep]="1"></nz-input-number>
          </div>

          <div class="form-group">
            <label for="Favorecido">Pagador</label>
            <input (change)="favorecidolan= $event.target.value" type="text" class="form-control" id="Favorecido">
          </div>


          <div class="form-group">
            <label for="tipoconta">Categoria</label>
            <select (change)="categorialan= $event.target.value" class="form-control" id="tipoconta">
              <option selected>selecione...</option>
              <option value="Homologação">Homologação</option>
              <option value="Projeto Elétrico">Projeto Elétrico</option>
              <option value="Projeto Avulso">Projeto Avulso</option>
              <option value="Execução de Obra">Execução de Obra</option>
              <option value="Venda de usina">Venda de usina</option>
              <option value="Aplicativo">Aplicativo</option>
              <option value="Comissão">Comissão</option>
              <option value="Outro">Outro</option>
            </select>
          </div>
        </form>
      </div>

      <div class="form-group">
        <label for="Datadopagamento">Vencimento</label>
        <br>
        <nz-date-picker nzFormat="dd-MM-yyyy" [(ngModel)]="startValue" nzPlaceHolder="Start"></nz-date-picker>
      </div>

    </nb-card-body>
    <nb-card-footer>
      <button nz-button (click)="ref.close();inserelancamento()">Cadastrar</button>
      <button nz-button (click)="ref.close()">Fechar</button>
    </nb-card-footer>
  </nb-card>
</ng-template>


<ng-template #transferir let-data let-ref="dialogRef">
  <nb-card style="width: 600px;">
    <nb-card-header>Transferir para recebimento</nb-card-header>
    <nb-card-body>
      <div class="space-align-container">
        <div *ngIf="transferencia[0].pagamentoentrada !== 'Pago'"
          class="cartviewprice d-block d-flex justify-content-between">
          <a class="cartproname">{{transferencia[0].codhomol}}{{'-1º Parcela'}}</a>
          <div class="seller d-block">
            <span>{{transferencia[0].Cliente}} </span>
          </div>
          <span class="disamt ">R$ {{transferencia[0].valor}}</span>
        </div>
      </div>
      <div class="space-align-container">
        <div *ngIf="transferencia[0].pagamentoentrada === 'Pago'"
          class="cartviewprice d-block d-flex justify-content-between">
          <a class="cartproname oldamt">{{transferencia[0].codhomol}}{{'-1º Parcela'}}</a>
          <div class="seller d-block oldamt">
            <span class="oldamt ">{{transferencia[0].Cliente}} </span>
          </div>
          <span class="oldamt ">R$ {{transferencia[0].valor}}</span>
        </div>
      </div>

      <div class="space-align-container">
        <div *ngIf="transferencia[1].pagamentosegundaparte !== 'Pago'"
          class="cartviewprice d-block d-flex justify-content-between">
          <a class="cartproname">{{transferencia[1].codhomol}}{{'-2º Parcela'}}</a>
          <div class="seller d-block">
            <span>{{transferencia[1].Cliente}} </span>
          </div>
          <span class="disamt ">R$ {{transferencia[1].valor}}</span>
        </div>
      </div>
      <div class="space-align-container">
        <div *ngIf="transferencia[1].pagamentosegundaparte === 'Pago'"
          class="cartviewprice d-block d-flex justify-content-between">
          <a class="cartproname oldamt">{{transferencia[1].codhomol}}{{'-1º Parcela'}}</a>
          <div class="seller d-block oldamt">
            <span class="oldamt ">{{transferencia[1].Cliente}} </span>
          </div>
          <span class="oldamt ">R$ {{transferencia[1].valor}}</span>
        </div>
      </div>
      <nz-divider nzText="" nzOrientation="right"></nz-divider>

      <div style="float:right;">
        <nz-statistic nzTitle="Total" [nzValue]="transferencia[0].valor + transferencia[1].valor" nzPrefix="R$">
        </nz-statistic>
      </div>
      <nz-divider nzText="" nzOrientation="right"></nz-divider>
      <form nz-form [nzLayout]="'inline'">
        <nz-form-item *ngIf="transferencia[0].pagamentoentrada !== 'Pago'">
          <nz-form-label>Valor 1º Parcela</nz-form-label>
          <nz-input-number [ngModelOptions]="{standalone: true}" placeholder="R$400.00" style="width: 40%;"
            id="valorapagar1" [(ngModel)]="transferencia[0].valor" [nzMin]="1" [nzStep]="1"></nz-input-number>
        </nz-form-item>
        <nz-form-item *ngIf="transferencia[1].pagamentosegundaparte !== 'Pago'">
          <nz-form-label>Valor 2º Parcela</nz-form-label>
          <nz-input-number [ngModelOptions]="{standalone: true}" placeholder="R$400.00" style="width: 40%;"
            id="valorapagar2" [(ngModel)]="transferencia[1].valor" [nzMin]="1" [nzStep]="1"></nz-input-number>
        </nz-form-item>
      </form>
      <div *ngIf="transferencia[0].pagamentoentrada !== 'Pago'" class="form-group">
        <label for="Datadopagamento">Vencimento 1º Parcela</label>
        <br>
        <nz-date-picker nzFormat="dd-MM-yyyy" [(ngModel)]="transferencia[0].data" nzPlaceHolder="Start">
        </nz-date-picker>
      </div>
      <div *ngIf="transferencia[1].pagamentosegundaparte !== 'Pago'" class="form-group">
        <label for="Datadopagamento">Vencimento 2º Parcela</label>
        <br>
        <nz-date-picker nzFormat="dd-MM-yyyy" [(ngModel)]="transferencia[1].data" nzPlaceHolder="Start">
        </nz-date-picker>
      </div>

    </nb-card-body>
    <nb-card-footer>
      <button nz-button (click)="ref.close(); transfere()">Transferir</button>
      <button nz-button (click)="ref.close()">Fechar</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<p-dialog header="Cadastrar novo  projeto" [style]="{width: '50vw'}" [(visible)]="display">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-6">
      <label for="valors">valor</label>
      <input (input)="valorbol = $event.target.value" id="valors" type="text" pInputText>
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label for="venci">Vencimento</label>
      <input mask="00/00/0000" (input)="vencimentoboleto = $event.target.value" id="venci" type="text" pInputText>
    </div>
    <div class="p-field p-col-12 p-md-12">
      <label for="email">Url Boleto</label>
      <input (change)="encode($event.target.value)" id="email" type="text" pInputText>
    </div>
  </div>
  <p-footer>
    <button (click)='display = false' type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
    <button (click)='atualizardeb(); display = false' data-dismiss="modal" type="button"
      class="btn btn-primary">Cadastrar</button>
  </p-footer>
</p-dialog>


<ng-template #pagar let-data let-ref="dialogRef">
  <nb-card style="width: 600px;">
    <nb-card-header>Realizar pagamento</nb-card-header>
    <nb-card-body>
      <div style="margin-top: 10px;" class="form-row">
        <label for="statusprops">Escolha uma conta</label>
        <select (change)="contasel=($event.target.value)" id="statusprops1" class="form-control">
          <option selected> Selecione...</option>
          <option *ngFor="let stat of contasativas" [value]="stat.value"> {{stat.viewValue}}</option>
        </select>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nz-button (click)="ref.close(); pagaconta()">Pagar</button>
      <button nz-button (click)="ref.close()">Fechar</button>
    </nb-card-footer>
  </nb-card>
</ng-template>



<ng-template #receber let-data let-ref="dialogRef">
  <nb-card style="width: 600px;">
    <nb-card-header>Realizar Recebimento</nb-card-header>
    <nb-card-body>
      <div style="margin-top: 10px;" class="form-row">
        <label for="statusprops">Escolha uma conta</label>
        <select (change)="contasel=($event.target.value)" id="statusprops1" class="form-control">
          <option selected> Selecione...</option>
          <option *ngFor="let stat of contasativas" [value]="stat.value"> {{stat.viewValue}}</option>
        </select>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nz-button (click)="ref.close(); receberconta()">Receber</button>
      <button nz-button (click)="ref.close()">Fechar</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<nb-card *ngIf="menu6 === true">
  <nb-card-body>
    <div style="margin-top: 10px;" class="form-row">
      <label for="statusprops">Conta origem</label>
      <select (change)="contaorigem=($event.target.value)" id="statusprops1" class="form-control">
        <option selected> Selecione...</option>
        <option *ngFor="let stat of contasativas" [value]="stat.value"> {{stat.viewValue}}</option>
      </select>
    </div>
    <p-divider></p-divider>
    <div style="margin-top: 10px;" class="form-row">
      <label for="statusprops">Conta destino</label>
      <select (change)="contadestino=($event.target.value)" id="statusprops1" class="form-control">
        <option selected> Selecione...</option>
        <option *ngFor="let stat of contasativas" [value]="stat.value"> {{stat.viewValue}}</option>
      </select>
    </div>
    <p-divider></p-divider>
    <div class="form-group">
      <label for="valorapagar">Valor a pagar</label>
      <br>
      <input style="border: 0.01px solid blue ;font-size: 14px;"
        (change)="valortransf= $event.target.value;mostavalor($event.target.value)" type="number" class="form-control">
    </div>
    <p-divider></p-divider>
    <div class="form-group">
      <label for="Datadopagamento">Data do pagamento</label>
      <br>
      <nz-date-picker nzFormat="dd-MM-yyyy" [(ngModel)]="datatransf"></nz-date-picker>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nz-button (click)="pagatransf()">Transferir</button>
  </nb-card-footer>
</nb-card>