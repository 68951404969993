import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggled = false;
  _hasBackgroundImage = true;
  menus = [
    {
      title: 'Projetos',
      type: 'header'
    },
    {
      title: 'Seus Dados',
      icon: 'fa fa-user',
      active: false,
      routerLink:"/alteracad",
      type: 'simple',
      submenus: [
        {
          title: 'Dashboard 1',
          badge: {
            text: 'Pro ',
            class: 'badge-success'
          }
        },
        {
          title: 'Dashboard 2'
        },
        {
          title: 'Dashboard 3'
        }
      ]
    },
    {
      title: 'Suas Homologações',
      routerLink:"/homologacoes",
      icon: 'fas fa-solar-panel',
      active: false,
      type: 'simple',
      submenus: [
        {
          title: 'Products',
        },
        {
          title: 'Orders'
        },
        {
          title: 'Credit cart'
        }
      ]
    },
    {
      title: 'Nova homologação',
      icon: 'fas fa-folder-plus',
      routerLink:"/editahomologacao",
      active: false,
      type: 'simple',
      submenus: [
        {
          title: 'General',
        },
        {
          title: 'Panels'
        },
        {
          title: 'Tables'
        },
        {
          title: 'Icons'
        },
        {
          title: 'Forms'
        }
      ]
    },
    {
      title: 'Gestāo',
      type: 'header'
    },
    {
      title: 'Gerador de proposta',
      icon: 'fas fa-file-alt',
      active: false,
      routerLink:"/geraproposta",
      type: 'simple',
 
    },
    {
      title: 'Gestāo de projetos',
      icon: 'fas fa-tasks',
      routerLink:"/gestaoclientes",
      active: false,
      type: 'simple'
    },
    {
      title: 'Minha equipe',
      icon: 'fas fa-users',
      active: false,
      routerLink:"/employer",
      embreve: true,
      type: 'simple'
    }
    ,
    {
      title: 'Compras',
      icon: 'fas fa-cart-arrow-down',
      active: false,
      routerLink:"/homologacoes",

      embreve: true,
      type: 'simple'
    }
    ,
    {
      title: 'App Cotações',
      icon: 'fas fa-funnel-dollar',
      active: false,
      routerLink:"/homologacoes",

      embreve: true,
      type: 'simple'
    }
  ];
  constructor() { }

  toggle() {
    this.toggled = ! this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getMenuList() {
    return this.menus;
  }

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }


}

