import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Dropbox } from 'dropbox';
import { NbDialogService } from '@nebular/theme';
import * as $AB from 'jquery';
import * as $ from 'jquery';
import { NotificationService } from '../notification.service'; // -- new import by K
import { AuthService } from '../auth.service';
import { FilesService } from '../files.service';
import { StorageService } from '../storage.service';
import { DbxAuth } from '../configs';
import { LocalStorageMethods, UrlMethods } from '../utils';
import { ImageData,BaseImage, DisplayConfig, SameSizeConfig, ImageEffect } from '@creativeacer/ngx-image-display';
import { IMAGEVIEWER_CONFIG, ImageViewerConfig } from '@hallysonh/ngx-imageviewer';
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';
import { MenuItem } from 'primeng/api';

const MY_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
  width: 940, // component default width
  //height: 600, // component default height
  bgStyle: '#ECEFF1', // component background style
  scaleStep: 0.1, // zoom scale step (using the zoom in/out buttons)
  rotateStepper: false, // touch rotate should rotate only 90 to 90 degrees
  loadingMessage: 'Loading...',
  buttonStyle: {
    iconFontFamily: 'Material Icons', // font used to render the button icons
    alpha: 0.9, // buttons' transparence value
    hoverAlpha: 0.7, // buttons' transparence value when mouse is over
    bgStyle: '#102F78', //  buttons' background style
    iconStyle: '#ffffff', // buttons' icon colors
    borderStyle: '#000000', // buttons' border style
    borderWidth: 0 // buttons' border width (0 == disabled)
  },
      zoomOutButton: { // zoomOut button config
        icon: 'zoom_out', // icon text
        tooltip: 'Zoom out', // button tooltip
        sortId: 0, // number used to determine the order of the buttons
        show: true // used to show/hide the button
      },
  };
  

// import { SearchComponent } from '../search/search.component'; Deleted by K



enum hoverEffect {
    zoom = 'zoom',
    lighten = 'lighten',
    darken = 'darken',
    greyscale = 'greyscale',
    sepia = 'sepia'
  }

@Component({
    selector: 'app-storage',
    templateUrl: './storage.component.html',
    styleUrls: ['./storage.component.css'],
    providers: [
        {
            provide: IMAGEVIEWER_CONFIG,
            useValue: MY_IMAGEVIEWER_CONFIG
          }
      ]

})
export class StorageComponent implements OnInit, OnDestroy {

    samples = [
        { label: 'PDF Test', url: 'https://hallysonh.github.io/ngx-imageviewer/pdf-test.pdf' },
        { label: 'Image 1 (BIG)', url: 'https://hallysonh.github.io/ngx-imageviewer/assets/imgs/sample-0.jpg' },
        { label: 'Image 2', url: 'https://hallysonh.github.io/ngx-imageviewer/assets/imgs/sample-1.jpg' },
        { label: 'Image 3', url: 'https://hallysonh.github.io/ngx-imageviewer/assets/imgs/sample-2.jpg' },
        { label: 'Image 4', url: 'https://hallysonh.github.io/ngx-imageviewer/assets/imgs/sample-3.jpg' },
        { label: 'Image 5', url: 'https://hallysonh.github.io/ngx-imageviewer/assets/imgs/sample-4.jpg' },
        { label: 'Image 6', url: 'https://hallysonh.github.io/ngx-imageviewer/assets/imgs/sample-5.jpg' }
      ];
      canvasWidth = 800;
      canvasHeight = 600;
      imageSrc = this.samples[0].url;    
    private hasChanged = false; // -- new property by K --
    private currentUrl = ''; // -- new property by K --
    public path;
    caminhoatual ='';
    cami = '';
    public storageSpace;
    public usedSpace;
    public spacePercentage;
    private starredItems = [];
    public inEntries: Array<any> = [];
    loading = false
    public fileEntries: Array<any> = [];
    public lastSearch: Array<any> = []; // Modified by K
    private dbxAuth: any;
    private dbxAuthSubscription: Subscription;
    private fileStreamSubscription: Subscription;
    private compEntries: Array<any> = [];
    private dbxConnection;
    private showFavorites = false;
    private showFavoritesSubscription: Subscription;
    private showDeletes = false;
    private showDeletesSubscription: Subscription;

    public showSearch = false;
    private showSearchSubscription: Subscription;
    @Input() novocaminho = {
      a: '',
      b: ''
    };
    teste = ""
    @Input() pathori2 = '';
    @Input() homolselc = '';
    @Input() tipo = '';
    caminhopdf = '';
    pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
    dowlo:any;
    base:any;
    imageChangedEvent: any;
    base64: any;

    fileName: string;
    filePreview: string;
    zoomp = 1;
    imagem: any;
    nbase64data: any;
    private imagebase64data: any;
    public page = 1;
    public pageLabel: string;
    ehpdf = 'true';
    ehimage = 'false';
    naorender = 'false';
    docFile: File;
    bred=[];
    nbred=[];
    navegaid='';
    imagesub: Subscription;
    images: Array<ImageData> = [];
    samesizeConfig: SameSizeConfig;
    displayconfig: DisplayConfig;
    hovereffect: ImageEffect;
    samesizeoption: boolean;
    fullscreenoption: boolean;
    hoverEffect: string = '';
    displyn = false;
    dbx:any;
    public cssClass: string = 'e-list-template';
    private subscription: Subscription;
    public pathArray;
    // private goBack; // Deleted by K
    public parentFolder;
    // private currentPath; // Deleted by K
    itemsx: MenuItem[];

    public localData: Object[] = [
        { id: 5, name: 'My Folder', hasChild: true, selected: true ,expanded: true },
        { id: 6, pid: 5, name: 'Documentos_tecnicos',   count: '20'},
        { id: 7, pid: 5,  name: 'fotos_vistoria', count: '5'}
    ];
    public field:Object ={ dataSource: this.localData, id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild' };

    constructor(private authService: AuthService,
                private service : HomologacoesserviceService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private dialogService: NbDialogService,
                private filesService: FilesService,
                private storageService: StorageService,
        private notificationService: NotificationService) {
            this.service.currentdAuth.subscribe(dados => {
                if (dados) {
                    this.dbxAuth = dados;;
                    this.dbx = new Dropbox({ accessToken: this.dbxAuth.accessToken });
                    }
                });
                    this.samesizeoption = true;                    
                    this.displayconfig = {
                      imageminwidth: '250px',
                      containerwidth: '80%',
                      containerheight: '500px',
                      fullScreenView: true // Set to false to hide the top right full screen option
                    };
                    this.hovereffect = {
                      hoverEffectActive: false
                    };
                    this.samesizeConfig = {
                      active: true,
                      imgContainerHeight: '250px'
                    }
                 } // -- new service by K --
      voltarq() {
                 console.log(this.novocaminho.b)
                  this.filesService.getFiles(this.novocaminho.b);
                  this.caminhoatual = this.novocaminho.b.split('/aplicativos')[1];
                  localStorage.setItem('currentpath', this.novocaminho.b);

                }


            voltaraiz(){
                this.filesService.getFiles(this.currentUrl);
                this.bred = [];

            }
          

            applyFilter(a){
                this.navegaid = a;
                this.filesService.getFiles(this.currentUrl+this.navegaid);
                this.bred= [];
            }

                zoomplus(){
                    this.zoomp = this.zoomp + 0.1
                }
                zoominus(){
                    this.zoomp = this.zoomp - 0.1
                }         
    ngOnInit() {
        this.itemsx = [
            {
              label: "Voltar",
              icon: "pi pi-arrow-left",
              command: (event) => {
              },
            },
            {
              label: "Upload",
              icon: "pi pi-cloud-upload",
              command: (event) => {
              },
            }
          ];
        this.dbxConnection = new Dropbox({ accessToken: this.dbxAuth.accessToken });
        this.currentUrl = UrlMethods.decodeWithoutParams(this.novocaminho.a);
        console.log(this.currentUrl)
        this.filesService.getFiles(this.currentUrl);
        localStorage.setItem('currentpath', this.currentUrl)
        //this.caminhoatual = this.novocaminho.a.split('/aplicativos')[1];
        this.gerabread(this.currentUrl)
        this.fileStreamSubscription = this.filesService.stream
            .subscribe((entries) => {
                this.updateFileStream(entries)
            });
            
        if(this.tipo === 'homol'){ this.localData[0]['name'] = this.homolselc}
       

        this.showFavoritesSubscription = this.storageService.showFavorites()
            .subscribe((status) => {
                this.showFavorites = status;
                if (status) {
                    const results = this.filesService.favaritesResults();
                    this.showCustomData(results);
                }
            });

        this.showDeletesSubscription = this.storageService.showDeletes()
            .subscribe((status) => {
                this.showDeletes = status;
                if (status) {
                    const data = this.filesService.deletedData();
                    this.showCustomData(data); // Continue code for this function here<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                }
            });

        // Added by K
        this.showSearchSubscription = this.storageService.showSearch()
            .subscribe((status) => {
                this.showSearch = status;
                if (status) {
                    const results = this.filesService.searchResults();
                    this.showCustomData(results);
                }
            });

        // New code to auto rerender this component
        this.notificationService.updateCurrentPath(this.currentUrl);
        this.notificationService.checkHasChange()
            .subscribe(changed => {
                this.hasChanged = changed;
                this.checkHasChanged();
            });
        // -- End new --

    }
    verificafolder(a, c) {
        console.log(c)
        console.log(a)
        if(this.tipo === 'homol'){ 

        if (c !== this.homolselc){
            this.filesService.getFiles("/aplicativos/homofold/" + this.homolselc + '/' + c);
            this.service.mudapasta("/aplicativos/homofold/" + this.homolselc + '/' + c + '/');

        this.gerabread(c)
            localStorage.setItem('currentpath', c);
        }
        if (c === this.homolselc){
            this.filesService.getFiles("/aplicativos/homofold/" + this.homolselc);
            this.service.mudapasta("/aplicativos/homofold/" + this.homolselc + '/');
            this.gerabread(c)
            localStorage.setItem('currentpath', c);}
        }
     }
     mostrasa(a){
         console.log(a)
        if(a !== '//aplicativos'){
         console.log(a.substr(1))
         this.filesService.getFiles(a.substr(1));
         localStorage.setItem('currentpath', a.substr(1));
         this.gerabread(a.substr(1))}
     }
 

     gerabread(a) {
            const urlWithoutParams = decodeURIComponent(a).split('?')[0]; // Added by K
            this.pathArray = this.getPathsToRenderFromUrl(urlWithoutParams); // Edited by K
            // this.goBack = this.goBackFn(); // Deleted by K
            this.goBackFn();
        }

     getPathsToRenderFromUrl(currentPath) {
        let paths = currentPath.split('/');
        if (currentPath === '' || currentPath === '/') {
            paths = [''];
        }
        let fullpath = '';
        const pathsToRender = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < paths.length; i++) {
            const path = decodeURI(paths[i]);
            fullpath += `/${path}`;
            pathsToRender.push({
                path,
                fullpath,
            });
        }
        return pathsToRender;
    }

    goBackFn() {
        return this.parentFolder = this.pathArray.slice(0, -1);
    }
      // BREAD ACIMA
      
    checkHasChanged() {
        if (this.hasChanged) {
            this.filesService.getFiles(this.currentUrl);
            this.notificationService.hasReRendered(); // report to service that this component has rerendered
        } else {
        }
    }
    // -- End new --

    updateFileStream(inData: Array<any>) {
        if (!this.showFavorites && !this.showSearch && !this.showDeletes) {
            this.compEntries = inData;
            this.getData();
            this.renderData(this.compEntries);
        }
    }

    showCustomData(inData: Array<any>) {
        if (this.showFavorites) {
            this.renderData(inData);
            this.storageService.deactivateShowFavorites();
        } else if (this.showSearch) {
            this.renderData(inData);
            this.storageService.deactivateShowSearch();
        } else if (this.showDeletes) {
            // code to render data here<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
            const objDeleteArr: Array<any> = [];
            inData.forEach(name => {
                objDeleteArr.push({
                    name: name,
                    path_lower: ''
                });
            });
            this.renderData(objDeleteArr);
            this.storageService.deactivateShowDeletes();
        }
    }

  
    getData() {
        this.dbxConnection
            .usersGetSpaceUsage(null)
            .then(spaceInfo => {
                this.storageSpace = (spaceInfo.allocation.allocated / 1024 / 1024 / 1024).toFixed(2);
                this.usedSpace = (spaceInfo.used / 1024 / 1024 / 1024).toFixed(2);
                this.spacePercentage = (this.usedSpace / this.storageSpace) * 100;
            })
            .catch(error => {
                console.log(error);
            });
    }

    downloadFile(filepath, filename, event, dialogo) {
        this.loading = true
        console.log(filepath)
        event.preventDefault();
        this.dbxConnection
            .filesDownload({ path: filepath })
            .then(data => {
                const fileurl = URL.createObjectURL((<any>data).fileBlob);
                const a = document.createElement('a');
                if (this.isImage(data.path_lower)) {
                   this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                   this.ehpdf = 'false';
                   this.naorender = 'false'
                   a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                var reader = new FileReader();
                reader.readAsDataURL(this.imagem); 
                reader.onloadend = (e)  => {
                    //var base64data = reader.result;
                    var base64data = (<string>reader.result).split(',')[1];
                    this.imagebase64data = base64data
                    //this.imagebase64data = (<string>base64data).replace('data:application/octet-stream;base64', 'data:image/png');
                    const imageName = filename;
                    console.log(this.imagebase64data);
                    const imageBlob = this.dataURItoBlob(this.imagebase64data);
                    this.docFile = new File([imageBlob], imageName, { type: 'image/png' });
                    this.images = [
                        {
                            type: 'base64',
                            imageData: {
                              value: this.imagebase64data,
                              subtext: 'Just some overlay',
                              subtextOverlay: 'full'
                            }
                          }
                    ];
                    this.ehimage = 'true';
                    this.openWithBackdrop(dialogo)
                    this.displyn = true
                }
                }
                if (this.Ispdf(data.path_lower))  {
                this.ehimage = 'false';
                this.naorender = 'false'
                this.ehpdf = 'true';
                a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                var reader = new FileReader();
                reader.readAsDataURL(this.imagem); 
                reader.onloadend = (e)  => {
                   this.openWithBackdrop(dialogo)
                    this.displyn = true
                    var base64data = (<string>reader.result).split(',')[1];
                    this.nbase64data = base64data
                }}
                if (!this.Ispdf(data.path_lower) && !this.isImage(data.path_lower) ) {
                    this.naorender = 'true'
                    this.ehimage = 'false';
                    this.ehpdf = 'false';
                    a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                this.openWithBackdrop(dialogo);
                this.displyn= true
            }
                                //a.click();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    downloadFilex(filepath, filename, event, dialogo) {
        this.loading = true
        console.log(filepath)
        event.preventDefault();
        this.dbxConnection
            .filesDownload({ path: filepath })
            .then(data => {
                const fileurl = URL.createObjectURL((<any>data).fileBlob);
                const a = document.createElement('a');
                if (this.isImage(data.path_lower)) {
                   this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                   this.ehpdf = 'false';
                   this.naorender = 'false'
                   a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                this.dowlo.click()
                this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                var reader = new FileReader();
                reader.readAsDataURL(this.imagem); 
                reader.onloadend = (e)  => {
                    //var base64data = reader.result;
                    var base64data = (<string>reader.result).split(',')[1];
                    this.imagebase64data = base64data
                    //this.imagebase64data = (<string>base64data).replace('data:application/octet-stream;base64', 'data:image/png');
                    const imageName = filename;
                    console.log(this.imagebase64data);
                    const imageBlob = this.dataURItoBlob(this.imagebase64data);
                    this.docFile = new File([imageBlob], imageName, { type: 'image/png' });
                    this.images = [
                        {
                            type: 'base64',
                            imageData: {
                              value: this.imagebase64data,
                              subtext: 'Just some overlay',
                              subtextOverlay: 'full'
                            }
                          }
                    ];
                    this.ehimage = 'true';
                    //this.openWithBackdrop(dialogo)
                    this.displyn = true
                }
                }
                if (this.Ispdf(data.path_lower))  {
                this.ehimage = 'false';
                this.naorender = 'false'
                this.ehpdf = 'true';
                a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                this.dowlo.click()
                this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                var reader = new FileReader();
                reader.readAsDataURL(this.imagem); 
                reader.onloadend = (e)  => {
                   //this.openWithBackdrop(dialogo)
                    this.displyn = true
                    var base64data = (<string>reader.result).split(',')[1];
                    this.nbase64data = base64data
                }}
                if (!this.Ispdf(data.path_lower) && !this.isImage(data.path_lower) ) {
                    this.naorender = 'true'
                    this.ehimage = 'false';
                    this.ehpdf = 'false';
                    a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                this.dowlo.click()
                //this.openWithBackdrop(dialogo);
                this.displyn= true
            }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    downloadFile2(filepath, filename, event) {
        this.loading = true
        console.log(filepath)
        event.preventDefault();
        this.dbxConnection
            .filesDownload({ path: filepath })
            .then(data => {
                const fileurl = URL.createObjectURL((<any>data).fileBlob);
                const a = document.createElement('a');
                if (this.isImage(data.path_lower)) {
                   this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                   this.ehpdf = 'false';
                   this.naorender = 'false'
                   a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                var reader = new FileReader();
                reader.readAsDataURL(this.imagem); 
                reader.onloadend = (e)  => {
                    //var base64data = reader.result;
                    var base64data = (<string>reader.result).split(',')[1];
                    this.imagebase64data = base64data
                    //this.imagebase64data = (<string>base64data).replace('data:application/octet-stream;base64', 'data:image/png');
                    const imageName = filename;
                    console.log(this.imagebase64data);
                    const imageBlob = this.dataURItoBlob(this.imagebase64data);
                    this.docFile = new File([imageBlob], imageName, { type: 'image/png' });
                    this.images = [
                        {
                            type: 'base64',
                            imageData: {
                              value: this.imagebase64data,
                              subtext: 'Just some overlay',
                              subtextOverlay: 'full'
                            }
                          }
                    ];
                    this.ehimage = 'true';
                    this.displyn = true
                }
                }
                if (this.Ispdf(data.path_lower))  {
                this.ehimage = 'false';
                this.naorender = 'false'
                this.ehpdf = 'true';
                a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                var reader = new FileReader();
                reader.readAsDataURL(this.imagem); 
                reader.onloadend = (e)  => {
                    this.displyn = true
                    var base64data = (<string>reader.result).split(',')[1];
                    this.nbase64data = base64data
                }}
                if (!this.Ispdf(data.path_lower) && !this.isImage(data.path_lower) ) {
                    this.naorender = 'true'
                    this.ehimage = 'false';
                    this.ehpdf = 'false';
                    a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                this.displyn= true
            }
                            a.click();
            })
            .catch((error) => {
                console.log(error);
            });
            this.dowlo.click()
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });    
        return blob;
     }
      

    waitForOneSecond() {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve("I promise to return after one second!");
          }, 1000);
        });
      }

     

    
    open(dialog: TemplateRef<any>) {
        this.dialogService.open(dialog, { context: 'this', closeOnBackdropClick: true });
        this.loading = false
      }

      openWithBackdrop(a){
        this.open(a);
      }

      downlo(){
        this.dowlo.click()
      }
    mostraevento(a) {
  
      }

    // Get latest search results -- Added by K
    retrieveLatestSearch() {
        const numberOfSearchResults = 3; // You decide yourself how many you want
        let searchResults = this.filesService.searchResults();
        const arrLength = searchResults.length;
        if (arrLength > 0) {
            if (arrLength > numberOfSearchResults) {
                searchResults = searchResults.slice(0, numberOfSearchResults);
            }
            this.lastSearch = searchResults;
        }
    }

    renderData(inEntries: Array<any>) {
        this.fileEntries = []
        //for (let i = 0; i < inEntries.length; i++) {
          //  console.log(inEntries)
            //if (inEntries[i]['.tag'] !== 'folder') {
              //  this.fileEntries.push(inEntries[i])  
            //}
        //}
        /* Run HERE this method to retrieve exactly the latest search results
           before you render data */
        this.retrieveLatestSearch();

        // You can use the latest results by using this.lastSearch

        if (inEntries.length > 0)  {
            if (LocalStorageMethods.retrieve('entries') !== null) {
                for (let i = 0; i < inEntries.length; i++) {
                    inEntries[i].starred = checkStars(inEntries[i]);
                }
            } else {
                for (const entry of inEntries) {
                    entry.starred = false;
                }
            }

            for (const entry of inEntries) {
                this.currentUrl 
                console.log(this.currentUrl.substring(22, 34).toUpperCase() )
                console.log(entry.path_lower.substring(22, 34).toUpperCase() )
                
                if (this.isImage(entry.path_lower) ) {
                    this.dbxConnection
                        .filesGetThumbnail({ path: entry.path_lower })
                        .then((result) => {
                            const fileUrl = URL.createObjectURL((<any>result).fileBlob);
                            document
                                .getElementById(entry.path_lower)
                                .setAttribute('src', fileUrl);
                            
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        }
        this.inEntries = inEntries;
        console.log(this.inEntries)
        for (const entry of this.inEntries) {
        if(this.currentUrl.substring(22, 34).toUpperCase() !== entry.path_lower.substring(22, 34).toUpperCase())
        {
            this.inEntries = []
        }
        }

    }



    isImage(fileName: string) {
        if (fileName !== '' && fileName.length > 4) { // Fixed bug by K
            const supportedImages = ['jpg', 'jpeg', 'png', 'svg', 'SVG', 'PNG', 'tiff', 'tif', 'gif', 'bmp','JPG', 'JPEG', 'PNG', 'TIFF', 'TIF', 'GIF', 'BMP'];
            const fileEnding = fileName.split('.').pop();
            if (supportedImages.some(imgType => imgType === fileEnding)) {
                return true;
            }
        }
        return false;
    }

    isDoc(fileName: string) {
        if (fileName !== '' && fileName.length > 4) { // Fixed bug by K
            const supportedImages = ['DOC', 'DOCX', 'doc', 'docx', 'txt', 'TXT', 'rtf', 'RTF'];
            const fileEnding = fileName.split('.').pop();
            if (supportedImages.some(imgType => imgType === fileEnding)) {
                return true;
            }
        }
        return false;
    }

    Ispdf(fileName: string) {
        if (fileName !== '' && fileName.length > 4) { // Fixed bug by K
            const supportedImages = ['PDF', 'pdf'];
            const fileEnding = fileName.split('.').pop();
            if (supportedImages.some(imgType => imgType === fileEnding)) {
                return true;
            }
        }
        return false;
    }
    getFileType(fileName: string) {
        if (fileName !== '' && fileName.length > 4) { // Fixed bug by K
            const fileEnding = fileName.split('.').pop();
            let fileType = ''; // Fixed bug by K
            switch (fileEnding) {
                case 'pdf':
                    fileType = 'fas fa-file-pdf fa-2x';
                    break;
                case 'docx' || 'docx':
                    fileType = 'fas fa-file-word fa-2x';
                    break;
                case 'pptx':
                    fileType = 'fas fa-file-powerpoint fa-2x';
                    break;
                case 'xlsx':
                    fileType = 'fas fa-file-excel fa-2x';
                    break;
                case 'html' || 'js':
                    fileType = 'fas fa-file-code fa-2x';
                    break;
                default:
                    fileType = 'fa fa-file fa-2x';
            }
            return fileType;
        }
        return false;
    }

    addStar(id, event) {
        event.preventDefault();
        this.starredItems = LocalStorageMethods.retrieve('entries') || [];
        const foundItem = this.compEntries.find(item => item.id === id) || {};
        if (foundItem) {
            foundItem.starred = true;
            this.starredItems.push(foundItem);
            LocalStorageMethods.store('entries', this.starredItems);
        }
    }

    delStar(id, event) {
        event.preventDefault();

        // Clear item in this.inEntries
        const foundItem = this.inEntries.find(item => item.id === id);
        if (foundItem) { // Fixed bug by K
            foundItem.starred = false;
        }

        // Clear item in localStorage
        this.starredItems = LocalStorageMethods.retrieve('entries') || [];
        this.starredItems = this.starredItems.filter(el => el.id !== id);

        // Store back to localStorage
        LocalStorageMethods.store('entries', this.starredItems);
    }

    ngOnDestroy() {
        //this.dbxAuthSubscription.unsubscribe();
        this.fileStreamSubscription.unsubscribe();
        this.showFavoritesSubscription.unsubscribe();
        this.showDeletesSubscription.unsubscribe();
        this.showSearchSubscription.unsubscribe();
    }
  
    
  
}

function checkStars(inItem: any) {
    const currentStartItems = LocalStorageMethods.retrieve('entries') || [];
    const results = currentStartItems.filter(item => item.id === inItem.id) || [];

    return results.length > 0 ? true : false;
}
