import { FORMS } from './../../homologacoes/fomu';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { NbDialogService, NbToastrService, NbComponentStatus } from '@nebular/theme';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import * as CryptoJS from 'crypto-js';

import docxtemplater from 'docxtemplater';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import createReport from 'docx-templates';
import * as $ from 'jquery';
import 'src/assets/js/docxtemplater.js';
import 'src/assets/js/FileSaver.min.js';
import 'src/assets/js/pizzip-utils.js';
import 'src/assets/js/pizzip.js';
import 'src/assets/js/pizzip.js';
import 'src/assets/js/dropboxsdk.min.js';
import { HomologacoesserviceService } from 'src/app/homologacoes/homologacoesservice.service';
import { kitconfiguracao } from './kit';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Dropbox } from 'dropbox';
declare const mudatexto: any;
declare const upa1: any;
declare const generate: any;
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { DbxAuth } from 'src/app/minhashomologacoes/configs';
import { VariableBinding } from '@angular/compiler';

export interface Locais {
  value: string;
  viewValue: string;
}
export interface Tipos {
  value: string;
  viewValue: string;
}
export interface Consx {
  value: string;
  viewValue: string;
}
export interface CONFIGURACAO {
  modeloplaca: string;
  marcaplaca: string;
  qtdplaca: number;
  potplaca: number;
  modeloinv: string;
  marcainv: string;
  qtdinv: number;
  potinv: number;
}

@Component({
  selector: 'app-solicitahomologacao',
  templateUrl: './solicitahomologacao.component.html',
  styleUrls: ['./solicitahomologacao.component.css']
})
export class SolicitahomologacaoComponent implements OnInit {
  formGroup: FormGroup;
  potnominal = 0;
  potpico = 0;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  selectedValue1: string;
  selectedValue2: string;
  selectedValue3: string;
  itemsx: MenuItem[];

  semmapa = true;
  enviado = false;
  naoenviado = false;
  ultprogup: number;
  lasti = 0;
  configurcao = [];
  validaupload = 'false';
  Cons: any[];
  filesUp: any[];
  esconde = false;
  menu1 = true;
  menu2 = false;
  menu3 = false;
  menu4 = false;
  concessionaria: string;
  selecionadacons: string;
  homologacao = 'HL';
  homol = '';
  naocontinua = false
  aumentocarga: string = null;
  datanagora = '';
  URL: string;
  pdf = false;
  criafoldex = false;
  dialogo: any;
  dadoshomol = [];
  camponull = false;
  dataxs= [{vakir:''}];
  public hostUrl: string = "https://www.talktunnels.com/api/_functions/FileManager/";
  public ajaxSettings: object;
  public navigationPaneSettings: object;
  formm = false;
  conc = [];
  links = [];
  fomssaved: FORMS[];
  id: any;
  kits: kitconfiguracao[];
  lastindex: number;
  distanciaPlacaInversor: number = null;
  distanciaInversorQuadro: number = null;
  descricaodokit: string = null;
  dadosolicitante: any;
  solicitante: string;
  novaid: string;
  marcainv2= ''
  modeloinv2= ''
  potinv2 = 0;
  qtdinv2= 0;
  marcainv3= ''
  modeloinv3= ''
  potinv3= 0;
  qtdinv3= 0;
  marcainv4= ''
  modeloinv4= ''
  potinv4= 0;
  qtdinv4= 0;
  marcainv5= ''
  modeloinv5= ''
  potinv5= 0;
  qtdinv5= 0;
  codhomolocacao= ''
  bairro= ''
  numerocliente= ''
  nome= ''
  ruacliente= ''
  cep= ''
  municipiocliente= ''
  cpfCnpj= ''
  eMailcomum= ''
  celcomum= ''
  ramoDeAtividade= ''
  tipoAtividade= ''
  kwpplacas= ''
  Areadasplacas= ''
  localInstalacao= ''
  tipoDeTelhado= ''
  latitude = null;
  longitude = null;
  naopode = false
  marcaInversor= ''
  padraoDeEntrada= ''
  disjuntorentrada= ''
  telefoneparceiro= ''
  statusColetaDados= ''
  statusHomologacao= ''
  statusProjeto= ''
  statusVistoria= ''
  modeloPlaca: string;
  entradaAreaSub = ''
  disjuntoraumentocarga= ''
  estado= ''
  aviso= ''
  numclientecemig= ''
  numeroinstalacao= ''
  cargainstalada= ''
  classetensao= ''
  qtdplacas= ''
  qtdinversor= ''
  marcaplacas= ''
  modeloinversor= ''
  potenciainversor= ''
  disjuntorgeracao= ''
  tipodisjgeracao= ''
  tpdaumentoDeCarga= ''
  primeiraparcela= ''
  segundaparcela= ''
  responsavel= ''
  validacao: {};
  validacao3: {};
  complemento= ''
  abriumapa = 'false'
  resourcesLoaded = '';
  linksform = [];
  todosfiles = false;
  mapabase64: string;
  mapafile:any;
  tokenx:any;
  codig : any;
  adbx: any;
  acesstok:any;
  dbcAutho = [];
  autorizado = 'true';
  public dbxAuth: DbxAuth;
  public ramaistipos: Object[] = [
    { Id: '', tipo: '' },
    { Id: 'Aéreo', tipo: 'Aéreo' },
    { Id: 'Subterrâneo', tipo: 'Subterrâneo' }
  ];
  public fields: Object = { text: 'tipo', value: 'Id' };
  public Consx: Object = { text: 'value', value: 'viewValue' };
  public height: string = '220px';
  public locais: Object = [
    { Id: 'Telhado', tipo: 'Telhado' },
    { Id: 'Laje', tipo: 'Laje' },
    { Id: 'Solo', tipo: 'Solo' },
    { Id: 'Grama seca', tipo: 'Grama seca' }
  ];
  public tipos: Object  = [
    { Id: 'Telhado Cerâmico', tipo: 'Telhado Cerâmico' },
    { Id: 'Telha Fibrocimento', tipo: 'Telha Fibrocimento' },
    { Id: 'Telhado Metálico', tipo: 'Telhado Metálico' },
    { Id: 'Telhado Plástico', tipo: 'Telhado Plástico' },
    { Id: 'Laje', tipo: 'Laje' },
    { Id: 'Grama', tipo: 'Grama' },
    { Id: 'Asfalto', tipo: 'Asfalto' },
    { Id: 'Concreto', tipo: 'Concreto' },
    { Id: 'Cascalho', tipo: 'Cascalho' },
    { Id: 'Solo', tipo: 'Solo' }
  ];
  public faseamentotipos: Object[] = [
    { Id: 'Monofásico', tipo: 'Monofásico' },
    { Id: 'Bifásico', tipo: 'Bifásico' },
    { Id: 'Trifásico', tipo: 'Trifásico' }
  ];
  public simnao: Object[] = [
    { Id: 'Sim', tipo: 'Sim' },
    { Id: 'Não', tipo: 'Não' }
  ];
  public fases: Object[] = [
    { Id: '127V', tipo: '127V' },
    { Id: '220V', tipo: '220V' },
    { Id: '127V/220V', tipo: '127V/220V' },
    { Id: '220V/380V', tipo: '220V/380V' },
    { Id: '13.8kV', tipo: '13.8kV' }
  ];
  emfalta = '';
  settings = {
    defaultStyle: false,
    actions: false,
    attr: {
      class: 'table table-bordered' // this is custom table scss or css class for table
    },
    columns: {
      conessionaria: {
        title: 'Concessionária', filter: false
      },
      link: {
        title: 'Link', filter: false
      }
    }
  };
  val = false;
  todosupload = false;
  // apagar depois
  productForm: FormGroup;
  // apagar depois
  display = 'false';
  wpp: string;
  meunome: string;
  nomearquivo=''
  consumo = '';
  agrupado= '';
  coletivo= '';
  rateio= '';
  alerta = false;
  constructor(private service: HomologacoesserviceService,
    private sanitizer: DomSanitizer,
    private dialogService: NbDialogService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private cookieService: CookieService)
    { this.uploader = new FileUploader({
      url: '',
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        console.log(this.nomearquivo)
        return new Promise((resolve, reject) => {
          resolve({
            name: this.nomearquivo,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.response = '';
    this.uploader.response.subscribe(res => this.response = res);
  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  showToasterror(status: NbComponentStatus) {
    this.toastrService.show(status, `Erro: Verifique todos os campos!`, { status });
  }
  showToast1(status: NbComponentStatus) {
    this.toastrService.show(status, 'Sucesso: Homologação ' + this.codhomolocacao + ' enviada com sucesso', { status });
  }
  nformcons() {
    console.warn(
      this.productForm.value);
    console.log(this.productForm.value.dadosinstal.classetensao)
  }
  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this', closeOnBackdropClick: true });
  }
  mutedisplay() {
    this.display = 'true'
  }
  openWithBackdrop(a) {
    this.open(a);
  }
  abrealerta() {
    document.getElementById("myCheck").click();
  }
  nomex(a) {
    console.log(a)
    this.nomearquivo = a
  }


  verificacampos() {
    if ( this.coletivo === ''  || this.rateio === ''  ||  this.agrupado === '' ||  this.consumo === '' || this.disjuntorentrada === '' || this.entradaAreaSub === '' || this.padraoDeEntrada === '' || this.classetensao === '' || this.distanciaPlacaInversor === null || this.distanciaInversorQuadro === null|| this.localInstalacao === '' || this.tipoDeTelhado === '' || this.latitude === '' || this.longitude === ''
    || this.productForm.value.selling_points[0].qtdmodulos === null ||
       this.productForm.value.selling_points[0].marcamodulos === null ||
this.productForm.value.selling_points[0].potenciamodulo === null ||
this.productForm.value.selling_points[0].modelomodulos === null||
this.productForm.value.selling_points[0].modeloinv === null ||
this.productForm.value.selling_points[0].potenciinv === null||
this.productForm.value.selling_points[0].marcainv === null ||
      this.productForm.value.selling_points[0].qtdinv === null
      || this.productForm.value.selling_points[0].qtdmodulos === '' ||
      this.productForm.value.selling_points[0].marcamodulos === '' ||
this.productForm.value.selling_points[0].potenciamodulo === '' ||
this.productForm.value.selling_points[0].modelomodulos === ''||
this.productForm.value.selling_points[0].modeloinv === '' ||
this.productForm.value.selling_points[0].potenciinv === ''||
this.productForm.value.selling_points[0].marcainv === '' ||
      this.productForm.value.selling_points[0].qtdinv === ''
      ||
      this.descricaodokit === null) {
      this.naopode = true
    }
    else {
      this.menumuda(3)
    }
  }

  verificacampos1() {
    if ( this.coletivo === ''  || this.rateio === ''  ||  this.agrupado === '' ||  this.consumo === '' || this.disjuntorentrada === '' || this.entradaAreaSub === '' || this.padraoDeEntrada === '' || this.classetensao === '' || this.distanciaPlacaInversor === null || this.distanciaInversorQuadro === null|| this.localInstalacao === '' || this.tipoDeTelhado === '' || this.latitude === '' || this.longitude === ''
    ) {
      this.naocontinua = true
    }
    else {
      this.menumuda(2)
    }
  }
  OnbeforeSend (args){ 
    if(args.action==="Upload"){
      //this.enviouarquivo();
      //this.notificaarqu() 
      var data = JSON.parse(args.ajaxSettings.data);  
      // Add custom parameter userid  
       data.push({"path2":this.homol}); 
       data.push({"myid":this.id}); 
      // Add custom parameter in ajax settings  
       args.ajaxSettings.data = JSON.stringify(data); 
    } 
    console.log('ONBEFORE',args)
    var data = JSON.parse(args.ajaxSettings.data);  
 // Add custom parameter userid  
  data["path2"] = this.homol; 
  data["myid"] = this.id; 

 // Add custom parameter in ajax settings  
  args.ajaxSettings.data = JSON.stringify(data);  
  } 

  toolbarClicks(args) { 
    if (args.item.text == "Custom") { 
        // Get the selected Files details. 
        //var selectedFiles = fileManager.getSelectedFiles()[0]; 
        // Get the path of FileManager component. 
        //var path = selectedFiles.filterPath + selectedFiles.name;
        //console.log(path) 
    } 
} 

  criasfold(){
    this.service
    .criafolder({
      path: '/',
      myid:this.id,
      name: this.homol,
      data: [{name: this.homol,
      size: '4.00 KB',
      isFile: false,
      dateModified: '2022-05-08T13:59:26.003Z',
      dateCreated: '2022-05-08T13:59:26.003Z',
      type: '',
      filterPath: '/',
      permission: null,
      hasChild: true,
      _fm_id: 'fe_tree'}
      ]})
    .subscribe((dados) => {
      console.log()
      if (dados ) {
        this.dataxs = dados
        console.log(this.dataxs)
       if(this.dataxs['vakir'] ==='ok'){
        this.navigationPaneSettings = { maxWidth: '850px', minWidth: '140px', visible: false};
        this.ajaxSettings = {
          path2: this.homol,
          url: this.hostUrl + 'ap',
          showHiddenItems: true,
          getImageUrl: this.hostUrl + 'GetImage',
          uploadUrl: this.hostUrl + 'Upload',
          downloadUrl: this.hostUrl + 'Download'
      };
        this.criafoldex = false
        this.menu3 = true
       }
 
      }
    })
  }
  verifyup(dialog) {
    if (this.uploader.queue.length > 0) {
      this.todosupload = true;
      this.clicabotao()
      this.uploadalgo(dialog)
    }
    if (this.uploader.queue.length === 0) {
      this.todosfiles = true;
    }
  }
  
  autorizacao() {
    this.tokenx =  localStorage.getItem('tokenx');
    this.service.getdbx({ token: this.tokenx })
    .subscribe((data) => {
      console.log(data)
      this.codig = data
      this.decryptData(this.codig.cod)}
      )
}
decryptData(data:any) {
  try {
    const bytes = CryptoJS.AES.decrypt(data, this.tokenx);
    if (bytes.toString()) {
      var dadox = (JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
      console.log(dadox)
      this.adbx = dadox;
      this.autoh();
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}


autoh(){
  this.acesstok =  new Dropbox({ accessToken: this.adbx[0].accessToken });
      // Begin authentication process if credentials not found
      console.log(this.adbx[0].accessToken )
    if (this.autorizado === 'true') {
          const authUrl = this.router.url;
          const parameters = authUrl.split('#')[1] || '';
          if (this.autorizado === 'true') {
              const arrParams = parameters.split('&') || [];
              if (this.autorizado === 'true') {
                  const authObj: DbxAuth = { isAuth: false };
                  // tslint:disable-next-line: prefer-for-of
                  authObj.accessToken = this.adbx[0].accessToken;
                  authObj.tokenType = this.adbx[0].tokenType;
                  authObj.uid = this.adbx[0].uid;
                  authObj.accountId = this.adbx[0].accountId;
                  if (authObj.accessToken && authObj.tokenType && authObj.uid && authObj.accountId) {
                      authObj.isAuth = true;
                      this.dbxAuth = authObj;
                  }
                }
          }
          console.log('esse eh dbxauto',this.dbxAuth)
          this.service.changedauth(this.dbxAuth)
          // Store credentials into Auth-service and into localStorage
          // if (this.dbxAuth.isAuth) {
           //    this.authService.storeAuth(this.dbxAuth);
           //    this.podeupar = true;
        //   }
      // } else {
      }
}

a(ev){
  console.log(ev)
  for (let i = 0; i < this.uploader.queue.length; i++) {
    if(this.uploader.queue[i]['file'].name === ev){
      var tipo = this.uploader.queue[i]['file'].name.split('.').pop();
      this.uploader.queue[i]['file'].name = this.uploader.queue[i]['file'].name.split('.')[0]+'(' + i +')' +'.'+ tipo;
    }
  }
  console.log(this.uploader.queue)
}


  ngOnInit() {
        this.disjuntorentrada = "63";
        this.entradaAreaSub = "Aéreo";
        this.padraoDeEntrada = "Trifásico";
        this.classetensao = "127V/220V";
        this.disjuntoraumentocarga = null;
        this.tpdaumentoDeCarga = null;
        this.distanciaPlacaInversor = 10;
        this.distanciaInversorQuadro = 10;
        this.localInstalacao = 'Telhado';
        this.tipoDeTelhado = 'Telhado Cerâmico';
        this.descricaodokit = "Dimensionamento de acordo com a equipe Sollux"
        this.consumo= "500";
        this.agrupado=  "Não";
        this.coletivo=  "Não";
        this.rateio= "Sim";
        this.latitude ="-21.489941"
        this.longitude ="-54.843750"


    this.autorizacao()
    this.uploader.onAfterAddingAll = (files: FileItem[]) => {
      files.forEach(fileItem => {
          var tipo = fileItem.file.name.split('.').pop();
        //fileItem.file.name = fileItem.file.name.split('.')[0]+'(' + fileItem.index +')' +'.'+ tipo;
         //(<any>fileItem).previewUrl = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
          console.log(fileItem.file.name)
           this.a(fileItem.file.name)
          });
  };
    this.itemsx = [

      {
        label: 'Editar',
        icon: 'pi pi-user-edit',
        command: (event) => {
          this.menumuda(1)
        }
      },

      {
        label: 'Configuraçāo do kit',
        icon: 'pi pi-calendar-plus',
        command: (event) => {
          this.verificacampos1()
        }
      },

      {
        label: 'Arquivos e envio',
        icon: 'pi pi-folder',
        command: (event) => {
          this.verificacampos() 
        }
      }
    ];
    this.service.currentNome.subscribe(dados => {
      if (dados !== this.meunome && dados !== '') {
        this.meunome = dados;
        console.log(this.meunome);
      }
    });
    this.service.currentwpp.subscribe(dados => {
      if (dados !== this.wpp && dados !== '') {
        this.wpp = dados;
        console.log(this.wpp);
      }
    });
    this.service.currentelatitude.subscribe(nome => this.latitude = nome);
    this.service.currentelongitude.subscribe(nome => this.longitude = nome);
    this.service.currentabriumapa.subscribe(nome => {
    if (nome !== this.abriumapa && nome !== '') 
      {
        this.abriumapa = nome;
        if (this.abriumapa === 'true') { 
          this.atualizarform() }
      }
    });
    for (let i = 0; i < 5; i++) {
      // tslint:disable-next-line: no-unused-expression
      this.configurcao.push({
        potinv: null,
        qtdinv: null,
        modeloinv: null,
        marcainv: null,
        marcaplaca: null,
        qtdplaca: null,
        modeloplaca: null,
        potplaca: null
      });
    }
    this.datanagora = (((Date.now()) / 1000).toFixed(0)).toString()
    this.homol = this.homologacao + this.datanagora;
    this.service.currentNome.subscribe(nome => this.solicitante = nome);
    this.service.currentid.subscribe(nome => this.id = nome);
    console.log(this.id)
    /* Initiate the form structure */
    this.productForm = this.fb.group({
      title: [],
      selling_points: this.fb.array([this.fb.group({
        qtdmodulos: null,
        marcamodulos: null,
        modelomodulos: null,
        potenciamodulo: null,
        qtdinv: null,
        marcainv: null,
        modeloinv: null,
        potenciinv: null,
      })]),
      dadosinstal: this.fb.group({
        digeral: new FormControl(this.disjuntorentrada, Validators.required),
        distanciaplacaquadro: new FormControl(this.distanciaPlacaInversor, Validators.required),
        distanciainversorquadro: new FormControl(this.distanciaInversorQuadro, Validators.required),
        padraoentrada: new FormControl(this.padraoDeEntrada, Validators.required),
        entradaareasub: new FormControl(this.entradaAreaSub, Validators.required),
        longitude: new FormControl(this.longitude, Validators.required),
        latitude: new FormControl(this.latitude, Validators.required),
        classetensao: new FormControl(this.classetensao, Validators.required),
        localinst: new FormControl(this.localInstalacao, Validators.required),
        tipotelhado: new FormControl(this.tipoDeTelhado, Validators.required),
        descricaodokit: new FormControl(this.descricaodokit, Validators.required),
        aumentocarga: new FormControl(this.disjuntoraumentocarga),
        tipoaumentocarga: new FormControl(this.tpdaumentoDeCarga),
        consumo:  new FormControl(this.consumo, Validators.required),
        agrupado: new FormControl(this.agrupado, Validators.required),
        coletivo:  new FormControl(this.coletivo, Validators.required),
        rateio: new FormControl(this.rateio, Validators.required),
      }),
    });
  
    this.service.currentNome.subscribe(dados => {
      if (dados !== this.codhomolocacao && dados !== '') {
        this.resourcesLoaded = dados;
        this.codhomolocacao = dados;
      }
    });

    this.service.dowconcessionarias()
      .subscribe(dados => {
        if (dados) {
          this.Cons = dados;
          console.log(this.Cons)
        }
      });

    console.log(this.id);
    this.service.gsolicitante({ ID: this.id })
      .subscribe(dados => {
        if (dados !== this.dadosolicitante) {
          this.dadosolicitante = dados;
          console.log('solicita é ')
          console.log(this.dadosolicitante.nick.toUpperCase())
          console.log(this.dadosolicitante.emailparceiro)

        }
      });

  }

  postnovahomol() {
    this.service
      .postaemail(
        {
          "fromAddress": "jonnathan.lopes@solluxengenharia.com.br",
          "toAddress": "escritorio.projetos@solluxengenharia.com.br,engenharia@solluxengenharia.com.br",
          "subject": "Nova Homologação",
          "content": '🌞  O parceiro ' + '*' + this.meunome + '*' + ' Solicitou uma nova homologação com número ' + '*' + this.homol + '*' + ' .Favor iniciar coleta de dados'
       }
      )
      .subscribe((dados) => {
        console.log(dados)
      });
  }
  get sellingPoints() {
    return this.productForm.get('selling_points') as FormArray;
  }
  console(e) {
    console.log(e)
  }
  menumuda(a) {
    if (a === 1) {
      this.menu1 = true;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false
    }
    if (a === 2) {
      this.menu1 = false;
      this.menu2 = true;
      this.menu3 = false;
      this.menu4 = false
    }
    if (a === 3) {
      this.criafoldex = true
      this.menu1 = false;
      this.menu2 = false;
      this.menu4 = false
    }
    if (a === 4) {
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = true
    }
  }
  addSellingPoint() {
    this.lasti = this.lasti + 1;
    this.sellingPoints.push(this.fb.group({
      qtdmodulos: '',
      marcamodulos: '',
      modelomodulos: '',
      potenciamodulo: '',
      qtdinv: '',
      marcainv: '',
      modeloinv: '',
      potenciinv: ''
    }));
  }
  teste() {
    console.log(this.productForm.value);
  }
  deleteSellingPoint(index) {
    console.log(index)
    this.sellingPoints.removeAt(index);
    this.lasti = this.lasti - 1;
    console.log(this.lasti)

  }

  dataChanged(event) {
    console.log(event)
    this.concessionaria = event.itemData.value;
    this.puxauploads();
  }
  puxauploads() {
    this.service.arquivosnecessarios({ cons: this.concessionaria })
      .subscribe(dados => {
        if (dados !== this.filesUp) {
          this.filesUp = dados;
        }
      });
  }

  idmuda(i) {
    this.URL = this.links[i];
  }

  closemodal() {
    $(function () {
      $('#exampleModal').modal('toggle');
    });
    this.nloadFile();
  }


  mostralinks() {
    this.formm = true;
  }

  getcons() {
    this.service.dowconcessionarias()
    .subscribe(dados => {
      if (dados) {
        this.Cons = dados;
        console.log(this.Cons)
      }
    });
  }

  nloadFile() {
    // tslint:disable-next-line: max-line-length
    generate({
      ncliente: this.numclientecemig,
      ninstal: this.numeroinstalacao,
      titular: this.nome,
      classe: this.ramoDeAtividade,
      cpf: this.cpfCnpj,
      rua: this.ruacliente,
      numerorua: this.numerocliente,
      bairro: this.bairro,
      cep: this.cep,
      municpio: this.municipiocliente,
      estado: this.estado,
      email: this.dadosolicitante.emailparceiro,
      lat: this.latitude,
      long: this.longitude,
      ci: this.cargainstalada,
      de: this.disjuntorentrada,
      te: this.classetensao,
      da: this.disjuntorentrada,
      potgeracao: this.potenciainversor,
      qtdmod: this.qtdplacas,
      fabmod: this.marcaplacas,
      modmodu: this.modeloPlaca,
      potmod: this.kwpplacas,
      qtdinv: this.qtdinversor,
      fabinv: this.marcaInversor,
      modinv: this.modeloinversor,
      potinv: this.potenciainversor,
      area: this.Areadasplacas,
      celular: this.dadosolicitante.celparceiro,
      complemento: this.complemento,
      linkdoc: this.URL
    });
  }

  onUserRowSelect(event): void {
    this.URL = event.data['link']
    console.log(event.data['link']);
    this.nloadFile();
  }

  atualizarform() {
    this.service.currentForm.subscribe(dados => {
      if (dados !== this.fomssaved) {
        this.fomssaved = dados;
        console.log(dados)
        this.disjuntorentrada = dados['diger'];
        this.entradaAreaSub = dados['ramal'];
        this.padraoDeEntrada = dados['tipoentrada'];
        this.classetensao = dados['classetensao'];
        this.disjuntoraumentocarga = dados['aumentocarga'];
        this.tpdaumentoDeCarga = dados['tipoaumento'];
        this.distanciaPlacaInversor = dados['distinv'];
        this.distanciaInversorQuadro = dados['distinvquadro'];
        this.localInstalacao = dados['localinst'];
        this.tipoDeTelhado = dados['tipoinst'];
        this.descricaodokit = dados['descricao'];
        this.consumo= dados['consumo'];
        this.agrupado=  dados['agrupado'];
        this.coletivo=  dados['coletivo'];
        this.rateio= dados['rateio'];
      }
    });
  }
  shx(a) {
    console.log(a)
    console.log(this.entradaAreaSub)
  }
  abremapa() {
    console.log(this.productForm.value.dadosinstal)
    this.service.changemapa('true');
    this.cookieService.set( 'diger', (this.productForm.value.dadosinstal.entradaAreaSub) );
    var formu = {
      diger: this.productForm.value.dadosinstal.digeral,
      ramal: this.productForm.value.dadosinstal.entradaareasub,
      tipoentrada: this.productForm.value.dadosinstal.padraoentrada,
      classetensao: this.productForm.value.dadosinstal.classetensao,
      aumentocarga: this.productForm.value.dadosinstal.aumentocarga,
      tipoaumento: this.productForm.value.dadosinstal.tipoaumentocarga,
      distinv: this.productForm.value.dadosinstal.distanciaplacaquadro,
      distinvquadro: this.productForm.value.dadosinstal.distanciainversorquadro,
      localinst: this.productForm.value.dadosinstal.localinst,
      tipoinst: this.productForm.value.dadosinstal.tipotelhado,
      descricao: this.productForm.value.dadosinstal.descricaodokit,
      consumo:  this.productForm.value.dadosinstal.consumo,
      agrupado:  this.productForm.value.dadosinstal.agrupado,
      coletivo:  this.productForm.value.dadosinstal.coletivo,
      rateio: this.productForm.value.dadosinstal.rateio,
    }
    console.log(formu)
    this.service.mudaform(formu);
    this.router.navigateByUrl('/mapa');
  }

  mapafileupload() {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      this.acesstok.filesUpload({ path: "/aplicativos/homofold/"+ this.homol + "/" + this.mapafile.name, contents: this.mapafile })
        .then(response => {
          resolve();
        })
        .catch(error => reject())
        .finally(() => this.solicitahomologa() 
        );
    });
  }

  asyncUpload(i) {
    return new Promise((resolve, reject) => {
      console.log(this.ultprogup);
      // tslint:disable-next-line: prefer-for-of
      console.log(this.uploader.queue[i]['isSuccess'])
      // tslint:disable-next-line: max-line-length
      this.acesstok.filesUpload({ path: "/aplicativos/homofold/"+ this.homol + "/" + this.uploader.queue[i]['file'].name, contents: this.uploader.queue[i]['_file'] })
        // tslint:disable-next-line: only-arrow-functions
        .then(response => {
          this.enviado = true;
          this.uploader.queue[i]['isUploaded'] = true;
          this.uploader.queue[i]['isSuccess'] = true;
          resolve();
        })
        .catch(error => reject())
        .finally(() => this.progresso());
    });
  }


  clicabotao(){
    this.service.currentBae64Source.subscribe(dados => {
      console.log(dados)
      if (dados !== '0') {
        console.log('this.semmapa = false')
        console.log(dados)
        console.log('esses dados mapadados')
        this.mapabase64 = dados;
        this.mapafile = this.dataURLtoFile(this.mapabase64,'print_mapa.png');
        console.log(this.mapafile)
        this.semmapa = false
        //this.uploadalgo()
      }
      if (dados === '0') {
        console.log('this.semmapa = true')
        this.semmapa = true
        //this.uploadalgo()
      }
    });
   }
  
 dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}
  progresso() {
    this.ultprogup = 100 / (this.uploader.queue.length);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (this.uploader.queue[i]['isSuccess'] === true) {
        this.uploader.progress = this.ultprogup * (i + 1);
      }
    }
    if (this.uploader.progress === 100) {
      if (this.semmapa === false) {
        this.mapafileupload();
      }
      if (this.semmapa === true) {
        this.solicitahomologa();
      }
    }
  }

  resetatudo() {
    this.enviado = false;
    this.naoenviado = false;
    this.ultprogup = 0;
  }

  async uploadalgo(dia) {
    this.dialogo = dia;
    for (let i = 0; i < this.uploader.queue.length; i++) {
      await this.asyncUpload(i);
    }
  }


  
  notificatodos() {
    console.log('notificando')
    this.service.swnotifica({ titulo: 'Nova Homologação !!', msg: '🌞  O parceiro ' + '*' + this.meunome + '*' + ' Solicitou uma nova homologação com número ' + '*' + this.homol + '*' + ' .Favor iniciar coleta de dados' }).subscribe((datax: any[]) => {
      if (datax) {
        console.log(datax)
      }
    });
  }


  rota() {
    this.router.navigateByUrl('/homologacoes');
  }

  solicitahomologa() {
    this.spinner.show();
    if (this.disjuntoraumentocarga === undefined) {
      this.aumentocarga = '0';
    }
    if (this.disjuntoraumentocarga !== undefined) {
      this.aumentocarga = this.disjuntoraumentocarga;
    }
    for (let i = 0; i < this.productForm.value.selling_points.length; i++) {
      this.potnominal = this.potnominal + parseFloat(this.productForm.value.selling_points[i].potenciinv)
      this.potpico = this.potpico + parseFloat(this.productForm.value.selling_points[i].potenciamodulo)
      console.log('pico', this.potpico)
      console.log('nominal', this.potnominal)

    }
    this.potnominal = 0;
    this.potpico = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = this.productForm.value.selling_points.length; i < 5; i++) {
      this.productForm.value.selling_points.push({
        qtdmodulos: null,
        marcamodulos: null,
        modelomodulos: null,
        potenciamodulo: null,
        qtdinv: null,
        marcainv: null,
        modeloinv: null,
        potenciinv: null
      })
    }

    const dateinici=  new Date(Date.now()).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    this.service.novahomologacao({
      token: localStorage.getItem('tokenx'),
      concessionaria:this.concessionaria,
      ID: this.id,
      nome: 'Coletando informações',
      endereco: '...',
      cep: '...',
      cidade: 'Coletando informações',
      uf: 'Coletando informações',
      rg: '...',
      cpf: '...',
      nomeparceiro: this.dadosolicitante.nick.toUpperCase(),
      emailparc: this.dadosolicitante.emailparceiro,
      homologato: this.homol,
      email: this.dadosolicitante.emailparceiro,
      cel: this.dadosolicitante.celparceiro,
      classe: '...',
      atividade: '...',
      potkwp: this.potpico,
      area: '...',
      localinst: this.productForm.value.dadosinstal.localinst,
      tipotelhado: this.productForm.value.dadosinstal.tipotelhado,
      inclinacao: '...',
      coords: '...',
      orientacao: '...',
      descricao: this.productForm.value.dadosinstal.descricaodokit,
      distanciaplacaquadro: this.productForm.value.dadosinstal.distanciaplacaquadro,
      distanciainversorquadro: this.productForm.value.dadosinstal.distanciainversorquadro,
      padraoentrada: this.productForm.value.dadosinstal.padraoentrada,
      digeral: this.productForm.value.dadosinstal.digeral,
      telefone: '...',
      codhomol: this.homol,
      statusdados: 'Pendente',
      statusprojeto: 'Pendente',
      statushomologacao: 'Pendente',
      statusvistoria: 'Pendente',
      modeloplaca1: this.productForm.value.selling_points[0].modelomodulos,
      entradaareasub: this.productForm.value.dadosinstal.entradaareasub,
      datainicio: dateinic2,
      time1: dateinic2,
      time2: '',
      time3: '',
      time4: '',
      time1f: '',
      time2f: '',
      time3f: '',   
      time4f: '',
      datafim: '...',
      art: '...',
      disjuntoraumentocarga: this.productForm.value.dadosinstal.aumentocarga,
      solicitante: this.dadosolicitante.nick.toUpperCase(),
      iddono: this.id,
      aviso: '...',
      bairro: '...',
      longitude: this.productForm.value.dadosinstal.latitude,
      latitude: this.productForm.value.dadosinstal.longitude,
      numerocliente: '...',
      numeroinstalacao: '...',
      numclientecemig: '...',
      classetensao: this.productForm.value.dadosinstal.classetensao,
      consumo: this.productForm.value.dadosinstal.consumo,
      agrupado:this.productForm.value.dadosinstal.agrupado,
      coletivo: this.productForm.value.dadosinstal.coletivo,
      rateio:this.productForm.value.dadosinstal.rateio,
      qtdplaca1: this.productForm.value.selling_points[0].qtdmodulos,
      marcaplaca1: this.productForm.value.selling_points[0].marcamodulos,
      disjuntorgeracao: '...',
      tipodisjgeracao: '...',
      tpdaumentodeCarga: this.productForm.value.dadosinstal.tipoaumentocarga,
      cargainstalada: '...',
      pagamentoentrada: 'A RECEBER',
      pagamentosegundaparte: 'A RECEBER',
      responsavel: '...',
      complemento: '...',
      marcainv1: this.productForm.value.selling_points[0].marcainv,
      qtdinv1: this.productForm.value.selling_points[0].qtdinv,
      modeloinv1: this.productForm.value.selling_points[0].modeloinv,
      potinv1: this.productForm.value.selling_points[0].potenciinv,
      marcainv2: this.productForm.value.selling_points[1].marcainv,
      modeloinv2: this.productForm.value.selling_points[1].modeloinv,
      qtdinv2: this.productForm.value.selling_points[1].qtdinv,
      potinv2: this.productForm.value.selling_points[1].potenciinv,
      marcainv3: this.productForm.value.selling_points[2].marcainv,
      modeloinv3: this.productForm.value.selling_points[2].modeloinv,
      qtdinv3: this.productForm.value.selling_points[2].qtdinv,
      potinv3: this.productForm.value.selling_points[2].potenciinv,
      marcainv4: this.productForm.value.selling_points[3].marcainv,
      modeloinv4: this.productForm.value.selling_points[3].modeloinv,
      qtdinv4: this.productForm.value.selling_points[3].qtdinv,
      potinv4: this.productForm.value.selling_points[3].potenciinv,
      marcainv5: this.productForm.value.selling_points[4].marcainv,
      modeloinv5: this.productForm.value.selling_points[4].modeloinv,
      qtdinv5: this.productForm.value.selling_points[4].qtdinv,
      potinv5: this.productForm.value.selling_points[4].potenciinv,
      potenominalusina: this.potnominal,
      potplaca1: this.productForm.value.selling_points[0].potenciamodulo,
      marcaplaca2: this.productForm.value.selling_points[1].marcamodulos,
      modeloplaca2: this.productForm.value.selling_points[1].modelomodulos,
      qtdplaca2: this.productForm.value.selling_points[1].qtdmodulos,
      potplaca2: this.productForm.value.selling_points[1].potenciamodulo,
      marcaplaca3: this.productForm.value.selling_points[2].marcamodulos,
      modeloplaca3: this.productForm.value.selling_points[2].modelomodulos,
      qtdplaca3: this.productForm.value.selling_points[2].qtdmodulos,
      potplaca3: this.productForm.value.selling_points[2].potenciamodulo,
      marcaplaca4: this.productForm.value.selling_points[3].marcamodulos,
      modeloplaca4: this.productForm.value.selling_points[3].modelomodulos,
      qtdplaca4: this.productForm.value.selling_points[3].qtdmodulos,
      potplaca4: this.productForm.value.selling_points[3].potenciamodulo,
      marcaplaca5: this.productForm.value.selling_points[4].marcamodulos,
      modeloplaca5: this.productForm.value.selling_points[4].modelomodulos,
      qtdplaca5: this.productForm.value.selling_points[4].qtdmodulos,
      potplaca5: this.productForm.value.selling_points[4].potenciamodulo,
    })
      .subscribe(dados => {
        if (dados["status"] === "homologado") {
          console.log(dados)
          //this.novatarefa();
          this.spinner.hide();
          this.enviouhomol()
          this.notificatodos()
          this.postnovahomol();
          this.solhomol()
          this.rota()
        }
        if (dados["status"] !== "homologado") {
          console.log(dados)
          this.alerta = true
          this.spinner.hide()
        }
      });
  }

  novatarefa() {
    const dateinici=  new Date(Date.now()).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    this.service.newtaskhomol({
      descricao: 'Coletando informações iniciais para elaboração do projeto',
      status: 'Em andamento',
      datainicio: dateinic2,
      datafim: 'Aguardando',
      responsavel: 'Sollux Engenharia',
      codhomol: this.homol,
    })
      .subscribe(dados => {
        if (dados !== this.validacao3) {
          this.validacao3 = dados;
          console.log(dados);
          if (dados['status'] === 'cadastrado') {
            console.log('inserido');
          }
          if (dados['status'] === 'erro') {
            console.log('não inserido');
          }
        }
      });
  }

  enviouhomol() {
    var grupo = "553892515427-1633011809";
    var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
    //for (let i = 0; i < fones.length; i++) {
    this.service.sendtext({
      sessionName: 'sollux',
      number: grupo,
      text: '⚠️  O parceiro ' + '*' + this.meunome + '*' + ' Solicitou uma nova homologação com número ' + '*' + this.homol + '*' + ' .Favor iniciar coleta de dados'
    })
      .subscribe(dados => {
        if (dados) {
          console.log(dados);
        }
      });
  }

  solhomol() {
    var grupo = '553892515427-1633011809'
    var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
    //for (let i = 0; i < fones.length; i++) {
    this.service.enviaemail({
      sessionName: 'sollux',
      number: this.wpp,
      text: '⚠️ Caro parceiro rebemos sua homologação de número ' + '*' + this.homol + '*' + ' .Iremos iniciar a elaboração da documentação inicial e lhe manteremos informadosß'
    })
      .subscribe(dados => {
        if (dados) {
          console.log(dados);
        }
      });
  }
}
