<div class="left-panel">
    <div class="overview-scheduler">
        <ejs-schedule startHour='07:00' endHour='19:00' [(currentView)]="currentView" [workHours]="scheduleHours" #scheduleObj height='100%' (actionComplete)="onActionComplete($event)" (eventRendered)="oneventRendered($event)" [eventSettings]="eventSettings">
            <e-resources>
                <e-resource field='EmployeeId' title='Empregados' name='Employee' [dataSource]='employeeDataSource'
                  [allowMultiple]='allowMultiple' textField='Text' idField='Id' groupIDField="GroupId" colorField='Color'>
                </e-resource>
              </e-resources>
            <e-views>
                <e-view option="Day"></e-view>
                <e-view option="Week"></e-view>
                <e-view option="WorkWeek"></e-view>
                <e-view option="Month"></e-view>
                <e-view option="Year"></e-view>
                <e-view option="Agenda"></e-view>
                <e-view option="TimelineDay"></e-view>
                <e-view option="TimelineWeek"></e-view>
                <e-view option="TimelineWorkWeek"></e-view>
                <e-view option="TimelineMonth"></e-view>
                <e-view option="TimelineYear"></e-view>
            </e-views>
        </ejs-schedule>
        <ejs-contextmenu #menuObj cssClass='schedule-context-menu' target='.e-schedule' [items]='menuItems' (beforeOpen)='onContextMenuBeforeOpen($event)' (select)='onMenuItemSelect($event)'></ejs-contextmenu>
    </div>
</div>
