<!-- BOÕES CONTROLE -->
<nb-card style="margin-right: 0.5rem;margin-left: 0.5rem;margin-top: 0.5rem
    !important; border: 1px solid rgb(171, 171,
    255)" status="success">
    <nb-card-header style="background-color: white; color: black; padding: 0rem
        !important">
        <p-menubar [model]="itemsx">
            <ng-template pTemplate="start"> </ng-template>
        </p-menubar>
    </nb-card-header>
    <nb-card-body style="padding: 0rem 0rem !important;">
        <!-- DADOS INICIAIS -->
        <div style="margin-top: 0px; border-bottom: none;" class="mb-2" *ngIf="menu1 === true">
            <ejs-tab   id="element" #element  heightAdjustMode='Auto'>
                <e-tabitems>
                    <e-tabitem cssClass="withoutIcon"  [header]='headerText[0]'>
                        <ng-template cssClass="withoutIcon" #content>

                            <div style="background-color:
                                #F7F7F7;border-radius:0% !important" class="card
                                form-row">
                                <div style="max-height: 50px !important;max-width:100% !important"
                                    class="form-row">
                                    <div class="form-group col-4 ml-1">
                                        <button class="mr-2" mat-flat-button
                                            (click)="mediames=
                                            true" style="border-radius:0%
                                            !important;background-color:
                                            #e9ecef">
                                            Análise Mensal
                                        </button>
                                        <button mat-flat-button
                                            (click)="grupoax=
                                            true" style="background-color:
                                            #e9ecef">
                                            Grupo A
                                        </button>
                                    </div>

                                </div>
                                <div style="position: absolute;
                                    right: 1rem;" class="form-group col-4">
                                    <div style="margin-right: 0px;">

                                        <div style="font-weight: bold"
                                            class="input-group mb-3">
                                            <input [value]="numucs"
                                                (input)="numucs=
                                                $event.target.value"
                                                type="number" [min]="1"
                                                [max]="5"
                                                (change)="criaucs()"
                                                class="form-control"
                                                id="consumo_fixo" />
                                            <div class="input-group-append">
                                                <span style="font-size: 11px
                                                    !important"
                                                    class="input-group-text">Número
                                                    de Unidades Consumidoras</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="border: none;
                                    border-radius: 10px;
                                    border-width: 1px;
                                    padding-bottom: 5px;
                                    padding-top: 10px;
                                    padding-left: 10px;
                                    padding-right: 10px;" class="form-row">
                                    <div class="col-md-4">
                                        <div style="margin-right: 20px">
                                            <label style="font-weight: bold"
                                                for="Ramal"> Estado:
                                            </label>
                                            <div style="font-weight: bold"
                                                class="input-group mb-3">
                                                <p-autoComplete
                                                    (onSelect)="cidadecaller(xestadoselecionado)"
                                                    [(ngModel)]="xestadoselecionado"
                                                    [suggestions]="filteredEstados"
                                                    (completeMethod)="filterCountry($event)"
                                                    field="name"
                                                    [dropdown]="true">
                                                    <ng-template let-estadox
                                                        pTemplate="item">
                                                        <div
                                                            class="country-item">
                                                            <div>{{ estadox.name
                                                                }}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-autoComplete>
                                            </div>
                                        </div>
                                        <div style="margin-right: 20px">
                                            <label style="font-weight: bold"
                                                for="Ramal"> Cidade :
                                            </label>
                                            <div style="font-weight: bold"
                                                class="input-group mb-3">
                                                <p-autoComplete
                                                    (onSelect)="radiacaocaller(xcidadeselecionada)"
                                                    [(ngModel)]="xcidadeselecionada"
                                                    [suggestions]="filteredCidades"
                                                    (completeMethod)="filterCidade($event)"
                                                    field="name"
                                                    [dropdown]="true">
                                                    <ng-template let-cidadex
                                                        pTemplate="item">
                                                        <div
                                                            class="country-item">
                                                            <div>{{ cidadex.name
                                                                }}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-autoComplete>
                                            </div>
                                        </div>
                                        <div style="font-weight: bold"
                                            class="form-group">
                                            <label style="font-weight: bold"
                                                for="Ramal"> Radiaçāo :
                                            </label>
                                            <div style="font-weight: bold;
                                                width: 220px !important"
                                                class="input-group mb-3">
                                                <input style="width: 150px
                                                    !important" type="number"
                                                    class="form-control"
                                                    (change)="radiacaoescolhida=
                                                    $event.target.value;
                                                    alterarads= true"
                                                    [value]="radiacaoescolhida"
                                                    id="radiacao_cidade" />
                                            </div>
                                        </div>
                                        <div *ngIf="semtemperatura" class="alert
                                            alert-info">
                                            <strong>Informação: </strong>{{
                                            alerts[0].message }}.
                                        </div>
                                        <div *ngIf="alterarads"
                                            [attr.class]="alerts[1].type">
                                            <strong>Atenção: </strong>{{
                                            alerts[1].message }}.
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div style="margin-right: 10px"
                                            class="p-field">
                                            <label style="font-weight: bold"
                                                for="consumo_fixo">Consumo
                                                Mensal Médio:</label>
                                            <div style="font-weight: bold"
                                                class="input-group mb-3">
                                                <input [value]="consumofixo"
                                                    (change)="consumo()"
                                                    (input)="consumofixo=
                                                    $event.target.value"
                                                    type="number" min="0"
                                                    class="form-control"
                                                    id="consumo_fixo" />
                                                <div class="input-group-append">
                                                    <span
                                                        class="input-group-text">kWh/mês</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin-right: 10px"
                                            class="p-field">
                                            <label style="font-weight: bold"
                                                for="con_anu">Consumo
                                                Anual:</label>
                                            <div class="input-group mb-3">
                                                <input [value]="consumoanual"
                                                    readonly type="number"
                                                    class="form-control"
                                                    id="con_anu" />
                                                <div class="input-group-append">
                                                    <span
                                                        class="input-group-text">kWh</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin-right: 10px"
                                            class="p-field">
                                            <label style="font-weight: bold"
                                                for="con_anu">Potência
                                                Necessária:</label>
                                            <div class="input-group mb-3">
                                                <input readonly
                                                    [value]="potnecessaria0"
                                                    (input)="potnecessaria0=
                                                    $event.target.value"
                                                    type="number"
                                                    class="form-control"
                                                    id="potenciadimensionada" />
                                                <div class="input-group-append">
                                                    <span
                                                        class="input-group-text">kWp</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div>
                                            <label style="font-weight: bold"
                                                for="eficiencia">Eficiência %: (100% - perdas)</label>
                                            <div class="input-group mb-3">
                                                <input value="80"
                                                    [value]="eficiencia"
                                                    (input)="eficiencia=
                                                    $event.target.value"
                                                    type="number" value="80"
                                                    min="0" max="100" float
                                                    maxlength="3"
                                                    class="form-control"
                                                    id="eficiencia" />
                                                <div class="input-group-append">
                                                    <span
                                                        class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label style="font-weight: bold"
                                                for="razao">Razão de
                                                consumo (100% = consumo total):</label>
                                            <div class="input-group mb-3">
                                                <input value="100"
                                                    [value]="razao"
                                                    (input)="razao=
                                                    $event.target.value"
                                                    value="100" min="0"
                                                    max="100" type="number"
                                                    class="form-control"
                                                    id="razao" />
                                                <div class="input-group-append">
                                                    <span
                                                        class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label style="font-weight: bold"
                                                for="aumentoconsumo">Aumento
                                                de consumo:</label>
                                            <div class="input-group mb-3">
                                                <input value="0"
                                                    [value]="aumentoconsumo"
                                                    (input)="aumentoconsumo=
                                                    $event.target.value"
                                                    type="number"
                                                    value="0" min="0" max="100"
                                                    class="form-control"
                                                    id="aumentoconsumo" />
                                                <div class="input-group-append">
                                                    <span
                                                        class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-12 p-4">

                                        <button (click)="calcpot()"
                                            mat-flat-button style="margin-top:
                                            27px; color:white;background-color:
                                            #C6230B; width:
                                            100%">
                                            Calcular
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </ng-template>
                    </e-tabitem>

                    <e-tabitem *ngIf="headerText[1]" [header]='headerText[1]'>
                        <ng-template #content>
                            <div style="background-color:
                                #F7F7F7;border-radius:0% !important" class="card
                                p-2">
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div style="margin-right: 10px"
                                            class="p-field">
                                            <label style="font-weight: bold"
                                                for="consumo_fixo">Consumo
                                                Mensal Médio:</label>
                                            <div style="font-weight: bold"
                                                class="input-group mb-3">
                                                <input [value]="consumofixo1"
                                                    (change)="consumo1()"
                                                    (input)="consumofixo1=
                                                    $event.target.value"
                                                    type="number" min="0"
                                                    class="form-control"
                                                    id="consumo_fixo" />
                                                <div class="input-group-append">
                                                    <span
                                                        class="input-group-text">kWh/mês</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin-right: 10px"
                                            class="p-field">
                                            <label style="font-weight: bold"
                                                for="con_anu">Consumo
                                                Anual:</label>
                                            <div class="input-group mb-3">
                                                <input [value]="consumoanual1"
                                                    readonly type="number"
                                                    class="form-control"
                                                    id="con_anu" />
                                                <div class="input-group-append">
                                                    <span
                                                        class="input-group-text">kWh</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin-right: 10px"
                                            class="p-field">
                                            <label style="font-weight: bold"
                                                for="con_anu">Potência
                                                Necessária:</label>
                                            <div class="input-group mb-3">
                                                <input readonly
                                                    [value]="potnecessaria1"
                                                    (input)="potnecessaria1=
                                                    $event.target.value"
                                                    type="number"
                                                    class="form-control"
                                                    id="potenciadimensionada" />
                                                <div class="input-group-append">
                                                    <span
                                                        class="input-group-text">kWp</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!grupoA1" class="col-md-8">
                                        <div style="margin-top: 10px" class="form-row">
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh" min="0"
                                                    placeholder="Janeiro" cssClass="e-outline"
                                                    [(ngModel)]="jan1" floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh"
                                                    placeholder="Fevereiro" cssClass="e-outline"
                                                    [(ngModel)]="fev1"
                                                    floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh" placeholder="Março"
                                                    cssClass="e-outline" [(ngModel)]="mar1"
                                                    floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh" placeholder="Abril"
                                                    cssClass="e-outline" [(ngModel)]="abr1"
                                                    floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh" placeholder="Maio"
                                                    cssClass="e-outline" [(ngModel)]="mai1"
                                                    floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh" placeholder="Junho"
                                                    cssClass="e-outline" [(ngModel)]="jun1"
                                                    floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh" placeholder="Julho"
                                                    cssClass="e-outline" [(ngModel)]="jul1"
                                                    floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh"
                                                    placeholder="Agosto" cssClass="e-outline"
                                                    [(ngModel)]="ago1"
                                                    floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh"
                                                    placeholder="Setembro" cssClass="e-outline"
                                                    [(ngModel)]="set1"
                                                    floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh"
                                                    placeholder="Outubro" cssClass="e-outline"
                                                    [(ngModel)]="out1"
                                                    floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh"
                                                    placeholder="Novembro" cssClass="e-outline"
                                                    [(ngModel)]="nov1"
                                                    floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                            <div class="form-group col-4">
                                                <ejs-numerictextbox format="###.### kWh"
                                                    placeholder="Dezembro" cssClass="e-outline"
                                                    [(ngModel)]="dez1"
                                                    floatLabelType="Auto"></ejs-numerictextbox>
                                            </div>
                                        </div>
                                        <div class="modal-footer" style="margin-top: 00px">
                                            <button *ngIf="consumofixo1===0" (click)="zera1();" type="button"
                                                class="btn
                                                btn-primary" data-dismiss="modal">
                                                Calcular
                                            </button>
                                            <button *ngIf="consumofixo1!==0" (click)="consumo1();" type="button"
                                            class="btn
                                            btn-primary" data-dismiss="modal">
                                            Calcular
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </e-tabitem>

                    <e-tabitem *ngIf="headerText[2]" [header]='headerText[2]'>
                        <ng-template #content>
                            <div style="background-color:
                            #F7F7F7;border-radius:0% !important" class="card
                            p-2">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div style="margin-right: 10px"
                                        class="p-field">
                                        <label style="font-weight: bold"
                                            for="consumo_fixo">Consumo
                                            Mensal Médio:</label>
                                        <div style="font-weight: bold"
                                            class="input-group mb-3">
                                            <input [value]="consumofixo2"
                                                (change)="consumo2()"
                                                (input)="consumofixo2=
                                                $event.target.value"
                                                type="number" min="0"
                                                class="form-control"
                                                id="consumo_fixo" />
                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text">kWh/mês</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-right: 10px"
                                        class="p-field">
                                        <label style="font-weight: bold"
                                            for="con_anu">Consumo
                                            Anual:</label>
                                        <div class="input-group mb-3">
                                            <input [value]="consumoanual2"
                                                readonly type="number"
                                                class="form-control"
                                                id="con_anu" />
                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text">kWh</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-right: 10px"
                                        class="p-field">
                                        <label style="font-weight: bold"
                                            for="con_anu">Potência
                                            Necessária:</label>
                                        <div class="input-group mb-3">
                                            <input readonly
                                                [value]="potnecessaria2"
                                                (input)="potnecessaria2=
                                                $event.target.value"
                                                type="number"
                                                class="form-control"
                                                id="potenciadimensionada" />
                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text">kWp</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!grupoA1" class="col-md-8">
                                    <div style="margin-top: 10px" class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" min="0"
                                                placeholder="Janeiro" cssClass="e-outline"
                                                [(ngModel)]="jan2" floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Fevereiro" cssClass="e-outline"
                                                [(ngModel)]="fev2"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Março"
                                                cssClass="e-outline" [(ngModel)]="mar2"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Abril"
                                                cssClass="e-outline" [(ngModel)]="abr2"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Maio"
                                                cssClass="e-outline" [(ngModel)]="mai2"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Junho"
                                                cssClass="e-outline" [(ngModel)]="jun2"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Julho"
                                                cssClass="e-outline" [(ngModel)]="jul2"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Agosto" cssClass="e-outline"
                                                [(ngModel)]="ago2"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Setembro" cssClass="e-outline"
                                                [(ngModel)]="set2"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Outubro" cssClass="e-outline"
                                                [(ngModel)]="out2"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Novembro" cssClass="e-outline"
                                                [(ngModel)]="nov2"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Dezembro" cssClass="e-outline"
                                                [(ngModel)]="dez2"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="modal-footer" style="margin-top: 00px">
                                        <button *ngIf="consumofixo2===0" (click)="zera2();" type="button"
                                            class="btn
                                            btn-primary" data-dismiss="modal">
                                            Calcular
                                        </button>
                                        <button *ngIf="consumofixo2!==0" (click)="consumo2();" type="button"
                                        class="btn
                                        btn-primary" data-dismiss="modal">
                                        Calcular
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </ng-template>
                    </e-tabitem>

                    <e-tabitem *ngIf="headerText[3]" [header]='headerText[3]'>
                        <ng-template #content>
                            <div style="background-color:
                            #F7F7F7;border-radius:0% !important" class="card
                            p-2">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div style="margin-right: 10px"
                                        class="p-field">
                                        <label style="font-weight: bold"
                                            for="consumo_fixo">Consumo
                                            Mensal Médio:</label>
                                        <div style="font-weight: bold"
                                            class="input-group mb-3">
                                            <input [value]="consumofixo3"
                                                (change)="consumo3()"
                                                (input)="consumofixo3=
                                                $event.target.value"
                                                type="number" min="0"
                                                class="form-control"
                                                id="consumo_fixo" />
                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text">kWh/mês</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-right: 10px"
                                        class="p-field">
                                        <label style="font-weight: bold"
                                            for="con_anu">Consumo
                                            Anual:</label>
                                        <div class="input-group mb-3">
                                            <input [value]="consumoanual3"
                                                readonly type="number"
                                                class="form-control"
                                                id="con_anu" />
                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text">kWh</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-right: 10px"
                                        class="p-field">
                                        <label style="font-weight: bold"
                                            for="con_anu">Potência
                                            Necessária:</label>
                                        <div class="input-group mb-3">
                                            <input readonly
                                                [value]="potnecessaria3"
                                                (input)="potnecessaria3=
                                                $event.target.value"
                                                type="number"
                                                class="form-control"
                                                id="potenciadimensionada" />
                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text">kWp</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!grupoA1" class="col-md-8">
                                    <div style="margin-top: 10px" class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" min="0"
                                                placeholder="Janeiro" cssClass="e-outline"
                                                [(ngModel)]="jan3" floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Fevereiro" cssClass="e-outline"
                                                [(ngModel)]="fev3"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Março"
                                                cssClass="e-outline" [(ngModel)]="mar3"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Abril"
                                                cssClass="e-outline" [(ngModel)]="abr3"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Maio"
                                                cssClass="e-outline" [(ngModel)]="mai3"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Junho"
                                                cssClass="e-outline" [(ngModel)]="jun3"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Julho"
                                                cssClass="e-outline" [(ngModel)]="jul3"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Agosto" cssClass="e-outline"
                                                [(ngModel)]="ago3"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Setembro" cssClass="e-outline"
                                                [(ngModel)]="set3"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Outubro" cssClass="e-outline"
                                                [(ngModel)]="out3"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Novembro" cssClass="e-outline"
                                                [(ngModel)]="nov3"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Dezembro" cssClass="e-outline"
                                                [(ngModel)]="dez3"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="modal-footer" style="margin-top: 00px">
                                        <button *ngIf="consumofixo3===0" (click)="zera3();" type="button"
                                            class="btn
                                            btn-primary" data-dismiss="modal">
                                            Calcular
                                        </button>
                                        <button *ngIf="consumofixo3!==0" (click)="consumo3();" type="button"
                                        class="btn
                                        btn-primary" data-dismiss="modal">
                                        Calcular
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </ng-template>
                    </e-tabitem>

                    <e-tabitem *ngIf="headerText[4]" [header]='headerText[4]'>
                        <ng-template #content>
                            <div style="background-color:
                            #F7F7F7;border-radius:0% !important" class="card
                            p-2">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div style="margin-right: 10px"
                                        class="p-field">
                                        <label style="font-weight: bold"
                                            for="consumo_fixo">Consumo
                                            Mensal Médio:</label>
                                        <div style="font-weight: bold"
                                            class="input-group mb-3">
                                            <input [value]="consumofixo4"
                                                (change)="consumo4()"
                                                (input)="consumofixo4=
                                                $event.target.value"
                                                type="number" min="0"
                                                class="form-control"
                                                id="consumo_fixo" />
                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text">kWh/mês</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-right: 10px"
                                        class="p-field">
                                        <label style="font-weight: bold"
                                            for="con_anu">Consumo
                                            Anual:</label>
                                        <div class="input-group mb-3">
                                            <input [value]="consumoanual4"
                                                readonly type="number"
                                                class="form-control"
                                                id="con_anu" />
                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text">kWh</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-right: 10px"
                                        class="p-field">
                                        <label style="font-weight: bold"
                                            for="con_anu">Potência
                                            Necessária:</label>
                                        <div class="input-group mb-3">
                                            <input readonly
                                                [value]="potnecessaria4"
                                                (input)="potnecessaria4=
                                                $event.target.value"
                                                type="number"
                                                class="form-control"
                                                id="potenciadimensionada" />
                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text">kWp</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!grupoA1" class="col-md-8">
                                    <div style="margin-top: 10px" class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" min="0"
                                                placeholder="Janeiro" cssClass="e-outline"
                                                [(ngModel)]="jan4" floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Fevereiro" cssClass="e-outline"
                                                [(ngModel)]="fev4"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Março"
                                                cssClass="e-outline" [(ngModel)]="mar4"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Abril"
                                                cssClass="e-outline" [(ngModel)]="abr4"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Maio"
                                                cssClass="e-outline" [(ngModel)]="mai4"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Junho"
                                                cssClass="e-outline" [(ngModel)]="jun4"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh" placeholder="Julho"
                                                cssClass="e-outline" [(ngModel)]="jul4"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Agosto" cssClass="e-outline"
                                                [(ngModel)]="ago4"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Setembro" cssClass="e-outline"
                                                [(ngModel)]="set4"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Outubro" cssClass="e-outline"
                                                [(ngModel)]="out4"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Novembro" cssClass="e-outline"
                                                [(ngModel)]="nov4"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                        <div class="form-group col-4">
                                            <ejs-numerictextbox format="###.### kWh"
                                                placeholder="Dezembro" cssClass="e-outline"
                                                [(ngModel)]="dez4"
                                                floatLabelType="Auto"></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="modal-footer" style="margin-top: 00px">
                                        <button *ngIf="consumofixo4===0" (click)="zera4();" type="button"
                                            class="btn
                                            btn-primary" data-dismiss="modal">
                                            Calcular
                                        </button>
                                        <button *ngIf="consumofixo4!==0" (click)="consumo4();" type="button"
                                        class="btn
                                        btn-primary" data-dismiss="modal">
                                        Calcular
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </ng-template>
                    </e-tabitem>
                </e-tabitems>
            </ejs-tab>

        </div>
        <!-- DIMENSIONAMENTO -->
        <div style="margin-top: 0px; border-bottom: none;" *ngIf="menu2 === true">
            <div class="form-row">
                <div style="background-color: #F7F7F7;border-radius:0%
                    !important;border-right: transparent !important"
                    class="card form-group col-4 p-2 mt-2">
                    <label style="font-weight: bold" for="albedo">Potência :</label>
                    <div class="input-group mb-3">
                        <input [value]="potenciafvselec"
                            (change)="calcfv($event.target.value);
                            potenciafvselec= $event.target.value" type="number"
                            min="0" max="900" class="form-control"
                            id="aumentoconsumo" />
                        <div class="input-group-append">
                            <span class="input-group-text">W</span>
                        </div>
                    </div>

                    <label style="font-weight: bold" for="albedo">Marca Módulos:</label>
                    <div class="input-group mb-3">
                        <select (change)="modelomodulos($event.target.value);
                            marcamodulos= $event.target.value"
                            [(ngModel)]="marcamodul" class="custom-select"
                            id="marca">
                            <option>Selecione...</option>
                            <option *ngFor="let marca of marcas"
                                [value]="marca">
                                {{ marca }}
                            </option>
                        </select>
                    </div>

                    <label style="font-weight: bold" for="albedo">Modelo
                        Módulos:</label>
                    <div class="input-group mb-2">
                        <select (change)="parametromudolo($event.target.value);
                            mudamodelo($event.target.selectedIndex);
                            modelomodu= $event.target.value
                            "
                            [value]="modelomodu"
                            [(ngModel)]="modelomodu"

                            class="custom-select"
                            id="modelo">
                            <option>Selecione...</option>
                            <option #i *ngFor="let modelo of mod; let i= index"
                                [value]="modelo">
                                {{ modelo}}
                            </option>
                        </select>
                    </div>

                    <label style="font-weight: bold" for="horizontal">Quantidade</label>
                    <div style="width:100% !important" class="input-group mb-2">
                        <p-inputNumber (onInput)="calcfveira($event)" [(ngModel)]="numplacas"
                            [showButtons]="true" buttonLayout="horizontal"
                            inputId="horizontal" spinnerMode="horizontal"
                            [step]="1"
                            decrementButtonClass="p-button-danger"
                            incrementButtonClass="p-button-success"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus" mode="decimal">
                        </p-inputNumber>
                    </div>

                    <label style="font-weight: bold" for="potkwp">Potência
                        dimensionada:</label>
                    <div class="input-group mb-2">
                        <input [value]="potkwp" readonly type="number"
                            class="form-control" id="potkwp" />
                        <div class="input-group-append">
                            <span class="input-group-text">kWp</span>
                        </div>
                    </div>

                    <label style="font-weight: bold" for="razao">Àrea Total:</label>
                    <div class="input-group mb-2">
                        <input [value]="areaplacas" type="number"
                            class="form-control" id="area" />
                        <div class="input-group-append">
                            <span class="input-group-text"> m²</span>
                        </div>
                    </div>

                    <button (click)="potenciainversores();
                        potenciainversores2();
                        openWithBackdrop(dialoga)" mat-flat-button
                        style="margin-top:
                        27px; color: white;
                        background-color: #2583e0;
                        border: 1px solid black !important; width:100% !important">
                        Buscar Inversor
                    </button>

                </div>


                <div style="background-color: #F7F7F7;border-radius:0%
                    !important;"
                    class="card form-group col-8  p-2 mt-2">
                    <div class="form-row " style="margin-top:-0.5rem">
                    <div class="form-group col-6">
                        <label style="font-weight: bold" for="potenciaINV">Potência
                            Inversor (kW):</label>
                        <div *ngIf="digitapotinv1 === 'false'"
                            class="input-group mb-0">
                            <select id="potenciaINV"
                                (change)="marcainversor($event.target.value);
                                potenciainversor1= $event.target.value"
                                [value]="potenciainversor1"
                                class="custom-select">
                                <option>Selecione...</option>
                                <option *ngFor="let pot of potinversor"
                                    [value]="pot">
                                    {{ pot }}
                                </option>
                            </select>
                            <p-button icon="pi pi-pencil"
                                (onClick)="potmanual1('true')"></p-button>
                        </div>
                        <div *ngIf="digitapotinv1 === 'true'"
                        class="input-group mb-0">
                        <input min="0"
                            (change)="marcainversor($event.target.value);
                            potenciainversor1= $event.target.value"
                            [value]="potenciainversor1"
                            type="text" class="form-control" id="potinv"
                            />
                        <div class="input-group-append">
                            <span class="input-group-text">kW</span>
                        </div>
                        <p-button icon="pi pi-filter"
                            (onClick)="potmanual1('false')"></p-button>
                        </div>
                    </div>
                    <div class="form-group col-3">
                        <label style="font-weight: bold" for="horizontal">Quantidade</label>
                            <p-inputNumber [(ngModel)]="numinv1"
                                [showButtons]="true"
                                buttonLayout="horizontal"
                                inputId="horizontal"
                                spinnerMode="horizontal" [step]="1"
                                decrementButtonClass="p-button-danger"
                                incrementButtonClass="p-button-success"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                                mode="decimal">
                            </p-inputNumber>
                    </div>
                </div>
                <div class="form-row " style="margin-top:-1rem"> 
                    <div class="form-group col-6">
                        <label style="font-weight: bold"
                            for="marcainversor1">Marca
                            :</label>
                        <div class="input-group mb-2">
                            <select
                                (change)="modeloinversor($event.target.value);
                                marcainversor1= $event.target.value;"
                                [(ngModel)]="marcinversor1"
                                class="custom-select"
                                id="marcainversor1">
                                <option>Selecione...</option>
                                <option *ngFor="let marc of marcasinver"
                                    [value]="marc">
                                    {{ marc }}
                                </option>
                            </select>
                        </div>
                    </div>

                    
                    <div class="form-group col-6">
                        <label style="font-weight: bold" for="albedo">Modelo
                            :</label>
                        <div class="input-group mb-2">
                            <select
                                (change)="parainver($event.target.value);
                                mudamodinv1($event.target.selectedIndex);
                                modeinv1= $event.target.value"
                                [(ngModel)]="modeinv1" class="custom-select"
                                id="modelo">
                                <option>Selecione...</option>
                                <option *ngFor="let modelo of modi"
                                    [value]="modelo.codice2">
                                    {{ modelo.modelo }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="form-row " style="margin-top:-1.5rem"> 

                <div class="form-group col-12">
                <label style="font-weight: bold" for="potenciaINV">inversor
                    :</label>
                <div *ngIf="allinversores">
                    <div class="input-group mb-2">
                        <select disabled
                            (change)="idmudanca($event.target.value)"
                            class="custom-select"
                            id="modelo">
                            <option value="0">Todos</option>
                            <option *ngFor="let idex of IDSCONF1"
                                [value]="idex.value + 1">
                                {{ idex.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="allinversores === false">
                    <div class="input-group mb-2">
                        <select (change)="idmudanca($event.target.value)"
                            class="custom-select" id="modelo">
                            <option *ngFor="let idex of IDSCONF1"
                                [value]="idex.value">
                                {{ idex.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-check">
                    <input [(ngModel)]="allinversores" type="checkbox"
                        class="form-check-input" id="check5" />
                    <label class="form-check-label" for="exampleCheck1">Mesma
                        configuração para todos</label>
                </div>
            </div>


                <div clas="form-row" style="width:100% !important;position:absolute !important; bottom:0rem !important">
                    <div class="form-group col-12">

                    <button  (click)="engvirtu= true" mat-flat-button
                    style="margin-top:
                    27px; color: white;
                    background-color: #2583e0;
                    border: 1px solid black !important; width:100% !important">
                    Engenheiro Virtual
                </button>            </div>
            </div>
            </div>


                </div>
            </div>
        </div>
        <!-- GRÁFICO GERAÇÃOXCONSUMO  -->
        <div *ngIf="menu3 === true">
            <div>
                <div>
                    <div style="width: 100%; height: 100%" style="display:
                        block">
                        <canvas id="myCanvas" baseChart
                            [datasets]="barChartData" [labels]="barChartLabels"
                            [options]="barChartOptions"
                            [colors]="barChartColors"
                            [plugins]="barChartPlugins"
                            [legend]="barChartLegend"
                            [chartType]="barChartType"></canvas>
                    </div>
                </div>
            </div>



        </div>
        <!-- GESTAO -->
        <div *ngIf="menu4 === true">
            <!-- RELATÓRIO ECONOMICO -->
            <div class="form-group">
                <div class="form-check">
                    <input (change)="ativafinan()" class="form-check-input"
                        type="checkbox" id="financhecl" />
                    <label style="font-weight: bold" class="form-check-label"
                        for="financhecl">
                        Desejo utilizar financiamento
                    </label>
                </div>
            </div>

            <table style="width: auto; height: auto; margin-top: 0px"
                class="table table-sm table-bordered">
                <tbody>
                    <tr>
                        <th class="teste" style="width: 200px;
                            font-size: 13px;
                            font-weight: bold;
                            text-align: center;
                            background-color: #f0f0f0;
                            color: black;
                            border-color: black;" colspan="2" scope="col">
                            Parâmetros de custo
                        </th>
                        <th class="teste" style="width: 200px;
                            font-size: 13px;
                            font-weight: bold;
                            text-align: center;
                            background-color: #f0f0f0;
                            color: black;
                            border-color: black;" colspan="2" scope="col">
                            Parâmetros de Cálculo
                        </th>
                        <th *ngIf="finam" class="teste" style="width: 200px;
                            font-size: 13px;
                            font-weight: bold;
                            text-align: center;
                            background-color: #f0f0f0;
                            color: black;
                            border-color: black;" colspan="2" scope="col">
                            Parâmetros de Financiamento
                        </th>
                    </tr>
                    <tr style="border: 0.15em solid black">
                        <th style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Sistema PV:
                        </th>
                        <td>
                            <input [(ngModel)]="custopv"
                                (ngModelChange)="atualizacustos()" currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.',
                                decimal: ',' }" style="font-size: 13px;
                                background-color: transparent;
                                border-radius: 0;
                                outline: none;
                                border-color: transparent;
                                width: 150px;" id="custopv" />
                        </td>
                        <th style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Tarifa na ponta:
                        </th>
                        <td style="width: 100px">
                            <ejs-numerictextbox [showSpinButton]="false"
                                style="border:
                                transparent !important;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;
                                text-align: right;
                                width: 150px;" [(ngModel)]="tarenergia"
                                (ngModelChange)="atualizacustos()" format="c4"
                                decimals="10" validateDecimalOnType="true"
                                value="100"></ejs-numerictextbox>
                        </td>
                        <th *ngIf="finam" style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Período em Anos:
                        </th>
                        <td *ngIf="finam" style="width: 100px">
                            <ejs-numerictextbox [showSpinButton]="false"
                                style="border:
                                transparent !important;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;
                                text-align: right;
                                width: 100px;" [(ngModel)]="periodofinan"
                                (ngModelChange)="atualizacustos()"
                                format="##" decimals="10"
                                validateDecimalOnType="true" value="100"></ejs-numerictextbox>
                        </td>
                    </tr>
                    <tr style="border: 0.15em solid black">
                        <th style="width: 250px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Material CA:
                        </th>
                        <td>
                            <div class="col-xs-2">
                                <input [(ngModel)]="materialca"
                                    (ngModelChange)="materialca= $event;
                                    atualizacustos()" value="0" currencyMask
                                    [options]="{ prefix:
                                    'R$ ', thousands: '.', decimal: ',' }"
                                    style="font-size: 13px;
                                    background-color: transparent;
                                    border-radius: 0;
                                    outline: none;
                                    border-color: transparent;
                                    width: 150px;" id="custoinstal" />
                            </div>
                        </td>
                        <th style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Tarifa Fora de Ponta :
                        </th>
                        <td style="background-color: #f0f0f0;width: 50x">
                            <ejs-numerictextbox readonly
                                [showSpinButton]="false" style="border:
                                transparent !important;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;
                                text-align: right;
                                width: 150px;" [(ngModel)]="tarenergiaforaponta"
                                (ngModelChange)="atualizacustos()"
                                prefix="R$" format="c4" decimals="10"
                                validateDecimalOnType="true" value="100">
                            </ejs-numerictextbox>
                        </td>
                        <th *ngIf="finam" style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Nº de parcelas:
                        </th>

                        <td *ngIf="finam" style="background-color:
                            #f0f0f0;width: 100px">
                            <ejs-numerictextbox readonly
                                [showSpinButton]="false" style="border:
                                transparent !important;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;
                                text-align: right;
                                width: 100px;" [(ngModel)]="numparcelas"
                                (ngModelChange)="atualizacustos()" format="##"
                                decimals="10" validateDecimalOnType="true"
                                value="100"></ejs-numerictextbox>
                        </td>
                    </tr>
                    <tr style="border: 0.15em solid black">
                        <th style="width: 250px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Projeto:
                        </th>
                        <td>
                            <div class="col-xs-2">
                                <input [(ngModel)]="custoproj"
                                    (ngModelChange)="custoproj= $event;
                                    atualizacustos()" currencyMask [options]="{
                                    prefix: 'R$ ',
                                    thousands: '.', decimal: ',' }"
                                    style="font-size:
                                    13px;
                                    background-color: transparent;
                                    border-radius: 0;
                                    outline: none;
                                    border-color: transparent;
                                    width: 150px;" id="custoprojeto" />
                            </div>
                        </td>
                        <th style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Taxa de custo capital :
                        </th>
                        <td style="width: 50x">
                            <ejs-numerictextbox [showSpinButton]="false"
                                style="border:
                                transparent !important;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;
                                text-align: right;
                                width: 150px;" [(ngModel)]="taxjuros"
                                (ngModelChange)="atualizacustos()" prefix="R$"
                                format="p2" decimals="10"
                                validateDecimalOnType="true" value="100"></ejs-numerictextbox>
                        </td>
                        <th *ngIf="finam" style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Taxa Anual de juros:
                        </th>
                        <td *ngIf="finam" style="width: 100px">
                            <ejs-numerictextbox [showSpinButton]="false"
                                style="border:
                                transparent !important;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;
                                text-align: right;
                                width: 100px;" [(ngModel)]="taxafinan"
                                (ngModelChange)="atualizacustos()" format="p4"
                                decimals="2" validateDecimalOnType="true"
                                value="100"></ejs-numerictextbox>
                        </td>
                    </tr>
                    <tr style="border: 0.15em solid black">
                        <th style="width: 250px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Mão-de-obra:
                        </th>
                        <td>
                            <div class="col-xs-2">
                                <input [(ngModel)]="customo"
                                    (ngModelChange)="customo= $event;
                                    atualizacustos()" style="font-size: 13px;
                                    background-color: transparent;
                                    border-radius: 0;
                                    outline: none;
                                    border-color: transparent;
                                    width: 150px;" currencyMask [options]="{
                                    prefix: 'R$ ', thousands: '.', decimal: ','
                                    }" id="customo" />
                            </div>
                        </td>
                        <th style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Reajuste Tarifário:
                        </th>
                        <td style="width: 50x">
                            <ejs-numerictextbox [showSpinButton]="false"
                                style="border:
                                transparent !important;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;
                                text-align: right;
                                width: 150px;" [(ngModel)]="reajtar"
                                (ngModelChange)="atualizacustos()" prefix="R$"
                                format="p2" decimals="10"
                                validateDecimalOnType="true" value="100"></ejs-numerictextbox>
                        </td>

                        <th *ngIf="finam" style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Percentual Financiado:
                        </th>
                        <td *ngIf="finam" style="width: 100px">
                            <ejs-numerictextbox [showSpinButton]="false"
                                style="border:
                                transparent !important;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;
                                text-align: right;
                                width: 100px;" [(ngModel)]="percentfinan"
                                (ngModelChange)="atualizacustos()"
                                format="p4" decimals="2"
                                validateDecimalOnType="true" value="100"></ejs-numerictextbox>
                        </td>
                    </tr>
                    <tr style="border: 0.15em solid black">
                        <th style="width: 250px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Margem de lucro:
                        </th>
                        <td>
                            <div class="col-xs-2">
                                <input [(ngModel)]="custosadicionais"
                                    (ngModelChange)="custosadicionais= $event;
                                    atualizacustos()" style="font-size: 13px;
                                    background-color: transparent;
                                    border-radius: 0;
                                    outline: none;
                                    border-color: transparent;
                                    width: 150px;" currencyMask [options]="{
                                    prefix: 'R$ ', thousands: '.', decimal: ','
                                    }" id="customo" />
                            </div>
                        </td>
                        <th style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Disponibilidade:
                        </th>
                        <th *ngIf="ativagrupoa" style="width: 200px; font-size:
                            13px; background-color: #f0f0f0" scope="row">
                            Demanda contratada:
                        </th>
                        <td style="width: 50x">
                            <input [(ngModel)]="custodisponibilidade"
                                (ngModelChange)="atualizacustos()"
                                style="font-size:
                                13px;
                                background-color: transparent;
                                border-radius: 0;
                                outline: none;
                                border-color: transparent;
                                width: 150px;" currencyMask [options]="{ prefix:
                                'kWh ', thousands: '.', decimal: ',' }"
                                id="customo" />
                        </td>
                        <th *ngIf="finam" style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Valor das parcelas:
                        </th>
                        <td *ngIf="finam" style="background-color:
                            #f0f0f0;width: 100px">
                            <ejs-numerictextbox readonly
                                [showSpinButton]="false" style="border:
                                transparent !important;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;
                                text-align: right;
                                width: 100px;"
                                [(ngModel)]="parcelafinanciamento" readonly
                                (ngModelChange)="atualizacustos()" format="c3"
                                decimals="10"
                                validateDecimalOnType="true"></ejs-numerictextbox>
                        </td>
                    </tr>
                    <tr style="border: 0.15em solid black">
                        <th style="width: 250px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Desconto:
                        </th>
                        <td>
                            <div class="col-xs-2">
                                <input [(ngModel)]="desconto"
                                    (ngModelChange)="desconto= $event;
                                    atualizacustos()" style="font-size: 13px;
                                    background-color: transparent;
                                    border-radius: 0;
                                    outline: none;
                                    border-color: transparent;
                                    width: 150px;
                                    color: red;
                                    font-weight: bold;" currencyMask
                                    [options]="{
                                    prefix: 'R$ ', thousands: '.', decimal: ','
                                    }" id="customo" />
                            </div>
                        </td>
                        <th style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Depreciação anual:
                        </th>
                        <td style="width: 50x">
                            <ejs-numerictextbox [showSpinButton]="false"
                                style="border:
                                transparent !important;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;
                                text-align: right;
                                width: 150px;" [(ngModel)]="depreciacao"
                                (ngModelChange)="atualizacustos()" prefix="R$"
                                format="p2" decimals="10"
                                validateDecimalOnType="true" value="100"></ejs-numerictextbox>
                        </td>
                        <th *ngIf="finam" style="width: 200px; font-size: 13px;
                            background-color: #f0f0f0" scope="row">
                            Período de carência:
                        </th>
                        <td *ngIf="finam" style="width: 100px">
                            <ejs-numerictextbox [showSpinButton]="false"
                                style="border:
                                transparent !important;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;
                                text-align: right;
                                width: 100px;" [(ngModel)]="parcelacarencias"
                                (ngModelChange)="atualizacustos()"
                                format="##" decimals="10"
                                validateDecimalOnType="true" value="100"></ejs-numerictextbox>
                        </td>
                    </tr>
                </tbody>
            </table>



            <table style="width: 100%; height: auto; margin-top: 15px"
                class="table table-sm table-bordered">
                <tbody>
                    <tr>
                        <th style="width: auto;
                            font-size: 13px;
                            font-weight: bold;
                            text-align: center;
                            background-color: #f0f0f0;
                            color: black;
                            height: 10px;" scope="col">
                            Custo kWp
                        </th>
                        <th style="width: auto;
                            font-size: 13px;
                            font-weight: bold;
                            text-align: center;
                            background-color: #f0f0f0;
                            color: black;
                            height: 10px;" scope="col">
                            Percentual de ganho
                        </th>
                        <th style="width: auto;
                            font-size: 13px;
                            font-weight: bold;
                            text-align: center;
                            background-color: #f0f0f0;
                            color: black;
                            height: 10px;" scope="col">
                            Custo total
                        </th>
                        <th style="width: auto;
                            font-size: 13px;
                            font-weight: bold;
                            text-align: center;
                            background-color: #f0f0f0;
                            color: black;
                            height: 10px;" scope="col">
                            Custo sem o kit
                        </th>
                    </tr>
                    <td style="text-align: left !important;height: 20px">
                        <div style="height: 20px" class="col-xs-1">
                            <input [(ngModel)]="custokwp"
                                (ngModelChange)="custokwp= $event;
                                atualizacustos()" style="font-size: 13px;
                                background-color: transparent;
                                border-radius: 0;
                                text-align: left !important;
                                outline: none;
                                border-color: transparent;
                                width: auto;" currencyMask [options]="{ prefix:
                                'R$/kWp ', thousands: '.', decimal: ',' }" />
                        </div>
                    </td>
                    <td style="height: 20px">
                        <div style="height: 20px" class="col-xs-1">
                            <input readonly currencyMask [(ngModel)]="ganho"
                                [options]="{
                                prefix: '',
                                suffix: '%',
                                thousands: '.',
                                decimal: ','
                                }" style="font-size: 13px;
                                background-color: transparent;
                                border-radius: 0;
                                outline: none;
                                text-align: left;
                                border-color: transparent;
                                width: auto;" id="custoprojeto" />
                        </div>
                    </td>
                    <td style="height: 20px">
                        <div style="height: 20px" class="col-xs-1">
                            <input readonly [(ngModel)]="custototal"
                                style="background-color:
                                transparent;
                                border-radius: 0;
                                outline: none;
                                border-color: transparent;
                                width: auto;
                                font-weight: bold;
                                text-align: left;
                                font-size: 13px;" currencyMask [options]="{
                                prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                id="custotal" />
                        </div>
                    </td>
                    <td style="height: 20px">
                        <div style="height: 20px" class="col-xs-1">
                            <input readonly [(ngModel)]="custototal - custopv"
                                style="background-color: transparent;
                                border-radius: 0;
                                outline: none;
                                border-color: transparent;
                                width: auto;
                                font-weight: bold;
                                text-align: left;
                                font-size: 13px;" currencyMask [options]="{
                                prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                id="custotal" />
                        </div>
                    </td>
                </tbody>
            </table>
            <div class="form-row">
                <div style="margin-right: 50px" class="form-group col-md-12">
                    <button (click)="nreleco()" style="width: 100%; color:
                        black; background-color: #c7cbf7" mat-raised-button>
                        <i style="font-size: 20px; margin-right: 5px" class="fas
                            fa-hand-holding-usd"></i>
                        Gerar relatório econômico
                    </button>
                </div>
            </div>
            <!-- INDICADORES -->
            <h5 style="font-size: 16px">
                Período de amortização {{payback}} Anos
            </h5>
            <div id="my-node2" class="control-section">
                <div align='center'>
                    <ejs-chart style='display:block' align='center'
                        id='chartcontainer' [title]='title'
                        [primaryXAxis]='primaryXAxis'
                        [primaryYAxis]='primaryYAxis' [tooltip]='tooltip'
                        (load)='load($event)' [chartArea]='chartArea'
                        [width]='width'>
                        <e-series-collection>
                            <e-series [dataSource]='data1'
                                [dragSettings]='dragSettings' type='Column'
                                xName='x'
                                yName='y' name='' width=1 [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
            </div>
            <!-- RELATÓRIO ECONOMICO -->
            <!-- RELATÓRIO ECONOMICO -->
            <div class="table-responsive text-nowrap">
                <div id="my-node">
                    <table style="height: 100px; margin-top: 15px" class="table
                        table-sm table-bordered">
                        <thead>
                            <tr>
                                <th style="width: 200px;
                                    font-size: 13px;
                                    font-weight: bold;
                                    text-align: center;
                                    background-color: #e3e3e3;
                                    color: black;" scope="col">
                                    Ano
                                </th>
                                <th *ngFor="let ano of ANOS; let i= index"
                                    style="font-size: 13px;
                                    font-weight: bold;
                                    text-align: center;
                                    background-color: #e3e3e3;
                                    width: 100px;
                                    color: black;" scope="col">
                                    {{ ano }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- PRODUÇÃO DE ENERGIA -->
                            <tr style="border: 0.15em solid black">
                                <th style="width: 200px;
                                    font-size: 13px;
                                    background-color: white;" scope="row">
                                    Produção de Energia
                                </th>
                                <td style="text-align: right" *ngFor="let item
                                    of EprodAno; let i= index">
                                    <input [(ngModel)]="EprodAno[i]" readonly
                                        currencyMask [options]="{
                                        prefix: '',
                                        suffix: 'kWh ',
                                        thousands: '.',
                                        decimal: ','
                                        }" style="border-color: transparent;
                                        width: 100px;
                                        font-size: 13px;
                                        background-color: transparent;
                                        height: 20px;" />
                                </td>
                            </tr>
                            <!-- CREDITO ACUMULADO -->
                            <tr style="border: 0.15em solid black">
                                <th style="width: 200px;
                                    font-size: 13px;
                                    background-color: white;" scope="row">
                                    Crédito Acumulado
                                </th>
                                <td style="text-align: right" *ngFor="let item
                                    of EprodAno; let i= index">
                                    <input [(ngModel)]="EaccumAno[i]" readonly
                                        currencyMask [options]="{
                                        prefix: '',
                                        suffix: 'kWh ',
                                        thousands: '.',
                                        decimal: ','
                                        }" style="border-color: transparent;
                                        width: 100px;
                                        font-size: 13px;
                                        background-color: transparent;
                                        height: 20px;" />
                                </td>
                            </tr>
                            <!-- RECEITA -->
                            <tr style="border: 0.15em solid black">
                                <th style="width: 200px;
                                    font-size: 13px;
                                    background-color: #c7cbf7;
                                    font-weight: bold;" scope="row">
                                    Receita com sistema
                                </th>
                                <td style="text-align: right; background-color:
                                    #c7cbf7" *ngFor="let item of EprodAno; let
                                    i= index">
                                    <input [(ngModel)]="Receita[i]" readonly
                                        currencyMask [options]="{ prefix: 'R$ ',
                                        thousands:
                                        '.', decimal: ',' }"
                                        style="border-color:
                                        transparent;
                                        width: 100px;
                                        font-size: 13px;
                                        background-color: transparent;
                                        height: 20px;" />
                                </td>
                            </tr>

                            <!-- SAIDA1 -->
                            <tr style="border: 0.15em solid black">
                                <th style="width: 200px; font-size: 13px;
                                    background-color: white" scope="row">
                                    Custo disponibilidade
                                </th>
                                <td *ngFor="let item of EprodAno; let i= index"
                                    style="text-align: right; background-color:
                                    white">
                                    <input [(ngModel)]="disponibile[i]" readonly
                                        currencyMask [options]="{ prefix: 'R$ ',
                                        thousands:
                                        '.', decimal: ',' }"
                                        style="border-color:
                                        transparent;
                                        width: 100px;
                                        font-size: 13px;
                                        background-color: transparent;
                                        height: 20px;" />
                                </td>
                            </tr>
                            <!-- FINANCIAMENTO -->
                            <tr *ngIf="finam" style="border: 0.15em solid
                                black">
                                <th style="width: 200px;
                                    font-size: 13px;
                                    background-color: white;
                                    font-weight: bold;" scope="row">
                                    Parcelas Financiamento
                                </th>
                                <td style="text-align: right; background-color:
                                    white" *ngFor="let item of EprodAno; let i=
                                    index">
                                    <input [(ngModel)]="ParcelaFinan[i]"
                                        currencyMask [options]="{ prefix: 'R$ ',
                                        thousands:
                                        '.', decimal: ',' }"
                                        style="border-color:
                                        transparent;
                                        width: 100px;
                                        font-size: 13px;
                                        background-color: transparent;
                                        height: 20px;" />
                                </td>
                            </tr>

                            <!-- FINANC
<tr *ngIf="finam" style="border:0.15em solid black;" >
  <th style="width: 200px; font-size: 13px; background-color: #B7DEE8;;font-weight: bold; " scope="row">
      Juros Financiamento: </th>
  <td style=" background-color: #B7DEE8 " *ngFor="let item of EprodAno; let i=index">
       <input [value] ="JurosFinan[i]" readonly style=" border-color: transparent;width: 100px; font-size: 13px;background-color: transparent;  height:20px;" class="inputa2"   type="number">
    </td>
</tr>
IAMENTO -->

                            <!-- FINANCIA
<tr  *ngIf="finam" style="border:0.15em solid black;" >
<th style=" background-color: #B7DEE8;font-weight: bold; " style="width: 200px; font-size: 13px; background-color: #B7DEE8; " scope="row">
Capital</th>
<td style=" background-color: #B7DEE8 " *ngFor="let item of EprodAno; let i=index">
<input [value] ="ParcelaFinan" readonly style=" border-color: transparent;width: 100px; font-size: 13px;background-color: transparent;  height:20px;" class="inputa2"   type="number">
</td>
</tr>
  MENTO -->
                            <!-- SAIDA -->
                            <tr style="border: 0.15em solid black">
                                <th style="width: 200px;
                                    font-size: 13px;
                                    background-color: #b7dee8;
                                    font-weight: bold;" scope="row">
                                    Total de Saídas
                                </th>
                                <td style="text-align: right; background-color:
                                    #b7dee8" *ngFor="let item of EprodAno; let
                                    i= index">
                                    <input [(ngModel)]="saidasAno[i]" readonly
                                        currencyMask [options]="{ prefix: 'R$ ',
                                        thousands:
                                        '.', decimal: ',' }"
                                        style="border-color:
                                        transparent;
                                        width: 100px;
                                        font-size: 13px;
                                        background-color: transparent;
                                        height: 20px;" />
                                </td>
                            </tr>
                            <!-- SAIDA -->
                            <tr style="border: 0.15em solid black">
                                <th style="width: 200px;
                                    font-size: 13px;
                                    background-color: white;
                                    font-weight: bold;" scope="row">
                                    Fluxo de Caixa
                                </th>
                                <td style="text-align: right; background-color:
                                    white" *ngFor="let item of EprodAno; let i=
                                    index">
                                    <input [(ngModel)]="FluxoCaixa[i]" readonly
                                        currencyMask [options]="{ prefix: 'R$ ',
                                        thousands:
                                        '.', decimal: ',' }"
                                        style="border-color:
                                        transparent;
                                        width: 100px;
                                        font-size: 13px;
                                        background-color: transparent;
                                        height: 20px;" />
                                </td>
                            </tr>
                            <tr style="border: 0.15em solid black">
                                <th style="width: 200px;
                                    font-size: 13px;
                                    background-color: white;
                                    font-weight: bold;" scope="row">
                                    Montante
                                </th>
                                <td style="text-align: right; background-color:
                                    white" *ngFor="let item of EprodAno; let i=
                                    index">
                                    <input [(ngModel)]="rendimentosacu[i]"
                                        readonly currencyMask [options]="{
                                        prefix: 'R$ ', thousands:
                                        '.', decimal: ',' }"
                                        style="border-color:
                                        transparent;
                                        width: 100px;
                                        font-size: 13px;
                                        background-color: transparent;
                                        height: 20px;" />
                                </td>
                            </tr>
                            <tr style="border: 0.15em solid black">
                                <th style="width: 200px;
                                    font-size: 13px;
                                    background-color: white;
                                    font-weight: bold;" scope="row">
                                    VPL
                                </th>
                                <td style="text-align: right; background-color:
                                    white" *ngFor="let item of EprodAno; let i=
                                    index">
                                    <input [(ngModel)]="VPL[i]" readonly
                                        currencyMask [options]="{ prefix: 'R$ ',
                                        thousands:
                                        '.', decimal: ',' }"
                                        style="border-color:
                                        transparent;
                                        width: 100px;
                                        font-size: 13px;
                                        background-color: transparent;
                                        height: 20px;" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div></div>
        </div>
        <div *ngIf="menu5 === true">
            <div style="background-color: #fafafa;border-radius:0%
            !important;border-right: transparent !important"
            class="card  p-2 mt-2 mb-2">
            <div class="form-row">
                <div class="form-group col-12">
                    <h4>Preencha os dados do cliente </h4>
                </div>
                <div class="form-group col-6">
                    <ejs-textbox placeholder="Nome Cliente" cssClass="e-outline"
                        [(ngModel)]="nomecliente"
                        floatLabelType="Auto"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-maskedtextbox placeholder="CPF/CNPJ Cliente"
                        cssClass="e-outline"
                        (change)="cpfcliente=$event.maskedValue"
                        [value]="cpfcliente"
                        mask="000.000.000-00" floatLabelType="Auto">
                    </ejs-maskedtextbox>
                </div>
                <div class="form-group col-4">
                    <ejs-textbox readonly placeholder="Código Cliente"
                        cssClass="e-outline" [(ngModel)]="codcliente"
                        floatLabelType="Auto"></ejs-textbox>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-6">
                    <ejs-textbox placeholder="E-mail Cliente"
                        cssClass="e-outline" [(ngModel)]="emailcliente"
                        floatLabelType="Auto"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-maskedtextbox placeholder="Telefone Cliente"
                        cssClass="e-outline"
                        [value]="telefonecliente"
                        (change)="telefonecliente=$event.maskedValue"
                        mask="(00)0 0000-0000" floatLabelType="Auto">
                    </ejs-maskedtextbox>
                </div>
                <div class="form-group col-4">
                    <ejs-dropdownlist [(ngModel)]="responsavel"
                        floatLabelType="Auto" cssClass="e-outline" id='ress'
                        #sample [dataSource]='empregados' [fields]='fields'
                        placeholder='Responsável'>
                    </ejs-dropdownlist>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-6">
                    <ejs-textbox placeholder="Endereço Cliente"
                        cssClass="e-outline" [(ngModel)]="enderecocliente"
                        floatLabelType="Auto"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox placeholder="Cidade Cliente"
                        cssClass="e-outline" [(ngModel)]="cidadeescolhida"
                        floatLabelType="Auto"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-textbox placeholder="UF Cliente" cssClass="e-outline"
                        [(ngModel)]="estadoselecionado"
                        floatLabelType="Auto"></ejs-textbox>
                </div>
                <div class="form-group col-2">
                    <ejs-maskedtextbox placeholder="CEP Cliente"
                        cssClass="e-outline"
                        [value]="cepcliente"
                        (change)="cepcliente=$event.maskedValue;showx($event.maskedValue)"
                        mask="00000-000"
                        floatLabelType="Auto"></ejs-maskedtextbox>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-4">
                    <ejs-dropdownlist [(ngModel)]="formapaga"
                        floatLabelType="Auto" cssClass="e-outline" id='d'
                        #sample
                        [dataSource]='formapags' [fields]='fields'
                        placeholder='Formas pagamento'></ejs-dropdownlist>
                </div>
                <div class="form-group col-4">
                    <ejs-dropdownlist [(ngModel)]="tpestrutura"
                        floatLabelType="Auto" cssClass="e-outline"
                        id='entradaAreaSub' #sample [dataSource]='tpestrutus'
                        [fields]='fields'
                        placeholder='Tipo de estrutura'></ejs-dropdownlist>
                </div>
                <div class="form-group col-2">

                    <ejs-numerictextbox format=".###" min="0"
                        placeholder="Descono a vista %" cssClass="e-outline"
                        [(ngModel)]="descontoavista" floatLabelType="Auto"></ejs-numerictextbox>
                </div>
                <div class="form-group col-2">

                    <ejs-textbox placeholder="Prazo de entrega dias"
                        cssClass="e-outline" [(ngModel)]="prazoentrega"
                        floatLabelType="Auto"></ejs-textbox>
                </div>

            </div>
            <form>
                <div class="modal-footer">
                    <button (click)="images(dialog)" data-dismiss="modal"
                        type="button" class="btn btn-primary">
                        Imprimir
                    </button>
                    <button type="button" class="btn btn-secondary"
                        data-dismiss="modal">
                        Cancelar
                    </button>
                </div>
            </form>
        </div>
        </div>
    </nb-card-body>
</nb-card>
<ng-template #dialoga let-data let-ref="dialogRef">
    <nb-card style="width: 600px">
        <nb-card-header>Atenção</nb-card-header>
        <nb-card-body>
            <div style="padding: 20px;" class="list4">
                <ol class="">
                    <li>
                        Após configurar seu sistema faça a validação com a
                        equipe de
                        engenharia para evitar erros no dimensionamento.
                    </li>
                    <li>
                        Caso o inversor suporte mais de uma string em paralelo
                        em algum dos
                        MPPT, exemplo: duas strings no MPPT1 e apenas uma no
                        MPPT2, ele não
                        será simétrico e
                        <b>você deverá informar à equipe Sollux para verificar
                            se essa
                            função está habilitado para esse inversor
                            específico.</b>
                    </li>
                </ol>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton (click)="ref.close()">Ok</button>
        </nb-card-footer>
    </nb-card>
</ng-template>
<ng-template #dialoga let-data let-ref="dialogRef">
    <nb-card style="width: 600px">
        <nb-card-header>Atenção</nb-card-header>
        <nb-card-body>
            <div class="list4">
                <ol class="">
                    <li>
                        Após configurar seu sistema faça a validação com a
                        equipe de
                        engenharia para evitar erros no dimensionamento.
                    </li>
                    <li>
                        Caso o inversor suporte mais de uma string em paralelo
                        em algum dos
                        MPPT, exemplo: duas strings no MPPT1 e apenas uma no
                        MPPT2, ele não
                        será simétrico e
                        <b>você deverá informar à equipe Sollux para verificar
                            se essa
                            função está habilitado para esse inversor
                            específico.</b>
                    </li>
                </ol>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton (click)="ref.close()">Ok</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<p-dialog [style]="{ width: '70vw' }" header="Engenheiro Virtual"
    [(visible)]="engvirtu">
    <span style="font-size: medium">
        Para pegar temperaturas minima e máxima clique
        <a style="color: red; font-weight: bold" (click)="goToLink(
            'https://www.meteoblue.com/pt/tempo/historyclimate/climatemodelled/'
            )">aqui</a>
    </span>
    <div *ngIf="engvirtu" class="form-row">
        <div class="form-group col-md-2">
            <label style="font-weight: bold; font-size: small" for="temp">Temp.
                mínima:</label>
            <div style="width: 130px !important" class="input-group">
                <input style="width: 60px !important; height: 40px"
                    (change)="tempcidade= $event.target.value"
                    [value]="tempcidade" type="number" class="form-control"
                    id="tempera" />
                <div style="height: 40px" class="input-group-append">
                    <span class="input-group-text">°C</span>
                </div>
            </div>
        </div>

        <div class="form-group col-md-2">
            <label style="font-weight: bold; font-size: small" for="temp">Temp.
                máxima:</label>
            <div style="width: 130px !important" class="input-group">
                <input style="width: 60px !important; height: 40px"
                    (change)="tempcidademax= $event.target.value"
                    [value]="tempcidademax" type="number" class="form-control"
                    id="tempera" />
                <div style="height: 40px" class="input-group-append">
                    <span class="input-group-text">°C</span>
                </div>
            </div>
        </div>

        <div class="form-group col-md-8">
            <button (click)="mostracheck1= false" mat-flat-button
                style="margin-right:
                10px;
                margin-top: 27px;
                background-color: #e9ecef;
                border: 1px solid black !important;">
                Configurar
            </button>
            <button (click)="mostracheck1= true" mat-flat-button
                style="margin-right:
                10px;
                margin-top: 27px;
                background-color: #e9ecef;
                border: 1px solid black !important;">
                Checar parâmetros
            </button>
            <button (click)="salvainversor();
                potgeracao();checaquantidademodulos();
                engvirtual();salvexinversor= 'true'" mat-flat-button
                style="margin-right:
                10px;
                margin-right: 10px;
                margin-top: 27px;
                background-color: #e9ecef;
                border: 1px solid black !important;">
                Salvar configuração
            </button>
        </div>
    </div>

    <table  *ngIf="!mostracheck1 && engvirtu" style="width: auto; height: 100px; margin-top:
        15px" class="table table-sm table-bordered">
        <thead>
            <tr>
                <th style="width: 200px;
                    font-size: 13px;
                    font-weight: bold;
                    text-align: center;
                    background-color: #e3e3e3;" scope="col">
                    Parâmetros
                </th>
                <th style="font-size: 13px;
                    font-weight: bold;
                    text-align: center;
                    background-color: #e3e3e3;
                    width: 100px;" *ngFor="let mmppt of numeromppts; let i=
                    index" scope="col">
                    {{ mmppt.numofmppt }}
                </th>
            </tr>
        </thead>

        <tbody>
            <tr style="border: 0.15em solid black">
                <th style="width: 200px; font-size: 13px; background-color:
                    #f0f0f0" scope="row">
                    Módulos em série
                </th>
                <td *ngFor="let mmppt of numeromppts; let i= index">
                    <input [value]="placasserie[i]" id="serie{{ idatual1 }}"
                        (change)="mmppt.qtd_mod_ser= $event.target.value;
                        placasserie[i]= $event.target.value"
                        style="border-color:
                        transparent;
                        width: 100px;
                        font-size: 13px;
                        background-color: transparent;
                        height: 100%;" class="inputa" min="0" type="number" />
                </td>
            </tr>

            <tr style="border: 0.15em solid black">
                <th style="width: 200px; font-size: 13px; background-color:
                    #f0f0f0" scope="row">
                    Quantidade de Strings
                </th>
                <td *ngFor="let mmppt of numeromppts; let i= index">
                    <div class="col-xs-2">
                        <input [value]="placaspar[i]"
                            (change)="mmppt.qtd_mod_par=
                            $event.target.value;
                            placaspar[i]= $event.target.value"
                            style="border-color:
                            transparent;
                            width: 100px;
                            font-size: 13px;
                            background-color: transparent;
                            height: 20px;" class="inputa" min="0" type="number"
                            />
                    </div>
                </td>
            </tr>
            <tr style="border: 0.15em solid black">
                <th style="width: 200px; font-size: 13px; background-color:
                    #f0f0f0" scope="row">
                    Tensão Vmpp
                </th>
                <td *ngFor="let mmppt of numeromppts; let i= index">
                    <div class="col-xs-2">
                        <input [value]="(paramsmod[0]['tensaonom'] *
                            placasserie[i]).toFixed(2)" readonly
                            style="border-color:
                            transparent;
                            width: 70px;
                            font-size: 13px;
                            background-color: transparent;
                            height: 20px;" class="inputa2" type="number" />
                        <img *ngIf="paramsinv[0]['vmp_max']>
                        (1 + (tempcidade - 25) * (paramsmod[0]['cft'] / 100)) *
                        paramsmod[0]['tensaonom'] *
                        placasserie[i] &&
                        paramsinv[0]['vmp_min'] <(1 + ((tempcidademax - 25) *
                            paramsmod[0]['cft']) / 100) *
                            paramsmod[0]['tensaonom'] *
                            placasserie[i] &&
                            (1 + ((tempcidademax - 25) * paramsmod[0]['cft']) /
                            100) *
                            paramsmod[0]['tensaonom'] *
                            placasserie[i] !==
                            0" style="margin-left: 5px; margin-top: -2px"
                            src="assets/images/correct.png" />
                            <img *ngIf="paramsinv[0]['vmp_max'] <(1 +
                                (tempcidade - 25) * (paramsmod[0]['cft'] / 100))
                                *
                                paramsmod[0]['tensaonom'] *
                                placasserie[i] ||
                                paramsinv[0]['vmp_min']>
                            (1 + ((tempcidademax - 25) * paramsmod[0]['cft']) /
                            100) *
                            paramsmod[0]['tensaonom'] *
                            placasserie[i] ||
                            (1 + ((tempcidademax - 25) * paramsmod[0]['cft']) /
                            100) *
                            paramsmod[0]['tensaonom'] *
                            placasserie[i] ===
                            0
                            " style="margin-left: 5px; margin-top: -2px"
                            src="assets/images/erro.png" />
                        </div>
                    </td>
                </tr>

                <tr style="border: 0.15em solid black">
                    <th style="width: 200px; font-size: 13px; background-color:
                        #f0f0f0" scope="row">
                        Corrente Imp
                    </th>
                    <td *ngFor="let mmppt of numeromppts; let i= index">
                        <div class="col-xs-2">
                            <input [value]="(paramsmod[0]['correntenom'] *
                                placaspar[i]).toFixed(2)" readonly
                                style="border-color:
                                transparent;
                                width: 70px;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;" class="inputa2" type="number" />
                            <img *ngIf="paramsinv[0]['mppt' + (i + 1)]>
                            paramsmod[0]['correntenom'] * placaspar[i] &&
                            paramsmod[0]['correntenom'] * placaspar[i] !== 0
                            " style="margin-left: 0px; margin-top: 0px"
                            src="assets/images/correct.png" />
                            <img *ngIf="paramsinv[0]['mppt' + (i + 1)]
                            <paramsmod[0]['correntenom'] * placaspar[i] ||
                                paramsmod[0]['correntenom'] * placaspar[i] ===
                                0" style="margin-left:
                                0px; margin-top: 0px"
                                src="assets/images/erro.png" />
                        </div>
                    </td>
                </tr>
                <tr style="border: 0.15em solid black">
                    <th style="width: 200px; font-size: 13px; background-color:
                        #f0f0f0" scope="row">
                        Potência
                    </th>
                    <td colspan="10">
                        <div *ngIf="salvou" style="font-size: 13px; align-items:
                            center">
                            {{ potconf1 }}
                            <img
                                *ngIf="inversorescascata1[idatual1][0].pottotalconfigurada
                                /
                                paramsinv[0]['pot_str']>
                            mi / 100 &&
                            ma / 100 >
                            inversorescascata1[idatual1][0].pottotalconfigurada
                            /
                            paramsinv[0]['pot_str']
                            " style="margin-left: 5px; margin-top: -2px"
                            src="assets/images/correct.png" />
                            <img *ngIf="mi / 100>
                            inversorescascata1[idatual1][0].pottotalconfigurada
                            /
                            paramsinv[0]['pot_str'] ||
                            inversorescascata1[idatual1][0].pottotalconfigurada
                            /
                            paramsinv[0]['pot_str'] >
                            ma / 100
                            " style="margin-left: 5px; margin-top: -2px"
                            src="assets/images/erro.png" />
                        </div>
                    </td>
                </tr>
                <tr style="border: 0.15em solid black">
                    <th style="width: 200px; font-size: 13px; background-color:
                        #f0f0f0" scope="row">
                        Número de Módulos
                    </th>
                    <td *ngFor="let mmppt of numeromppts; let i= index">
                        <div class="col-xs-2">
                            <input [value]="placaspar[i] * placasserie[i]"
                                readonly style="border-color: transparent;
                                width: 100px;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;" class="inputa2" type="number" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div   *ngIf="mostracheck1 && engvirtu" style="margin-left: 5px" class="row">
            <nz-tabset>
                <nz-tab *ngFor="let mmppt of numeromppts; let i= index"
                    nzTitle="MPPT {{ i + 1 }}">
                    <div>
                        <p style="width: 400px; font-size: smaller; color: blue"
                            scope="row">
                            <strong>Parâmetros de tensão MPPT {{ i + 1 }}</strong>
                        </p>
                        <div>
                            <div class="col-xs-2">
                                <p style="font-size: smaller">
                                    MPPT {{ i + 1 }} - Tensão a Vazio Vo a
                                    <strong>{{ tempcidade }}°C</strong> é
                                    <strong>{{
                                        (
                                        (1 + (tempcidade - 25) *
                                        paramsmod[0]["coef"]) *
                                        paramsmod[0]["tensaovazio"] *
                                        placasserie[i]
                                        ).toFixed(2)
                                        }}V</strong>
                                    - A tensão máxima do inversor é
                                    <strong>{{ paramsinv[0]["vmp_max"] }}V</strong>
                                    <img *ngIf="paramsinv[0]['vmp_max']>
                                    (1 + (tempcidade - 25) *
                                    paramsmod[0]['coef']) *
                                    paramsmod[0]['tensaovazio'] *
                                    placasserie[i] &&
                                    (1 + (tempcidade - 25) *
                                    paramsmod[0]['coef']) *
                                    paramsmod[0]['tensaovazio'] *
                                    placasserie[i] !==
                                    0
                                    " style="margin-left: 5px; margin-top: -2px"
                                    src="assets/images/correct.png" />
                                    <img *ngIf="paramsinv[0]['vmp_max'] <(1 +
                                        (tempcidade - 25) *
                                        paramsmod[0]['coef']) *
                                        paramsmod[0]['tensaovazio'] *
                                        placasserie[i] &&
                                        (1 + (tempcidade - 25) *
                                        paramsmod[0]['coef']) *
                                        paramsmod[0]['tensaovazio'] *
                                        placasserie[i] !==
                                        0" style="margin-left: 5px; margin-top:
                                        -2px" src="assets/images/erro.png" />
                                </p>
                            </div>
                        </div>

                        <div>
                            <div class="col-xs-2">
                                <p style="font-size: smaller">
                                    MPPT {{ i + 1 }} - Tensão a Vazio Vo a
                                    <strong>{{ tempcidade }}°C</strong> é
                                    <strong>{{
                                        (
                                        (1 + (tempcidade - 25) *
                                        paramsmod[0]["coef"]) *
                                        paramsmod[0]["tensaovazio"] *
                                        placasserie[i]
                                        ).toFixed(2)
                                        }}V</strong>
                                    - A tensão máxima de isolamento é
                                    <strong>{{ paramsmod[0]["maxten"] }}V</strong>
                                    <img *ngIf="paramsmod[0]['maxten']>
                                    (1 + (tempcidade - 25) *
                                    paramsmod[0]['coef']) *
                                    paramsmod[0]['tensaovazio'] *
                                    placasserie[i] &&
                                    (1 + (tempcidade - 25) *
                                    paramsmod[0]['coef']) *
                                    paramsmod[0]['tensaovazio'] *
                                    placasserie[i] !==
                                    0
                                    " style="margin-left: 5px; margin-top: -2px"
                                    src="assets/images/correct.png" />
                                    <img *ngIf="paramsmod[0]['maxten'] <(1 +
                                        (tempcidade - 25) *
                                        paramsmod[0]['coef']) *
                                        paramsmod[0]['tensaovazio'] *
                                        placasserie[i] &&
                                        (1 + (tempcidade - 25) *
                                        paramsmod[0]['coef']) *
                                        paramsmod[0]['tensaovazio'] *
                                        placasserie[i] !==
                                        0" style="margin-left: 5px; margin-top:
                                        -2px" src="assets/images/erro.png" />
                                </p>
                            </div>
                        </div>

                        <div>
                            <div class="col-xs-2">
                                <p style="font-size: smaller">
                                    MPPT {{ i + 1 }} - Tensão mínima Vn a
                                    <strong>{{ tempcidademax }}°C</strong> é
                                    <strong>{{
                                        (
                                        (1 + ((tempcidademax - 25) *
                                        paramsmod[0]["cft"]) / 100) *
                                        paramsmod[0]["tensaonom"] *
                                        placasserie[i]
                                        ).toFixed(2)
                                        }}V</strong>
                                    - A tensão mínima VMPP é
                                    <strong>{{ paramsinv[0]["vmp_min"] }}V</strong>
                                    <img *ngIf="paramsinv[0]['vmp_min'] <(1 +
                                        ((tempcidademax - 25) *
                                        paramsmod[0]['cft']) / 100) *
                                        paramsmod[0]['tensaonom'] *
                                        placasserie[i] &&
                                        (1 + ((tempcidademax - 25) *
                                        paramsmod[0]['cft']) / 100) *
                                        paramsmod[0]['tensaonom'] *
                                        placasserie[i] !==
                                        0" style="margin-left: 5px; margin-top:
                                        -2px" src="assets/images/correct.png" />
                                    <img *ngIf="paramsinv[0]['vmp_min']>
                                    (1 + ((tempcidademax - 25) *
                                    paramsmod[0]['cft']) / 100) *
                                    paramsmod[0]['tensaonom'] *
                                    placasserie[i] ||
                                    (1 + ((tempcidademax - 25) *
                                    paramsmod[0]['cft']) / 100) *
                                    paramsmod[0]['tensaonom'] *
                                    placasserie[i] ===
                                    0
                                    " style="margin-left: 5px; margin-top: -2px"
                                    src="assets/images/erro.png" />
                                </p>
                            </div>
                        </div>

                        <div>
                            <div class="col-xs-2">
                                <p style="font-size: smaller">
                                    MPPT {{ i + 1 }} - Tensão máxima Vn a
                                    <strong>{{ tempcidade }}°C</strong> é
                                    <strong>{{
                                        (
                                        (1 + ((tempcidade - 25) *
                                        paramsmod[0]["cft"]) / 100) *
                                        paramsmod[0]["tensaonom"] *
                                        placasserie[i]
                                        ).toFixed(2)
                                        }}V</strong>
                                    - A tensão máxima VMPP é
                                    <strong>{{ paramsinv[0]["vmpax"] }}V</strong>
                                    <img *ngIf="paramsinv[0]['vmp_max']>
                                    (1 + (tempcidade - 25) *
                                    (paramsmod[0]['cft'] / 100)) *
                                    paramsmod[0]['tensaonom'] *
                                    placasserie[i]
                                    " style="margin-left: 5px; margin-top: -2px"
                                    src="assets/images/correct.png" />
                                    <img *ngIf="paramsinv[0]['vmp_max'] <(1 +
                                        (tempcidade - 25) * (paramsmod[0]['cft']
                                        / 100)) *
                                        paramsmod[0]['tensaonom'] *
                                        placasserie[i]" style="margin-left: 5px;
                                        margin-top: -2px"
                                        src="assets/images/erro.png" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <p style="width: 200px; font-size: smaller; color: blue"
                        scope="row">
                        <strong>Parâmetros de corrente</strong>
                    </p>
                    <div *ngFor="let mmppt of numeromppts; let i= index">
                        <div class="col-xs-2">
                            <p style="font-size: smaller">
                                MPPT {{ i + 1 }} - Corrente Isc na enrrada
                                <strong>
                                    {{
                                    (paramsmod[0]["correntecurto"] *
                                    placaspar[i]).toFixed(2)
                                    }}
                                    A</strong>
                                Máxima corrente suportada
                                <strong>{{ paramsinv[0]["cor_str"] }}A</strong>
                                <img *ngIf="paramsinv[0]['cor_str']>
                                paramsmod[0]['correntecurto'] * placaspar[i] &&
                                paramsmod[0]['correntenom'] * placaspar[i] !== 0
                                " style="margin-left: 5px; margin-top: -2px"
                                src="assets/images/correct.png" />
                                <img *ngIf="paramsinv[0]['cor_str']
                                <paramsmod[0]['correntecurto'] * placaspar[i] &&
                                    paramsmod[0]['correntenom'] * placaspar[i]
                                    !== 0" style="margin-left: 5px; margin-top:
                                    -2px" src="assets/images/erro.png" />
                            </p>
                        </div>
                    </div>

                    <div>
                        <p style="width: 200px; font-size: smaller; color: blue"
                            scope="row">
                            <strong>Parâmetros de potência</strong>
                        </p>
                        <div class="col-xs-2">
                            <p style="font-size: smaller">
                                Potência configurada
                                <strong>
                                    {{
                                    inversorescascata1[idatual1][0].pottotalconfigurada.toFixed(2)
                                    }}
                                    kW</strong>
                                . Fator de dimensionamento de
                                <strong>
                                    {{
                                    (
                                    (inversorescascata1[idatual1][0].pottotalconfigurada
                                    /
                                    paramsinv[0]["pot_str"]) *
                                    100
                                    ).toFixed(1)
                                    }}
                                    %</strong>
                                Fator configurado <strong> {{ mi }}% - {{ ma }}%</strong>
                                <img
                                    *ngIf="inversorescascata1[idatual1][0].pottotalconfigurada
                                    /
                                    paramsinv[0]['pot_str']>
                                mi / 100 &&
                                ma / 100 >
                                inversorescascata1[idatual1][0].pottotalconfigurada
                                /
                                paramsinv[0]['pot_str']
                                " style="margin-left: 5px; margin-top: -2px"
                                src="assets/images/correct.png" />
                                <img *ngIf="mi / 100>
                                inversorescascata1[idatual1][0].pottotalconfigurada
                                /
                                paramsinv[0]['pot_str'] ||
                                inversorescascata1[idatual1][0].pottotalconfigurada
                                /
                                paramsinv[0]['pot_str'] >
                                ma / 100
                                " style="margin-left: 5px; margin-top: -2px"
                                src="assets/images/erro.png" />
                            </p>
                        </div>
                    </div>
                </nz-tab>
            </nz-tabset>
        </div>
    </p-dialog>


    <p-dialog [style]="{ width: '70vw' }" header="Engenheiro Virtual Segundo
        inversor" [(visible)]="engvirtu2">
        <span style="font-size: medium">
            Para pegar temperaturas minima e máxima clique
            <a style="color: red; font-weight: bold" (click)="goToLink(
                'https://www.meteoblue.com/pt/tempo/historyclimate/climatemodelled/'
                )">aqui</a>
        </span>
        <div *ngIf="engvirtu2" class="form-row">
            <div class="form-group col-md-2">
                <label style="font-weight: bold; font-size: small" for="temp">Temp.
                    mínima:</label>
                <div style="width: 130px !important" class="input-group">
                    <input style="width: 60px !important; height: 40px"
                        (change)="tempcidade= $event.target.value"
                        [value]="tempcidade" type="number" class="form-control"
                        id="tempera" />
                    <div style="height: 40px" class="input-group-append">
                        <span class="input-group-text">°C</span>
                    </div>
                </div>
            </div>

            <div class="form-group col-md-2">
                <label style="font-weight: bold; font-size: small" for="temp">Temp.
                    máxima:</label>
                <div style="width: 130px !important" class="input-group">
                    <input style="width: 60px !important; height: 40px"
                        (change)="tempcidademax= $event.target.value"
                        [value]="tempcidademax" type="number"
                        class="form-control" id="tempera" />
                    <div style="height: 40px" class="input-group-append">
                        <span class="input-group-text">°C</span>
                    </div>
                </div>
            </div>

            <div class="form-group col-md-8">
                <button (click)="mostracheck2= false" mat-flat-button
                    style="margin-right:
                    10px;
                    margin-top: 27px;
                    background-color: #e9ecef;
                    border: 1px solid black !important;">
                    Configurar
                </button>
                <button (click)="mostracheck2= true" mat-flat-button
                    style="margin-right:
                    10px;
                    margin-top: 27px;
                    background-color: #e9ecef;
                    border: 1px solid black !important;">
                    Checar parâmetros
                </button>
                <button (click)="salvainversor2();
                    potgeracao2();checaquantidademodulos();
                    engvirtual2();salvexinversor2= 'true'" mat-flat-button
                    style="margin-right:
                    10px;
                    margin-right: 10px;
                    margin-top: 27px;
                    background-color: #e9ecef;
                    border: 1px solid black !important;">
                    Salvar configuração
                </button>
            </div>
        </div>

        <table  *ngIf="!mostracheck2 && engvirtu2" style="width: auto; height: 100px;
            margin-top:
            15px" class="table table-sm table-bordered">
            <thead>
                <tr>
                    <th style="width: 200px;
                        font-size: 13px;
                        font-weight: bold;
                        text-align: center;
                        background-color: #e3e3e3;" scope="col">
                        Parâmetros
                    </th>
                    <th style="font-size: 13px;
                        font-weight: bold;
                        text-align: center;
                        background-color: #e3e3e3;
                        width: 100px;" *ngFor="let mmppt of numeromppts2; let i=
                        index" scope="col">
                        {{ mmppt.numofmppt }}
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr style="border: 0.15em solid black">
                    <th style="width: 200px; font-size: 13px; background-color:
                        #f0f0f0" scope="row">
                        Módulos em série
                    </th>
                    <td *ngFor="let mmppt of numeromppts2; let i= index">
                        <input [value]="placasserie2[i]" id="serie{{ idatual2
                            }}" (change)="mmppt.qtd_mod_ser=
                            $event.target.value;
                            placasserie2[i]= $event.target.value"
                            style="border-color:
                            transparent;
                            width: 100px;
                            font-size: 13px;
                            background-color: transparent;
                            height: 100%;" class="inputa" min="0" type="number"
                            />
                    </td>
                </tr>

                <tr style="border: 0.15em solid black">
                    <th style="width: 200px; font-size: 13px; background-color:
                        #f0f0f0" scope="row">
                        Quantidade de Strings
                    </th>
                    <td *ngFor="let mmppt of numeromppts2; let i= index">
                        <div class="col-xs-2">
                            <input [value]="placaspar2[i]"
                                (change)="mmppt.qtd_mod_par=
                                $event.target.value;
                                placaspar2[i]= $event.target.value"
                                style="border-color:
                                transparent;
                                width: 100px;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;" class="inputa" min="0"
                                type="number" />
                        </div>
                    </td>
                </tr>
                <tr style="border: 0.15em solid black">
                    <th style="width: 200px; font-size: 13px; background-color:
                        #f0f0f0" scope="row">
                        Tensão Vmpp
                    </th>
                    <td *ngFor="let mmppt of numeromppts2; let i= index">
                        <div class="col-xs-2">
                            <input [value]="(paramsmod[0]['tensaonom'] *
                                placasserie2[i]).toFixed(2)" readonly
                                style="border-color:
                                transparent;
                                width: 70px;
                                font-size: 13px;
                                background-color: transparent;
                                height: 20px;" class="inputa2" type="number" />
                            <img *ngIf="paramsinv2[0]['vmp_max']>
                            (1 + (tempcidade - 25) * (paramsmod[0]['cft'] /
                            100)) *
                            paramsmod[0]['tensaonom'] *
                            placasserie2[i] &&
                            paramsinv2[0]['vmp_min'] <(1 + ((tempcidademax - 25)
                                *
                                paramsmod[0]['cft']) / 100) *
                                paramsmod[0]['tensaonom'] *
                                placasserie2[i] &&
                                (1 + ((tempcidademax - 25) *
                                paramsmod[0]['cft']) /
                                100) *
                                paramsmod[0]['tensaonom'] *
                                placasserie2[i] !==
                                0" style="margin-left: 5px; margin-top: -2px"
                                src="assets/images/correct.png" />
                                <img *ngIf="paramsinv2[0]['vmp_max'] <(1 +
                                    (tempcidade - 25) * (paramsmod[0]['cft'] /
                                    100))
                                    *
                                    paramsmod[0]['tensaonom'] *
                                    placasserie2[i] ||
                                    paramsinv2[0]['vmp_min']>
                                (1 + ((tempcidademax - 25) *
                                paramsmod[0]['cft']) /
                                100) *
                                paramsmod[0]['tensaonom'] *
                                placasserie2[i] ||
                                (1 + ((tempcidademax - 25) *
                                paramsmod[0]['cft']) /
                                100) *
                                paramsmod[0]['tensaonom'] *
                                placasserie2[i] ===
                                0
                                " style="margin-left: 5px; margin-top: -2px"
                                src="assets/images/erro.png" />
                            </div>
                        </td>
                    </tr>

                    <tr style="border: 0.15em solid black">
                        <th style="width: 200px; font-size: 13px;
                            background-color:
                            #f0f0f0" scope="row">
                            Corrente Imp
                        </th>
                        <td *ngFor="let mmppt of numeromppts2; let i= index">
                            <div class="col-xs-2">
                                <input [value]="(paramsmod[0]['correntenom'] *
                                    placaspar2[i]).toFixed(2)" readonly
                                    style="border-color:
                                    transparent;
                                    width: 70px;
                                    font-size: 13px;
                                    background-color: transparent;
                                    height: 20px;" class="inputa2" type="number"
                                    />
                                <img *ngIf="paramsinv2[0]['mppt' + (i + 1)]>
                                paramsmod[0]['correntenom'] * placaspar2[i] &&
                                paramsmod[0]['correntenom'] * placaspar2[i] !==
                                0
                                " style="margin-left: 0px; margin-top: 0px"
                                src="assets/images/correct.png" />
                                <img *ngIf="paramsinv2[0]['mppt' + (i + 1)]
                                <paramsmod[0]['correntenom'] * placaspar2[i] ||
                                    paramsmod[0]['correntenom'] * placaspar2[i]
                                    === 0" style="margin-left:
                                    0px; margin-top: 0px"
                                    src="assets/images/erro.png" />
                            </div>
                        </td>
                    </tr>
                    <tr style="border: 0.15em solid black">
                        <th style="width: 200px; font-size: 13px;
                            background-color:
                            #f0f0f0" scope="row">
                            Potência
                        </th>
                        <td colspan="10">
                            <div *ngIf="salvou2" style="font-size: 13px;
                                align-items:
                                center">
                                {{ potconf2 }}
                                <img
                                    *ngIf="inversorescascata2[idatual2][0].pottotalconfigurada
                                    /
                                    paramsinv2[0]['pot_str']>
                                mi / 100 &&
                                ma / 100 >
                                inversorescascata2[idatual2][0].pottotalconfigurada
                                /
                                paramsinv2[0]['pot_str']
                                " style="margin-left: 5px; margin-top: -2px"
                                src="assets/images/correct.png" />
                                <img *ngIf="mi / 100>
                                inversorescascata2[idatual2][0].pottotalconfigurada
                                /
                                paramsinv2[0]['pot_str'] ||
                                inversorescascata2[idatual2][0].pottotalconfigurada
                                /
                                paramsinv2[0]['pot_str'] >
                                ma / 100
                                " style="margin-left: 5px; margin-top: -2px"
                                src="assets/images/erro.png" />
                            </div>
                        </td>
                    </tr>
                    <tr style="border: 0.15em solid black">
                        <th style="width: 200px; font-size: 13px;
                            background-color:
                            #f0f0f0" scope="row">
                            Número de Módulos
                        </th>
                        <td *ngFor="let mmppt of numeromppts2; let i= index">
                            <div class="col-xs-2">
                                <input [value]="placaspar2[i] * placasserie2[i]"
                                    readonly style="border-color: transparent;
                                    width: 100px;
                                    font-size: 13px;
                                    background-color: transparent;
                                    height: 20px;" class="inputa2" type="number"
                                    />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div  *ngIf="mostracheck2 && engvirtu2" style="margin-left: 5px" class="row">
                <nz-tabset>
                    <nz-tab *ngFor="let mmppt of numeromppts2; let i= index"
                        nzTitle="MPPT {{ i + 1 }}">
                        <div>
                            <p style="width: 400px; font-size: smaller; color:
                                blue" scope="row">
                                <strong>Parâmetros de tensão MPPT {{ i + 1 }}</strong>
                            </p>
                            <div>
                                <div class="col-xs-2">
                                    <p style="font-size: smaller">
                                        MPPT {{ i + 1 }} - Tensão a Vazio Vo a
                                        <strong>{{ tempcidade }}°C</strong> é
                                        <strong>{{
                                            (
                                            (1 + (tempcidade - 25) *
                                            paramsmod[0]["coef"]) *
                                            paramsmod[0]["tensaovazio"] *
                                            placasserie2[i]
                                            ).toFixed(2)
                                            }}V</strong>
                                        - A tensão máxima do inversor é
                                        <strong>{{ paramsinv2[0]["vmp_max"] }}V</strong>
                                        <img *ngIf="paramsinv2[0]['vmp_max']>
                                        (1 + (tempcidade - 25) *
                                        paramsmod[0]['coef']) *
                                        paramsmod[0]['tensaovazio'] *
                                        placasserie2[i] &&
                                        (1 + (tempcidade - 25) *
                                        paramsmod[0]['coef']) *
                                        paramsmod[0]['tensaovazio'] *
                                        placasserie2[i] !==
                                        0
                                        " style="margin-left: 5px; margin-top:
                                        -2px" src="assets/images/correct.png" />
                                        <img *ngIf="paramsinv2[0]['vmp_max'] <(1
                                            +
                                            (tempcidade - 25) *
                                            paramsmod[0]['coef']) *
                                            paramsmod[0]['tensaovazio'] *
                                            placasserie2[i] &&
                                            (1 + (tempcidade - 25) *
                                            paramsmod[0]['coef']) *
                                            paramsmod[0]['tensaovazio'] *
                                            placasserie2[i] !==
                                            0" style="margin-left: 5px;
                                            margin-top:
                                            -2px" src="assets/images/erro.png"
                                            />
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div class="col-xs-2">
                                    <p style="font-size: smaller">
                                        MPPT {{ i + 1 }} - Tensão a Vazio Vo a
                                        <strong>{{ tempcidade }}°C</strong> é
                                        <strong>{{
                                            (
                                            (1 + (tempcidade - 25) *
                                            paramsmod[0]["coef"]) *
                                            paramsmod[0]["tensaovazio"] *
                                            placasserie2[i]
                                            ).toFixed(2)
                                            }}V</strong>
                                        - A tensão máxima de isolamento é
                                        <strong>{{ paramsmod[0]["maxten"] }}V</strong>
                                        <img *ngIf="paramsmod[0]['maxten']>
                                        (1 + (tempcidade - 25) *
                                        paramsmod[0]['coef']) *
                                        paramsmod[0]['tensaovazio'] *
                                        placasserie2[i] &&
                                        (1 + (tempcidade - 25) *
                                        paramsmod[0]['coef']) *
                                        paramsmod[0]['tensaovazio'] *
                                        placasserie2[i] !==
                                        0
                                        " style="margin-left: 5px; margin-top:
                                        -2px" src="assets/images/correct.png" />
                                        <img *ngIf="paramsmod[0]['maxten'] <(1 +
                                            (tempcidade - 25) *
                                            paramsmod[0]['coef']) *
                                            paramsmod[0]['tensaovazio'] *
                                            placasserie2[i] &&
                                            (1 + (tempcidade - 25) *
                                            paramsmod[0]['coef']) *
                                            paramsmod[0]['tensaovazio'] *
                                            placasserie2[i] !==
                                            0" style="margin-left: 5px;
                                            margin-top:
                                            -2px" src="assets/images/erro.png"
                                            />
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div class="col-xs-2">
                                    <p style="font-size: smaller">
                                        MPPT {{ i + 1 }} - Tensão mínima Vn a
                                        <strong>{{ tempcidademax }}°C</strong> é
                                        <strong>{{
                                            (
                                            (1 + ((tempcidademax - 25) *
                                            paramsmod[0]["cft"]) / 100) *
                                            paramsmod[0]["tensaonom"] *
                                            placasserie2[i]
                                            ).toFixed(2)
                                            }}V</strong>
                                        - A tensão mínima VMPP é
                                        <strong>{{ paramsinv2[0]["vmp_min"] }}V</strong>
                                        <img *ngIf="paramsinv2[0]['vmp_min'] <(1
                                            +
                                            ((tempcidademax - 25) *
                                            paramsmod[0]['cft']) / 100) *
                                            paramsmod[0]['tensaonom'] *
                                            placasserie2[i] &&
                                            (1 + ((tempcidademax - 25) *
                                            paramsmod[0]['cft']) / 100) *
                                            paramsmod[0]['tensaonom'] *
                                            placasserie2[i] !==
                                            0" style="margin-left: 5px;
                                            margin-top:
                                            -2px"
                                            src="assets/images/correct.png" />
                                        <img *ngIf="paramsinv2[0]['vmp_min']>
                                        (1 + ((tempcidademax - 25) *
                                        paramsmod[0]['cft']) / 100) *
                                        paramsmod[0]['tensaonom'] *
                                        placasserie2[i] ||
                                        (1 + ((tempcidademax - 25) *
                                        paramsmod[0]['cft']) / 100) *
                                        paramsmod[0]['tensaonom'] *
                                        placasserie2[i] ===
                                        0
                                        " style="margin-left: 5px; margin-top:
                                        -2px" src="assets/images/erro.png" />
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div class="col-xs-2">
                                    <p style="font-size: smaller">
                                        MPPT {{ i + 1 }} - Tensão máxima Vn a
                                        <strong>{{ tempcidade }}°C</strong> é
                                        <strong>{{
                                            (
                                            (1 + ((tempcidade - 25) *
                                            paramsmod[0]["cft"]) / 100) *
                                            paramsmod[0]["tensaonom"] *
                                            placasserie2[i]
                                            ).toFixed(2)
                                            }}V</strong>
                                        - A tensão máxima VMPP é
                                        <strong>{{ paramsinv2[0]["vmpax"] }}V</strong>
                                        <img *ngIf="paramsinv2[0]['vmp_max']>
                                        (1 + (tempcidade - 25) *
                                        (paramsmod[0]['cft'] / 100)) *
                                        paramsmod[0]['tensaonom'] *
                                        placasserie2[i]
                                        " style="margin-left: 5px; margin-top:
                                        -2px" src="assets/images/correct.png" />
                                        <img *ngIf="paramsinv2[0]['vmp_max'] <(1
                                            +
                                            (tempcidade - 25) *
                                            (paramsmod[0]['cft']
                                            / 100)) *
                                            paramsmod[0]['tensaonom'] *
                                            placasserie2[i]" style="margin-left:
                                            5px;
                                            margin-top: -2px"
                                            src="assets/images/erro.png" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p style="width: 200px; font-size: smaller; color: blue"
                            scope="row">
                            <strong>Parâmetros de corrente</strong>
                        </p>
                        <div *ngFor="let mmppt of numeromppts; let i= index">
                            <div class="col-xs-2">
                                <p style="font-size: smaller">
                                    MPPT {{ i + 1 }} - Corrente Isc na enrrada
                                    <strong>
                                        {{
                                        (paramsmod[0]["correntecurto"] *
                                        placaspar2[i]).toFixed(2)
                                        }}
                                        A</strong>
                                    Máxima corrente suportada
                                    <strong>{{ paramsinv[0]["cor_str"] }}A</strong>
                                    <img *ngIf="paramsinv2[0]['cor_str']>
                                    paramsmod[0]['correntecurto'] *
                                    placaspar2[i] &&
                                    paramsmod[0]['correntenom'] * placaspar2[i]
                                    !== 0
                                    " style="margin-left: 5px; margin-top: -2px"
                                    src="assets/images/correct.png" />
                                    <img *ngIf="paramsinv[0]['cor_str']
                                    <paramsmod[0]['correntecurto'] *
                                        placaspar2[i] &&
                                        paramsmod[0]['correntenom'] *
                                        placaspar2[i]
                                        !== 0" style="margin-left: 5px;
                                        margin-top:
                                        -2px" src="assets/images/erro.png" />
                                </p>
                            </div>
                        </div>

                        <div>
                            <p style="width: 200px; font-size: smaller; color:
                                blue" scope="row">
                                <strong>Parâmetros de potência</strong>
                            </p>
                            <div class="col-xs-2">
                                <p style="font-size: smaller">
                                    Potência configurada
                                    <strong>
                                        {{
                                        inversorescascata2[idatual2][0].pottotalconfigurada.toFixed(2)
                                        }}
                                        kW</strong>
                                    . Fator de dimensionamento de
                                    <strong>
                                        {{
                                        (
                                        (inversorescascata2[idatual2][0].pottotalconfigurada
                                        /
                                        paramsinv2[0]["pot_str"]) *
                                        100
                                        ).toFixed(1)
                                        }}
                                        %</strong>
                                    Fator configurado <strong> {{ mi }}% - {{ ma
                                        }}%</strong>
                                    <img
                                        *ngIf="inversorescascata2[idatual2][0].pottotalconfigurada
                                        /
                                        paramsinv2[0]['pot_str']>
                                    mi / 100 &&
                                    ma / 100 >
                                    inversorescascata2[idatual2][0].pottotalconfigurada
                                    /
                                    paramsinv2[0]['pot_str']
                                    " style="margin-left: 5px; margin-top: -2px"
                                    src="assets/images/correct.png" />
                                    <img *ngIf="mi / 100>
                                    inversorescascata2[idatual2][0].pottotalconfigurada
                                    /
                                    paramsinv[0]['pot_str'] ||
                                    inversorescascata2[idatual2][0].pottotalconfigurada
                                    /
                                    paramsinv2[0]['pot_str'] >
                                    ma / 100
                                    " style="margin-left: 5px; margin-top: -2px"
                                    src="assets/images/erro.png" />
                                </p>
                            </div>
                        </div>
                    </nz-tab>
                </nz-tabset>
            </div>
        </p-dialog>
        <ng-template #dialog let-data let-ref="dialogRef">
            <nb-card style="width: 600px">
                <nb-card-header>Gerando sua propsota</nb-card-header>
                <nb-card-body *ngIf="!mostrabuton">
                    <p>
                        Estamos elaborando sua proposta, mas não se preocupe
                        isso
                        durará cerca
                        de 15 segundos! Aguarde
                    </p>
                    <progress-bar [progress]="progress" [color]="'#488aff'">
                    </progress-bar>
                </nb-card-body>
                <nb-card-body *ngIf="mostrabuton">
                    <nz-result nzStatus="success" nzTitle="Sucesso!"
                        nzSubTitle="Esse cliente foi salvo na gestāo de clientes
                        para que você possa monitorar.">
                        <div nz-result-extra>
                            <button (click)="ref.close();" nz-button
                                nzType="primary">
                                Concluir
                            </button>
                        </div>
                    </nz-result>
                </nb-card-body>
            </nb-card>
        </ng-template>

        <p-dialog [style]="{ width: '50vw' }" header="Análise mensal"
            [(visible)]="mediames">
            <div style="margin-top: 10px" class="form-row">
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh" min="0"
                        placeholder="Janeiro" cssClass="e-outline"
                        [(ngModel)]="jan" floatLabelType="Auto"></ejs-numerictextbox>
                </div>
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh"
                        placeholder="Fevereiro" cssClass="e-outline"
                        [(ngModel)]="fev"
                        floatLabelType="Auto"></ejs-numerictextbox>
                </div>
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh" placeholder="Março"
                        cssClass="e-outline" [(ngModel)]="mar"
                        floatLabelType="Auto"></ejs-numerictextbox>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh" placeholder="Abril"
                        cssClass="e-outline" [(ngModel)]="abr"
                        floatLabelType="Auto"></ejs-numerictextbox>
                </div>
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh" placeholder="Maio"
                        cssClass="e-outline" [(ngModel)]="mai"
                        floatLabelType="Auto"></ejs-numerictextbox>
                </div>
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh" placeholder="Junho"
                        cssClass="e-outline" [(ngModel)]="jun"
                        floatLabelType="Auto"></ejs-numerictextbox>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh" placeholder="Julho"
                        cssClass="e-outline" [(ngModel)]="jul"
                        floatLabelType="Auto"></ejs-numerictextbox>
                </div>
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh"
                        placeholder="Agosto" cssClass="e-outline"
                        [(ngModel)]="ago"
                        floatLabelType="Auto"></ejs-numerictextbox>
                </div>
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh"
                        placeholder="Setembro" cssClass="e-outline"
                        [(ngModel)]="set"
                        floatLabelType="Auto"></ejs-numerictextbox>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh"
                        placeholder="Outubro" cssClass="e-outline"
                        [(ngModel)]="out"
                        floatLabelType="Auto"></ejs-numerictextbox>
                </div>
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh"
                        placeholder="Novembro" cssClass="e-outline"
                        [(ngModel)]="nov"
                        floatLabelType="Auto"></ejs-numerictextbox>
                </div>
                <div class="form-group col-4">
                    <ejs-numerictextbox format="###.### kWh"
                        placeholder="Dezembro" cssClass="e-outline"
                        [(ngModel)]="dez"
                        floatLabelType="Auto"></ejs-numerictextbox>
                </div>
            </div>
            <div class="modal-footer" style="margin-top: 00px">
                <button (click)="zera(); mediames= false" type="button"
                    class="btn
                    btn-primary" data-dismiss="modal">
                    Calcular
                </button>
                <button (click)="mediames= false" stype="button" class="btn
                    btn-secondary" data-dismiss="modal">
                    Cancelar
                </button>
            </div>
        </p-dialog>



        <p-dialog [style]="{ width: '60vw' }" header="Análise Grupo A"
            [(visible)]="grupoax">
            <nz-tabset>
                <nz-tab [nzTitle]="'Consumo na ponta'">
                    <div style="margin-top: 10px" class="form-row">
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" min="0" placeholder="Janeiro"
                                cssClass="e-outline" [(ngModel)]="pjan"
                                floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Fevereiro"
                                cssClass="e-outline"
                                [(ngModel)]="pfev" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Março" cssClass="e-outline"
                                [(ngModel)]="pmar" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Abril" cssClass="e-outline"
                                [(ngModel)]="pabr" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Maio" cssClass="e-outline"
                                [(ngModel)]="pmai" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Junho" cssClass="e-outline"
                                [(ngModel)]="pjun" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Julho" cssClass="e-outline"
                                [(ngModel)]="pjul" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Agosto" cssClass="e-outline"
                                [(ngModel)]="pago" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Setembro" cssClass="e-outline"
                                [(ngModel)]="pset" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Outubro" cssClass="e-outline"
                                [(ngModel)]="pout" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Novembro" cssClass="e-outline"
                                [(ngModel)]="pnov" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Dezembro" cssClass="e-outline"
                                [(ngModel)]="pdez" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                    </div>
                </nz-tab>
                <nz-tab [nzTitle]="'Consumo fora de ponta'">
                    <div style="margin-top: 10px" class="form-row">
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" min="0" placeholder="Janeiro"
                                cssClass="e-outline" [(ngModel)]="fjan"
                                floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Fevereiro"
                                cssClass="e-outline"
                                [(ngModel)]="ffev" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Março" cssClass="e-outline"
                                [(ngModel)]="fmar" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Abril" cssClass="e-outline"
                                [(ngModel)]="fabr" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Maio" cssClass="e-outline"
                                [(ngModel)]="fmai" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Junho" cssClass="e-outline"
                                [(ngModel)]="fjun" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Julho" cssClass="e-outline"
                                [(ngModel)]="fjul" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Agosto" cssClass="e-outline"
                                [(ngModel)]="fago" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Setembro" cssClass="e-outline"
                                [(ngModel)]="fset" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Outubro" cssClass="e-outline"
                                [(ngModel)]="fout" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Novembro" cssClass="e-outline"
                                [(ngModel)]="fnov" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.###
                                kWh" placeholder="Dezembro" cssClass="e-outline"
                                [(ngModel)]="fdez" floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                    </div>
                </nz-tab>
                <nz-tab [nzTitle]="'Tarifaçāo e consumo'">
                    <div style="margin-top: 10px" class="form-row">
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="10" format="$
                                ###.######" min="0"
                                placeholder="Tarifa Fora de ponta(TFP)"
                                cssClass="e-outline" [(ngModel)]="tfp"
                                floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="10" format="$
                                ###.######" placeholder="Tarifa de ponta(TP)"
                                cssClass="e-outline" [(ngModel)]="tp"
                                floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="10"
                                format="###.######" placeholder="Fator de
                                ajuste" readonly
                                cssClass="e-outline" [(ngModel)]="fatorajuste"
                                floatLabelType="Aucto"></ejs-numerictextbox>
                        </div>
                    </div>
                    <div style="margin-top: 10px" class="form-row">
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.######
                                kWh" min="0" placeholder="Consumo de ponta"
                                readonly cssClass="e-outline" [(ngModel)]="cmp"
                                floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.######
                                kWh" placeholder="Consumo fora de ponta"
                                readonly cssClass="e-outline" [(ngModel)]="cmfp"
                                floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.######
                                kWh" placeholder="Zerar ponta e fora de ponta"
                                readonly cssClass="e-outline"
                                [(ngModel)]="consumototalpor12"
                                floatLabelType="Auto">
                            </ejs-numerictextbox>
                        </div>
                    </div>
                    <div style="margin-top: 10px" class="form-row">
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="###.######
                                kW" placeholder="Demanda contratada"
                                cssClass="e-outline" [(ngModel)]="demanda"
                                floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox decimals="2" format="$
                                ###.######" placeholder="Tarifa da demanda"
                                cssClass="e-outline" [(ngModel)]="custodemanda"
                                floatLabelType="Auto"></ejs-numerictextbox>
                        </div>
                        <div class="form-group col-4">
                            <ejs-numerictextbox readonly decimals="2" format="$
                                ###.######" placeholder="Custo
                                total da demanda" cssClass="e-outline"
                                [(ngModel)]="custodisponibilidade"
                                floatLabelType="Auto">
                            </ejs-numerictextbox>
                        </div>
                    </div>
                </nz-tab>
            </nz-tabset>
            <div class="modal-footer" style="margin-top: 00px">
                <button (click)="grupoa()" type="button" class="btn btn-primary"
                    data-dismiss="modal">
                    Calcular
                </button>
                <button (click)="grupoax= false" stype="button" class="btn
                    btn-secondary" data-dismiss="modal">
                    Cancelar
                </button>
            </div>
        </p-dialog>
        <p-dialog [style]="{ width: '50vw' }" header="Atençāo"
            [(visible)]="avissx">
            <nz-result nzStatus="warning" nzTitle="Atenção">
                <div nz-result-extra>
                    <p *ngIf="avisaplaca" style="font-size: larger; text-align:
                        justify;">
                        Verifique a quantidade de módulos: A potência
                        configurada é menor que a potência necessária para o
                        consumo inserido!
                    </p>
                    <p *ngIf="avisodem" style="font-size: larger;text-align:
                        justify;">
                        Demanda contratada é <b style="color: red"> {{ demanda
                            }} kW</b>
                        , a
                        potência nominal configurada não pode ser maior que esse
                        valor .
                        Faça um estudo para verificar a viabilidade do aumento
                        de
                        demanda
                    </p>
                </div>
            </nz-result>
        </p-dialog>

        <p-dialog [style]="{ width: '40vw' }" [(visible)]="gera">
            <ng-template pTemplate="header">
                Atenção
            </ng-template>
            <div style="text-align: justify !impotant; text-justify :
                inter-word;">
                <p>
                    Essa é uma estimativa de geração e dessa forma trata-se de
                    um modelo de aproximação! Os valores de geração
                    são condicionados à parâmetros como: potência nominal do
                    inversor, perdas, orientações do telhado,
                    sombreamento etc.
                </p>
                <br>
                <p>
                    <b> A Sollux Engenharia não se responsabiliza pela não
                        observação desses detalhes no dimensionamento .</b>
                </p>
                <br>
                <p>
                    Caso deseje um estudo detalhado de viabilidade é necessário
                    contratar o serviço com um dos consultores da
                    Sollux.
                </p>
            </div>
            <ng-template pTemplate="footer">
                <div class="flex justify-content-left pt-0 col-12">
                    <button style="background-color: rgb(72, 51, 121)
                        !important;
                        color: white;
                        height: 40px;
                        font-size: 14px;
                        font-weight: bold;" (click)="gera=false" pButton pRipple
                        label="Ok"
                        class="p-button-outlined p-button-secondary w-12 mr-2"></button>
                </div>
            </ng-template>
        </p-dialog>


        <p-dialog [style]="{ width: '40vw' }" [(visible)]="novogera">
            <ng-template pTemplate="header">
                Atenção
            </ng-template>
            <div style="text-align:justify !impotant; text-justify:inter-word">
                <p>
                 Você está usando uma proposta salva, para gerar o relatório basta clicar no botão abaixo. 
                <br>
                    <b> Mas lembre-se, sempre que fizer uma alteração deverá efeutar novamente o cálculo automático .</b>
                </p>
            </div>
            <ng-template pTemplate="footer">
                <div class="flex justify-content-left pt-0 col-12">
                    <button style="background-color: rgb(72, 51, 121)
                        !important;
                        color: white;
                        height: 40px;
                        font-size: 14px;
                        font-weight: bold;" (click)="novogera=false;nreleco()" pButton pRipple
                        label="Calcular agora"
                        class="p-button-outlined p-button-secondary w-12 mr-2"></button>
                </div>
            </ng-template>
        </p-dialog>

<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>