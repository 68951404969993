import { Component, OnInit, ViewChild, ValueProvider, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Customer } from './data';
import {
  GridComponent, ToolbarService, ExcelExportService, PdfExportService,
  GroupService, ExcelQueryCellInfoEventArgs, PdfQueryCellInfoEventArgs, RowSelectEventArgs
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations'
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { FileUploader } from "ng2-file-upload";
const URL = 'https://www.sollux.dev/api/_functions/consultas/upaimax';
import { RichTextEditorModel } from '@syncfusion/ej2-richtexteditor';
import { PopupSettingsModel } from "node_modules/@syncfusion/ej2-inplace-editor/src/inplace-editor/base/models-model";
import { MYCLIENTES } from '../homologacoes/myclientes';

@Component({
  selector: 'app-employers',
  templateUrl: './employers.component.html',
  styleUrls: ['./employers.component.css']
})
export class EmployersComponent implements OnInit {
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  formData: any;
  employerfildred: any;
  datatarefa: any
  tarefanova = ""
  validaupload = "false"
  public commentsPopSettings: PopupSettingsModel;
  public commentEditorModel: RichTextEditorModel = {
    toolbarSettings: {
      enableFloating: false,
      items: ['Bold', 'Italic', 'Underline', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|', 'OrderedList', 'UnorderedList']
    }
  };
  public commentRule: { [name: string]: { [rule: string]: Object } } = {
    rte: { required: [true, 'Enter valid comments'], maxLength: 80 }
  };
  public empregados: Object[] = [
    { Id: 'Administrador', tipo: 'Administrador' },
    { Id: 'Vendas', tipo: 'Vendas' },
    { Id: 'Instalador', tipo: 'Instalador' },
    { Id: 'Técnico', tipo: 'Técnico' },
    { Id: 'Financeiro', tipo: 'Financeiro' },
    { Id: 'Engenharia', tipo: 'Engenharia' },
    { Id: 'Estagiário', tipo: 'Estagiário' },
    { Id: 'Compras', tipo: 'Compras' },
    { Id: 'Marketing', tipo: 'Marketing' },
    { Id: 'TI', tipo: 'TI' },

  ];
  permiteadm =  false;
  public fields: Object = { text: 'tipo', value: 'Id' };

  public data: Object[];
  public toolbar: string[];
  public pageSettings: Object;
  public isInitial: Boolean = true;
  cadastracli = false;
  tabelacli = false
  loading = false;
  avatarUrl?: string;
  fileupd: any;
  filtrado = false;
  title = 'fileUpload';
  images;
  urlx : any;
  urlxok = false
  nCliente = ''
  display5w = false;
  enviarfoto = false;
  multipleImages = [];
  id = '';
  @ViewChild('grid')
  public grid: GridComponent;
  items: MenuItem[];
  tabs = [
    { title: "Estrutura da Empresa", id: 0 },
    { title: "Funcionários", id: 1 },
  ]
  preview: string;
  form: FormGroup;
  percentDone: any = 0;
  users = [];
  cincodata: any;
  cincodatafiltro: any;
  cincow2h = true;
  meuemail = '';
  statuses: any[];
  activityValues: number[] = [0, 100];
  funcionario = ''
  editacard = false;
  datatarefax: any;
  nStatus = '';
  nobservadires = '';
  observa = [];
  nTitle = '';
  nAssignee = ''
  nduracao: number;
  novatarx = ""
  mduracao = 0
  whatsppAssignee = ''
  ndatafim: any;
  todosclientes = [];
  ndescricao = '';
  projetx =''
  nomecli = ''
  adob = false;
  adresp= false;
  novatarefas = false;
  sholist = false
  public fieldsx: Object = { text: 'funcionario', value: 'funcionario' };
  idfiltr = ''
  urln=''
  tarefaspadrao = []
  trocarsenha = false;
  taskx = ''
  padrotar = false;
  todos = []
  public listex: Object = {
    text: 'Cliente',
    value: 'Cliente'
  }
  nobservadores = '';
  novob = '';
  employers: any;
  usuario = '';
  clientemanu = false
  email = ''
  wppfun = ''
  cargo = ''
  sobremim = ''
  senha = null
  novasenha = null
  adm = ''
  dadosolicitante: any;
  resposta: any;
  solicitante: string;
  showaviso = false
  colaboradorinserido = false
  emailexiste = false
  erroinserir = false
  cadastrou = false
  abrecalendar = false
  meunome = '';
    
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private service: HomologacoesserviceService
  ) {
    this.form = this.fb.group({
      name: [''],
      avatar: [null]
    })

    this.uploader = new FileUploader({
      url: URL,
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.response = '';
    this.uploader.response.subscribe(res => this.response = res);
  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  zeralist(){
    this.todos = []
  }

  show(x) {
    for (let i = 0; i < this.cincodata.length; i++) {
      if (this.cincodata[i].idx = x) {
        this.cincodatafiltro = this.cincodata[i]
        this.display5w = true;
      }
    }
  }
  puxasolicitante() {
    this.service.admx({ email: this.meuemail })
      .subscribe(dados => {
        if (dados !== this.dadosolicitante) {
          this.dadosolicitante = dados;
          console.log('solicitante', this.dadosolicitante)
        }
      })
  }
  admsim(a) {
    this.permiteadm = a
    console.log(this.permiteadm)
  }
  dropped(e){
    console.log(e)
    var reader = new FileReader();
		reader.readAsDataURL(this.uploader.queue[0]["_file"]);
		reader.onload = (_event) => {
			this.urlx = reader.result; 
		}
    this.urlxok = true
  }

  filtrand(a){
    console.log(a)
    for (let i = 0; i < this.todosclientes.length; i++) {
      console.log(this.todosclientes[i]['Cliente'])
      if (this.todosclientes[i]['Cliente'] === a) {
        this.projetx = this.todosclientes[i]['codcliente']
        console.log(this.todosclientes[i])
      }
    }
  }
  atribuir2() {
    this.novatarx = (((Date.now()) / 1000).toFixed(0)).toString()
    var urldono = ""
    for (let i = 0; i < this.employers.length; i++) {
      if (this.nAssignee === this.employers[i]["nickname"]) {
        urldono = this.employers[i]["pic"]
        this.whatsppAssignee = this.employers[i]["wpp"]
      }
    }
    this.service.newmanager({
      wppresp: this.whatsppAssignee,
      Title: this.nTitle,
      Status: "Pendente",
      Summary: "",
      Type: "Demanda",
      Priority: "",
      Tags: "Demanda",
      Estimate: this.nduracao,
      Assignee: this.nAssignee,
      RankId: 0,
      Color: "#8F8173",
      ClassName: "e-story, e-low, e-nancy-davloio",
      dataini:  new Date(),
      datafim: this.ndatafim,
      valor: "#8F8173",
      nomecli: 'this.filtredhomol.nome',
      descricao: this.ndescricao,
      observadores: this.nobservadores,
      criador: this.meunome,
      urlcriador: this.urln,
      ide: 'this.homolselec',
      urldono: urldono,
      Id: this.novatarx,
      ts1: this.nduracao
    })
      .subscribe(dados => {
        if (dados) {
          this.editacard = false
          this.nAssignee = ''
          this.nobservadores = ''
          this.todos = []
          if (dados['status'] === 'atualizado') {
            //this.filtrawppx2();
            //this.nvtar();
          }
          if (dados['status'] === 'erro') {
          }
        }
      });
  }
  gettodos() {
    this.todos = []
    this.nduracao = 0
    this.service.getliix({ ID: this.idfiltr }).subscribe((datax: any[]) => {
      if (datax) {
        this.todos = datax;
        for (let i = 0; i < this.todos.length; i++) {
          this.nduracao = this.nduracao + this.todos[i].estimate
          console.log(this.nduracao)
          //this.mduracao = (new Date(this.nduracao * 1000 * 60).toISOString().substr(11, 8))
        }
      }
    });
  }

  mudades() {
    for (let m = 0; m < this.tarefaspadrao.length; m++) {
      if (this.nTitle === this.tarefaspadrao[m].title) {
        this.idfiltr = this.tarefaspadrao[m].tag
        this.ndescricao = this.tarefaspadrao[m].descricao
        this.gettodos()
      }
    }
  }
  
  adoba() {
    var pic = '';
    var emax = '';
    var newobserva = this.nobservadores
    //this.tarefafiltrada.observadores = ''
    let observa = (newobserva).split(';')
    var controle = false
    for (let m = 0; m < observa.length; m++) {
      if (this.novob === observa[m].split(',')[0]) {
        controle = true
      }
    }
    if (controle !== true && this.novob !== this.nAssignee && this.novob !== this.meunome) {
      for (let i = 0; i < this.employers.length; i++) {
        if (this.novob === this.employers[i]["nickname"]) {
          pic = this.employers[i]["pic"]
          emax = this.employers[i]["email"]
        }
      }
      this.nobservadores = this.nobservadores + this.novob + ',' + pic + ';'
      this.observa.push({ user: this.novob, pic: pic , email:emax })
    }
    this.novob = ''
    //this.updatestatus();
  }
  mudaob(a){
    this.novob = a
    console.log(a)
  }
  cadastranovos() {
    this.cadastrou = false
    console.log(this.funcionario)
    console.log(this.email)
    this.service.cadastranovo({
      token: localStorage.getItem('tokenx'),
      funcionario: this.funcionario,
      email: this.email,
      wppfun: this.wppfun,
      cargo: this.cargo,
      sobremim: this.sobremim,
      adm: this.permiteadm,
      emailad: this.meuemail,
      senha: this.senha
    })
      .subscribe((data) => {
        console.log(data)
        this.resposta = data
        if (this.resposta.valido === "emailexiste") {
          this.emailexiste = true;
        }
        if (this.resposta.valido === "atualizado") {
          this.colaboradorinserido = true;
          this.cadastrou = true
          this.mostrafile2()

        }
        if (this.resposta.valido === "erro") {
          this.erroinserir = true;
        }


      });
  }
  upacliente() {
    if(this.trocarsenha = true){
      if(this.trocarsenha === true && this.senha !== null){
      if(this.novasenha === this.senha){
        this.employerfildred['senha'] = this.novasenha;
      }}
      this.service.upamydados2({
        token: localStorage.getItem('tokenx'),
        funcionario: this.employerfildred['funcionario'],
        id: this.employerfildred['id'],
        email: this.employerfildred['email'],
        wppfun: this.employerfildred['wppfun'],
        cargo: this.employerfildred['cargo'],
        sobremim: this.employerfildred['sobremim'],
        adm: this.employerfildred['adm'],
        senha: this.employerfildred['senha'],
      })
        .subscribe((data) => {
          if (data['status'] === 'atualizado') {
  
          }
        });
    }
    if(this.trocarsenha = false){
    this.service.upamydados({
      token: localStorage.getItem('tokenx'),
      funcionario: this.employerfildred['funcionario'],
      id: this.employerfildred['id'],
      email: this.employerfildred['email'],
      wppfun: this.employerfildred['wppfun'],
      cargo: this.employerfildred['cargo'],
      sobremim: this.employerfildred['sobremim'],
      adm: this.employerfildred['adm'],
    })
      .subscribe((data) => {
        if (data['status'] === 'atualizado') {

        }
      });}
      this.trocarsenha = false
      this.senha = null
      this.novasenha = null
  }
  dhc(a) {
    console.log(a)
  }
  mostrafile() {
    console.log(this.employerfildred['email'].split('@')[0])
    const file = this.uploader.queue[0]["_file"];
    this.form.patchValue({
      avatar: file,
      nome: this.employerfildred['email']
    });
    this.form.get('avatar').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;
    }
    reader.readAsDataURL(file)

    this.submitForm()
  }

  

  mostrafile2() {
    const file = this.uploader.queue[0]["_file"];
    this.form.patchValue({
      avatar: file,
      nome: this.email
    });
    this.form.get('avatar').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;
    }
    reader.readAsDataURL(file)

    this.submitForm2()
  }

  submitForm2() {
    this.service
      .addUser(
        this.email,
        this.form.value.avatar
      ).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.percentDone = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.percentDone}%`);
            break;
          case HttpEventType.Response:
            console.log('User successfully created!', event.body);
            this.percentDone = false;
        }
      })
  }



  submitForm() {
    this.service
      .addUser(
        this.employerfildred['email'],
        this.form.value.avatar
      ).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.percentDone = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.percentDone}%`);
            break;
          case HttpEventType.Response:
            console.log('User successfully created!', event.body);
            this.percentDone = false;
        }
      })
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  cria() {
    this.service.criadbmana({ id:this.id,name: this.data[0]['nickname'].split(' ')[0] + this.id.split('-')[2] }).subscribe((datax: any[]) => {
      if (datax) {
        console.log('equipe', datax)
      }
    });
  }

  novatarefax() {
    this.novatarx = (((Date.now()) / 1000).toFixed(0)).toString()
    var urldono = ""
    for (let i = 0; i < this.employers.length; i++) {
      if (this.nAssignee === this.employers[i]["nickname"]) {
        urldono = this.employers[i]["pic"]
        this.whatsppAssignee = this.employers[i]["wpp"]
      }
    }
    this.service.managercli({
      managerx:   'manager_'+ this.employers[0]["nickname"].split(' ')[0] + this.id.split('-')[2],
      wppresp: this.whatsppAssignee,
      Title: this.nTitle,
      Status: "Pendente",
      Summary: "",
      Type: "Demanda",
      Priority: "",
      Tags: "Demanda",
      Estimate: this.nduracao,
      Assignee: this.nAssignee,
      RankId: 0,
      Color: "#8F8173",
      ClassName: "e-story, e-low, e-nancy-davloio",
      dataini:  new Date(),
      datafim: this.ndatafim,
      valor: "#8F8173",
      nomecli: this.nomecli,
      descricao: this.ndescricao,
      observadores: this.nobservadores,
      criador: this.meunome,
      urlcriador: this.urln,
      ide: this.projetx,
      urldono: urldono,
      Id: this.novatarx,
      ts1: this.nduracao
    })
      .subscribe(dados => {
        if (dados) {
          this.editacard = false
          this.nAssignee = ''
          this.nobservadores = ''
          this.todos = []
          if (dados['status'] === 'atualizado') {
            //this.filtrawppx2();
            //this.nvtar();
          }
          if (dados['status'] === 'erro') {
          }
        }
      });
  }


  public ngOnInit(): void {
    this.urlx= "assets/gen.png"
    this.service.currenturl.subscribe(dados => {
      this.urln = dados;
    });
    this.service.currentmail.subscribe((dados) => {
      if (dados) {
        this.meuemail = dados
      }
    });
    this.service.currentid.subscribe((dados) => {
      if (dados) {
        this.id = dados;
        this.puxasolicitante()
        this.puxaequipe();
      }
    });
    this.items = [
      {
        label: 'Calendário de eventos',
        icon: 'pi pi-calendar-times',
        command: (event) => {
          this.abrecalendario();
        }

      },
      {
        label: 'Tarefas',
        icon: 'pi pi-calendar-times',
        command: (event) => {
          this.abrecalendar = false;
          this.tabelacli = false;
          this.cincow2h = true;
        }
      }

      ,
      {
        label: 'Funcionários',
        icon: 'pi pi-users',
        command: (event) => {
          this.abrecalendar = false;
          this.tabelacli = true;
          this.cincow2h = false;
        }


      },
      {
        label: 'Adicionar Colaborador',
        icon: 'pi pi-fw pi-plus',
        command: (event) => {
          if (this.dadosolicitante.cargo !== "Administrador" && this.dadosolicitante.adm !== 1) {
            this.showaviso = true
          }
          if (this.dadosolicitante.cargo === "Administrador" || this.dadosolicitante.adm === 1) {
            this.cadastracli = true
          }
        }
      },

      {
        label: 'Adicionar Tarefa',
        icon: 'pi pi-calendar-plus',
        command: (event) => {
          this.editacard = true;
          this.novatarefas = true
        }
      }

    ];

    this.toolbar = ['ExcelExport', 'PdfExport', 'CsvExport'];
    this.pageSettings = { pageCount: 5 };
  }
  abrecalendario() {
    this.abrecalendar = true;
    this.tabelacli = false
    this.cincow2h = false
  }
  abrecli() {
    this.cadastracli = true;
  }
  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case 'PDF Export':
        this.grid.pdfExport();
        break;
      case 'Excel Export':
        this.grid.excelExport();
        break;
      case 'CSV Export':
        this.grid.csvExport();
        break;
    }
  }

  novatarefa() {
    this.service.cadastralista({
      token: localStorage.getItem('tokenx'),
      tarefa: this.tarefanova,
      data: this.datatarefa,
      checked: "false",
      dono: this.meuemail,
      idempresa: this.id,

    })
      .subscribe((data) => {
        console.log(data)
      });
  }
  puxahomologacoes() {
    this.service.myhomol2({ ID: this.id }).subscribe((data) => {
      if (data) {
        this.todosclientes = data;
      }
    });
  }
  puxaclientes() {
    this.service.myclientes2({ ID: this.id }).subscribe((data: MYCLIENTES[]) => {
      if (data) {
        this.todosclientes = data;
        console.log(this.todosclientes);
      }
    });
  }
  
  exportQueryCellInfo(args: ExcelQueryCellInfoEventArgs | PdfQueryCellInfoEventArgs): void {
    if (args.column.headerText === 'Employee Image') {
      if ((args as any).name === 'excelQueryCellInfo') {
        args.image = { height: 75, base64: (args as any).data.EmployeeImage, width: 75 };
      } else {
        args.image = { base64: (args as any).data.EmployeeImage };
      }
    }
    if (args.column.headerText === 'Email ID') {
      args.hyperLink = {
        target: 'mailto:' + (args as any).data.EmailID,
        displayText: (args as any).data.EmailID
      };
    }
  }
  public checkboxChange(e: any): void {
    let fields: string[] = ['Employee Image', 'Email ID'];
    if (e.checked) {
      this.grid.showColumns(fields, 'headerText');
      this.grid.toolbarModule.toolbar.hideItem(2, true);
    } else {
      this.grid.hideColumns(fields, 'headerText');
      this.grid.toolbarModule.toolbar.hideItem(2, false);
    }
  }

  puxaequipe() {
    this.service.allequipe({ ID: this.id }).subscribe((datax: any[]) => {
      if (datax) {
        console.log('equipe', datax)
        this.data = datax;
        this.employers = datax;
        this.cria();
        console.log(this.data)
        this.tabelacli = false;
      }
    });
  }



  postafile() {
    console.log(this.uploader.queue[0]["_file"])
    this.service
      .postfilex(
        this.uploader.queue[0]["_file"]
      )
      .subscribe((dados) => {
        if (dados) {
          console.log(dados);

        }
      });
  }
  selectImage(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.images = file;
    }
  }

  selectMultipleImage(event) {
    if (event.target.files.length > 0) {
      this.multipleImages = event.target.files;
    }
  }

  onSubmit() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        "Content-Type": "application/json",
      })
    };

    let formData = new FormData();
    formData.append('imagePath', this.uploader.queue[0]["_file"]);
    console.log(formData)
    this.http.post<any>('https://www.sollux.dev/api/_functions/consultas/upaimax', { formData, headers: httpOptions }).subscribe(
      (res) => console.log(res),
      (err) => console.log(err)
    )
  }
  puxatarefas() {
    this.service
      .cincow({ ID: this.meuemail })
      .subscribe((data: any[]) => {
        console.log(data)
        this.cincodata = data
      });
  }
  onMultipleSubmit() {
    const formData = new FormData();
    for (let img of this.multipleImages) {
      formData.append('files', img);
    }
    this.http.post<any>('http://localhost:3000/multipleFiles', formData).subscribe(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  }

  public onRowSelected(args: RowSelectEventArgs): void {
    console.log(args)
    const queryData: any = args.data;
    console.log(queryData)
    this.employerfildred = queryData
    console.log(this.employerfildred)
    this.enviarfoto = false;
    this.filtrado = true;
  }

}
