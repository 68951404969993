import { Component, OnInit } from '@angular/core';
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';

@Component({
  selector: 'app-curso',
  templateUrl: './curso.component.html',
  styleUrls: ['./curso.component.css']
})
export class CursoComponent implements OnInit {
  isCollapsed = false;
  curso = []
  srcslect = ""
  stepslect = ""
  idselect = 0
  selecionada:any;
  filtrou = false
  constructor(private service: HomologacoesserviceService) { }

  ngOnInit(): void {

    this.service.datasolcuso({a:"a"})
    .subscribe(dados => {
      console.log(dados)
      this.curso = dados
      this.srcslect = this.curso[0].src
      console.log(this.srcslect)
      this.filtrou = true;
      this.selecionada = this.curso[0]
      this.idselect = 0;
    })

  }

  selecionaaula(a){
    for (let i = 0; i < this.curso.length; i++) {
      if(this.curso[i].id === a){
        if(this.curso[i].src === null){
          this.srcslect = this.curso[this.idselect].src 
          this.filtrou = true
        }
        if(this.curso[i].src != null){
          this.srcslect = this.curso[i].src 
          this.selecionada = this.curso[i]
          this.filtrou = true
          this.idselect = a;
        }

      }
    }
  }

}
