import {AfterViewChecked, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  canvasWidth = 800;
  canvasHeight = 600;
  validacao3 = {};
  validacao4 = {};
  seedValue: string;
  showatualiza = false;
  datainicio: any;
  msg="";
  allmsg = [];
  allultimas = [];
  contatoslista =[];
  QRCode='';
  contact:any;
  numeroselecionado = '';
  unread : any;
  indice:any;
  numeroselecionadoser='';
  picsel ='';
  nomesel='';
  sp= true;
  lsi : any;
  meunome='';
  public page = 1;
  public pageLabel: string;

  contatotrue = false;
  @ViewChild('scrollMe', {static:false}) private myScrollContainer: ElementRef;
  constructor( private service: HomologacoesserviceService, private dialogService: NbDialogService)
   {  }
 

  ngOnInit() {
    this.service.currentNome.subscribe(dados => {
      if (dados !== this.meunome && dados !== '') {
        this.meunome = dados;
        console.log(this.meunome);

      }
    });
    this.contatos()
    this.lsi = (((Date.now()) / 1000).toFixed(0)).toString()
  }
  seedData(ev: string): void {
    this.seedValue = ev;
  }
  contatos(){
    this.service.contatos({
      sessionName:'sollux'
    })
    .subscribe(dados => {
      if (dados) {
        this.contact = dados;
        console.log(this.contact);
        this.contatotrue = true;
        for (let i = 0; i < this.contact.length; i++) {
          this.contatoslista.push(this.contact[i].id._serialized)
          if(this.contact[i].profilePicThumbObj === {}){
            this.contact[i].profilePicThumbObj  = '';
            console.log('imagem é nula')}
        }
        //this.puxamsg(this.contact[1].id._serialized,this.contact[1].id.user,this.contact[1].profilePicThumbObj.eurl,this.contact[1].formattedName)
      }
      console.log(this.contatoslista)
    this.ultimas()
    });
  }

  allwpp(){
    this.service.allcontawpp()
    .subscribe(dados => {
      if (dados) {
      for (let i = 0; i < dados.length; i++) {
          this.contatoslista.push(dados[i].number)
        }
      console.log(this.contatoslista);
      this.ultimas()
      }
    });
  }
  

      
  ultimas(){
    this.service.getallmsg2({
      number:this.contatoslista,
      sessionName: 'sollux'
    })
    .subscribe(dados => {
      console.log(dados);
      if (dados !== this.allultimas) {
        this.allultimas  = dados;
        this.puxamsg(this.allultimas[0].chat.contact.id._serialized,this.allultimas[0].chat.contact.id.user,this.allultimas[0].chat.contact.profilePicThumbObj.eurl,this.allultimas[0].chat.contact.formattedName)
      }
    });
    
  }
  apuxamsg(indice){
    this.puxamsg(this.allultimas[indice].chat.contact.id._serialized,this.allultimas[indice].chat.contact.id.user,this.allultimas[indice].chat.contact.profilePicThumbObj.eurl,this.allultimas[indice].chat.contact.formattedName)
  }
  mudadata(){
    console.log(this.datainicio+" 00:00:00")
  var unixdate = (new Date(this.datainicio+" 00:00:00")).getTime() / 1000;
  this.lsi = unixdate
  console.log(unixdate);
  this.puxamsg2()
  }
  naolidas(){
    this.service.nlidas({
      sessionName:'sollux'
    })
    .subscribe(dados => {
      if (dados !== this.unread) {
        this.unread = dados;
        console.log(this.unread);
      }
    });
  }
  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this' , closeOnBackdropClick : true, closeOnEsc : true});
  }
  openWithBackdrop(a){
    this.open(a);
  }  
  
  puxamsg(a,b,c,d){
    this.sp = true;
    this.allmsg = [];
    this.picsel =c;
    this.nomesel =d;
    console.log(a)
    console.log(b)
    this.numeroselecionadoser = a;
    this.numeroselecionado = b;
    this.service.getallmsg({
      number:a,
      tnow: this.lsi,
      t:parseInt(this.lsi) - (5*24*60*60)
    })
    .subscribe(dados => {
      console.log(dados);
      if (dados !== this.allmsg) {
        this.allmsg = dados;
        this.sp = false;
        this.scrollToBottom();
      }
    });  
  }

  puxamsg2(){
    this.sp = true;
    var tnow = (((Date.now()) / 1000).toFixed(0)).toString()
    this.service.getallmsg({
      number:this.numeroselecionado+'@c.us',
      tnow: this.lsi 
    })
    .subscribe(dados => {
      console.log(dados);
      if (dados) {
        this.allmsg = (dados)
        console.log(this.allmsg)
        this.sp = false;
      }
    });
    
  }
  enviamsg(){
    this.service.sendtext({
      sessionName:'sollux',
      number:this.numeroselecionado,
      text:this.msg
    })
    .subscribe(dados => {
      if (dados !== this.validacao4) {
        this.validacao4 = dados;
        if(dados['status'] === 'Enviado' ){
          this.showatualiza = true;
          console.log(dados['status'])
          this.puxamsg(this.allultimas[this.indice].chat.contact.id._serialized,this.allultimas[this.indice].chat.contact.id.user,this.allultimas[this.indice].chat.contact.profilePicThumbObj.eurl,this.allultimas[this.indice].chat.contact.formattedName)

        this.msg='';
        }
        console.log(dados);
      }
    });
    this.validacao4 = {}
  }



scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}

  qrcode(a){
    this.service.qrcode({
      sessionName:'sollux',
    })
    .subscribe(dados => {
      if (dados) {
        this.QRCode = (dados['qrcode'])
        this.openWithBackdrop(a)
      }
    });
    this.validacao4 = {}
  }

  wpp(){
    this.service.wpp({
      'sessionName':'sollux'
    })
    .subscribe(dados => {
      if (dados !== this.validacao3) {
        this.validacao3 = dados;
        console.log(dados);
        this.QRCode = dados['message']
        console.log(this.QRCode)
        console.log(dados)

      }
    });
    this.validacao3 = {}
  }

  contators(){
    this.service.contatos({
      'sessionName':'sollux'
    })
    .subscribe(dados => {
      if (dados) {
        console.log(dados)
      }
    });
    this.validacao3 = {}
  }
}
