import { Component, OnInit, ViewChild, ElementRef, TemplateRef, Input } from '@angular/core';
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';
import { todosdados } from '../homologacoes/todosdados';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as $AB from 'jquery';
import 'node_modules/jquery/dist/jquery.min.js';
import 'src/assets/js/radiacao.js';
import 'src/assets/js/docxtemplater.js';
import 'src/assets/js/FileSaver.min.js';
import 'src/assets/js/pizzip-utils.js';
import 'src/assets/js/pizzip.js';
import 'src/assets/js/imagemodule.js';
import 'src/assets/js/jszip.js';
import 'src/assets/js/jszip-utils.js';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import ImageModule from 'open-docxtemplater-image-module';
import 'src/assets/js/dropboxsdk.min.js';
import { FileUploader } from 'ng2-file-upload';
import { Dropbox } from 'dropbox';
import { NgxSpinnerService } from 'ngx-spinner';
import { NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { DbxAuth } from '../configs';
import * as CryptoJS from 'crypto-js';


function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

function replaceErrors(key, value) {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(function (error,key) {
      error[key] = value[key];
      return error;
    },
      {});
  }
  return value;
}

function base64DataURLToArrayBuffer(dataURL) {
  const base64Regex = /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
  if (!base64Regex.test(dataURL)) {
    return false;
  }
  const stringBase64 = dataURL.replace(base64Regex, "");
  let binaryString;
  if (typeof window !== "undefined") {
    binaryString = window.atob(stringBase64);
  } else {
    binaryString = Buffer.from(stringBase64, "base64").toString("binary");
  }
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes.buffer;
}

@Component({
  selector: 'app-solicitarvistoria',
  templateUrl: './solicitarvistoria.component.html',
  styleUrls: ['./solicitarvistoria.component.css']
})
export class SolicitarvistoriaComponent implements OnInit {
  // DROPBOX UPLOAD
  wpp: string;
  meunome: string;

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  validaupload = 'false';
  validacaoemail = {};
  validacao3 = {};
  ultprogup: number;
  finalizou = false;
  enviado = false;
  naoenviado = false;
 // apagar depois
 productForm: FormGroup;
 // apagar depois
 tokenx:any;
 codig : any;
 adbx: any;
 acesstok:any;
 dbcAutho = [];
 autorizado = 'true';
 public dbxAuth: DbxAuth;
  dialogo: any;
  vistorias: todosdados[];
  @Input() vistoriaslecionada: string;
  todashomologacoes: todosdados[];
  id: string;
  filtredvisto: any;
  image;
  dadosolicitante: any;
  aviso = false;
  solicitou = false;

  @ViewChild('labelImport', { static: false })
  labelImport: ElementRef;

  formImport: FormGroup;
  fileToUpload: File = null;

  constructor(private service: HomologacoesserviceService,
              private dialogService: NbDialogService,
              private router: Router,
              private spinner: NgxSpinnerService) {
    this.formImport = new FormGroup({
      importFile: new FormControl('', Validators.required)
    });
    this.uploader = new FileUploader({
      url: '',
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.response = '';
    this.uploader.response.subscribe(res => this.response = res);
  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  asyncUpload(i) {
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line: max-line-length
      this.acesstok.filesUpload({ path: "/aplicativos/homofold/" + this.vistoriaslecionada + "/fotos_vistoria" + "/" + this.uploader.queue[i]['_file'].name, contents: this.uploader.queue[i]['_file'] })
        // tslint:disable-next-line: only-arrow-functions
        .then(response => {
          this.enviado = true;
          this.uploader.queue[i]['isUploaded'] = true;
          this.uploader.queue[i]['isSuccess'] = true;
          resolve();
        })
        .catch(error => reject())
        .finally(() => this.progresso());
    });
  }
  progresso() {
    this.ultprogup = 100 / (this.uploader.queue.length);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (this.uploader.queue[i]['isSuccess'] === true) {
        this.uploader.progress = this.ultprogup * (i + 1);
      }
    }
    if ( this.uploader.progress === 100) {
      this.validaupload = 'true';
      this.spinner.show();
      this.novavisto();

    }
  }
  resetatudo() {
    this.enviado = false;
    this.naoenviado = false;
    this.ultprogup = 0;
  }
  async uploadalgo(a) {
    this.dialogo = a;
    for (let i = 0; i < this.uploader.queue.length; i++) {
      await this.asyncUpload(i);
    }
  }
  ngOnInit() {
    this.autorizacao()
    this.service.currentwpp.subscribe(dados => {
      if (dados !== this.wpp && dados !== '') {
        this.wpp = dados;
      }
    });

    this.service.currentNome.subscribe(dados => {
      if (dados !== this.meunome && dados !== '') {
        this.meunome = dados;
      }
    });
    this.service.currentid.subscribe(dados => {
      if (dados !== this.id && dados !== '') {
        this.id = dados;
        this.puxahomologacoes();
      }
      this.service.gsolicitante({ ID: this.id })
        .subscribe(dats => {
          if (dats !== this.dadosolicitante) {
            this.dadosolicitante = dats;
          }
        });
    });
    console.log(this.vistoriaslecionada)
    this.filtravistoria()
  }

  puxahomologacoes() {
    this.service.myvistoria({ ID: this.id })
      .subscribe((data: todosdados[]) => {
        if (data !== this.vistorias) {
          this.vistorias = data;
        }
      });
  }

  filtravistoria(){
    if(this.vistoriaslecionada === undefined){ console.log('erro') }  else {
    this.service.filtravisto({ ID: this.vistoriaslecionada })
        .subscribe((data) => {
          if (data) {
            this.filtredvisto = data;
            this.filtredvisto[0].Cliente = this.filtredvisto[0].Cliente.toUpperCase()
            console.log('vistoriafiltrada', this.filtredvisto)
            this.solicitou = true;
            this.aviso = true;
          }
        });
      }
  }

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  rota(){
    this.router.navigateByUrl('/homologacoes');
  }
  novatarefa() {
    this.enviouvisto()
    this.notificatodos()
    //this.solvisto()
    const dateinici = new Date(Date.now()).toLocaleString('pt-BR');
    this.upavisto();
  }

  notificatodos() {
    console.log('notificando')
    this.service.swnotifica({ titulo: 'Vistoria Solicitada', msg: '⚠️  O parceiro '  + this.meunome  + ' Solicitou vistoria para homologação ' +  this.filtredvisto[0].codhomol + '-' + this.filtredvisto[0].Cliente }).subscribe((datax: any[]) => {
      if (datax) {
        console.log(datax)
      }
    });
  }

upavisto(){
  // tslint:disable-next-line: max-line-length
  let val: any;
  this.service.upastatusvisto({
    token: localStorage.getItem('tokenx'),
    statusvistoria: 'Solicitada pelo cliente',
    codhomol: this.vistoriaslecionada,
    msgav: "Vistoria" })
  .subscribe((data) => {
    if (data !== val) {
      val = data;
      this.geravistoria();
    }
  });
}
autorizacao() {
  this.tokenx =  localStorage.getItem('tokenx');
  this.service.getdbx({ token: this.tokenx })
  .subscribe((data) => {
    this.codig = data
    this.decryptData(this.codig.cod)}
    )
}
decryptData(data:any) {
try {
  const bytes = CryptoJS.AES.decrypt(data, this.tokenx);
  if (bytes.toString()) {
    var dadox = (JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
    this.adbx = dadox;
    this.autoh();
  }
  return data;
} catch (e) {
}
}


autoh(){
this.acesstok =  new Dropbox({ accessToken: this.adbx[0].accessToken });
    // Begin authentication process if credentials not found
  if (this.autorizado === 'true') {
        const authUrl = this.router.url;
        const parameters = authUrl.split('#')[1] || '';
        if (this.autorizado === 'true') {
            const arrParams = parameters.split('&') || [];
            if (this.autorizado === 'true') {
                const authObj: DbxAuth = { isAuth: false };
                // tslint:disable-next-line: prefer-for-of
                authObj.accessToken = this.adbx[0].accessToken;
                authObj.tokenType = this.adbx[0].tokenType;
                authObj.uid = this.adbx[0].uid;
                authObj.accountId = this.adbx[0].accountId;
                if (authObj.accessToken && authObj.tokenType && authObj.uid && authObj.accountId) {
                    authObj.isAuth = true;
                    this.dbxAuth = authObj;
                }
              }
        }
        this.service.changedauth(this.dbxAuth)
        // Store credentials into Auth-service and into localStorage
        // if (this.dbxAuth.isAuth) {
         //    this.authService.storeAuth(this.dbxAuth);
         //    this.podeupar = true;
      //   }
    // } else {
    }
}

  novavisto(){
    // tslint:disable-next-line: max-line-length
    this.service.solicitavisto({
      token: localStorage.getItem('tokenx'),
      cliente: this.filtredvisto[0].Cliente , 
      email: "jonnathan.lopes@solluxengenharia.com.br" ,
      homol: this.vistoriaslecionada })
        .subscribe((data) => {
          if (data) {
            this.novatarefa();
          }
        });
      }
  geravistoria() {
    const nomeclientes = this.filtredvisto[0].Cliente;
    const enderecos = this.filtredvisto[0].local;
    const codhomols = this.filtredvisto[0].codhomol;
    const datainicios = this.filtredvisto[0].datainicio;
    const cidades = this.filtredvisto[0].cidade;
    const estados = this.filtredvisto[0].estado;
    const datafims = new Date(Date.now()).toLocaleString('pt-BR');
    const cnpjs = this.filtredvisto[0].cpf;
    const potepicos = this.filtredvisto[0].potkwp;
    const potenominals = this.filtredvisto[0].potnom;
    // tslint:disable-next-line: max-line-length
    const enderecopars =  this.dadosolicitante.ruaparceiro + ' - ' + this.dadosolicitante.numruaparceiro + ' - ' + this.dadosolicitante.bairroparceiro + ' - ' + this.dadosolicitante.cidadeparceiro + ' - ' + this.dadosolicitante.ufparceiro;
    const cnpjpars = this.dadosolicitante.cnpjparceiro1;
    const emailpars = this.dadosolicitante.emailparceiro;
    const parceiros = this.dadosolicitante.nomeparceiro1;
    const arts = this.filtredvisto[0].art;
    const visto = this.vistoriaslecionada;
    const linkdoc = 'assets/doc/vistoria.docx'
    // tslint:disable-next-line: only-arrow-functions
    loadFile(linkdoc, function (error,content) {
      if (error) {
        throw error;
      }
      const imageOpts = {
        getImage(tag) {
          return base64DataURLToArrayBuffer(tag);
        },
        getSize() {
          return [480, 340];
        }
      };
      const imageModule = new ImageModule(imageOpts);
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, { modules: [imageModule] });
      doc.setData({
        nomecliente: nomeclientes,
        endereco: enderecos,
        codhomol: codhomols,
        datainicio: datainicios,
        datafim: datafims,
        cnpj: cnpjs,
        potepico: potepicos,
        potenominal: potenominals,
        enderecopar: enderecopars,
        cnpjpar: cnpjpars,
        emailpar: emailpars,
        art: arts,
        cidade: cidades,
        estado: estados,
        parceiro: parceiros
      });
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {


        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          console.log("errorMessages", errorMessages);
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "foobar" is unopened'
        }
        throw error;
      }
      const out = doc.getZip().generate({
        type: "blob",
        mimeType:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });

      saveAs(out,  visto + '_checklist.docx');
    });
    this.spinner.hide();
    this.finalizou = true;
  }

  enviouvisto() {
    var grupo = "553892515427-1633011809";
    var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
    //for (let i = 0; i < fones.length; i++) {
    this.service.sendtext({
      sessionName: 'sollux',
      number: grupo,
      text: '⚠️  O parceiro ' + '*' + this.meunome + '*' + ' Solicitou vistoria para homologação ' + '*' + this.filtredvisto[0].codhomol + '-' + this.filtredvisto[0].Cliente + '*' 
    })
      .subscribe(dados => {
        if (dados) {
        }
      });
  }

  solvisto() {
    var grupo = "553892515427-1633011809";
    var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
    //for (let i = 0; i < fones.length; i++) {
    this.service.enviaemail({
      sessionName: 'sollux',
      number: this.wpp,
      text: '⚠️ Recebemos sua solicitação de vistoria para homologação ' + '*' + this.filtredvisto[0].codhomol + ' - ' + this.filtredvisto[0].Cliente + '*' + ' .Iremos por favor preencha o checklist(se for o caso) gerado pelo Datasol e anexe para que sua solicitação seja concluída'
    })
      .subscribe(dados => {
        if (dados) {
        }
      });
  }
}
