import { MenuComponent } from './../menu/menu.component';
import { MYCARTEIRA } from './../homologacoes/mycarteira';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImageModule } from 'open-docxtemplater-image-module';
import createReport from 'docx-templates';
import { PAINV } from './../homologacoes/parametrosinv';
import { PAMOD } from './../homologacoes/parametrosmod';
import { FormArray, FormGroup, FormControl } from '@angular/forms'
import { UFS } from './estados';
import { consumo_mensal } from './consumo';
import { ActivatedRoute, Router } from '@angular/router';
import { HomologacoesserviceService } from './../homologacoes/homologacoesservice.service';
import { todosdados } from './../homologacoes/todosdados';
import { Component, OnInit, NgModule, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import html2canvas from 'html2canvas';
import * as $AB from 'jquery';
import 'node_modules/jquery/dist/jquery.min.js';
import 'src/assets/js/radiacao.js';
import { ChartOptions, ChartType, ChartDataSets, LinearTickOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import docxtemplater from 'docxtemplater';
import { Label, Color } from 'ng2-charts';
import { Location } from '@angular/common';
import 'src/assets/js/docxtemplater.js';
import 'src/assets/js/FileSaver.min.js';
import 'src/assets/js/pizzip-utils.js';
import 'src/assets/js/pizzip.js';
import 'src/assets/js/imagemodule.js';
import 'src/assets/js/jszip.js';
import 'src/assets/js/jszip-utils.js';
// tslint:disable-next-line: import-spacing
import { MOD } from '../homologacoes/modelomodu';
import { DomSanitizer } from '@angular/platform-browser';
import domtoimage from 'dom-to-image-more';
import { Finance } from 'financejs';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';

declare var $: any;
declare const calculaplacas2: any;
declare const calculaplacas: any;
declare const calcularconsumo: any;
declare const zeraconsumo: any;
declare const potenciainstalada: any;
declare const lerad: any;
declare const cidade: any;
declare const generate: any;
declare const onimage: any;
import 'src/assets/js/docxgenjs/js/docxgen.js';
import { from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { NbComponentStatus, NbDialogService, NbToastrService } from '@nebular/theme';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { NgxFixedDecimalPipe } from 'ngx-fixed-decimal';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Browser, setStyleAttribute } from '@syncfusion/ej2-base';
import { ResizeService } from '@syncfusion/ej2-angular-grids';
import { SpreadsheetComponent, getFormatFromType, BeforeSaveEventArgs } from '@syncfusion/ej2-angular-spreadsheet';
import { GridSettings } from '@syncfusion/ej2-pivotview/src/pivotview/model/gridsettings';
import { enableRipple } from '@syncfusion/ej2-base';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import 'src/assets/js/dropboxsdk.min.js';
import { ChartTheme, ILoadedEventArgs } from '@syncfusion/ej2-charts';
import { DbxAuth } from '../configs';
import * as CryptoJS from 'crypto-js';
import { Dropbox } from "dropbox";
import { AutoCompleteComponent } from '@syncfusion/ej2-angular-dropdowns';

enableRipple(false);
export interface Potencia {
  value: number;
  viewValue: string;
}
export interface MODULOMODELO {
  modelo: string;
  codice2: string;
}
export interface ARRAYINVERSORES {
  arrainv: INVERCONF[];
}
export interface NUMMPPT {
  numofmppt: string;
  href: string;
  cor_max: number;
  vmp_min: number;
  vmp_max: number;
  pot_max: number;
  qtd_mod_ser: number;
  qtd_mod_par: number;
  voc: number;
  vnom: number;
  Ic: number;
  potconfigurada: number;
  pottotalconfigurada: number;
  qtd_modulos: number;
}
export interface INVERSOREMCASCATA {
  coinv: NUMMPPT[];
}
export interface IDSCONF1 {
  value: number;
  viewValue: string;
}


export interface CARTEIRA {
  usoproposta: string;
  usoprojeto: string;
  creditoprojeto: string;
  creditoproposta: string;
}

export interface INVERCONF {
  coinv: NUMMPPT[];
  potencia: number;
  rendimento: number;
  rendimentoeu: number;
  numppt: number;
  pot_max: number;
}

interface Alert {
  type: string;
  message: string;
}

const ALERTS: Alert[] = [
  {
    type: 'info',
    message: 'Essa cidade não possui base  de dados para médias de temperatura',
  },
  {
    type: 'alert alert-danger',
    message: 'Alteração manual de radiação afetará o dimensionamento final do sistema',
  }
];

@Component({
  selector: 'app-geradorproposta',
  templateUrl: './geradorproposta.component.html',
  styleUrls: ['./geradorproposta.component.css'],
  providers: [
    NgxFixedDecimalPipe
  ]
})
export class GeradorpropostaComponent implements OnInit {
  public fieldsx: Object = { value: 'name' };
  estados: any[] = [];
  nwestados: any[] = [];
  nwinversores: any[] = [];
  filteredEstados: any[];
  filteredCidades: any[];
  estadoselecionado: string;
  cidadeselecionada: string;;
  cidades: any[] = [];
  nwcidades: any[] = [];
  filteredinversores: any[];
  xinersorslec: any[];
  xestadoselecionado: any;
  xcidadeselecionada: any;
  data: any;
  radiajson2: any;

  mostracheck1 = false;
  mostracheck2 = false;

  finance = new Finance();
  digitapotinv1 = "false";
  digitapotinv2 = "false";
  ID = [];
  fileUrl;
  docxs;
  ma = 130;
  mediames = false;
  grupoax = false;
  tabelager: any;
  selectedtabelager: any[];
  loading: boolean = false;
  mi = 80;
  engvirtu = false;
  engvirtu2 = false;
  itemsx: MenuItem[];
  desativadow = false;
  progress = 0;
  mostrabuton = false;
  semcredito = false;
  validacao4 = {};
  dialogoso: any;
  aceitou = false;
  imagefinanceiro: string;
  imageindicadores: string;
  idempresa: string;
  creditoproposta: string;
  usoproposta: string;
  base64textString: string;
  anoatual = 2020;
  numdiames = 0;
  alerts: Alert[];
  custopv = 0;
  custoinstal = 0;
  custoproj = 0;
  customo = 0;
  custototal = 0;
  tarenergia = 0;
  reajtar = 0.05;
  taxjuros = 0.1;
  depreciacao = 0.008;
  taxinf = 0;
  periodopay = 0;
  payback = 0;
  valortir = 0;
  valortir2: any[];
  retorno = 0;
  valorroi = 0;
  custoroi = 0;
  placaconfig = 0;
  gasto = 0;
  periodofinan = 0;
  parcelasfinan = 0;
  taxafinan = 0;
  valorfinan = 0;
  encargos = 0;
  custosadicionais = 0;
  novogera = false;
  count=1
  parcelacarencias = 0;
  tarenergiaforaponta = 0;
  finam = false;
  minhacarteira: CARTEIRA[];
  descontado = 0;
  mediacon: any;
  checks = false;
  menu1 = true;
  menu2 = false;
  menu3 = false;
  menu4 = false;
  menu5 = false;
  placasserie = [];
  placaspar = [];
  placasserie2 = []; // INVERSOR 2
  placaspar2 = []; // INVERSOR 2
  energia: any;
  modelmodulos: [];
  IDSCONF1: IDSCONF1[] = [];
  IDSCONF2: IDSCONF1[] = [];  // INVERSOR 2
  idatual1 = 0;
  idatual2 = 0; // INVERSOR 2
  mpptatual = 0;
  allinversores = true;
  allinversores2 = true;
  multiploinvesor2 = false;
  mod: any[];
  modi: MODULOMODELO[] = [];
  modi2: MODULOMODELO[] = []; // INVERSOR 2
  paramsinv: PAINV[] = [];
  paramsinv2: PAINV[] = []; // INVERSOR 2
  paramsmod: PAMOD[] = [];
  numeromppts: NUMMPPT[] = [];
  numeromppts2: NUMMPPT[] = [];
  inversorconf: INVERCONF[] = [];
  inversorconf2: INVERCONF[] = []; // INVERSOR 2
  inversorescascata1: Array<object> = [];
  inversorescascata2: Array<object> = []; // INVERSOR 2
  salvou = false;
  salvou2 = false;
  configura: Array<object> = [];
  configura2: Array<object> = []; // INVERSOR 2
  codmodulo: string;
  codinversor: string;
  codinversor2: string; // INVERSOR 2
  potinversorselec: any;
  potinversorselec2: any; // INVERSOR 2
  kwhdia: any;
  kwhdia1: any;
  kwhdia2: any;
  kwhdia3: any;
  kwhdia4: any;
  consumoanual2: any;
  consumoanual3: any;
  consumoanual4: any;
  propostasalva: any;
  propostasalvax = false;
  @ViewChild('element') tabObj: TabComponent;
  geralmeses1 = ""
  geralmeses2 = ""
  geralmeses3 = ""
  geralmeses4 = ""
  geralmeses5 = ""
  codeproposta = ""
  numinv1: number;
  numinv2 = 0; // INVERSOR 2
  numeroinversores1: number;
  numeroinversores2: any;
  tempcidade = -5;
  tempcidademax = 50;
  potnom: any;
  pot25p: any;
  potconf1 = 0;
  potconf2 = 0;
    // Mapping Tab items Header property
    public headerText= [{ text: 'Unidade Geradora' }];
  numucs = 1
  numplacas: any;
  nomecliente: string;
  nomeresponsavel: string;
  telefoneresp: string;
  emailresponsavel: string;
  siteempresa: string;
  potkwp: any;
  qtdmod1 = 0;
  qtdmod2 = 0;
  areaplacas: any;
  ecopriano = 0;
  radiacao: any;
  eficiencia: any = 80;
  aumento: any;
  aumentoconsumo: any = 0;
  consumoanual: any;
  consumoanual1: any;

  razao: any = 100;
  potnecessaria: any = 0;
  potnecessaria1: any= 0;
  potnecessaria0: any= 0;
  potenciasmodulo: any[];
  consumofixo: any;
  consumofixo1:any = 0;
  consumofixo2:any = 0;
  consumofixo3:any = 0;
  consumofixo4:any = 0;
  potnecessaria2:any = 0;
  potnecessaria3: any = 0;
  potnecessaria4: any= 0;

  grupoA1 = false
  cidadeescolhida: string;
  estadoescolhido: string;
  percentfinan = 0;
  marcamodulos: string;
  marcamodulosx: string;

  modelomodu: string;
  modeinv1: string;
  modeinv2: '';

  potenciainversor1 = '';
  marcinversor1: '';
  marcainversor1: '';
  marcainversor1x: string;
  gera =false;
  potenciainversor2 = '0';
  marcainver2 = '';
  tfp = 0;
  salvexinversor = 'false';
  salvexinversor2 = 'false';
  public enableClose: boolean = true;
  cpfcliente: string
  codcliente: string
  emailcliente: string
  telefonecliente: string
  responsavel: string
  enderecocliente: string
  cidadcliente: string
  estadocliente: string
  cepcliente: string
  datafinan = [];
  tpestrutura: string
  descontoavista: string
  formapaga: string
  prazoentrega: string
  consumototal = 0;
  consumototalpor12 = 0;
  ativagrupoa = false;
  avisodem = false;
  avissx = false;
  public tpestrutus: Object[] = [
    { Id: 'Telhado cerâmico', tipo: 'Telhado cerâmico' },
    { Id: 'Telhado fibrocimento', tipo: 'Telhado Fibrocimento' },
    { Id: 'Telhado metálico', tipo: 'Telhado metálico' },
    { Id: 'Laje plana', tipo: 'Laje plana' },
    { Id: 'Solo', tipo: 'Solo' }
  ];
  public formapags: Object[] = [
    { Id: 'Somente à vista', tipo: 'Somente à vista' },
    { Id: '2 vezes (Entrada + 30 dias)', tipo: '2 vezes (Entrada + 30 dias)' },
    { Id: '2 vezes (Entrada + Entrega do serviço)', tipo: '2 vezes (Entrada + Entrega do serviço)' },
    { Id: '2 vezes (30 | 60)', tipo: '2 vezes (30 | 60)' },
    { Id: '2x no boleto', tipo: '2x no boleto' },
    { Id: '3x no boleto', tipo: '3x no boleto' },
    { Id: '2x no cartão', tipo: '2x no cartão' },
    { Id: '3x no cartão', tipo: '3x no cartão' },
    { Id: '1 a 12x com juros', tipo: '1 a 12x com juros' }

  ];
  public fields: Object = { text: 'tipo', value: 'Id' };
  
  ctp = 0;
  ctfp = 0;

  ctp1 = 0;
  ctfp1 = 0;
  ctp2 = 0;
  ctfp2 = 0;
  ctp3 = 0;
  ctfp3 = 0;
  ctp4 = 0;
  ctfp4 = 0;
  cmp = 0;
  cmfp = 0;
  gefp = 0
  gep = 0;
  tp = 0;
  demanda = 0;
  custodemanda = 0;
  fatorajuste = 0;
  pjan: number;
  pfev: number;
  pmar: number;
  pabr: number;
  pmai: number;
  pjun: number;
  pjul: number;
  pago: number;
  pset: number;
  pout: number;
  pnov: number;
  pdez: number;
  fjan: number;
  ffev: number;
  fmar: number;
  fabr: number;
  fmai: number;
  fjun: number;
  fjul: number;
  fago: number;
  fset: number;
  fout: number;
  fnov: number;
  fdez: number;
  jan: number;
  fev: number;
  mar: number;
  abr: number;
  mai: number;
  jun: number;
  jul: number;
  ago: number;
  set: number;
  out: number;
  nov: number;
  dez: number;
  jan1: number;
  fev1: number;
  mar1: number;
  abr1: number;
  mai1: number;
  jun1: number;
  jul1: number;
  ago1: number;
  set1: number;
  out1: number;
  nov1: number;
  dez1: number;

  jan2: number;
  fev2: number;
  mar2: number;
  abr2: number;
  mai2: number;
  jun2: number;
  jul2: number;
  ago2: number;
  set2: number;
  out2: number;
  nov2: number;
  dez2: number;

  jan3: number;
  fev3: number;
  mar3: number;
  abr3: number;
  mai3: number;
  jun3: number;
  jul3: number;
  ago3: number;
  set3: number;
  out3: number;
  nov3: number;
  dez3: number;

  jan4: number;
  fev4: number;
  mar4: number;
  abr4: number;
  mai4: number;
  jun4: number;
  jul4: number;
  ago4: number;
  set4: number;
  out4: number;
  nov4: number;
  dez4: number;
  gerjan = 0;
  gerfev = 0;
  germar = 0;
  gerabr = 0;
  germai = 0;
  gerjun = 0;
  gerjul = 0;
  gerago = 0;
  gerset = 0;
  gerout = 0;
  gernov = 0;
  gerdez = 0;
  gerjan2 = 0;
  gerfev2 = 0;
  germar2 = 0;
  gerabr2 = 0;
  germai2 = 0;
  gerjun2 = 0;
  gerjul2 = 0;
  gerago2 = 0;
  gerset2 = 0;
  gerout2 = 0;
  gernov2 = 0;
  gerdez2 = 0;
  t1 = 0;
  t2 = 0;
  t3 = 0;
  t4 = 0;
  t5 = 0;
  t6 = 0;
  t7 = 0;
  t8 = 0;
  t9 = 0;
  t10 = 0;
  t11 = 0;
  t12 = 0;
  ajan = 0;
  afev = 0;
  amar = 0;
  aabr = 0;
  amai = 0;
  ajun = 0;
  ajul = 0;
  aago = 0;
  aset = 0;
  aout = 0;
  anov = 0;
  adez = 0;
  ajan2 = 0;
  afev2 = 0;
  amar2 = 0;
  aabr2 = 0;
  amai2 = 0;
  ajun2 = 0;
  ajul2 = 0;
  aago2 = 0;
  aset2 = 0;
  aout2 = 0;
  anov2 = 0;
  adez2 = 0;
  rad1: number;
  rad2: number;
  rad3: number;
  rad4: number;
  rad5: number;
  rad6: number;
  rad7: number;
  rad8: number;
  rad9: number;
  rad10: number;
  rad11: number;
  rad12: number;
  materialca = 0;
  desconto = 0;
  custokwp = 0;
  ganho = 0;
  demandacontratada = 0
  valdemandacontratada = 0;
  modeloinversor1: string;
  modeloplacas: string;
  marcamodul: string;
  selectedValue4: any;
  modelinv2 = null;
  potinversores: number;
  potenciafvselec: number;
  semtemperatura = false;
  conta = 0;
  canvs: any;
  totalpotcasc1 = 0
  imagev: any;
  compen = 0;
  economia = 0;
  analise = '';
  totalplacas = 0;
  totalinversores = 0;
  inversoresx: any[];
  iv1: any
  tipos: Potencia[] = [
    { value: 225, viewValue: '225W' },
    { value: 230, viewValue: '230W' },
    { value: 235, viewValue: '235W' },
    { value: 240, viewValue: '240W' },
    { value: 245, viewValue: '245W' },
    { value: 250, viewValue: '250W' },
    { value: 255, viewValue: '255W' },
    { value: 260, viewValue: '260W' },
    { value: 265, viewValue: '265W' },
    { value: 270, viewValue: '270W' },
    { value: 275, viewValue: '275W' },
    { value: 280, viewValue: '285W' },
    { value: 295, viewValue: '295W' },
    { value: 300, viewValue: '300W' },
    { value: 305, viewValue: '305W' },
    { value: 310, viewValue: '310W' },
    { value: 315, viewValue: '315W' },
    { value: 320, viewValue: '320W' },
    { value: 325, viewValue: '325W' },
    { value: 330, viewValue: '330W' },
    { value: 335, viewValue: '335W' },
    { value: 340, viewValue: '340W' },
    { value: 345, viewValue: '345W' },
    { value: 350, viewValue: '350W' },
    { value: 355, viewValue: '355W' },
    { value: 360, viewValue: '360W' },
    { value: 365, viewValue: '365W' },
    { value: 370, viewValue: '370W' },
    { value: 375, viewValue: '375W' },
    { value: 380, viewValue: '380W' },
    { value: 385, viewValue: '385W' },
    { value: 390, viewValue: '390W' },
    { value: 395, viewValue: '395W' },
    { value: 400, viewValue: '400W' },
  ];

  ANOSx = []
  EprodAnox = []
  EaccumAnox = []
  Receitax = []
  saidasAnox = []
  FluxoCaixax = []
  rendimentosacux = []
  disponibilex = []
  VPLx = []
  saldodevedor = []
  jurosmes = []
  valoramortizado = []
  jurosano = []


  parcelafinanciamento = 0;
  inputmmpt = 0;
  radiacaoescolhida: any;
  potmodulo: number;
  marcas: any[];
  numparcelas = 0;
  modelosinver: any[];
  marcasinver: any[];
  marcasinver2: any[]; // INVERSOR2
  modelos: any[];
  custodisponibilidade = 0;
  disponibile: number[] = [];
  dias: any[] = [];
  public rowMode: string;
  public grid: GridComponent;
  @ViewChild('adaptive')
  EprodAno: number[] = [];
  EaccumAno: number[] = [];
  EconsumAno: number[] = [];
  Receita: number[] = [];
  EconoSimuAno: number[] = [];
  EconoCompAno: number[] = [];
  JurosFinan: number[] = [];
  JurosAtivosAno: number[] = [];
  FluxoCaixadesc: number[];
  saidasAno: number[] = [];
  CustoDisp: number[] = [];
  CustosAnu: number[] = [];
  DespExtra: number[] = [];
  rendimentosacu: number[] = [];
  ParcelaFinan: number[] = [];
  taxajurosfinan: number;
  capital: number[] = [];
  FluxoCaixa: number[] = [];
  FluxoCaixaacc: number[] = [];
  CapProprio: number[] = [];
  MonteEco: number[] = [];
  Taxarend: number[] = [];
  VPL: number[] = [];
  TIR: number[] = [];
  solicitante: string;
  potacc = 0;
  ANOS: any[] = [];
  edescontado: 0;
  valorkwp = 0;
  custocordisp = 0;
  potinversor: any[];
  potinversor2: any[]; // INVERSOR 2
  potenominal = 0;
  potepico: 0;
  tipousina = 'Microgeração';
  produacaopiano: string;
  potdimensionada: number;
  miniv: number;
  mainv: number;
  graph1 = true;
  radiajson: any;
  alterarads = false;
  capturedImage: any;
  temperaturas: any;
  todashomologacoes: todosdados[];
  homologacaoselecionada: string;
  spinnerdados = '';
  nomeempresa = '';
  cpfempresa = '';
  telefonexempresa = '';
  enderecempresa = '';
  emailxempresa = '';
  dadosolicitante = [];
  public isDeskTop: Boolean;
  public saveUrl = '';
  myControl = new FormControl();
  //Initializing Primary X Axis
  public primaryXAxis: Object = {
    valueType: 'Category', majorGridLines: { width: 0 }, title: 'Ano'
  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    labelFormat: 'R$ {value}',
    title: 'Valor em Reais'
  };
  public title: string = 'RETORNO DO INVESTIMENTO';

  public tooltip: Object = {
    enable: true
  };
  adbx: any;
  tokenx: any;
  acesstok: any;
  dbcAutho = [];
  autorizado = 'true';
  codig: any;
  equipex: any;
  public empregados: Object[] = [
    { Id: '', tipo: ''}
  ];
  public dbxAuth: DbxAuth;
  public colors1: string[] = ['#FFFF99'];
  public colors2: string[] = ['#FFA500'];
  public colors3: string[] = ['#FF4040'];

  public legendSettings: Object = {
    mode: 'Range'
  };

  public animation: Object = {
    enable: false
  };

  public cornerRadius: Object = {
    topLeft: 10, topRight: 10
  }
  // custom code start
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
  };
  // custom code end
  public chartArea: Object = {
    border: {
      width: 0
    }
  };
  public width: string = Browser.isDevice ? '100%' : '100%';
  options: string[] = ['One', 'Two', 'Three'];
  URL = 'https://483dc174-72c9-4290-bade-2bdc6b39e45a.usrfiles.com/ugd/483dc1_f6723775c410436a860d6d6d2cf9b557.docx'
  // tslint:disable-next-line: max-line-length
  // tslint:disable-next-line: align
  public barChartOptions: ChartOptions = {

    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { yAxes: [{ ticks: { min: 0, stepSize: 200 } }] }
    ,
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
      },
      legend: { display: true }
    }
  };
  public barChartColors: Color[] = [
    { backgroundColor: 'yellow' },
    { backgroundColor: '#0FB3FF' },
    
  ]
  public barChartLabels: Label[] = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  public barChartLabels2: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25'];


  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels,
    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 50; // must use `function` and not => because of `this`
        };
      }
    }
  ];
  barChartData: ChartDataSets[];
  barChartData2: ChartDataSets[];
  public data1: Object[] = [
    { x: '1', y: 0 },
    { x: '2', y: 0 },
    { x: '3', y: 0 },
    { x: '4', y: 0 },
    { x: '5', y: 0 },
    { x: '6', y: 0 },
    { x: '7', y: 0 },
    { x: '8', y: 0 },
    { x: '9', y: 0 },
    { x: '10', y: 0 },
    { x: '11', y: 0 },
    { x: '12', y: 0 },
    { x: '13', y: 0 },
    { x: '14', y: 0 },
    { x: '15', y: 0 },
    { x: '16', y: 0 },
    { x: '17', y: 0 },
    { x: '18', y: 0 },
    { x: '19', y: 0 },
    { x: '20', y: 0 },
    { x: '21', y: 0 },
    { x: '22', y: 0 },
    { x: '23', y: 0 },
    { x: '24', y: 0 },
    { x: '25', y: 0 }
  ];

  avisaplaca = false;
  public marker: Object = {
    visible: true, height: 5, width: 5, shape: 'Diamond',
    dataLabel: { visible: true, position: 'top' }
  };
  public dragSettings: Object = {
    enable: false
  };



  potger = false;
  constructor(private service: HomologacoesserviceService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toastrService: NbToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private dialogService: NbDialogService,
    private fixedDecimal: NgxFixedDecimalPipe,
    private location:Location
  ) {
    this.reset();
  }


  //////////////////////////////////////////////////////////
  //  TEMP ALERT
  //////////////////////////////////////////////////////////
  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  reset() {
    this.alerts = Array.from(ALERTS);
  }

  puxasolicitante() {
    this.service.gsolicitante({ ID: this.idempresa })
      .subscribe(dados => {
        if (dados) {
          this.dadosolicitante = dados;
          this.atualizanomes();
        }
      })
  }

  atualizanomes() {
    this.nomeempresa = this.dadosolicitante['nomeparceiro1'];
    this.cpfempresa = this.dadosolicitante['cnpjparceiro1'];
    this.emailxempresa = this.dadosolicitante['emailparceiro'];
    this.telefonexempresa = this.dadosolicitante['celparceiro'];
    this.enderecempresa = this.dadosolicitante['ruaparceiro'] + ', ' + this.dadosolicitante['numruaparceiro'] + '  ' + this.dadosolicitante['complemento'] + ', ' + this.dadosolicitante['bairroparceiro'] + ', ' + this.dadosolicitante['cepparceiro'] + ', ' + this.dadosolicitante['cidadeparceiro'] + ', ' + this.dadosolicitante['ufparceiro']
  }
  testex() {
    console.log(this.cepcliente)
  }
  novocliente() {
    console.log(this.cepcliente)
    var novomodulo = this.marcamodulos + ':' + this.modelomodu + ':' + this.potenciafvselec + ':' + this.numplacas + ';'
    var novoinver = this.marcainversor1 + ':' + this.modeinv1 + ':' + this.potenciainversor1 + ':' + this.numinv1 + ';'
    console.log(novoinver)
    var data = new Date(),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    this.service
      .novocliente2({
        token: localStorage.getItem("tokenx"),
        id: this.codcliente,
        idempresa: this.idempresa,
        nomecliente: this.nomecliente,
        cidade: this.cidadeescolhida,
        estado: this.estadoselecionado,
        mail: this.emailcliente,
        telefone: this.telefonecliente,
        endereco: this.enderecocliente,
        responsavel: this.responsavel,
        data_inicio: diaF + "/" + mesF + "/" + anoF,
        data_fim: diaF + "/" + mesF + "/" + anoF,
        cpf: this.cpfcliente,
        status_proposta: "Elaborando proposta comercial",
        status_homologacao: "Aguardando aprovação de proposta",
        status_compra: "Aguardando aprovação de proposta",
        status_execucao: "Aguardando aprovação de proposta",
        status_vistoria: "Aguardando aprovação de proposta",
        kwp: this.potkwp,
        valor_total: this.custototal,
        statuscliente: 'Coleta de dados',
        obstatus: 'Aguardando informações do cliente',
        formapag: 'Digite o valor, Digite o prazo;',
        cep: this.cepcliente,
        custopv: this.custopv,
        custoca: this.materialca,
        custoproj: this.custoproj,
        customo: this.customo,
        malucro: this.custosadicionais,
        desconto: this.desconto,
        ganho: this.ganho,
        dadosinversor: novoinver,
        dadosmodulo: novomodulo,
        consumo: this.consumoanual / 12,
        reducao: 1 - (this.razao) / 100,
        aumento: this.aumentoconsumo
      })
      .subscribe((dados) => {
        if (dados) {

          console.log(dados);
          if (dados["status"] === "cadastrado") {
            console.log("inserido");
            this.criapasta()
          }
          if (dados["status"] === "erro") {
            console.log(" não inserido");
          }
        }
      });
  }

  potmanual2(a) {
    this.digitapotinv2 = a
  }

  updateprops() {
    this.service.upacarteiraprop({
      token: localStorage.getItem('tokenx'),
      ID: this.idempresa
    })
      .subscribe(dados => {
        if (dados !== this.validacao4) {
          this.validacao4 = dados;
          console.log(dados)
          if (dados['status'] === 'atualizado') {
            this.aceitou = true;
            this.desativadow = false;
          }
          if (dados['status'] === 'erro') {
            console.log(' não inserido')
          }
        }
      });
    this.validacao4 = {};
  }

  beforeSave(args: BeforeSaveEventArgs) {
    console.log(args)
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  grupoa() {
    this.barChartData = []
    this.barChartData = [
      // tslint:disable-next-line: max-line-length
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],  stack: 'a', label: 'Consumo total corrigido' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Geração Estimada' }
    ];
    this.barChartData2 = [
      // tslint:disable-next-line: max-line-length
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Retorno financeiro' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Renda Capital investido' }
    ];
    this.fatorajuste = this.tfp / this.tp
    this.ctp = (this.pjan) / this.fatorajuste + (this.pfev) / this.fatorajuste + (this.pmar) / this.fatorajuste + (this.pabr) / this.fatorajuste + (this.pmai) / this.fatorajuste + (this.pjun) / this.fatorajuste + (this.pjul) / this.fatorajuste + (this.pago) / this.fatorajuste + (this.pset) / this.fatorajuste + (this.pout) / this.fatorajuste + (this.pnov) / this.fatorajuste + (this.pdez) / this.fatorajuste;
    this.ctfp = (this.fjan) + (this.ffev) + (this.fmar) + (this.fabr) + (this.fmai) + (this.fjun) + (this.fjul) + (this.fago) + (this.fset) + (this.fout) + (this.fnov) + (this.fdez);
    this.consumoanual = (this.ctp + this.ctfp).toFixed(2)
    this.consumofixo = (this.consumoanual / 12).toFixed(2)
    console.log('this.consumoanual', this.consumoanual)
    this.consumototal = (this.ctp + this.ctfp)
    this.consumototalpor12 = this.consumototal / 12
    this.cmp = ((this.ctp) / 12)
    this.cmfp = (this.ctfp) / 12;
    this.kwhdia = ((this.ctp + this.ctfp) / (12 * 30)).toFixed(2);
    this.custodisponibilidade = this.demanda * this.custodemanda
    this.tarenergia = this.tp
    this.tarenergiaforaponta = this.tfp;
    if (this.ctfp !== 0 && this.ctp !== 0) {
      console.log('this.pjun')
      console.log(this.pjun)
      console.log(this.fjun)
      console.log(this.pjun / this.fatorajuste)

      this.barChartData[0].data = [
        parseFloat(
          this.fixedDecimal.transform(
            this.pjan / this.fatorajuste + this.fjan,
            0,
            "ceil"
          )
        ),
        parseFloat(
          this.fixedDecimal.transform(
            this.pfev / this.fatorajuste + this.ffev,
            0,
            "ceil"
          )
        ),
        parseFloat(
          this.fixedDecimal.transform(
            this.pmar / this.fatorajuste + this.fmar,
            0,
            "ceil"
          )
        ),
        parseFloat(
          this.fixedDecimal.transform(
            this.pabr / this.fatorajuste + this.fabr,
            0,
            "ceil"
          )
        ),
        parseFloat(
          this.fixedDecimal.transform(
            this.pmai / this.fatorajuste + this.fmai,
            0,
            "ceil"
          )
        ),
        parseFloat(
          this.fixedDecimal.transform(
            this.pjun / this.fatorajuste + this.fjun,
            0,
            "ceil"
          )
        ),
        parseFloat(
          this.fixedDecimal.transform(
            this.pjul / this.fatorajuste + this.fjul,
            0,
            "ceil"
          )
        ),
        parseFloat(
          this.fixedDecimal.transform(
            this.pago / this.fatorajuste + this.fago,
            0,
            "ceil"
          )
        ),
        parseFloat(
          this.fixedDecimal.transform(
            this.pset / this.fatorajuste + this.fset,
            0,
            "ceil"
          )
        ),
        parseFloat(
          this.fixedDecimal.transform(
            this.pout / this.fatorajuste + this.fout,
            0,
            "ceil"
          )
        ),
        parseFloat(
          this.fixedDecimal.transform(
            this.pnov / this.fatorajuste + this.fnov,
            0,
            "ceil"
          )
        ),
        parseFloat(
          this.fixedDecimal.transform(
            this.pdez / this.fatorajuste + this.fdez,
            0,
            "ceil"
          )
        ),
      ];
    }

  }
  //////////////////////////////////////////////////////////
  //  DATA TEMP AND RAD
  //////////////////////////////////////////////////////////

  potmanual1(a) {
    this.digitapotinv1 = a
  }
  public changeHandler(e: any): void {
    if (e.checked) {
      this.grid.rowRenderingMode = 'Horizontal';
    } else {
      this.grid.rowRenderingMode = 'Vertical';
    }
  }


  @ViewChild('cellDataBind')
  public spreadsheetObj: SpreadsheetComponent;
  public styles = { fontWeight: 'bold', textAlign: 'right' };
  public styles2 = { fontWeight: 'bold', textAlign: 'left' };
  public styles3 = { fontWeight: 'bold' };
  public styles4 = { textAlign: 'right' };
  currencyFormat: string = getFormatFromType('Currency');

  testeatualiza(){
    var adata = {
      //Dados da UG
      xestadoselecionado: { name: "MINAS GERAIS", code: "MINAS GERAIS" } , 
      xcidadeselecionada:{ name: "MONTES CLAROS", code: "MONTES CLAROS" },
      radiacaoescolhida: '0',
      consumofixo: 500,
      consumoanual: 0,
      consumog : "",
      consumo1 : "",
      consumo2 : "",
      consumo3 : "",
      consumo4 : "",
      potenciafvselec:400,
      marcamodul:"CSI CANADIAN SOLAR",
      modelomodu:"BiHiKu CS3W-400PB-AG",
      potnecessaria0: 0,
      potnecessaria: 0,
      eficiencia: this.eficiencia,
      razao: this.razao,
      aumentoconsumo: this.aumentoconsumo,
      potenciainversor1: 4,
      numinv1:1,
      marcinversor1:"(SOLIS) GINLONG",
      modeinv1:"Solis-1P4K-4G (60Hz)",
      //Dados da UC1
      //PARAMETROS FINANCEIRO
      finam: false,
      custopv:5000,
      tarenergia:0.9,
      periodofinan:5,
      materialca:1000,
      tarenergiaforaponta:0,
      numparcelas:10,
      custoproj:1000,
      taxjuros:0.1,
      taxafinan:0.5,
      customo:1000,
      reajtar:0.05,
      percentfinan:0.5,
      custosadicionais:1000,
      custodisponibilidade:50,
      parcelafinanciamento:200,
      desconto:100,
      depreciacao:0.008,
      parcelacarencias:0,
      nomecliente:"Jonnathan Vinicius Lopes Silva",
      cpfcliente:"09009648630",
      codcliente: "PR1659139287",
      emailcliente: "jonnathan@solluxengenharia.com.br",
      telefonecliente:"38992515427",
      responsavel:"Jonnathan Lopes",
      enderecocliente: "Rua Domiciano Pimenta 115, Vila Luiza",
      cidadeescolhida: this.cidadeselecionada,
      estadoselecionado: this.estadoselecionado,
      cepcliente:"39400399",
      formapaga:"Somente à vista",
      tpestrutura:"Solo",
      descontoavista: 0.05,
      prazoentrega:30
     }
     console.log(this.propostasalva)
     if(this.propostasalva.finam === 0){
      this.finam = false
     }
     if(this.propostasalva.finam === 1){
      this.finam = true
     }
    //this.propostasalva = adata
    this.consumofixo = parseFloat(this.propostasalva.consumofixo)

    this.custopv=this.propostasalva.custopv
    this.tarenergia=this.propostasalva.tarenergia
    this.periodofinan=this.propostasalva.periodofinan
    this.materialca=this.propostasalva.materialca
    this.tarenergiaforaponta=this.propostasalva.tarenergiaforaponta
    this.numparcelas=this.propostasalva.numparcelas
    this.custoproj=this.propostasalva.custoproj
    this.taxjuros=this.propostasalva.taxjuros
    this.taxafinan=this.propostasalva.taxafinan
    this.customo=this.propostasalva.customo
    this.reajtar=this.propostasalva.reajtar
    this.percentfinan=this.propostasalva.percentfinan
    this.custosadicionais=this.propostasalva.custosadicionais
    this.custodisponibilidade=this.propostasalva.custodisponibilidade
    this.parcelafinanciamento=this.propostasalva.parcelafinanciamento
    this.desconto=this.propostasalva.desconto
    this.depreciacao=this.propostasalva.depreciacao
    this.parcelacarencias=this.propostasalva.parcelacarencias
    this.estadoselecionado = this.propostasalva.xestadoselecionado 
    this.cidadeescolhida =  this.propostasalva.xcidadeselecionada 
    this.xestadoselecionado = { name: this.propostasalva.xestadoselecionado, code: this.propostasalva.xestadoselecionado } , 
    this.xcidadeselecionada = { name: this.propostasalva.xcidadeselecionada, code: this.propostasalva.xcidadeselecionada }, 
    this.nomecliente=this.propostasalva.nomecliente
    this.cpfcliente=this.propostasalva.cpfcliente
    this.codcliente=this.propostasalva.codcliente
    this.emailcliente=this.propostasalva.emailcliente
    this.telefonecliente=this.propostasalva.telefonecliente
    this.responsavel=this.propostasalva.responsavel
    this.enderecocliente=this.propostasalva.enderecocliente
    this.cidadeescolhida=this.propostasalva.xcidadeselecionada
    this.estadoselecionado=this.propostasalva.xestadoselecionado
    this.cepcliente=this.propostasalva.cepcliente
    this.formapaga=this.propostasalva.formapaga
    this.tpestrutura=this.propostasalva.tpestrutura
    this.descontoavista=this.propostasalva.descontoavista
    this.prazoentrega=this.propostasalva.prazoentrega
    this.numucs = this.propostasalva.ucs
    this.consumofixo1 = this.propostasalva.consumo1
    this.consumofixo2 = this.propostasalva.consumo2
    this.consumofixo3 = this.propostasalva.consumo3
    this.consumofixo4 = this.propostasalva.consumo4
    this.criaucs()
    this.radiacaocaller({ name: this.propostasalva.xcidadeselecionada, code: this.propostasalva.xcidadeselecionada })
    
  }
  showToast2(status: NbComponentStatus) {
    this.toastrService.show(status, `Sucesso: Atualizado com sucesso!`, {
      status,
    });
  }
  atualizanovocliente() {
    console.log(this.cepcliente)
    var novomodulo = this.marcamodulos + ':' + this.modelomodu + ':' + this.potenciafvselec + ':' + this.numplacas + ';'
    var novoinver = this.marcainversor1 + ':' + this.modeinv1 + ':' + this.potenciainversor1 + ':' + this.numinv1 + ';'
    console.log(novoinver)
    var data = new Date(),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    this.service
      .atualizanovocliente({
        token: localStorage.getItem("tokenx"),
        id: this.codcliente,
        idempresa: this.idempresa,
        nomecliente: this.nomecliente,
        cidade: this.cidadeescolhida,
        estado: this.estadoselecionado,
        mail: this.emailcliente,
        telefone: this.telefonecliente,
        endereco: this.enderecocliente,
        responsavel: this.responsavel,
        data_inicio: diaF + "/" + mesF + "/" + anoF,
        data_fim: diaF + "/" + mesF + "/" + anoF,
        cpf: this.cpfcliente,
        status_proposta: "Elaborando proposta comercial",
        status_homologacao: "Aguardando aprovação de proposta",
        status_compra: "Aguardando aprovação de proposta",
        status_execucao: "Aguardando aprovação de proposta",
        status_vistoria: "Aguardando aprovação de proposta",
        kwp: this.potkwp,
        valor_total: this.custototal,
        statuscliente: 'Coleta de dados',
        obstatus: 'Aguardando informações do cliente',
        formapag: 'Digite o valor, Digite o prazo;',
        cep: this.cepcliente,
        custopv: this.custopv,
        custoca: this.materialca,
        custoproj: this.custoproj,
        customo: this.customo,
        malucro: this.custosadicionais,
        desconto: this.desconto,
        ganho: this.ganho,
        dadosinversor: novoinver,
        dadosmodulo: novomodulo,
        consumo: this.consumoanual / 12,
        reducao: 1 - (this.razao) / 100,
        aumento: this.aumentoconsumo
      })
      .subscribe((dados) => {
        if (dados) {

          console.log(dados);
          if (dados["status"] === "cadastrado") {
            console.log("inserido");
            this.showToast2("success");
            this.criapasta()
          }
          if (dados["status"] === "erro") {
            console.log(" não inserido");
          }
        }
      });
  }
 salvapropostax(){

  if(this.propostasalvax ){
    this.service.atualizaproposta({
      token: localStorage.getItem('tokenx'),
      xestadoselecionado: this.estadoselecionado, 
      xcidadeselecionada:this.cidadeescolhida,
      radiacaoescolhida: this.radiacaoescolhida,
      consumofixo: this.consumofixo,
      consumoanual: this.consumoanual,
      consumog : "",
      ucs: this.numucs,
      consumo1 : this.consumofixo1,
      consumo2 : this.consumofixo2,
      consumo3 : this.consumofixo3,
      consumo4 : this.consumofixo4, 
      geralmeses1 : this.geralmeses1,
      geralmeses2 : this.geralmeses2,
      geralmeses3 : this.geralmeses3,
      geralmeses4 : this.geralmeses4,
      geralmeses5 : this.geralmeses5,
      potenciafvselec: this.potenciafvselec,
      marcamodul:this.marcamodulos,
      modelomodu:this.modelomodu,
      potnecessaria0: this.potnecessaria0,
      potnecessaria: this.potnecessaria,
      eficiencia: this.eficiencia,
      razao: this.razao,
      aumentoconsumo: this.aumentoconsumo,
      potenciainversor1:this.potenciainversor1,
      numinv1:this.numinv1,
      marcinversor1:this.marcainversor1,
      modeinv1:this.modeinv1,
      //Dados da UC1
      //PARAMETROS FINANCEIRO
      finam:this.finam,
      custopv:this.custopv,
      tarenergia:this.tarenergia,
      periodofinan:this.periodofinan,
      materialca:this.materialca,
      tarenergiaforaponta:this.tarenergiaforaponta,
      numparcelas:this.numparcelas,
      custoproj:this.custoproj,
      taxjuros:this.taxjuros,
      taxafinan:this.taxafinan,
      customo:this.customo,
      reajtar:this.reajtar,
      percentfinan:this.percentfinan,
      custosadicionais:this.custosadicionais,
      custodisponibilidade:this.custodisponibilidade,
      parcelafinanciamento:this.parcelafinanciamento,
      desconto:this.desconto,
      depreciacao:this.depreciacao,
      parcelacarencias:this.parcelacarencias,
      nomecliente:this.nomecliente,
      cpfcliente:this.cpfcliente,
      codcliente: this.codcliente,
      emailcliente: this.emailcliente,
      telefonecliente:this.telefonecliente,
      responsavel:this.responsavel,
      enderecocliente: this.enderecocliente,
      cidadeescolhida: this.cidadeescolhida,
      estadoselecionado: this.estadoselecionado,
      cepcliente:this.cepcliente,
      formapaga:this.formapaga,
      tpestrutura:this.tpestrutura,
      descontoavista: this.descontoavista,
      prazoentrega:this.prazoentrega
    })
      .subscribe(dados => {
        if (dados["status"] === "inserido") {
          this.atualizanovocliente()
          console.log(dados)
          //this.novatarefa();
        }
        if (dados["status"] !== "erro") {
          console.log(dados)
        }
      });
    }
    if(!this.propostasalvax ){
  this.service.salvaproposta({
    token: localStorage.getItem('tokenx'),
    xestadoselecionado: this.estadoselecionado, 
    xcidadeselecionada:this.cidadeescolhida,
    radiacaoescolhida: this.radiacaoescolhida,
    consumofixo: this.consumofixo,
    consumoanual: this.consumoanual,
    consumog : "",
    ucs: this.numucs,
    consumo1 : this.consumofixo1,
    consumo2 : this.consumofixo2,
    consumo3 : this.consumofixo3,
    consumo4 : this.consumofixo4, 
    geralmeses1 : this.geralmeses1,
    geralmeses2 : this.geralmeses2,
    geralmeses3 : this.geralmeses3,
    geralmeses4 : this.geralmeses4,
    geralmeses5 : this.geralmeses5,
    potenciafvselec: this.potenciafvselec,
    marcamodul:this.marcamodulos,
    modelomodu:this.modelomodu,
    potnecessaria0: this.potnecessaria0,
    potnecessaria: this.potnecessaria,
    eficiencia: this.eficiencia,
    razao: this.razao,
    aumentoconsumo: this.aumentoconsumo,
    potenciainversor1:this.potenciainversor1,
    numinv1:this.numinv1,
    marcinversor1:this.marcainversor1,
    modeinv1:this.modeinv1,
    //Dados da UC1
    //PARAMETROS FINANCEIRO
    finam:this.finam,
    custopv:this.custopv,
    tarenergia:this.tarenergia,
    periodofinan:this.periodofinan,
    materialca:this.materialca,
    tarenergiaforaponta:this.tarenergiaforaponta,
    numparcelas:this.numparcelas,
    custoproj:this.custoproj,
    taxjuros:this.taxjuros,
    taxafinan:this.taxafinan,
    customo:this.customo,
    reajtar:this.reajtar,
    percentfinan:this.percentfinan,
    custosadicionais:this.custosadicionais,
    custodisponibilidade:this.custodisponibilidade,
    parcelafinanciamento:this.parcelafinanciamento,
    desconto:this.desconto,
    depreciacao:this.depreciacao,
    parcelacarencias:this.parcelacarencias,
    nomecliente:this.nomecliente,
    cpfcliente:this.cpfcliente,
    codcliente: this.codcliente,
    emailcliente: this.emailcliente,
    telefonecliente:this.telefonecliente,
    responsavel:this.responsavel,
    enderecocliente: this.enderecocliente,
    cidadeescolhida: this.cidadeescolhida,
    estadoselecionado: this.estadoselecionado,
    cepcliente:this.cepcliente,
    formapaga:this.formapaga,
    tpestrutura:this.tpestrutura,
    descontoavista: this.descontoavista,
    prazoentrega:this.prazoentrega
  })
    .subscribe(dados => {
      if (dados["status"] === "inserido") {
        this.novocliente()
        console.log(dados)
        //this.novatarefa();
      }
      if (dados["status"] !== "erro") {
        console.log(dados)
      }
    });
  }
 }

created(e){  
  this.tabObj.items.push({ header: { text:  'Unidade Geradora' }, cssClass:"withoutIcon", content: "#idv" }); 
  //use the same way for dynamically created tab also  
  (<any>this.tabObj).refresh(); 
  } 

  ngOnInit() {
    this.route.queryParams.subscribe(dados => {
      console.log(dados)
      this.propostasalvax = dados.ativa
      this.codeproposta = dados.data
      if(dados.ativa === 'true'){
        this.propostasalvax  = true
        this.service.filtraproposta({ ID: this.codeproposta }).subscribe((data: any[]) => {
          if (data) {
            console.log(data[0])
            this.propostasalva = data[0]
            this.novinit()
          }
          });
        } else {
          this.novinit()
        }
    })
    
  }

  novinit(){
    this.radiajasonx()
    this.tokenx = localStorage.getItem("tokenx")
    this.autorizacao()
    this.rowMode = 'Vertical';
    this.isDeskTop = !Browser.isDevice;
    this.codcliente = 'PR' + (Date.now() / 1000).toFixed(0).toString()
    this.itemsx = [
      {
        label: 'Dados iniciais',
        icon: 'pi  pi-pencil',
        command: (event) => {
          this.menumuda(1)
        }
      },
      {
        label: 'Dimensionar kit',
        icon: 'pi pi-check-square',
        command: (event) => {
          this.menumuda(2)
        }
      },
      {
        label: 'Análise financeira',
        icon: 'pi  pi-dollar',
        command: (event) => {
          if (this.potger === false) {
            this.potgeracao()
          }
          if (this.potconf1 !== 0 || this.potconf2 !== 0) {
            console.log('avisaplaca')
            if ((this.potconf1 + this.potconf2) < parseFloat(this.potnecessaria)) {
              this.avisaplaca = true;
            }

            if (this.ctfp !== 0 && this.ctp !== 0) {
              if (this.numinv1 * parseFloat(this.potenciainversor1) + this.numinv2 * parseFloat(this.potenciainversor2) > this.demanda) {
                this.avisodem = true;
              }
            }
            if (this.avisodem === true || this.avisaplaca === true) {
              this.avissx = true
            }
            this.menumuda(4)

          }

          if (this.potconf1 === 0 && this.potconf2 === 0) {
            if (this.potkwp < parseFloat(this.potnecessaria)) {
              this.avisaplaca = true;
            }
            if (this.ctfp !== 0 && this.ctp !== 0) {
              console.log(this.potenciainversor1)
              console.log(this.numinv1)
              console.log(this.numinv2)
              console.log(this.potenciainversor2)

              if (this.numinv1 * parseFloat(this.potenciainversor1 ) + this.numinv2 * parseFloat(this.potenciainversor2) > this.demanda) {
                this.avisodem = true;
              }
            }
            if (this.avisodem === true || this.avisaplaca === true) {
              this.avissx = true
            }
            this.menumuda(4)

          }

        }
      },
      {
        label: 'Estimativa Geração',
        icon: 'pi pi-chart-line',
        command: (event) => {
          this.gera = true
          this.imageindica();
        }
      },
      {
        label: 'Imprimir proposta',
        icon: 'pi  pi-print',
        command: (event) => {
          this.canvas()
        }
      },

      {
        label: 'Salvar proposta',
        icon: 'pi  pi-save',
        command: (event) => {
          this.salvapropostax()
        }
      }
    ];

    this.barChartData = [
      // tslint:disable-next-line: max-line-length
      { data: [this.jan, this.fev, this.mar, this.abr, this.mai, this.jun, this.jul, this.ago, this.set, this.nov, this.dez], label: 'Consumo' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Geração Estimada' }
    ];
    // this.service.getradiacao()
    this.service.currentNome.subscribe(nome => this.solicitante = nome);
    this.service.currentid.subscribe(dados => {
      if (dados !== this.idempresa && dados !== '') {
        this.idempresa = dados;
        this.puxasolicitante();
      }
    });
    this.service.getradiacaoglobal()
      .subscribe(dados => {
        if (dados !== this.radiajson) {
          this.radiajson = dados;
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < this.radiajson.length; i++) {
            this.estados.push(this.radiajson[i]['UF']);
          }
          this.estados = this.estados.sort();
          this.estados = this.estados.filter(
            (thing, i, arr) => arr.findIndex(t => t === thing) === i);
        }
        for (let i = 0; i < this.estados.length; i++) {
          this.nwestados.push({ name: this.estados[i], code: this.estados[i] });
        }
        console.log(this.nwestados)

      });
      this.puxaequipe()
  }
  puxaequipe() {
    this.service.allequipe({ ID: this.idempresa }).subscribe((datax: any[]) => {
      this.empregados = [];
      if (datax) {
          console.log(datax)
          this.equipex = datax;
          for (let i = 0; i < this.equipex.length; i++) {
            this.empregados.push({Id: this.equipex[i].funcionario, tipo: this.equipex[i].funcionario,})
          }
      }
    });
  }
  filterCountry(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.nwestados.length; i++) {
      let estadox = this.nwestados[i];
      if (estadox['code'].toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(estadox);
      }
    }
    this.filteredEstados = filtered;
  }

  filterInver(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.nwinversores.length; i++) {
      let estadox = this.nwinversores[i];
      if (estadox['code'].indexOf(query) == 0) {
        filtered.push(estadox);
      }
    }
    this.filteredinversores = filtered;
  }

  filterCidade(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.nwcidades.length; i++) {
      let cidadex = this.nwcidades[i];
      if (cidadex['code'].toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(cidadex);
      }
    }
    this.filteredCidades = filtered;
  }

  //////////////////////////////////////////////////////////
  //  CT REFRESH
  //////////////////////////////////////////////////////////
  atualizacustos() {
    this.custototal = this.custopv + this.custoproj + this.customo + this.custosadicionais + this.materialca - this.desconto;
    this.custokwp = (this.custototal / (this.potkwp))
    this.ganho = ((this.materialca + this.custoproj + this.customo + this.custosadicionais - this.desconto) / this.custopv) * 100
    if(this.propostasalvax && this.count>0){
      this.count = 0
      this.novogera = true;
      }
  }



  numpar() {
    this.numparcelas = this.periodofinan * 12
  }
  //////////////////////////////////////////////////////////
  //  BIG FUNCTION RELECO
  //////////////////////////////////////////////////////////
  releco() {
    if (this.ctfp !== 0 && this.ctp !== 0) {
      console.log('grupoA')
      this.nreleca()
    }
    if (this.ctfp === 0 && this.ctp === 0) {
      console.log('grupoB')
      this.ANOS = [];
      this.dias = [];
      this.EprodAno = [];
      this.EaccumAno = [];
      this.EconsumAno = [];
      this.Receita = [];
      this.EconoSimuAno = [];
      this.EconoCompAno = [];
      this.JurosFinan = [];
      this.JurosAtivosAno = [];
      this.FluxoCaixadesc = [];
      this.saidasAno = [];
      this.CustoDisp = [];
      this.CustosAnu = [];
      this.DespExtra = [];
      this.rendimentosacu = [];
      this.ParcelaFinan = [];
      this.capital = [];
      this.FluxoCaixa = [];
      this.FluxoCaixaacc = [];
      this.CapProprio = [];
      this.MonteEco = [];
      this.Taxarend = [];
      this.VPL = [];
      this.TIR = [];
      this.descontado = 0;
      this.depreciacao = this.depreciacao;
      this.ANOS.push(1)
      // tslint:disable-next-line: max-line-length
      this.EprodAno.push(+(this.gerjan + this.gerfev + this.germar + this.gerabr + this.germai + this.gerjun + this.gerjul + this.gerago + this.gerset + this.gerout + this.gernov + this.gerdez).toFixed(2))
      // tslint:disable-next-line: max-line-length
      this.produacaopiano = +(this.gerjan + this.gerfev + this.germar + this.gerabr + this.germai + this.gerjun + this.gerjul + this.gerago + this.gerset + this.gerout + this.gernov + this.gerdez).toFixed(2) + 'kWh';
      // tslint:disable-next-line: max-line-length
      this.EaccumAno.push(+(this.gerjan + this.gerfev + this.germar + this.gerabr + this.germai + this.gerjun + this.gerjul + this.gerago + this.gerset + this.gerout + this.gernov + this.gerdez - this.consumoanual).toFixed(2));
      const taxjur = (this.taxafinan / 100) / 12;
      const relac = this.periodofinan * -1 * 12;
      this.numparcelas = this.periodofinan * 12
      const relac2 = 1 + taxjur;
      const coeficientfinan = (taxjur) / (1 - (Math.pow(relac2, relac)));
      for (let s = 0; s < 25; s++) {
        if (s < this.periodofinan) {
          this.parcelasfinan = this.periodofinan * 12;
          this.valorfinan = (this.percentfinan / 100) * this.custototal;
          this.ParcelaFinan.push(parseFloat((coeficientfinan * this.valorfinan * 12).toFixed(2)));
          this.parcelafinanciamento = this.ParcelaFinan[0] / 12;
        }
        if (s >= this.periodofinan) {
          this.ParcelaFinan.push(0);
        }
      }
      // calculos rendimento com financiamento
      if (this.finam === true) {
        if (this.EprodAno[0] >= this.consumoanual) {
          this.FluxoCaixa.push(this.consumoanual * this.tarenergia - this.ParcelaFinan[0] - this.custodisponibilidade);
          const controlecusto = 0 * (-1 * this.custototal * this.percentfinan / 100);
          const controlecusto2 = controlecusto + this.FluxoCaixa[0];
          this.FluxoCaixaacc.push(controlecusto2);
          // tslint:disable-next-line: no-unused-expression
          const corret = (1.0 + (this.taxjuros / 100))
          const fluxdesc = this.FluxoCaixa[0] / Math.pow(corret, 1);
          this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
          const ret = controlecusto + (this.FluxoCaixadesc[0]);
          this.VPL.push(parseFloat(ret.toFixed(2)));
          this.saidasAno.push(this.custodisponibilidade + this.ParcelaFinan[0])
          this.rendimentosacu.push(parseFloat(fluxdesc.toFixed(2)));
          this.Receita.push(this.consumoanual * this.tarenergia);
        }
        if (this.EprodAno[0] < this.consumoanual) {
          this.descontado = this.EaccumAno[0]
          if ((this.EprodAno[0] + this.descontado) >= this.consumoanual) {
            this.Receita.push(this.consumoanual * this.tarenergia);
            this.EaccumAno[0] = this.EprodAno[0] + this.descontado - this.consumoanual;
            this.FluxoCaixa.push(this.consumoanual * this.tarenergia - this.ParcelaFinan[0] - this.custodisponibilidade);
            const controlecusto = 0 * (-1 * this.custototal * this.percentfinan / 100);
            const controlecusto2 = controlecusto + this.FluxoCaixa[0];
            this.FluxoCaixaacc.push(controlecusto2);
            // tslint:disable-next-line: no-unused-expression
            const corret = (1.0 + (this.taxjuros / 100))
            const fluxdesc = this.FluxoCaixa[0] / Math.pow(corret, 1);
            this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
            const ret = controlecusto + (this.FluxoCaixadesc[0]);
            this.VPL.push(parseFloat(ret.toFixed(2)));
            this.rendimentosacu.push(this.FluxoCaixadesc[0]);
            this.saidasAno.push(this.custodisponibilidade + this.ParcelaFinan[0])
            this.rendimentosacu.push(this.FluxoCaixadesc[0]);

          } else {
            this.Receita.push(this.EprodAno[0] * this.tarenergia);
            this.FluxoCaixa.push(this.EprodAno[0] * this.tarenergia - this.ParcelaFinan[0] - this.custodisponibilidade);
            const controlecusto = 0 * (-1 * this.custototal * this.percentfinan / 100);
            const controlecusto2 = controlecusto + this.FluxoCaixa[0];
            this.FluxoCaixaacc.push(controlecusto2);
            // tslint:disable-next-line: no-unused-expression
            const corret = (1.0 + (this.taxjuros / 100))
            const fluxdesc = this.FluxoCaixa[0] / Math.pow(corret, 1);
            this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
            const ret = controlecusto + (this.FluxoCaixadesc[0]);
            this.VPL.push(parseFloat(ret.toFixed(2)));
            this.rendimentosacu.push(this.FluxoCaixadesc[0]);
            this.saidasAno.push(this.custodisponibilidade + this.ParcelaFinan[0]);
            this.EaccumAno[0] = 0;
          }
        }
        this.barChartData[0].data = []
        this.barChartData[1].data = []


      }

      // calculos rendimento sem financiamento
      this.disponibile.push(this.custodisponibilidade * 12 * this.tarenergia);
      if (this.finam === false) {
        if (this.EprodAno[0] >= this.consumoanual) {
          this.Receita.push(this.consumoanual * this.tarenergia);
          this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 * this.tarenergia);
          this.FluxoCaixa.push(this.MonteEco[0]);
          const custonegativo = this.custototal * -1;
          const controlecusto = custonegativo + this.MonteEco[0];
          this.FluxoCaixaacc.push(controlecusto);
          this.JurosAtivosAno.push(0);
          // tslint:disable-next-line: no-unused-expression
          const corret = (1.0 + (this.taxjuros / 100))
          const fluxdesc = this.FluxoCaixa[0] / Math.pow(corret, 1);
          this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
          const ret = (this.FluxoCaixadesc[0]);
          this.VPL.push(parseFloat(ret.toFixed(2)));
          this.rendimentosacu.push(this.FluxoCaixadesc[0]);
          this.saidasAno.push(this.custodisponibilidade * 12 * this.tarenergia)
        }
        if (this.EprodAno[0] < this.consumoanual) {
          this.descontado = this.EaccumAno[0]
          if ((this.EprodAno[0] + this.descontado) >= this.consumoanual) {
            this.EaccumAno[0] = this.EprodAno[0] + this.descontado - this.consumoanual;
            this.Receita.push(this.consumoanual * this.tarenergia);
            this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 * this.tarenergia);
            this.FluxoCaixa.push(this.MonteEco[0]);
            const custonegativo = this.custototal * -1;
            const controlecusto = custonegativo + this.MonteEco[0];
            this.FluxoCaixaacc.push(controlecusto);
            this.JurosAtivosAno.push(0);
            const corret = (1.0 + (this.taxjuros / 100))
            const fluxdesc = this.FluxoCaixa[0] / Math.pow(corret, 1);
            this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
            const ret = custonegativo + (this.FluxoCaixadesc[0]);
            this.VPL.push(parseFloat(ret.toFixed(2)));
            this.rendimentosacu.push(this.FluxoCaixadesc[0]);
            this.saidasAno.push(this.custodisponibilidade * 12 * this.tarenergia)

          } else {
            this.Receita.push(this.EprodAno[0] * this.tarenergia);
            this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 * this.tarenergia);
            this.FluxoCaixa.push(this.MonteEco[0]);
            const custonegativo = this.custototal * -1;
            const controlecusto = custonegativo + (this.MonteEco[0]);
            this.FluxoCaixaacc.push(controlecusto);
            this.JurosAtivosAno.push(0);
            this.EaccumAno[0] = 0;
            const corret = (1.0 + (this.taxjuros / 100));
            const fluxdesc = this.FluxoCaixa[0] / Math.pow(corret, 1);
            this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
            const ret = custonegativo + this.FluxoCaixadesc[0];
            this.VPL.push(parseFloat(ret.toFixed(2)));
            this.rendimentosacu.push(this.FluxoCaixadesc[0]);
            this.saidasAno.push(this.custodisponibilidade * 12 * this.tarenergia)

          }
        }
      }
      for (let i = 1; i < 25; i++) {
        this.descontado = 0;
        this.ANOS.push((1 + (i)))
        this.dias = [];
        for (let n = 0; n < 12; n++) {
          const diasNoMes = new Date((this.anoatual + (i + 1)), (n + 1), 0).getDate();
          // tslint:disable-next-line: one-variable-per-declaration
          this.dias[n] = [];
          for (let j = 1; j <= diasNoMes; j++) {
            this.dias[n].push(j);
          }
        }
        this.ajan2 = 0;
        this.afev2 = 0;
        this.amar2 = 0;
        this.aabr2 = 0;
        this.amai2 = 0;
        this.ajun2 = 0;
        this.ajul2 = 0;
        this.aago2 = 0;
        this.aset2 = 0;
        this.aout2 = 0;
        this.anov2 = 0;
        this.adez2 = 0;
        this.ajan = 0;
        this.afev = 0;
        this.amar = 0;
        this.aabr = 0;
        this.amai = 0;
        this.ajun = 0;
        this.ajul = 0;
        this.aago = 0;
        this.aset = 0;
        this.aout = 0;
        this.anov = 0;
        this.adez = 0;
        // tslint:disable-next-line: prefer-for-of
        if (this.semtemperatura === true) {
          for (let o = 0; o < this.numinv1; o++) {
            for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
              // tslint:disable-next-line: max-line-length
              this.ajan = this.ajan + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.afev = this.afev + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.amar = this.amar + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.aabr = this.aabr + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.amai = this.amai + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.ajun = this.ajun + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.ajul = this.ajul + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.aago = this.aago + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.aset = this.aset + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.aout = this.aout + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.anov = this.anov + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.adez = this.adez + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 - this.depreciacao * (i));

            }
          }
          if (this.multiploinvesor2 === true) {
            for (let o = 0; o < this.numinv2; o++) {
              for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
                // tslint:disable-next-line: max-line-length
                this.ajan2 = this.ajan2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.afev2 = this.afev2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.amar2 = this.amar2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.aabr2 = this.aabr2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.amai2 = this.amai2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.ajun2 = this.ajun2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.ajul2 = this.ajul + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.aago2 = this.aago2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.aset2 = this.aset2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.aout2 = this.aout2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.anov2 = this.anov2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.adez2 = this.adez2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 - this.depreciacao * (i));

              }
            }
          }
        }
        if (this.semtemperatura === false) {
          for (let o = 0; o < this.numinv1; o++) {
            for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
              // tslint:disable-next-line: max-line-length
              this.ajan = this.ajan + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25)) * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.afev = this.afev + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25)) * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.amar = this.amar + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25)) * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.aabr = this.aabr + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25)) * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.amai = this.amai + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25)) * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.ajun = this.ajun + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25)) * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.ajul = this.ajul + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25)) * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.aago = this.aago + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25)) * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.aset = this.aset + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25)) * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.aout = this.aout + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25)) * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.anov = this.anov + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25)) * (1 - this.depreciacao * (i));
              // tslint:disable-next-line: max-line-length
              this.adez = this.adez + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25)) * (1 - this.depreciacao * (i));

            }
          }
          if (this.multiploinvesor2 === true) {
            for (let o = 0; o < this.numinv2; o++) {
              for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
                // tslint:disable-next-line: max-line-length
                this.ajan2 = this.ajan2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25)) * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.afev2 = this.afev2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25)) * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.amar2 = this.amar2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25)) * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.aabr2 = this.aabr2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25)) * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.amai2 = this.amai2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25)) * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.ajun2 = this.ajun2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25)) * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.ajul2 = this.ajul2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25)) * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.aago2 = this.aago2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25)) * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.aset2 = this.aset2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25)) * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.aout2 = this.aout2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25)) * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.anov2 = this.anov2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25)) * (1 - this.depreciacao * (i));
                // tslint:disable-next-line: max-line-length
                this.adez2 = this.adez2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25)) * (1 - this.depreciacao * (i));

              }
            }
          }
        }
        this.EprodAno.push(
          +(this.ajan + this.ajan2 +
            this.afev + this.afev2 +
            this.amar + this.amar2 +
            this.aabr + this.aabr2 +
            this.amai + this.amai2 +
            this.ajun + this.ajun2 +
            this.ajul + this.ajul2 +
            this.aago + this.aago2 +
            this.aset + this.aset2 +
            this.aout + this.aout2 +
            this.anov + this.anov2 +
            this.adez + this.adez2).toFixed(2));
        // tslint:disable-next-line: max-line-length
        const acc = (+(this.ajan + this.ajan2 +
          this.afev + this.afev2 +
          this.amar + this.amar2 +
          this.aabr + this.aabr2 +
          this.amai + this.amai2 +
          this.ajun + this.ajun2 +
          this.ajul + this.ajul2 +
          this.aago + this.aago2 +
          this.aset + this.aset2 +
          this.aout + this.aout2 +
          this.anov + this.anov2 +
          this.adez + this.adez2) - this.consumoanual).toFixed(2);
        const ainx = i - 1;
        this.custocordisp = this.disponibile[ainx] * (1.05);
        this.disponibile.push(+this.custocordisp.toFixed(2));
        if (parseFloat(acc) > 0) {
          if (i > 0)
          // tslint:disable-next-line: max-line-length
          {
            this.EaccumAno.push(+(parseFloat(acc) + this.EaccumAno[ainx]).toFixed(2))
          } else {
            this.EaccumAno.push((parseFloat(acc)))
          }
        }
        if (parseFloat(acc) < 0) {
          this.EaccumAno.push(0);
        }
        this.descontado = this.EaccumAno[ainx];
        // calculos rendimento sem financiamento
        if (this.finam === false) {
          if (this.EprodAno[i] >= this.consumoanual) {
            const indiceant = i - 1;
            // tslint:disable-next-line: max-line-length
            const reajuste = (1 + (this.reajtar * i));
            const novareceitacor = + (reajuste * this.consumoanual * this.tarenergia).toFixed(2);
            const montantecor = + (this.MonteEco[indiceant] * (this.taxjuros)).toFixed(2);
            //  this.Receita.push(novareceitacor + montantecor);
            this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
            // tslint:disable-next-line: max-line-length
            const receitaatual = +(this.Receita[i]).toFixed(2);
            this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
            this.FluxoCaixa.push(+(receitaatual - this.custocordisp).toFixed(2));
            // const custonegativo = this.custototal *  -1;
            // tslint:disable-next-line: max-line-length
            const controlecusto = +(this.FluxoCaixaacc[indiceant] + receitaatual - this.custocordisp).toFixed(2);
            this.FluxoCaixaacc.push(controlecusto);
            this.JurosAtivosAno.push(this.MonteEco[indiceant] * (this.taxjuros));
            // tslint:disable-next-line: no-unused-expression
            const corret = (1 + (this.taxjuros))
            const fluxdesc = this.FluxoCaixa[i] / Math.pow(corret, i + 1);
            this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
            const ret = this.VPL[indiceant] + this.FluxoCaixadesc[i];
            this.VPL.push(parseFloat(ret.toFixed(2)));
            this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(fluxdesc.toFixed(2)));
            this.saidasAno.push(this.custocordisp)

          }

          if (this.EprodAno[i] < this.consumoanual) {
            if (this.EprodAno[i] + this.descontado >= this.consumoanual) {
              const indiceant = i - 1;
              // tslint:disable-next-line: max-line-length
              const reajuste = (1 + (this.reajtar * i));
              this.EaccumAno[i] = this.EprodAno[i] + this.descontado - this.consumoanual;
              const novareceitacor = + (reajuste * this.consumoanual * this.tarenergia).toFixed(2);
              const montantecor = + (this.MonteEco[indiceant] * (this.taxjuros)).toFixed(2);
              // this.Receita.push(novareceitacor + montantecor);
              this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));

              // tslint:disable-next-line: max-line-length
              const receitaatual = +(this.Receita[i]).toFixed(2);
              this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
              this.FluxoCaixa.push(+(receitaatual - this.custocordisp).toFixed(2));
              // const custonegativo = this.custototal *  -1;
              // tslint:disable-next-line: max-line-length
              const controlecusto = +(this.FluxoCaixaacc[indiceant] + receitaatual - this.custocordisp).toFixed(2);
              this.FluxoCaixaacc.push(controlecusto);
              this.JurosAtivosAno.push(this.MonteEco[indiceant] * (this.taxjuros));
              // tslint:disable-next-line: no-unused-expression
              const corret = (1.0 + (this.taxjuros))
              const fluxdesc = this.FluxoCaixa[i] / Math.pow(corret, i + 1);
              this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
              const ret = this.VPL[indiceant] + this.FluxoCaixadesc[i];
              this.VPL.push(parseFloat(ret.toFixed(2)));
              this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(fluxdesc.toFixed(2)));
              this.saidasAno.push(this.custocordisp)


            }
            if (this.EprodAno[i] + this.descontado <= this.consumoanual) {
              const indiceant = i - 1;
              // tslint:disable-next-line: max-line-length
              const reajuste = (1 + (this.reajtar * i));
              this.EaccumAno[i] = 0;
              const novareceitacor = + (reajuste * (this.EprodAno[i] + this.descontado) * this.tarenergia).toFixed(2);
              const montantecor = + (this.MonteEco[indiceant] * (this.taxjuros)).toFixed(2);
              //this.Receita.push(novareceitacor + montantecor);
              this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));

              // tslint:disable-next-line: max-line-length
              const receitaatual = +(this.Receita[i]).toFixed(2);
              this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
              this.FluxoCaixa.push(+(receitaatual - this.custocordisp).toFixed(2));
              // const custonegativo = this.custototal *  -1;
              // tslint:disable-next-line: max-line-length
              const controlecusto = +(this.FluxoCaixaacc[indiceant] + receitaatual - this.custocordisp).toFixed(2);
              this.FluxoCaixaacc.push(controlecusto);
              this.JurosAtivosAno.push(this.MonteEco[indiceant] * (this.taxjuros));
              // tslint:disable-next-line: no-unused-expression
              const corret = (1.0 + (this.taxjuros))
              const fluxdesc = this.FluxoCaixa[i] / Math.pow(corret, i + 1);
              this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
              const ret = this.VPL[indiceant] + this.FluxoCaixadesc[i];
              this.VPL.push(parseFloat(ret.toFixed(2)));
              this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(fluxdesc.toFixed(2)));
              this.saidasAno.push(this.custocordisp)
            }
          }
        }

        // calculos rendimento com financiamento
        if (this.finam === true) {
          if (this.ParcelaFinan[i + 1] === null || this.ParcelaFinan[i + 1] === undefined) {
            this.ParcelaFinan[i + 1] = 0;
          }
          const indiceant = i - 1;
          if (this.EprodAno[i] >= this.consumoanual) {
            // tslint:disable-next-line: max-line-length
            const reajuste = (1 + (this.reajtar * i));
            const novareceitacor = + (reajuste * this.consumoanual * this.tarenergia).toFixed(2);
            const montantecor = + (this.FluxoCaixa[indiceant] * (this.taxjuros)).toFixed(2);
            this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
            // tslint:disable-next-line: max-line-length
            this.FluxoCaixa.push(this.consumoanual * this.tarenergia * reajuste - this.ParcelaFinan[i] - this.custocordisp);

            // tslint:disable-next-line: no-unused-expression
            const corret = (1.0 + (this.taxjuros));
            const fluxdesc = this.FluxoCaixa[i] / Math.pow(corret, i + 1);
            this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
            const controlecusto2 = this.VPL[indiceant] + this.FluxoCaixadesc[i];
            this.VPL.push(parseFloat(controlecusto2.toFixed(2)));
            this.saidasAno.push(this.custocordisp + this.ParcelaFinan[i + 1])
            this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(fluxdesc.toFixed(2)));

          }
          if (this.EprodAno[i] < this.consumoanual) {
            this.descontado = this.EaccumAno[i]
            if ((this.EprodAno[i] + this.descontado) >= this.consumoanual) {
              // tslint:disable-next-line: max-line-length
              const reajuste = (1 + (this.reajtar * i));
              this.EaccumAno[i] = (this.EprodAno[i] + this.descontado - this.consumoanual);
              const novareceitacor = + (reajuste * this.consumoanual * this.tarenergia).toFixed(2);
              const montantecor = + (this.FluxoCaixa[indiceant] * (this.taxjuros)).toFixed(2);
              this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
              // tslint:disable-next-line: max-line-length
              this.FluxoCaixa.push(this.consumoanual * this.tarenergia * reajuste - this.ParcelaFinan[i] - this.custocordisp);
              // tslint:disable-next-line: no-unused-expression
              const corret = (1.0 + (this.taxjuros))
              const fluxdesc = this.FluxoCaixa[i] / Math.pow(corret, i + 1);
              this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
              const controlecusto2 = this.VPL[indiceant] + this.FluxoCaixadesc[i];
              this.VPL.push(parseFloat(controlecusto2.toFixed(2)));
              this.saidasAno.push(this.custocordisp + this.ParcelaFinan[i + 1])
              this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(fluxdesc.toFixed(2)));

            } else {
              // tslint:disable-next-line: max-line-length
              const reajuste = (1 + (this.reajtar * i));
              this.EaccumAno[i] = 0;
              const novareceitacor = + (reajuste * (this.EprodAno[i] + this.descontado) * this.tarenergia).toFixed(2);
              const montantecor = + (this.FluxoCaixa[indiceant] * (this.taxjuros)).toFixed(2);
              this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
              // tslint:disable-next-line: max-line-length
              this.FluxoCaixa.push(this.EprodAno[i] * this.tarenergia * reajuste - this.ParcelaFinan[i] - this.custocordisp);
              // tslint:disable-next-line: no-unused-expression
              const corret = (1.0 + (this.taxjuros))
              const fluxdesc = this.FluxoCaixa[i] / Math.pow(corret, i + 1);
              this.FluxoCaixadesc.push(parseFloat(fluxdesc.toFixed(2)));
              const controlecusto2 = this.VPL[indiceant] + this.FluxoCaixadesc[i];
              this.VPL.push(parseFloat(controlecusto2.toFixed(2)));
              this.saidasAno.push(this.custocordisp + this.ParcelaFinan[i + 1]);
              this.EaccumAno[i] = 0;
              this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(fluxdesc.toFixed(2)));
            }
          }
        }
        for (let n = 0; n < 12; n++) {
          // tslint:disable-next-line: one-variable-per-declaration
          this.dias.pop();
        }
      }
      var cfw: Number[] = [];
      if (this.finam === false) {
        this.gasto = this.custototal * -1;
        cfw.push(this.custototal * -1);
      }
      if (this.finam === true) {
        let flux = 0;
        // tslint:disable-next-line: prefer-for-of
        for (let n = 0; n < this.ParcelaFinan.length; n++) {
          flux = flux + this.ParcelaFinan[n];
        }
        this.gasto = (flux + (this.custototal * (1 - this.percentfinan))) * -1;
        cfw.push(this.gasto);
      }
      console.log('gastos', this.gasto)
      this.custoroi = 0;
      // tslint:disable-next-line: prefer-for-of
      for (let x = 0; x < this.FluxoCaixadesc.length; x++) {
        cfw.push(this.FluxoCaixadesc[x]);
        this.custoroi = this.custoroi + this.FluxoCaixadesc[x];
      }
      console.log(this.FluxoCaixadesc)
      this.payback = (this.PP(25, cfw));
      console.log(cfw);
      this.valortir = this.finance.IRR(cfw);
      this.valorroi = this.finance.ROI(this.gasto, this.custoroi);
    }
    this.datafinan = [];
    this.ecopriano = this.EprodAno[0]
    for (let i = 0; i < 25; i++) {
      this.datafinan.push({
        "ano": this.ANOS[i],
        "prod": this.EprodAno[i],
        "cred": this.EaccumAno[i],
        "receita": this.Receita[i],
        "disp": this.disponibile[i],
        "anufinan": this.ParcelaFinan[i],
        "saidastotal": this.saidasAno[i],
        "fluxosimples": this.FluxoCaixa[i],
        "fluxodesc": this.FluxoCaixadesc[i],
        "montante": this.rendimentosacu[i]
      })
    }
    console.log(this.datafinan['EprodAno'][0])
  }

  // PAYBACK
  PP(a, b) {
    for (let i = 0; i < this.VPL.length; i++) {
      if (this.VPL[i] >= 0) {
        return i + 1;
      } else {
        console.log("Ainda nao pagou a divida")
      }
    }
  }

  criaucs(){
    this.headerText= [{ text: 'Unidade Geradora' }];
    for (let i = 1; i < this.numucs; i++) {
      var a = (this.headerText.length+(i))
      this.headerText.push({ text: 'UC ' + i })
      console.log(this.headerText)  
    }
    console.log(this.headerText)  
  }
  PP2(numOfPeriods, cfs) {
    // for even cash flows
    if (numOfPeriods === 0) {
      return Math.abs(cfs[0]) / cfs[1];
    }
    // for uneven cash flows
    let cumulativeCashFlow = cfs[0];
    let yearsCounter = 1;
    for (let i = 1; i < cfs.length; i++) {
      cumulativeCashFlow += cfs[i];
      console.log('cfs[i]', cfs[i])
      console.log('cfs[i]', cfs[i])
      if (cumulativeCashFlow > 0) {
        console.log('cumulativeCashFlow', cumulativeCashFlow)
        console.log('(cumulativeCashFlow - cfs[i]) / cfs[i]', (cumulativeCashFlow - cfs[i]) / cfs[i])
        yearsCounter += (cumulativeCashFlow - cfs[i]) / cfs[i];
        return yearsCounter;
      } else {
        yearsCounter++;
      }
    }
  }

  // Internal Rate of Return (IRR)


  seekZero(fn) {
    var x = 1;
    while (fn(x) > 0) {
      x += 1;
    }
    while (fn(x) < 0) {
      x -= 0.01
    }
    return x + 0.01;
  }
  ////////////////////////////////////////////////////////
  //  PUTTING INVERTER IN CASCADE
  //////////////////////////////////////////////////////////
  idmudanca(a) {
    this.idatual1 = a;
    console.log(this.idatual1)
    // tslint:disable-next-line: max-line-length
    if (this.inversorescascata1[this.idatual1][0].qtd_mod_ser) {
      console.log('entrou no if do idmudanca')
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.placasserie.length; i++) {
        this.placasserie[i] = this.inversorescascata1[this.idatual1][i].qtd_mod_ser;
        this.placaspar[i] = this.inversorescascata1[this.idatual1][i].qtd_mod_par;
      }
    } else {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.placasserie.length; i++) {
        this.placasserie[i] = 0;
        this.placaspar[i] = 0;
      }
    }
    // tslint:disable-next-line: max-line-length
  }

  //criacascata1() {
  //this.inversorescascata1 = [];
  //}

  //////////////////////////////////////////////////////////
  //  NUMERO DE INVERSORES
  //////////////////////////////////////////////////////////

  calcinv1() {
    this.numinv1 = this.numinv1 + 1;
    const is = 'Inv' + (this.inversorescascata1.length + 1);
    this.IDSCONF1.push({ value: this.IDSCONF1.length, viewValue: 'Inversor ' + (this.IDSCONF1.length + 1) });
    this.configura = [];
    for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
      const a = String(j + 1);
      const codigomppt = 'MPPT ' + a;
      this.configura.push({
        inversor: 'Inversor ' + (this.numinv1 - 1),
        numofmppt: codigomppt,
        qtd_mod_ser: 0,
        qtd_mod_par: 0,
        voc: 0 * this.paramsmod[0].tensaonom,
        vnom: 0 * this.paramsmod[0].tensaovazio,
        Ic: 0 * this.paramsmod[0].correntenom,
        potconfigurada: 0 * this.numeromppts[j].qtd_mod_par * this.potnom,
        pottotalconfigurada: 0,
        qtd_modulos: 0 * this.numeromppts[j].qtd_mod_par,
      });
      this.qtdmod1 = this.qtdmod1 + this.configura[j]['qtd_modulos'];
    }
    this.inversorescascata1.push(this.configura);
    console.log(this.inversorescascata1);
  }
  calcinv2() {
    this.numinv1 = this.numinv1 - 1;
    this.inversorescascata1.pop();
    this.IDSCONF1.pop();
  }

  //////////////////////////////////////////////////////////
  //  ATIVA INVERSORES DIFERENTES
  //////////////////////////////////////////////////////////

  ativaoutroinv2() {
    if (this.multiploinvesor2 === false) {
      this.multiploinvesor2 = true;
    } else {
      this.multiploinvesor2 = false;
    }
  }

  //////////////////////////////////////////////////////////
  //  ATIVA FINANCIMANENTO
  //////////////////////////////////////////////////////////

  ativafinan() {
    if (this.finam === false) {
      this.finam = true;
    } else {
      this.finam = false;
    }
  }

  //////////////////////////////////////////////////////////
  //  CALCULA POTENCIA INSTALADA
  //////////////////////////////////////////////////////////
  calcpot() {
    this.aumento = 1;
    if (parseFloat(this.aumentoconsumo) > 0) {
      this.aumento = 1 + parseFloat(this.aumentoconsumo) / 100;
    }
    // tslint:disable-next-line: max-line-length
    this.energia = this.kwhdia * (parseFloat(this.razao) / 100) * this.aumento / ((parseFloat(this.eficiencia) / 100) * this.radiacaoescolhida);
    this.potnecessaria = (this.energia).toFixed(2);
    this.pottotal()
    if(this.propostasalvax){
      this.calcfv(this.propostasalva.potenciafvselec)
    }
  }
  
  pottotal(){
    if(this.potnecessaria != 0 && this.potnecessaria1 === 0 && this.potnecessaria2 ===0 && this.potnecessaria3 ===0 && this.potnecessaria4 ===0){
      this.potnecessaria0 = (this.potnecessaria)*1 
    }
    if(this.potnecessaria1 != 0 && this.potnecessaria2 ===0 && this.potnecessaria3 ===0 && this.potnecessaria4 ===0){
      this.potnecessaria0 = (this.potnecessaria)*1 +this.potnecessaria1 *1
    }
    if(this.potnecessaria1 != 0 && this.potnecessaria2 != 0 && this.potnecessaria3 ===0 && this.potnecessaria4 ===0 ){
      this.potnecessaria0 = (this.potnecessaria)*1 +this.potnecessaria1 *1 + this.potnecessaria2 *1
    }
    if(this.potnecessaria1 != 0 && this.potnecessaria2 != 0 && this.potnecessaria3 != 0 && this.potnecessaria4 ===0 ){
      this.potnecessaria0 = (this.potnecessaria)*1 +this.potnecessaria1 *1 + this.potnecessaria2 *1 + this.potnecessaria3 *1
    }
    if(this.potnecessaria1 != 0 && this.potnecessaria2 != 0 && this.potnecessaria3 != 0 && this.potnecessaria4 != 0){
      this.potnecessaria0 = (this.potnecessaria)*1 +this.potnecessaria1 *1 + this.potnecessaria2 *1 + this.potnecessaria3 *1 + this.potnecessaria4 *1 
    }
    
    this.potnecessaria0 = (this.potnecessaria0 + this.potnecessaria0*0.130913091309131).toFixed(2)
    console.log('this.potnecessaria',this.potnecessaria0)
  }

  calcpot1() {
    this.aumento = 1;
    if (parseFloat(this.aumentoconsumo) > 0) {
      this.aumento = 1 + parseFloat(this.aumentoconsumo) / 100;
    }
    // tslint:disable-next-line: max-line-length
    var energia1 = this.kwhdia1 * (parseFloat(this.razao) / 100) * this.aumento / ((parseFloat(this.eficiencia) / 100) * this.radiacaoescolhida);
    this.potnecessaria1 = (energia1).toFixed(2);
    this.pottotal()
  }

  calcpot2() {
    this.aumento = 1;
    if (parseFloat(this.aumentoconsumo) > 0) {
      this.aumento = 1 + parseFloat(this.aumentoconsumo) / 100;
    }
    // tslint:disable-next-line: max-line-length
    var energia2 = this.kwhdia2 * (parseFloat(this.razao) / 100) * this.aumento / ((parseFloat(this.eficiencia) / 100) * this.radiacaoescolhida);
    this.potnecessaria2 = (energia2).toFixed(2);
    this.pottotal()
  }

  calcpot3() {
    this.aumento = 1;
    if (parseFloat(this.aumentoconsumo) > 0) {
      this.aumento = 1 + parseFloat(this.aumentoconsumo) / 100;
    }
    // tslint:disable-next-line: max-line-length
    var energia3 = this.kwhdia3 * (parseFloat(this.razao) / 100) * this.aumento / ((parseFloat(this.eficiencia) / 100) * this.radiacaoescolhida);
    this.potnecessaria3 = (energia3).toFixed(2);
    this.pottotal()
  }

  calcpot4() {
    this.aumento = 1;
    if (parseFloat(this.aumentoconsumo) > 0) {
      this.aumento = 1 + parseFloat(this.aumentoconsumo) / 100;
    }
    // tslint:disable-next-line: max-line-length
    var energia4 = this.kwhdia4 * (parseFloat(this.razao) / 100) * this.aumento / ((parseFloat(this.eficiencia) / 100) * this.radiacaoescolhida);
    this.potnecessaria4 = (energia4).toFixed(2);
    this.pottotal()
  }
  //////////////////////////////////////////////////////////
  //  CALCULA CONSUMO
  //////////////////////////////////////////////////////////
 
  consumo() {
    console.log('this.consumo',this.consumofixo)
    this.barChartData = [];
    this.barChartData = [
      // tslint:disable-next-line: max-line-length
      { data: [this.jan, this.fev, this.mar, this.abr, this.mai, this.jun, this.jul, this.ago, this.set, this.nov, this.dez],   label: 'Consumo Geradora', stack: 'a' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Geração Estimada' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],   label: 'Consumo UC 1', stack: 'a' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],   label: 'Consumo UC 2', stack: 'a' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],   label: 'Consumo UC 3', stack: 'a' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],   label: 'Consumo UC 4', stack: 'a' },
    ];
    if (this.ctfp === 0 && this.ctp === 0) {
      if (this.consumofixo !== 0) {
        this.consumoanual = this.consumofixo * 12;
        // tslint:disable-next-line: max-line-length
        this.barChartData[0].data = [this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo];
        this.kwhdia = this.consumofixo / 30;
        if(this.propostasalvax === false){
          this.jan = this.consumofixo;
          this.fev = this.consumofixo;
          this.mar = this.consumofixo;
          this.abr = this.consumofixo;
          this.mai = this.consumofixo;
          this.jun = this.consumofixo;
          this.jul = this.consumofixo;
          this.ago = this.consumofixo;
          this.set = this.consumofixo;
          this.out = this.consumofixo;
          this.nov = this.consumofixo;
          this.dez = this.consumofixo;
        }
        if(this.propostasalvax === true){

        if(this.propostasalva.geralmeses1 != ""){
          let cons1 = (this.propostasalva.geralmeses1).split(';');
          console.log('tamanho cons1',cons1.length)
          console.log('cons1',cons1)
          for (let i = 0; i < cons1.length; i++) {
           console.log(cons1[i])
          }
          this.jan = cons1[0];
          this.fev = cons1[1];
          this.mar = cons1[2];
          this.abr = cons1[3];
          this.mai = cons1[4];
          this.jun = cons1[5];
          this.jul = cons1[6];
          this.ago = cons1[7];
          this.set = cons1[8];
          this.out = cons1[9];
          this.nov = cons1[10];
          this.dez = cons1[11];
        }
        if(this.propostasalva.geralmeses1 === ""){
        this.jan = this.consumofixo;
        this.fev = this.consumofixo;
        this.mar = this.consumofixo;
        this.abr = this.consumofixo;
        this.mai = this.consumofixo;
        this.jun = this.consumofixo;
        this.jul = this.consumofixo;
        this.ago = this.consumofixo;
        this.set = this.consumofixo;
        this.out = this.consumofixo;
        this.nov = this.consumofixo;
        this.dez = this.consumofixo;
      }}
      }
      // tslint:disable-next-line: no-unused-expression
      if (this.consumofixo === 0) {
        // tslint:disable-next-line: max-line-length
        this.consumoanual = (this.jan) + (this.fev) + (this.mar) + (this.abr) + (this.mai) + (this.jun) + (this.jul) + (this.ago) + (this.set) + (this.out) + (this.nov) + (this.dez);
        this.kwhdia = this.consumoanual / (12 * 30);
        this.geralmeses1 = this.jan +";" + this.fev +";" + this.mar +";" + this.abr +";" + this.mai +";" + this.jun +";" + this.jul +";" + this.ago +";" + this.set + ";" + this.out +";" + this.out + ";" + this.nov +";" + this.dez
        this.consumofixo = this.consumoanual / 12;
        // tslint:disable-next-line: max-line-length
        this.barChartData[0].data = [this.jan, this.fev, this.mar, this.abr, this.mai, this.jun, this.jul, this.ago, this.set, this.out, this.nov, this.dez];
      }

    }
    if(this.propostasalvax){
      this.consumo1();
      this.consumo2()
      this.consumo3()
      this.consumo4()
      this.calcpot()
    }
  }


  //////////////////////////////////////////////////////////
  //  CALCULA CONSUMO
  //////////////////////////////////////////////////////////
  consumo1() {
    if (this.ctfp1 === 0 && this.ctp1 === 0) {
      if (this.consumofixo1 !== 0) {
        this.consumoanual1 = this.consumofixo1 * 12;
        // tslint:disable-next-line: max-line-length
        this.barChartData[2].data = [this.consumofixo1, this.consumofixo1, this.consumofixo1, this.consumofixo1, this.consumofixo1, this.consumofixo1, this.consumofixo1, this.consumofixo1, this.consumofixo1, this.consumofixo1, this.consumofixo1, this.consumofixo1];
        this.kwhdia1 = this.consumofixo1 / 30;

        if(this.propostasalvax === false){
          this.jan1 = this.consumofixo1;
          this.fev1 = this.consumofixo1;
          this.mar1 = this.consumofixo1;
          this.abr1 = this.consumofixo1;
          this.mai1 = this.consumofixo1;
          this.jun1 = this.consumofixo1;
          this.jul1 = this.consumofixo1;
          this.ago1 = this.consumofixo1;
          this.set1 = this.consumofixo1;
          this.out1 = this.consumofixo1;
          this.nov1 = this.consumofixo1;
          this.dez1 = this.consumofixo1;
        }
        if(this.propostasalvax === true){

        if(this.propostasalva.geralmeses1 != ""){
          let cons1 = (this.propostasalva.geralmeses2).split(';');
          console.log('tamanho cons1',cons1.length)
          console.log('cons1',cons1)
          for (let i = 0; i < cons1.length; i++) {
           console.log(cons1[i])
          }
          this.jan1 = cons1[0];
          this.fev1 = cons1[1];
          this.mar1 = cons1[2];
          this.abr1 = cons1[3];
          this.mai1 = cons1[4];
          this.jun1 = cons1[5];
          this.jul1 = cons1[6];
          this.ago1 = cons1[7];
          this.set1 = cons1[8];
          this.out1 = cons1[9];
          this.nov1 = cons1[10];
          this.dez1 = cons1[11];
        }
        if(this.propostasalva.geralmeses1 === ""){
        this.jan1 = this.consumofixo1;
        this.fev1 = this.consumofixo1;
        this.mar1 = this.consumofixo1;
        this.abr1 = this.consumofixo1;
        this.mai1 = this.consumofixo1;
        this.jun1 = this.consumofixo1;
        this.jul1 = this.consumofixo1;
        this.ago1 = this.consumofixo1;
        this.set1 = this.consumofixo1;
        this.out1 = this.consumofixo1;
        this.nov1 = this.consumofixo1;
        this.dez1 = this.consumofixo1;
      }}
        this.calcpot1();
      }
      // tslint:disable-next-line: no-unused-expression
      if (this.consumofixo1 === 0) {
        // tslint:disable-next-line: max-line-length
        this.consumoanual1 = (this.jan1) + (this.fev1) + (this.mar1) + (this.abr1) + (this.mai1) + (this.jun1) + (this.jul1) + (this.ago1) + (this.set1) + (this.out1) + (this.nov1) + (this.dez1);
        this.geralmeses2 = this.jan1 +";" + this.fev1 +";" + this.mar1 +";" + this.abr1 +";" + this.mai1 +";" + this.jun1 +";" + this.jul1 +";" + this.ago1 +";" + this.set1 +";"+ this.out1 + ";" + this.nov1 +";" + this.dez1
        this.kwhdia1 = this.consumoanual1 / (12 * 30);
        this.consumofixo1 = (this.consumoanual1 / 12).toFixed(2);
        // tslint:disable-next-line: max-line-length
        console.log(this.consumoanual1)
        console.log(this.consumofixo1)
        this.barChartData[2].data = [this.jan1, this.fev1, this.mar1, this.abr1, this.mai1, this.jun1, this.jul1, this.ago1, this.set1, this.out1, this.nov1, this.dez1];
        this.calcpot1();
      }

      
    }

  }

  consumo2() {
    if (this.ctfp2 === 0 && this.ctp2 === 0) {
      if (this.consumofixo2 !== 0) {
        this.consumoanual2 = this.consumofixo2 * 12;
        // tslint:disable-next-line: max-line-length
        this.barChartData[3].data = [this.consumofixo2, this.consumofixo2, this.consumofixo2, this.consumofixo2, this.consumofixo2, this.consumofixo2, this.consumofixo2, this.consumofixo2, this.consumofixo2, this.consumofixo2, this.consumofixo2, this.consumofixo2 ];
        this.kwhdia2 = this.consumofixo2 / 30;

        if(this.propostasalvax === false){
          this.jan2 = this.consumofixo2;
          this.fev2 = this.consumofixo2;
          this.mar2 = this.consumofixo2;
          this.abr2 = this.consumofixo2;
          this.mai2 = this.consumofixo2;
          this.jun2 = this.consumofixo2;
          this.jul2 = this.consumofixo2;
          this.ago2 = this.consumofixo2;
          this.set2 = this.consumofixo2;
          this.out2 = this.consumofixo2;
          this.nov2 = this.consumofixo2;
          this.dez2 = this.consumofixo2;
        }
        if(this.propostasalvax === true){
        if(this.propostasalva.geralmese3 != ""){
          let cons1 = (this.propostasalva.geralmeses3).split(';');
          console.log('tamanho cons1',cons1.length)
          console.log('cons1',cons1)
          for (let i = 0; i < cons1.length; i++) {
           console.log(cons1[i])
          }
          this.jan2 = cons1[0];
          this.fev2 = cons1[1];
          this.mar2 = cons1[2];
          this.abr2 = cons1[3];
          this.mai2 = cons1[4];
          this.jun2 = cons1[5];
          this.jul2 = cons1[6];
          this.ago2 = cons1[7];
          this.set2 = cons1[8];
          this.out2 = cons1[9];
          this.nov2 = cons1[10];
          this.dez2 = cons1[11];
        }
        if(this.propostasalva.geralmeses3 === ""){
        this.jan2 = this.consumofixo2;
        this.fev2 = this.consumofixo2;
        this.mar2 = this.consumofixo2;
        this.abr2 = this.consumofixo2;
        this.mai2 = this.consumofixo2;
        this.jun2 = this.consumofixo2;
        this.jul2 = this.consumofixo2;
        this.ago2 = this.consumofixo2;
        this.set2 = this.consumofixo2;
        this.out2 = this.consumofixo2;
        this.nov2 = this.consumofixo2;
        this.dez2 = this.consumofixo2;
      }
      }
      this.calcpot2();

      }
      // tslint:disable-next-line: no-unused-expression
      if (this.consumofixo2 === 0) {
        // tslint:disable-next-line: max-line-length
        this.consumoanual2 = (this.jan2) + (this.fev2) + (this.mar2) + (this.abr2) + (this.mai2) + (this.jun2) + (this.jul2) + (this.ago2) + (this.set2) + (this.out2) + (this.nov2) + (this.dez2);
        this.kwhdia2 = this.consumoanual2 / (12 * 30);
        this.geralmeses3 = this.jan2 +";" + this.fev2 +";" + this.mar2 +";" + this.abr2 +";" + this.mai2 +";" + this.jun2 +";" + this.jul2 +";" + this.ago2 +";" + this.set2 +";" + this.out2 + ";" + this.nov2 +";" + this.dez2
        this.consumofixo2 = (this.consumoanual2 / 12).toFixed(2);
        // tslint:disable-next-line: max-line-length
        this.barChartData[3].data = [this.jan2, this.fev2, this.mar2, this.abr2, this.mai2, this.jun2, this.jul2, this.ago2, this.set2, this.out2, this.nov2, this.dez2];
        this.calcpot2();

      }
     
    }

  }

  consumo3() {
    if (this.ctfp3 === 0 && this.ctp3 === 0) {
      if (this.consumofixo3 !== 0) {
        this.consumoanual3 = this.consumofixo3 * 12;
        // tslint:disable-next-line: max-line-length
        this.barChartData[4].data = [this.consumofixo3, this.consumofixo3, this.consumofixo3, this.consumofixo3, this.consumofixo3, this.consumofixo3, this.consumofixo3, this.consumofixo3, this.consumofixo3, this.consumofixo3, this.consumofixo3, this.consumofixo3];
        this.kwhdia3 = this.consumofixo3 / 30;
        if(this.propostasalvax === false){
          this.jan3 = this.consumofixo3;
          this.fev3 = this.consumofixo3;
          this.mar3 = this.consumofixo3;
          this.abr3 = this.consumofixo3;
          this.mai3 = this.consumofixo3;
          this.jun3 = this.consumofixo3;
          this.jul3 = this.consumofixo3;
          this.ago3 = this.consumofixo3;
          this.set3 = this.consumofixo3;
          this.out3 = this.consumofixo3;
          this.nov3 = this.consumofixo3;
          this.dez3 = this.consumofixo3;
        }
        if(this.propostasalvax === true){
        if(this.propostasalva.geralmeses14!= ""){
          let cons1 = (this.propostasalva.geralmeses4).split(';');
          console.log('tamanho cons1',cons1.length)
          console.log('cons1',cons1)
          for (let i = 0; i < cons1.length; i++) {
           console.log(cons1[i])
          }
          this.jan3 = cons1[0];
          this.fev3 = cons1[1];
          this.mar3 = cons1[2];
          this.abr3 = cons1[3];
          this.mai3 = cons1[4];
          this.jun3 = cons1[5];
          this.jul3 = cons1[6];
          this.ago3 = cons1[7];
          this.set3 = cons1[8];
          this.out3 = cons1[9];
          this.nov3 = cons1[10];
          this.dez3 = cons1[11];
        }
        if(this.propostasalva.geralmeses4 === ""){
        this.jan3 = this.consumofixo3;
        this.fev3 = this.consumofixo3;
        this.mar3 = this.consumofixo3;
        this.abr3 = this.consumofixo3;
        this.mai3 = this.consumofixo3;
        this.jun3 = this.consumofixo3;
        this.jul3 = this.consumofixo3;
        this.ago3 = this.consumofixo3;
        this.set3 = this.consumofixo3;
        this.out3 = this.consumofixo3;
        this.nov3 = this.consumofixo3;
        this.dez3 = this.consumofixo3;
      }
      }
      this.calcpot3();
      }
      // tslint:disable-next-line: no-unused-expression
      if (this.consumofixo3 === 0) {
        // tslint:disable-next-line: max-line-length
        this.consumoanual3 = (this.jan3) + (this.fev3) + (this.mar3) + (this.abr3) + (this.mai3) + (this.jun3) + (this.jul3) + (this.ago3) + (this.set3) + (this.out3) + (this.nov3) + (this.dez3);
        this.kwhdia3 = this.consumoanual3 / (12 * 30);
        this.consumofixo3 = (this.consumoanual3 / 12).toFixed(2);
        this.geralmeses4 = this.jan3 +";" + this.fev3 +";" + this.mar3 +";" + this.abr3 +";" + this.mai3 +";" + this.jun3 +";" + this.jul3 +";" + this.ago3 +";" + this.set3 +";" + this.out3 + ";" + this.nov3 +";" + this.dez3
        // tslint:disable-next-line: max-line-length
        this.barChartData[4].data = [this.jan3, this.fev3, this.mar3, this.abr3, this.mai3, this.jun3, this.jul3, this.ago3, this.set3, this.out3, this.nov3, this.dez3];
        this.calcpot3();
      }
      
    }

  }

  consumo4() {
    if (this.ctfp4 === 0 && this.ctp4 === 0) {
      if (this.consumofixo4 !== 0) {
        this.consumoanual4 = this.consumofixo4 * 12;
        // tslint:disable-next-line: max-line-length
        this.barChartData[5].data = [this.consumofixo4, this.consumofixo4,this.consumofixo4,this.consumofixo4,this.consumofixo4,this.consumofixo4,this.consumofixo4,this.consumofixo4,this.consumofixo4,this.consumofixo4,this.consumofixo4,this.consumofixo4];
        this.kwhdia4 = this.consumofixo4 / 30;
        if(this.propostasalvax === false){
          this.jan4 = this.consumofixo4;
          this.fev4 = this.consumofixo4;
          this.mar4 = this.consumofixo4;
          this.abr4 = this.consumofixo4;
          this.mai4 = this.consumofixo4;
          this.jun4 = this.consumofixo4;
          this.jul4 = this.consumofixo4;
          this.ago4 = this.consumofixo4;
          this.set4 = this.consumofixo4;
          this.out4 = this.consumofixo4;
          this.nov4 = this.consumofixo4;
          this.dez4 = this.consumofixo4;
        }
        if(this.propostasalvax === true){

        if(this.propostasalva.geralmeses5 != ""){
          let cons1 = (this.propostasalva.geralmeses5).split(';');
          console.log('tamanho cons1',cons1.length)
          console.log('cons1',cons1)
          for (let i = 0; i < cons1.length; i++) {
           console.log(cons1[i])
          }
          this.jan4 = cons1[0];
          this.fev4 = cons1[1];
          this.mar4 = cons1[2];
          this.abr4 = cons1[3];
          this.mai4 = cons1[4];
          this.jun4 = cons1[5];
          this.jul4 = cons1[6];
          this.ago4 = cons1[7];
          this.set4 = cons1[8];
          this.out4 = cons1[9];
          this.nov4 = cons1[10];
          this.dez4 = cons1[11];
        }
        if(this.propostasalva.geralmeses5 === ""){
        this.jan4 = this.consumofixo4;
        this.fev4 = this.consumofixo4;
        this.mar4 = this.consumofixo4;
        this.abr4 = this.consumofixo4;
        this.mai4 = this.consumofixo4;
        this.jun4 = this.consumofixo4;
        this.jul4 = this.consumofixo4;
        this.ago4 = this.consumofixo4;
        this.set4 = this.consumofixo4;
        this.out4 = this.consumofixo4;
        this.nov4 = this.consumofixo4;
        this.dez4 = this.consumofixo4;
      }}
      this.calcpot4();
      }
      // tslint:disable-next-line: no-unused-expression
      if (this.consumofixo4 === 0) {
        // tslint:disable-next-line: max-line-length
        this.consumoanual4 = (this.jan4) + (this.fev4) + (this.mar4) + (this.abr4) + (this.mai4) + (this.jun4) + (this.jul4) + (this.ago4) + (this.set4) + (this.out4) + (this.nov4) + (this.dez4);
        this.kwhdia4 = this.consumoanual4 / (12 * 30);
        this.consumofixo4 = (this.consumoanual4 / 12).toFixed(2);
        // tslint:disable-next-line: max-line-length
        this.geralmeses5 = this.jan4 +";" + this.fev4 +";" + this.mar4 +";" + this.abr4 +";" + this.mai4 +";" + this.jun4 +";" + this.jul4 +";" + this.ago4 +";" + this.set4 +";" + this.out4 + ";" + this.nov4 +";" + this.dez4
        this.barChartData[5].data = [this.jan4, this.fev4, this.mar4, this.abr4, this.mai4, this.jun4, this.jul4, this.ago4, this.set4, this.out4, this.nov4, this.dez4];
        this.calcpot4();
      }
    }

  }
  //////////////////////////////////////////////////////////
  //  ZERA CONSUMO
  //////////////////////////////////////////////////////////
  zeraconsumomensal() {
    this.jan = 0;
    this.fev = 0;
    this.mar = 0;
    this.abr = 0;
    this.mai = 0;
    this.jun = 0;
    this.jul = 0;
    this.ago = 0;
    this.set = 0;
    this.out = 0;
    this.nov = 0;
    this.dez = 0;
  }


  // ZERA  CONSUMO
  zera() {
    this.consumofixo = 0;
    this.consumo();
  }

  zera1() {
    this.consumofixo1 = 0;
    this.consumo1();
  }

  zera2() {
    this.consumofixo2 = 0;
    this.consumo2();
  }

  zera3() {
    this.consumofixo3 = 0;
    this.consumo3();
  }

  zera4() {
    this.consumofixo4 = 0;
    this.consumo4();
  }
  //////////////////////////////////////////////////////////
  //  BUSCA CIDADE DB
  //////////////////////////////////////////////////////////
  cidadecaller(uf) {
    this.cidades = [];
    this.temperaturas = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.radiajson.length; i++) {
      if (uf.code === this.radiajson[i]['UF']) {
        this.estadoselecionado = this.radiajson[i]['UF']
        this.cidades.push(this.radiajson[i]['CIDADE']);
      }
    }
    this.cidades = this.cidades.sort();
    this.cidades = this.cidades.filter(
      (thing, i, arr) => arr.findIndex(t => t === thing) === i
    );
    for (let i = 0; i < this.cidades.length; i++) {
      this.nwcidades.push({ name: this.cidades[i], code: this.cidades[i] });
    }
  }
  /////////////////////////////////////////////////////////
  //  BUSCA RADIACAO BANCO DE DADOS
  //////////////////////////////////////////////////////////
  radiajasonx() {
    this.service.getradiacao()
      .subscribe(dados => {
        if (dados) {
          this.radiajson2 = dados;
          // tslint:disable-next-line: prefer-for-o
          if(this.propostasalvax){
            this.testeatualiza()
          }
          
        }
      });
      console.log(this.radiajson2)
      
  }

  getFlooredFixed(v, d) {
    this.radiacaoescolhida = (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }

  radiacaocaller(a) {
    var cida = a.name
    console.log(cida)
    console.log(a)
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.radiajson2.length; i++) {
      if (cida === this.radiajson2[i]['CIDADE'].toUpperCase() && this.estadoselecionado === this.radiajson2[i]['UF']) {
        console.log(this.radiajson2[i]['CIDADE'].toUpperCase())
        this.cidadeescolhida = this.radiajson2[i]['CIDADE'].toUpperCase()
        console.log(this.radiajson2[i]['CIDADE'].toUpperCase())
        //this.radiacaoescolhida = (this.radiajson2[i]['anual'].toFixed(1));
        this.getFlooredFixed(this.radiajson2[i]['anual'], 2)
      }
    }
    this.service.getemperatura()
      // tslint:disable-next-line: deprecation
      .subscribe(dados => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < dados.length; i++) {
          // tslint:disable-next-line: max-line-length
          if (dados[i]['des_pro'] === (this.cidadeescolhida) || dados[i]['des_pro'] === (this.cidadeescolhida + ' - ' + this.estadoselecionado)) {
            this.semtemperatura = false;
            this.t1 = parseFloat(dados[i]['tem_m01']);
            this.t2 = parseFloat(dados[i]['tem_m02']);
            this.t3 = parseFloat(dados[i]['tem_m03']);
            this.t4 = parseFloat(dados[i]['tem_m04']);
            this.t5 = parseFloat(dados[i]['tem_m05']);
            this.t6 = parseFloat(dados[i]['tem_m06']);
            this.t7 = parseFloat(dados[i]['tem_m07']);
            this.t8 = parseFloat(dados[i]['tem_m08']);
            this.t9 = parseFloat(dados[i]['tem_m09']);
            this.t10 = parseFloat(dados[i]['tem_m10']);
            this.t11 = parseFloat(dados[i]['tem_m11']);
            this.t12 = parseFloat(dados[i]['tem_m12']);
          }
        }
        if (this.t1 === 0) { this.semtemperatura = true; }
      })

    this.service.getradiacaoglobal()
      .subscribe(dados => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < dados.length; i++) {
          if (this.cidadeescolhida === dados[i]['CIDADE'] && this.estadoselecionado === dados[i]['UF']) {
            this.rad1 = dados[i]['JAN'] / 1000;
            this.rad2 = dados[i]['FEB'] / 1000;
            this.rad3 = dados[i]['MAR'] / 1000;
            this.rad4 = dados[i]['APR'] / 1000;
            this.rad5 = dados[i]['MAY'] / 1000;
            this.rad6 = dados[i]['JUN'] / 1000;
            this.rad7 = dados[i]['JUL'] / 1000;
            this.rad8 = dados[i]['AUG'] / 1000;
            this.rad9 = dados[i]['SEP'] / 1000;
            this.rad10 = dados[i]['OCT'] / 1000;
            this.rad11 = dados[i]['NOV'] / 1000;
            this.rad12 = dados[i]['DEC'] / 1000;
          }
        }
      });
      if(this.propostasalvax){
        this.consumo()
      }
  }

  /////////////////////////////////////////////////////////
  //  BUSCA MODELO MODULOS BANCO DE DADOS
  //////////////////////////////////////////////////////////
  modelomodulos(mar) {
    this.mod = [];
    this.service.modelomodu({ marca: mar, pot: this.potmodulo })
      .subscribe(dados => {
        if (dados) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < dados.length; i++) {
            this.mod.push(dados[i]['modelo'])
            console.log(this.mod)
            if(this.propostasalvax){
              for (let i = 0; i < this.mod.length; i++) {
                if(this.propostasalva.modelomodu === this.mod[i]){
                  this.modelomodu = this.mod[i]
                }
              }
            }
          }
        }
      });

      console.log(this.modelomodu)
  }
  /////////////////////////////////////////////////////////
  // BUSCA MPARAMETROS DOS MODULOS
  //////////////////////////////////////////////////////////
  parametromudolo(mods) {
    console.log(mods)
    this.codmodulo = mods;
    this.paramsmod = [];
    this.service.parametrosmodulo({ codigo: mods })
      .subscribe(dados => {
        this.paramsmod = dados;
        this.areaplc();
      });
  }

  engvirtual() {
    this.potconf1 = 0;
    console.log(this.potconf1)
    for (let i = 0; i < this.numeromppts.length; i++) {
      console.log(this.inversorescascata1)
      console.log(this.inversorescascata1[0][i])
      this.potconf1 = this.potconf1 + this.inversorescascata1[0][i].pottotalconfigurada
    }
    console.log(this.potconf1)
    this.atualizaplac()
  }

  engvirtual2() {
    this.potconf2 = 0;
    console.log(this.potconf2)
    for (let i = 0; i < this.numeromppts2.length; i++) {
      console.log(this.inversorescascata2)
      console.log(this.inversorescascata2[0][i])
      this.potconf2 = this.potconf2 + this.inversorescascata2[0][i].pottotalconfigurada
    }
    console.log(this.potconf2)
    this.atualizaplac()
  }

  atualizaplac() {
    this.numplacas = 0
    for (let i = 0; i < this.numeromppts.length; i++) {
      this.numplacas = this.numplacas + this.placasserie[i] * this.placaspar[i]
    }

    for (let i = 0; i < this.numeromppts2.length; i++) {
      this.numplacas = this.numplacas + this.placasserie2[i] * this.placaspar2[i]
    }
    this.potkwp = this.numplacas * this.potnom;
    console.log(this.numplacas)
  }
  /////////////////////////////////////////////////////////
  // BUSCA MPARAMETROS DO INVERSOR 1
  //////////////////////////////////////////////////////////

  parainver(mods) {
    this.inversorescascata1 = [];
    this.codinversor = mods;
    this.paramsinv = [];
    this.IDSCONF1 = [];
    this.numeromppts = [];
    this.service.parametrosinversores({ codigo: mods })
      .subscribe(dados => {
        this.paramsinv = dados;
        for (let i = 0; i < this.paramsinv[0]['numppt']; i++) {
          const a = String(i + 1);
          const codigomppt = 'MPPT ' + a;
          const hrefe = '#' + codigomppt;
          this.placasserie.push(0);
          this.placaspar.push(0);
          // parametros dos mppt
          this.numeromppts.push({
            numofmppt: codigomppt,
            href: hrefe,
            cor_max: (this.paramsinv[0]['cor_str']),
            vmp_min: this.paramsinv[0]['vmp_min'],
            vmp_max: this.paramsinv[0]['vmp_max'],
            pot_max: ((this.paramsinv[0]['pot_max']) / 10) / 2,
            qtd_mod_ser: 0,
            qtd_mod_par: 0,
            voc: 0,
            vnom: 0,
            Ic: 0,
            potconfigurada: 0,
            qtd_modulos: 0,
            pottotalconfigurada: 0
          });
        }
        this.inversorconf.push({
          coinv: this.numeromppts,
          potencia: this.paramsinv[0]['vmp_max'],
          rendimento: this.paramsinv[0]['rendimento'],
          rendimentoeu: this.paramsinv[0]['rendimentoeu'],
          numppt: this.paramsinv[0]['numppt'],
          pot_max: this.paramsinv[0]['ppv_max'],
        });
        this.salvainversor();
      });
    for (let i = 0; i < this.numinv1; i++) {
      this.IDSCONF1.push({ value: i, viewValue: 'Inversor ' + (i + 1) });
    }

  }

  menumuda(a) {
    console.log(a)
    if (a === 1) {
      this.menu1 = true;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = false

    }
    if (a === 2) {
      this.menu1 = false;
      this.menu2 = true;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = false
    }
    if (a === 3) {
      console.log("EprodAno",this.EprodAno)
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = true;
      this.menu4 = false
      this.menu5 = false
    }
    if (a === 4) {
      console.log("EprodAno",this.EprodAno)
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = true
      this.menu5 = false
      if(this.propostasalvax){
        this.atualizacustos();
        }
    }
    if (a === 5) {
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false
      this.menu5 = true
    }
  }

  openWithBackdrop(a) {
    this.open(a);
  }
  /////////////////////////////////////////////////////////
  // COLOCA INVERSOR1 EM CASCATA
  //////////////////////////////////////////////////////////
  todosinversores() {
    if (this.allinversores === true) { this.allinversores = false; }
    if (this.allinversores === false) { this.allinversores = true; }
  }

  /////////////////////////////////////////////////////////
  // SALVA INVERSOR SEM CONFIGURAR
  //////////////////////////////////////////////////////////
  salvex2() {
    this.configura = [];
    this.totalpotcasc1 = 0;
    this.qtdmod1 = 0;
  }
  /////////////////////////////////////////////////////////
  // SALVA DADOS DO INVERSOR 1
  //////////////////////////////////////////////////////////
  salvainversor() {
    this.configura = [];
    this.totalpotcasc1 = 0;
    this.qtdmod1 = 0;
    if (this.allinversores === true) {
      this.inversorescascata1 = [];
      for (let i = 0; i < this.numinv1; i++) {
        this.configura = [];
        for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
          const a = String(j + 1);
          const codigomppt = 'MPPT ' + a;
          this.configura.push({
            inversor: 'Inversor ' + (i + 1),
            numofmppt: codigomppt,
            qtd_mod_ser: this.numeromppts[j].qtd_mod_ser,
            qtd_mod_par: this.numeromppts[j].qtd_mod_par,
            voc: this.numeromppts[j].qtd_mod_ser * this.paramsmod[0].tensaonom,
            vnom: this.numeromppts[j].qtd_mod_ser * this.paramsmod[0].tensaovazio,
            Ic: this.numeromppts[j].qtd_mod_par * this.paramsmod[0].correntenom,
            potconfigurada: this.numeromppts[j].qtd_mod_ser * this.numeromppts[j].qtd_mod_par * this.potnom,
            qtd_modulos: this.numeromppts[j].qtd_mod_ser * this.numeromppts[j].qtd_mod_par,
            pottotalconfigurada: 0
          });
          this.qtdmod1 = this.qtdmod1 + this.configura[j]['qtd_modulos'];
        }
        let totalpot = 0;
        for (let k = 0; k < this.paramsinv[0]['numppt']; k++) {
          console.log(this.configura[k]['potconfigurada'])
          totalpot = totalpot + this.configura[k]['potconfigurada']
        }
        this.configura[0]['pottotalconfigurada'] = parseFloat(totalpot.toFixed(2));
        this.inversorescascata1.push(this.configura);
        console.log(this.inversorescascata1);
        if (this.configura[0]['pottotalconfigurada'] > this.paramsinv[0]['potencia']) {
          this.totalpotcasc1 = this.paramsinv[0]['potencia'] + this.totalpotcasc1
          console.log(this.totalpotcasc1)
        }
        if (this.configura[0]['pottotalconfigurada'] < this.paramsinv[0]['potencia']) {
          this.totalpotcasc1 = this.configura[0]['pottotalconfigurada'] + this.totalpotcasc1
          console.log(this.totalpotcasc1)
        }
        console.log(this.totalpotcasc1)
      }
    } else {
      for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
        const a = String(j + 1);
        const codigomppt = 'MPPT ' + a;
        this.inversorescascata1[this.idatual1][j].inversor = 'Inversor ' + (this.idatual1)
        this.inversorescascata1[this.idatual1][j].numofmppt = codigomppt;
        this.inversorescascata1[this.idatual1][j].qtd_mod_ser = this.numeromppts[j].qtd_mod_ser;
        this.inversorescascata1[this.idatual1][j].qtd_mod_par = this.numeromppts[j].qtd_mod_par;
        this.inversorescascata1[this.idatual1][j].voc = (this.numeromppts[j].qtd_mod_ser * this.paramsmod[0].tensaonom);
        this.inversorescascata1[this.idatual1][j].vnom = this.numeromppts[j].qtd_mod_ser * this.paramsmod[0].tensaovazio;
        // tslint:disable-next-line: no-unused-expression
        this.inversorescascata1[this.idatual1][j].Ic = this.numeromppts[j].qtd_mod_par * this.paramsmod[0].correntenom;
        // tslint:disable-next-line: max-line-length
        this.inversorescascata1[this.idatual1][j].potconfigurada = this.numeromppts[j].qtd_mod_ser * this.numeromppts[j].qtd_mod_par * this.potnom;
        // tslint:disable-next-line: max-line-length
        this.inversorescascata1[this.idatual1][j].qtd_modulos = this.numeromppts[j].qtd_mod_ser * this.numeromppts[j].qtd_mod_par;
        this.qtdmod1 = this.qtdmod1 + this.inversorescascata1[this.idatual2][j].qtd_modulos;
      }
    }
    this.salvou = true;


  }



  /////////////////////////////////////////////////////////
  // CALCULO GERACAO INVERSOR 1
  //////////////////////////////////////////////////////////
  potgeracao() {
    console.log('entrou em pot geracao', this.salvexinversor)
    this.gerjan = 0;
    this.gerfev = 0;
    this.germar = 0;
    this.gerabr = 0;
    this.germai = 0;
    this.gerjun = 0;
    this.gerjul = 0;
    this.gerago = 0;
    this.gerset = 0;
    this.gerout = 0;
    this.gernov = 0;
    this.gerdez = 0;
    console.log(this.paramsmod[0].area)
    console.log(this.numplacas)
    console.log(this.rad1)
    console.log(this.eficiencia)
    console.log(this.paramsmod[0].rendimento)
    console.log(this.paramsmod[0].cft)
    // tslint:disable-next-line: prefer-for-of
    if (this.semtemperatura === true) {
      if (this.salvexinversor === 'true') {
        for (let i = 0; i < this.numinv1; i++) {
          for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
            this.gerjan = this.gerjan + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
            this.gerfev = this.gerfev + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 29;
            // tslint:disable-next-line: max-line-length
            this.germar = this.germar + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
            // tslint:disable-next-line: max-line-length
            this.gerabr = this.gerabr + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
            // tslint:disable-next-line: max-line-length
            this.germai = this.germai + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
            // tslint:disable-next-line: max-line-length
            this.gerjun = this.gerjun + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
            // tslint:disable-next-line: max-line-length
            this.gerjul = this.gerjul + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
            // tslint:disable-next-line: max-line-length
            this.gerago = this.gerago + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
            // tslint:disable-next-line: max-line-length
            this.gerset = this.gerset + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
            // tslint:disable-next-line: max-line-length
            this.gerout = this.gerout + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
            // tslint:disable-next-line: max-line-length
            this.gernov = this.gernov + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
            // tslint:disable-next-line: max-line-length
            this.gerdez = this.gerdez + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;

          }
        }
      }
      if (this.salvexinversor === 'false') {
        this.gerjan = this.gerjan + (this.paramsmod[0].area) * (this.numplacas) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
        // tslint:disable-next-line: max-line-length
        this.gerfev = this.gerfev + (this.paramsmod[0].area) * (this.numplacas) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 29;
        // tslint:disable-next-line: max-line-length
        this.germar = this.germar + (this.paramsmod[0].area) * (this.numplacas) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
        // tslint:disable-next-line: max-line-length
        this.gerabr = this.gerabr + (this.paramsmod[0].area) * (this.numplacas) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
        // tslint:disable-next-line: max-line-length
        this.germai = this.germai + (this.paramsmod[0].area) * (this.numplacas) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
        // tslint:disable-next-line: max-line-length
        this.gerjun = this.gerjun + (this.paramsmod[0].area) * (this.numplacas) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
        // tslint:disable-next-line: max-line-length
        this.gerjul = this.gerjul + (this.paramsmod[0].area) * (this.numplacas) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
        // tslint:disable-next-line: max-line-length
        this.gerago = this.gerago + (this.paramsmod[0].area) * (this.numplacas) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
        // tslint:disable-next-line: max-line-length
        this.gerset = this.gerset + (this.paramsmod[0].area) * (this.numplacas) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
        // tslint:disable-next-line: max-line-length
        this.gerout = this.gerout + (this.paramsmod[0].area) * (this.numplacas) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
        // tslint:disable-next-line: max-line-length
        this.gernov = this.gernov + (this.paramsmod[0].area) * (this.numplacas) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
        // tslint:disable-next-line: max-line-length
        this.gerdez = this.gerdez + (this.paramsmod[0].area) * (this.numplacas) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
      }

    }
    if (this.semtemperatura === false) {
      if (this.salvexinversor === 'true') {
        for (let i = 0; i < this.numinv1; i++) {
          for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
            // tslint:disable-next-line: max-line-length
            this.gerjan = this.gerjan + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25));
            // tslint:disable-next-line: max-line-length
            this.gerfev = this.gerfev + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 28 * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25));
            // tslint:disable-next-line: max-line-length
            this.germar = this.germar + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25));
            // tslint:disable-next-line: max-line-length
            this.gerabr = this.gerabr + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25));
            // tslint:disable-next-line: max-line-length
            this.germai = this.germai + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25));
            // tslint:disable-next-line: max-line-length
            this.gerjun = this.gerjun + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25));
            // tslint:disable-next-line: max-line-length
            this.gerjul = this.gerjul + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25));
            // tslint:disable-next-line: max-line-length
            this.gerago = this.gerago + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25));
            // tslint:disable-next-line: max-line-length
            this.gerset = this.gerset + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25));
            // tslint:disable-next-line: max-line-length
            this.gerout = this.gerout + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25));
            // tslint:disable-next-line: max-line-length
            this.gernov = this.gernov + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25));
            // tslint:disable-next-line: max-line-length
            this.gerdez = this.gerdez + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25));

          }
        }
      }
      if (this.salvexinversor === 'false') {
        this.gerjan = this.gerjan + (this.paramsmod[0].area) * (this.numplacas) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25));
        // tslint:disable-next-line: max-line-length
        this.gerfev = this.gerfev + (this.paramsmod[0].area) * (this.numplacas) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 28 * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25));
        // tslint:disable-next-line: max-line-length
        this.germar = this.germar + (this.paramsmod[0].area) * (this.numplacas) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25));
        // tslint:disable-next-line: max-line-length
        this.gerabr = this.gerabr + (this.paramsmod[0].area) * (this.numplacas) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25));
        // tslint:disable-next-line: max-line-length
        this.germai = this.germai + (this.paramsmod[0].area) * (this.numplacas) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25));
        // tslint:disable-next-line: max-line-length
        this.gerjun = this.gerjun + (this.paramsmod[0].area) * (this.numplacas) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25));
        // tslint:disable-next-line: max-line-length
        this.gerjul = this.gerjul + (this.paramsmod[0].area) * (this.numplacas) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25));
        // tslint:disable-next-line: max-line-length
        this.gerago = this.gerago + (this.paramsmod[0].area) * (this.numplacas) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25));
        // tslint:disable-next-line: max-line-length
        this.gerset = this.gerset + (this.paramsmod[0].area) * (this.numplacas) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25));
        // tslint:disable-next-line: max-line-length
        this.gerout = this.gerout + (this.paramsmod[0].area) * (this.numplacas) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25));
        // tslint:disable-next-line: max-line-length
        this.gernov = this.gernov + (this.paramsmod[0].area) * (this.numplacas) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25));
        // tslint:disable-next-line: max-line-length
        this.gerdez = this.gerdez + (this.paramsmod[0].area) * (this.numplacas) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25));
      }

    }
    // tslint:disable-next-line: max-line-length
    this.barChartData[1].data = [Math.trunc(this.gerjan), Math.trunc(this.gerfev), Math.trunc(this.germar), Math.trunc(this.gerabr), Math.trunc(this.germai), Math.trunc(this.gerjun), Math.trunc(this.gerjul), Math.trunc(this.gerago), Math.trunc(this.gerset), Math.trunc(this.gerout), Math.trunc(this.gernov), Math.trunc(this.gerdez)];
    this.tablex()
    this.potger = true
    //this.menumuda(4)
  }


  checaquantidademodulos() {
    var xtotalplac = 0;
    var total1 = 0
    var total2 = 0
    for (let i = 0; i < this.numeromppts.length; i++) {
      xtotalplac = xtotalplac + this.placaspar[i] * this.placasserie[i]
      total1 = i
    }
    for (let x = 0; x < this.numeromppts2.length; x++) {
      xtotalplac = xtotalplac + this.placaspar2[x] * this.placasserie2[x]
      total2 = x
    }
    console.log("placasxtotal", xtotalplac)
    if (total1 === this.numeromppts.length - 1 && total2 === this.numeromppts2.length - 1) {
      this.numplacas = xtotalplac
      this.calcfvnov()
    }
  }

  calcfvnov() {
    this.areaplacas = this.numplacas * this.paramsmod[0].area;
    this.potkwp = (this.numplacas * this.potenciafvselec).toFixed(3);
  }

  tablex() {
    console.log("ä", this.jan)
    this.tabelager = [
      {
        "mes": "Janeiro",
        "consumo": this.jan,
        "geracao": this.gerjan,
        "excedente": this.gerjan - this.jan,
        "fatura": this.jan * this.tarenergia,
        "faturapos": this.checktar(this.gerjan, this.jan)
      },
      {
        "mes": "Fevereiro",
        "consumo": this.fev,
        "geracao": this.gerfev,
        "excedente": this.gerfev - this.fev,
        "fatura": this.fev * this.tarenergia,
        "faturapos": this.checktar(this.gerfev, this.fev)
      },
      {
        "mes": "Março",
        "consumo": this.mar,
        "geracao": this.germar,
        "excedente": this.germar - this.mar,
        "fatura": this.mar * this.tarenergia,
        "faturapos": this.checktar(this.germar, this.mar)
      },
      {
        "mes": "Abril",
        "consumo": this.abr,
        "geracao": this.gerabr,
        "excedente": this.gerabr - this.abr,
        "fatura": this.abr * this.tarenergia,
        "faturapos": this.checktar(this.gerabr, this.abr)
      },
      {
        "mes": "Maio",
        "consumo": this.mai,
        "geracao": this.germai,
        "excedente": this.germai - this.mai,
        "fatura": this.mai * this.tarenergia,
        "faturapos": this.checktar(this.germai, this.mai)
      },
      {
        "mes": "Junho",
        "consumo": this.jun,
        "geracao": this.gerjun,
        "excedente": this.gerjun - this.jun,
        "fatura": this.jun * this.tarenergia,
        "faturapos": this.checktar(this.gerjun, this.jun)
      },
      {
        "mes": "Julho",
        "consumo": this.jul,
        "geracao": this.gerjul,
        "excedente": this.gerjul - this.jul,
        "fatura": this.jul * this.tarenergia,
        "faturapos": this.checktar(this.gerjul, this.jul)
      },
      {
        "mes": "Agosto",
        "consumo": this.ago,
        "geracao": this.gerago,
        "excedente": this.gerago - this.ago,
        "fatura": this.ago * this.tarenergia,
        "faturapos": this.checktar(this.gerago, this.ago)
      },
      {
        "mes": "Setembro",
        "consumo": this.set,
        "geracao": this.gerset,
        "excedente": this.gerset - this.set,
        "fatura": this.set * this.tarenergia,
        "faturapos": this.checktar(this.gerset, this.set)
      },
      {
        "mes": "Outubro",
        "consumo": this.out,
        "geracao": this.gerout,
        "excedente": this.gerout - this.out,
        "fatura": this.out * this.tarenergia,
        "faturapos": this.checktar(this.gerout, this.out)
      },
      {
        "mes": "Novembro",
        "consumo": this.nov,
        "geracao": this.gernov,
        "excedente": this.gernov - this.nov,
        "fatura": this.nov * this.tarenergia,
        "faturapos": this.checktar(this.gernov, this.nov)
      },
      {
        "mes": "Dezembro",
        "consumo": this.dez,
        "geracao": this.gerdez,
        "excedente": this.gerdez - this.dez,
        "fatura": this.dez * this.tarenergia,
        "faturapos": this.checktar(this.gerdez, this.dez)
      }
    ]
  }
  checktar(a, b) {
    console.log("ä", a)
    console.log("b", b)
    if (a >= b) {
      return (this.custodisponibilidade)
    }
    if (a < b) {
      return (this.custodisponibilidade + (a * this.tarenergia - b * this.tarenergia))
    }
  }

  mostra(a, b, c) {
    const ac = a - 1;
  }

  /////////////////////////////////////////////////////////
  // BUSCA POTENCIA INVERSORES BANCO DE DADOS
  //////////////////////////////////////////////////////////
  potenciainversores() {
    this.modi = [];
    this.miniv = Math.trunc(this.potkwp);
    this.mainv = Math.ceil(1.15 * this.potkwp);
    this.service.potinv({ m: this.miniv/4, max: this.mainv })
      .subscribe(dados => {
        if (dados) {
          this.potinversor = dados.sort((a, b) => a - b);
          for (let i = 0; i < this.potinversor.length; i++) {
            this.nwinversores.push({ name: this.potinversor, code: this.potinversor });
          }
          console.log(this.nwinversores)
        }
      });
  }
  /////////////////////////////////////////////////////////
  // BUSCA MARCA INVERSOR1 BANCO DE DADOS
  //////////////////////////////////////////////////////////
  marcainversor(potencia) {
    this.iv1 = potencia
    this.potinversorselec = potencia
    this.modi = [];
    this.marcasinver = [];
    //transforma a entrada em número float

    var value = potencia.toString();
    if (value.indexOf('.') !== -1 && value.indexOf(',') !== -1) {
      if (value.indexOf('.') < value.indexOf(',')) {
        //inglês
        return parseFloat(value.replace(/,/gi, ''));
      } else {
        //português
        value = parseFloat(value.replace(/./gi, '').replace(/,/gi, '.'));
      }
    } else {
      value = parseFloat(value);
    }
    console.log(value);

    if (this.ctfp !== 0 && this.ctp !== 0) {
      console.log('grupoA')
      console.log(parseFloat(potencia))
      if (this.potkwp >= this.demanda) {
        this.numinv1 = Math.trunc(this.demanda / parseFloat(potencia));
      }
      if (this.potkwp < this.demanda) {
        this.numinv1 = Math.trunc(this.potkwp / parseFloat(potencia));
      }
    }
    if (this.ctfp === 0 && this.ctp === 0) {
      console.log('grupoB')
      this.numinv1 = Math.trunc(this.potkwp / parseFloat(potencia));
    }
    console.log(this.numinv1)
    if (this.numinv1 === 0) { this.numinv1 = 1; }
    this.service.marcainver({ pot: potencia })
      .subscribe(dados => {
        if (dados !== this.marcasinver) {
          this.marcasinver = dados.sort();
        }
      });
  }
  /////////////////////////////////////////////////////////
  // BUSCA MODELO INVERSOR1 BANCO DE DADOS
  //////////////////////////////////////////////////////////
  modeloinversor(mar) {
    this.modi = [];
    this.placasserie = [];
    this.placaspar = [];
    this.service.modeloinv({ marca: mar, pot: this.potinversorselec })
      .subscribe(dados => {
        if (dados !== this.modi) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < dados.length; i++) {
            this.modi.push({ modelo: dados[i]['modelo'], codice2: dados[i]['codice2'] })
          }
        }
      });
  }


  /////////////////////////////////////////////////////////
  // CALCULA FVS
  //////////////////////////////////////////////////////////
  calcfv(pot) {
    this.modelos = [];
    this.marcas = [];
    this.mod = [];
    this.potkwp = 0;
    this.areaplacas = 0;
    this.potnom = (pot / 1000)
    this.potnom = this.potnom.toFixed(3);
    this.numplacas = parseFloat((this.potnecessaria0 / this.potnom).toFixed(0));
    this.potkwp = this.numplacas * this.potnom;
    //this.areaplacas = this.numplacas * this.paramsmod[0].area;
    while (this.potkwp <= this.potnecessaria0) {
      this.numplacas = this.numplacas + 1;
      this.potkwp = this.numplacas * this.potnom;
      //this.areaplacas = this.numplacas * this.paramsmod[0].area;
    }
    this.potkwp = (this.numplacas * this.potnom).toFixed(3);
    this.potenciafvselec = this.potnom * 1000;
    this.potmodulo = this.potnom * 1000;
    this.potenciamodulos(this.potmodulo);

    if(this.propostasalvax){
      this.marcamodul = this.propostasalva.marcamodul
      //this.modelomodu = this.propostasalva.modelomodu.modelo
      //this.mod = this.propostasalva.modelomodu;
      this.potenciainversor1 = this.propostasalva.potenciainversor1
      this.numinv1 = this.propostasalva.numinv1
      this.marcinversor1 = this.propostasalva.marcinversor1
      this.modeinv1 = this.propostasalva.modeinv1
      this.digitapotinv1 = 'true'
      this.parametromudolo(this.propostasalva.modelomodu)
      this.modelomodulos(this.propostasalva.marcamodul);
      this.marcainversor(this.potenciainversor1)
      console.log(this.propostasalva.modelomodu)
      this.potinversorselec = this.potenciainversor1
      this.modeloinversor(this.marcinversor1)
      this.parainver(this.modeinv1)
    }
  }

  areaplc(){
          this.areaplacas = this.numplacas * this.paramsmod[0].area;
  }

  calcfv2() {
    this.numplacas = this.numplacas + 1;
    this.potkwp = (this.numplacas * (this.potmodulo) / 1000).toFixed(3);
    this.areaplacas = this.numplacas * this.paramsmod[0].area;
  }
  calcfv3() {
    this.numplacas = this.numplacas - 1;
    this.potkwp = (this.numplacas * (this.potmodulo) / 1000).toFixed(3);
    this.areaplacas = this.numplacas * this.paramsmod[0].area;
  }


  calcfveira(a) {
    console.log(a)
    console.log("this.numplacas",this.numplacas)
    this.potkwp = (this.numplacas * (this.potmodulo) / 1000).toFixed(3);
    this.areaplacas = this.numplacas * this.paramsmod[0].area;
  }
  /////////////////////////////////////////////////////////
  // CALCULA POTÊNCIA DOS MÓDULOS
  //////////////////////////////////////////////////////////
  potenciamodulos(potencia) {
    this.service.potmod({ pot: potencia })
      .subscribe(dados => {
        if (dados !== this.marcas) {
          this.marcas = dados.sort();
        }
      });
  }
  //////////////////////////////////////////////////////////
  //  NUMERO DE INVERSORES 2
  //////////////////////////////////////////////////////////

  calcinv21() {
    this.numinv2 = this.numinv2 + 1;
    const is = 'Inv' + (this.inversorescascata2.length + 1);
    this.IDSCONF2.push({ value: this.IDSCONF2.length, viewValue: 'Inversor ' + (this.IDSCONF2.length + 1) });
    this.configura2 = [];
    for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
      const a = String(j + 1);
      const codigomppt = 'MPPT ' + a;
      this.configura2.push({
        inversor: 'Inversor ' + (this.numinv2 - 1),
        numofmppt: codigomppt,
        qtd_mod_ser: 0,
        qtd_mod_par: 0,
        voc: 0 * this.paramsmod[0].tensaonom,
        vnom: 0 * this.paramsmod[0].tensaovazio,
        Ic: 0 * this.paramsmod[0].correntenom,
        potconfigurada: 0 * this.numeromppts2[j].qtd_mod_par * this.potnom,
        pottotalconfigurada: 0,
        qtd_modulos: 0 * this.numeromppts2[j].qtd_mod_par,
      });
      this.qtdmod2 = this.qtdmod2 + this.configura[j]['qtd_modulos'];
    }
    this.inversorescascata2.push(this.configura2);

  }
  calcinv22() {
    this.numinv2 = this.numinv2 - 1;
    this.inversorescascata2.pop();
    this.IDSCONF2.pop();
  }

  ////////////////////////////////////////////////////////
  //  PUTTING INVERTER IN CASCADE 2
  //////////////////////////////////////////////////////////
  idmudanca2(a) {
    this.idatual2 = a;
    // tslint:disable-next-line: max-line-length
    if (this.inversorescascata2[this.idatual2][0].qtd_mod_ser) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.placasserie2.length; i++) {
        this.placasserie2[i] = this.inversorescascata2[this.idatual2][i].qtd_mod_ser;
        this.placaspar2[i] = this.inversorescascata2[this.idatual2][i].qtd_mod_par;
      }
    } else {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.placasserie2.length; i++) {
        this.placasserie2[i] = 0;
        this.placaspar2[i] = 0;
      }
    }
  }


  /////////////////////////////////////////////////////////
  // BUSCA MPARAMETROS DO INVERSOR 2
  //////////////////////////////////////////////////////////
  parainver2(mods) {
    this.inversorescascata2 = [];
    this.codinversor2 = mods;
    this.paramsinv2 = [];
    this.IDSCONF2 = [];
    this.numeromppts2 = [];
    this.service.parametrosinversores({ codigo: mods })
      .subscribe(dados => {
        this.paramsinv2 = dados;
        for (let i = 0; i < this.paramsinv2[0]['numppt']; i++) {
          const a = String(i + 1);
          const codigomppt = 'MPPT ' + a;
          const hrefe = '#' + codigomppt;
          this.placasserie2.push(0);
          this.placaspar2.push(0);
          // parametros dos mppt
          this.numeromppts2.push({
            numofmppt: codigomppt,
            href: hrefe,
            cor_max: (this.paramsinv2[0]['cor_str']),
            vmp_min: this.paramsinv2[0]['vmp_min'],
            vmp_max: this.paramsinv2[0]['vmp_max'],
            pot_max: ((this.paramsinv2[0]['ppv_max']) / 10) / 2,
            qtd_mod_ser: 0,
            qtd_mod_par: 0,
            voc: 0,
            vnom: 0,
            Ic: 0,
            potconfigurada: 0,
            qtd_modulos: 0,
            pottotalconfigurada: 0,
          });
        }
        this.inversorconf2.push({
          coinv: this.numeromppts2,
          potencia: this.paramsinv2[0]['vmp_max'],
          rendimento: this.paramsinv2[0]['rendimento'],
          rendimentoeu: this.paramsinv2[0]['rendimentoeu'],
          numppt: this.paramsinv2[0]['numppt'],
          pot_max: this.paramsinv2[0]['ppv_max'],
        });
        this.salvainversor2();
      });
    for (let i = 0; i < this.numinv2; i++) {
      this.IDSCONF2.push({ value: i, viewValue: 'Inversor ' + (i + 1) });
    }

  }
  /////////////////////////////////////////////////////////
  // COLOCA INVERSOR2 EM CASCATA
  //////////////////////////////////////////////////////////
  todosinversores2() {
    if (this.allinversores2 === true) { this.allinversores2 = false; }
    if (this.allinversores2 === false) { this.allinversores2 = true; }
  }

  /////////////////////////////////////////////////////////
  // SALVA DADOS DO INVERSOR 2
  //////////////////////////////////////////////////////////
  salvainversor2() {
    this.configura2 = [];
    this.qtdmod2 = 0;
    if (this.allinversores2 === true) {
      this.inversorescascata2 = [];
      for (let i = 0; i < this.numinv2; i++) {
        this.configura2 = [];
        for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
          const a = String(j + 1);
          const codigomppt = 'MPPT ' + a;
          this.configura2.push({
            inversor: 'Inversor ' + (i + 1),
            numofmppt: codigomppt,
            qtd_mod_ser: this.numeromppts2[j].qtd_mod_ser,
            qtd_mod_par: this.numeromppts2[j].qtd_mod_par,
            voc: this.numeromppts2[j].qtd_mod_ser * this.paramsmod[0].tensaonom,
            vnom: this.numeromppts2[j].qtd_mod_ser * this.paramsmod[0].tensaovazio,
            Ic: this.numeromppts2[j].qtd_mod_par * this.paramsmod[0].correntenom,
            potconfigurada: this.numeromppts2[j].qtd_mod_ser * this.numeromppts2[j].qtd_mod_par * this.potnom,
            qtd_modulos: this.numeromppts2[j].qtd_mod_ser * this.numeromppts2[j].qtd_mod_par,
            pottotalconfigurada: 0
          });
          this.qtdmod2 = this.qtdmod2 + this.configura2[j]['qtd_modulos'];
        }
        let totalpot = 0;
        for (let k = 0; k < this.paramsinv2[0]['numppt']; k++) {
          console.log(this.configura2[k]['potconfigurada'])
          totalpot = totalpot + this.configura2[k]['potconfigurada']
        }
        this.configura2[0]['pottotalconfigurada'] = parseFloat(totalpot.toFixed(2));
        this.inversorescascata2.push(this.configura2);
      }
    } else {
      for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
        const a = String(j + 1);
        const codigomppt = 'MPPT ' + a;
        this.inversorescascata2[this.idatual2][j].inversor = 'Inversor ' + (this.idatual2)
        this.inversorescascata2[this.idatual2][j].numofmppt = codigomppt;
        this.inversorescascata2[this.idatual2][j].qtd_mod_ser = this.numeromppts2[j].qtd_mod_ser;
        this.inversorescascata2[this.idatual2][j].qtd_mod_par = this.numeromppts2[j].qtd_mod_par;
        this.inversorescascata2[this.idatual2][j].voc = (this.numeromppts2[j].qtd_mod_ser * this.paramsmod[0].tensaonom);
        this.inversorescascata2[this.idatual2][j].vnom = this.numeromppts2[j].qtd_mod_ser * this.paramsmod[0].tensaovazio;
        // tslint:disable-next-line: no-unused-expression
        this.inversorescascata2[this.idatual2][j].Ic = this.numeromppts2[j].qtd_mod_par * this.paramsmod[0].correntenom;
        // tslint:disable-next-line: max-line-length
        this.inversorescascata2[this.idatual2][j].potconfigurada = this.numeromppts2[j].qtd_mod_ser * this.numeromppts2[j].qtd_mod_par * this.potnom;
        // tslint:disable-next-line: max-line-length
        this.inversorescascata2[this.idatual2][j].qtd_modulos = this.numeromppts2[j].qtd_mod_ser * this.numeromppts2[j].qtd_mod_par;
        this.qtdmod2 = this.qtdmod2 + this.inversorescascata2[this.idatual2][j].qtd_modulos;
      }
    }
    this.salvou2 = true;
  }
  /////////////////////////////////////////////////////////
  // CALCULO GERACAO INVERSOR 2
  //////////////////////////////////////////////////////////
  potgeracao2() {
    this.gerjan2 = 0;
    this.gerfev2 = 0;
    this.germar2 = 0;
    this.gerabr2 = 0;
    this.germai2 = 0;
    this.gerjun2 = 0;
    this.gerjul2 = 0;
    this.gerago2 = 0;
    this.gerset2 = 0;
    this.gerout2 = 0;
    this.gernov2 = 0;
    this.gerdez2 = 0;
    // tslint:disable-next-line: prefer-for-of
    if (this.semtemperatura === true) {
      for (let i = 0; i < this.numinv2; i++) {
        for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
          // tslint:disable-next-line: max-line-length
          this.gerjan2 = this.gerjan2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerfev2 = this.gerfev2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 29;
          // tslint:disable-next-line: max-line-length
          this.germar2 = this.germar2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerabr2 = this.gerabr2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.germai2 = this.germai2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerjun2 = this.gerjun2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerjul2 = this.gerjul2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerago2 = this.gerago2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerset2 = this.gerset2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.gerout2 = this.gerout2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gernov2 = this.gernov2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.gerdez2 = this.gerdez2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;

        }
      }
    }
    if (this.semtemperatura === false) {

      for (let i = 0; i < this.numinv2; i++) {
        for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
          // tslint:disable-next-line: max-line-length
          this.gerjan2 = this.gerjan2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerfev2 = this.gerfev2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 29 * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25));
          // tslint:disable-next-line: max-line-length
          this.germar2 = this.germar2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerabr2 = this.gerabr2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25));
          // tslint:disable-next-line: max-line-length
          this.germai2 = this.germai2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerjun2 = this.gerjun2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerjul2 = this.gerjul2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerago2 = this.gerago2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerset2 = this.gerset2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerout2 = this.gerout2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25));
          // tslint:disable-next-line: max-line-length
          this.gernov2 = this.gernov2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerdez2 = this.gerdez2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25));

        }
      }
    }
    // tslint:disable-next-line: max-line-length
    this.barChartData[1].data = [Math.trunc(this.gerjan + this.gerjan2), Math.trunc(this.gerfev + this.gerfev2), Math.trunc(this.germar + this.germar2), Math.trunc(this.gerabr + this.gerabr2), Math.trunc(this.germai + this.germai2), Math.trunc(this.gerjun + this.gerjun2), Math.trunc(this.gerjul + this.gerjul2), Math.trunc(this.gerago + this.gerago2), Math.trunc(this.gerset + this.gerset2), Math.trunc(this.gerout + this.gerout2), Math.trunc(this.gernov + this.gernov2), Math.trunc(this.gerdez + this.gerdez2)];
  }

  /////////////////////////////////////////////////////////
  // BUSCA POTENCIA INVERSORES2 BANCO DE DADOS
  //////////////////////////////////////////////////////////
  potenciainversores2() {
    this.modi2 = [];
    this.service.potinv({ m: this.miniv/4, max: this.mainv })
      .subscribe(dados => {
        if (dados !== this.potinversor2) {
          this.potinversor2 = dados.sort((a, b) => a - b);
        }
      });
  }
  /////////////////////////////////////////////////////////
  // BUSCA MARCA INVERSOR2 BANCO DE DADOS
  //////////////////////////////////////////////////////////
  marcainversor2(potencia) {
    this.potinversorselec2 = potencia;
    this.modi2 = [];
    this.marcasinver2 = [];
    var value2 = potencia.toString();
    if (value2.indexOf('.') !== -1 && value2.indexOf(',') !== -1) {
      if (value2.indexOf('.') < value2.indexOf(',')) {
        //inglês
        return parseFloat(value2.replace(/,/gi, ''));
      } else {
        //português
        value2 = parseFloat(value2.replace(/./gi, '').replace(/,/gi, '.'));
      }
    } else {
      value2 = parseFloat(value2);
    }
    this.numinv2 = 1
    if (this.ctfp === 0 && this.ctp === 0) {
      console.log('grupoB')
      this.numinv1 = Math.trunc(this.potkwp / parseFloat(potencia));
    }
    if (this.numinv2 === 0) { this.numinv2 = 1; }
    this.service.marcainver({ pot: value2 })
      .subscribe(dados => {
        if (dados !== this.marcasinver2) {
          this.marcasinver2 = dados.sort();
        }
      });
  }
  /////////////////////////////////////////////////////////
  // BUSCA MODELO INVERSOR1 BANCO DE DADOS
  //////////////////////////////////////////////////////////
  modeloinversor2(mar) {
    this.modi2 = [];
    this.placasserie2 = [];
    this.placaspar2 = [];
    this.service.modeloinv({ marca: mar, pot: this.potinversorselec2 })
      .subscribe(dados => {
        if (dados !== this.modi2) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < dados.length; i++) {
            this.modi2.push({ modelo: dados[i]['modelo'], codice2: dados[i]['codice2'] })
          }
        }
      });
  }

  nloadFile() {
    // tslint:disable-next-line: max-line-length
    generate({
      tipousina: 'Microgeração',
      potnominal: 'testepot',
      kwp: 'testekwp',
      cidade: 'montes claros',
      estado: 'Minas Gerais',
      nomecliente: 'Robson',
      inversor1: 'Canadia solar' + ' ' + '10',
      inversor2: 'Canadia solar' + ' ' + '10',
      inversor3: '',
      modulos1: 'ABB solar',
      modulos2: '',
      modulos3: '',
      linkdoc: this.URL,
    });
  }


  imagefin() {
    const node = document.getElementById('my-node');
    domtoimage.toPng(node)
      .then(dataUrl => {
        const img = new Image();
        img.src = dataUrl;
        this.imagefinanceiro = dataUrl;
        this.imageindica();
      })
      // tslint:disable-next-line: only-arrow-functions
      .catch(error => {
        console.error('oops, something went wrong!', error);
      });
  }

  imageindica() {
    const node = document.getElementById('my-node2');
    domtoimage.toPng(node)
      .then(dataUrl => {
        const img = new Image();
        img.src = dataUrl;
        this.imageindicadores = dataUrl;
        this.menumuda(3)
      })
      // tslint:disable-next-line: only-arrow-functions
      .catch(error => {
        console.error('oops, something went wrong!', error);
      });
  }

  mudamodelo(i) {
    const index = i - 1;
    this.modeloplacas = this.mod[index].modelo;
    this.marcamodulosx = this.marcamodulos + ' - ' + this.modeloplacas;
  }

  mudamodinv1(i) {
    const index = i - 1;
    this.modeloinversor1 = this.modi[index].modelo;
    this.marcainversor1x = this.marcainversor1 + ' - ' + this.modeloinversor1;
  }

  mudamodinv2(i) {
    const index = i - 1;
    console.log(this.modi2[index].modelo);
    this.modelinv2 = this.modi2[index].modelo;
    //this.marcainver2 = this.marcainver2 + '-' + this.modelinv2;
  }



  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }
  canvas() {
    html2canvas(document.querySelector('#myCanvas')).then(canvasx => {
    });
    this.canvs = document.getElementById('myCanvas') as HTMLCanvasElement;
    this.imagev = this.canvs.toDataURL('image/png').replace('image/png', 'image/png');
    this.menumuda(5)
  }
  geraoutrasimagens() {
    this.imagefin();
  }

  images(a) {
    console.log(this.EprodAnox, 'this.EprodAnox')
    this.dialogoso = a;
    this.open(this.dialogoso)
    this.retorno = 0;
    for (let i = 0; i < 25; i++) {
      this.retorno = this.retorno + this.FluxoCaixadesc[i];
    }
    this.totalplacas = this.qtdmod2 + this.qtdmod1;
    for (let i = 0; i < this.periodopay; i++) {
    }
    this.conta = parseFloat((this.consumoanual/12 * this.tarenergia).toFixed(2));
    this.economia = (parseFloat((this.ecopriano/12 * this.tarenergia).toFixed(2)));
    if (this.ctfp === 0 && this.ctp === 0) {	
      if ((this.ecopriano - this.consumoanual) < 0) {
         if (( this.consumoanual-this.ecopriano) < this.custodisponibilidade) {
           this.compen = this.custodisponibilidade*this.tarenergia
       }	
         if ((this.consumoanual-this.ecopriano) > this.custodisponibilidade) {
           this.compen = (this.consumoanual*this.tarenergia)/12 - (this.ecopriano*this.tarenergia)/12 + this.custodisponibilidade*this.tarenergia
       }
      }	
       if ((this.ecopriano - this.consumoanual) > 0) {
         this.compen = this.custodisponibilidade*this.tarenergia
       }
     }

     if (this.ctfp !== 0 && this.ctp !== 0) {	
      if ((this.ecopriano - this.consumoanual) < 0) {
           this.compen = (this.consumoanual*this.tarenergiaforaponta)/12 - (this.ecopriano*this.tarenergiaforaponta)/12 + this.custodisponibilidade
     
      }	
       if ((this.ecopriano - this.consumoanual) > 0) {
         this.compen = this.custodisponibilidade
       }
     }

    if (this.ctfp !== 0 && this.ctp !== 0) {
      var grupera = true
      var grupo = 'A'
      var tpdisp =  'Demanda'
      var gruperb = false
      var disponex = this.demanda
    }
    //  CONTINUA GRUPO A
    if (this.ctfp === 0 && this.ctp === 0) {
      var grupo = 'B'
      var tpdisp =  'disponibilidade'
      var grupera = false
      var gruperb = true
      var disponex = this.custodisponibilidade
    }
    this.analise = 'teste';

    if (this.inversorescascata1.length > 0) {
      this.numeroinversores1 = this.inversorescascata1.length;
      this.potenominal = this.potenominal + this.numeroinversores1 * parseFloat(this.potenciainversor1)
    }
    if (this.inversorescascata2.length > 0) {
      this.numeroinversores2 = this.inversorescascata1.length;
      const qtdinversor2 = this.numeroinversores2;
      this.potenominal = this.potenominal + this.numeroinversores2 * parseFloat(this.potenciainversor2)
      this.potenciainversor2 = this.potenciainversor2 + ' kW'
    } else {
      this.numeroinversores2 = '';
      this.potenciainversor2 = '';
    }
    if (this.potenominal < 75) { this.tipousina = 'Microgeração' };
    if (this.potenominal >= 75) { this.tipousina = 'Minigeração' };
  

    if (this.potenominal > ((this.numplacas * this.potenciafvselec) / 1000)) { this.potenominal = (this.numplacas * this.potenciafvselec) / 1000 };
    if (this.potenominal < ((this.numplacas * this.potenciafvselec) / 1000)) { this.potenominal = this.potenominal };
    this.inversoresx = []
    console.log('modeinv2',this.modeinv2)
    if (this.modeinv1 !== '' && this.modeinv1 !==undefined) {
      this.inversoresx.push(
        { 'qinverso': this.numeroinversores1, 'mainverso': this.marcinversor1 + " - " + this.modi[0].modelo, 'poinverso': this.potenciainversor1 }
      )
    }
      if (this.modeinv2 !== '' && this.modeinv2 !==undefined) {
      this.inversoresx.push(
        { 'qinverso': this.numinv2, 'mainverso': this.marcainver2 + " - " + this.modi2[0].modelo, 'poinverso': this.potenciainversor2 }
      )
    }
    var entrafinan = 'R$ 0';
    if(this.finam === true){entrafinan = ((1 - this.percentfinan) * this.custototal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
    console.log(this.inversoresx)
    var retornx = this.service.getproposta({
      token: localStorage.getItem("tokenx"),
      inversores: this.inversoresx,
      images: this.imagev,
      grupo: grupo,
      grupera: grupera,
      gruperb:gruperb,
      disponex:disponex, 
      tpdisp: tpdisp,
      image3: this.imagefinanceiro,
      image2: this.imageindicadores,
      tipousina: this.tipousina,
      nomecliente: this.nomecliente,
      cidadecliente: this.cidadeescolhida,
      estadocliente: this.estadoselecionado,
      consumo: (this.consumoanual/12).toFixed(2),
      potenominal: this.potenominal + " kW",
      potenciapico:
        ((this.numplacas * this.potenciafvselec) / 1000).toFixed(2) + " kWp",
      produano1: this.produacaopiano,
      marcainv1: this.marcinversor1 + "-" + this.modi[0].modelo,
      potinv1: this.potenciainversor1 + " kW",
      qtdinver1: this.numeroinversores1,
      marcainv2: this.marcainver2,
      potinv2: this.potenciainversor2,
      qtdinver2: this.numeroinversores2,
      marcamodu: this.marcamodul,
      potmod: this.potenciafvselec + " Wp",
      qtdmod: this.numplacas,
      analiseconsumo: "fazer",
      equipamentos: "R$ " + this.custopv,
      maodeobra: "R$ " + this.customo,
      projeto: "R$ " + this.custoproj,
      parcelasfinanciamento: "R$ " + this.parcelafinanciamento.toFixed(2),
      valortotal: this.custototal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      capitalproprio: "R$ " + this.custototal * (1 - this.percentfinan / 100),
      parcelamentocapital: "fazer",
      financiamento: "R$ " + this.valorfinan.toFixed(2),
      periodofinaciamento: this.periodofinan + " Anos",
      taxajuros: this.taxafinan + " %",
      tma: this.taxjuros + " %",
      tarifaenergia: "R$ " + this.tarenergia + " kWh",
      disponibilidade: this.custodisponibilidade.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      conta: "R$ " + this.conta,
      compen: this.compen.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      economia: "R$ " + this.economia,
      periodo: this.payback.toFixed(2) + " Anos",
      retorno: "RS " + this.retorno.toFixed(2),
      nomeresponsavel: this.responsavel,
      PARCEIRO: this.nomeempresa,
      endereco: "insira seu endereço",
      fone: this.telefonexempresa,
      email: this.emailxempresa,
      site: "Digite seu site",
      ROI: this.valorroi + " %",
      TIR: this.valortir + " %",
      radmed: this.radiacaoescolhida,
      id: this.idempresa,
      p1: this.EprodAnox[0],
      p2: this.EprodAnox[1],
      p3: this.EprodAnox[2],
      p4: this.EprodAnox[3],
      p5: this.EprodAnox[4],
      p6: this.EprodAnox[5],
      p7: this.EprodAnox[6],
      p8: this.EprodAnox[7],
      p9: this.EprodAnox[8],
      p10: this.EprodAnox[9],
      p11: this.EprodAnox[10],
      p12: this.EprodAnox[11],
      p13: this.EprodAnox[12],
      p14: this.EprodAnox[13],
      p15: this.EprodAnox[14],
      p16: this.EprodAnox[15],
      p17: this.EprodAnox[16],
      p18: this.EprodAnox[17],
      p19: this.EprodAnox[18],
      p20: this.EprodAnox[19],
      p21: this.EprodAnox[20],
      p22: this.EprodAnox[21],
      p23: this.EprodAnox[22],
      p24: this.EprodAnox[23],
      p25: this.EprodAnox[24],
      c1: this.EaccumAnox[0],
      c2: this.EaccumAnox[1],
      c3: this.EaccumAnox[2],
      c4: this.EaccumAnox[3],
      c5: this.EaccumAnox[4],
      c6: this.EaccumAnox[5],
      c7: this.EaccumAnox[6],
      c8: this.EaccumAnox[7],
      c9: this.EaccumAnox[8],
      c10: this.EaccumAnox[9],
      c11: this.EaccumAnox[10],
      c12: this.EaccumAnox[11],
      c13: this.EaccumAnox[12],
      c14: this.EaccumAnox[13],
      c15: this.EaccumAnox[14],
      c16: this.EaccumAnox[15],
      c17: this.EaccumAnox[16],
      c18: this.EaccumAnox[17],
      c19: this.EaccumAnox[18],
      c20: this.EaccumAnox[19],
      c21: this.EaccumAnox[20],
      c22: this.EaccumAnox[21],
      c23: this.EaccumAnox[22],
      c24: this.EaccumAnox[23],
      c25: this.EaccumAnox[24],
      r1: this.Receitax[0],
      r2: this.Receitax[1],
      r3: this.Receitax[2],
      r4: this.Receitax[3],
      r5: this.Receitax[4],
      r6: this.Receitax[5],
      r7: this.Receitax[6],
      r8: this.Receitax[7],
      r9: this.Receitax[8],
      r10: this.Receitax[9],
      r11: this.Receitax[10],
      r12: this.Receitax[11],
      r13: this.Receitax[12],
      r14: this.Receitax[13],
      r15: this.Receitax[14],
      r16: this.Receitax[15],
      r17: this.Receitax[16],
      r18: this.Receitax[17],
      r19: this.Receitax[18],
      r20: this.Receitax[19],
      r21: this.Receitax[20],
      r22: this.Receitax[21],
      r23: this.Receitax[22],
      r24: this.Receitax[23],
      r25: this.Receitax[24],
      d1: this.disponibilex[0],
      d2: this.disponibilex[1],
      d3: this.disponibilex[2],
      d4: this.disponibilex[3],
      d5: this.disponibilex[4],
      d6: this.disponibilex[5],
      d7: this.disponibilex[6],
      d8: this.disponibilex[7],
      d9: this.disponibilex[8],
      d10: this.disponibilex[9],
      d11: this.disponibilex[10],
      d12: this.disponibilex[11],
      d13: this.disponibilex[12],
      d14: this.disponibilex[13],
      d15: this.disponibilex[14],
      d16: this.disponibilex[15],
      d17: this.disponibilex[16],
      d18: this.disponibilex[17],
      d19: this.disponibilex[18],
      d20: this.disponibilex[19],
      d21: this.disponibilex[20],
      d22: this.disponibilex[21],
      d23: this.disponibilex[22],
      d24: this.disponibilex[23],
      d25: this.disponibilex[24],
      f1: this.FluxoCaixax[0],
      f2: this.FluxoCaixax[1],
      f3: this.FluxoCaixax[2],
      f4: this.FluxoCaixax[3],
      f5: this.FluxoCaixax[4],
      f6: this.FluxoCaixax[5],
      f7: this.FluxoCaixax[6],
      f8: this.FluxoCaixax[7],
      f9: this.FluxoCaixax[8],
      f10: this.FluxoCaixax[9],
      f11: this.FluxoCaixax[10],
      f12: this.FluxoCaixax[11],
      f13: this.FluxoCaixax[12],
      f14: this.FluxoCaixax[13],
      f15: this.FluxoCaixax[14],
      f16: this.FluxoCaixax[15],
      f17: this.FluxoCaixax[16],
      f18: this.FluxoCaixax[17],
      f19: this.FluxoCaixax[18],
      f20: this.FluxoCaixax[19],
      f21: this.FluxoCaixax[20],
      f22: this.FluxoCaixax[21],
      f23: this.FluxoCaixax[22],
      f24: this.FluxoCaixax[23],
      f25: this.FluxoCaixax[24],
      m1: this.rendimentosacux[0],
      m2: this.rendimentosacux[1],
      m3: this.rendimentosacux[2],
      m4: this.rendimentosacux[3],
      m5: this.rendimentosacux[4],
      m6: this.rendimentosacux[5],
      m7: this.rendimentosacux[6],
      m8: this.rendimentosacux[7],
      m9: this.rendimentosacux[8],
      m10: this.rendimentosacux[9],
      m11: this.rendimentosacux[10],
      m12: this.rendimentosacux[11],
      m13: this.rendimentosacux[12],
      m14: this.rendimentosacux[13],
      m15: this.rendimentosacux[14],
      m16: this.rendimentosacux[15],
      m17: this.rendimentosacux[16],
      m18: this.rendimentosacux[17],
      m19: this.rendimentosacux[18],
      m20: this.rendimentosacux[19],
      m21: this.rendimentosacux[20],
      m22: this.rendimentosacux[21],
      m23: this.rendimentosacux[22],
      m24: this.rendimentosacux[23],
      m25: this.rendimentosacux[24],
      v1: this.VPLx[0],
      v2: this.VPLx[1],
      v3: this.VPLx[2],
      v4: this.VPLx[3],
      v5: this.VPLx[4],
      v6: this.VPLx[5],
      v7: this.VPLx[6],
      v8: this.VPLx[7],
      v9: this.VPLx[8],
      v10: this.VPLx[9],
      v11: this.VPLx[10],
      v12: this.VPLx[11],
      v13: this.VPLx[12],
      v14: this.VPLx[13],
      v15: this.VPLx[14],
      v16: this.VPLx[15],
      v17: this.VPLx[16],
      v18: this.VPLx[17],
      v19: this.VPLx[18],
      v20: this.VPLx[19],
      v21: this.VPLx[20],
      v22: this.VPLx[21],
      v23: this.VPLx[22],
      v24: this.VPLx[23],
      v25: this.VPLx[24],
      valorfinan: (this.numparcelas * this.parcelafinanciamento).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      mesesfinan: this.numparcelas,
      parfinan: this.parcelafinanciamento.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      entradafinan: entrafinan,
      tpestrutura: this.tpestrutura,
      descontoavista: this.descontoavista,
      formapaga: this.formapaga,
      prazoentrega: this.prazoentrega,
      valorcomdesconto: ((1 - parseFloat(this.descontoavista) / 100) * this.custototal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    });
    this.progress = 0;
    setInterval(() => {
      if (this.progress < 100) {
        console.log(this.progress);
        this.progress = this.progress + 1;
        // tslint:disable-next-line: align
      }
      if (this.progress === 100) {
        clearInterval();
        if (retornx === true) {
          this.mostrabuton = true;
          this.salvapropostax()
        }
      }
    }, 100);
  }

  rota() { this.router.navigateByUrl('/gestaoclientes'); }
  handleFileSelect(evt) {
    const files = evt.files;
    const file = files[0];
    if (files && file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  atualizacar() {
    this.service.changeCarteira(parseFloat(this.minhacarteira['usoproposta']) - 1);
  }



  puxacarteira() {
    this.service.mycarteira({ ID: this.idempresa })
      .subscribe(dados => {
        if (dados !== this.minhacarteira) {
          this.minhacarteira = dados;
          this.creditoproposta = this.minhacarteira['creditoproposta'];
          this.usoproposta = this.minhacarteira['usoproposta'];
          console.log(this.minhacarteira['usoproposta']);
          if (this.usoproposta === this.creditoproposta) {
            this.desativadow = false;
            this.aceitou = false;
            this.semcredito = true;
            this.atualizacar();
          } else {
            this.desativadow = true;
          }
        }
      });
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }
  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }
  usoprojeto(usoprojeto: any) {
    throw new Error("Method not implemented.");
  }

  //////////////////////////////////////////////////////////
  //  BIG FUNCTION RELECO GRUPO A
  //////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////
  //  BIG FUNCTION RELECO GRUPO A
  //////////////////////////////////////////////////////////
  zeraindcesa() {
    this.ANOS = [];
    this.dias = [];
    this.EprodAno = [];
    this.EaccumAno = [];
    this.EconsumAno = [];
    this.Receita = [];
    this.EconoSimuAno = [];
    this.EconoCompAno = [];
    this.JurosFinan = [];
    this.JurosAtivosAno = [];
    this.FluxoCaixadesc = [];
    this.saidasAno = [];
    this.CustoDisp = [];
    this.CustosAnu = [];
    this.DespExtra = [];
    this.rendimentosacu = [];
    this.ParcelaFinan = [];
    this.capital = [];
    this.FluxoCaixa = [];
    this.FluxoCaixaacc = [];
    this.CapProprio = [];
    this.MonteEco = [];
    this.Taxarend = [];
    this.VPL = [];
    this.TIR = [];
    this.descontado = 0;
  }
  calcprodsa() {
    this.EprodAno.push(+(this.gerjan + this.gerfev + this.germar + this.gerabr + this.germai + this.gerjun + this.gerjul + this.gerago + this.gerset + this.gerout + this.gernov + this.gerdez).toFixed(2))
    this.produacaopiano = +(this.gerjan + this.gerfev + this.germar + this.gerabr + this.germai + this.gerjun + this.gerjul + this.gerago + this.gerset + this.gerout + this.gernov + this.gerdez).toFixed(2) + 'kWh';
    this.EaccumAno.push(+(this.gerjan + this.gerfev + this.germar + this.gerabr + this.germai + this.gerjun + this.gerjul + this.gerago + this.gerset + this.gerout + this.gernov + this.gerdez - this.consumoanual).toFixed(2));
  }

  indicefinana() {
    if (this.parcelacarencias === 0) {
      this.saldodevedor = [];
      this.jurosmes = [];
      this.valoramortizado = [];
      this.jurosano = [];
      var taxjur = (this.taxafinan) / 12;
      console.log('taxajuros', taxjur)
      var relac = this.periodofinan * -1 * 12;
      this.numparcelas = this.periodofinan * 12
      var relacao1 = Math.pow((1 + taxjur), this.numparcelas);
      console.log('relacao1', relacao1)
      var relacao2 = relacao1 - 1;
      console.log('relacao2', relacao2)
      console.log('percentfinan', this.percentfinan)
      this.valorfinan = (this.percentfinan) * (this.custototal) * relacao1;
      var valorparcela = this.valorfinan * (taxjur / relacao2)
      console.log('valorparcela', valorparcela)
      for (let s = 0; s < 25; s++) {
        if (s < this.periodofinan) {
          this.parcelasfinan = this.periodofinan * 12;
          console.log('parcelasfinan', this.parcelasfinan)
          this.ParcelaFinan.push(valorparcela * 12);
          this.parcelafinanciamento = this.ParcelaFinan[0] / 12;
          console.log('parcelafinanciamento', this.parcelafinanciamento)
        }
        if (s >= this.periodofinan) {
          this.ParcelaFinan.push(0);
        }
      }
      for (let p = 0; p < this.numparcelas; p++) {
        var jurmex = 0
        if (p === 0) {
          var jurmex = this.custototal * (this.taxafinan / 12);
          this.valoramortizado.push(this.parcelafinanciamento - jurmex)
          var sad = this.custototal - this.valoramortizado[0]
          this.saldodevedor.push(sad)
          this.jurosmes.push(this.custototal * (this.taxafinan / 12))
        }
        if (p > 0) {
          var jurmex = (this.saldodevedor[p - 1]) * (this.taxafinan / 12);
          this.valoramortizado.push(this.parcelafinanciamento - jurmex)
          var sad = this.saldodevedor[p - 1] - this.valoramortizado[p]
          this.saldodevedor.push(sad)
          this.jurosmes.push(this.saldodevedor[p - 1] * (this.taxafinan / 12))
        }
      }
      var jurostotalano = 0
      console.log('saldodevedor', this.saldodevedor)
      console.log('valoramortizado', this.valoramortizado)
      console.log('jurosmes', this.jurosmes)
      for (let n = 0; n < this.periodofinan; n++) {
        jurostotalano = 0;
        for (let s = 12 * n; s < 12 * (n + 1); s++) {
          jurostotalano = this.jurosmes[s] + jurostotalano
        }
        this.jurosano.push(jurostotalano)
      }
      console.log('jurosano', this.jurosano)
      for (let s = this.periodofinan - 1; s < 25; s++) {
        this.jurosano.push(0)
      }
    }
    if (this.parcelacarencias > 0) {
      this.saldodevedor = [];
      this.jurosmes = [];
      this.valoramortizado = [];
      this.jurosano = [];
      var taxjur = (this.taxafinan) / 12;
      console.log('taxajuros', taxjur)
      var relac = this.periodofinan * -1 * 12;
      this.numparcelas = this.periodofinan * 12
      var relacao1 = Math.pow((1 + taxjur), this.numparcelas);
      console.log('relacao1', relacao1)
      var relacao2 = relacao1 - 1;
      console.log('relacao2', relacao2)
      console.log('percentfinan', this.percentfinan)
      this.valorfinan = (this.percentfinan) * (this.custototal * Math.pow((1 + taxjur), this.parcelacarencias)) * relacao1;
      var valorparcela = this.valorfinan * (taxjur / relacao2)
      console.log('valorparcela', valorparcela)
      for (let s = 0; s < 25; s++) {
        if (s < this.periodofinan) {
          this.parcelasfinan = this.periodofinan * 12;
          console.log('parcelasfinan', this.parcelasfinan)
          this.ParcelaFinan.push(valorparcela * 12);
          this.parcelafinanciamento = this.ParcelaFinan[0] / 12;
          console.log('parcelafinanciamento', this.parcelafinanciamento)
        }
        if (s >= this.periodofinan) {
          this.ParcelaFinan.push(0);
        }
      }
      for (let p = 0; p < this.numparcelas; p++) {
        var jurmex = 0
        if (p === 0) {
          var jurmex = this.custototal * (this.taxafinan / 12);
          this.valoramortizado.push(this.parcelafinanciamento - jurmex)
          var sad = this.custototal - this.valoramortizado[0]
          this.saldodevedor.push(sad)
          this.jurosmes.push(this.custototal * (this.taxafinan / 12))
        }
        if (p > 0) {
          var jurmex = (this.saldodevedor[p - 1]) * (this.taxafinan / 12);
          this.valoramortizado.push(this.parcelafinanciamento - jurmex)
          var sad = this.saldodevedor[p - 1] - this.valoramortizado[p]
          this.saldodevedor.push(sad)
          this.jurosmes.push(this.saldodevedor[p - 1] * (this.taxafinan / 12))
        }
      }
      var jurostotalano = 0
      console.log('saldodevedor', this.saldodevedor)
      console.log('valoramortizado', this.valoramortizado)
      console.log('jurosmes', this.jurosmes)
      for (let n = 0; n < this.periodofinan; n++) {
        jurostotalano = 0;
        for (let s = 12 * n; s < 12 * (n + 1); s++) {
          jurostotalano = this.jurosmes[s] + jurostotalano
        }
        this.jurosano.push(jurostotalano)
      }
      console.log('jurosano', this.jurosano)
      for (let s = this.periodofinan - 1; s < 25; s++) {
        this.jurosano.push(0)
      }
    }
  }

  nreleca() {

    //  CONTINUA GRUPO A
    this.zeraindcesa();
    this.depreciacao = this.depreciacao;
    this.ANOS.push(1);
    this.calcprodsa();
    this.indicefinana();
    console.log('this.EprodAno', this.EprodAno)
    console.log('this.EaccumAno', this.EaccumAno)
    this.disponibile.push(this.custodisponibilidade * 12 * this.tarenergiaforaponta);

    // calculos rendimento com financiamento primeiro ano
    if (this.finam === true) {
      let flux = 0;
      // tslint:disable-next-line: prefer-for-of
      for (let n = 0; n < this.ParcelaFinan.length; n++) {
        flux = flux + this.ParcelaFinan[n];
      }
      //this.custototal = (flux );
      console.log('percentfinan', this.custototal)
      // Se o produzido é maior que consumo
      if (this.EprodAno[0] >= this.consumoanual) {
        this.Receita.push(this.consumoanual * this.tarenergiaforaponta); // Consumo multiplicado pela tarifa
        this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 ); // soma das receitas
        this.FluxoCaixa.push(this.MonteEco[0] - this.ParcelaFinan[0]); //Receita menos disponibilidade - parcela finan
        this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0])); // Capital  menos fluxo de caixa
        this.JurosAtivosAno.push(0);
        this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
        //const ret = (this.FluxoCaixadesc[0]);this.jurosano
        this.VPL.push(this.FluxoCaixaacc[0] - this.jurosano[0]); // VPL anterior - fluxo de caixa
        this.rendimentosacu.push(this.FluxoCaixa[0]); // Montante
        this.saidasAno.push((this.custodisponibilidade * 12 ) + this.ParcelaFinan[0])
      }
      // Se o produzido é menor que consumo
      if (this.EprodAno[0] < this.consumoanual) {
        this.descontado = this.EaccumAno[0] // pega saldo de credito do mes anterior
        if ((this.EprodAno[0] + this.descontado) >= this.consumoanual) { // se geracao atual mais credito anterior maior que consumo
          this.EaccumAno[0] = this.EprodAno[0] + this.descontado - this.consumoanual;
          this.Receita.push(this.consumoanual * this.tarenergiaforaponta);
          this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 );
          this.FluxoCaixa.push(this.MonteEco[0] - this.ParcelaFinan[0]);
          this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0]));
          this.JurosAtivosAno.push(0);
          this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
          this.VPL.push(this.FluxoCaixaacc[0] - this.jurosano[0]); // VPL anterior - fluxo de caixa
          this.rendimentosacu.push(this.FluxoCaixa[0]);
          this.saidasAno.push((this.custodisponibilidade * 12) + this.ParcelaFinan[0])

        }
        if ((this.EprodAno[0] + this.descontado) < this.consumoanual) { // se geracao atual mais credito anterior maior que consumo
          this.Receita.push(this.EprodAno[0] * this.tarenergiaforaponta);
          this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 );
          this.FluxoCaixa.push(this.MonteEco[0] - this.ParcelaFinan[0]);
          this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0]));
          this.JurosAtivosAno.push(0);
          this.EaccumAno[0] = 0;
          this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
          this.VPL.push(this.FluxoCaixaacc[0] - this.jurosano[0]); // VPL anterior - fluxo de caixa
          this.rendimentosacu.push(this.FluxoCaixadesc[0]);
          this.saidasAno.push((this.custodisponibilidade * 12) + this.ParcelaFinan[0])

        }
      }
    }

    // calculos rendimento sem financiamento primeiro ano
    if (this.finam === false) {
      // Se o produzido é maior que consumo
      if (this.EprodAno[0] >= this.consumoanual) {
        this.Receita.push(this.consumoanual * this.tarenergiaforaponta); // Consumo multiplicado pela tarifa
        this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12); // soma das receitas
        this.FluxoCaixa.push(this.MonteEco[0]); //Receita menos disponibilidade 
        this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0])); // Capital  menos fluxo de caixa
        this.JurosAtivosAno.push(0);
        this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
        //const ret = (this.FluxoCaixadesc[0]);
        this.VPL.push(this.FluxoCaixaacc[0]); // VPL anterior - fluxo de caixa
        this.rendimentosacu.push(this.FluxoCaixa[0]); // Montante
        this.saidasAno.push(this.custodisponibilidade * 12 )
      }
      // Se o produzido é menor que consumo
      if (this.EprodAno[0] < this.consumoanual) {
        this.descontado = this.EaccumAno[0] // pega saldo de credito do mes anterior
        if ((this.EprodAno[0] + this.descontado) >= this.consumoanual) { // se geracao atual mais credito anterior maior que consumo
          this.EaccumAno[0] = this.EprodAno[0] + this.descontado - this.consumoanual;
          this.Receita.push(this.consumoanual * this.tarenergiaforaponta);
          this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12);
          this.FluxoCaixa.push(this.MonteEco[0]);
          this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0]));
          this.JurosAtivosAno.push(0);
          this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
          this.VPL.push(this.FluxoCaixaacc[0]); // VPL anterior - fluxo de caixa
          this.rendimentosacu.push(this.FluxoCaixa[0]);
          this.saidasAno.push(this.custodisponibilidade * 12)
        }
        if ((this.EprodAno[0] + this.descontado) < this.consumoanual) { // se geracao atual mais credito anterior maior que consumo
          this.Receita.push(this.EprodAno[0] * this.tarenergiaforaponta);
          this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12);
          this.FluxoCaixa.push(this.MonteEco[0]);
          this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0]));
          this.JurosAtivosAno.push(0);
          this.EaccumAno[0] = 0;
          this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
          this.VPL.push(this.FluxoCaixaacc[0]); // VPL anterior - fluxo de caixa
          this.rendimentosacu.push(this.FluxoCaixadesc[0]);
          this.saidasAno.push(this.custodisponibilidade * 12 )
        }
      }
    }
    for (let i = 1; i < 25; i++) {
      this.descontado = 0;
      this.ANOS.push((1 + (i)))
      this.dias = [];
      this.ajan2 = 0;
      this.afev2 = 0;
      this.amar2 = 0;
      this.aabr2 = 0;
      this.amai2 = 0;
      this.ajun2 = 0;
      this.ajul2 = 0;
      this.aago2 = 0;
      this.aset2 = 0;
      this.aout2 = 0;
      this.anov2 = 0;
      this.adez2 = 0;
      this.ajan = 0;
      this.afev = 0;
      this.amar = 0;
      this.aabr = 0;
      this.amai = 0;
      this.ajun = 0;
      this.ajul = 0;
      this.aago = 0;
      this.aset = 0;
      this.aout = 0;
      this.anov = 0;
      this.adez = 0;
      for (let n = 0; n < 12; n++) {
        const diasNoMes = new Date((this.anoatual + (i + 1)), (n + 1), 0).getDate();
        this.dias[n] = [];
        for (let j = 1; j <= diasNoMes; j++) {
          this.dias[n].push(j);
        }
      }


      if (this.semtemperatura === true) {
        if (this.salvexinversor === 'true') {
          for (let o = 0; o < this.numinv1; o++) {
            for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
              this.ajan = this.ajan + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 - this.depreciacao * (i));
              this.afev = this.afev + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 - this.depreciacao * (i));
              this.amar = this.amar + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 - this.depreciacao * (i));
              this.aabr = this.aabr + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 - this.depreciacao * (i));
              this.amai = this.amai + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 - this.depreciacao * (i));
              this.ajun = this.ajun + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 - this.depreciacao * (i));
              this.ajul = this.ajul + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 - this.depreciacao * (i));
              this.aago = this.aago + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 - this.depreciacao * (i));
              this.aset = this.aset + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 - this.depreciacao * (i));
              this.aout = this.aout + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 - this.depreciacao * (i));
              this.anov = this.anov + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 - this.depreciacao * (i));
              this.adez = this.adez + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 - this.depreciacao * (i));
            }
          }
        }
        if (this.salvexinversor === 'false') {
          this.ajan = this.ajan + (this.paramsmod[0].area) * (this.numplacas) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 - this.depreciacao * (i));
          this.afev = this.afev + (this.paramsmod[0].area) * (this.numplacas) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 - this.depreciacao * (i));
          this.amar = this.amar + (this.paramsmod[0].area) * (this.numplacas) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 - this.depreciacao * (i));
          this.aabr = this.aabr + (this.paramsmod[0].area) * (this.numplacas) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 - this.depreciacao * (i));
          this.amai = this.amai + (this.paramsmod[0].area) * (this.numplacas) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 - this.depreciacao * (i));
          this.ajun = this.ajun + (this.paramsmod[0].area) * (this.numplacas) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 - this.depreciacao * (i));
          this.ajul = this.ajul + (this.paramsmod[0].area) * (this.numplacas) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 - this.depreciacao * (i));
          this.aago = this.aago + (this.paramsmod[0].area) * (this.numplacas) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 - this.depreciacao * (i));
          this.aset = this.aset + (this.paramsmod[0].area) * (this.numplacas) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 - this.depreciacao * (i));
          this.aout = this.aout + (this.paramsmod[0].area) * (this.numplacas) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 - this.depreciacao * (i));
          this.anov = this.anov + (this.paramsmod[0].area) * (this.numplacas) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 - this.depreciacao * (i));
          this.adez = this.adez + (this.paramsmod[0].area) * (this.numplacas) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 - this.depreciacao * (i));
        }

        if (this.salvexinversor2 === 'true') {
          if (this.multiploinvesor2 === true) {
            for (let o = 0; o < this.numinv2; o++) {
              for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
                this.ajan2 = this.ajan2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 - this.depreciacao * (i));
                this.afev2 = this.afev2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 - this.depreciacao * (i));
                this.amar2 = this.amar2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 - this.depreciacao * (i));
                this.aabr2 = this.aabr2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 - this.depreciacao * (i));
                this.amai2 = this.amai2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 - this.depreciacao * (i));
                this.ajun2 = this.ajun2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 - this.depreciacao * (i));
                this.ajul2 = this.ajul + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 - this.depreciacao * (i));
                this.aago2 = this.aago2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 - this.depreciacao * (i));
                this.aset2 = this.aset2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 - this.depreciacao * (i));
                this.aout2 = this.aout2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 - this.depreciacao * (i));
                this.anov2 = this.anov2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 - this.depreciacao * (i));
                this.adez2 = this.adez2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 - this.depreciacao * (i));
              }
            }
          }
        }
      }

      if (this.semtemperatura === false) {
        if (this.salvexinversor === 'true') {
          for (let o = 0; o < this.numinv1; o++) {
            for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
              this.ajan = this.ajan + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25)) * (1 - this.depreciacao * (i));
              this.afev = this.afev + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25)) * (1 - this.depreciacao * (i));
              this.amar = this.amar + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25)) * (1 - this.depreciacao * (i));
              this.aabr = this.aabr + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25)) * (1 - this.depreciacao * (i));
              this.amai = this.amai + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25)) * (1 - this.depreciacao * (i));
              this.ajun = this.ajun + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25)) * (1 - this.depreciacao * (i));
              this.ajul = this.ajul + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25)) * (1 - this.depreciacao * (i));
              this.aago = this.aago + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25)) * (1 - this.depreciacao * (i));
              this.aset = this.aset + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25)) * (1 - this.depreciacao * (i));
              this.aout = this.aout + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25)) * (1 - this.depreciacao * (i));
              this.anov = this.anov + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25)) * (1 - this.depreciacao * (i));
              this.adez = this.adez + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25)) * (1 - this.depreciacao * (i));
            }
          }
        }
        if (this.salvexinversor === 'false') {
          this.ajan = this.ajan + (this.paramsmod[0].area) * (this.numplacas) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25)) * (1 - this.depreciacao * (i));
          this.afev = this.afev + (this.paramsmod[0].area) * (this.numplacas) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25)) * (1 - this.depreciacao * (i));
          this.amar = this.amar + (this.paramsmod[0].area) * (this.numplacas) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25)) * (1 - this.depreciacao * (i));
          this.aabr = this.aabr + (this.paramsmod[0].area) * (this.numplacas) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25)) * (1 - this.depreciacao * (i));
          this.amai = this.amai + (this.paramsmod[0].area) * (this.numplacas) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25)) * (1 - this.depreciacao * (i));
          this.ajun = this.ajun + (this.paramsmod[0].area) * (this.numplacas) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25)) * (1 - this.depreciacao * (i));
          this.ajul = this.ajul + (this.paramsmod[0].area) * (this.numplacas) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25)) * (1 - this.depreciacao * (i));
          this.aago = this.aago + (this.paramsmod[0].area) * (this.numplacas) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25)) * (1 - this.depreciacao * (i));
          this.aset = this.aset + (this.paramsmod[0].area) * (this.numplacas) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25)) * (1 - this.depreciacao * (i));
          this.aout = this.aout + (this.paramsmod[0].area) * (this.numplacas) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25)) * (1 - this.depreciacao * (i));
          this.anov = this.anov + (this.paramsmod[0].area) * (this.numplacas) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25)) * (1 - this.depreciacao * (i));
          this.adez = this.adez + (this.paramsmod[0].area) * (this.numplacas) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25)) * (1 - this.depreciacao * (i));
        }
        if (this.multiploinvesor2 === true) {
          for (let o = 0; o < this.numinv2; o++) {
            for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
              this.ajan2 = this.ajan2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25)) * (1 - this.depreciacao * (i));
              this.afev2 = this.afev2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25)) * (1 - this.depreciacao * (i));
              this.amar2 = this.amar2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25)) * (1 - this.depreciacao * (i));
              this.aabr2 = this.aabr2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25)) * (1 - this.depreciacao * (i));
              this.amai2 = this.amai2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25)) * (1 - this.depreciacao * (i));
              this.ajun2 = this.ajun2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25)) * (1 - this.depreciacao * (i));
              this.ajul2 = this.ajul2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25)) * (1 - this.depreciacao * (i));
              this.aago2 = this.aago2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25)) * (1 - this.depreciacao * (i));
              this.aset2 = this.aset2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25)) * (1 - this.depreciacao * (i));
              this.aout2 = this.aout2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25)) * (1 - this.depreciacao * (i));
              this.anov2 = this.anov2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25)) * (1 - this.depreciacao * (i));
              this.adez2 = this.adez2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25)) * (1 - this.depreciacao * (i));
            }
          }
        }
      }
      this.EprodAno.push(
        +(this.ajan + this.ajan2 +
          this.afev + this.afev2 +
          this.amar + this.amar2 +
          this.aabr + this.aabr2 +
          this.amai + this.amai2 +
          this.ajun + this.ajun2 +
          this.ajul + this.ajul2 +
          this.aago + this.aago2 +
          this.aset + this.aset2 +
          this.aout + this.aout2 +
          this.anov + this.anov2 +
          this.adez + this.adez2).toFixed(2));
      const acc = (+(this.ajan + this.ajan2 +
        this.afev + this.afev2 +
        this.amar + this.amar2 +
        this.aabr + this.aabr2 +
        this.amai + this.amai2 +
        this.ajun + this.ajun2 +
        this.ajul + this.ajul2 +
        this.aago + this.aago2 +
        this.aset + this.aset2 +
        this.aout + this.aout2 +
        this.anov + this.anov2 +
        this.adez + this.adez2) - this.consumoanual).toFixed(2);
      const ainx = i - 1;
      this.custocordisp = this.custodisponibilidade * 12  * (1 + (this.reajtar * i))
      this.disponibile.push(+this.custocordisp.toFixed(2));
      if (parseFloat(acc) > 0) {
        if (i > 0) {
          this.EaccumAno.push(+(parseFloat(acc) + this.EaccumAno[ainx]).toFixed(2))
        } else {
          this.EaccumAno.push((parseFloat(acc)))
        }
      }
      if (parseFloat(acc) < 0) {
        this.EaccumAno.push(0);
      }
      this.descontado = this.EaccumAno[ainx];
      // calculos rendimento sem financiamento após primeiro Ano
      if (this.finam === false) {
        if (this.EprodAno[i] >= this.consumoanual) {
          const indiceant = i - 1;
          const novareceitacor = + ((1 + (this.reajtar * i)) * this.consumoanual * this.tarenergiaforaponta).toFixed(2);
          this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros)); //  considera juros receita anteriores
          const receitaatual = +(this.Receita[i]).toFixed(2);
          this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2)); //  receita anterior + atual com juros - disponibilidade
          this.FluxoCaixa.push(+(receitaatual - this.custocordisp).toFixed(2)); // receita atual - disponibilidade
          this.FluxoCaixaacc.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
          this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
          this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1 + (this.taxjuros)), i + 1)).toFixed(2)));
          const ret = this.VPL[indiceant] + receitaatual - this.custocordisp
          this.VPL.push(ret);
          this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
          this.saidasAno.push(this.custocordisp)
        }
        // Se a geracao menor que consumo
        if (this.EprodAno[i] < this.consumoanual) {
          if (this.EprodAno[i] + this.descontado >= this.consumoanual) {
            const indiceant = i - 1;
            this.EaccumAno[i] = this.EprodAno[i] + this.descontado - this.consumoanual;
            const novareceitacor = + ((1 + (this.reajtar * i)) * this.consumoanual * this.tarenergiaforaponta).toFixed(2);
            const montantecor = + (this.MonteEco[indiceant] * (this.taxjuros)).toFixed(2);
            this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
            const receitaatual = +(this.Receita[i]).toFixed(2);
            this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
            this.FluxoCaixa.push(+(receitaatual - this.custocordisp).toFixed(2));
            this.FluxoCaixaacc.push((+(this.FluxoCaixaacc[indiceant] + receitaatual - this.custocordisp).toFixed(2)));
            this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
            this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1.0 + (this.taxjuros)), i + 1)).toFixed(2)));
            const ret = this.VPL[indiceant] + receitaatual - this.custocordisp
            this.VPL.push(ret);
            this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
            this.saidasAno.push(this.custocordisp)
          }
          if (this.EprodAno[i] + this.descontado < this.consumoanual) {
            const indiceant = i - 1;
            // tslint:disable-next-line: max-line-length
            const reajuste = (1 + (this.reajtar * i));
            this.EaccumAno[i] = 0;
            const novareceitacor = + (reajuste * (this.EprodAno[i] + this.descontado) * this.tarenergiaforaponta).toFixed(2);
            this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
            const receitaatual = +(this.Receita[i]).toFixed(2);
            this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
            this.FluxoCaixa.push(+(receitaatual - this.custocordisp).toFixed(2));
            this.FluxoCaixaacc.push((+(this.FluxoCaixaacc[indiceant] + receitaatual - this.custocordisp).toFixed(2)));
            this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
            const corret = (1.0 + (this.taxjuros))
            const fluxdesc = this.FluxoCaixa[i] / Math.pow(corret, i + 1);
            this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1.0 + (this.taxjuros)), i + 1)).toFixed(2)));
            const ret = this.VPL[indiceant] + receitaatual - this.custocordisp
            this.VPL.push(ret);
            this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
            this.saidasAno.push(this.custocordisp)
          }
        }
      }

      // calculos rendimento com financiamento
      if (this.finam === true) {
        if (this.EprodAno[i] >= this.consumoanual) {
          const indiceant = i - 1;
          const novareceitacor = + ((1 + (this.reajtar * i)) * this.consumoanual * this.tarenergiaforaponta).toFixed(2);
          this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros)); //  considera juros receita anteriores
          const receitaatual = +(this.Receita[i]).toFixed(2);
          this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2)); //  receita anterior + atual com juros - disponibilidade
          this.FluxoCaixa.push(+(receitaatual - this.custocordisp - this.ParcelaFinan[i]).toFixed(2)); // receita atual - disponibilidade
          this.FluxoCaixaacc.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
          this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
          this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1 + (this.taxjuros)), i + 1)).toFixed(2)));
          const ret = this.VPL[indiceant] + receitaatual - this.custocordisp - this.jurosano[i]
          this.VPL.push(ret);
          this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
          this.saidasAno.push(this.custocordisp + this.ParcelaFinan[i])
        }
        // Se a geracao menor que consumo
        if (this.EprodAno[i] < this.consumoanual) {
          if (this.EprodAno[i] + this.descontado >= this.consumoanual) {
            const indiceant = i - 1;
            this.EaccumAno[i] = this.EprodAno[i] + this.descontado - this.consumoanual;
            const novareceitacor = + ((1 + (this.reajtar * i)) * this.consumoanual * this.tarenergiaforaponta).toFixed(2);
            const montantecor = + (this.MonteEco[indiceant] * (this.taxjuros)).toFixed(2);
            this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
            const receitaatual = +(this.Receita[i]).toFixed(2);
            this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
            this.FluxoCaixa.push(+(receitaatual - this.custocordisp - this.ParcelaFinan[i]).toFixed(2));
            this.FluxoCaixaacc.push((+(this.FluxoCaixaacc[indiceant] + receitaatual - this.custocordisp).toFixed(2)));
            this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
            this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1.0 + (this.taxjuros)), i + 1)).toFixed(2)));
            const ret = this.VPL[indiceant] + receitaatual - this.custocordisp - this.jurosano[i]
            this.VPL.push(ret);
            this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
            this.saidasAno.push(this.custocordisp + this.ParcelaFinan[i])
          }
          if (this.EprodAno[i] + this.descontado < this.consumoanual) {
            const indiceant = i - 1;
            // tslint:disable-next-line: max-line-length
            const reajuste = (1 + (this.reajtar * i));
            this.EaccumAno[i] = 0;
            const novareceitacor = + (reajuste * (this.EprodAno[i] + this.descontado) * this.tarenergiaforaponta).toFixed(2);
            this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
            const receitaatual = +(this.Receita[i]).toFixed(2);
            this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
            this.FluxoCaixa.push(+(receitaatual - this.custocordisp - this.ParcelaFinan[i]).toFixed(2));
            this.FluxoCaixaacc.push((+(this.FluxoCaixaacc[indiceant] + receitaatual - this.custocordisp).toFixed(2)));
            this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
            const corret = (1.0 + (this.taxjuros))
            const fluxdesc = this.FluxoCaixa[i] / Math.pow(corret, i + 1);
            this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1.0 + (this.taxjuros)), i + 1)).toFixed(2)));
            const ret = this.VPL[indiceant] + receitaatual - this.custocordisp - this.jurosano[i]
            this.VPL.push(ret);
            this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
            this.saidasAno.push(this.custocordisp + this.ParcelaFinan[i])
          }
        }
      }
      for (let n = 0; n < 12; n++) {
        // tslint:disable-next-line: one-variable-per-declaration
        this.dias.pop();
      }
    }
    var cfw: Number[] = [];
    if (this.finam === false) {
      this.gasto = this.custototal * -1;
      cfw.push(this.custototal * -1);
    }
    console.log('gastos', this.gasto)

    if (this.finam === true) {
      let flux = 0;
      // tslint:disable-next-line: prefer-for-of
      for (let n = 0; n < this.ParcelaFinan.length; n++) {
        flux = flux + this.ParcelaFinan[n];
      }
      this.gasto = (flux + (this.custototal * (1 - this.percentfinan))) * -1;
      console.log('percentfinan', this.percentfinan)

      cfw.push(this.gasto);
    }
    //console.log('gastos', this.custototal)

    this.custoroi = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let x = 0; x < this.VPL.length; x++) {
      cfw.push(this.VPL[x]);
      this.custoroi = this.custoroi + this.VPL[x];
    }
    console.log(this.VPL)
    this.payback = (this.PP(25, cfw));
    console.log(cfw);
    this.valortir = this.finance.IRR(cfw);
    this.valorroi = this.finance.ROI(this.gasto, this.custoroi);

    this.datafinan = [];
    this.ecopriano = this.EprodAno[0]
    this.data1 = [];
    this.data1.push({ x: 0, y: -1 * this.custototal })

    for (let i = 0; i < 25; i++) {
      this.datafinan.push({
        "ANOS": this.ANOS[i],
        "EprodAno": this.EprodAno[i],
        "EaccumAno": this.EaccumAno[i],
        "Receita": this.Receita[i],
        "disponibile": this.disponibile[i],
        "ParcelaFinan": this.ParcelaFinan[i],
        "saidasAno": this.saidasAno[i],
        "FluxoCaixa": this.FluxoCaixa[i],
        "VPL": this.VPL[i],
        "rendimentosacu": this.rendimentosacu[i]
      })
      this.data1.push({ x: i + 1, y: this.VPL[i] })
      this.ANOSx.push(this.ANOS[i].toFixed(2))
      this.EprodAnox.push(this.EprodAno[i].toFixed(2))
      this.EaccumAnox.push(this.EaccumAno[i].toFixed(2))
      this.Receitax.push(this.Receita[i].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
      this.saidasAnox.push(this.saidasAno[i].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
      this.FluxoCaixax.push(this.FluxoCaixa[i].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
      this.VPLx.push((this.VPL[i]).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
      console.log("VPLx", ((this.VPL[i]).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })))
      this.rendimentosacux.push(this.rendimentosacu[i].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
      this.disponibilex.push(this.disponibile[i].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))

    }
    console.log('data1', this.data1)
  }


  //////////////////////////////////////////////////////////
  //  BIG FUNCTION NRELECO
  //////////////////////////////////////////////////////////
  zeraindces() {
    this.ANOS = [];
    this.dias = [];
    this.EprodAno = [];
    this.EaccumAno = [];
    this.EconsumAno = [];
    this.Receita = [];
    this.EconoSimuAno = [];
    this.EconoCompAno = [];
    this.JurosFinan = [];
    this.JurosAtivosAno = [];
    this.FluxoCaixadesc = [];
    this.saidasAno = [];
    this.CustoDisp = [];
    this.CustosAnu = [];
    this.DespExtra = [];
    this.rendimentosacu = [];
    this.ParcelaFinan = [];
    this.capital = [];
    this.FluxoCaixa = [];
    this.FluxoCaixaacc = [];
    this.CapProprio = [];
    this.MonteEco = [];
    this.Taxarend = [];
    this.VPL = [];
    this.TIR = [];
    this.descontado = 0;
  }
  calcprods() {
    this.EprodAno.push(+(this.gerjan + this.gerfev + this.germar + this.gerabr + this.germai + this.gerjun + this.gerjul + this.gerago + this.gerset + this.gerout + this.gernov + this.gerdez).toFixed(2))
    this.produacaopiano = +(this.gerjan + this.gerfev + this.germar + this.gerabr + this.germai + this.gerjun + this.gerjul + this.gerago + this.gerset + this.gerout + this.gernov + this.gerdez).toFixed(2) + 'kWh';
    this.EaccumAno.push(+(this.gerjan + this.gerfev + this.germar + this.gerabr + this.germai + this.gerjun + this.gerjul + this.gerago + this.gerset + this.gerout + this.gernov + this.gerdez - this.consumoanual).toFixed(2));
  }

  indicefinan() {
    if (this.parcelacarencias === 0) {
      this.saldodevedor = [];
      this.jurosmes = [];
      this.valoramortizado = [];
      this.jurosano = [];
      var taxjur = (this.taxafinan) / 12;
      console.log('taxajuros', taxjur)
      var relac = this.periodofinan * -1 * 12;
      this.numparcelas = this.periodofinan * 12
      var relacao1 = Math.pow((1 + taxjur), this.numparcelas);
      console.log('relacao1', relacao1)
      var relacao2 = relacao1 - 1;
      console.log('relacao2', relacao2)
      console.log('percentfinan', this.percentfinan)
      this.valorfinan = (this.percentfinan) * (this.custototal) * relacao1;
      var valorparcela = this.valorfinan * (taxjur / relacao2)
      console.log('valorparcela', valorparcela)
      for (let s = 0; s < 25; s++) {
        if (s < this.periodofinan) {
          this.parcelasfinan = this.periodofinan * 12;
          console.log('parcelasfinan', this.parcelasfinan)
          this.ParcelaFinan.push(valorparcela * 12);
          this.parcelafinanciamento = this.ParcelaFinan[0] / 12;
          console.log('parcelafinanciamento', this.parcelafinanciamento)
        }
        if (s >= this.periodofinan) {
          this.ParcelaFinan.push(0);
        }
      }
      for (let p = 0; p < this.numparcelas; p++) {
        var jurmex = 0
        if (p === 0) {
          var jurmex = this.custototal * (this.taxafinan / 12);
          this.valoramortizado.push(this.parcelafinanciamento - jurmex)
          var sad = this.custototal - this.valoramortizado[0]
          this.saldodevedor.push(sad)
          this.jurosmes.push(this.custototal * (this.taxafinan / 12))
        }
        if (p > 0) {
          var jurmex = (this.saldodevedor[p - 1]) * (this.taxafinan / 12);
          this.valoramortizado.push(this.parcelafinanciamento - jurmex)
          var sad = this.saldodevedor[p - 1] - this.valoramortizado[p]
          this.saldodevedor.push(sad)
          this.jurosmes.push(this.saldodevedor[p - 1] * (this.taxafinan / 12))
        }
      }
      var jurostotalano = 0
      console.log('saldodevedor', this.saldodevedor)
      console.log('valoramortizado', this.valoramortizado)
      console.log('jurosmes', this.jurosmes)
      for (let n = 0; n < this.periodofinan; n++) {
        jurostotalano = 0;
        for (let s = 12 * n; s < 12 * (n + 1); s++) {
          jurostotalano = this.jurosmes[s] + jurostotalano
        }
        this.jurosano.push(jurostotalano)
      }
      console.log('jurosano', this.jurosano)
      for (let s = this.periodofinan - 1; s < 25; s++) {
        this.jurosano.push(0)
      }
    }
    if (this.parcelacarencias > 0) {
      this.saldodevedor = [];
      this.jurosmes = [];
      this.valoramortizado = [];
      this.jurosano = [];
      var taxjur = (this.taxafinan) / 12;
      console.log('taxajuros', taxjur)
      var relac = this.periodofinan * -1 * 12;
      this.numparcelas = this.periodofinan * 12
      var relacao1 = Math.pow((1 + taxjur), this.numparcelas);
      console.log('relacao1', relacao1)
      var relacao2 = relacao1 - 1;
      console.log('relacao2', relacao2)
      console.log('percentfinan', this.percentfinan)
      this.valorfinan = (this.percentfinan) * (this.custototal * Math.pow((1 + taxjur), this.parcelacarencias)) * relacao1;
      var valorparcela = this.valorfinan * (taxjur / relacao2)
      console.log('valorparcela', valorparcela)
      for (let s = 0; s < 25; s++) {
        if (s < this.periodofinan) {
          this.parcelasfinan = this.periodofinan * 12;
          console.log('parcelasfinan', this.parcelasfinan)
          this.ParcelaFinan.push(valorparcela * 12);
          this.parcelafinanciamento = this.ParcelaFinan[0] / 12;
          console.log('parcelafinanciamento', this.parcelafinanciamento)
        }
        if (s >= this.periodofinan) {
          this.ParcelaFinan.push(0);
        }
      }
      for (let p = 0; p < this.numparcelas; p++) {
        var jurmex = 0
        if (p === 0) {
          var jurmex = this.custototal * (this.taxafinan / 12);
          this.valoramortizado.push(this.parcelafinanciamento - jurmex)
          var sad = this.custototal - this.valoramortizado[0]
          this.saldodevedor.push(sad)
          this.jurosmes.push(this.custototal * (this.taxafinan / 12))
        }
        if (p > 0) {
          var jurmex = (this.saldodevedor[p - 1]) * (this.taxafinan / 12);
          this.valoramortizado.push(this.parcelafinanciamento - jurmex)
          var sad = this.saldodevedor[p - 1] - this.valoramortizado[p]
          this.saldodevedor.push(sad)
          this.jurosmes.push(this.saldodevedor[p - 1] * (this.taxafinan / 12))
        }
      }
      var jurostotalano = 0
      console.log('saldodevedor', this.saldodevedor)
      console.log('valoramortizado', this.valoramortizado)
      console.log('jurosmes', this.jurosmes)
      for (let n = 0; n < this.periodofinan; n++) {
        jurostotalano = 0;
        for (let s = 12 * n; s < 12 * (n + 1); s++) {
          jurostotalano = this.jurosmes[s] + jurostotalano
        }
        this.jurosano.push(jurostotalano)
      }
      console.log('jurosano', this.jurosano)
      for (let s = this.periodofinan - 1; s < 25; s++) {
        this.jurosano.push(0)
      }
    }
  }

  nreleco() {
    //  CHAMA FUNCAO GRUPO A
    if (this.ctfp !== 0 && this.ctp !== 0) {
      console.log('grupoA')
      this.nreleca()
    }
    //  CONTINUA GRUPO A
    if (this.ctfp === 0 && this.ctp === 0) {
      console.log('grupoB')
      this.zeraindces();
      this.depreciacao = this.depreciacao;
      this.ANOS.push(1);
      this.calcprods();
      this.indicefinan();
      console.log('this.EprodAno', this.EprodAno)
      console.log('this.EaccumAno', this.EaccumAno)
      this.disponibile.push(this.custodisponibilidade * 12 * this.tarenergia);

      // calculos rendimento com financiamento primeiro ano
      if (this.finam === true) {
        let flux = 0;
        // tslint:disable-next-line: prefer-for-of
        for (let n = 0; n < this.ParcelaFinan.length; n++) {
          flux = flux + this.ParcelaFinan[n];
        }
        //this.custototal = (flux );
        console.log('percentfinan', this.custototal)
        // Se o produzido é maior que consumo
        if (this.EprodAno[0] >= this.consumoanual) {
          this.Receita.push(this.consumoanual * this.tarenergia); // Consumo multiplicado pela tarifa
          this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 * this.tarenergia); // soma das receitas
          this.FluxoCaixa.push(this.MonteEco[0] - this.ParcelaFinan[0]); //Receita menos disponibilidade - parcela finan
          this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0])); // Capital  menos fluxo de caixa
          this.JurosAtivosAno.push(0);
          this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
          //const ret = (this.FluxoCaixadesc[0]);this.jurosano
          this.VPL.push(this.FluxoCaixaacc[0] - this.jurosano[0]); // VPL anterior - fluxo de caixa
          this.rendimentosacu.push(this.FluxoCaixa[0]); // Montante
          this.saidasAno.push((this.custodisponibilidade * 12 * this.tarenergia) + this.ParcelaFinan[0])
        }
        // Se o produzido é menor que consumo
        if (this.EprodAno[0] < this.consumoanual) {
          this.descontado = this.EaccumAno[0] // pega saldo de credito do mes anterior
          if ((this.EprodAno[0] + this.descontado) >= this.consumoanual) { // se geracao atual mais credito anterior maior que consumo
            this.EaccumAno[0] = this.EprodAno[0] + this.descontado - this.consumoanual;
            this.Receita.push(this.consumoanual * this.tarenergia);
            this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 * this.tarenergia);
            this.FluxoCaixa.push(this.MonteEco[0] - this.ParcelaFinan[0]);
            this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0]));
            this.JurosAtivosAno.push(0);
            this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
            this.VPL.push(this.FluxoCaixaacc[0] - this.jurosano[0]); // VPL anterior - fluxo de caixa
            this.rendimentosacu.push(this.FluxoCaixa[0]);
            this.saidasAno.push((this.custodisponibilidade * 12 * this.tarenergia) + this.ParcelaFinan[0])

          }
          if ((this.EprodAno[0] + this.descontado) < this.consumoanual) { // se geracao atual mais credito anterior maior que consumo
            this.Receita.push(this.EprodAno[0] * this.tarenergia);
            this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 * this.tarenergia);
            this.FluxoCaixa.push(this.MonteEco[0] - this.ParcelaFinan[0]);
            this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0]));
            this.JurosAtivosAno.push(0);
            this.EaccumAno[0] = 0;
            this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
            this.VPL.push(this.FluxoCaixaacc[0] - this.jurosano[0]); // VPL anterior - fluxo de caixa
            this.rendimentosacu.push(this.FluxoCaixadesc[0]);
            this.saidasAno.push((this.custodisponibilidade * 12 * this.tarenergia) + this.ParcelaFinan[0])

          }
        }
      }

      // calculos rendimento sem financiamento primeiro ano
      if (this.finam === false) {
        // Se o produzido é maior que consumo
        if (this.EprodAno[0] >= this.consumoanual) {
          this.Receita.push(this.consumoanual * this.tarenergia); // Consumo multiplicado pela tarifa
          this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 * this.tarenergia); // soma das receitas
          this.FluxoCaixa.push(this.MonteEco[0]); //Receita menos disponibilidade 
          this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0])); // Capital  menos fluxo de caixa
          this.JurosAtivosAno.push(0);
          this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
          //const ret = (this.FluxoCaixadesc[0]);
          this.VPL.push(this.FluxoCaixaacc[0]); // VPL anterior - fluxo de caixa
          this.rendimentosacu.push(this.FluxoCaixa[0]); // Montante
          this.saidasAno.push(this.custodisponibilidade * 12 * this.tarenergia)
        }
        // Se o produzido é menor que consumo
        if (this.EprodAno[0] < this.consumoanual) {
          this.descontado = this.EaccumAno[0] // pega saldo de credito do mes anterior
          if ((this.EprodAno[0] + this.descontado) >= this.consumoanual) { // se geracao atual mais credito anterior maior que consumo
            this.EaccumAno[0] = this.EprodAno[0] + this.descontado - this.consumoanual;
            this.Receita.push(this.consumoanual * this.tarenergia);
            this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 * this.tarenergia);
            this.FluxoCaixa.push(this.MonteEco[0]);
            this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0]));
            this.JurosAtivosAno.push(0);
            this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
            this.VPL.push(this.FluxoCaixaacc[0]); // VPL anterior - fluxo de caixa
            this.rendimentosacu.push(this.FluxoCaixa[0]);
            this.saidasAno.push(this.custodisponibilidade * 12 * this.tarenergia)
          }
          if ((this.EprodAno[0] + this.descontado) < this.consumoanual) { // se geracao atual mais credito anterior maior que consumo
            this.Receita.push(this.EprodAno[0] * this.tarenergia);
            this.MonteEco.push(this.Receita[0] - this.custodisponibilidade * 12 * this.tarenergia);
            this.FluxoCaixa.push(this.MonteEco[0]);
            this.FluxoCaixaacc.push((-1 * this.custototal + this.MonteEco[0]));
            this.JurosAtivosAno.push(0);
            this.EaccumAno[0] = 0;
            this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[0] / Math.pow((1.0 + (this.taxjuros / 100)), 1)).toFixed(2)));
            this.VPL.push(this.FluxoCaixaacc[0]); // VPL anterior - fluxo de caixa
            this.rendimentosacu.push(this.FluxoCaixadesc[0]);
            this.saidasAno.push(this.custodisponibilidade * 12 * this.tarenergia)
          }
        }
      }
      for (let i = 1; i < 25; i++) {
        this.descontado = 0;
        this.ANOS.push((1 + (i)))
        this.dias = [];
        this.ajan2 = 0;
        this.afev2 = 0;
        this.amar2 = 0;
        this.aabr2 = 0;
        this.amai2 = 0;
        this.ajun2 = 0;
        this.ajul2 = 0;
        this.aago2 = 0;
        this.aset2 = 0;
        this.aout2 = 0;
        this.anov2 = 0;
        this.adez2 = 0;
        this.ajan = 0;
        this.afev = 0;
        this.amar = 0;
        this.aabr = 0;
        this.amai = 0;
        this.ajun = 0;
        this.ajul = 0;
        this.aago = 0;
        this.aset = 0;
        this.aout = 0;
        this.anov = 0;
        this.adez = 0;
        for (let n = 0; n < 12; n++) {
          const diasNoMes = new Date((this.anoatual + (i + 1)), (n + 1), 0).getDate();
          this.dias[n] = [];
          for (let j = 1; j <= diasNoMes; j++) {
            this.dias[n].push(j);
          }
        }


        if (this.semtemperatura === true) {
          if (this.salvexinversor === 'true') {
            for (let o = 0; o < this.numinv1; o++) {
              for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
                this.ajan = this.ajan + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 - this.depreciacao * (i));
                this.afev = this.afev + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 - this.depreciacao * (i));
                this.amar = this.amar + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 - this.depreciacao * (i));
                this.aabr = this.aabr + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 - this.depreciacao * (i));
                this.amai = this.amai + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 - this.depreciacao * (i));
                this.ajun = this.ajun + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 - this.depreciacao * (i));
                this.ajul = this.ajul + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 - this.depreciacao * (i));
                this.aago = this.aago + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 - this.depreciacao * (i));
                this.aset = this.aset + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 - this.depreciacao * (i));
                this.aout = this.aout + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 - this.depreciacao * (i));
                this.anov = this.anov + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 - this.depreciacao * (i));
                this.adez = this.adez + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 - this.depreciacao * (i));
              }
            }
          }
          if (this.salvexinversor === 'false') {
            this.ajan = this.ajan + (this.paramsmod[0].area) * (this.numplacas) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 - this.depreciacao * (i));
            this.afev = this.afev + (this.paramsmod[0].area) * (this.numplacas) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 - this.depreciacao * (i));
            this.amar = this.amar + (this.paramsmod[0].area) * (this.numplacas) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 - this.depreciacao * (i));
            this.aabr = this.aabr + (this.paramsmod[0].area) * (this.numplacas) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 - this.depreciacao * (i));
            this.amai = this.amai + (this.paramsmod[0].area) * (this.numplacas) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 - this.depreciacao * (i));
            this.ajun = this.ajun + (this.paramsmod[0].area) * (this.numplacas) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 - this.depreciacao * (i));
            this.ajul = this.ajul + (this.paramsmod[0].area) * (this.numplacas) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 - this.depreciacao * (i));
            this.aago = this.aago + (this.paramsmod[0].area) * (this.numplacas) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 - this.depreciacao * (i));
            this.aset = this.aset + (this.paramsmod[0].area) * (this.numplacas) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 - this.depreciacao * (i));
            this.aout = this.aout + (this.paramsmod[0].area) * (this.numplacas) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 - this.depreciacao * (i));
            this.anov = this.anov + (this.paramsmod[0].area) * (this.numplacas) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 - this.depreciacao * (i));
            this.adez = this.adez + (this.paramsmod[0].area) * (this.numplacas) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 - this.depreciacao * (i));
          }

          if (this.salvexinversor2 === 'true') {
            if (this.multiploinvesor2 === true) {
              for (let o = 0; o < this.numinv2; o++) {
                for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
                  this.ajan2 = this.ajan2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 - this.depreciacao * (i));
                  this.afev2 = this.afev2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 - this.depreciacao * (i));
                  this.amar2 = this.amar2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 - this.depreciacao * (i));
                  this.aabr2 = this.aabr2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 - this.depreciacao * (i));
                  this.amai2 = this.amai2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 - this.depreciacao * (i));
                  this.ajun2 = this.ajun2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 - this.depreciacao * (i));
                  this.ajul2 = this.ajul + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 - this.depreciacao * (i));
                  this.aago2 = this.aago2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 - this.depreciacao * (i));
                  this.aset2 = this.aset2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 - this.depreciacao * (i));
                  this.aout2 = this.aout2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 - this.depreciacao * (i));
                  this.anov2 = this.anov2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 - this.depreciacao * (i));
                  this.adez2 = this.adez2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 - this.depreciacao * (i));
                }
              }
            }
          }
        }

        if (this.semtemperatura === false) {
          if (this.salvexinversor === 'true') {
            for (let o = 0; o < this.numinv1; o++) {
              for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
                this.ajan = this.ajan + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25)) * (1 - this.depreciacao * (i));
                this.afev = this.afev + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25)) * (1 - this.depreciacao * (i));
                this.amar = this.amar + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25)) * (1 - this.depreciacao * (i));
                this.aabr = this.aabr + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25)) * (1 - this.depreciacao * (i));
                this.amai = this.amai + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25)) * (1 - this.depreciacao * (i));
                this.ajun = this.ajun + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25)) * (1 - this.depreciacao * (i));
                this.ajul = this.ajul + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25)) * (1 - this.depreciacao * (i));
                this.aago = this.aago + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25)) * (1 - this.depreciacao * (i));
                this.aset = this.aset + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25)) * (1 - this.depreciacao * (i));
                this.aout = this.aout + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25)) * (1 - this.depreciacao * (i));
                this.anov = this.anov + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25)) * (1 - this.depreciacao * (i));
                this.adez = this.adez + (this.paramsmod[0].area) * (this.inversorescascata1[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25)) * (1 - this.depreciacao * (i));
              }
            }
          }
          if (this.salvexinversor === 'false') {
            this.ajan = this.ajan + (this.paramsmod[0].area) * (this.numplacas) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25)) * (1 - this.depreciacao * (i));
            this.afev = this.afev + (this.paramsmod[0].area) * (this.numplacas) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25)) * (1 - this.depreciacao * (i));
            this.amar = this.amar + (this.paramsmod[0].area) * (this.numplacas) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25)) * (1 - this.depreciacao * (i));
            this.aabr = this.aabr + (this.paramsmod[0].area) * (this.numplacas) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25)) * (1 - this.depreciacao * (i));
            this.amai = this.amai + (this.paramsmod[0].area) * (this.numplacas) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25)) * (1 - this.depreciacao * (i));
            this.ajun = this.ajun + (this.paramsmod[0].area) * (this.numplacas) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25)) * (1 - this.depreciacao * (i));
            this.ajul = this.ajul + (this.paramsmod[0].area) * (this.numplacas) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25)) * (1 - this.depreciacao * (i));
            this.aago = this.aago + (this.paramsmod[0].area) * (this.numplacas) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25)) * (1 - this.depreciacao * (i));
            this.aset = this.aset + (this.paramsmod[0].area) * (this.numplacas) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25)) * (1 - this.depreciacao * (i));
            this.aout = this.aout + (this.paramsmod[0].area) * (this.numplacas) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25)) * (1 - this.depreciacao * (i));
            this.anov = this.anov + (this.paramsmod[0].area) * (this.numplacas) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25)) * (1 - this.depreciacao * (i));
            this.adez = this.adez + (this.paramsmod[0].area) * (this.numplacas) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25)) * (1 - this.depreciacao * (i));
          }
          if (this.multiploinvesor2 === true) {
            for (let o = 0; o < this.numinv2; o++) {
              for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
                this.ajan2 = this.ajan2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[0].length * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25)) * (1 - this.depreciacao * (i));
                this.afev2 = this.afev2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[1].length * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25)) * (1 - this.depreciacao * (i));
                this.amar2 = this.amar2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[2].length * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25)) * (1 - this.depreciacao * (i));
                this.aabr2 = this.aabr2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[3].length * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25)) * (1 - this.depreciacao * (i));
                this.amai2 = this.amai2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[4].length * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25)) * (1 - this.depreciacao * (i));
                this.ajun2 = this.ajun2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad6) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[5].length * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25)) * (1 - this.depreciacao * (i));
                this.ajul2 = this.ajul2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[6].length * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25)) * (1 - this.depreciacao * (i));
                this.aago2 = this.aago2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[7].length * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25)) * (1 - this.depreciacao * (i));
                this.aset2 = this.aset2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[8].length * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25)) * (1 - this.depreciacao * (i));
                this.aout2 = this.aout2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[9].length * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25)) * (1 - this.depreciacao * (i));
                this.anov2 = this.anov2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[10].length * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25)) * (1 - this.depreciacao * (i));
                this.adez2 = this.adez2 + (this.paramsmod[0].area) * (this.inversorescascata2[o][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * this.dias[11].length * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25)) * (1 - this.depreciacao * (i));
              }
            }
          }
        }
        this.EprodAno.push(
          +(this.ajan + this.ajan2 +
            this.afev + this.afev2 +
            this.amar + this.amar2 +
            this.aabr + this.aabr2 +
            this.amai + this.amai2 +
            this.ajun + this.ajun2 +
            this.ajul + this.ajul2 +
            this.aago + this.aago2 +
            this.aset + this.aset2 +
            this.aout + this.aout2 +
            this.anov + this.anov2 +
            this.adez + this.adez2).toFixed(2));
        const acc = (+(this.ajan + this.ajan2 +
          this.afev + this.afev2 +
          this.amar + this.amar2 +
          this.aabr + this.aabr2 +
          this.amai + this.amai2 +
          this.ajun + this.ajun2 +
          this.ajul + this.ajul2 +
          this.aago + this.aago2 +
          this.aset + this.aset2 +
          this.aout + this.aout2 +
          this.anov + this.anov2 +
          this.adez + this.adez2) - this.consumoanual).toFixed(2);
        const ainx = i - 1;
        this.custocordisp = this.custodisponibilidade * 12 * this.tarenergia * (1 + (this.reajtar * i))
        this.disponibile.push(+this.custocordisp.toFixed(2));
        if (parseFloat(acc) > 0) {
          if (i > 0) {
            this.EaccumAno.push(+(parseFloat(acc) + this.EaccumAno[ainx]).toFixed(2))
          } else {
            this.EaccumAno.push((parseFloat(acc)))
          }
        }
        if (parseFloat(acc) < 0) {
          this.EaccumAno.push(0);
        }
        this.descontado = this.EaccumAno[ainx];
        // calculos rendimento sem financiamento após primeiro Ano
        if (this.finam === false) {
          if (this.EprodAno[i] >= this.consumoanual) {
            const indiceant = i - 1;
            const novareceitacor = + ((1 + (this.reajtar * i)) * this.consumoanual * this.tarenergia).toFixed(2);
            this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros)); //  considera juros receita anteriores
            const receitaatual = +(this.Receita[i]).toFixed(2);
            this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2)); //  receita anterior + atual com juros - disponibilidade
            this.FluxoCaixa.push(+(receitaatual - this.custocordisp).toFixed(2)); // receita atual - disponibilidade
            this.FluxoCaixaacc.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
            this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
            this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1 + (this.taxjuros)), i + 1)).toFixed(2)));
            const ret = this.VPL[indiceant] + receitaatual - this.custocordisp
            this.VPL.push(ret);
            this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
            this.saidasAno.push(this.custocordisp)
          }
          // Se a geracao menor que consumo
          if (this.EprodAno[i] < this.consumoanual) {
            if (this.EprodAno[i] + this.descontado >= this.consumoanual) {
              const indiceant = i - 1;
              this.EaccumAno[i] = this.EprodAno[i] + this.descontado - this.consumoanual;
              const novareceitacor = + ((1 + (this.reajtar * i)) * this.consumoanual * this.tarenergia).toFixed(2);
              const montantecor = + (this.MonteEco[indiceant] * (this.taxjuros)).toFixed(2);
              this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
              const receitaatual = +(this.Receita[i]).toFixed(2);
              this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
              this.FluxoCaixa.push(+(receitaatual - this.custocordisp).toFixed(2));
              this.FluxoCaixaacc.push((+(this.FluxoCaixaacc[indiceant] + receitaatual - this.custocordisp).toFixed(2)));
              this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
              this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1.0 + (this.taxjuros)), i + 1)).toFixed(2)));
              const ret = this.VPL[indiceant] + receitaatual - this.custocordisp
              this.VPL.push(ret);
              this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
              this.saidasAno.push(this.custocordisp)
            }
            if (this.EprodAno[i] + this.descontado < this.consumoanual) {
              const indiceant = i - 1;
              // tslint:disable-next-line: max-line-length
              const reajuste = (1 + (this.reajtar * i));
              this.EaccumAno[i] = 0;
              const novareceitacor = + (reajuste * (this.EprodAno[i] + this.descontado) * this.tarenergia).toFixed(2);
              this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
              const receitaatual = +(this.Receita[i]).toFixed(2);
              this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
              this.FluxoCaixa.push(+(receitaatual - this.custocordisp).toFixed(2));
              this.FluxoCaixaacc.push((+(this.FluxoCaixaacc[indiceant] + receitaatual - this.custocordisp).toFixed(2)));
              this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
              const corret = (1.0 + (this.taxjuros))
              const fluxdesc = this.FluxoCaixa[i] / Math.pow(corret, i + 1);
              this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1.0 + (this.taxjuros)), i + 1)).toFixed(2)));
              const ret = this.VPL[indiceant] + receitaatual - this.custocordisp
              this.VPL.push(ret);
              this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
              this.saidasAno.push(this.custocordisp)
            }
          }
        }

        // calculos rendimento com financiamento
        if (this.finam === true) {
          if (this.EprodAno[i] >= this.consumoanual) {
            const indiceant = i - 1;
            const novareceitacor = + ((1 + (this.reajtar * i)) * this.consumoanual * this.tarenergia).toFixed(2);
            this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros)); //  considera juros receita anteriores
            const receitaatual = +(this.Receita[i]).toFixed(2);
            this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2)); //  receita anterior + atual com juros - disponibilidade
            this.FluxoCaixa.push(+(receitaatual - this.custocordisp - this.ParcelaFinan[i]).toFixed(2)); // receita atual - disponibilidade
            this.FluxoCaixaacc.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
            this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
            this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1 + (this.taxjuros)), i + 1)).toFixed(2)));
            const ret = this.VPL[indiceant] + receitaatual - this.custocordisp - this.jurosano[i]
            this.VPL.push(ret);
            this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
            this.saidasAno.push(this.custocordisp + this.ParcelaFinan[i])
          }
          // Se a geracao menor que consumo
          if (this.EprodAno[i] < this.consumoanual) {
            if (this.EprodAno[i] + this.descontado >= this.consumoanual) {
              const indiceant = i - 1;
              this.EaccumAno[i] = this.EprodAno[i] + this.descontado - this.consumoanual;
              const novareceitacor = + ((1 + (this.reajtar * i)) * this.consumoanual * this.tarenergia).toFixed(2);
              const montantecor = + (this.MonteEco[indiceant] * (this.taxjuros)).toFixed(2);
              this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
              const receitaatual = +(this.Receita[i]).toFixed(2);
              this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
              this.FluxoCaixa.push(+(receitaatual - this.custocordisp - this.ParcelaFinan[i]).toFixed(2));
              this.FluxoCaixaacc.push((+(this.FluxoCaixaacc[indiceant] + receitaatual - this.custocordisp).toFixed(2)));
              this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
              this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1.0 + (this.taxjuros)), i + 1)).toFixed(2)));
              const ret = this.VPL[indiceant] + receitaatual - this.custocordisp - this.jurosano[i]
              this.VPL.push(ret);
              this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
              this.saidasAno.push(this.custocordisp + this.ParcelaFinan[i])
            }
            if (this.EprodAno[i] + this.descontado < this.consumoanual) {
              const indiceant = i - 1;
              // tslint:disable-next-line: max-line-length
              const reajuste = (1 + (this.reajtar * i));
              this.EaccumAno[i] = 0;
              const novareceitacor = + (reajuste * (this.EprodAno[i] + this.descontado) * this.tarenergia).toFixed(2);
              this.Receita.push(novareceitacor + this.rendimentosacu[indiceant] * (this.taxjuros));
              const receitaatual = +(this.Receita[i]).toFixed(2);
              this.MonteEco.push(+(this.MonteEco[indiceant] + receitaatual - this.custocordisp).toFixed(2));
              this.FluxoCaixa.push(+(receitaatual - this.custocordisp - this.ParcelaFinan[i]).toFixed(2));
              this.FluxoCaixaacc.push((+(this.FluxoCaixaacc[indiceant] + receitaatual - this.custocordisp).toFixed(2)));
              this.JurosAtivosAno.push(this.JurosAtivosAno[indiceant] + this.MonteEco[indiceant] * (this.taxjuros));// juros anterior + 10% do montante anterior
              const corret = (1.0 + (this.taxjuros))
              const fluxdesc = this.FluxoCaixa[i] / Math.pow(corret, i + 1);
              this.FluxoCaixadesc.push(parseFloat((this.FluxoCaixa[i] / Math.pow((1.0 + (this.taxjuros)), i + 1)).toFixed(2)));
              const ret = this.VPL[indiceant] + receitaatual - this.custocordisp - this.jurosano[i]
              this.VPL.push(ret);
              this.rendimentosacu.push(this.rendimentosacu[indiceant] + parseFloat(this.FluxoCaixa[i].toFixed(2)));
              this.saidasAno.push(this.custocordisp + this.ParcelaFinan[i])
            }
          }
        }
        for (let n = 0; n < 12; n++) {
          // tslint:disable-next-line: one-variable-per-declaration
          this.dias.pop();
        }
      }
      var cfw: Number[] = [];
      if (this.finam === false) {
        this.gasto = this.custototal * -1;
        cfw.push(this.custototal * -1);
      }
      console.log('gastos', this.gasto)

      if (this.finam === true) {
        let flux = 0;
        // tslint:disable-next-line: prefer-for-of
        for (let n = 0; n < this.ParcelaFinan.length; n++) {
          flux = flux + this.ParcelaFinan[n];
        }
        this.gasto = (flux + (this.custototal * (1 - this.percentfinan))) * -1;
        console.log('percentfinan', this.percentfinan)

        cfw.push(this.gasto);
      }
      //console.log('gastos', this.custototal)

      this.custoroi = 0;
      // tslint:disable-next-line: prefer-for-of
      for (let x = 0; x < this.VPL.length; x++) {
        cfw.push(this.VPL[x]);
        this.custoroi = this.custoroi + this.VPL[x];
      }
      console.log(this.VPL)
      this.payback = (this.PP(25, cfw));
      console.log(cfw);
      this.valortir = this.finance.IRR(cfw);
      this.valorroi = this.finance.ROI(this.gasto, this.custoroi);
    }
    this.datafinan = [];
    this.ecopriano = this.EprodAno[0]
    this.data1 = [];
    this.data1.push({ x: 0, y: -1 * this.custototal })

    for (let i = 0; i < 25; i++) {
      this.datafinan.push({
        "ANOS": this.ANOS[i],
        "EprodAno": this.EprodAno[i],
        "EaccumAno": this.EaccumAno[i],
        "Receita": this.Receita[i],
        "disponibile": this.disponibile[i],
        "ParcelaFinan": this.ParcelaFinan[i],
        "saidasAno": this.saidasAno[i],
        "FluxoCaixa": this.FluxoCaixa[i],
        "VPL": this.VPL[i],
        "rendimentosacu": this.rendimentosacu[i]
      })
      this.data1.push({ x: i + 1, y: this.VPL[i] })
      this.ANOSx.push(this.ANOS[i].toFixed(2))
      this.EprodAnox.push(this.EprodAno[i].toFixed(2))
      this.EaccumAnox.push(this.EaccumAno[i].toFixed(2))
      this.Receitax.push(this.Receita[i].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
      this.saidasAnox.push(this.saidasAno[i].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
      this.FluxoCaixax.push(this.FluxoCaixa[i].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
      this.VPLx.push((this.VPL[i]).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
      console.log("VPLx", ((this.VPL[i]).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })))
      this.rendimentosacux.push(this.rendimentosacu[i].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
      this.disponibilex.push(this.disponibile[i].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))

    }
    console.log('data1', this.data1)
  }
  criapasta() {
    this.acesstok
      .filesCreateFolderV2({
        'path': "/aplicativos/homofold/" + this.codcliente
      })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => { console.log(error) })
  }

  autorizacao() {
    this.tokenx = localStorage.getItem('tokenx');
    this.service.getdbx({ token: this.tokenx })
      .subscribe((data) => {
        console.log(data)
        this.codig = data
        this.decryptData(this.codig.cod)
      }
      )
  }
  decryptData(data: any) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.tokenx);
      if (bytes.toString()) {
        var dadox = (JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        console.log(dadox)
        this.adbx = dadox;
        this.autoh();
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  showx(s) {
    console.log("shox", s)
  }
  autoh() {
    this.acesstok = new Dropbox({ accessToken: this.adbx[0].accessToken });
    // Begin authentication process if credentials not found
    console.log(this.adbx[0].accessToken)
    if (this.autorizado === 'true') {
      const authUrl = this.router.url;
      const parameters = authUrl.split('#')[1] || '';
      if (this.autorizado === 'true') {
        const arrParams = parameters.split('&') || [];
        if (this.autorizado === 'true') {
          const authObj: DbxAuth = { isAuth: false };
          // tslint:disable-next-line: prefer-for-of
          authObj.accessToken = this.adbx[0].accessToken;
          authObj.tokenType = this.adbx[0].tokenType;
          authObj.uid = this.adbx[0].uid;
          authObj.accountId = this.adbx[0].accountId;
          if (authObj.accessToken && authObj.tokenType && authObj.uid && authObj.accountId) {
            authObj.isAuth = true;
            this.dbxAuth = authObj;
          }
        }
      }
      console.log('esse eh dbxauto', this.dbxAuth)
      this.service.changedauth(this.dbxAuth)
      // Store credentials into Auth-service and into localStorage
      // if (this.dbxAuth.isAuth) {
      //    this.authService.storeAuth(this.dbxAuth);
      //    this.podeupar = true;
      //   }
      // } else {
    }
  }
}
