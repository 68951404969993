
<div  class="messaging middle">
  <div class="inbox_msg">
    <div class="inbox_people">
        <section class="sidebar">
        <div class="sidebar__header">
          <img *ngIf="contatotrue" alt="avatar"
               mat-card-avatar
               [src]="contact[0].profilePicThumbObj.eurl">
          <div class="sidebar__header__icons">
            <button  *ngIf="showatualiza===true"(click)="apuxamsg(indice)" mat-icon-button>
              <mat-icon>donut_large</mat-icon>
            </button>
            <button (click) ="qrcode(dialoga)" mat-icon-button>
              <mat-icon>chat</mat-icon>
            </button>
            <button  mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item >Logout</button>
            </mat-menu>
          </div>
        </div>
        <!--  SIDEBAR-->
  <div class="sidebar__search">
    <div class="sidebar__search__content">
      <form #form="ngForm" >
        <button class="sidebar__search__content__icon" mat-icon-button>
          <mat-icon>search_outline</mat-icon>
        </button>
        <input class="sidebar__search__content__input"
               name="search"
               ngModel
               placeholder=""
               type="text">

        <button style="display:none;" type="submit"></button>
      </form>
    </div>
  </div>
      </section>
      <div  class="inbox_chat scroll">
        <div class="sidebar__content">
          <ng-container  *ngFor="let contato of allultimas; let i=index">
            <section   class="sidebar-content" (click)="puxamsg(contato.chat.contact.id._serialized,contato.chat.contact.id.user,contato.chat.contact.profilePicThumbObj.eurl,contato.chat.contact.formattedName); indice = i" >
              <img *ngIf="allultimas[i].chat.contact.profilePicThumbObj.tag !== '' &&  allultimas[i].chat.contact.profilePicThumbObj.eurl !== undefined" [src]="contato.chat.contact.profilePicThumbObj.eurl"  mat-card-avatar>
              <img *ngIf="allultimas[i].chat.contact.profilePicThumbObj.tag === '' || allultimas[i].chat.contact.profilePicThumbObj.eurl === undefined" src="assets/images/gen.png"  mat-card-avatar>
              <div class="sidebar-content__room">
                <span class="sidebar-content__room__name">{{ contato.chat.contact.formattedName }}</span>
                <span *ngIf="contato.broadcast !== false" class="sidebar-content__room__last-msg">{{contato.body  | slice:0:20 }}  {{'....'}} </span>
                <span style="color: red;font-weight: bold;" *ngIf="contato.broadcast === false" class="sidebar-content__room__last-msg"> <b>{{contato.body  | slice:0:20 }}  {{'....'}} </b> </span>
              </div>
            </section>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="chat__header">
      <img  mat-card-avatar *ngIf="picsel !== '' && picsel !== undefined" [src]="picsel">
      <img  mat-card-avatar *ngIf="picsel === '' || picsel === undefined " src="assets/images/gen.png">
      <div class="chat__header__room-name">
        <h6>{{ nomesel }}</h6>
      </div>
      <div class="chat__header__right">
        <mat-form-field style="width: 120px; margin-right: 20px;">
          <input style="color: transparent !important;"
            (dateChange)="datainicio = ($event.target.value['_i']['month']+ 1 + '/' + ($event.target.value['_i']['date'] ) + '/' + $event.target.value['_i']['year'] ); mudadata();"
            matInput [matDatepicker]="picker30" >
          <mat-datepicker-toggle matSuffix [for]="picker30"></mat-datepicker-toggle>
          <mat-datepicker #picker30></mat-datepicker>
        </mat-form-field>
        <button mat-icon-button>
          <mat-icon>search_outline</mat-icon>
        </button>
        <button mat-icon-button>
          <mat-icon class="file">attach_file</mat-icon>
        </button>
        <button mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </div>
    <div [nbSpinner]="sp" nbSpinnerSize="giant" nbSpinnerStatus="primary" class="mesgs">

      <div  #scrollMe class="msg_history">
        <div *ngFor="let msg of allmsg; let i= index">
          <div *ngIf="msg.type !== 'image' && msg.type !== 'ptt' && msg.type !== 'video' &&  msg.fromMe === false &&  msg.isForwarded === false  &&  msg.type !== 'notification_template' && msg.type !== 'e2e_notification' "
            class="incoming_msg">
            <div class="received_msg">
              <div class="received_withd_msg">
                <p>{{msg.body}}</p>
                <span class="time_date"> {{msg.timestamp* 1000 | date: 'dd/MM/yyyy h:mm:ss a'}}</span>
              </div>
            </div>
          </div>

          <div *ngIf="msg.type === 'image' && msg.fromMe === false &&  msg.isForwarded === false  &&  msg.type !== 'notification_template' && msg.type !== 'e2e_notification' "
          class="incoming_msg">
          <div class="received_msg">
            <div class="received_withd_msg">
              <img [src]="'data:image/png;base64,'+ msg.body"  />
              <span class="time_date"> {{msg.timestamp* 1000 | date: 'dd/MM/yyyy h:mm:ss a'}}</span>
            </div>
          </div>
        </div>

          <div *ngIf=" msg.type !== 'image' && msg.type !== 'ptt' && msg.type !== 'video'&&  msg.fromMe === false && msg.isForwarded === true && msg.type !== 'notification_template' && msg.type !== 'e2e_notification' "
          class="incoming_msg">
          <div class="received_msg">
            <div class="received_withd_msg">
              <p><span style="font-size: 12px; color: #94AE8C">
                <i class="fa fa-share" aria-hidden="true"></i>
                Encaminhada</span>
                <br>
                {{msg.body}}</p>
              <span class="time_date"> {{msg.timestamp* 1000 | date: 'dd/MM/yyyy h:mm:ss a'}}</span>
            </div>
          </div>
        </div>

        <div *ngIf="msg.type !== 'image' && msg.type !== 'ptt' && msg.type !== 'video' &&  msg.fromMe === true && msg.type !== 'notification_template' && msg.type !== 'e2e_notification'"
            class="outgoing_msg">
            <div class="sent_msg">
              <p>{{msg.body}}</p>
              <span class="time_date"> {{msg.timestamp* 1000 | date: 'dd/MM/yyyy h:mm:ss a'}}</span>
            </div>
          </div>

          <div *ngIf="msg.type === 'image' && msg.fromMe === true && msg.type !== 'notification_template' && msg.type !== 'e2e_notification'"
            class="outgoing_msg">
            <div class="sent_msg">
              <img [src]="'data:image/png;base64,'+ msg.body"  />
              <span class="time_date"> {{msg.timestamp* 1000 | date: 'dd/MM/yyyy h:mm:ss a'}}</span>
            </div>
          </div>


          
        </div>
      </div>
      <div class="type_msg">
        <div class="input_msg_write">
          <input style="border-radius: 30px;	border: 1px solid lightgray;" (change)="msg=$event.target.value" type="text" class="write_msg" placeholder="Escreva a mensagem" />
          <button (click)="enviamsg()" class="msg_send_btn" type="button"><i class="fa fa-paper-plane"
              aria-hidden="true"></i></button>
        </div>
      </div>
    </div>

  </div>

</div>



<ng-template #dialoga let-data let-ref="dialogRef">
  <nb-card style="width:auto;">
    <nb-card-header style="background-color: red;">
      <h6 style="color: white;">QRCode</h6>
    </nb-card-header>
    <nb-card-body>
      <h6>
        <img [src]="QRCode" />
      </h6>
    </nb-card-body>
  </nb-card>
</ng-template>

