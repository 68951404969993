<!-- BOÕES CONTROLE -->
<mat-toolbar style="background-color: transparent ;margin-bottom: 5px;">
    <ul class="legend">
        <li style="width: 220px;"><span class="superawesome"></span>
            <h1 style="font-size: 16px;margin-left: 5px;">Devendo duas parcelas</h1>
        </li>
        <li style="width: 220px;"><span class="awesome"></span>
            <h1 style="font-size: 16px;margin-left: 5px;">Devendo uma parcela</h1>
        </li>
        <li style="width: 220px;"><span class="kindaawesome"></span>
            <h1 style="font-size: 16px;margin-left: 5px;">Pago</h1>
        </li>
    </ul>
    <button data-toggle="modal" data-target="#transacaomodal" routerLinkActive="active" style="color:white;background-color:blue; border: 0.5px solid transparent !important;" mat-flat-button>
    <mat-icon>plus_one</mat-icon> Adicionar Projeto
    </button>
    <button (click)="refresh()" style="color:white;background-color:blue; border: 0.5px solid transparent !important; margin-left: 10px;" mat-flat-button>
     Atualizar
    </button>

</mat-toolbar>

<div *ngIf="renderizou === true" class="control-section p-shadow-2">
    <ejs-grid id="Grid" [locale]='locas' #overviewgrid id='overviewgrid' [dataSource]='data' rowHeight='38' [allowSelection]='true' [allowSorting]='true' height='600' (dataBound)='onDataBound($event);dataBound()' [enableHover]='true' [selectionSettings]='selectionSettings'
        [filterSettings]='filterSettings' [allowSorting]='true' [allowFiltering]='true' [enableVirtualization]='true' [toolbar]='toolbar' (toolbarClick)='toolbarClick($event)' [allowExcelExport]='true' [allowPdfExport]='true' (excelQueryCellInfo)='exportQueryCellInfo($event)'
        (pdfQueryCellInfo)='exportQueryCellInfo($event)'>
        <e-columns>
            <e-column field='' headerText='OS' width='130' [isPrimaryKey]='true'>
                <ng-template #template let-data>
                    <a [ngStyle]="{'color':data.projeto === '...' ? 'black' : 'black','background-color':data.responsavel === '...' ? 'yellow' : 'transparent' ,'font-weight':data.responsavel === '...' ? 'bold' : 'normal'}">{{data.codhomol}}
            </a>
                </ng-template>
            </e-column>


        </e-columns>
    </ejs-grid>
    <!-- TABELA -->
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
        <!-- Position Column -->
        <ng-container matColumnDef="projeto" sticky>
            <th mat-header-cell *matHeaderCellDef style="width: 180px !important;color: black;font-size: 14px;" mat-sort-header>
                <strong>Projeto</strong> </th>
            <td [ngStyle]="{ 
            'background-color': todosdados.pgt1 === 'Pendente' && todosdados.pgt2 === 'Pendente' ? 'red':  todosdados.pgt1 === 'Pendente' && todosdados.pgt2 === 'Pago' ? 'yellow': todosdados.pgt1 === 'Pago' && todosdados.pgt2 === 'Pendente' ? 'yellow': todosdados.pgt1 === 'Pago' && todosdados.pgt2 === 'Pago' ? '#00e600':'' ,
            'color': todosdados.pgt1 === 'Pendente' && todosdados.pgt2 === 'Pendente' ? 'white':  todosdados.pgt1 === 'Pendente' && todosdados.pgt2 === 'Pago' ? 'black': todosdados.pgt1 === 'Pago' && todosdados.pgt2 === 'Pendente' ? 'black': todosdados.pgt1 === 'Pago' && todosdados.pgt2 === 'Pago' ? 'black':''
            }" (click)="filtrahomol(todosdados.id);" style="width: 180px !important;color: black; font-size: 14px;text-align: left;" mat-cell *matCellDef="let todosdados">
                {{todosdados.projeto}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="responsavel" sticky>
            <th mat-header-cell *matHeaderCellDef style="width: 130px !important;color: black;font-size: 14px;" mat-sort-header>
                <strong>Resp.</strong> </th>
            <td (click)="filtrahomol(todosdados.id) " style="width: 130px !important;color: black;font-size: 14px;text-align: center;" mat-cell *matCellDef="let todosdados">
                {{todosdados.responsavel}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="dataini" sticky>
            <th style="width: 100px !important;color: black;text-align: left;font-size: 14px;" mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Entrada</strong></th>
            <td (click)="filtrahomol(todosdados.id) " style="width: 100px !important;color: black;text-align: left;font-size: 14px;" mat-cell *matCellDef="let todosdados">
                {{todosdados.dataini}} </td>
        </ng-container>

        <ng-container matColumnDef="cliente" sticky>
            <th style="width: 190px !important;color: black;text-align: left;font-size: 14px;" mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Cliente</strong></th>
            <td (click)="filtrahomol(todosdados.id) " style="width: 190px !important;color: black;text-align: left;font-size: 14px;" mat-cell *matCellDef="let todosdados">
                {{todosdados.cliente}} </td>
        </ng-container>

        <ng-container matColumnDef="parceiro" sticky>
            <th style="width: 190px !important;color: black;text-align: left;font-size: 14px;" mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Parceiro</strong></th>
            <td (click)="filtrahomol(todosdados.id) " style="width: 190px !important;color: black;text-align: left;font-size: 14px;" mat-cell *matCellDef="let todosdados">
                {{todosdados.parceiro}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="observacao" sticky>
            <th style="width: auto;text-align: left;color: black;font-size: 14px;" mat-header-cell *matHeaderCellDef mat-sort-header>
                <strong>Observação</strong> </th>
            <td (click)="filtrahomol(todosdados.id) " style="width: auto;text-align: left;font-size: 14px;margin-top: 10px;margin-bottom: 10px;" mat-cell *matCellDef="let todosdados"> {{todosdados.observacao}} </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="datafim" sticky>
            <th style="width: auto;text-align: center;color: black;font-size: 14px;" mat-header-cell *matHeaderCellDef mat-sort-header>
                <strong>Dias</strong> </th>
            <td (click)="filtrahomol(todosdados.id)" style="width: 30px;text-align: left;font-size: 14px; color: white;background-color: red;text-align: center;" mat-cell *matCellDef="let todosdados"> <b>{{todosdados.diasvenc}}</b> </td>
        </ng-container>

        <!-- Menu Column -->
        <ng-container matColumnDef="Menu" sticky>
            <th style="width: 10px;text-align: left;color: black;font-size: large;" mat-header-cell *matHeaderCellDef mat-sort-header> </th>
            <td style="width: 10px;text-align: left;font-size: large;" mat-cell *matCellDef="let todosdados">
                <button (click)="filtrahomol(todosdados.id)" style="background-color: transparent; color: black;border-radius: 0%;border: transparent;" data-toggle="modal" data-target="#transacaomodal2" routerLinkActive="active" nbButton shape="round" size="small" status="success"><i class="far fa-edit"></i>
        </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td style="background-color: teal;" mat-cell *matCellDef="let todosdados" [attr.colspan]="columnsToDisplay.length">
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
        </tr>
        <tr mat-row class="example-detail-row"></tr>

    </table>
    <!-- TABELA -->
    <mat-paginator [pageSizeOptions]="[5, 10, 20,30,40]" showFirstLastButtons></mat-paginator>
</div>


<!-- MODAL -->
<div class="modal fade ku" id="transacaomodal" tabindex="-1" role="dialog" aria-labelledby="transacaomodalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="transacaomodalLabel">Novo controle</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <mat-form-field style="width: 400px">
                                <input (change)="projeto = $event.target.value" type="text" matInput placeholder="Projeto">
                            </mat-form-field>
                        </div>

                    </div>
                </form>
                <form>
                    <div class="form-row">
                        <div style="width: 400px" class="form-group">
                            <label for="statusprops">Responsável:</label>
                            <select (change)="responsavel = $event.target.value" id="statusprops" class="form-control">
                  <option selected> Selecione</option>
                  <option > Robson</option>
                  <option > Jonnathan</option>
                  <option > Erick</option>
                  <option > Michael</option>
                </select>
                        </div>
                    </div>
                </form>
                <form>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <mat-form-field style="width: 120px;">
                                <input (dateChange)="dataini = ($event.target.value['_i']['date'] + '/' + ($event.target.value['_i']['month'] + 1) + '/' + $event.target.value['_i']['year'] )" matInput [matDatepicker]="picker" placeholder="Data inicio">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-4">
                            <mat-form-field style="width: 200px;">
                                <input (dateChange)="datafim = ($event.target.value['_i']['date'] + '/' + ($event.target.value['_i']['month'] + 1) + '/' + $event.target.value['_i']['year'] )" matInput [matDatepicker]="picker10" placeholder="Previsão de Término">
                                <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
                                <mat-datepicker #picker10></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Observação:</label>
                            <textarea (change)="observacao = $event.target.value" style="width: 400px;" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                    </div>
                </form>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <mat-form-field style="width: 400px">
                            <input (change)="cliprojeto = $event.target.value" type="text" matInput placeholder="Cliente">
                        </mat-form-field>
                    </div>

                </div>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <mat-form-field style="width: 400px">
                            <input (change)="parceiro = $event.target.value" type="text" matInput placeholder="Empresa">
                        </mat-form-field>
                    </div>

                </div>
            </div>
            <div style="margin-top: -20px;" class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button (click)='novagestao()' data-dismiss="modal" type="button" class="btn btn-primary">Cadastrar</button>
            </div>
        </div>
    </div>
</div>


<!-- MODAL -->
<div class="modal fade ku" id="transacaomodal2" tabindex="-1" role="dialog" aria-labelledby="transacaomodalLabel2" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="transacaomodalLabel2">Alterar</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Projeto</label>
                            <input readonly [value]="filtredhomol.projeto" (change)="filtredhomol.projeto = $event.target.value" type="text" class="form-control" id="inputEmail4" placeholder="Email">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">Responsável</label>
                            <select [value]="filtredhomol.responsavel" (change)="filtredhomol.responsavel = $event.target.value" id="statusprops" class="form-control">
              <option selected> Selecione</option>
              <option > Robson</option>
              <option > Jonnathan</option>
              <option > Erick</option>
              <option > Michael</option>
            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <mat-form-field>
                                <input [value]="filtredhomol.dataini" (dateChange)="filtredhomol.dataini = ($event.target.value['_i']['date'] + '/' + ($event.target.value['_i']['month'] + 1) + '/' + $event.target.value['_i']['year'] )" matInput [matDatepicker]="picker3" placeholder="Data inicio">
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-6">
                            <mat-form-field>
                                <input [value]="filtredhomol.datafim" (dateChange)="filtredhomol.datafim = ($event.target.value['_i']['date'] + '/' + ($event.target.value['_i']['month'] + 1) + '/' + $event.target.value['_i']['year'] )" matInput [matDatepicker]="picker11" placeholder="Previsão de Término">
                                <mat-datepicker-toggle matSuffix [for]="picker11"></mat-datepicker-toggle>
                                <mat-datepicker #picker11></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="exampleFormControlTextarea1">Observação:</label>
                            <textarea [value]="filtredhomol.observacao" (change)="filtredhomol.observacao = $event.target.value" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Cliente</label>
                            <input [value]="filtredhomol.cliente" (change)="filtredhomol.cliente = $event.target.value" type="text" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Parceiro</label>
                            <input [value]="filtredhomol.parceiro" (change)="filtredhomol.parceiro = $event.target.value" type="text" class="form-control">
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputEmail4">1ª parcela</label>
                            <select [value]="filtredhomol.pgt1" (change)="filtredhomol.pgt1 = $event.target.value" id="statusprops" class="form-control">
              <option selected> Selecione</option>
              <option > Pago</option>
              <option > Pendente</option>
            </select>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputEmail4"> 2ª parcela</label>
                            <select [value]="filtredhomol.pgt2" (change)="filtredhomol.pgt2 = $event.target.value" id="statusprops" class="form-control">
                <option selected> Selecione</option>
                <option > Pago</option>
                <option > Pendente</option>
              </select>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputPassword4">Status</label>
                            <select [value]="filtredhomol.status" (change)="filtredhomol.status = $event.target.value" id="statusprops" class="form-control">
              <option selected> Selecione</option>
              <option > Concluído</option>
              <option > Pendente</option>
            </select>
                        </div>
                    </div>
                </form>
            </div>
            <div style="margin-top: -20px;" class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button (click)="updatege()" data-dismiss="modal" type="button" class="btn btn-primary">Atualizar</button>
            </div>
        </div>
    </div>
</div>