<p-menubar [model]="itemsx">
    <ng-template  pTemplate="start"> </ng-template>
</p-menubar>
<div #screen id="my-node"></div>
<div (click)="($event);" class="map" id="map">
</div>

<div id="download">
    <img #canvas>
    <a #downloadLink></a>
</div>
