/**
 * Schedule datasource
 */

 export let scheduleData: Object[] = [
  {
      Id: 1,
      Subject: 'Explosion of Betelgeuse Star',
      Location: 'Space Centre USA',
      StartTime: new Date(2019, 0, 6, 9, 30),
      EndTime: new Date(2019, 0, 6, 11, 0),
      CategoryColor: '#1aaa55'
  }, {
      Id: 2,
      Subject: 'Thule Air Crash Report',
      Location: 'Newyork City',
      StartTime: new Date(2019, 0, 7, 12, 0),
      EndTime: new Date(2019, 0, 7, 14, 0),
      CategoryColor: '#357cd2'
  }, {
      Id: 3,
      Subject: 'Blue Moon Eclipse',
      Location: 'Space Centre USA',
      StartTime: new Date(2019, 0, 8, 9, 30),
      EndTime: new Date(2019, 0, 8, 11, 0),
      CategoryColor: '#7fa900'
  }, {
      Id: 4,
      Subject: 'Meteor Showers in 2018',
      Location: 'Space Centre USA',
      StartTime: new Date(2019, 0, 9, 13, 0),
      EndTime: new Date(2019, 0, 9, 14, 30),
      CategoryColor: '#ea7a57'
  }, {
      Id: 5,
      Subject: 'Milky Way as Melting pot',
      Location: 'Space Centre USA',
      StartTime: new Date(2019, 0, 10, 12, 0),
      EndTime: new Date(2019, 0, 10, 14, 0),
      CategoryColor: '#00bdae'
  }, {
      Id: 6,
      Subject: 'Mysteries of Bermuda Triangle',
      Location: 'Bermuda',
      StartTime: new Date(2019, 0, 10, 9, 30),
      EndTime: new Date(2019, 0, 10, 11, 0),
      CategoryColor: '#f57f17'
  }, {
      Id: 7,
      Subject: 'Glaciers and Snowflakes',
      Location: 'Himalayas',
      StartTime: new Date(2019, 0, 11, 11, 0),
      EndTime: new Date(2019, 0, 11, 12, 30),
      CategoryColor: '#1aaa55'
  }, {
      Id: 8,
      Subject: 'Life on Mars',
      Location: 'Space Centre USA',
      StartTime: new Date(2019, 0, 12, 9, 0),
      EndTime: new Date(2019, 0, 12, 10, 0),
      CategoryColor: '#357cd2'
  }, {
      Id: 9,
      Subject: 'Alien Civilization',
      Location: 'Space Centre USA',
      StartTime: new Date(2019, 0, 14, 11, 0),
      EndTime: new Date(2019, 0, 14, 13, 0),
      CategoryColor: '#7fa900'
  }, {
      Id: 10,
      Subject: 'Wildlife Galleries',
      Location: 'Africa',
      StartTime: new Date(2019, 0, 16, 11, 0),
      EndTime: new Date(2019, 0, 16, 13, 0),
      CategoryColor: '#ea7a57'
  }, {
      Id: 11,
      Subject: 'Best Photography 2018',
      Location: 'London',
      StartTime: new Date(2019, 0, 17, 9, 30),
      EndTime: new Date(2019, 0, 17, 11, 0),
      CategoryColor: '#00bdae'
  }, {
      Id: 12,
      Subject: 'Smarter Puppies',
      Location: 'Sweden',
      StartTime: new Date(2019, 0, 4, 10, 0),
      EndTime: new Date(2019, 0, 4, 11, 30),
      CategoryColor: '#f57f17'
  }, {
      Id: 13,
      Subject: 'Myths of Andromeda Galaxy',
      Location: 'Space Centre USA',
      StartTime: new Date(2019, 0, 2, 10, 30),
      EndTime: new Date(2019, 0, 2, 12, 30),
      CategoryColor: '#1aaa55'
  }, {
      Id: 14,
      Subject: 'Aliens vs Humans',
      Location: 'Research Centre of USA',
      StartTime: new Date(2019, 0, 1, 10, 0),
      EndTime: new Date(2019, 0, 1, 11, 30),
      CategoryColor: '#357cd2'
  }, {
      Id: 15,
      Subject: 'Facts of Humming Birds',
      Location: 'California',
      StartTime: new Date(2019, 0, 15, 9, 30),
      EndTime: new Date(2019, 0, 15, 11, 0),
      CategoryColor: '#7fa900'
  }, {
      Id: 16,
      Subject: 'Sky Gazers',
      Location: 'Alaska',
      StartTime: new Date(2019, 0, 18, 11, 0),
      EndTime: new Date(2019, 0, 18, 13, 0),
      CategoryColor: '#ea7a57'
  }, {
      Id: 17,
      Subject: 'The Cycle of Seasons',
      Location: 'Research Centre of USA',
      StartTime: new Date(2019, 0, 7, 5, 30),
      EndTime: new Date(2019, 0, 7, 7, 30),
      CategoryColor: '#00bdae'
  }, {
      Id: 18,
      Subject: 'Space Galaxies and Planets',
      Location: 'Space Centre USA',
      StartTime: new Date(2019, 0, 7, 17, 0),
      EndTime: new Date(2019, 0, 7, 18, 30),
      CategoryColor: '#f57f17'
  }, {
      Id: 19,
      Subject: 'Lifecycle of Bumblebee',
      Location: 'San Fransisco',
      StartTime: new Date(2019, 0, 10, 6, 0),
      EndTime: new Date(2019, 0, 10, 7, 30),
      CategoryColor: '#7fa900'
  }, {
      Id: 20,
      Subject: 'Alien Civilization',
      Location: 'Space Centre USA',
      StartTime: new Date(2019, 0, 10, 16, 0),
      EndTime: new Date(2019, 0, 10, 18, 0),
      CategoryColor: '#ea7a57'
  }, {
      Id: 21,
      Subject: 'Alien Civilization',
      Location: 'Space Centre USA',
      StartTime: new Date(2019, 0, 6, 14, 0),
      EndTime: new Date(2019, 0, 6, 16, 0),
      CategoryColor: '#ea7a57'
  }, {
      Id: 22,
      Subject: 'The Cycle of Seasons',
      Location: 'Research Centre of USA',
      StartTime: new Date(2019, 0, 8, 14, 30),
      EndTime: new Date(2019, 0, 8, 16, 0),
      CategoryColor: '#00bdae'
  }, {
      Id: 23,
      Subject: 'Sky Gazers',
      Location: 'Greenland',
      StartTime: new Date(2019, 0, 11, 14, 30),
      EndTime: new Date(2019, 0, 11, 16, 0),
      CategoryColor: '#ea7a57'
  }, {
      Id: 24,
      Subject: 'Facts of Humming Birds',
      Location: 'California',
      StartTime: new Date(2019, 0, 12, 12, 30),
      EndTime: new Date(2019, 0, 12, 14, 30),
      CategoryColor: '#7fa900'
  }
];

export let timelineData: Object[] = [
  {
      Id: 25,
      Subject: 'Sky Gazers',
      StartTime: new Date(2018, 1, 15, 10, 30),
      EndTime: new Date(2018, 1, 15, 12, 0),
      CategoryColor: '#1aaa55'
  }, {
      Id: 26,
      Subject: 'The Cycle of Seasons',
      StartTime: new Date(2018, 1, 15, 11, 0),
      EndTime: new Date(2018, 1, 15, 12, 30),
      CategoryColor: '#7fa900'
  }, {
      Id: 27,
      Subject: 'Facts of Humming Birds',
      StartTime: new Date(2018, 1, 15, 12, 30),
      EndTime: new Date(2018, 1, 15, 14, 0),
      CategoryColor: '#ea7a57'
  }, {
      Id: 28,
      Subject: 'Myths of Andromeda Galaxy',
      StartTime: new Date(2018, 1, 15, 10, 0),
      EndTime: new Date(2018, 1, 15, 11, 30),
      CategoryColor: '#00bdae'
  }, {
      Id: 29,
      Subject: 'Croco World',
      StartTime: new Date(2018, 1, 15, 19, 0),
      EndTime: new Date(2018, 1, 15, 20, 30),
      CategoryColor: '#f57f17'
  }, {
      Id: 30,
      Subject: 'Explosion of Betelgeuse Star',
      StartTime: new Date(2018, 1, 15, 13, 30),
      EndTime: new Date(2018, 1, 15, 15, 0),
      CategoryColor: '#1aaa55'
  }
];

export let zooEventsData: Object[] = [
  {
      Id: 1,
      Subject: 'Story Time for Kids',
      StartTime: new Date(2018, 1, 11, 10, 0),
      EndTime: new Date(2018, 1, 11, 11, 30),
      CategoryColor: '#1aaa55'
  }, {
      Id: 2,
      Subject: 'Camping with Turtles',
      StartTime: new Date(2018, 1, 12, 12, 0),
      EndTime: new Date(2018, 1, 12, 14, 0),
      CategoryColor: '#357cd2'
  }, {
      Id: 3,
      Subject: 'Wildlife Warriors',
      StartTime: new Date(2018, 1, 13, 10, 0),
      EndTime: new Date(2018, 1, 13, 11, 30),
      CategoryColor: '#7fa900'
  }, {
      Id: 4,
      Subject: 'Parrot Talk',
      StartTime: new Date(2018, 1, 14, 9, 0),
      EndTime: new Date(2018, 1, 14, 10, 0),
      CategoryColor: '#ea7a57'
  }, {
      Id: 5,
      Subject: 'Birds of Prey',
      StartTime: new Date(2018, 1, 15, 10, 0),
      EndTime: new Date(2018, 1, 15, 11, 30),
      CategoryColor: '#00bdae'
  }, {
      Id: 6,
      Subject: 'Croco World',
      StartTime: new Date(2018, 1, 16, 12, 0),
      EndTime: new Date(2018, 1, 16, 14, 0),
      CategoryColor: '#f57f17'
  }, {
      Id: 7,
      Subject: 'Venomous Snake Hunt',
      StartTime: new Date(2018, 1, 17, 10, 0),
      EndTime: new Date(2018, 1, 17, 11, 30),
      CategoryColor: '#1aaa55'
  }, {
      Id: 8,
      Subject: 'Face Painting & Drawing events',
      StartTime: new Date(2018, 1, 19, 9, 30),
      EndTime: new Date(2018, 1, 19, 11, 0),
      CategoryColor: '#357cd2'
  }, {
      Id: 9,
      Subject: 'Pony Rides',
      StartTime: new Date(2018, 1, 21, 11, 0),
      EndTime: new Date(2018, 1, 21, 13, 0),
      CategoryColor: '#7fa900'
  }, {
      Id: 10,
      Subject: 'Feed the Giants',
      StartTime: new Date(2018, 1, 22, 9, 30),
      EndTime: new Date(2018, 1, 22, 11, 0),
      CategoryColor: '#ea7a57'
  }, {
      Id: 11,
      Subject: 'Jungle Treasure Hunt',
      StartTime: new Date(2018, 1, 9, 10, 0),
      EndTime: new Date(2018, 1, 9, 11, 30),
      CategoryColor: '#00bdae'
  }, {
      Id: 12,
      Subject: 'Endangered Species Program',
      StartTime: new Date(2018, 1, 7, 10, 30),
      EndTime: new Date(2018, 1, 7, 12, 30),
      CategoryColor: '#f57f17'
  }, {
      Id: 13,
      Subject: 'Black Cockatoos Playtime',
      StartTime: new Date(2018, 1, 5, 10, 0),
      EndTime: new Date(2018, 1, 5, 11, 30),
      CategoryColor: '#1aaa55'
  }, {
      Id: 14,
      Subject: 'Walk with Jungle King',
      StartTime: new Date(2018, 1, 14, 12, 0),
      EndTime: new Date(2018, 1, 14, 14, 0),
      CategoryColor: '#357cd2'
  }, {
      Id: 15,
      Subject: 'Trained Climbers',
      StartTime: new Date(2018, 1, 19, 13, 0),
      EndTime: new Date(2018, 1, 19, 14, 30),
      CategoryColor: '#7fa900'
  }, {
      Id: 16,
      Subject: 'Playtime with Chimpanzees',
      StartTime: new Date(2018, 1, 22, 13, 0),
      EndTime: new Date(2018, 1, 22, 14, 30),
      CategoryColor: '#ea7a57'
  }, {
      Id: 17,
      Subject: 'Story Time for Kids',
      StartTime: new Date(2018, 1, 13, 14, 30),
      EndTime: new Date(2018, 1, 13, 16, 0),
      CategoryColor: '#1aaa55'
  }, {
      Id: 18,
      Subject: 'Black Cockatoos Playtime',
      StartTime: new Date(2018, 1, 15, 14, 30),
      EndTime: new Date(2018, 1, 15, 16, 0),
      CategoryColor: '#7fa900'
  }
];

export let eventsData: Object[] = [
  {
      Id: 1,
      Subject: 'Server Maintenance',
      StartTime: new Date(2018, 1, 11, 10, 0),
      EndTime: new Date(2018, 1, 11, 11, 30),
      EventType: 'maintenance',
      City: 'Seattle',
      CategoryColor: '#1aaa55'
  }, {
      Id: 2,
      Subject: 'Art & Painting Gallery',
      StartTime: new Date(2018, 1, 12, 12, 0),
      EndTime: new Date(2018, 1, 12, 14, 0),
      EventType: 'public-event',
      City: 'Costa Rica',
      CategoryColor: '#357cd2'
  }, {
      Id: 3,
      Subject: 'Dany Birthday Celebration',
      StartTime: new Date(2018, 1, 13, 10, 0),
      EndTime: new Date(2018, 1, 13, 11, 30),
      EventType: 'family-event',
      City: 'Kirkland',
      CategoryColor: '#7fa900'
  }, {
      Id: 4,
      Subject: 'John Wedding Anniversary',
      StartTime: new Date(2018, 1, 14, 9, 0),
      EndTime: new Date(2018, 1, 14, 10, 0),
      EventType: 'family-event',
      City: 'Redmond',
      CategoryColor: '#ea7a57'
  }, {
      Id: 5,
      Subject: 'ISA Annual Conference',
      StartTime: new Date(2018, 1, 15, 10, 0),
      EndTime: new Date(2018, 1, 15, 11, 30),
      EventType: 'commercial-event',
      City: 'USA',
      CategoryColor: '#00bdae'
  }, {
      Id: 6,
      Subject: 'Equipment Maintenance',
      StartTime: new Date(2018, 1, 16, 12, 0),
      EndTime: new Date(2018, 1, 16, 14, 0),
      EventType: 'maintenance',
      City: 'Seattle',
      CategoryColor: '#f57f17'
  }, {
      Id: 7,
      Subject: 'Aircraft Maintenance',
      StartTime: new Date(2018, 1, 17, 10, 0),
      EndTime: new Date(2018, 1, 17, 11, 30),
      EventType: 'maintenance',
      City: 'Seattle',
      CategoryColor: '#1aaa55'
  }, {
      Id: 8,
      Subject: 'Facilities Maintenance',
      StartTime: new Date(2018, 1, 19, 9, 30),
      EndTime: new Date(2018, 1, 19, 11, 0),
      EventType: 'maintenance',
      City: 'Seattle',
      CategoryColor: '#357cd2'
  }, {
      Id: 9,
      Subject: 'Britto Birthday Celebration',
      StartTime: new Date(2018, 1, 21, 11, 0),
      EndTime: new Date(2018, 1, 21, 13, 0),
      EventType: 'family-event',
      City: 'Greenland',
      CategoryColor: '#7fa900'
  }, {
      Id: 10,
      Subject: 'Justin Wedding Anniversary',
      StartTime: new Date(2018, 1, 22, 9, 30),
      EndTime: new Date(2018, 1, 22, 11, 0),
      EventType: 'family-event',
      City: 'Finland',
      CategoryColor: '#ea7a57'
  }, {
      Id: 11,
      Subject: 'AIEA Annual Meet',
      StartTime: new Date(2018, 1, 9, 10, 0),
      EndTime: new Date(2018, 1, 9, 11, 30),
      EventType: 'commercial-event',
      City: 'USA',
      CategoryColor: '#00bdae'
  }, {
      Id: 12,
      Subject: 'AAN Conference',
      StartTime: new Date(2018, 1, 7, 10, 30),
      EndTime: new Date(2018, 1, 7, 12, 30),
      EventType: 'commercial-event',
      City: 'USA',
      CategoryColor: '#f57f17'
  }, {
      Id: 13,
      Subject: 'Photography Gallery',
      StartTime: new Date(2018, 1, 5, 10, 0),
      EndTime: new Date(2018, 1, 5, 11, 30),
      EventType: 'public-event',
      City: 'Chennai',
      CategoryColor: '#1aaa55'
  }, {
      Id: 14,
      Subject: 'Beach Clean-up',
      StartTime: new Date(2018, 1, 14, 12, 0),
      EndTime: new Date(2018, 1, 14, 2, 0),
      EventType: 'public-event',
      City: 'Mumbai',
      CategoryColor: '#357cd2'
  }, {
      Id: 15,
      Subject: 'Turtle Walk',
      StartTime: new Date(2018, 1, 19, 13, 0),
      EndTime: new Date(2018, 1, 19, 14, 30),
      EventType: 'public-event',
      City: 'Costa Rica',
      CategoryColor: '#7fa900'
  }, {
      Id: 16,
      Subject: 'Silent Walk for Cancer day',
      StartTime: new Date(2018, 1, 22, 13, 0),
      EndTime: new Date(2018, 1, 22, 14, 30),
      EventType: 'public-event',
      City: 'Chennai',
      CategoryColor: '#ea7a57'
  }, {
      Id: 17,
      Subject: 'Beach Clean-up',
      StartTime: new Date(2018, 1, 13, 14, 0),
      EndTime: new Date(2018, 1, 13, 16, 0),
      EventType: 'public-event',
      City: 'Mumbai',
      CategoryColor: '#357cd2'
  }, {
      Id: 18,
      Subject: 'Photography Gallery',
      StartTime: new Date(2018, 1, 15, 14, 0),
      EndTime: new Date(2018, 1, 15, 16, 0),
      EventType: 'public-event',
      City: 'Chennai',
      CategoryColor: '#1aaa55'
  }
];

export let employeeEventData: Object[] = [
  {
      Id: 1,
      Subject: 'Project Workflow Analysis',
      StartTime: new Date(2018, 1, 12, 9, 0),
      EndTime: new Date(2018, 1, 12, 11, 0),
      CategoryColor: '#1aaa55'
  }, {
      Id: 2,
      Subject: 'Project Requirement Planning',
      StartTime: new Date(2018, 1, 13, 11, 30),
      EndTime: new Date(2018, 1, 13, 14, 0),
      CategoryColor: '#357cd2'
  }, {
      Id: 3,
      Subject: 'Quality Analysis',
      StartTime: new Date(2018, 1, 14, 9, 30),
      EndTime: new Date(2018, 1, 14, 11, 0),
      CategoryColor: '#7fa900'
  }, {
      Id: 4,
      Subject: 'Timeline Estimation',
      StartTime: new Date(2018, 1, 14, 11, 30),
      EndTime: new Date(2018, 1, 14, 14, 0),
      CategoryColor: '#ea7a57'
  }, {
      Id: 5,
      Subject: 'Project Resource planning',
      StartTime: new Date(2018, 1, 15, 9, 30),
      EndTime: new Date(2018, 1, 15, 12, 0),
      CategoryColor: '#00bdae'
  }, {
      Id: 6,
      Subject: 'Meeting with Developers',
      StartTime: new Date(2018, 1, 16, 11, 0),
      EndTime: new Date(2018, 1, 16, 13, 0),
      CategoryColor: '#f57f17'
  }, {
      Id: 7,
      Subject: 'Task Assignment to Developers',
      StartTime: new Date(2018, 1, 19, 10, 0),
      EndTime: new Date(2018, 1, 19, 11, 30),
      CategoryColor: '#1aaa55'
  }, {
      Id: 8,
      Subject: 'Project Review - 1',
      StartTime: new Date(2018, 1, 20, 11, 0),
      EndTime: new Date(2018, 1, 20, 12, 30),
      CategoryColor: '#357cd2'
  }, {
      Id: 9,
      Subject: 'Project Review - 2',
      StartTime: new Date(2018, 1, 21, 12, 0),
      EndTime: new Date(2018, 1, 21, 13, 30),
      CategoryColor: '#7fa900'
  }, {
      Id: 10,
      Subject: 'Project Review - 3',
      StartTime: new Date(2018, 1, 22, 11, 0),
      EndTime: new Date(2018, 1, 22, 12, 30),
      CategoryColor: '#ea7a57'
  }, {
      Id: 11,
      Subject: 'Project Output Preview',
      StartTime: new Date(2018, 1, 23, 9, 30),
      EndTime: new Date(2018, 1, 23, 12, 0),
      CategoryColor: '#00bdae'
  }, {
      Id: 12,
      Subject: 'Testing Project Quality',
      StartTime: new Date(2018, 1, 26, 10, 0),
      EndTime: new Date(2018, 1, 26, 11, 30),
      CategoryColor: '#f57f17'
  }, {
      Id: 13,
      Subject: 'Draft Technical Documentation',
      StartTime: new Date(2018, 1, 27, 10, 0),
      EndTime: new Date(2018, 1, 27, 13, 0),
      CategoryColor: '#1aaa55'
  }, {
      Id: 14,
      Subject: 'Online Hosting of Project',
      StartTime: new Date(2018, 1, 28, 10, 0),
      EndTime: new Date(2018, 1, 28, 11, 30),
      CategoryColor: '#357cd2'
  }, {
      Id: 15,
      Subject: 'Project Submission to Client',
      StartTime: new Date(2018, 2, 1, 10, 0),
      EndTime: new Date(2018, 2, 1, 13, 0),
      CategoryColor: '#7fa900'
  }, {
      Id: 16,
      Subject: 'Review meeting with Client',
      StartTime: new Date(2018, 2, 2, 10, 0),
      EndTime: new Date(2018, 2, 2, 11, 30),
      CategoryColor: '#ea7a57'
  }
];

export let leaveData: Object[] = [
  {
      Id: 1,
      Subject: 'Casual Leave',
      StartTime: new Date(2018, 1, 12, 9, 0),
      EndTime: new Date(2018, 1, 12, 13, 0)
  }, {
      Id: 2,
      Subject: 'Sick Leave',
      StartTime: new Date(2018, 1, 15),
      EndTime: new Date(2018, 1, 16),
      IsAllDay: true
  }, {
      Id: 3,
      Subject: 'Time-off in lieu',
      StartTime: new Date(2018, 1, 16, 12, 0),
      EndTime: new Date(2018, 1, 16, 13, 0)
  }, {
      Id: 4,
      Subject: 'Time-off in lieu',
      StartTime: new Date(2018, 1, 13, 9, 0),
      EndTime: new Date(2018, 1, 13, 11, 0)
  }, {
      Id: 5,
      Subject: 'Time-off in lieu',
      StartTime: new Date(2018, 1, 20, 9, 0),
      EndTime: new Date(2018, 1, 20, 11, 0)
  }, {
      Id: 6,
      Subject: 'Casual Leave',
      StartTime: new Date(2018, 1, 22, 14, 0),
      EndTime: new Date(2018, 1, 24, 18, 0)
  }, {
      Id: 7,
      Subject: 'Training Session - Off-site',
      StartTime: new Date(2018, 1, 8, 14, 0),
      EndTime: new Date(2018, 1, 9, 3, 0),
      Location: 'San-Francisco'
  }, {
      Id: 8,
      Subject: 'Sick Leave',
      StartTime: new Date(2018, 1, 5, 9, 0),
      EndTime: new Date(2018, 1, 5, 13, 0)
  }, {
      Id: 9,
      Subject: 'Maternity leave',
      StartTime: new Date(2018, 2, 1),
      EndTime: new Date(2018, 7, 31),
      IsAllDay: true
  }, {
      Id: 10,
      Subject: 'Valentine\'s Day',
      StartTime: new Date(2018, 1, 14),
      EndTime: new Date(2018, 1, 15),
      IsAllDay: true
  }, {
      Id: 11,
      Subject: 'Presidents\' Day',
      StartTime: new Date(2018, 1, 19),
      EndTime: new Date(2018, 1, 20),
      IsAllDay: true
  }, {
      Id: 12,
      Subject: 'Daylight Saving Time starts',
      StartTime: new Date(2018, 2, 11),
      EndTime: new Date(2018, 2, 12),
      IsAllDay: true
  }, {
      Id: 13,
      Subject: 'Easter Sunday',
      StartTime: new Date(2018, 3, 1),
      EndTime: new Date(2018, 3, 2),
      IsAllDay: true
  }, {
      Id: 14,
      Subject: 'Time-off in lieu',
      StartTime: new Date(2018, 1, 14, 14, 0),
      EndTime: new Date(2018, 1, 14, 15, 0)
  }
];

export let recurrenceData: Object[] = [
  {
      Id: 1,
      Subject: 'Project demo meeting with Andrew',
      Location: 'Office',
      StartTime: new Date(2018, 1, 14, 12, 0),
      EndTime: new Date(2018, 1, 14, 13, 0),
      RecurrenceRule: 'FREQ=WEEKLY;INTERVAL=2;BYDAY=MO;COUNT=10',
      CategoryColor: '#1aaa55'
  }, {
      Id: 2,
      Subject: 'Scrum Meeting',
      Location: 'Office',
      StartTime: new Date(2018, 1, 12, 9, 30),
      EndTime: new Date(2018, 1, 12, 10, 30),
      RecurrenceRule: 'FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR;INTERVAL=1',
      CategoryColor: '#357cd2'
  }, {
      Id: 3,
      Subject: 'Meeting with Core team',
      Location: 'Office',
      StartTime: new Date(2018, 1, 16, 12, 0),
      EndTime: new Date(2018, 1, 16, 14, 0),
      RecurrenceRule: 'FREQ=WEEKLY;INTERVAL=1;BYDAY=FR',
      CategoryColor: '#7fa900'
  }, {
      Id: 4,
      Subject: 'Customer meeting – John Mackenzie',
      Location: 'Office',
      StartTime: new Date(2018, 1, 20, 11, 30),
      EndTime: new Date(2018, 1, 20, 13, 30),
      RecurrenceRule: 'FREQ=MONTHLY;BYMONTHDAY=20;INTERVAL=1;COUNT=5',
      CategoryColor: '#ea7a57'
  }, {
      Id: 5,
      Subject: 'Team Fun Activities',
      Location: 'Office',
      StartTime: new Date(2018, 1, 22),
      EndTime: new Date(2018, 1, 23),
      IsAllDay: true,
      RecurrenceRule: 'FREQ=YEARLY;BYDAY=TH;BYMONTH=2;BYSETPOS=4;INTERVAL=1;COUNT=10',
      CategoryColor: '#00bdae'
  }
];

export let webinarData: Object[] = [
  {
      Id: 1,
      Subject: 'Environment Day',
      Tags: 'Eco day, Forest conserving, Earth & its resources',
      Description: 'A day that creates awareness to promote the healthy planet and reduce the air pollution crisis on nature earth.',
      StartTime: new Date(2018, 1, 12, 9, 0),
      EndTime: new Date(2018, 1, 12, 14, 0),
      ImageName: 'environment-day',
      PrimaryColor: '#1aaa55',
      SecondaryColor: '#47bb76'
  }, {
      Id: 2,
      Subject: 'Health Day',
      Tags: 'Reduce mental stress, Follow good food habits',
      Description: 'A day that raises awareness on different health issues. It marks the anniversary of the foundation of WHO.',
      StartTime: new Date(2018, 1, 13, 9, 0),
      EndTime: new Date(2018, 1, 13, 14, 0),
      ImageName: 'health-day',
      PrimaryColor: '#357cd2',
      SecondaryColor: '#5d96db'
  }, {
      Id: 3,
      Subject: 'Cancer Day',
      Tags: 'Life threatening cancer effects, Palliative care',
      Description: 'A day that raises awareness on cancer and its preventive measures. Early detection saves life.',
      StartTime: new Date(2018, 1, 14, 9, 0),
      EndTime: new Date(2018, 1, 14, 14, 0),
      ImageName: 'cancer-day',
      PrimaryColor: '#7fa900',
      SecondaryColor: '#a4c932'
  }, {
      Id: 4,
      Subject: 'Happiness Day',
      Tags: 'Stress-free, Smile, Resolve frustration and bring happiness',
      Description: 'A general idea is to promote happiness and smile around the world.',
      StartTime: new Date(2018, 1, 15, 9, 0),
      EndTime: new Date(2018, 1, 15, 14, 0),
      ImageName: 'happiness-day',
      PrimaryColor: '#ea7a57',
      SecondaryColor: '#ee9478'
  }, {
      Id: 5,
      Subject: 'Tourism Day',
      Tags: 'Diverse cultural heritage, strengthen peace',
      Description: 'A day that raises awareness on the role of tourism and its effect on social and economic values.',
      StartTime: new Date(2018, 1, 16, 9, 0),
      EndTime: new Date(2018, 1, 16, 14, 0),
      ImageName: 'tourism-day',
      PrimaryColor: '#00bdae',
      SecondaryColor: '#32cabe'
  }
];

export let doctorsEventData: Object[] = [
  {
      Id: 1,
      Subject: 'Surgery - Andrew',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 12, 9, 0),
      EndTime: new Date(2018, 1, 12, 10, 0)
  }, {
      Id: 2,
      Subject: 'Consulting - John',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 12, 10, 0),
      EndTime: new Date(2018, 1, 12, 11, 30)
  }, {
      Id: 3,
      Subject: 'Therapy - Robert',
      EventType: 'Requested',
      StartTime: new Date(2018, 1, 12, 11, 30),
      EndTime: new Date(2018, 1, 12, 12, 30)
  }, {
      Id: 4,
      Subject: 'Observation - Steven',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 12, 12, 30),
      EndTime: new Date(2018, 1, 12, 13, 30)
  }, {
      Id: 5,
      Subject: 'Extraction - Nancy',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 12, 13, 30),
      EndTime: new Date(2018, 1, 12, 15, 0)
  }, {
      Id: 6,
      Subject: 'Surgery - Paul',
      EventType: 'New',
      StartTime: new Date(2018, 1, 13, 9, 0),
      EndTime: new Date(2018, 1, 13, 10, 0)
  }, {
      Id: 7,
      Subject: 'Extraction - Josephs',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 13, 10, 0),
      EndTime: new Date(2018, 1, 13, 11, 0)
  }, {
      Id: 8,
      Subject: 'Consulting - Mario',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 13, 11, 0),
      EndTime: new Date(2018, 1, 13, 12, 0)
  }, {
      Id: 9,
      Subject: 'Therapy - Saveley',
      EventType: 'Requested',
      StartTime: new Date(2018, 1, 13, 12, 0),
      EndTime: new Date(2018, 1, 13, 13, 30)
  }, {
      Id: 10,
      Subject: 'Observation - Cartrain',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 13, 13, 30),
      EndTime: new Date(2018, 1, 13, 15, 30)
  }, {
      Id: 11,
      Subject: 'Consulting - Yang',
      EventType: 'New',
      StartTime: new Date(2018, 1, 14, 9, 0),
      EndTime: new Date(2018, 1, 14, 10, 0)
  }, {
      Id: 12,
      Subject: 'Observation - Michael',
      EventType: 'New',
      StartTime: new Date(2018, 1, 14, 10, 0),
      EndTime: new Date(2018, 1, 14, 11, 30)
  }, {
      Id: 13,
      Subject: 'Surgery - Roland',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 14, 11, 30),
      EndTime: new Date(2018, 1, 14, 12, 30)
  }, {
      Id: 14,
      Subject: 'Extraction - Francisco',
      EventType: 'Requested',
      StartTime: new Date(2018, 1, 14, 12, 30),
      EndTime: new Date(2018, 1, 14, 13, 30)
  }, {
      Id: 15,
      Subject: 'Therapy - Henriette',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 14, 13, 30),
      EndTime: new Date(2018, 1, 14, 15, 0)
  }, {
      Id: 16,
      Subject: 'Observation - Bernardo',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 15, 9, 0),
      EndTime: new Date(2018, 1, 15, 10, 0)
  }, {
      Id: 17,
      Subject: 'Therapy - Wilson',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 15, 10, 0),
      EndTime: new Date(2018, 1, 15, 11, 0)
  }, {
      Id: 18,
      Subject: 'Consulting - Horst',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 15, 11, 0),
      EndTime: new Date(2018, 1, 15, 12, 0)
  }, {
      Id: 19,
      Subject: 'Surgery - Limeira',
      EventType: 'Requested',
      StartTime: new Date(2018, 1, 15, 12, 0),
      EndTime: new Date(2018, 1, 15, 13, 30)
  }, {
      Id: 20,
      Subject: 'Observation - Victoria',
      EventType: 'Requested',
      StartTime: new Date(2018, 1, 15, 13, 30),
      EndTime: new Date(2018, 1, 15, 15, 30)
  }, {
      Id: 21,
      Subject: 'Extraction - Afonso',
      EventType: 'Confirmed',
      StartTime: new Date(2018, 1, 16, 9, 0),
      EndTime: new Date(2018, 1, 16, 10, 0)
  }, {
      Id: 22,
      Subject: 'Extraction - Paula',
      EventType: 'New',
      StartTime: new Date(2018, 1, 16, 10, 0),
      EndTime: new Date(2018, 1, 16, 11, 0)
  }, {
      Id: 23,
      Subject: 'Observation - George',
      EventType: 'Requested',
      StartTime: new Date(2018, 1, 16, 11, 0),
      EndTime: new Date(2018, 1, 16, 12, 0)
  }, {
      Id: 24,
      Subject: 'Therapy - Smith',
      EventType: 'New',
      StartTime: new Date(2018, 1, 16, 12, 0),
      EndTime: new Date(2018, 1, 16, 13, 30)
  }, {
      Id: 25,
      Subject: 'Surgery - Jennifer',
      EventType: 'New',
      StartTime: new Date(2018, 1, 16, 13, 30),
      EndTime: new Date(2018, 1, 16, 15, 30)
  }
];

export let resourceData: Object[] = [
  {
      Id: 1,
      Subject: 'Workflow Analysis',
      StartTime: new Date(2018, 3, 1, 9, 30),
      EndTime: new Date(2018, 3, 1, 12, 0),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 2
  }, {
      Id: 2,
      Subject: 'Requirement planning',
      StartTime: new Date(2018, 3, 1, 12, 30),
      EndTime: new Date(2018, 3, 1, 14, 45),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 3,
      Subject: 'Quality Analysis',
      StartTime: new Date(2018, 3, 2, 10, 0),
      EndTime: new Date(2018, 3, 2, 12, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 4,
      Subject: 'Resource planning',
      StartTime: new Date(2018, 3, 2, 13, 0),
      EndTime: new Date(2018, 3, 2, 15, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 5,
      Subject: 'Timeline estimation',
      StartTime: new Date(2018, 3, 3, 9, 0),
      EndTime: new Date(2018, 3, 3, 11, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 6,
      Subject: 'Developers Meeting',
      StartTime: new Date(2018, 3, 3, 14, 0),
      EndTime: new Date(2018, 3, 3, 16, 45),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 2
  }, {
      Id: 7,
      Subject: 'Project Review',
      StartTime: new Date(2018, 3, 4, 11, 15),
      EndTime: new Date(2018, 3, 4, 13, 0),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 8,
      Subject: 'Manual testing',
      StartTime: new Date(2018, 3, 4, 9, 15),
      EndTime: new Date(2018, 3, 4, 11, 45),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 2
  }, {
      Id: 9,
      Subject: 'Project Preview',
      StartTime: new Date(2018, 3, 5, 9, 30),
      EndTime: new Date(2018, 3, 5, 12, 45),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 10,
      Subject: 'Cross-browser testing',
      StartTime: new Date(2018, 3, 5, 13, 45),
      EndTime: new Date(2018, 3, 5, 16, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 11,
      Subject: 'Bug Automation',
      StartTime: new Date(2018, 3, 6, 10, 0),
      EndTime: new Date(2018, 3, 6, 12, 15),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 12,
      Subject: 'Functionality testing',
      StartTime: new Date(2018, 3, 6, 9, 0),
      EndTime: new Date(2018, 3, 6, 11, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 13,
      Subject: 'Resolution-based testing',
      StartTime: new Date(2018, 3, 7, 13, 0),
      EndTime: new Date(2018, 3, 7, 15, 15),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 14,
      Subject: 'Test report Validation',
      StartTime: new Date(2018, 3, 7, 9),
      EndTime: new Date(2018, 3, 7, 11),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 15,
      Subject: 'Test case correction',
      StartTime: new Date(2018, 3, 8, 9, 45),
      EndTime: new Date(2018, 3, 8, 11, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 16,
      Subject: 'Run test cases',
      StartTime: new Date(2018, 3, 8, 10, 30),
      EndTime: new Date(2018, 3, 8, 13, 0),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 17,
      Subject: 'Quality Analysis',
      StartTime: new Date(2018, 3, 9, 12),
      EndTime: new Date(2018, 3, 9, 15, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 18,
      Subject: 'Debugging',
      StartTime: new Date(2018, 3, 9, 9, 0),
      EndTime: new Date(2018, 3, 9, 11, 15),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 19,
      Subject: 'Exception handling',
      StartTime: new Date(2018, 3, 10, 10, 10),
      EndTime: new Date(2018, 3, 10, 13, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 20,
      Subject: 'Decoding',
      StartTime: new Date(2018, 3, 10, 10, 30),
      EndTime: new Date(2018, 3, 10, 12, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 21,
      Subject: 'workflow Analysis',
      StartTime: new Date(2018, 3, 11, 9, 30),
      EndTime: new Date(2018, 3, 11, 11, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 22,
      Subject: 'Requirement planning',
      StartTime: new Date(2018, 3, 11, 12, 30),
      EndTime: new Date(2018, 3, 11, 14, 45),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 2
  }, {
      Id: 23,
      Subject: 'Quality Analysis',
      StartTime: new Date(2018, 3, 12, 10),
      EndTime: new Date(2018, 3, 12, 12, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 24,
      Subject: 'Resource planning',
      StartTime: new Date(2018, 3, 12, 13),
      EndTime: new Date(2018, 3, 12, 14, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 2
  }, {
      Id: 25,
      Subject: 'Timeline estimation',
      StartTime: new Date(2018, 3, 13, 9),
      EndTime: new Date(2018, 3, 13, 11),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 26,
      Subject: 'Developers Meeting',
      StartTime: new Date(2018, 3, 13, 14),
      EndTime: new Date(2018, 3, 13, 15, 45),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 2
  }, {
      Id: 27,
      Subject: 'Project Review',
      StartTime: new Date(2018, 3, 14, 11),
      EndTime: new Date(2018, 3, 14, 13),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 28,
      Subject: 'Manual testing',
      StartTime: new Date(2018, 3, 14, 9),
      EndTime: new Date(2018, 3, 14, 11, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 29,
      Subject: 'Project Preview',
      StartTime: new Date(2018, 3, 15, 9, 30),
      EndTime: new Date(2018, 3, 15, 11),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 30,
      Subject: 'Cross-browser testing',
      StartTime: new Date(2018, 3, 15, 14),
      EndTime: new Date(2018, 3, 15, 16, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 31,
      Subject: 'Bug Automation',
      StartTime: new Date(2018, 3, 16, 10),
      EndTime: new Date(2018, 3, 16, 11),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 32,
      Subject: 'Functionality testing',
      StartTime: new Date(2018, 3, 16, 9),
      EndTime: new Date(2018, 3, 16, 11, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 33,
      Subject: 'Resolution-based testing',
      StartTime: new Date(2018, 3, 17, 14),
      EndTime: new Date(2018, 3, 17, 15),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 34,
      Subject: 'Test report Validation',
      StartTime: new Date(2018, 3, 17, 9),
      EndTime: new Date(2018, 3, 17, 11),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 2
  }, {
      Id: 35,
      Subject: 'Test case correction',
      StartTime: new Date(2018, 3, 18, 10),
      EndTime: new Date(2018, 3, 18, 11, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 36,
      Subject: 'Run test cases',
      StartTime: new Date(2018, 3, 18, 10),
      EndTime: new Date(2018, 3, 18, 10, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 2
  }, {
      Id: 37,
      Subject: 'Bug fixing',
      StartTime: new Date(2018, 3, 9, 10),
      EndTime: new Date(2018, 3, 9, 10, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 38,
      Subject: 'Debugging',
      StartTime: new Date(2018, 3, 19, 9),
      EndTime: new Date(2018, 3, 19, 10, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 39,
      Subject: 'Exception handling',
      StartTime: new Date(2018, 3, 20, 10),
      EndTime: new Date(2018, 3, 20, 11),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 40,
      Subject: 'Decoding',
      StartTime: new Date(2018, 3, 20, 10, 30),
      EndTime: new Date(2018, 3, 20, 12, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 41,
      Subject: 'workflow Analysis',
      StartTime: new Date(2018, 3, 21, 9, 30),
      EndTime: new Date(2018, 3, 21, 11, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 42,
      Subject: 'Requirement planning',
      StartTime: new Date(2018, 3, 21, 12, 30),
      EndTime: new Date(2018, 3, 21, 13, 45),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 2
  }, {
      Id: 43,
      Subject: 'Quality Analysis',
      StartTime: new Date(2018, 3, 22, 10),
      EndTime: new Date(2018, 3, 22, 11, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 44,
      Subject: 'Resource planning',
      StartTime: new Date(2018, 3, 4, 10),
      EndTime: new Date(2018, 3, 4, 12, 30),
      IsAllDay: false,
      ProjectId: 3,
      TaskId: 2
  }, {
      Id: 45,
      Subject: 'Timeline estimation',
      StartTime: new Date(2018, 3, 4, 9),
      EndTime: new Date(2018, 3, 4, 11),
      IsAllDay: false,
      ProjectId: 3,
      TaskId: 1
  }, {
      Id: 46,
      Subject: 'Developers Meeting',
      StartTime: new Date(2018, 3, 4, 14, 30),
      EndTime: new Date(2018, 3, 4, 16, 30),
      IsAllDay: false,
      ProjectId: 3,
      TaskId: 2
  }, {
      Id: 47,
      Subject: 'Project Review',
      StartTime: new Date(2018, 3, 4, 14),
      EndTime: new Date(2018, 3, 4, 16),
      IsAllDay: false,
      ProjectId: 3,
      TaskId: 1
  }, {
      Id: 48,
      Subject: 'Manual testing',
      StartTime: new Date(2018, 3, 4, 17, 30),
      EndTime: new Date(2018, 3, 4, 19, 30),
      IsAllDay: false,
      ProjectId: 3,
      TaskId: 2
  }, {
      Id: 49,
      Subject: 'Project Preview',
      StartTime: new Date(2018, 3, 4, 18, 0),
      EndTime: new Date(2018, 3, 4, 20),
      IsAllDay: false,
      ProjectId: 3,
      TaskId: 1
  }, {
      Id: 50,
      Subject: 'Cross-browser testing',
      StartTime: new Date(2018, 3, 25, 14),
      EndTime: new Date(2018, 3, 25, 15, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 51,
      Subject: 'Bug Automation',
      StartTime: new Date(2018, 3, 26, 10),
      EndTime: new Date(2018, 3, 26, 11),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 52,
      Subject: 'Functionality testing',
      StartTime: new Date(2018, 3, 26, 9),
      EndTime: new Date(2018, 3, 26, 11, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 2
  }, {
      Id: 53,
      Subject: 'Resolution-based testing',
      StartTime: new Date(2018, 3, 27, 14),
      EndTime: new Date(2018, 3, 27, 15),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 54,
      Subject: 'Test report Validation',
      StartTime: new Date(2018, 3, 27, 9),
      EndTime: new Date(2018, 3, 27, 11),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 55,
      Subject: 'Test case correction',
      StartTime: new Date(2018, 3, 28, 10),
      EndTime: new Date(2018, 3, 28, 11, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 56,
      Subject: 'Run test cases',
      StartTime: new Date(2018, 3, 28, 10),
      EndTime: new Date(2018, 3, 28, 10, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 57,
      Subject: 'Bug fixing',
      StartTime: new Date(2018, 3, 29, 12),
      EndTime: new Date(2018, 3, 29, 12, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 58,
      Subject: 'Debugging',
      StartTime: new Date(2018, 3, 29, 9),
      EndTime: new Date(2018, 3, 29, 10, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 59,
      Subject: 'Exception handling',
      StartTime: new Date(2018, 3, 30, 10),
      EndTime: new Date(2018, 3, 30, 11),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 60,
      Subject: 'Decoding',
      StartTime: new Date(2018, 3, 30, 10, 30),
      EndTime: new Date(2018, 3, 30, 12, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }
];

export let timelineResourceData: Object[] = [
  {
      Id: 61,
      Subject: 'Decoding',
      StartTime: new Date(2018, 3, 4, 9, 30),
      EndTime: new Date(2018, 3, 4, 10, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 2
  }, {
      Id: 62,
      Subject: 'Bug Automation',
      StartTime: new Date(2018, 3, 4, 16, 0),
      EndTime: new Date(2018, 3, 4, 20, 0),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 1
  }, {
      Id: 63,
      Subject: 'Functionality testing',
      StartTime: new Date(2018, 3, 4, 9),
      EndTime: new Date(2018, 3, 4, 10, 30),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 64,
      Subject: 'Resolution-based testing',
      StartTime: new Date(2018, 3, 4, 12),
      EndTime: new Date(2018, 3, 4, 15, 0),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 4
  }, {
      Id: 65,
      Subject: 'Test report Validation',
      StartTime: new Date(2018, 3, 4, 15),
      EndTime: new Date(2018, 3, 4, 18),
      IsAllDay: false,
      ProjectId: 1,
      TaskId: 1
  }, {
      Id: 66,
      Subject: 'Test case correction',
      StartTime: new Date(2018, 3, 4, 14),
      EndTime: new Date(2018, 3, 4, 16),
      IsAllDay: false,
      ProjectId: 3,
      TaskId: 6
  }, {
      Id: 67,
      Subject: 'Bug fixing',
      StartTime: new Date(2018, 3, 4, 14, 30),
      EndTime: new Date(2018, 3, 4, 18, 30),
      IsAllDay: false,
      ProjectId: 3,
      TaskId: 5
  }, {
      Id: 68,
      Subject: 'Run test cases',
      StartTime: new Date(2018, 3, 4, 17, 30),
      EndTime: new Date(2018, 3, 4, 19, 30),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 4
  }, {
      Id: 70,
      Subject: 'Bug Automation',
      StartTime: new Date(2018, 3, 4, 16, 0),
      EndTime: new Date(2018, 3, 4, 20, 0),
      IsAllDay: false,
      ProjectId: 2,
      TaskId: 3
  }
];

export let resourceTeamData: Object[] = [
  {
      Id: 1,
      Subject: 'Developers Meeting',
      StartTime: new Date(2018, 5, 1, 10, 0),
      EndTime: new Date(2018, 5, 1, 11, 0),
      RecurrenceRule: 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR',
      ProjectId: 1,
      CategoryId: 1
  }, {
      Id: 2,
      Subject: 'Test report Validation',
      StartTime: new Date(2018, 5, 2, 10, 30),
      EndTime: new Date(2018, 5, 2, 13, 0),
      RecurrenceRule: 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,WE,FR',
      ProjectId: 1,
      CategoryId: 2
  }, {
      Id: 3,
      Subject: 'Requirement planning',
      StartTime: new Date(2018, 5, 4, 9, 30),
      EndTime: new Date(2018, 5, 4, 10, 45),
      ProjectId: 2,
      CategoryId: 1
  }, {
      Id: 4,
      Subject: 'Bug Automation',
      StartTime: new Date(2018, 5, 2, 11, 0),
      EndTime: new Date(2018, 5, 2, 13, 0),
      RecurrenceRule: 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,WE,FR',
      ProjectId: 2,
      CategoryId: 2
  }, {
      Id: 5,
      Subject: 'Timeline estimation',
      StartTime: new Date(2018, 5, 3, 10, 0),
      EndTime: new Date(2018, 5, 3, 12, 0),
      ProjectId: 1,
      CategoryId: 1
  }, {
      Id: 6,
      Subject: 'Timeline estimation',
      StartTime: new Date(2018, 5, 3, 13, 0),
      EndTime: new Date(2018, 5, 3, 15, 0),
      ProjectId: 2,
      CategoryId: 1
  }, {
      Id: 7,
      Subject: 'Functionality testing',
      StartTime: new Date(2018, 5, 4, 14, 0),
      EndTime: new Date(2018, 5, 4, 15, 30),
      ProjectId: 1,
      CategoryId: 2
  }, {
      Id: 8,
      Subject: 'Functionality testing',
      StartTime: new Date(2018, 5, 4, 19, 0),
      EndTime: new Date(2018, 5, 4, 21, 0),
      ProjectId: 2,
      CategoryId: 2
  }, {
      Id: 9,
      Subject: 'Workflow Analysis',
      StartTime: new Date(2018, 5, 5, 14, 0),
      EndTime: new Date(2018, 5, 5, 15, 30),
      ProjectId: 1,
      CategoryId: 1
  }, {
      Id: 10,
      Subject: 'Quality Analysis',
      StartTime: new Date(2018, 5, 5, 13, 0),
      EndTime: new Date(2018, 5, 5, 16, 0),
      ProjectId: 2,
      CategoryId: 1
  }, {
      Id: 11,
      Subject: 'Cross-browser testing',
      StartTime: new Date(2018, 5, 5, 14, 45),
      EndTime: new Date(2018, 5, 5, 16, 15),
      ProjectId: 1,
      CategoryId: 2
  }, {
      Id: 12,
      Subject: 'Resolution-based testing',
      StartTime: new Date(2018, 5, 5, 15, 0),
      EndTime: new Date(2018, 5, 5, 17, 30),
      ProjectId: 2,
      CategoryId: 2
  }, {
      Id: 13,
      Subject: 'Project Preview',
      StartTime: new Date(2018, 5, 8, 16, 0),
      EndTime: new Date(2018, 5, 8, 18, 0),
      ProjectId: 1,
      CategoryId: 1
  }, {
      Id: 14,
      Subject: 'Project Preview',
      StartTime: new Date(2018, 5, 8, 15, 0),
      EndTime: new Date(2018, 5, 8, 17, 30),
      ProjectId: 2,
      CategoryId: 1
  }, {
      Id: 15,
      Subject: 'Test report Validation',
      StartTime: new Date(2018, 5, 8, 15, 30),
      EndTime: new Date(2018, 5, 8, 17, 45),
      ProjectId: 1,
      CategoryId: 2
  }, {
      Id: 16,
      Subject: 'Test report Validation',
      StartTime: new Date(2018, 5, 8, 15, 0),
      EndTime: new Date(2018, 5, 8, 17, 0),
      ProjectId: 2,
      CategoryId: 2
  }, {
      Id: 17,
      Subject: 'Resource planning',
      StartTime: new Date(2018, 5, 6, 15, 0),
      EndTime: new Date(2018, 5, 6, 18, 0),
      ProjectId: 1,
      CategoryId: 1
  }, {
      Id: 18,
      Subject: 'Resource planning',
      StartTime: new Date(2018, 5, 7, 16, 0),
      EndTime: new Date(2018, 5, 7, 17, 0),
      ProjectId: 2,
      CategoryId: 1
  }, {
      Id: 19,
      Subject: 'Run test cases',
      StartTime: new Date(2018, 5, 7, 14, 0),
      EndTime: new Date(2018, 5, 7, 18, 0),
      ProjectId: 1,
      CategoryId: 2
  }, {
      Id: 20,
      Subject: 'Run test cases',
      StartTime: new Date(2018, 5, 6, 14, 0),
      EndTime: new Date(2018, 5, 6, 17, 30),
      ProjectId: 2,
      CategoryId: 2
  }, {
      Id: 21,
      Subject: 'Resource planning',
      StartTime: new Date(2018, 5, 7, 9, 30),
      EndTime: new Date(2018, 5, 7, 11, 30),
      ProjectId: 2,
      CategoryId: 1
  }, {
      Id: 22,
      Subject: 'Developers Meeting',
      StartTime: new Date(2018, 5, 1, 12, 0),
      EndTime: new Date(2018, 5, 1, 13, 0),
      RecurrenceRule: 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR',
      ProjectId: 2,
      CategoryId: 1
  }
];

export let resourceConferenceData: Object[] = [
  {
      Id: 1,
      Subject: 'Burning Man',
      StartTime: new Date(2018, 5, 1, 15, 0),
      EndTime: new Date(2018, 5, 1, 17, 0),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 2,
      Subject: 'Data-Driven Economy',
      StartTime: new Date(2018, 5, 2, 12, 0),
      EndTime: new Date(2018, 5, 2, 14, 0),
      ConferenceId: [1, 2]
  }, {
      Id: 3,
      Subject: 'Techweek',
      StartTime: new Date(2018, 5, 2, 15, 0),
      EndTime: new Date(2018, 5, 2, 17, 0),
      ConferenceId: [2, 3]
  }, {
      Id: 4,
      Subject: 'Content Marketing World',
      StartTime: new Date(2018, 5, 2, 18, 0),
      EndTime: new Date(2018, 5, 2, 20, 0),
      ConferenceId: [1, 3]
  }, {
      Id: 5,
      Subject: 'B2B Marketing Forum',
      StartTime: new Date(2018, 5, 3, 10, 0),
      EndTime: new Date(2018, 5, 3, 12, 0),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 6,
      Subject: 'Business Innovation Factory',
      StartTime: new Date(2018, 5, 3, 13, 0),
      EndTime: new Date(2018, 5, 3, 15, 0),
      ConferenceId: [1, 2]
  }, {
      Id: 7,
      Subject: 'Grow Conference',
      StartTime: new Date(2018, 5, 3, 16, 0),
      EndTime: new Date(2018, 5, 3, 18, 0),
      ConferenceId: [2, 3]
  }, {
      Id: 8,
      Subject: 'Journalism Interactive',
      StartTime: new Date(2018, 5, 3, 19, 0),
      EndTime: new Date(2018, 5, 3, 21, 0),
      ConferenceId: [1, 3]
  }, {
      Id: 9,
      Subject: 'Blogcademy',
      StartTime: new Date(2018, 5, 4, 10, 0),
      EndTime: new Date(2018, 5, 4, 11, 30),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 10,
      Subject: 'Sustainable Brands',
      StartTime: new Date(2018, 5, 4, 13, 0),
      EndTime: new Date(2018, 5, 4, 15, 30),
      ConferenceId: [1, 2]
  }, {
      Id: 11,
      Subject: 'Fashion Confidential',
      StartTime: new Date(2018, 5, 4, 9, 0),
      EndTime: new Date(2018, 5, 4, 9, 45),
      ConferenceId: [2, 3]
  }, {
      Id: 12,
      Subject: 'Mobile World Conference',
      StartTime: new Date(2018, 5, 5, 12, 0),
      EndTime: new Date(2018, 5, 5, 14, 0),
      ConferenceId: [1, 3]
  }, {
      Id: 13,
      Subject: 'The Human Gathering',
      StartTime: new Date(2018, 5, 5, 15, 0),
      EndTime: new Date(2018, 5, 5, 17, 0),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 14,
      Subject: 'Web Summit',
      StartTime: new Date(2018, 5, 5, 18, 0),
      EndTime: new Date(2018, 5, 5, 20, 0),
      ConferenceId: [1, 2]
  }, {
      Id: 15,
      Subject: 'Funnel Hacking Live',
      StartTime: new Date(2018, 5, 6, 12, 0),
      EndTime: new Date(2018, 5, 6, 14, 0),
      ConferenceId: [1, 3]
  }, {
      Id: 16,
      Subject: 'Data Science Conference',
      StartTime: new Date(2018, 5, 6, 15, 0),
      EndTime: new Date(2018, 5, 6, 17, 0),
      ConferenceId: [2, 3]
  }, {
      Id: 17,
      Subject: 'Powerful Living Experience',
      StartTime: new Date(2018, 5, 6, 21, 0),
      EndTime: new Date(2018, 5, 6, 23, 30),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 18,
      Subject: 'World Domination Summit',
      StartTime: new Date(2018, 5, 7, 12, 0),
      EndTime: new Date(2018, 5, 7, 14, 0),
      ConferenceId: [2, 3]
  }, {
      Id: 19,
      Subject: 'Burning Man',
      StartTime: new Date(2018, 5, 7, 15, 0),
      EndTime: new Date(2018, 5, 7, 17, 0),
      ConferenceId: [1, 3]
  }, {
      Id: 20,
      Subject: 'Data-Driven Economy',
      StartTime: new Date(2018, 5, 7, 18, 0),
      EndTime: new Date(2018, 5, 7, 20, 0),
      ConferenceId: [1, 2]
  }, {
      Id: 21,
      Subject: 'Techweek',
      StartTime: new Date(2018, 5, 8, 12, 0),
      EndTime: new Date(2018, 5, 8, 14, 0),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 22,
      Subject: 'Content Marketing World',
      StartTime: new Date(2018, 5, 8, 15, 0),
      EndTime: new Date(2018, 5, 8, 17, 0),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 23,
      Subject: 'B2B Marketing Forum',
      StartTime: new Date(2018, 5, 8, 20, 30),
      EndTime: new Date(2018, 5, 8, 21, 30),
      ConferenceId: [1, 3]
  }, {
      Id: 24,
      Subject: 'Business Innovation Factory',
      StartTime: new Date(2018, 5, 9, 12, 0),
      EndTime: new Date(2018, 5, 9, 14, 0),
      ConferenceId: [2, 3]
  }, {
      Id: 25,
      Subject: 'Grow Conference',
      StartTime: new Date(2018, 5, 9, 15, 0),
      EndTime: new Date(2018, 5, 9, 17, 0),
      ConferenceId: [1, 2]
  }, {
      Id: 26,
      Subject: 'Journalism Interactive',
      StartTime: new Date(2018, 5, 9, 18, 0),
      EndTime: new Date(2018, 5, 9, 20, 0),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 27,
      Subject: 'Blogcademy',
      StartTime: new Date(2018, 5, 10, 12, 0),
      EndTime: new Date(2018, 5, 10, 14, 0),
      ConferenceId: [1, 3]
  }, {
      Id: 28,
      Subject: 'Sustainable Brands',
      StartTime: new Date(2018, 5, 10, 15, 0),
      EndTime: new Date(2018, 5, 10, 17, 0),
      ConferenceId: [2, 3]
  }, {
      Id: 29,
      Subject: 'Fashion Confidential',
      StartTime: new Date(2018, 5, 10, 18, 0),
      EndTime: new Date(2018, 5, 10, 20, 0),
      ConferenceId: [1, 2]
  }, {
      Id: 30,
      Subject: 'Mobile World Conference',
      StartTime: new Date(2018, 5, 11, 12, 0),
      EndTime: new Date(2018, 5, 11, 14, 0),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 31,
      Subject: 'The Human Gathering',
      StartTime: new Date(2018, 5, 11, 15, 0),
      EndTime: new Date(2018, 5, 11, 17, 0),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 32,
      Subject: 'Web Summit',
      StartTime: new Date(2018, 5, 11, 18, 0),
      EndTime: new Date(2018, 5, 11, 20, 0),
      ConferenceId: [3]
  }, {
      Id: 33,
      Subject: 'Funnel Hacking Live',
      StartTime: new Date(2018, 5, 12, 14, 0),
      EndTime: new Date(2018, 5, 12, 16, 0),
      ConferenceId: [1]
  }, {
      Id: 34,
      Subject: 'Data Science Conference',
      StartTime: new Date(2018, 5, 12, 14, 0),
      EndTime: new Date(2018, 5, 12, 16, 0),
      ConferenceId: [2]
  }, {
      Id: 35,
      Subject: 'Powerful Living Experience',
      StartTime: new Date(2018, 5, 12, 18, 0),
      EndTime: new Date(2018, 5, 12, 20, 0),
      ConferenceId: [1, 2]
  }, {
      Id: 36,
      Subject: 'World Domination Summit',
      StartTime: new Date(2018, 5, 12, 18, 0),
      EndTime: new Date(2018, 5, 12, 20, 0),
      ConferenceId: [3]
  }, {
      Id: 37,
      Subject: 'Burning Man',
      StartTime: new Date(2018, 5, 13, 14, 0),
      EndTime: new Date(2018, 5, 13, 16, 0),
      ConferenceId: [1, 3]
  }, {
      Id: 38,
      Subject: 'Data-Driven Economy',
      StartTime: new Date(2018, 5, 13, 14, 0),
      EndTime: new Date(2018, 5, 13, 16, 0),
      ConferenceId: [1]
  }, {
      Id: 39,
      Subject: 'Techweek',
      StartTime: new Date(2018, 5, 13, 18, 0),
      EndTime: new Date(2018, 5, 13, 20, 0),
      ConferenceId: [2, 3]
  }, {
      Id: 40,
      Subject: 'Content Marketing World',
      StartTime: new Date(2018, 5, 13, 18, 0),
      EndTime: new Date(2018, 5, 13, 20, 0),
      ConferenceId: [1, 2]
  }, {
      Id: 41,
      Subject: 'B2B Marketing Forum',
      StartTime: new Date(2018, 5, 14, 14, 0),
      EndTime: new Date(2018, 5, 14, 16, 0),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 42,
      Subject: 'Business Innovation Factory',
      StartTime: new Date(2018, 5, 14, 14, 0),
      EndTime: new Date(2018, 5, 14, 16, 0),
      ConferenceId: [2, 3]
  }, {
      Id: 43,
      Subject: 'Grow Conference',
      StartTime: new Date(2018, 5, 14, 18, 0),
      EndTime: new Date(2018, 5, 14, 20, 0),
      ConferenceId: [3]
  }, {
      Id: 44,
      Subject: 'Journalism Interactive',
      StartTime: new Date(2018, 5, 14, 18, 0),
      EndTime: new Date(2018, 5, 14, 20, 0),
      ConferenceId: [1, 2, 3]
  }, {
      Id: 45,
      Subject: 'Blogcademy',
      StartTime: new Date(2018, 5, 15, 14, 0),
      EndTime: new Date(2018, 5, 15, 16, 0),
      ConferenceId: [1, 3]
  }, {
      Id: 46,
      Subject: 'Sustainable Brands',
      StartTime: new Date(2018, 5, 15, 14, 0),
      EndTime: new Date(2018, 5, 15, 16, 0),
      ConferenceId: [1, 3]
  }, {
      Id: 47,
      Subject: 'Fashion Confidential',
      StartTime: new Date(2018, 5, 15, 18, 0),
      EndTime: new Date(2018, 5, 15, 20, 0),
      ConferenceId: [1, 2]
  }, {
      Id: 48,
      Subject: 'Mobile World Conference',
      StartTime: new Date(2018, 5, 15, 18, 0),
      EndTime: new Date(2018, 5, 15, 20, 0),
      ConferenceId: [2, 3]
  }
];

export let holidayData: Object[] = [
  {
      Id: 401,
      Subject: 'Global Family Day',
      StartTime: new Date(2018, 0, 1),
      EndTime: new Date(2018, 0, 2),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 402,
      Subject: 'World Braille Day',
      StartTime: new Date(2018, 0, 4),
      EndTime: new Date(2018, 0, 5),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 403,
      Subject: 'World Literary Day',
      StartTime: new Date(2018, 0, 8),
      EndTime: new Date(2018, 0, 9),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 404,
      Subject: 'International Thank-You Day',
      StartTime: new Date(2018, 0, 11),
      EndTime: new Date(2018, 0, 12),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 405,
      Subject: 'World Leprosy Day',
      StartTime: new Date(2018, 0, 30),
      EndTime: new Date(2018, 0, 31),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 406,
      Subject: 'Darwin Day',
      StartTime: new Date(2018, 1, 12),
      EndTime: new Date(2018, 1, 13),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 407,
      Subject: 'International Mother Language Day',
      StartTime: new Date(2018, 1, 21),
      EndTime: new Date(2018, 1, 22),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 408,
      Subject: 'World Thinking Day',
      StartTime: new Date(2018, 1, 22),
      EndTime: new Date(2018, 1, 23),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 409,
      Subject: 'International Day of the Seal',
      StartTime: new Date(2018, 2, 1),
      EndTime: new Date(2018, 2, 2),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 410,
      Subject: 'International Women’s Day',
      StartTime: new Date(2018, 2, 8),
      EndTime: new Date(2018, 2, 9),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 411,
      Subject: 'World Book Day',
      StartTime: new Date(2018, 2, 14),
      EndTime: new Date(2018, 2, 15),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 412,
      Subject: 'World Frog Day',
      StartTime: new Date(2018, 2, 20),
      EndTime: new Date(2018, 2, 21),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 413,
      Subject: 'World Down Syndrome Day',
      StartTime: new Date(2018, 2, 21),
      EndTime: new Date(2018, 2, 22),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 414,
      Subject: 'World Day for Water',
      StartTime: new Date(2018, 2, 22),
      EndTime: new Date(2018, 2, 23),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 415,
      Subject: 'World Meteorological Day',
      StartTime: new Date(2018, 2, 23),
      EndTime: new Date(2018, 2, 24),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 416,
      Subject: 'International Children’s Book Day',
      StartTime: new Date(2018, 3, 2),
      EndTime: new Date(2018, 3, 3),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 417,
      Subject: 'World Health Day',
      StartTime: new Date(2018, 3, 7),
      EndTime: new Date(2018, 3, 8),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 418,
      Subject: 'International Special Librarian’s',
      StartTime: new Date(2018, 3, 13),
      EndTime: new Date(2018, 3, 14),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 419,
      Subject: 'International Creativity and Innovation Day',
      StartTime: new Date(2018, 3, 21),
      EndTime: new Date(2018, 3, 22),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 420,
      Subject: 'Earth Day',
      StartTime: new Date(2018, 3, 22),
      EndTime: new Date(2018, 3, 23),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 421,
      Subject: 'World Copyright Day',
      StartTime: new Date(2018, 3, 23),
      EndTime: new Date(2018, 3, 24),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 422,
      Subject: 'World Penguin Day',
      StartTime: new Date(2018, 3, 25),
      EndTime: new Date(2018, 3, 26),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 423,
      Subject: 'World Press Freedom Day',
      StartTime: new Date(2018, 4, 3),
      EndTime: new Date(2018, 4, 4),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 424,
      Subject: 'International Midwives Day',
      StartTime: new Date(2018, 4, 5),
      EndTime: new Date(2018, 4, 5),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 425,
      Subject: 'World Red Cross Day',
      StartTime: new Date(2018, 4, 8),
      EndTime: new Date(2018, 4, 9),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 426,
      Subject: 'World Lupus Day',
      StartTime: new Date(2018, 4, 10),
      EndTime: new Date(2018, 4, 11),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 427,
      Subject: 'International Nurses Day',
      StartTime: new Date(2018, 4, 12),
      EndTime: new Date(2018, 4, 12),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 428,
      Subject: 'IEEE Global Engineering Day',
      StartTime: new Date(2018, 4, 13),
      EndTime: new Date(2018, 4, 14),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 429,
      Subject: 'International Day of Families',
      StartTime: new Date(2018, 4, 15),
      EndTime: new Date(2018, 4, 16),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 430,
      Subject: 'International Museum Day',
      StartTime: new Date(2018, 4, 18),
      EndTime: new Date(2018, 4, 19),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 431,
      Subject: 'World Turtle Day',
      StartTime: new Date(2018, 4, 23),
      EndTime: new Date(2018, 4, 24),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 432,
      Subject: 'World No-Tobacco Day',
      StartTime: new Date(2018, 4, 31),
      EndTime: new Date(2018, 5, 1),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 433,
      Subject: 'World Ocean Day',
      StartTime: new Date(2018, 5, 8),
      EndTime: new Date(2018, 5, 9),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 434,
      Subject: 'World Blood Donor Day',
      StartTime: new Date(2018, 5, 14),
      EndTime: new Date(2018, 5, 15),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 435,
      Subject: 'World Day to Combat Desertification & Drought',
      StartTime: new Date(2018, 5, 17),
      EndTime: new Date(2018, 5, 18),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 436,
      Subject: 'World Refugee Day',
      StartTime: new Date(2018, 5, 20),
      EndTime: new Date(2018, 5, 21),
      IsAllDay: true,
      CalendarId: 4
  }, {
      Id: 437,
      Subject: 'International Day Against Drug Abuse & Trafficking',
      StartTime: new Date(2018, 5, 26),
      EndTime: new Date(2018, 5, 27),
      IsAllDay: true,
      CalendarId: 4
  }
];

export let birthdayData: Object[] = [
  {
      Id: 301,
      Subject: 'Gladys Spellman',
      StartTime: new Date(2018, 2, 1),
      EndTime: new Date(2018, 2, 2),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 302,
      Subject: 'Susanna Salter',
      StartTime: new Date(2018, 2, 2),
      EndTime: new Date(2018, 2, 3),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 303,
      Subject: 'Dora Marsden',
      StartTime: new Date(2018, 2, 5),
      EndTime: new Date(2018, 2, 6),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 304,
      Subject: 'Anne Bonny',
      StartTime: new Date(2018, 2, 8),
      EndTime: new Date(2018, 2, 9),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 305,
      Subject: 'Clare Boothe Luce',
      StartTime: new Date(2018, 2, 10),
      EndTime: new Date(2018, 2, 11),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 306,
      Subject: 'Ethel Anderson',
      StartTime: new Date(2018, 2, 16),
      EndTime: new Date(2018, 2, 17),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 307,
      Subject: 'Louise Otto-Peters',
      StartTime: new Date(2018, 2, 26),
      EndTime: new Date(2018, 2, 27),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 308,
      Subject: 'Faith Leech',
      StartTime: new Date(2018, 2, 31),
      EndTime: new Date(2018, 3, 1),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 309,
      Subject: 'Wilhelmine Reichard',
      StartTime: new Date(2018, 3, 2),
      EndTime: new Date(2018, 3, 3),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 310,
      Subject: 'Janet Rowley',
      StartTime: new Date(2018, 3, 5),
      EndTime: new Date(2018, 3, 6),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 311,
      Subject: 'Kathleen Major',
      StartTime: new Date(2018, 3, 10),
      EndTime: new Date(2018, 3, 11),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 312,
      Subject: 'Kasturba Gandhi',
      StartTime: new Date(2018, 3, 11),
      EndTime: new Date(2018, 3, 12),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 313,
      Subject: 'Elizabeth Huckaby',
      StartTime: new Date(2018, 3, 14),
      EndTime: new Date(2018, 3, 15),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 314,
      Subject: 'Helene Hanff',
      StartTime: new Date(2018, 3, 15),
      EndTime: new Date(2018, 3, 16),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 315,
      Subject: 'Caresse Crosby',
      StartTime: new Date(2018, 3, 20),
      EndTime: new Date(2018, 3, 21),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 316,
      Subject: 'Angela Burdett-Coutts',
      StartTime: new Date(2018, 3, 21),
      EndTime: new Date(2018, 3, 22),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 317,
      Subject: 'Pandita Ramabai',
      StartTime: new Date(2018, 3, 23),
      EndTime: new Date(2018, 3, 24),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 318,
      Subject: 'Melissa Hayden',
      StartTime: new Date(2018, 3, 25),
      EndTime: new Date(2018, 3, 26),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 319,
      Subject: 'Mary Wollstonecraft',
      StartTime: new Date(2018, 3, 27),
      EndTime: new Date(2018, 3, 28),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 320,
      Subject: 'Mary Petty',
      StartTime: new Date(2018, 3, 29),
      EndTime: new Date(2018, 3, 30),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 321,
      Subject: 'Doris Fisher',
      StartTime: new Date(2018, 4, 2),
      EndTime: new Date(2018, 4, 3),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 322,
      Subject: 'Kay Petre',
      StartTime: new Date(2018, 4, 10),
      EndTime: new Date(2018, 4, 11),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 323,
      Subject: 'Williamina Fleming',
      StartTime: new Date(2018, 4, 15),
      EndTime: new Date(2018, 4, 16),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 324,
      Subject: 'Ondina Valla',
      StartTime: new Date(2018, 4, 20),
      EndTime: new Date(2018, 4, 21),
      IsAllDay: true,
      CalendarId: 3
  }, {
      Id: 325,
      Subject: 'Marie Menken',
      StartTime: new Date(2018, 4, 25),
      EndTime: new Date(2018, 4, 26),
      IsAllDay: true,
      CalendarId: 3
  }
];

export let companyData: Object[] = [
  {
      Id: 201,
      Subject: 'Conference meeting',
      StartTime: new Date(2018, 2, 1),
      EndTime: new Date(2018, 2, 2),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 202,
      Subject: 'Product discussion',
      StartTime: new Date(2018, 2, 4),
      EndTime: new Date(2018, 2, 5),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 203,
      Subject: 'Companys growth related discussion',
      StartTime: new Date(2018, 2, 8),
      EndTime: new Date(2018, 2, 9),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 204,
      Subject: 'Customer issues',
      StartTime: new Date(2018, 2, 11),
      EndTime: new Date(2018, 2, 12),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 205,
      Subject: 'Development related chat',
      StartTime: new Date(2018, 2, 13),
      EndTime: new Date(2018, 2, 14),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 206,
      Subject: 'Product meeting',
      StartTime: new Date(2018, 2, 18),
      EndTime: new Date(2018, 2, 19),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 207,
      Subject: 'General discussion',
      StartTime: new Date(2018, 2, 21),
      EndTime: new Date(2018, 2, 22),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 208,
      Subject: 'Hike discussion',
      StartTime: new Date(2018, 2, 24),
      EndTime: new Date(2018, 2, 25),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 209,
      Subject: 'Customer meeting',
      StartTime: new Date(2018, 2, 28),
      EndTime: new Date(2018, 2, 29),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 210,
      Subject: 'New launch discussion',
      StartTime: new Date(2018, 2, 30),
      EndTime: new Date(2018, 2, 31),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 211,
      Subject: 'Conference Meeting',
      StartTime: new Date(2018, 3, 1),
      EndTime: new Date(2018, 3, 2),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 212,
      Subject: 'Product Discussion',
      StartTime: new Date(2018, 3, 3),
      EndTime: new Date(2018, 3, 4),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 213,
      Subject: 'Companys growth related issues',
      StartTime: new Date(2018, 3, 7),
      EndTime: new Date(2018, 3, 8),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 214,
      Subject: 'Customer issues',
      StartTime: new Date(2018, 3, 12),
      EndTime: new Date(2018, 3, 13),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 215,
      Subject: 'Development related chat',
      StartTime: new Date(2018, 3, 15),
      EndTime: new Date(2018, 3, 16),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 216,
      Subject: 'Product meeting',
      StartTime: new Date(2018, 3, 18),
      EndTime: new Date(2018, 3, 19),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 217,
      Subject: 'General discussion',
      StartTime: new Date(2018, 3, 21),
      EndTime: new Date(2018, 3, 22),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 218,
      Subject: 'Hike discussion',
      StartTime: new Date(2018, 3, 24),
      EndTime: new Date(2018, 3, 25),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 219,
      Subject: 'Customer meeting',
      StartTime: new Date(2018, 3, 26),
      EndTime: new Date(2018, 3, 27),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 220,
      Subject: 'New launch discussion',
      StartTime: new Date(2018, 3, 29),
      EndTime: new Date(2018, 3, 30),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 221,
      Subject: 'Conference Meeting',
      StartTime: new Date(2018, 4, 1),
      EndTime: new Date(2018, 4, 2),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 222,
      Subject: 'Product Discussion',
      StartTime: new Date(2018, 4, 3),
      EndTime: new Date(2018, 4, 4),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 223,
      Subject: 'Companys growth related issues',
      StartTime: new Date(2018, 4, 9),
      EndTime: new Date(2018, 4, 10),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 224,
      Subject: 'Customer issues',
      StartTime: new Date(2018, 4, 13),
      EndTime: new Date(2018, 4, 14),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 225,
      Subject: 'Development related chat',
      StartTime: new Date(2018, 4, 15),
      EndTime: new Date(2018, 4, 16),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 226,
      Subject: 'Product meeting',
      StartTime: new Date(2018, 4, 18),
      EndTime: new Date(2018, 4, 19),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 227,
      Subject: 'General discussion',
      StartTime: new Date(2018, 4, 21),
      EndTime: new Date(2018, 4, 22),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 228,
      Subject: 'Hike discussion',
      StartTime: new Date(2018, 4, 24),
      EndTime: new Date(2018, 4, 25),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 229,
      Subject: 'Customer meeting',
      StartTime: new Date(2018, 4, 26),
      EndTime: new Date(2018, 4, 27),
      IsAllDay: true,
      CalendarId: 2
  }, {
      Id: 230,
      Subject: 'New launch discussion',
      StartTime: new Date(2018, 4, 29),
      EndTime: new Date(2018, 4, 30),
      IsAllDay: true,
      CalendarId: 2
  }
];

export let personalData: Object[] = [
  {
      Id: 101,
      Subject: 'Father Birthday',
      StartTime: new Date(2018, 2, 1),
      EndTime: new Date(2018, 2, 2),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 102,
      Subject: 'Engagement day',
      StartTime: new Date(2018, 2, 4),
      EndTime: new Date(2018, 2, 5),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 103,
      Subject: 'Wedding day',
      StartTime: new Date(2018, 2, 8),
      EndTime: new Date(2018, 2, 9),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 104,
      Subject: 'Mother Birthday',
      StartTime: new Date(2018, 2, 11),
      EndTime: new Date(2018, 2, 12),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 105,
      Subject: 'Peter`s Wedding Day',
      StartTime: new Date(2018, 2, 13),
      EndTime: new Date(2018, 2, 14),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 106,
      Subject: 'Family Trip',
      StartTime: new Date(2018, 2, 18),
      EndTime: new Date(2018, 2, 19),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 107,
      Subject: 'Cousin Wedding Ceremony',
      StartTime: new Date(2018, 2, 21),
      EndTime: new Date(2018, 2, 22),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 108,
      Subject: 'Family Meetup',
      StartTime: new Date(2018, 2, 24),
      EndTime: new Date(2018, 2, 25),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 109,
      Subject: 'Grandfather Birthday',
      StartTime: new Date(2018, 2, 28),
      EndTime: new Date(2018, 2, 29),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 110,
      Subject: 'Sister-in-law Wedding Ceremony',
      StartTime: new Date(2018, 2, 30),
      EndTime: new Date(2018, 2, 31),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 111,
      Subject: 'Family Meetup',
      StartTime: new Date(2018, 3, 1),
      EndTime: new Date(2018, 3, 2),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 112,
      Subject: 'Grandparent Wedding Day',
      StartTime: new Date(2018, 3, 3),
      EndTime: new Date(2018, 3, 4),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 113,
      Subject: 'Cousin Wedding Ceremony',
      StartTime: new Date(2018, 3, 7),
      EndTime: new Date(2018, 3, 8),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 114,
      Subject: 'Family Vacation Trip',
      StartTime: new Date(2018, 3, 12),
      EndTime: new Date(2018, 3, 13),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 115,
      Subject: 'Brother-in-law Birthday',
      StartTime: new Date(2018, 3, 15),
      EndTime: new Date(2018, 3, 16),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 116,
      Subject: 'Brother`s Birthday',
      StartTime: new Date(2018, 3, 18),
      EndTime: new Date(2018, 3, 19),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 117,
      Subject: 'Sister Wedding Anniversary',
      StartTime: new Date(2018, 3, 21),
      EndTime: new Date(2018, 3, 22),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 118,
      Subject: 'Family Vacation Trip',
      StartTime: new Date(2018, 3, 24),
      EndTime: new Date(2018, 3, 25),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 119,
      Subject: 'Wedding Anniversary',
      StartTime: new Date(2018, 3, 26),
      EndTime: new Date(2018, 3, 27),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 120,
      Subject: 'Month end trip',
      StartTime: new Date(2018, 3, 29),
      EndTime: new Date(2018, 3, 30),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 121,
      Subject: 'John Birthday',
      StartTime: new Date(2018, 4, 1),
      EndTime: new Date(2018, 4, 2),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 122,
      Subject: 'Vishnu Birthday',
      StartTime: new Date(2018, 4, 3),
      EndTime: new Date(2018, 4, 4),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 123,
      Subject: 'Family Trip',
      StartTime: new Date(2018, 4, 9),
      EndTime: new Date(2018, 4, 10),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 124,
      Subject: 'Revanth Wedding Anniversary',
      StartTime: new Date(2018, 4, 13),
      EndTime: new Date(2018, 4, 14),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 125,
      Subject: 'Family Meetup',
      StartTime: new Date(2018, 4, 15),
      EndTime: new Date(2018, 4, 16),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 126,
      Subject: 'Family get-together',
      StartTime: new Date(2018, 4, 18),
      EndTime: new Date(2018, 4, 19),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 127,
      Subject: 'Friends Reunion',
      StartTime: new Date(2018, 4, 21),
      EndTime: new Date(2018, 4, 22),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 128,
      Subject: 'Rahul Wedding Anniversary Celebration',
      StartTime: new Date(2018, 4, 24),
      EndTime: new Date(2018, 4, 25),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 129,
      Subject: 'Vacation Trip with friends',
      StartTime: new Date(2018, 4, 26),
      EndTime: new Date(2018, 4, 27),
      IsAllDay: true,
      CalendarId: 1
  }, {
      Id: 130,
      Subject: 'Friends Reunion',
      StartTime: new Date(2018, 4, 29),
      EndTime: new Date(2018, 4, 30),
      IsAllDay: true,
      CalendarId: 1
  }
];

export let doctorData: Object[] = [
  {
      Id: 1,
      Subject: 'Echocardiogram',
      StartTime: new Date(2018, 3, 2, 9, 30),
      EndTime: new Date(2018, 3, 2, 11, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 2,
      Subject: 'Lumbar punctures',
      StartTime: new Date(2018, 3, 2, 9, 30),
      EndTime: new Date(2018, 3, 2, 10, 45),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 3,
      Subject: 'Osteoarthritis',
      StartTime: new Date(2018, 3, 2, 8),
      EndTime: new Date(2018, 3, 2, 10, 30),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 4,
      Subject: 'Ambulatory ECG',
      StartTime: new Date(2018, 3, 3, 12),
      EndTime: new Date(2018, 3, 3, 12, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 5,
      Subject: 'Osteoporosis',
      StartTime: new Date(2018, 3, 3, 11),
      EndTime: new Date(2018, 3, 3, 11, 50),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 6,
      Subject: 'Neuromuscular',
      StartTime: new Date(2018, 3, 4, 11, 30),
      EndTime: new Date(2018, 3, 4, 13, 30),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 7,
      Subject: 'Rheumatoid arthritis',
      StartTime: new Date(2018, 3, 4, 13, 40),
      EndTime: new Date(2018, 3, 4, 14, 40),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 8,
      Subject: 'Cardiac Catheterization',
      StartTime: new Date(2018, 3, 5, 11, 30),
      EndTime: new Date(2018, 3, 5, 13),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 9,
      Subject: 'Growth abnormalities',
      StartTime: new Date(2018, 3, 5, 14),
      EndTime: new Date(2018, 3, 5, 15, 30),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 10,
      Subject: 'Sleep disorders',
      StartTime: new Date(2018, 3, 6, 12),
      EndTime: new Date(2018, 3, 6, 14),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 11,
      Subject: 'Torn ligaments',
      StartTime: new Date(2018, 3, 6, 13, 30),
      EndTime: new Date(2018, 3, 6, 14, 45),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 12,
      Subject: 'Coronary angiogram',
      StartTime: new Date(2018, 3, 6, 8),
      EndTime: new Date(2018, 3, 6, 9, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 13,
      Subject: 'Blood pressure',
      StartTime: new Date(2018, 3, 9, 12),
      EndTime: new Date(2018, 3, 9, 12, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 14,
      Subject: 'Radiculopathy',
      StartTime: new Date(2018, 3, 9, 15, 45),
      EndTime: new Date(2018, 3, 9, 16, 30),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 15,
      Subject: 'Sprains and strains',
      StartTime: new Date(2018, 3, 9, 14),
      EndTime: new Date(2018, 3, 9, 15, 30),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 16,
      Subject: 'Cardiac stress testing',
      StartTime: new Date(2018, 3, 10, 10),
      EndTime: new Date(2018, 3, 10, 10, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 17,
      Subject: 'Tendon injuries',
      StartTime: new Date(2018, 3, 10, 14),
      EndTime: new Date(2018, 3, 10, 15, 30),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 18,
      Subject: 'Dementia',
      StartTime: new Date(2018, 3, 11, 15),
      EndTime: new Date(2018, 3, 11, 17),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 19,
      Subject: 'Pulled muscles',
      StartTime: new Date(2018, 3, 11, 13, 30),
      EndTime: new Date(2018, 3, 11, 15, 50),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 20,
      Subject: 'Coronary angiogram',
      StartTime: new Date(2018, 3, 12, 10, 30),
      EndTime: new Date(2018, 3, 12, 12, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 21,
      Subject: 'Back pain',
      StartTime: new Date(2018, 3, 12, 10, 30),
      EndTime: new Date(2018, 3, 12, 11, 30),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 22,
      Subject: 'Neuropathy',
      StartTime: new Date(2018, 3, 13, 12, 30),
      EndTime: new Date(2018, 3, 13, 13, 45),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 23,
      Subject: 'Ruptured disks',
      StartTime: new Date(2018, 3, 13, 13),
      EndTime: new Date(2018, 3, 13, 15, 50),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 24,
      Subject: 'Atherosclerosis',
      StartTime: new Date(2018, 3, 13, 10),
      EndTime: new Date(2018, 3, 13, 12, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 25,
      Subject: 'Arthroplasty',
      StartTime: new Date(2018, 3, 16, 9),
      EndTime: new Date(2018, 3, 16, 10),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 26,
      Subject: 'Hyperactivity disorder',
      StartTime: new Date(2018, 3, 16, 14),
      EndTime: new Date(2018, 3, 16, 15, 45),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 27,
      Subject: 'Muscular dystrophy',
      StartTime: new Date(2018, 3, 16, 13, 10),
      EndTime: new Date(2018, 3, 16, 15, 20),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 28,
      Subject: 'Consulting',
      StartTime: new Date(2018, 3, 17, 9, 20),
      EndTime: new Date(2018, 3, 17, 10, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 29,
      Subject: 'Hand surgery',
      StartTime: new Date(2018, 3, 17, 13, 20),
      EndTime: new Date(2018, 3, 17, 15, 22),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 30,
      Subject: 'Neuromuscular',
      StartTime: new Date(2018, 3, 18, 14),
      EndTime: new Date(2018, 3, 18, 15, 40),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 31,
      Subject: 'Spine surgery',
      StartTime: new Date(2018, 3, 18, 12, 18),
      EndTime: new Date(2018, 3, 18, 14, 23),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 32,
      Subject: 'Fibrinogen',
      StartTime: new Date(2018, 3, 19, 9),
      EndTime: new Date(2018, 3, 19, 12, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 33,
      Subject: 'Bone tumors',
      StartTime: new Date(2018, 3, 19, 10, 45),
      EndTime: new Date(2018, 3, 19, 12, 20),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 34,
      Subject: 'Neuromuscular',
      StartTime: new Date(2018, 3, 20, 13),
      EndTime: new Date(2018, 3, 20, 17),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 35,
      Subject: 'Osteoporosis',
      StartTime: new Date(2018, 3, 20, 11, 45),
      EndTime: new Date(2018, 3, 20, 14, 30),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 36,
      Subject: 'Triglyceride',
      StartTime: new Date(2018, 3, 20, 9, 30),
      EndTime: new Date(2018, 3, 20, 10, 45),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 37,
      Subject: 'Fibrinogen',
      StartTime: new Date(2018, 3, 23, 8),
      EndTime: new Date(2018, 3, 23, 12, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 38,
      Subject: 'Head trauma',
      StartTime: new Date(2018, 3, 23, 12),
      EndTime: new Date(2018, 3, 23, 15),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 39,
      Subject: 'Arthroplasty',
      StartTime: new Date(2018, 3, 23, 12, 18),
      EndTime: new Date(2018, 3, 23, 13, 22),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 40,
      Subject: 'Echocardiogram',
      StartTime: new Date(2018, 3, 24, 10, 30),
      EndTime: new Date(2018, 3, 24, 12, 40),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 41,
      Subject: 'Skull reconstruction',
      StartTime: new Date(2018, 3, 24, 13, 20),
      EndTime: new Date(2018, 3, 24, 15, 45),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 42,
      Subject: 'Dementia',
      StartTime: new Date(2018, 3, 25, 12, 30),
      EndTime: new Date(2018, 3, 25, 16, 45),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 43,
      Subject: 'Orthopedic trauma',
      StartTime: new Date(2018, 3, 25, 10, 18),
      EndTime: new Date(2018, 3, 25, 12, 20),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 44,
      Subject: 'Blood pressure',
      StartTime: new Date(2018, 3, 26, 10, 50),
      EndTime: new Date(2018, 3, 26, 12, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 45,
      Subject: 'Ruptured disks',
      StartTime: new Date(2018, 3, 26, 12, 50),
      EndTime: new Date(2018, 3, 26, 15, 20),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 46,
      Subject: 'Head trauma',
      StartTime: new Date(2018, 3, 27, 11, 50),
      EndTime: new Date(2018, 3, 27, 12, 45),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 47,
      Subject: 'Cerebral palsy',
      StartTime: new Date(2018, 3, 27, 14, 50),
      EndTime: new Date(2018, 3, 27, 15, 50),
      IsAllDay: false,
      DoctorId: 3
  }, {
      Id: 48,
      Subject: 'Consulting',
      StartTime: new Date(2018, 3, 27, 9),
      EndTime: new Date(2018, 3, 27, 11, 30),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 49,
      Subject: 'Electrocardiogram',
      StartTime: new Date(2018, 3, 30, 9, 30),
      EndTime: new Date(2018, 3, 30, 11, 50),
      IsAllDay: false,
      DoctorId: 1
  }, {
      Id: 50,
      Subject: 'Radiculopathy',
      StartTime: new Date(2018, 3, 30, 14),
      EndTime: new Date(2018, 3, 30, 15, 30),
      IsAllDay: false,
      DoctorId: 2
  }, {
      Id: 51,
      Subject: 'Skull reconstruction',
      StartTime: new Date(2018, 3, 30, 14),
      EndTime: new Date(2018, 3, 30, 16),
      IsAllDay: false,
      DoctorId: 3
  }
];

export let resAirlinesData: Object[] = [
  {
      Id: 1,
      Subject: '#SG 208 Greenville - Washington',
      StartTime: new Date(2018, 3, 2, 10, 0),
      EndTime: new Date(2018, 3, 2, 12, 30),
      IsAllDay: false,
      AirlineId: 1
  }, {
      Id: 2,
      Subject: '#IT 188 Washington - Arizona',
      StartTime: new Date(2018, 3, 2, 4, 0),
      EndTime: new Date(2018, 3, 2, 5, 0),
      IsAllDay: false,
      AirlineId: 3
  }, {
      Id: 3,
      Subject: '#SG 300 Chicago - Nevada',
      StartTime: new Date(2018, 3, 2, 11, 0),
      EndTime: new Date(2018, 3, 2, 13, 0),
      IsAllDay: false,
      AirlineId: 2
  }, {
      Id: 4,
      Subject: '#IT 306 Washington - Newport',
      StartTime: new Date(2018, 3, 4, 4, 0),
      EndTime: new Date(2018, 3, 4, 5, 0),
      IsAllDay: false,
      AirlineId: 1
  }, {
      Id: 5,
      Subject: '#AI 520 Washington - Chicago',
      StartTime: new Date(2018, 3, 3, 10, 30),
      EndTime: new Date(2018, 3, 3, 12, 30),
      IsAllDay: false,
      AirlineId: 3
  }, {
      Id: 6,
      Subject: '#SG 345 Renfrewshire - Lancashire',
      StartTime: new Date(2018, 3, 5, 6, 30),
      EndTime: new Date(2018, 3, 5, 7, 30),
      IsAllDay: false,
      AirlineId: 2
  }
];

export let fifaEventsData: Object[] = [
  {
      Id: 1,
      Subject: 'RUSSIA vs SAUDI ARABIA',
      Description: 'Group A',
      StartTime: new Date(2018, 5, 14, 15, 0),
      EndTime: new Date(2018, 5, 14, 17, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Moscow',
      CategoryColor: '#1aaa55',
      GroupId: 1
  }, {
      Id: 2,
      Subject: 'EGYPT vs URUGUAY',
      Description: 'Group A',
      StartTime: new Date(2018, 5, 15, 12, 0),
      EndTime: new Date(2018, 5, 15, 14, 0),
      StartTimezone: 'Asia/Yekaterinburg',
      EndTimezone: 'Asia/Yekaterinburg',
      City: 'Ekaterinburg',
      CategoryColor: '#1aaa55',
      GroupId: 1
  }, {
      Id: 3,
      Subject: 'MOROCCO vs IR IRAN',
      Description: 'Group B',
      StartTime: new Date(2018, 5, 15, 15, 0),
      EndTime: new Date(2018, 5, 15, 17, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Saint Petersburg',
      CategoryColor: '#357cd2',
      GroupId: 2
  }, {
      Id: 4,
      Subject: 'PORTUGAL vs SPAIN',
      Description: 'Group B',
      StartTime: new Date(2018, 5, 15, 18, 0),
      EndTime: new Date(2018, 5, 15, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Sochi',
      CategoryColor: '#357cd2',
      GroupId: 2
  }, {
      Id: 5,
      Subject: 'FRANCE vs AUSTRALIA',
      Description: 'Group C',
      StartTime: new Date(2018, 5, 16, 10, 0),
      EndTime: new Date(2018, 5, 16, 12, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Kazan',
      CategoryColor: '#7fa900',
      GroupId: 3
  }, {
      Id: 6,
      Subject: 'ARGENTINA vs ICELAND',
      Description: 'Group D',
      StartTime: new Date(2018, 5, 16, 13, 0),
      EndTime: new Date(2018, 5, 16, 15, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Moscow',
      CategoryColor: '#ea7a57',
      GroupId: 4
  }, {
      Id: 7,
      Subject: 'PERU vs DENMARK',
      Description: 'Group C',
      StartTime: new Date(2018, 5, 16, 16, 0),
      EndTime: new Date(2018, 5, 16, 18, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Saransk',
      CategoryColor: '#7fa900',
      GroupId: 3
  }, {
      Id: 8,
      Subject: 'CROATIA vs NIGERIA',
      Description: 'Group D',
      StartTime: new Date(2018, 5, 16, 19, 0),
      EndTime: new Date(2018, 5, 16, 21, 0),
      StartTimezone: 'Europe/Kaliningrad',
      EndTimezone: 'Europe/Kaliningrad',
      City: 'Kaliningrad',
      CategoryColor: '#ea7a57',
      GroupId: 4
  }, {
      Id: 9,
      Subject: 'COSTA RICA vs SERBIA',
      Description: 'Group E',
      StartTime: new Date(2018, 5, 17, 12, 0),
      EndTime: new Date(2018, 5, 17, 14, 0),
      StartTimezone: 'Europe/Samara',
      EndTimezone: 'Europe/Samara',
      City: 'Samara',
      CategoryColor: '#00bdae',
      GroupId: 5
  }, {
      Id: 10,
      Subject: 'GERMANY vs MEXICO',
      Description: 'Group F',
      StartTime: new Date(2018, 5, 17, 15, 0),
      EndTime: new Date(2018, 5, 17, 17, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Moscow',
      CategoryColor: '#f57f17',
      GroupId: 6
  }, {
      Id: 11,
      Subject: 'BRAZIL vs SWITZERLAND',
      Description: 'Group E',
      StartTime: new Date(2018, 5, 17, 18, 0),
      EndTime: new Date(2018, 5, 17, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Rostov-On-Don',
      CategoryColor: '#00bdae',
      GroupId: 5
  }, {
      Id: 12,
      Subject: 'SWEDEN vs KOREA REPUBLIC',
      Description: 'Group F',
      StartTime: new Date(2018, 5, 18, 12, 0),
      EndTime: new Date(2018, 5, 18, 14, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Nizhny Novgorod',
      CategoryColor: '#f57f17',
      GroupId: 6
  }, {
      Id: 13,
      Subject: 'BELGIUM vs PANAMA',
      Description: 'Group G',
      StartTime: new Date(2018, 5, 18, 15, 0),
      EndTime: new Date(2018, 5, 18, 17, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Sochi',
      CategoryColor: '#8e24aa',
      GroupId: 7
  }, {
      Id: 14,
      Subject: 'TUNISIA vs ENGLAND',
      Description: 'Group G',
      StartTime: new Date(2018, 5, 18, 18, 0),
      EndTime: new Date(2018, 5, 18, 20, 0),
      StartTimezone: 'Europe/Volgograd',
      EndTimezone: 'Europe/Volgograd',
      City: 'Volgograd',
      CategoryColor: '#8e24aa',
      GroupId: 7
  }, {
      Id: 15,
      Subject: 'COLOMBIA vs JAPAN',
      Description: 'Group H',
      StartTime: new Date(2018, 5, 19, 12, 0),
      EndTime: new Date(2018, 5, 19, 14, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Saransk',
      CategoryColor: '#7fa900',
      GroupId: 8
  }, {
      Id: 16,
      Subject: 'POLAND vs SENEGAL',
      Description: 'Group H',
      StartTime: new Date(2018, 5, 19, 15, 0),
      EndTime: new Date(2018, 5, 19, 17, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Moscow',
      CategoryColor: '#7fa900',
      GroupId: 8
  }, {
      Id: 17,
      Subject: 'RUSSIA vs EGYPT',
      Description: 'Group A',
      StartTime: new Date(2018, 5, 19, 18, 0),
      EndTime: new Date(2018, 5, 19, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Saint Petersburg',
      CategoryColor: '#1aaa55',
      GroupId: 1
  }, {
      Id: 18,
      Subject: 'PORTUGAL vs MOROCCO',
      Description: 'Group B',
      StartTime: new Date(2018, 5, 20, 12, 0),
      EndTime: new Date(2018, 5, 20, 14, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Rostov-On-Don',
      CategoryColor: '#357cd2',
      GroupId: 2
  }, {
      Id: 19,
      Subject: 'URUGUAY vs SAUDI ARABIA',
      Description: 'Group A',
      StartTime: new Date(2018, 5, 20, 15, 0),
      EndTime: new Date(2018, 5, 20, 17, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Moscow',
      CategoryColor: '#1aaa55',
      GroupId: 1
  }, {
      Id: 20,
      Subject: 'IR IRAN vs SPAIN',
      Description: 'Group B',
      StartTime: new Date(2018, 5, 20, 18, 0),
      EndTime: new Date(2018, 5, 20, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Kazan',
      CategoryColor: '#357cd2',
      GroupId: 2
  }, {
      Id: 21,
      Subject: 'DENMARK vs AUSTRALIA',
      Description: 'Group C',
      StartTime: new Date(2018, 5, 21, 12, 0),
      EndTime: new Date(2018, 5, 21, 14, 0),
      StartTimezone: 'Europe/Samara',
      EndTimezone: 'Europe/Samara',
      City: 'Samara',
      CategoryColor: '#7fa900',
      GroupId: 3
  }, {
      Id: 22,
      Subject: 'FRANCE vs PERU',
      Description: 'Group D',
      StartTime: new Date(2018, 5, 21, 15, 0),
      EndTime: new Date(2018, 5, 21, 17, 0),
      StartTimezone: 'Asia/Yekaterinburg',
      EndTimezone: 'Asia/Yekaterinburg',
      City: 'Ekaterinburg',
      CategoryColor: '#ea7a57',
      GroupId: 4
  }, {
      Id: 23,
      Subject: 'ARGENTINA vs CROATIA',
      Description: 'Group D',
      StartTime: new Date(2018, 5, 21, 18, 0),
      EndTime: new Date(2018, 5, 21, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Nizhny Novgorod',
      CategoryColor: '#ea7a57',
      GroupId: 4
  }, {
      Id: 24,
      Subject: 'BRAZIL vs COSTA RICA',
      Description: 'Group E',
      StartTime: new Date(2018, 5, 22, 12, 0),
      EndTime: new Date(2018, 5, 22, 14, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Saint Petersburg',
      CategoryColor: '#00bdae',
      GroupId: 5
  }, {
      Id: 25,
      Subject: 'NIGERIA vs ICELAND',
      Description: 'Group D',
      StartTime: new Date(2018, 5, 22, 15, 0),
      EndTime: new Date(2018, 5, 22, 17, 0),
      StartTimezone: 'Europe/Volgograd',
      EndTimezone: 'Europe/Volgograd',
      City: 'Volgograd',
      CategoryColor: '#ea7a57',
      GroupId: 4
  }, {
      Id: 26,
      Subject: 'SERBIA vs SWITZERLAND',
      Description: 'Group E',
      StartTime: new Date(2018, 5, 22, 18, 0),
      EndTime: new Date(2018, 5, 22, 20, 0),
      StartTimezone: 'Europe/Kaliningrad',
      EndTimezone: 'Europe/Kaliningrad',
      City: 'Kaliningrad',
      CategoryColor: '#00bdae',
      GroupId: 5
  }, {
      Id: 27,
      Subject: 'BELGIUM vs TUNISIA',
      Description: 'Group G',
      StartTime: new Date(2018, 5, 23, 12, 0),
      EndTime: new Date(2018, 5, 23, 14, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Moscow',
      CategoryColor: '#8e24aa',
      GroupId: 7
  }, {
      Id: 28,
      Subject: 'KOREA REPUBLIC vs MEXICO',
      Description: 'Group F',
      StartTime: new Date(2018, 5, 23, 15, 0),
      EndTime: new Date(2018, 5, 23, 17, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Rostov-On-Don',
      CategoryColor: '#f57f17',
      GroupId: 6
  }, {
      Id: 29,
      Subject: 'GERMANY vs SWEDEN',
      Description: 'Group F',
      StartTime: new Date(2018, 5, 23, 18, 0),
      EndTime: new Date(2018, 5, 23, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Sochi',
      CategoryColor: '#f57f17',
      GroupId: 6
  }, {
      Id: 30,
      Subject: 'ENGLAND vs PANAMA',
      Description: 'Group G',
      StartTime: new Date(2018, 5, 24, 12, 0),
      EndTime: new Date(2018, 5, 24, 14, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Nizhny Novgorod',
      CategoryColor: '#8e24aa',
      GroupId: 7
  }, {
      Id: 31,
      Subject: 'JAPAN vs SENEGAL',
      Description: 'Group H',
      StartTime: new Date(2018, 5, 24, 15, 0),
      EndTime: new Date(2018, 5, 24, 17, 0),
      StartTimezone: 'Asia/Yekaterinburg',
      EndTimezone: 'Asia/Yekaterinburg',
      City: 'Ekaterinburg',
      CategoryColor: '#7fa900',
      GroupId: 8
  }, {
      Id: 32,
      Subject: 'POLAND vs COLOMBIA',
      Description: 'Group H',
      StartTime: new Date(2018, 5, 24, 18, 0),
      EndTime: new Date(2018, 5, 24, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Kazan',
      CategoryColor: '#7fa900',
      GroupId: 8
  }, {
      Id: 33,
      Subject: 'URUGUAY vs RUSSIA',
      Description: 'Group A',
      StartTime: new Date(2018, 5, 25, 14, 0),
      EndTime: new Date(2018, 5, 25, 16, 0),
      StartTimezone: 'Europe/Samara',
      EndTimezone: 'Europe/Samara',
      City: 'Samara',
      CategoryColor: '#1aaa55',
      GroupId: 1
  }, {
      Id: 34,
      Subject: 'SAUDI ARABIA vs EGYPT',
      Description: 'Group A',
      StartTime: new Date(2018, 5, 25, 14, 0),
      EndTime: new Date(2018, 5, 25, 16, 0),
      StartTimezone: 'Europe/Volgograd',
      EndTimezone: 'Europe/Volgograd',
      City: 'Volgograd',
      CategoryColor: '#1aaa55',
      GroupId: 1
  }, {
      Id: 35,
      Subject: 'IR IRAN vs PORTUGAL',
      Description: 'Group B',
      StartTime: new Date(2018, 5, 25, 18, 0),
      EndTime: new Date(2018, 5, 25, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Saransk',
      CategoryColor: '#357cd2',
      GroupId: 2
  }, {
      Id: 36,
      Subject: 'SPAIN vs MOROCCO',
      Description: 'Group B',
      StartTime: new Date(2018, 5, 25, 18, 0),
      EndTime: new Date(2018, 5, 25, 20, 0),
      StartTimezone: 'Europe/Kaliningrad',
      EndTimezone: 'Europe/Kaliningrad',
      City: 'Kaliningrad',
      CategoryColor: '#357cd2',
      GroupId: 2
  }, {
      Id: 37,
      Subject: 'DENMARK vs FRANCE',
      Description: 'Group C',
      StartTime: new Date(2018, 5, 26, 14, 0),
      EndTime: new Date(2018, 5, 26, 16, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Moscow',
      CategoryColor: '#7fa900',
      GroupId: 3
  }, {
      Id: 38,
      Subject: 'AUSTRALIA vs PERU',
      Description: 'Group C',
      StartTime: new Date(2018, 5, 26, 14, 0),
      EndTime: new Date(2018, 5, 26, 16, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Sochi',
      CategoryColor: '#7fa900',
      GroupId: 3
  }, {
      Id: 39,
      Subject: 'NIGERIA vs ARGENTINA',
      Description: 'Group D',
      StartTime: new Date(2018, 5, 26, 18, 0),
      EndTime: new Date(2018, 5, 26, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Saint Petersburg',
      CategoryColor: '#ea7a57',
      GroupId: 4
  }, {
      Id: 40,
      Subject: 'ICELAND vs CROATIA',
      Description: 'Group D',
      StartTime: new Date(2018, 5, 26, 18, 0),
      EndTime: new Date(2018, 5, 26, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Rostov-On-Don',
      CategoryColor: '#ea7a57',
      GroupId: 4
  }, {
      Id: 41,
      Subject: 'MEXICO vs SWEDEN',
      Description: 'Group F',
      StartTime: new Date(2018, 5, 27, 14, 0),
      EndTime: new Date(2018, 5, 27, 16, 0),
      StartTimezone: 'Asia/Yekaterinburg',
      EndTimezone: 'Asia/Yekaterinburg',
      City: 'Ekaterinburg',
      CategoryColor: '#f57f17',
      GroupId: 6
  }, {
      Id: 42,
      Subject: 'KOREA REPUBLIC vs GERMANY',
      Description: 'Group F',
      StartTime: new Date(2018, 5, 27, 14, 0),
      EndTime: new Date(2018, 5, 27, 16, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Kazan',
      CategoryColor: '#f57f17',
      GroupId: 6
  }, {
      Id: 43,
      Subject: 'SERBIA vs BRAZIL',
      Description: 'Group E',
      StartTime: new Date(2018, 5, 27, 18, 0),
      EndTime: new Date(2018, 5, 27, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Moscow',
      CategoryColor: '#00bdae',
      GroupId: 5
  }, {
      Id: 44,
      Subject: 'SWITZERLAND vs COSTA RICA',
      Description: 'Group E',
      StartTime: new Date(2018, 5, 27, 18, 0),
      EndTime: new Date(2018, 5, 27, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Nizhny Novgorod',
      CategoryColor: '#00bdae',
      GroupId: 5
  }, {
      Id: 45,
      Subject: 'JAPAN vs POLAND',
      Description: 'Group H',
      StartTime: new Date(2018, 5, 28, 14, 0),
      EndTime: new Date(2018, 5, 28, 16, 0),
      StartTimezone: 'Europe/Volgograd',
      EndTimezone: 'Europe/Volgograd',
      City: 'Volgograd',
      CategoryColor: '#7fa900',
      GroupId: 8
  }, {
      Id: 46,
      Subject: 'SENEGAL vs COLOMBIA',
      Description: 'Group H',
      StartTime: new Date(2018, 5, 28, 14, 0),
      EndTime: new Date(2018, 5, 28, 16, 0),
      StartTimezone: 'Europe/Samara',
      EndTimezone: 'Europe/Samara',
      City: 'Samara',
      CategoryColor: '#7fa900',
      GroupId: 8
  }, {
      Id: 47,
      Subject: 'PANAMA vs TUNISIA',
      Description: 'Group G',
      StartTime: new Date(2018, 5, 28, 18, 0),
      EndTime: new Date(2018, 5, 28, 20, 0),
      StartTimezone: 'Europe/Moscow',
      EndTimezone: 'Europe/Moscow',
      City: 'Saransk',
      CategoryColor: '#8e24aa',
      GroupId: 4
  }, {
      Id: 48,
      Subject: 'ENGLAND vs BELGIUM',
      Description: 'Group G',
      StartTime: new Date(2018, 5, 28, 18, 0),
      EndTime: new Date(2018, 5, 28, 20, 0),
      StartTimezone: 'Europe/Kaliningrad',
      EndTimezone: 'Europe/Kaliningrad',
      City: 'Kaliningrad',
      CategoryColor: '#8e24aa',
      GroupId: 4
  }
];

const msPerDay = 86400000;
const msPerHour = 3600000;
const currentTime: number = new Date().setMinutes(0, 0, 0);
export let readonlyEventsData: Object[] = [
  {
      Id: 1,
      Subject: 'Project Workflow Analysis',
      StartTime: new Date(currentTime + msPerDay * -2 + msPerHour * 2),
      EndTime: new Date(currentTime + msPerDay * -2 + msPerHour * 4),
      IsReadonly: true
  }, {
      Id: 2,
      Subject: 'Project Requirement Planning',
      StartTime: new Date(currentTime + msPerDay * -1 + msPerHour * 2),
      EndTime: new Date(currentTime + msPerDay * -1 + msPerHour * 4),
      IsReadonly: true
  }, {
      Id: 3,
      Subject: 'Meeting with Developers',
      StartTime: new Date(currentTime + msPerDay * -1 + msPerHour * -3),
      EndTime: new Date(currentTime + msPerDay * -1 + msPerHour * -1),
      IsReadonly: true
  }, {
      Id: 4,
      Subject: 'Team Fun Activities',
      StartTime: new Date(currentTime + msPerHour * -4),
      EndTime: new Date(currentTime + msPerHour * -2),
      IsReadonly: true
  }, {
      Id: 5,
      Subject: 'Quality Analysis',
      StartTime: new Date(currentTime + msPerHour * 1),
      EndTime: new Date(currentTime + msPerHour * 3),
      IsReadonly: true
  }, {
      Id: 6,
      Subject: 'Customer meeting – John Mackenzie',
      StartTime: new Date(currentTime + msPerHour * 5),
      EndTime: new Date(currentTime + msPerHour * 6),
      IsReadonly: false
  }, {
      Id: 7,
      Subject: 'Meeting with Core team',
      StartTime: new Date(currentTime + msPerHour * 9),
      EndTime: new Date(currentTime + msPerHour * 10),
      IsReadonly: false
  }, {
      Id: 8,
      Subject: 'Project Review',
      StartTime: new Date(currentTime + msPerDay * 1 + msPerHour * 3),
      EndTime: new Date(currentTime + msPerDay * 1 + msPerHour * 5),
      IsReadonly: false
  }, {
      Id: 9,
      Subject: 'Project demo meeting with Andrew',
      StartTime: new Date(currentTime + msPerDay * 1 + msPerHour * -4),
      EndTime: new Date(currentTime + msPerDay * 1 + msPerHour * -3),
      IsReadonly: false
  }, {
      Id: 10,
      Subject: 'Online Hosting of Project',
      StartTime: new Date(currentTime + msPerDay * 2 + msPerHour * 4),
      EndTime: new Date(currentTime + msPerDay * 2 + msPerHour * 6),
      IsReadonly: false
  }
];

export function generateObject(start: number = new Date(2017, 6, 1).getTime(), end: number = new Date(2018, 6, 31).getTime()): Object[] {
  const data: Object[] = [];
  const names: string[] = [
      'Story Time for Kids', 'Camping with Turtles', 'Wildlife Warriors', 'Parrot Talk', 'Birds of Prey', 'Croco World',
      'Venomous Snake Hunt', 'Face Painting & Drawing events', 'Pony Rides', 'Feed the Giants', 'Jungle Treasure Hunt',
      'Endangered Species Program', 'Black Cockatoos Playtime', 'Walk with Jungle King', 'Trained Climbers', 'Playtime with Chimpanzees',
      'Meet a small Mammal', 'Amazon Fish Feeding', 'Elephant Ride'
  ];
  const dayCount: number = 1000 * 60 * 60;
  for (let a: number = start, id = 1; a < end; a += (dayCount * 24) * 2) {
      const count: number = Math.floor((Math.random() * 9) + 1);
      for (let b = 0; b < count; b++) {
          const hour: number = Math.floor(Math.random() * 100) % 24;
          const minutes: number = Math.round((Math.floor(Math.random() * 100) % 60) / 5) * 5;
          const nCount: number = Math.floor(Math.random() * names.length);
          const startDate: Date = new Date(new Date(a).setHours(hour, minutes));
          const endDate: Date = new Date(startDate.getTime() + (dayCount * 2.5));
          data.push({
              Id: id,
              Subject: names[nCount],
              StartTime: startDate,
              EndTime: endDate,
              IsAllDay: (id % 10) ? false : true
          });
          id++;
      }
  }
  return data;
}

export let roomData: Object[] = [
  {
      Id: 1,
      Subject: 'Board Meeting',
      Description: 'Meeting to discuss business goal of 2018.',
      StartTime: new Date(2018, 6, 30, 9, 0),
      EndTime: new Date(2018, 6, 30, 11, 0),
      RoomId: 1
  },
  {
      Id: 2,
      Subject: 'Training session on JSP',
      Description: 'Knowledge sharing on JSP topics.',
      StartTime: new Date(2018, 6, 30, 15, 0),
      EndTime: new Date(2018, 6, 30, 17, 0),
      RoomId: 5
  },
  {
      Id: 3,
      Subject: 'Sprint Planning with Team members',
      Description: 'Planning tasks for sprint.',
      StartTime: new Date(2018, 6, 30, 9, 30),
      EndTime: new Date(2018, 6, 30, 11, 0),
      RoomId: 3
  },
  {
      Id: 4,
      Subject: 'Meeting with Client',
      Description: 'Customer meeting to discuss features.',
      StartTime: new Date(2018, 6, 30, 11, 0),
      EndTime: new Date(2018, 6, 30, 13, 0),
      RoomId: 4
  },
  {
      Id: 5,
      Subject: 'Support Meeting with Managers',
      Description: 'Meeting to discuss support plan.',
      StartTime: new Date(2018, 6, 30, 16, 0),
      EndTime: new Date(2018, 6, 30, 17, 30),
      RoomId: 5
  },
  {
      Id: 6, Subject: 'Client Meeting',
      Description: 'Meeting to discuss client requirements.',
      StartTime: new Date(2018, 6, 30, 10, 30),
      EndTime: new Date(2018, 6, 30, 13, 0),
      RoomId: 6
  },
  {
      Id: 7,
      Subject: 'Appraisal Meeting',
      Description: 'Meeting to discuss employee appraisals.',
      StartTime: new Date(2018, 6, 30, 15, 0),
      EndTime: new Date(2018, 6, 30, 16, 30),
      RoomId: 7
  },
  {
      Id: 8,
      Subject: 'HR Meeting',
      Description: 'Meeting to discuss HR plans.',
      StartTime: new Date(2018, 6, 30, 8, 0),
      EndTime: new Date(2018, 6, 30, 9, 0),
      RoomId: 4
  },
  {
      Id: 9,
      Subject: 'Customer Meeting',
      Description: 'Meeting to discuss customer reported issues.',
      StartTime: new Date(2018, 6, 30, 10, 0),
      EndTime: new Date(2018, 6, 30, 12, 0),
      RoomId: 8
  },
  {
      Id: 10,
      Subject: 'Board Meeting',
      Description: 'Meeting to discuss business plans.',
      StartTime: new Date(2018, 6, 30, 14, 30),
      EndTime: new Date(2018, 6, 30, 17, 0),
      RoomId: 9
  },
  {
      Id: 11,
      Subject: 'Training session on Vue',
      Description: 'Knowledge sharing on Vue concepts.',
      StartTime: new Date(2018, 6, 30, 9, 0),
      EndTime: new Date(2018, 6, 30, 10, 30),
      RoomId: 10
  },
  {
      Id: 12,
      Subject: 'Meeting with Team members',
      Description: 'Meeting to discuss on work report.',
      StartTime: new Date(2018, 6, 30, 11, 30),
      EndTime: new Date(2018, 6, 30, 12, 0),
      RoomId: 5
  },
  {
      Id: 13,
      Subject: 'Meeting with General Manager',
      Description: 'Meeting to discuss support plan.',
      StartTime: new Date(2018, 6, 30, 14, 0),
      EndTime: new Date(2018, 6, 30, 16, 0),
      RoomId: 5
  },
  {
      Id: 14,
      Subject: 'Board Meeting',
      Description: 'Meeting to discuss business goal of 2018.',
      StartTime: new Date(2018, 6, 31, 9, 0),
      EndTime: new Date(2018, 6, 31, 11, 0),
      RoomId: 1
  },
  {
      Id: 15,
      Subject: 'Training session on JSP',
      Description: 'Knowledge sharing on JSP topics.',
      StartTime: new Date(2018, 6, 31, 14, 0),
      EndTime: new Date(2018, 6, 31, 17, 0),
      RoomId: 6
  },
  {
      Id: 16,
      Subject: 'Sprint Planning with Team members',
      Description: 'Planning tasks for sprint.',
      StartTime: new Date(2018, 6, 31, 9, 30),
      EndTime: new Date(2018, 6, 31, 11, 0),
      RoomId: 2
  },
  {
      Id: 17,
      Subject: 'Meeting with Client',
      Description: 'Customer meeting to discuss features.',
      StartTime: new Date(2018, 6, 31, 11, 0),
      EndTime: new Date(2018, 6, 31, 13, 0),
      RoomId: 7
  },
  {
      Id: 18,
      Subject: 'Support Meeting with Managers',
      Description: 'Meeting to discuss support plan.',
      StartTime: new Date(2018, 6, 31, 16, 0),
      EndTime: new Date(2018, 6, 31, 17, 30),
      RoomId: 2
  },
  {
      Id: 19,
      Subject: 'Training session on C#',
      Description: 'Training session',
      StartTime: new Date(2018, 6, 31, 14, 30),
      EndTime: new Date(2018, 6, 31, 16, 0),
      RoomId: 9
  },
  {
      Id: 20,
      Subject: 'Client Meeting',
      Description: 'Meeting to discuss client requirements.',
      StartTime: new Date(2018, 6, 31, 10, 30),
      EndTime: new Date(2018, 6, 31, 13, 0),
      RoomId: 3
  },
  {
      Id: 21,
      Subject: 'Appraisal Meeting',
      Description: 'Meeting to discuss employee appraisals.',
      StartTime: new Date(2018, 6, 31, 15, 0),
      EndTime: new Date(2018, 6, 31, 16, 30),
      RoomId: 3
  },
  {
      Id: 22,
      Subject: 'HR Meeting',
      Description: 'Meeting to discuss HR plans.',
      StartTime: new Date(2018, 6, 31, 8, 0),
      EndTime: new Date(2018, 6, 31, 9, 0),
      RoomId: 4
  },
  {
      Id: 23,
      Subject: 'Customer Meeting',
      Description: 'Meeting to discuss customer reported issues.',
      StartTime: new Date(2018, 6, 31, 10, 0),
      EndTime: new Date(2018, 6, 31, 12, 0),
      RoomId: 4
  },
  {
      Id: 24,
      Subject: 'Board Meeting',
      Description: 'Meeting to discuss business plans.',
      StartTime: new Date(2018, 7, 1, 16, 30),
      EndTime: new Date(2018, 7, 1, 18, 0),
      RoomId: 10
  },
  {
      Id: 25,
      Subject: 'Training session on Vue',
      Description: 'Knowledge sharing on Vue concepts.',
      StartTime: new Date(2018, 6, 31, 9, 0),
      EndTime: new Date(2018, 6, 31, 10, 30),
      RoomId: 5
  },
  {
      Id: 26,
      Subject: 'Meeting with Team members',
      Description: 'Meeting to discuss on work report.',
      StartTime: new Date(2018, 6, 31, 11, 30),
      EndTime: new Date(2018, 6, 31, 12, 0),
      RoomId: 5
  },
  {
      Id: 27,
      Subject: 'Meeting with General Manager',
      Description: 'Meeting to discuss support plan.',
      StartTime: new Date(2018, 6, 31, 14, 0),
      EndTime: new Date(2018, 6, 31, 16, 0),
      RoomId: 10
  },

  {
      Id: 28,
      Subject: 'Board Meeting',
      Description: 'Meeting to discuss business goal of 2018.',
      StartTime: new Date(2018, 7, 1, 9, 0),
      EndTime: new Date(2018, 7, 1, 11, 0),
      RoomId: 1
  },
  {
      Id: 29,
      Subject: 'Training session on JSP',
      Description: 'Knowledge sharing on JSP topics.',
      StartTime: new Date(2018, 7, 1, 17, 0),
      EndTime: new Date(2018, 7, 1, 20, 0),
      RoomId: 6
  },
  {
      Id: 30,
      Subject: 'Sprint Planning with Team members',
      Description: 'Planning tasks for sprint.',
      StartTime: new Date(2018, 7, 1, 10, 30),
      EndTime: new Date(2018, 7, 1, 12, 0),
      RoomId: 2
  },
  {
      Id: 31,
      Subject: 'Meeting with Client',
      Description: 'Customer meeting to discuss features.',
      StartTime: new Date(2018, 7, 1, 18, 0),
      EndTime: new Date(2018, 7, 1, 20, 0),
      RoomId: 8
  },
  {
      Id: 32,
      Subject: 'Support Meeting with Managers',
      Description: 'Meeting to discuss support plan.',
      StartTime: new Date(2018, 7, 1, 16, 0),
      EndTime: new Date(2018, 7, 1, 17, 30),
      RoomId: 7
  },
  {
      Id: 33,
      Subject: 'Training session on C#',
      Description: 'Training session',
      StartTime: new Date(2018, 7, 1, 14, 30),
      EndTime: new Date(2018, 7, 1, 16, 0),
      RoomId: 2
  },
  {
      Id: 34,
      Subject: 'Client Meeting',
      Description: 'Meeting to discuss client requirements.',
      StartTime: new Date(2018, 7, 1, 10, 30),
      EndTime: new Date(2018, 7, 1, 13, 0),
      RoomId: 3
  },
  {
      Id: 35,
      Subject: 'Appraisal Meeting',
      Description: 'Meeting to discuss employee appraisals.',
      StartTime: new Date(2018, 7, 1, 15, 0),
      EndTime: new Date(2018, 7, 1, 16, 30),
      RoomId: 8
  },
  {
      Id: 36,
      Subject: 'HR Meeting',
      Description: 'Meeting to discuss HR plans.',
      StartTime: new Date(2018, 7, 1, 9, 30),
      EndTime: new Date(2018, 7, 1, 11, 30),
      RoomId: 4
  },
  {
      Id: 37,
      Subject: 'Customer Meeting',
      Description: 'Meeting to discuss customer reported issues.',
      StartTime: new Date(2018, 7, 1, 10, 0),
      EndTime: new Date(2018, 7, 1, 12, 0),
      RoomId: 9
  },
  {
      Id: 38,
      Subject: 'Board Meeting',
      Description: 'Meeting to discuss business plans.',
      StartTime: new Date(2018, 7, 1, 15, 0),
      EndTime: new Date(2018, 7, 1, 17, 0),
      RoomId: 4
  },
  {
      Id: 39,
      Subject: 'Training session on Vue',
      Description: 'Knowledge sharing on Vue concepts.',
      StartTime: new Date(2018, 7, 1, 9, 0),
      EndTime: new Date(2018, 7, 1, 10, 30),
      RoomId: 5
  },
  {
      Id: 40,
      Subject: 'Meeting with Team members',
      Description: 'Meeting to discuss on work report.',
      StartTime: new Date(2018, 7, 1, 11, 30),
      EndTime: new Date(2018, 7, 1, 12, 30),
      RoomId: 5
  },
  {
      Id: 41,
      Subject: 'Meeting with General Manager',
      Description: 'Meeting to discuss support plan.',
      StartTime: new Date(2018, 7, 1, 14, 0),
      EndTime: new Date(2018, 7, 1, 16, 0),
      RoomId: 10
  },
  {
      Id: 43,
      Subject: 'HR Meeting',
      Description: 'Meeting to discuss HR plans.',
      StartTime: new Date(2018, 7, 1, 18, 0),
      EndTime: new Date(2018, 7, 1, 20, 0),
      RoomId: 2
  },
  {
      Id: 44,
      Subject: 'HR Meeting',
      Description: 'Meeting to discuss HR plans.',
      StartTime: new Date(2018, 7, 1, 17, 30),
      EndTime: new Date(2018, 7, 1, 20, 0),
      RoomId: 1
  },
  {
      Id: 45,
      Subject: 'Client Meeting',
      Description: 'Meeting to discuss client requirements.',
      StartTime: new Date(2018, 7, 1, 16, 30),
      EndTime: new Date(2018, 7, 1, 18, 0),
      RoomId: 3
  },
  {
      Id: 46,
      Subject: 'Board Meeting',
      Description: 'Meeting to discuss business plans.',
      StartTime: new Date(2018, 7, 1, 18, 30),
      EndTime: new Date(2018, 7, 1, 20, 0),
      RoomId: 4
  },
  {
      Id: 47,
      Subject: 'Board Meeting',
      Description: 'Meeting to discuss business plans.',
      StartTime: new Date(2018, 7, 1, 15, 30),
      EndTime: new Date(2018, 7, 1, 18, 0),
      RoomId: 5
  },
  {
      Id: 48,
      Subject: 'HR Meeting',
      Description: 'Meeting to discuss HR plans.',
      StartTime: new Date(2018, 7, 1, 18, 30),
      EndTime: new Date(2018, 7, 1, 20, 0),
      RoomId: 5
  },
  {
      Id: 49,
      Subject: 'HR Meeting',
      Description: 'Meeting to discuss HR plans.',
      StartTime: new Date(2018, 7, 1, 14, 30),
      EndTime: new Date(2018, 7, 1, 16, 0),
      RoomId: 6
  },
  {
      Id: 50,
      Subject: 'Board Meeting',
      Description: 'Meeting to discuss business plans.',
      StartTime: new Date(2018, 7, 1, 9, 30),
      EndTime: new Date(2018, 7, 1, 12, 0),
      RoomId: 6
  },
  {
      Id: 51,
      Subject: 'Client Meeting',
      Description: 'Meeting to discuss client requirements.',
      StartTime: new Date(2018, 7, 1, 10, 30),
      EndTime: new Date(2018, 7, 1, 12, 0),
      RoomId: 7
  },
  {
      Id: 52,
      Subject: 'Appraisal Meeting',
      Description: 'Meeting to discuss employee appraisals.',
      StartTime: new Date(2018, 7, 1, 18, 0),
      EndTime: new Date(2018, 7, 1, 19, 30),
      RoomId: 7
  },
  {
      Id: 53,
      Subject: 'Support Meeting with Managers',
      Description: 'Meeting to discuss support plan.',
      StartTime: new Date(2018, 7, 1, 15, 30),
      EndTime: new Date(2018, 7, 1, 17, 0),
      RoomId: 9
  },
  {
      Id: 54,
      Subject: 'Support Meeting with Managers',
      Description: 'Meeting to discuss support plan.',
      StartTime: new Date(2018, 7, 1, 11, 0),
      EndTime: new Date(2018, 7, 1, 12, 30),
      RoomId: 8
  },
  {
      Id: 55,
      Subject: 'Support Meeting with Managers',
      Description: 'Meeting to discuss support plan.',
      StartTime: new Date(2018, 7, 1, 11, 0),
      EndTime: new Date(2018, 7, 1, 12, 30),
      RoomId: 10
  },
  {
      Id: 56,
      Subject: 'Lunch Break',
      StartTime: new Date(2017, 7, 1, 13, 0),
      EndTime: new Date(2017, 7, 1, 14, 0),
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      IsBlock: true,
      RoomId: 1
  },
  {
      Id: 57,
      Subject: 'Lunch Break',
      StartTime: new Date(2017, 7, 1, 13, 0),
      EndTime: new Date(2017, 7, 1, 14, 0),
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      IsBlock: true,
      RoomId: 2
  },
  {
      Id: 58,
      Subject: 'Lunch Break',
      StartTime: new Date(2017, 7, 1, 13, 0),
      EndTime: new Date(2017, 7, 1, 14, 0),
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      IsBlock: true,
      RoomId: 3
  },
  {
      Id: 59,
      Subject: 'Lunch Break',
      StartTime: new Date(2017, 7, 1, 13, 0),
      EndTime: new Date(2017, 7, 1, 14, 0),
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      IsBlock: true,
      RoomId: 4
  },
  {
      Id: 60,
      Subject: 'Lunch Break',
      StartTime: new Date(2017, 7, 1, 13, 0),
      EndTime: new Date(2017, 7, 1, 14, 0),
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      IsBlock: true,
      RoomId: 5
  },
  {
      Id: 61,
      Subject: 'Lunch Break',
      StartTime: new Date(2017, 7, 1, 13, 0),
      EndTime: new Date(2017, 7, 1, 14, 0),
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      IsBlock: true,
      RoomId: 6
  },
  {
      Id: 62,
      Subject: 'Lunch Break',
      StartTime: new Date(2017, 7, 1, 13, 0),
      EndTime: new Date(2017, 7, 1, 14, 0),
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      IsBlock: true,
      RoomId: 7
  },
  {
      Id: 63,
      Subject: 'Lunch Break',
      StartTime: new Date(2017, 7, 1, 13, 0),
      EndTime: new Date(2017, 7, 1, 14, 0),
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      IsBlock: true,
      RoomId: 8
  },
  {
      Id: 64,
      Subject: 'Lunch Break',
      StartTime: new Date(2017, 7, 1, 13, 0),
      EndTime: new Date(2017, 7, 1, 14, 0),
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      IsBlock: true,
      RoomId: 9
  },
  {
      Id: 65,
      Subject: 'Lunch Break',
      StartTime: new Date(2017, 7, 1, 13, 0),
      EndTime: new Date(2017, 7, 1, 14, 0),
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      IsBlock: true,
      RoomId: 10
  }
];

export let headerRowData: Object[] = [
  {
      Id: 1,
      Subject: 'Story Time for Kids',
      StartTime: new Date(2018, 0, 1, 9, 0),
      EndTime: new Date(2018, 0, 3, 11, 0),
      CategoryColor: '#df5286'
  },
  {
      Id: 2,
      Subject: 'Camping with Turtles',
      StartTime: new Date(2018, 0, 2, 15, 0),
      EndTime: new Date(2018, 0, 3, 17, 0),
      CategoryColor: '#7fa900'
  },
  {
      Id: 3,
      Subject: 'Wildlife Warriors',
      StartTime: new Date(2018, 0, 3, 9, 30),
      EndTime: new Date(2018, 0, 4, 11, 0),
      CategoryColor: '#ea7a57'
  },
  {
      Id: 4,
      Subject: 'Parrot Talk',
      StartTime: new Date(2018, 0, 4, 11, 0),
      EndTime: new Date(2018, 0, 6, 13, 0),
      CategoryColor: '#1aaa55'
  },
  {
      Id: 6,
      Subject: 'Croco World',
      StartTime: new Date(2018, 0, 5, 16, 0),
      EndTime: new Date(2018, 0, 8, 17, 30),
      CategoryColor: '#fec200'
  },
  {
      Id: 7,
      Subject: 'Venomous Snake Hunt',
      StartTime: new Date(2018, 0, 6, 15, 0),
      EndTime: new Date(2018, 0, 7, 16, 30),
      CategoryColor: '#5978ee'
  },
  {
      Id: 8,
      Subject: 'Face Painting & Drawing events',
      StartTime: new Date(2018, 0, 7, 8, 0),
      EndTime: new Date(2018, 0, 10, 9, 0),
      IsAllDay: true,
      CategoryColor: '#00bdae'
  },
  {
      Id: 11,
      Subject: 'Jungle Treasure Hunt',
      StartTime: new Date(2018, 0, 11),
      EndTime: new Date(2018, 0, 14),
      IsAllDay: true,
      CategoryColor: '#7FA900'
  },
  {
      Id: 12,
      Subject: 'Endangered Species Program',
      StartTime: new Date(2018, 0, 12, 11, 30),
      EndTime: new Date(2018, 0, 15, 12, 0),
      CategoryColor: '#1aaa55'
  },
  {
      Id: 13,
      Subject: 'Black Cockatoos Playtime',
      StartTime: new Date(2018, 0, 13),
      EndTime: new Date(2018, 0, 17),
      IsAllDay: true,
      CategoryColor: '#ea7a57'
  },
  {
      Id: 14,
      Subject: 'Walk with Jungle King',
      StartTime: new Date(2018, 0, 17, 9, 0),
      EndTime: new Date(2018, 0, 21, 11, 0),
      CategoryColor: '#fec200'
  },
  {
      Id: 15,
      Subject: 'Trained Climbers',
      StartTime: new Date(2018, 0, 20),
      EndTime: new Date(2018, 0, 22),
      CategoryColor: '#1aaa55'
  },
  {
      Id: 16,
      Subject: 'Playtime with Chimpanzees',
      StartTime: new Date(2018, 0, 18, 9, 30),
      EndTime: new Date(2018, 0, 20, 11, 0),
      CategoryColor: '#df5286'
  },
  {
      Id: 17,
      Subject: 'Meet a small Mammal',
      StartTime: new Date(2018, 0, 23, 11, 0),
      EndTime: new Date(2018, 0, 25, 13, 0),
      CategoryColor: '#7fa900'
  },
  {
      Id: 18,
      Subject: 'Amazon Fish Feeding',
      StartTime: new Date(2018, 0, 22),
      EndTime: new Date(2018, 0, 24),
      IsAllDay: true,
      CategoryColor: '#1aaa55'
  },
  {
      Id: 19,
      Subject: 'Elephant Ride',
      StartTime: new Date(2018, 0, 24, 14, 30),
      EndTime: new Date(2018, 0, 27, 16, 0),
      CategoryColor: '#7fa900'
  },
  {
      Id: 20,
      Subject: 'Black Cockatoos Playtime',
      StartTime: new Date(2018, 0, 26),
      EndTime: new Date(2018, 0, 30),
      IsAllDay: true,
      CategoryColor: '#ea7a57'
  },
  {
      Id: 21,
      Subject: 'Endangered Species Program',
      StartTime: new Date(2018, 0, 27, 11, 30),
      EndTime: new Date(2018, 0, 29, 12, 0),
      CategoryColor: '#1aaa55'
  },
  {
      Id: 22,
      Subject: 'Feed the Giants',
      StartTime: new Date(2018, 0, 28, 14, 30),
      EndTime: new Date(2018, 0, 31, 17, 0),
      CategoryColor: '#00bdae'
  },
  {
      Id: 23,
      Subject: 'Birds of Prey',
      StartTime: new Date(2018, 1, 4, 16, 0),
      EndTime: new Date(2018, 1, 7, 17, 30),
      CategoryColor: '#865fcf'
  },
  {
      Id: 24,
      Subject: 'Trained Climbers',
      StartTime: new Date(2018, 0, 31),
      EndTime: new Date(2018, 1, 3),
      CategoryColor: '#1aaa55'
  },
  {
      Id: 25,
      Subject: 'Venomous Snake Hunt',
      StartTime: new Date(2018, 1, 2, 15, 0),
      EndTime: new Date(2018, 1, 5, 16, 30),
      CategoryColor: '#5978ee'
  },
  {
      Id: 26,
      Subject: 'Croco World',
      StartTime: new Date(2018, 1, 6, 16, 0),
      EndTime: new Date(2018, 1, 9, 17, 30),
      CategoryColor: '#7fa900'
  },
  {
      Id: 27,
      Subject: 'Amazon Fish Feeding',
      StartTime: new Date(2018, 1, 8),
      EndTime: new Date(2018, 1, 11),
      IsAllDay: true,
      CategoryColor: '#1aaa55'
  },

  {
      Id: 28,
      Subject: 'Playtime with Chimpanzees',
      StartTime: new Date(2018, 1, 10, 9, 30),
      EndTime: new Date(2018, 1, 13, 11, 0),
      CategoryColor: '#df5286'
  },
  {
      Id: 29,
      Subject: 'Meet a small Mammal',
      StartTime: new Date(2018, 1, 12, 11, 0),
      EndTime: new Date(2018, 1, 15, 13, 0),
      CategoryColor: '#7fa900'
  },
  {
      Id: 30,
      Subject: 'Black Cockatoos Playtime',
      StartTime: new Date(2018, 1, 13),
      EndTime: new Date(2018, 1, 17),
      IsAllDay: true,
      CategoryColor: '#ea7a57'
  }
];

export let hospitalData: Object[] = [
  {
      Id: 10,
      Name: 'David',
      StartTime: new Date(2018, 7, 1, 9, 0),
      EndTime: new Date(2018, 7, 1, 10, 0),
      Description: 'Health Checkup',
      DepartmentID: 1,
      ConsultantID: 1,
      DepartmentName: 'GENERAL'
  }, {
      Id: 11,
      Name: 'John',
      StartTime: new Date(2018, 7, 1, 10, 30),
      EndTime: new Date(2018, 7, 1, 11, 30),
      Description: 'Tooth Erosion',
      DepartmentID: 2,
      ConsultantID: 2,
      DepartmentName: 'DENTAL'
  }, {
      Id: 12,
      Name: 'Peter',
      StartTime: new Date(2018, 7, 1, 12, 0),
      EndTime: new Date(2018, 7, 1, 13, 0),
      Description: 'Eye and Spectacles Checkup',
      DepartmentID: 1,
      ConsultantID: 3,
      DepartmentName: 'GENERAL'
  }, {
      Id: 13,
      Name: 'Starc',
      StartTime: new Date(2018, 7, 1, 14, 0),
      EndTime: new Date(2018, 7, 1, 15, 0),
      Description: 'Toothaches',
      DepartmentID: 2,
      ConsultantID: 4,
      DepartmentName: 'DENTAL'
  }, {
      Id: 14,
      Name: 'James',
      StartTime: new Date(2018, 7, 1, 10, 0),
      EndTime: new Date(2018, 7, 1, 11, 0),
      Description: 'Surgery Appointment',
      DepartmentID: 1,
      ConsultantID: 5,
      DepartmentName: 'GENERAL'
  }, {
      Id: 15,
      Name: 'Jercy',
      StartTime: new Date(2018, 7, 1, 9, 30),
      EndTime: new Date(2018, 7, 1, 10, 30),
      Description: 'Tooth Sensitivity',
      DepartmentID: 2,
      ConsultantID: 6,
      DepartmentName: 'DENTAL'
  }, {
      Id: 16,
      Name: 'Albert',
      StartTime: new Date(2018, 7, 2, 10, 0),
      EndTime: new Date(2018, 7, 2, 11, 30),
      Description: 'Skin care treatment',
      DepartmentID: 1,
      ConsultantID: 7,
      DepartmentName: 'GENERAL'
  }, {
      Id: 17,
      Name: 'Louis',
      StartTime: new Date(2018, 7, 2, 12, 30),
      EndTime: new Date(2018, 7, 2, 13, 45),
      Description: 'General Checkup',
      DepartmentID: 1,
      ConsultantID: 9,
      DepartmentName: 'GENERAL'
  }, {
      Id: 18,
      Name: 'Williams',
      StartTime: new Date(2018, 7, 2, 12, 0),
      EndTime: new Date(2018, 7, 2, 14, 0),
      Description: 'Mouth Sores',
      DepartmentID: 2,
      ConsultantID: 10,
      DepartmentName: 'DENTAL'
  },
  {
      Id: 19,
      Name: 'David',
      StartTime: new Date(2018, 7, 2, 16, 30),
      EndTime: new Date(2018, 7, 2, 18, 15),
      Description: 'Eye checkup and Treatment',
      DepartmentID: 1,
      ConsultantID: 1,
      DepartmentName: 'GENERAL'
  }, {
      Id: 20,
      Name: 'John',
      StartTime: new Date(2018, 7, 2, 19, 30),
      EndTime: new Date(2018, 7, 2, 21, 45),
      Description: 'Toothaches',
      DepartmentID: 2,
      ConsultantID: 2,
      DepartmentName: 'DENTAL'
  }, {
      Id: 21,
      Name: 'Peter',
      StartTime: new Date(2018, 7, 3, 17, 30),
      EndTime: new Date(2018, 7, 3, 19, 30),
      Description: 'Surgery Treatment',
      DepartmentID: 1,
      ConsultantID: 3,
      DepartmentName: 'GENERAL'
  }, {
      Id: 22,
      Name: 'Starc',
      StartTime: new Date(2018, 7, 4, 18, 30),
      EndTime: new Date(2018, 7, 4, 21, 30),
      Description: 'Tooth Decay',
      DepartmentID: 2,
      ConsultantID: 4,
      DepartmentName: 'DENTAL'
  }, {
      Id: 23,
      Name: 'James',
      StartTime: new Date(2018, 7, 3, 19, 0),
      EndTime: new Date(2018, 7, 3, 21, 0),
      Description: 'General Checkup',
      DepartmentID: 1,
      ConsultantID: 5,
      DepartmentName: 'GENERAL'
  }, {
      Id: 24,
      Name: 'Jercy',
      StartTime: new Date(2018, 7, 4, 20, 0),
      EndTime: new Date(2018, 7, 4, 22, 0),
      Description: 'Tooth Erosion',
      DepartmentID: 2,
      ConsultantID: 6,
      DepartmentName: 'DENTAL'
  }];

export let waitingList: { [key: string]: Object }[] = [
  {
      Id: 1,
      Name: 'Steven',
      StartTime: new Date(2018, 8, 3, 7, 30),
      EndTime: new Date(2018, 8, 3, 9, 30),
      Description: 'Consulting',
      DepartmentName: 'GENERAL'
  },
  {
      Id: 2,
      Name: 'Milan',
      StartTime: new Date(2018, 8, 4, 8, 30),
      EndTime: new Date(2018, 8, 4, 10, 30),
      Description: 'Bad Breath',
      DepartmentName: 'DENTAL'
  },
  {
      Id: 3,
      Name: 'Laura',
      StartTime: new Date(2018, 8, 4, 9, 30),
      EndTime: new Date(2018, 8, 4, 10, 30),
      Description: 'Eye Checkup',
      DepartmentName: 'GENERAL'
  },
  {
      Id: 4,
      Name: 'Janet',
      StartTime: new Date(2018, 8, 3, 11, 0),
      EndTime: new Date(2018, 8, 3, 12, 30),
      Description: 'Gum Disease',
      DepartmentName: 'DENTAL'
  },
  {
      Id: 5,
      Name: 'Adams',
      StartTime: new Date(2018, 8, 3, 11, 0),
      EndTime: new Date(2018, 8, 3, 12, 30),
      Description: 'Observation',
      DepartmentName: 'GENERAL'
  },
  {
      Id: 6,
      Name: 'John',
      StartTime: new Date(2018, 8, 3, 11, 0),
      EndTime: new Date(2018, 8, 3, 12, 30),
      Description: 'Mouth Sores',
      DepartmentName: 'DENTAL'
  }
];

export let blockData: Object[] = [
  {
      Id: 1,
      Subject: 'Not Available',
      StartTime: new Date(2018, 7, 1, 10, 0),
      EndTime: new Date(2018, 7, 1, 12, 0),
      IsAllDay: false,
      IsBlock: true,
      EmployeeId: 1
  }, {
      Id: 2,
      Subject: 'Not Available',
      StartTime: new Date(2018, 7, 1, 16, 0),
      EndTime: new Date(2018, 7, 1, 20, 0),
      IsAllDay: false,
      IsBlock: true,
      EmployeeId: 2
  }, {
      Id: 3,
      Subject: 'Not Available',
      StartTime: new Date(2018, 7, 1, 12, 0),
      EndTime: new Date(2018, 7, 1, 14, 0),
      IsAllDay: false,
      IsBlock: true,
      EmployeeId: 3
  }, {
      Id: 4,
      Subject: 'Not Available',
      StartTime: new Date(2018, 7, 4, 11, 0),
      EndTime: new Date(2018, 7, 5, 10, 0),
      IsAllDay: true,
      IsBlock: true,
      EmployeeId: 4
  }, {
      Id: 5,
      Subject: 'Not Available',
      StartTime: new Date(2018, 7, 10, 11, 0),
      EndTime: new Date(2018, 7, 12, 10, 0),
      IsAllDay: false,
      IsBlock: true,
      EmployeeId: 5
  }, {
      Id: 6,
      Subject: 'Not Available',
      StartTime: new Date(2018, 7, 8),
      EndTime: new Date(2018, 7, 11),
      IsAllDay: false,
      IsBlock: true,
      EmployeeId: 6
  }, {
      Id: 9,
      Subject: 'Client Meeting',
      StartTime: new Date(2018, 7, 3, 8, 0),
      EndTime: new Date(2018, 7, 3, 10, 30),
      IsAllDay: false,
      EmployeeId: 3
  }, {
      Id: 10,
      Subject: 'Conference',
      StartTime: new Date(2018, 7, 2, 13, 30),
      EndTime: new Date(2018, 7, 2, 15, 0),
      IsAllDay: false,
      EmployeeId: 4
  }, {
      Id: 11,
      Subject: 'Employee Recruitment',
      StartTime: new Date(2018, 7, 1, 10, 0),
      EndTime: new Date(2018, 7, 1, 13, 0),
      IsAllDay: false,
      EmployeeId: 5
  }, {
      Id: 12,
      Subject: 'Data Analyzing',
      StartTime: new Date(2018, 7, 1, 15, 0),
      EndTime: new Date(2018, 7, 1, 17, 0),
      IsAllDay: false,
      EmployeeId: 6
  }, {
      Id: 13,
      Subject: 'Content Writting',
      StartTime: new Date(2018, 7, 2, 14, 0),
      EndTime: new Date(2018, 7, 2, 16, 0),
      IsAllDay: false,
      EmployeeId: 1
  }, {
      Id: 14,
      Subject: 'Meeting',
      StartTime: new Date(2018, 7, 1, 9, 0),
      EndTime: new Date(2018, 7, 1, 11, 0),
      IsAllDay: false,
      EmployeeId: 4
  }, {
      Id: 15,
      Subject: 'Not Available',
      StartTime: new Date(2018, 7, 29, 11, 0),
      EndTime: new Date(2018, 7, 31, 10, 0),
      IsAllDay: false,
      IsBlock: true,
      EmployeeId: 4
  }, {
      Id: 16,
      Subject: 'Not Available',
      StartTime: new Date(2018, 7, 12),
      EndTime: new Date(2018, 7, 15),
      IsAllDay: false,
      IsBlock: true,
      EmployeeId: 3
  }
];

export let resourceSampleData: Object[] = [
  {
      Id: 1,
      Subject: 'Burning Man',
      StartTime: new Date(2018, 5, 1, 15, 0),
      EndTime: new Date(2018, 5, 1, 17, 30),
      OwnerId: 1
  }, {
      Id: 2,
      Subject: 'Marketing Forum',
      StartTime: new Date(2018, 5, 3, 10, 0),
      EndTime: new Date(2018, 5, 3, 11, 30),
      OwnerId: 2
  }, {
      Id: 3,
      Subject: 'Business Factory',
      StartTime: new Date(2018, 5, 3, 13, 30),
      EndTime: new Date(2018, 5, 3, 15, 0),
      OwnerId: 3
  }, {
      Id: 4,
      Subject: 'Burning Man',
      StartTime: new Date(2018, 5, 4, 11, 30),
      EndTime: new Date(2018, 5, 4, 13, 0),
      OwnerId: 1
  }, {
      Id: 5,
      Subject: 'Funnel Hacking',
      StartTime: new Date(2018, 5, 5, 9, 30),
      EndTime: new Date(2018, 5, 5, 11, 0),
      OwnerId: 3
  }, {
      Id: 6,
      Subject: 'The human gathering"',
      StartTime: new Date(2018, 5, 5, 13, 0),
      EndTime: new Date(2018, 5, 5, 14, 30),
      OwnerId: 2
  }, {
      Id: 7,
      Subject: 'Techweek',
      StartTime: new Date(2018, 5, 6, 11, 0),
      EndTime: new Date(2018, 5, 6, 12, 30),
      OwnerId: 2
  }, {
      Id: 8,
      Subject: 'Grow Conference',
      StartTime: new Date(2018, 5, 7, 10, 0),
      EndTime: new Date(2018, 5, 7, 11, 30),
      OwnerId: 1
  }, {
      Id: 9,
      Subject: 'Data Science Conference',
      StartTime: new Date(2018, 5, 7, 13, 30),
      EndTime: new Date(2018, 5, 7, 15, 0),
      OwnerId: 1
  }, {
      Id: 10,
      Subject: 'Blogcademy',
      StartTime: new Date(2018, 5, 8, 12, 0),
      EndTime: new Date(2018, 5, 8, 13, 30),
      OwnerId: 3
  }, {
      Id: 11,
      Subject: 'World Domination Summit',
      StartTime: new Date(2018, 5, 9, 9, 30),
      EndTime: new Date(2018, 5, 9, 11, 0),
      OwnerId: 2
  }, {
      Id: 12,
      Subject: 'Content Marketing',
      StartTime: new Date(2018, 5, 9, 13, 0),
      EndTime: new Date(2018, 5, 9, 14, 30),
      OwnerId: 1
  }, {
      Id: 13,
      Subject: 'Mobile World Conference',
      StartTime: new Date(2018, 5, 15, 18, 0),
      EndTime: new Date(2018, 5, 15, 20, 0),
      OwnerId: 1
  }
];

export let quickInfoTemplateData: Object[] = [
  {
      RoomId: 10,
      Id: 1,
      Subject: 'Board Meeting',
      Description: 'Meeting to discuss business goal of 2020.',
      StartTime: '2020-01-05T04:00:00.000Z',
      EndTime: '2020-01-05T05:30:00.000Z'
  },
  {
      RoomId: 8,
      Id: 2,
      Subject: 'Training session on JSP',
      Description: 'Knowledge sharing on JSP topics.',
      StartTime: '2020-01-07T04:00:00.000Z',
      EndTime: '2020-01-07T05:30:00.000Z'
  },
  {
      RoomId: 3,
      Id: 3,
      Subject: 'Sprint Planning with Team members',
      Description: 'Planning tasks for sprint.',
      StartTime: '2020-01-09T04:00:00.000Z',
      EndTime: '2020-01-09T05:30:00.000Z'
  },
  {
      RoomId: 2,
      Id: 4,
      Subject: 'Meeting with Client',
      Description: 'Customer meeting to discuss features.',
      StartTime: '2020-01-11T03:30:00.000Z',
      EndTime: '2020-01-11T05:00:00.000Z'
  },
  {
      RoomId: 5,
      Id: 5,
      Subject: 'Support Meeting with Managers',
      Description: 'Meeting to discuss support plan.',
      StartTime: '2020-01-06T06:30:00.000Z',
      EndTime: '2020-01-06T08:00:00.000Z'
  },
  {
      RoomId: 1,
      Id: 6,
      Subject: 'Client Meeting',
      Description: 'Meeting to discuss client requirements.',
      StartTime: '2020-01-08T06:00:00.000Z',
      EndTime: '2020-01-08T07:30:00.000Z'
  },
  {
      RoomId: 7,
      Id: 7,
      Subject: 'Appraisal Meeting',
      Description: 'Meeting to discuss employee appraisals.',
      StartTime: '2020-01-10T05:30:00.000Z',
      EndTime: '2020-01-10T07:00:00.000Z'
  },
  {
      RoomId: 6,
      Id: 8,
      Subject: 'HR Meeting',
      Description: 'Meeting to discuss HR plans.',
      StartTime: '2020-01-05T07:30:00.000Z',
      EndTime: '2020-01-05T09:00:00.000Z'
  },
  {
      RoomId: 4,
      Id: 9,
      Subject: 'Customer Meeting',
      Description: 'Meeting to discuss customer reported issues.',
      StartTime: '2020-01 - 09T07: 00: 00.000Z',
      EndTime: '2020-01 - 09T08: 30: 00.000Z'
  },
  {
      RoomId: 9,
      Id: 10,
      Subject: 'Board Meeting',
      Description: 'Meeting to discuss business plans.',
      StartTime: '2020-01 - 11T07: 30: 00.000Z',
      EndTime: '2020-01 - 11T09: 00: 00.000Z'
  }
];
