import { Component, OnInit } from '@angular/core';
import { ChatService } from '../chat.service';

@Component({
  selector: 'app-chatcota',
  templateUrl: './chatcota.component.html',
  styleUrls: ['./chatcota.component.css']
})
export class ChatcotaComponent implements OnInit {

  chatselc = [];
  chatsx = [];
  user: String;
  room: String;
  currentCota = "";
  messageText: String;
  messageArray: Array<{ user: String, textmsg: String, tipo:String}> = [];
  public messageSeen(event: any) {
    console.log(event);
  }
  constructor(private _chatService: ChatService) 
  { 
    this._chatService.newUserJoined()
    .subscribe(data => console.log(data));


  this._chatService.userLeftRoom()
    .subscribe(data => this.messageArray.push(data));

  this._chatService.newMessageReceived()
  .subscribe(data => 
    this.apresentamsg(data))
  }

  
  ngOnInit() {
  }
  
  join() {
    this._chatService.joinRoom({ user: this.user, room: this.room });
  }

  leave() {
    this._chatService.leaveRoom({ user: this.user, room: this.room });
  }

  sendMessage() {
    console.log('enviado mensagem')
    this._chatService.sendMessage({ user: this.user, room: this.room, textmsg: this.messageText, tipo: 'in' ,ts: new Date(Date.now()).toLocaleString('pt-BR')});
    this.messageText = ""
  }

  apresentamsg(a){
    this.chatsx.push(a)
    console.log(a.textmsg)
  }
  enviamsg(a){
    this.messageText = a.message
    this.sendMessage();
    console.log(a)
  }
}
