import { Injectable } from "@angular/core";
import { Socket } from 'ngx-socket-io';
import {Observable} from 'rxjs';
import { map } from "rxjs/operators";

@Injectable()


export class ChatService{
    constructor(private socket: Socket) { }

    joinRoom(data)
    {
        this.socket.emit('join',data);
    }

    newUserJoined()
    {
        let observable = new Observable<{user:String, message:String}>(observer=>{
            this.socket.on('new user joined', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });
        console.log(observable)
        return observable;
    }

    onlinestatus(){
        let observable = new Observable<
        {
            user:String, 
            message:String
            }>(observer=>{
            this.socket.on('status', (data)=>{
              console.log(data);
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });
        return observable;
    }


    leaveRoom(data){
        this.socket.emit('leave',data);
    }

    userLeftRoom(){
        let observable = new Observable<{user:String, textmsg:String, tipo:String, ts:String }>(observer=>{
            this.socket.on('left room', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });
        return observable;
    }

    sendMessage(data)
    {
        this.socket.emit('message',data);
    }

    sendMessagex(data)
    {
        this.socket.emit('messagep',data);
    }
    
    newMessageReceived(){
        let observable = new Observable<
        {
            dono:String,
            user:String, 
            textmsg:String, 
            tipo:String, 
            data:String, 
            sender: String,
            recept:String,
            room:String}>(observer=>{
            this.socket.on('new message', (data)=>{
              console.log(data);
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });
        return observable;
    }

    newMessageReceivedx(){
        let observable = new Observable<
        {
            idono:String,
            hl:String, 
            data:String, 
            lida:String, 
            nome:String, 
            urlpic: String,
            msg:String,
            wpp: String
        }>(observer=>{
            this.socket.on('newdata', (data)=>{
              console.log(data);
                observer.next(data);
            });
            return () => {console.log('nova msg')}
        });
        return observable;
    }
    
}