import { todosdados } from './../homologacoes/todosdados';
import { Component, OnInit } from '@angular/core';
import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consultahomologa',
  templateUrl: './consultahomologa.component.html',
  styleUrls: ['./consultahomologa.component.css']
})
export class ConsultahomologaComponent implements OnInit {
  todashomologacoes: todosdados[];
  homologacaoselecionada: string;
  spinnerdados = '';

  settings = {
    defaultStyle: false,
    actions: false,
    attr: {
      class: 'table table-bordered' // this is custom table scss or css class for table
    },
    columns: {
      codhomol: {
        title: 'Código'
      },
      solicitante: {
        title: 'Solicitante'
      },
      Cliente: {
        title: 'Cliente'
      },
      Status: {
        title: 'Status'
      }
    }

  };

  constructor(private service: HomologacoesserviceService,
    private router: Router
  ) { }

  ngOnInit() {
    setTimeout(() => { this.table() }, 3000);
  }
  table() {

  }
  onUserRowSelect(event): void {
    this.btnClick();
  }
  btnClick = function () {
    this.router.navigateByUrl('/editahomologacao', { cod: this.homologacaoselecionada });
  };

}
