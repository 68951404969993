import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {AvatarModule as AvatarModulex} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';

// tslint:disable-next-line: max-line-length
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarService,
  NbSidebarModule,
  NbButtonModule,
  NbMenuModule,
  NbCardModule,
  NbMenuService,
  NbActionsModule,
  NbUserModule,
  NbPopoverModule,
  NbListModule,
  NbAccordionModule,
  NbDialogModule,
  NbToastrModule,
  NbInputModule,
  NbTooltipModule,
  NbSpinnerModule,
  NbChatModule,
  NbTabsetModule,
} from "@nebular/theme";
import { MenuComponent } from "./menu/menu.component";
import { HomologacoesComponent } from "./homologacoes/homologacoes.component";
import { HttpClientModule, HttpParams } from "@angular/common/http";
import { ConsultahomologaComponent } from "./consultahomologa/consultahomologa.component";
import { VisualizarquivosComponent } from "./visualizarquivos/visualizarquivos.component";
import { FormsModule } from "@angular/forms";
import { AuthService } from "./auth.service";
import { StorageService } from "./storage.service";
import { NotificationService } from "./notification.service";
import { AuthComponent } from "./auth/auth.component";
import { BoardComponent } from "./board/board.component";
import { StorageComponent } from "./storage/storage.component";
import { LogoutComponent } from "./logout/logout.component";
import { SearchComponent } from "./search/search.component";
import { UploadComponent } from "./upload/upload.component";
import { FilesizePipe } from "./filesize.pipe";
import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { FilesService } from "./files.service";
import { WebhooksComponent } from "./webhooks/webhooks.component";
import { DeleteComponent } from "./delete/delete.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCardModule } from "@angular/material/card";
import {CardModule} from 'primeng/card';
import { ClientecmComponent } from "./clientmanager/clientecm.component";
import { AlteracadastroComponent } from "./alteracadastro/alteracadastro.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AvatarModule } from "ngx-avatar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { SolicitahomologacaoComponent } from "./solicitahomologacao/solicitahomologacao/solicitahomologacao.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MapsComponent } from "./maps/maps.component";
import { FileUploadModule } from "ng2-file-upload";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import { ChartsModule } from "ng2-charts";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { MinhashomologacoesComponent } from "./minhashomologacoes/minhashomologacoes.component";
import { GeradorpropostaComponent } from "./geradorproposta/geradorproposta.component";
import { TextFieldModule } from "@angular/cdk/text-field";
import { SolicitarvistoriaComponent } from "./solicitarvistoria/solicitarvistoria.component";
import { NgxLoadingModule } from "ngx-loading";
import { NgxSpinnerModule } from "ngx-spinner";
import { ProgressBarModule } from "angular-progress-bar";
import { CotacoesComponent } from "./cotafacil/cotacoes/cotacoes.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { CKEditorModule } from "ng2-ckeditor";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { NbIconModule } from "@nebular/theme";
import { GestaoComponent } from "./gestao/gestao.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { FinanceiroComponent } from "./financeiro/financeiro.component";
import { GestaoarquivosComponent } from "./gestaoarquivos/gestaoarquivos.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { NgxImageDisplayModule } from "@creativeacer/ngx-image-display";
import { ImageViewerModule } from "@hallysonh/ngx-imageviewer";
import { MatGridListModule } from "@angular/material/grid-list";
import { NgxFileDropModule } from "ngx-file-drop";
import { ChatComponent } from "./chat/chat.component";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { registerLocaleData } from "@angular/common";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { IconDefinition } from "@ant-design/icons-angular";
import * as AllIcons from "@ant-design/icons-angular/icons";
import { NzButtonModule } from "ng-zorro-antd/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTabsModule } from "@angular/material/tabs";
import { PanelModule } from "primeng/panel";
import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { CalendarModule } from "primeng/calendar";
import { SliderModule } from "primeng/slider";
import { MultiSelectModule } from "primeng/multiselect";
import { ContextMenuModule } from "primeng/contextmenu";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { NzTableModule } from "ng-zorro-antd/table";
import { NZ_I18N } from "ng-zorro-antd/i18n";
import { pt_BR } from "ng-zorro-antd/i18n";
import pt from "@angular/common/locales/pt";
import { NzStatisticModule } from "ng-zorro-antd/statistic";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { InputMaskModule } from "primeng/inputmask";
import { NzDescriptionsModule } from "ng-zorro-antd/descriptions";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { InputNumberModule } from "primeng/inputnumber";
import { NzCardModule } from "ng-zorro-antd/card";
import { NgChatModule } from "ng-chat";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { ChatService } from "./chat.service";
import { ChatcotaComponent } from "./chatcota/chatcota.component";
import { SidebarModule } from "primeng/sidebar";
import { ListboxModule } from "primeng/listbox";
import { NzListModule } from "ng-zorro-antd/list";
import { NzCommentModule } from "ng-zorro-antd/comment";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { DividerModule } from "primeng/divider";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { FileUploadModule as filex } from "primeng/fileupload";
import { KanbanComponents } from "./kanban/kanban.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { TagModule } from "primeng/tag";
import { DocumentEditorModule } from "@syncfusion/ej2-angular-documenteditor";
import { KanbanAllModule } from "@syncfusion/ej2-angular-kanban";
import { CheckBoxAllModule } from "@syncfusion/ej2-angular-buttons";
import { DropDownListAllModule } from "@syncfusion/ej2-angular-dropdowns";
import { DatePickerAllModule } from "@syncfusion/ej2-angular-calendars";
import { MenuItem, TabAllModule, ToolbarModule } from "@syncfusion/ej2-angular-navigations";
import { NumericTextBoxAllModule } from "@syncfusion/ej2-angular-inputs";
import { GridAllModule } from "@syncfusion/ej2-angular-grids";
import { CheckBoxModule } from "@syncfusion/ej2-angular-buttons";
import { InPlaceEditorModule } from "@syncfusion/ej2-angular-inplace-editor";
import { CheckboxModule } from "primeng/checkbox";
import { BadgeModule } from "primeng/badge";
//import { EJAngular2Module } from 'ej-angular2';
import { MenuModule } from "@syncfusion/ej2-angular-navigations";
import { MenubarModule } from "primeng/menubar";
import { UploaderModule } from "@syncfusion/ej2-angular-inputs";
import { SplitterModule } from "@syncfusion/ej2-angular-layouts";
import { ListViewModule } from "@syncfusion/ej2-angular-lists";
import { TreeViewModule } from "@syncfusion/ej2-angular-navigations";
import {
  DocumentEditorAllModule,
  DocumentEditorContainerAllModule,
} from "@syncfusion/ej2-angular-documenteditor";
import { SpinnersAngularModule } from "spinners-angular";
import { NzTransferModule } from "ng-zorro-antd/transfer";
import { PickListModule } from "primeng/picklist";
import { extend, loadCldr, L10n, setCulture } from "@syncfusion/ej2-base";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as gregorian from "cldr-data/main/pt/ca-gregorian.json";
import * as numbers from "cldr-data/main/pt/numbers.json";
import * as timeZoneNames from "cldr-data/main/pt/timeZoneNames.json";
import { NzResultModule } from "ng-zorro-antd/result";
import { MomentModule } from "ngx-moment";
import { RadioButtonModule } from "primeng/radiobutton";
import { NzInputModule } from "ng-zorro-antd/input";
import {AutoCompleteModule} from 'primeng/autocomplete';
import { HammerModule } from "@angular/platform-browser";
import { CodeInputModule } from "angular-code-input";
import { CookieService } from "ngx-cookie-service";
import { ToolbarModule as xToolbarModule } from "primeng/toolbar";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { AccordionModule } from "primeng/accordion";
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NzSelectModule } from 'ng-zorro-antd/select';
import {TabViewModule} from 'primeng/tabview';

import {
  TextBoxModule,
  NumericTextBoxModule,
} from "@syncfusion/ej2-angular-inputs";
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
import { setCurrencyCode } from "@syncfusion/ej2-base";
import { SafexPipe } from "./safex.pipe";
import { CalendarioComponent } from "./calendario/calendario.component";
import {
  ScheduleAllModule,
  RecurrenceEditorAllModule,
} from "@syncfusion/ej2-angular-schedule";
import { DropDownButtonAllModule } from "@syncfusion/ej2-angular-splitbuttons";
import {
  MaskedTextBoxModule,
  UploaderAllModule,
} from "@syncfusion/ej2-angular-inputs";
import {
  ToolbarAllModule,
  ContextMenuAllModule,
} from "@syncfusion/ej2-angular-navigations";
import {
  ButtonAllModule,
  SwitchAllModule,
} from "@syncfusion/ej2-angular-buttons";
import { MultiSelectAllModule } from "@syncfusion/ej2-angular-dropdowns";
import {
  TimePickerAllModule,
  DateTimePickerAllModule,
} from "@syncfusion/ej2-angular-calendars";
import { TextBoxAllModule } from "@syncfusion/ej2-angular-inputs";
import { NgxFixedDecimalModule } from "ngx-fixed-decimal";
import {
  ChartAllModule,
  AccumulationChartAllModule,
  RangeNavigatorAllModule,
} from "@syncfusion/ej2-angular-charts";
import { ListViewAllModule } from '@syncfusion/ej2-angular-lists';

import { AccordionAllModule } from '@syncfusion/ej2-angular-navigations';
import { SpreadsheetAllModule } from "@syncfusion/ej2-angular-spreadsheet";
import { EmployersComponent } from './employers/employers.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FilemanagerComponent } from './filemanager/filemanager.component';
import { FileManagerAllModule } from '@syncfusion/ej2-angular-filemanager';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {StyleClassModule} from 'primeng/styleclass';

registerLocaleData(pt);
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);
const config: SocketIoConfig = { url: "https://sollux.dev", options: {} };
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { SmartcotaComponent } from './smartcota/smartcota.component';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { CursoComponent } from './curso/curso.component';
import { TicketsComponent } from './tickets/tickets.component';
import { MessageService } from "primeng/api";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IntersolarComponent } from './intersolar/intersolar.component';
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomologacoesComponent,
    ConsultahomologaComponent,
    VisualizarquivosComponent,
    AuthComponent,
    BoardComponent,
    StorageComponent,
    LogoutComponent,
    SearchComponent,
    UploadComponent,
    FilesizePipe,
    BreadcrumbsComponent,
    WebhooksComponent,
    DeleteComponent,
    GeradorpropostaComponent,
    MinhashomologacoesComponent,
    ClientecmComponent,
    AlteracadastroComponent,
    SolicitahomologacaoComponent,
    MapsComponent,
    SolicitarvistoriaComponent,
    CotacoesComponent,
    GestaoComponent,
    FinanceiroComponent,
    GestaoarquivosComponent,
    ChatComponent,
    ChatcotaComponent,
    KanbanComponents,
    SafexPipe,
    CalendarioComponent,
    EmployersComponent,
    SidebarComponent,
    FilemanagerComponent,
    SmartcotaComponent,
    CursoComponent,
    TicketsComponent,
    IntersolarComponent,
  ],
  imports: [
    TabAllModule,
    TabViewModule,
    AutoCompleteModule,
    RichTextEditorAllModule,
    NzSelectModule,
    DateRangePickerModule,
    NzProgressModule,
    StyleClassModule,
    ProgressSpinnerModule,
    NgxChartsModule,
    ReactiveFormsModule,
    AvatarModule,
    AvatarModulex,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: "default" }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbSidebarModule,
    NbUserModule,
    NbButtonModule,
    RouterModule,
    NbMenuModule,
    NbCardModule,
    DashboardLayoutModule,
    NbMenuModule.forRoot(),
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatFormFieldModule,
    NbActionsModule,
    MatCardModule,
    CardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    FileUploadModule,
    MatListModule,
    MatExpansionModule,
    ChartsModule,
    MatTabsModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(),
    NbPopoverModule,
    NbListModule,
    NbAccordionModule,
    NbDialogModule.forRoot(),
    TextFieldModule,
    NbToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ProgressBarModule,
    AngularEditorModule,
    CKEditorModule,
    BrowserModule,
    BrowserAnimationsModule,
    NbEvaIconsModule,
    NbIconModule,
    NbMenuModule,
    NbInputModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    NgxImageDisplayModule.forRoot(),
    ImageViewerModule,
    MatGridListModule,
    NgxFileDropModule,
    NbTooltipModule,
    NbSpinnerModule,
    NbTabsetModule,
    //PRIME MODULES
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    // zoromodule
    NzDatePickerModule,
    NzInputNumberModule,
    NzLayoutModule,
    NzButtonModule,
    NzButtonModule,
    NzTableModule,
    NzStatisticModule,
    NzDropDownModule,
    NzFormModule,
    NzGridModule,
    NzSpaceModule,
    NzTypographyModule,
    NzDividerModule,
    NzIconModule,
    NzPageHeaderModule,
    NzPaginationModule,
    NzStepsModule,
    InputMaskModule,
    NzDescriptionsModule,
    NzPopconfirmModule,
    InputNumberModule,
    NzCardModule,
    NgChatModule,
    NbChatModule,
    SocketIoModule.forRoot(config),
    SidebarModule,
    ListboxModule,
    NzListModule,
    NzCommentModule,
    NzAvatarModule,
    DividerModule,
    ScrollPanelModule,
    PanelModule,
    NzUploadModule,
    filex,
    DocumentEditorModule,
    KanbanAllModule,
    CheckBoxAllModule,
    ToolbarModule,
    GridAllModule,
    BrowserModule,
    NumericTextBoxAllModule,
    DialogModule,
    DatePickerAllModule,
    DropDownListAllModule,
    ReactiveFormsModule,
    FormsModule,
    CheckBoxModule,
    InPlaceEditorModule,
    CheckboxModule,
    MenuModule,
    MenubarModule,
    DocumentEditorAllModule,
    DocumentEditorContainerAllModule,
    SplitterModule,
    UploaderModule,
    TreeViewModule,
    ListViewModule,
    SpinnersAngularModule,
    NzTransferModule,
    DragDropModule,
    TagModule,
    PickListModule,
    BadgeModule,
    NzResultModule,
    MomentModule,
    RadioButtonModule,
    NzInputModule,
    AutoCompleteModule,
    HammerModule,
    CodeInputModule,
    xToolbarModule,
    NzTabsModule,
    AccordionModule,
    TextBoxModule,
    NumericTextBoxModule,
    ScheduleAllModule,
    RecurrenceEditorAllModule,
    DropDownButtonAllModule,
    MaskedTextBoxModule,
    UploaderAllModule,
    ToolbarAllModule,
    ContextMenuAllModule,
    ButtonAllModule,
    SwitchAllModule,
    MultiSelectAllModule,
    TimePickerAllModule,
    DateTimePickerAllModule,
    TextBoxAllModule,
    NgxFixedDecimalModule,
    SpreadsheetAllModule,
    ChartAllModule,
    AccumulationChartAllModule,
    RangeNavigatorAllModule,
    FileManagerAllModule,
    AccordionAllModule,
    ListViewAllModule,
    RichTextEditorModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    NbSidebarService,
    NbMenuService,
    AuthService,
    FilesService,
    StorageService,
    CookieService,
    NotificationService,
    ChatService,
    MessageService,
    { provide: NZ_I18N, useValue: pt_BR },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
