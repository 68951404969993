<nb-card style="margin-right: 0.5rem;margin-left: 0.5rem;margin-top: 1rem !important;">
    <nb-card-header style="background-color: rgb(186, 248, 248); color:black;">Seus Dados - Atenção esses dados são colocados em ARTS e documentos técnicos
    </nb-card-header>
    <nb-card-body style="background-color: #ffffff; color: black">



        <div class="form-row">
            <div class="form-group col-5">
                <ejs-textbox placeholder="Nome" cssClass="e-outline" [(ngModel)]="nome" floatLabelType="Always"></ejs-textbox>
            </div>
            <div class="form-group col-3">
                <ejs-textbox placeholder="CPF/CNPJ" cssClass="e-outline" [(ngModel)]="cpf" floatLabelType="Always"></ejs-textbox>
            </div>
            <div class="form-group col-4">
                <ejs-textbox placeholder="Telefone " cssClass="e-outline" [(ngModel)]="telefone" floatLabelType="Always"></ejs-textbox>
            </div>
        </div>


        <div class="form-row">
            <div class="form-group col-5">
                <ejs-textbox placeholder="Rua" cssClass="e-outline" [(ngModel)]="rua" floatLabelType="Always"></ejs-textbox>
            </div>
            <div class="form-group col-3">
                <ejs-textbox placeholder="Bairro " cssClass="e-outline" [(ngModel)]="bairro" floatLabelType="Always"></ejs-textbox>
            </div>
            <div class="form-group col-2">
                <ejs-textbox placeholder="Número" cssClass="e-outline" [(ngModel)]="numero" floatLabelType="Always"></ejs-textbox>
            </div>
            <div class="form-group col-2">
                <ejs-textbox placeholder="Complemento " cssClass="e-outline" [(ngModel)]="complemento" floatLabelType="Always"></ejs-textbox>
            </div>

        </div>

        <div class="form-row">
            <div class="form-group col-5">
                <ejs-textbox placeholder="Email " cssClass="e-outline" [(ngModel)]="email" floatLabelType="Always"></ejs-textbox>
            </div>
            <div class="form-group col-3">
                <ejs-textbox placeholder="Cidade" cssClass="e-outline" [(ngModel)]="cidade" floatLabelType="Always"></ejs-textbox>
            </div>
            <div class="form-group col-2">
                <ejs-textbox placeholder="Estado" cssClass="e-outline" [(ngModel)]="estado" floatLabelType="Always"></ejs-textbox>
            </div>
            <div class="form-group col-2">
                <ejs-textbox placeholder="CEP " cssClass="e-outline" [(ngModel)]="cep" floatLabelType="Always"></ejs-textbox>
            </div>

        </div>

        <p-divider> <span class="teste">Representante Legal</span></p-divider>
        <div class="form-row">
            <div class="form-group col-4">
                <ejs-textbox placeholder="Nome" cssClass="e-outline" [(ngModel)]="replegal" floatLabelType="Always"></ejs-textbox>
            </div>
            <div class="form-group col-4">
                <ejs-textbox placeholder="CPF" cssClass="e-outline" [(ngModel)]="cpflegal" floatLabelType="Always"></ejs-textbox>
            </div>
            <div class="form-group col-4">
                <ejs-textbox placeholder="RG " cssClass="e-outline" [(ngModel)]="rglegal" floatLabelType="Always"></ejs-textbox>
            </div>
        </div>


        <div class="row">
            <button style="width: 100%;" (click)="upacliente()" class=" btn btn-dark my-2 my-sm-6">Atualizar</button>
        </div>




    </nb-card-body>

</nb-card>