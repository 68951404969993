import { Component, ViewChild } from '@angular/core';
import { zooEventsData } from './data';
import { addClass, extend, removeClass, closest, remove, isNullOrUndefined, Internationalization } from '@syncfusion/ej2-base';
import { GroupModel,
  TimelineViewsService, TimelineMonthService, Timezone, CurrentAction,
  CellClickEventArgs, ResourcesModel, EJ2Instance, PrintService, ExcelExportService, ICalendarExportService,
    EventSettingsModel, View, EventRenderedArgs, DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService, YearService,TimelineYearService, ScheduleComponent, WorkHoursModel
} from '@syncfusion/ej2-angular-schedule';

import { HomologacoesserviceService } from '../homologacoes/homologacoesservice.service';
import { BeforeOpenCloseMenuEventArgs, ClickEventArgs, ContextMenuComponent, MenuEventArgs, MenuItemModel } from '@syncfusion/ej2-angular-navigations';
import { SelectedEventArgs, TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';
import { ItemModel } from '@syncfusion/ej2-angular-splitbuttons';
import { ChangeEventArgs as SwitchEventArgs } from '@syncfusion/ej2-angular-buttons';
import { ChangeEventArgs, MultiSelectChangeEventArgs, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ChangeEventArgs as TimeEventArgs } from '@syncfusion/ej2-calendars';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css'],
  providers: [DayService, WeekService, WorkWeekService, MonthService, YearService, AgendaService,
    TimelineViewsService, TimelineMonthService, TimelineYearService, PrintService, ExcelExportService, ICalendarExportService]
})
export class CalendarioComponent   {
  id: any;
  insere = []
  public datax: Object[] = <Object[]>extend([], zooEventsData, null, true);
  //public selectedDate: Date = new Date();
  public eventSettings: EventSettingsModel;
  public currentView: View = 'Week';
  @ViewChild('scheduleObj') scheduleObj: ScheduleComponent;
  @ViewChild('eventTypeObj') eventTypeObj: DropDownListComponent;
  @ViewChild('titleObj') titleObj: TextBoxComponent;
  @ViewChild('notesObj') notesObj: TextBoxComponent;
  public showFileList: Boolean = false;
  public multiple: Boolean = false;
  public buttons: Object = { browse: 'Import' };
  public intl: Internationalization = new Internationalization();
  public liveTimeUpdate: String = new Date().toLocaleTimeString('en-US', { timeZone: 'UTC' });
  equie = [];
  public employeeDataSource: Record<string, any>[] = [];
  //public group: GroupModel = { enableCompactView: false, resources: ['Employee'] };
  public group: GroupModel = { enableCompactView: false,resources: ['Calendars','Employee']};
  public resourceDataSource: Object[] = [
      { CalendarText: 'My Calendar', CalendarId: 1, CalendarColor: '#c43081' },
      { CalendarText: 'Company', CalendarId: 2, CalendarColor: '#ff7f50' },
      { CalendarText: 'Birthday', CalendarId: 3, CalendarColor: '#AF27CD' },
      { CalendarText: 'Holiday', CalendarId: 4, CalendarColor: '#808000' }
  ];
  public resourceQuery: Query = new Query().where('CalendarId', 'equal', 1);
  public allowMultiple: Boolean = true;
  public isTimelineView: Boolean = false;
  public exportItems: ItemModel[] = [
      { text: 'iCalendar', iconCss: 'e-icons e-schedule-ical-export' },
      { text: 'Excel', iconCss: 'e-icons e-schedule-excel-export' }
  ];
  itemsx: MenuItem[];
  public checkboxMode: String = 'CheckBox';
  public firstDayOfWeek: Number = 0;
  public workDays: Number[] = [1, 2, 3, 4, 5];
  public calendarsValue: Number[] = [1];
  public fields: Object = { text: 'text', value: 'value' };
  public calendarFields: Object = { text: 'CalendarText', value: 'CalendarId' };
  public dayStartHourValue: Date = new Date(new Date().setHours(0, 0, 0));
  public dayEndHourValue: Date = new Date(new Date().setHours(23, 59, 59));
  public workStartHourValue: Date = new Date(new Date().setHours(9, 0, 0));
  public workEndHourValue: Date = new Date(new Date().setHours(18, 0, 0));
  public workHours: WorkHoursModel = { start: '7:00', end: '19:00' };
  public scheduleHours: WorkHoursModel  = { highlight: true, start: '07:00', end: '19:00' };

  public weekDays: Object[] = [
      { text: 'Domingo', value: 0 },
      { text: 'Segunda', value: 1 },
      { text: 'Terça', value: 2 },
      { text: 'Quarta', value: 3 },
      { text: 'Quinta', value: 4 },
      { text: 'Sexta', value: 5 },
      { text: 'Sábado', value: 6 }
  ];
  public timezoneData: Object[] = [
    { text: 'UTC -03:00', value: 'Etc/GMT+3' },
      { text: 'UTC +01:00', value: 'Etc/GMT-1' },
      { text: 'UTC +02:00', value: 'Etc/GMT-2' },
      { text: 'UTC +03:00', value: 'Etc/GMT-3' },
      { text: 'UTC +04:00', value: 'Etc/GMT-4' },
  ];
  public timeSlotDuration: Object[] = [
      { Name: '1 hora', Value: 60 },
      { Name: '1.5 hora', Value: 90 },
      { Name: '2 hora', Value: 120 },
      { Name: '2.5 hora', Value: 150 },
      { Name: '3 hora', Value: 180 },
      { Name: '3.5 hora', Value: 210 },
      { Name: '4 hora', Value: 240 },
      { Name: '4.5 hora', Value: 270 },
      { Name: '5 hora', Value: 300 },
      { Name: '5.5 hora', Value: 330 },
      { Name: '6 hora', Value: 360 },
      { Name: '6.5 hora', Value: 390 },
      { Name: '7 hora', Value: 420 },
      { Name: '7.5 hora', Value: 450 },
      { Name: '8 hora', Value: 480 },
      { Name: '8.5 hora', Value: 510 },
      { Name: '9 hora', Value: 540 },
      { Name: '9.5 hora', Value: 570 },
      { Name: '10 hora', Value: 600 },
      { Name: '10.5 hora', Value: 630 },
      { Name: '11 hora', Value: 660 },
      { Name: '11.5 hora', Value: 690 },
      { Name: '12 hora', Value: 720 }
  ];
  public timeSlotFields = { text: 'Name', value: 'Value' };
  public timeSlotCount: Number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public timeSlotDurationValue: Number = 60;
  public timeSlotCountValue: Number = 2;
  public timeFormatData: Object[] = [
      { Name: '12 horas', Value: "hh:mm a" },
      { Name: '24 horas', Value: "HH:mm" }
  ];
  public timeFormatValue: string = "hh:mm a";
  public weekNumberData: Object[] = [
      { Name: 'Off', Value: 'Off' },
      { Name: 'Primeiro dia do ano', Value: 'FirstDay' },
      { Name: 'Primeira Semana inteira', Value: 'FirstFullWeek' },
      { Name: 'Primeira semana de quatro dias', Value: 'FirstFourDayWeek' }
  ];
  public weekNumberValue: string = "Off";
  @ViewChild('menuObj')
  public menuObj: ContextMenuComponent;
  public selectedTarget: Element;
  public menuItems: MenuItemModel[] = [
      { text: 'Novo Evento', iconCss: 'e-icons new', id: 'Add' },
      { text: 'Novo evento recorrente', iconCss: 'e-icons recurrence', id: 'AddRecurrence' },
      { text: 'Hoje', iconCss: 'e-icons today', id: 'Today' },
      { text: 'Editar Evento', iconCss: 'e-icons edit', id: 'Save' },
      {
          text: 'Editar Evento', id: 'EditRecurrenceEvent', iconCss: 'e-icons edit',
          items: [
              { text: 'Editar Ocorrência', id: 'EditOccurrence' },
              { text: 'Editar Série', id: 'EditSeries' }
          ]
      },
      { text: 'Delete Event', iconCss: 'e-icons delete', id: 'Delete' },
      {
          text: 'Deletar Evento', id: 'DeleteRecurrenceEvent', iconCss: 'e-icons delete',
          items: [
              { text: 'Deletar Ocorrência', id: 'DeleteOccurrence' },
              { text: 'Deletar Série', id: 'DeleteSeries' }
          ]
      }
  ];
  constructor(private service: HomologacoesserviceService) { }
  
  public onToolbarItemClicked(args: ClickEventArgs): void {
    console.log(args.item.text)
    switch (args.item.text) {
        case 'Dia':
            this.currentView = this.isTimelineView ? 'TimelineDay' : 'Day';
            break;
        case 'Semana':
            this.currentView = this.isTimelineView ? 'TimelineWeek' : 'Week';
            break;
        case 'WorkWeek':
            this.currentView = this.isTimelineView ? 'TimelineWorkWeek' : 'WorkWeek';
            break;
        case 'Mês':
            this.currentView = this.isTimelineView ? 'TimelineMonth' : 'Month';
            break;
        case 'Ano':
            this.currentView = this.isTimelineView ? 'TimelineYear' : 'Year';
            break;
        case 'Agenda':
            this.currentView = 'Agenda';
            break;

    }
  }
  
  public onSettingsClick(args): void {
    const settingsPanel: Element = document.querySelector('.overview-content .right-panel');
    if (settingsPanel.classList.contains('hide')) {
        removeClass([settingsPanel], 'hide');
    } else {
        addClass([settingsPanel], 'hide');
    }
    this.scheduleObj.refreshEvents();
  }

  ngOnInit(): void {
    this.itemsx = [
      {
        label: 'Fechar',
        icon: 'pi pi-angle-double-left',
        command: (event) => {
        
        }
      },
      {
        label: 'Adicionar tarefa',
        icon: 'pi pi-plus',
        command: (event) => {
          const eventData: Object = this.getEventData();
          this.scheduleObj.openEditor(eventData, 'Add', true);
        }
      },
      {
        label: 'Tarefa Recorrente',
        icon: 'pi pi-replay',
        command: (event) => {
          const recEventData: Object = this.getEventData();
          this.scheduleObj.openEditor(recEventData, 'Add', true, 1)
        }
      },
      {
        label: 'Hoje',
        icon: 'pi pi-clock',
        command: (event) => {
          this.currentView = this.isTimelineView ? 'TimelineDay' : 'Day';
        }
      },
      {
        label: 'Mês',
        icon: 'pi pi-calendar',
        command: (event) => {
          this.currentView = this.isTimelineView ? 'TimelineMonth' : 'Month';
        }
      },
      {
        label: 'Semana',
        icon: 'pi pi-calendar-plus',
        command: (event) => {
          this.currentView = this.isTimelineView ? 'TimelineWeek' : 'Week';
        }
      },
      {
        label: 'Agenda',
        icon: 'pi pi-list',
        command: (event) => {
          this.currentView = 'Agenda';
        }
      },
      {
        label: 'Ano',
        icon: 'pi pi-sitemap',
        command: (event) => {
          this.currentView = 'Year';
        }
      }


    ];
    this.service.currentid.subscribe(dados => {
      if (dados) {
        this.id = dados;
        this.puxaequipe()
      }
    });
  }

  private getEventData(): Object {
    const date: Date = this.scheduleObj.selectedDate;
    return {
        Id: this.scheduleObj.getEventMaxID(),
        Subject: '',
        StartTime: new Date(date.getFullYear(), date.getMonth(), date.getDate(), new Date().getHours(), 0, 0),
        EndTime: new Date(date.getFullYear(), date.getMonth(), date.getDate(), new Date().getHours() + 1, 0, 0),
        Location: '',
        Description: '',
        IsAllDay: false,
        CalendarId: 1
    };
}
  public onTimelineViewChange(args: SwitchEventArgs): void {
    this.isTimelineView = args.checked;
    switch (this.scheduleObj.currentView) {
        case 'Day':
        case 'TimelineDay':
            this.currentView = this.isTimelineView ? 'TimelineDay' : 'Day';
            break;
        case 'Week':
        case 'TimelineWeek':
            this.currentView = this.isTimelineView ? 'TimelineWeek' : 'Week';
            break;
        case 'WorkWeek':
        case 'TimelineWorkWeek':
            this.currentView = this.isTimelineView ? 'TimelineWorkWeek' : 'WorkWeek';
            break;
        case 'Month':
        case 'TimelineMonth':
            this.currentView = this.isTimelineView ? 'TimelineMonth' : 'Month';
            break;
        case 'Year':
        case 'TimelineYear':
            this.currentView = this.isTimelineView ? 'TimelineYear' : 'Year';
            break;
        case 'Agenda':
            this.currentView = 'Agenda';
            break;
    }
}
  oneventRendered(args: EventRenderedArgs): void {
      let categoryColor: string = args.data.CategoryColor as string;
      if (!args.element || !categoryColor) {
          return;
      }
      if (this.currentView === 'Agenda') {
          (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
      } else {
          args.element.style.backgroundColor = categoryColor;
      }
  }

  onActionComplete(a): void {
    console.log(a.data)
    if (a.data) {
      this.insere = a.data
    if (this.insere[0].OwnerId === undefined) {
      this.insere[0].OwnerId = this.id
      console.log(this.insere[0].OwnerId)
    }
    if (this.insere[0].Subject === undefined) {
      this.insere[0].Subject = null
      console.log(this.insere[0].Subject)
    }
    if (this.insere[0].Tags === undefined) {
      this.insere[0].Tags = null
      console.log(this.insere[0].Tags)
    }
    if (this.insere[0].Description === undefined) {
      this.insere[0].Description = null
      console.log(this.insere[0].Description)
    }
    if (this.insere[0].StartTime === undefined) {
      this.insere[0].StartTime = null
      console.log(this.insere[0].StartTime)
    }
    if (this.insere[0].EndTime === undefined) {
      this.insere[0].EndTime = null
      console.log(this.insere[0].EndTime)
    }
    if (this.insere[0].ImageName === undefined) {
      this.insere[0].ImageName = null
      console.log(this.insere[0].ImageName)
    }
    if (this.insere[0].PrimaryColor === undefined) {
      this.insere[0].PrimaryColor = null
      console.log(this.insere[0].PrimaryColor)
    }
    if (this.insere[0].SecondaryColor === undefined) {
      this.insere[0].SecondaryColor = null
      console.log(this.insere[0].SecondaryColor)
    }

    if (this.insere[0].IsAllDay === undefined) {
      this.insere[0].IsAllDay = null
      console.log(this.insere[0].IsAllDay)
    }
    if (this.insere[0].ProjectId === undefined) {
      this.insere[0].ProjectId = null
      console.log(this.insere[0].ProjectId)
    }
    if (this.insere[0].TaskId === undefined) {
      this.insere[0].TaskId = null
      console.log(this.insere[0].TaskId)
    }
    if (this.insere[0].DoctorId === undefined) {
      this.insere[0].DoctorId = null
      console.log(this.insere[0].DoctorId)
    }

    if (this.insere[0].RoomId === undefined) {
      this.insere[0].RoomId = null
      console.log(this.insere[0].RoomId)
    }

    if (this.insere[0].iddono === undefined) {
      this.insere[0].iddono = this.id
      console.log(this.insere[0].iddono)
    }
    if (this.insere[0].Location === undefined) {
      this.insere[0].Location = null
      console.log(this.insere[0].Location)
    }
    if (a.requestType === "eventChanged") {
      this.upacalendar()
    }
    if (a.requestType === "eventCreated") {
      this.inserecal();
    }
    if (a.requestType === "eventRemoved") {
      this.deletecal();
    }
    console.log(this.insere)
    //this.inserecal();
  }
  }
  inserecal(){
    console.log(this.insere, 'insere calendario')
    this.service.insertcal(this.insere)
    .subscribe(dados => {
      if (dados) {
        console.log(dados)
      }
    });
  }

  deletecal() {
    this.service
      .delcar({
        token: localStorage.getItem("tokenx"),
        Id: this.insere[0].Id,
        iddono: this.id,
      })
      .subscribe((dados) => {
        console.log(dados)
      })
  }
  upacalendar() {
    this.service.upacalendar({
      token:localStorage.getItem('tokenx'),
      chaves: this.insere
    })
      .subscribe((data) => {
        if (data) {
          console.log(data);
        }
      });
  }

  public onWeekDayChange(args: ChangeEventArgs): void {
    this.scheduleObj.firstDayOfWeek = args.value as number;
}

public onWorkWeekDayChange(args: MultiSelectChangeEventArgs): void {
    this.scheduleObj.workDays = args.value as number[];
}

public onResourceChange(args: MultiSelectChangeEventArgs): void {
  let resourcePredicate: Predicate;
  for (const value of args.value) {
      if (resourcePredicate) {
          resourcePredicate = resourcePredicate.or(new Predicate('CalendarId', 'equal', value));
      } else {
          resourcePredicate = new Predicate('CalendarId', 'equal', value);
      }
  }
  //this.scheduleObj.resources[0].query = resourcePredicate ? new Query().where(resourcePredicate) :
    //  new Query().where('CalendarId', 'equal', 1);
}
  public onSelected(args: SelectedEventArgs): void {
  console.log(args)
  this.scheduleObj.importICalendar((<HTMLInputElement>args.event.target).files[0]);
}
public onPrintClick(): void {
  this.scheduleObj.print();
}
  
public onExportClick(args): void {
  if (args.item.text === 'Excel') {
      let exportDatas: { [key: string]: Object }[] = [];
      let eventCollection: Object[] = this.scheduleObj.getEvents();
      let resourceCollection: ResourcesModel[] = this.scheduleObj.getResourceCollections();
      let resourceData: { [key: string]: Object }[] = resourceCollection[0].dataSource as { [key: string]: Object }[];
      for (let resource of resourceData) {
          let data: Object[] = eventCollection.filter((e: { [key: string]: Object }) => e.CalendarId === resource.CalendarId);
          exportDatas = exportDatas.concat(data as { [key: string]: Object }[]);
      }
      this.scheduleObj.exportToExcel({
          exportType: 'xlsx', customData: exportDatas, fields: ['Id', 'Subject', 'StartTime', 'EndTime', 'CalendarId']
      });
  } else {
      this.scheduleObj.exportToICalendar();
  }
}
public onContextMenuBeforeOpen(args: BeforeOpenCloseMenuEventArgs): void {
  const newEventElement: HTMLElement = document.querySelector('.e-new-event') as HTMLElement;
  if (newEventElement) {
      remove(newEventElement);
      removeClass([document.querySelector('.e-selected-cell')], 'e-selected-cell');
  }
  const targetElement: HTMLElement = <HTMLElement>args.event.target;
  if (closest(targetElement, '.e-contextmenu')) {
      return;
  }
  this.selectedTarget = closest(targetElement, '.e-appointment,.e-work-cells,' +
      '.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells');
  if (isNullOrUndefined(this.selectedTarget)) {
      args.cancel = true;
      return;
  }
  if (this.selectedTarget.classList.contains('e-appointment')) {
      const eventObj: { [key: string]: Object } = this.scheduleObj.getEventDetails(this.selectedTarget) as { [key: string]: Object };
      if (eventObj.RecurrenceRule) {
          this.menuObj.showItems(['EditRecurrenceEvent', 'DeleteRecurrenceEvent'], true);
          this.menuObj.hideItems(['Add', 'AddRecurrence', 'Today', 'Save', 'Delete'], true);
      } else {
          this.menuObj.showItems(['Save', 'Delete'], true);
          this.menuObj.hideItems(['Add', 'AddRecurrence', 'Today', 'EditRecurrenceEvent', 'DeleteRecurrenceEvent'], true);
      }
      return;
  }
  this.menuObj.hideItems(['Save', 'Delete', 'EditRecurrenceEvent', 'DeleteRecurrenceEvent'], true);
  this.menuObj.showItems(['Add', 'AddRecurrence', 'Today'], true);
}
  
public onMenuItemSelect(args: MenuEventArgs): void {
  const selectedMenuItem: string = args.item.id;
  let eventObj: { [key: string]: number };
  if (this.selectedTarget.classList.contains('e-appointment')) {
      eventObj = this.scheduleObj.getEventDetails(this.selectedTarget) as { [key: string]: number };
  }
  switch (selectedMenuItem) {
      case 'Today':
          this.scheduleObj.selectedDate = new Date();
          break;
      case 'Add':
      case 'AddRecurrence':
          const selectedCells: Element[] = this.scheduleObj.getSelectedElements();
          const activeCellsData: CellClickEventArgs =
              this.scheduleObj.getCellDetails(selectedCells.length > 0 ? selectedCells : this.selectedTarget);
          if (selectedMenuItem === 'Add') {
              this.scheduleObj.openEditor(activeCellsData, 'Add');
          } else {
              this.scheduleObj.openEditor(activeCellsData, 'Add', null, 1);
          }
          break;
      case 'Save':
      case 'EditOccurrence':
      case 'EditSeries':
          if (selectedMenuItem === 'EditSeries') {
              const query: Query = new Query().where(this.scheduleObj.eventFields.id, 'equal', eventObj.RecurrenceID);
              eventObj = new DataManager(this.scheduleObj.eventsData).executeLocal(query)[0] as { [key: string]: number };
          }
          this.scheduleObj.openEditor(eventObj, selectedMenuItem);
          break;
      case 'Delete':
          this.scheduleObj.deleteEvent(eventObj);
          break;
      case 'DeleteOccurrence':
      case 'DeleteSeries':
          this.scheduleObj.deleteEvent(eventObj, selectedMenuItem);
          break;
  }
}
public onTimezoneChange(args: ChangeEventArgs): void {
    this.scheduleObj.timezone = args.value as string;
    this.updateLiveTime(this.scheduleObj.timezone);
    document.querySelector('.schedule-overview #timezoneBtn').innerHTML =
        '<span class="e-btn-icon e-icons e-schedule-timezone e-icon-left"></span>' + args.itemData.text;
}
public updateLiveTime(timezone: string = 'UTC'): void {
  this.liveTimeUpdate = new Date().toLocaleTimeString('en-US', { timeZone: timezone });
}
public onDayStartHourChange(args: TimeEventArgs): void {
    this.scheduleObj.startHour = this.intl.formatDate(args.value, { skeleton: 'Hm' });
}

public onDayEndHourChange(args: TimeEventArgs): void {
    this.scheduleObj.endHour = this.intl.formatDate(args.value, { skeleton: 'Hm' });
}

public onWorkStartHourChange(args: TimeEventArgs): void {
    this.scheduleObj.workHours.start = this.intl.formatDate(args.value, { skeleton: 'Hm' });
}

public onWorkEndHourChange(args: TimeEventArgs): void {
    this.scheduleObj.workHours.end = this.intl.formatDate(args.value, { skeleton: 'Hm' });
}

public onTimescaleDurationChange(args: ChangeEventArgs): void {
    this.scheduleObj.timeScale.interval = args.value as number;
}

public onTimescaleIntervalChange(args: ChangeEventArgs): void {
    this.scheduleObj.timeScale.slotCount = args.value as number;
}

public onTimeFormatChange(args: ChangeEventArgs): void {
    this.scheduleObj.timeFormat = args.value as string;
}

public onweekNumberChange(args: ChangeEventArgs): void {
    if (args.value == "Off") {
        this.scheduleObj.showWeekNumber = false;
    } else {
        this.scheduleObj.showWeekNumber = true;
        this.scheduleObj.weekRule = args.value as any;
    }
}

puxaequipe() {
  this.service.allequipe({ ID: this.id }).subscribe((datax: any[]) => {
    if (datax) {
        this.equie = datax;
        this.employeeDataSource = []
        for (let i = 0; i < this.equie.length; i++) {
          var colorx = '';
          if(this.equie[i].cargo === 'Vendas') {colorx ='#FF5733'}
          if(this.equie[i].cargo === 'Administrador') {colorx ='#4233FF'}
          if(this.equie[i].cargo === 'Instalador') {colorx ='#CEFF33'}
          if(this.equie[i].cargo === 'Financeiro') {colorx ='#FF33AC'}
          if(this.equie[i].cargo === 'Engenharia') {colorx ='#33FFEC'}
          if(this.equie[i].cargo === 'Estagiário') {colorx ='#33FF80'}
          if(this.equie[i].cargo === 'Compras') {colorx ='#780F62'}
          this.employeeDataSource.push({ Text: this.equie[i].funcionario, Id: this.equie[i].idcalendar, EmployeeId: this.equie[i].idcalendar, GroupId: this.equie[i].idcalendar, Color: colorx, Designation: this.equie[i].cargo }  )
        }
        console.log('this.employeeDataSource',this.employeeDataSource)
        this.service.mycalendar({ ID: this.id })
        .subscribe((data: any[]) => {
          if (data) {
            for (var a = 0; a < data.length; a++) {
              if (data[a].IsAllDay === "false") {
                data[a].IsAllDay = false
              }
            }
             this.eventSettings = { dataSource: data };
            console.log('calendario',data)
          }
        })
    }
  });
}
}
